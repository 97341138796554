import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import { get, post } from '../src/utils/http'
// import './config/rem'
import *as echarts from 'echarts'
import loginDialog from './components/loginDialog.vue';
import vueSeamlessScroll from 'vue-seamless-scroll' // 循环滚动
import './utils/dialog-directive'
Vue.use(vueSeamlessScroll)
Vue.prototype.$echarts = echarts
Vue.prototype.$img='https://www.zhnl.com'
Vue.prototype.$http = post
Vue.directive('html-with-spaces', {  
  bind(el, binding, vnode) {  
    let content = binding.value;  
    content = content.replace(/\s+/g, function(match) {  
      return Array(match.length + 1).join('&nbsp;');  
    });  
    el.innerHTML = content;  
  }  
});  
// 在main.js或类似的入口文件中  
const EventBus = new Vue();  
  
// 将其挂载到Vue的原型上，以便在任意组件中访问  
Vue.prototype.$eventBus = EventBus; 

Vue.prototype.$isLogin =  function() {
  let userId = window.localStorage.getItem('user_id')
  if (!userId) {
    this.$message.error('请前往登录');
    setTimeout(() => {
      window.location= '/'
    }, 1000)
    return
  }
}

import VeRing from 'v-charts/lib/ring.common'// 环形图

Vue.component(VeRing.name, VeRing)

import BaiduMap from 'vue-baidu-map'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
 
Vue.prototype.$video = Video
Vue.component('loginDialog', loginDialog)  


Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '5LBIZi1bDkGIveD6vuk9i0GGZGrTGYlj'
})

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  
  router,
  store,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this
	}
}).$mount('#app')
