<template>
    <div>
        <div class="quotedBox">
            <div class="title" style="margin-bottom: 20px;">地址管理 </div>

            <!-- <div class="formBox">
                <div class="line1 ">
                    <div class="btn" @click="sub">新增收货地址</div>
                </div>
                <div class="txt1">
                    <div class="txtName">
                        地址信息：
                    </div>
                    <div class="selectBox" style="display: flex; align-items: center;">
                        <div class="select">
                            <el-select v-model="value" placeholder="请选择省" style="width: 95%; height: 34px;"
                                @change="select1">
                                <el-option required v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="select" style="">
                            <el-select v-model="value2" placeholder="请选择市" style="width: 95%; height: 34px;"
                                @change="select2">
                                <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="select" style="">
                            <el-select v-model="value3" placeholder="请选择区" style="width: 95%; height: 34px;">
                                <el-option v-for="item in options3" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </div>

                <div class="txt1">
                    <div class="txtName">
                        详细地址：
                    </div>
                    <div>
                        <el-input type="textarea" :rows="3" placeholder="请输入详细地址信息，如道路、门牌号等" v-model="address">
                        </el-input>
                    </div>
                </div>

                <div class="txt1">
                    <div class="txtName">
                        邮政编码：
                    </div>
                    <div>
                        <el-input placeholder="请填写邮编" v-model="zipcode">
                        </el-input>
                    </div>
                </div>


                <div class="txt1">
                    <div class="txtName">
                        收货人姓名：
                    </div>
                    <div>
                        <el-input placeholder="长度不超过25个字符" v-model="names">
                        </el-input>
                    </div>
                </div>

                <div class="txt1">
                    <div class="txtName">
                        手机号码：
                    </div>
                    <div>
                        <el-input placeholder="手机号码" v-model="mobile">
                        </el-input>
                    </div>
                </div>


            </div> -->
            <el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules" :inline="true">

                <el-form-item label="选择省份：" label-width="100px" required prop="value">
                    <el-select v-model="ruleForm.value" placeholder="请选择省" @change="select1">
                        <el-option required v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择市：" label-width="100px" required prop="value2">
                    <el-select v-model="ruleForm.value2" placeholder="请选择市" @change="select2">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择区：" label-width="100px" required prop="value3">
                    <el-select v-model="ruleForm.value3" placeholder="请选择市">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="邮政编码:" prop="zipcode" required label-width="100px">
                    <el-input v-model="ruleForm.zipcode"></el-input>
                </el-form-item>
                <el-form-item label="收货人姓名:" prop="names" label-width="100px" required>
                    <el-input v-model="ruleForm.names"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="mobile" label-width="100px" required>
                    <el-input v-model="ruleForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="详细地址：" label-width="100px" style="width: 100%;" prop="address" required>
                    <el-input type="textarea" v-model="ruleForm.address" maxlength="30" style="width: 40vw;"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" style="margin-left: 2vw;" @click="sub('ruleForm')">新增收货地址</el-button>
                    <el-button type="primary" style="margin-left: 2vw;" @click="amend('ruleForm')"
                        v-if="ifShow">修改收货地址</el-button>
                </el-form-item>
            </el-form>
            <div class="tableBox">
                <el-table :data="tableData" style="width: 100%; border: 1px solid #AEAEAE;"
                    :header-cell-style="{ background: '#EBECF0', color: '#606266' }">
                    <el-table-column prop="consignee" label="收货人" width="140px">
                    </el-table-column>
                    <el-table-column prop="province" label="所在地区" width="140px">
                    </el-table-column>
                    <el-table-column prop="address" label="详细地址" width="180px">
                    </el-table-column>
                    <el-table-column prop="zipCode" label="邮政编码" width="100px">
                    </el-table-column>
                    <el-table-column prop="province_id" label="省" width="100px" v-if="false
                ">
                    </el-table-column>
                    <el-table-column prop="city_id" label="市" width="100px" v-if="false
                ">
                    </el-table-column>
                    <el-table-column prop="twOn_id" label="街道" width="100px" v-if="false">
                    </el-table-column>
                    <el-table-column prop="district_id" label="区" width="100px" v-if="false">
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号码">
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" width="150px" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button @click="amend(scope.row)" type="text" size="small">修改</el-button> -->
                            <el-button @click="amend2(scope.row)" type="text" size="small">编辑</el-button>
                            <span style="padding: 0 2px;">|</span>
                            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                            <!-- <el-button style="margin-right: 5px;" type="text" size="small" @click="selectAddress(scope.row)" v-if="refund != 1">选中地址</el-button>
                            <el-button style="margin-right: 5px;" type="text" size="small" @click="selectAddress2(scope.row)" v-if="refund == 1">选中地址</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>


        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'
import { getRegion, addUserAddress, getUserAddressByUserId, updateUserAddress, delUserAddress } from '@/api/user'
export default {
    data() {
        return {
            ifShow: false,
            ruleForm: {
                names: '',
                mobile: '',
                zipcode: '',
                address: '',
                value: '',
                value2: '',
                value3: '',
            },
            tableData: [],

            //表单数据
            names: '',
            address: '',
            zipcode: '',
            mobile: '',
            address_id: 0,

            options: [],
            value: '',
            options2: [],
            value2: '',
            options3: [],
            value3: '',
            refund: '',
            orderId: '',
            //form

            rules: {
                value: [
                    { required: true, message: '请选择省份', trigger: 'change' }
                ],
                value2: [
                    { required: true, message: '请选择城市', trigger: 'change' }
                ],
                value3: [
                    { required: true, message: '请选择区县', trigger: 'change' }
                ],
                // region: [
                //     { required: true, message: '请选择活动区域', trigger: 'blur' }
                // ],
                // date1: [
                //     { type: 'date', required: true, message: '请选择日期', trigger: 'blur' }
                // ],
                // date2: [
                //     { type: 'date', required: true, message: '请选择时间', trigger: 'blur' }
                // ],
                mobile: [
                    { required: true, message: '请填写手机号码', trigger: 'blur' }
                ],
                names: [
                    { required: true, message: '请填写收货人姓名', trigger: 'blur' }
                ],
                zipcode: [
                    { required: true, message: '请填写邮政编码', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' }
                ],

            }
        }
    },

    methods: {

        select1() {
            this.UserRegionList2()
        },

        select2() {
            this.UserRegionList3()
        },

        selectAddress(e) {
            console.log(e)
            this.UserAddressDefault(e.address_id)
        },

        selectAddress2(e) {
            console.log(e)
            window.location = '/buyerCenter/afterSalePut?addressId=' + e.address_id + '&orderId=' + orderId

        },

        sub(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    let params = {
                        user_id: window.localStorage.getItem('user_id'),
                        consignee: this.ruleForm.names,
                        address: this.ruleForm.address,
                        zipcode: this.ruleForm.zipcode,
                        mobile: this.ruleForm.mobile,
                        province: this.ruleForm.value,
                        city: this.ruleForm.value2,
                        district: this.ruleForm.value3
                    }
                    addUserAddress(params).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success('添加收货地址成功')
                        }
                        this.tableData = []
                        this.UserAddressList()

                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        amend2(row) {
            this.ifShow = true
            this.address_id = row.address_id
            console.log(row, 'row000');
            this.ruleForm.names = row.consignee
            this.ruleForm.mobile = row.mobile
            this.ruleForm.zipcode = row.zipCode
            this.ruleForm.address = row.address
            this.ruleForm.value = row.province_id
            // this.ruleForm.value2 = row.city_id
            // this.ruleForm.value3 = row.twOn_id

            let params = {
                level: null,
                parent_id: row.province_id
            }
            let params2 = {
                level: null,
                parent_id: row.city_id
            }
            getRegion(params).then((res) => {
                res.data.data.forEach((item, index) => {
                    let obj = {}
                    obj.value = item.rId
                    obj.label = item.rName
                    this.options2.push(obj)
                })
                this.ruleForm.value2 = row.city_id
            })
            getRegion(params2).then((res) => {
                res.data.data.forEach((item, index) => {

                    let obj = {}
                    obj.value = item.rId
                    obj.label = item.rName
                    this.options3.push(obj)
                })
                this.ruleForm.value3 = row.district_id
            })
        },
        amend(row) {
            // let params = row
            // console.log(params,'passs');
            // params.province = row.province_id
            this.ifShow = false
            let params = {
                user_id: window.localStorage.getItem('user_id'),
                consignee: this.ruleForm.names,
                mobile: this.ruleForm.mobile,
                zipcode: this.ruleForm.zipcode,
                address: this.ruleForm.address,
                province: this.ruleForm.value,
                city: this.ruleForm.value2,
                district: this.ruleForm.value3,
                address_id: this.address_id,
                twon: '',
            }
            updateUserAddress(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.ruleForm = {
                        names: '',
                        mobile: '',
                        zipcode: '',
                        address: '',
                        value: '',
                        value2: '',
                        value3: '',
                    }
                    this.$nextTick(() => {
                        this.$refs['ruleForm'].clearValidate();
                    });

                }

            })
            this.tableData = []
            this.UserAddressList()
            // this.UserAddressInfo(row.address_id)
            // document.documentElement.scrollTop = 0
        },

        del(row) {
            let params = {
                user_id: window.localStorage.getItem('user_id'),
                address_id: row.address_id
            }
            delUserAddress(params).then((res) => {
                console.log(res, '删除了');
                this.$forceUpdate()
                if (res.data.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.tableData = []
                    this.UserAddressList()
                }
            })
            // this.UserAddressDelete(row.address_id)
        },

        //地址详情
        async UserAddressInfo(address_id) {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                address_id: address_id
            }
            let res = await this.$http('/mini/ApiConnect/UserAddressInfo', params)

            this.names = res.result.consignee
            this.address = res.result.consignee
            this.zipcode = res.result.zipcode
            this.mobile = res.result.mobile
            this.address_id = res.result.address_id
        },

        //地址下拉列表
        async UserRegionList() {
            let params = {
                level: 1,
                parent_id: 0
            }
            // this.options2 = []
            // this.options3 = []
            console.log(this.value, '-----------------------');

            getRegion(params).then((res) => {
                console.log(res, 'kkkkkkkkkkkkkk');
                res.data.data.forEach((item, index) => {
                    this.options2 = []
                    this.options3 = []
                    let obj = {}
                    obj.value = item.rId
                    obj.label = item.rName
                    this.options.push(obj)
                })
            })
            // let res = await this.$http('/mini/ApiConnect/UserRegionList')

            // res.result.forEach((e, index) => {
            //     let obj = {}
            //     obj.value = e.id
            //     obj.label = e.name
            //     this.options.push(obj)
            // })
        },

        async UserRegionList2() {
            this.ruleForm.value2 = ''
            let params = {
                level: null,
                parent_id: this.ruleForm.value
            }
            this.options2 = []
            getRegion(params).then((res) => {
                console.log(res, 'kkkkkkkkkkkkkk22222');

                res.data.data.forEach((item, index) => {
                    let obj = {}
                    obj.value = item.rId
                    obj.label = item.rName
                    this.options2.push(obj)
                })
            })
            // let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value })
            // this.options2 = []
            // res.result.forEach((e, index) => {
            //     let obj = {}
            //     obj.value = e.id
            //     obj.label = e.name
            //     this.options2.push(obj)
            // })
        },

        async UserRegionList3() {
            this.ruleForm.value3 = ''
            let params = {
                level: null,
                parent_id: this.ruleForm.value2
            }
            getRegion(params).then((res) => {
                console.log(res, 'kkkkkkkkkkkkkk22222');
                this.options3 = []
                res.data.data.forEach((item, index) => {

                    let obj = {}
                    obj.value = item.rId
                    obj.label = item.rName
                    this.options3.push(obj)
                })
            })
            // let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value2 })
            // this.options3 = []

            // res.result.forEach((e, index) => {
            //     let obj = {}
            //     obj.value = e.id
            //     obj.label = e.name
            //     this.options3.push(obj)
            // })
        },

        //修改默认地址
        async UserAddressDefault(addressId) {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserAddressDefault', { user_id: userId, address_id: addressId })
            if (res.status == 1) {
                window.location = '/order'
            }
        },

        //修改地址
        async UserAddressDo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                // address_id: 0,
                consignee: this.names,
                address: this.address,
                zipcode: this.zipcode,
                mobile: this.mobile,
                province: this.value,
                city: this.value2,
                district: this.value3,

            }
            // if (this.address_id != 0) {
            //     params.address_id = this.address_id
            // }
            addUserAddress(params).then((res) => {

            })
            // let res = await this.$http('/mini/ApiConnect/UserAddressDo', params)
            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success'
            //     });
            //     window.location.reload()
            // } else {
            //     this.$message.error(res.msg);
            // }
        },

        //获取地址
        async UserAddressList() {
            let user_id = window.localStorage.getItem('user_id')
            getUserAddressByUserId({ user_id }).then((res) => {
                res.data.data.forEach((e, index) => {
                    let obj = {}
                    obj.consignee = e.consignee
                    obj.province = e.province
                    obj.address = e.address
                    obj.zipCode = e.zipCode
                    obj.mobile = e.mobile
                    obj.address_id = e.addressId
                    obj.city_id = e.city_id
                    obj.province_id = e.province_id
                    obj.twOn_id = e.twOn_id
                    obj.district_id = e.district_id
                    this.tableData.push(obj)
                })
            })
            // let res = await this.$http('/mini/ApiConnect/UserAddressList', { user_id: userId })
            // res.result.forEach((e, index) => {
            //     let obj = {}
            //     obj.perpeo = e.consignee
            //     obj.region = e.region
            //     obj.address = e.address
            //     obj.code = e.zipcode
            //     obj.phone = e.mobile
            //     obj.address_id = e.address_id
            //     this.tableData.push(obj)
            // })
        },

        //删除地址
        async UserAddressDelete(address_id) {
            let userId = window.localStorage.getItem('user_id')

            let res = await this.$http('/mini/ApiConnect/UserAddressDelete', { user_id: userId, address_id: address_id })

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                window.location.reload()
            } else {
                this.$message.error(res.msg);
            }
        },
    },

    mounted() {
        // this.refund = getParams().refund
        // this.orderId = getParams().orderId

        this.UserRegionList()
        this.UserAddressList()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 105%;
    // margin-top: 24px;
    // height: 956px;
    margin-bottom: 50px;
    padding: 24px;
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
    }


    .line1 {
        // padding: 20px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
        position: relative;

        .btn {
            width: 142px;
            height: 42px;
            // text-align: center;
            line-height: 42px;
            color: #006EB7;
            // margin-top: 119px;
            margin-bottom: 10px;

            cursor: pointer;
        }
    }

    .tableBox {
        width: 850px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 60px;

        ::v-deep .cell {
            font-size: 16px;
            color: #000;
            font-weight: normal !important;
        }

        ::v-deep .el-button--text {
            font-size: 16px;
            color: #000;
            font-weight: normal !important;
            ;
        }
    }

    .formBox {
        width: 333px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 21px;

        .txt1 {
            margin-bottom: 21px;

            .txtName {
                font-size: 16px;
                line-height: 18.72px;
                text-align: left;
                color: #000;
                margin-bottom: 7px;
            }

            .select {
                width: 332px;
                height: 34px;

            }
        }
    }
}
</style>