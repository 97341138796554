<template>
  <div class="zh-box" style="margin-top: 30px">
    <!-- <div class="wz-top">
            <div
                style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;margin-left: 4px;">
                {{ titleName }}
            </div>
            <div class="classifyList">

                <div class="classify-item" v-for="item in areaCategoryList2.slice(0, 7) "
                    :class="{ bgc: wangNumber == item.gId }" @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>
            <div>
            </div>
        </div> -->
    <div class="lun">
      <div class="lun-left">
        <div style="font-weight: 400; font-size: 26px; color: #227bff; line-height: 40px; width: 200px; margin-left: 4px; padding: 10px 0px">
          {{ titleName }}
        </div>
        <div class="line1" style="border: 1px solid #227bff; border-radius: 10px">
          <!-- <div class="top">
                    </div> -->

          <div class="scroll_table">
            <div style="display: inline-block; width: 100%">
              <el-table
                :data="tableList2"
                class="table"
                :class="['commonScrollbarTable']"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'rgba(34, 123, 255)',
                  color: 'white',
                  // 'border-radius': '10px',

                  padding: '0',
                  height: '46px',
                  'font-family': 'MicrosoftYaHeiLight',
                  'font-size': '14px'
                }"
                :row-style="{
                  padding: '0px',
                  cursor: 'pointer',
                  color: '#227BFF',

                  'background-color': 'rgba(230, 239, 243)'
                }"
                :cell-style="{
                  'text-align': 'center',
                  'font-size': '14px',
                  padding: '0',
                  height: '45.5px'
                }"
                @row-click="tableClick2"
              >
                <el-table-column
                  prop="date"
                  label="日期"
                  :style="{
                    'font-size': '14px',
                    width: '100px'
                  }"
                >
                </el-table-column>
                <el-table-column prop="good_name" label="产品">
                  <template #default="scope">
                    <div>{{ scope.row.good_name / scope.row.type }}123</div>
                  </template>
                </el-table-column>
                <el-table-column prop="bqPrice" label="价格"> </el-table-column>
                <el-table-column prop="real_name" label="供货商" width="190px"> </el-table-column>

                <el-table-column prop="up_price" label="涨跌">
                  <template slot-scope="scope">
                    <div style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                      <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; overflow: auto">
                        {{ scope.row.up_price }}
                      </div>
                    </div>

                    <div style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 2">
                      <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center">
                        {{ scope.row.up_price }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div @click="godeilt($event)" style="position: relative">
                <vue-seamless-scroll :data="tableList2" class="seamless-warp" style="width: 100%" :class-option="classOption2">
                  <el-table
                    :data="tableList2"
                    class="table_scroll"
                    :class="['commonScrollbarTable']"
                    :header-cell-style="{
                      'text-align': 'center',
                      'background-color': '#EFEFEF',
                      padding: '0',
                      height: '32px',
                      'font-family': 'Microsoft YaHei UI Bold',
                      'font-size': '14px'
                    }"
                    :row-style="{
                      padding: '0px',
                      cursor: 'pointer'
                    }"
                    :cell-style="{
                      'text-align': 'center',
                      'font-size': '14px',
                      padding: '0',
                      height: '45.5px'
                    }"
                  >
                    <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="font-size: 14px; text-align: center; color: #333333">
                          {{ scope.row.add_time.slice(5, scope.row.date?.length) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="good_name" label="产品名称">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                          {{ scope.row.type ? scope.row.good_name + "/" + scope.row.type : scope.row.good_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">{{ scope.row.price }}/{{ scope.row.spec }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="real_name" label="供货商" width="190px">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                          <span style="vertical-align: middle"> {{ scope.row.real_name }}</span>
                          <!-- <img src="../assets/newImg3/超级会员静态.png"
                                                        style="vertical-align: middle;padding-left: 2px;width: 52px;height: 17px;"
                                                        alt="" v-if="scope.row.is_supper_vip == 1">
                                                    <img v-else-if="scope.row.is_bus_vip == 1"
                                                        src="../assets/newImg3/黄金会员@1x.png"
                                                        style="vertical-align: middle;padding-left: 2px;width: 52px;height: 17px;"
                                                        alt=""> -->
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="up_price" label="涨跌">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 0">
                          <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; overflow: auto">
                            {{ scope.row.up_price }}
                          </div>
                        </div>

                        <div :data-id="JSON.stringify(scope.row)" style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                          <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center">
                            {{ scope.row.up_price }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
          <div class="scroll_table2">
            <div style="display: inline-block; width: 100%">
              <el-table
                :data="tableList3"
                style=""
                class="table"
                id="czh"
                :class="['commonScrollbarTable']"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'green',
                  'font-weight': '900',
                  'border-radius': '10px',
                  color: '#227BFF',
                  padding: '0',
                  height: '46px',
                  'font-family': 'MicrosoftYaHeiLight',
                  'font-size': '14px'
                }"
                :row-style="{
                  padding: '0px',
                  cursor: 'pointer',
                  color: '#227BFF',

                  'background-color': 'rgba(30, 239, 243)'
                }"
                :cell-style="{
                  'text-align': 'center',
                  'font-size': '14px',
                  padding: '0',
                  height: '45.5px',
                  color: '#227BFF'
                }"
                @row-click="tableClick2"
              >
                <el-table-column
                  prop="date"
                  label="日期"
                  :style="{
                    'font-size': '14px',
                    width: '100px'
                  }"
                >
                </el-table-column>
                <el-table-column prop="good_name" label="产品名称">
                  <template slot-scope="scope">
                    <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                      {{ scope.row.type ? scope.row.good_name + "/" + scope.row.type : scope.row.good_name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="bqPrice" label="价格"> </el-table-column>
                <el-table-column prop="bus_name_abbrev" label="供货商" width="190px"> </el-table-column>

                <el-table-column prop="up_price" label="涨跌">
                  <template slot-scope="scope">
                    <div style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                      <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; overflow: auto">
                        {{ scope.row.up_price }}
                      </div>
                    </div>

                    <div style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 2">
                      <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center">
                        {{ scope.row.up_price }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div @click="godeilt($event)" style="position: relative">
                <vue-seamless-scroll :data="tableList3" class="seamless-warp2" style="width: 100%" :class-option="classOption3">
                  <el-table
                    :data="tableList3"
                    class="table_scroll"
                    :class="['commonScrollbarTable']"
                    :row-class-name="tableRowClassName2"
                    :header-cell-style="{
                      'text-align': 'center',
                      'background-color': '#EFEFEF',
                      'font-weight': '900',
                      padding: '0',
                      height: '32px',
                      'font-family': 'Microsoft YaHei UI Bold',
                      'font-size': '14px'
                    }"
                    :row-style="{
                      padding: '0px',
                      cursor: 'pointer',
                      'font-weight': '960'
                      // 'color': '#0256FF'
                    }"
                    :cell-style="{
                      color: '#227BFF',
                      'text-align': 'center',
                      'font-size': '14px',
                      padding: '0',
                      height: '45.5px'
                    }"
                  >
                    <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="font-size: 14px; text-align: center; color: #227bff">
                          {{ scope.row.add_time.slice(5, scope.row.date?.length) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="good_name" label="产品名称">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                          {{ scope.row.type ? scope.row.good_name + "/" + scope.row.type : scope.row.good_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">{{ scope.row.price }}/{{ scope.row.spec }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="bus_name_abbrev" label="供货商" width="190px">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                          <span style="vertical-align: middle"> {{ scope.row.bus_name_abbrev }}</span>
                          <img src="../assets/newImg3/超级会员静态.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" />
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="up_price" label="涨跌">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 0">
                          <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; overflow: auto; font-weight: 290">
                            {{ scope.row.up_price }}
                          </div>
                        </div>

                        <div :data-id="JSON.stringify(scope.row)" style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                          <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; font-weight: 400">
                            {{ scope.row.up_price }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
          <div class="scroll_table3">
            <div style="display: inline-block; width: 100%">
              <el-table
                :data="tableList4"
                style=""
                class="table"
                id="czh"
                :class="['commonScrollbarTable']"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'green',
                  'font-weight': '900',
                  'border-radius': '10px',

                  color: '#227BFF',
                  padding: '0',
                  height: '46px',
                  'font-family': 'MicrosoftYaHeiLight',
                  'font-size': '14px'
                }"
                :row-style="{
                  padding: '0px',
                  cursor: 'pointer',
                  color: '#227BFF',

                  'background-color': 'rgba(30, 239, 243)'
                }"
                :cell-style="{
                  'text-align': 'center',
                  'font-size': '14px',
                  padding: '0',
                  height: '45.5px',
                  color: '#227BFF'
                }"
                @row-click="tableClick2"
              >
                <el-table-column
                  prop="date"
                  label="日期"
                  :style="{
                    'font-size': '14px',
                    width: '100px'
                  }"
                >
                </el-table-column>
                <el-table-column prop="good_name" label="产品"> </el-table-column>
                <el-table-column prop="bqPrice" label="价格"> </el-table-column>
                <el-table-column prop="real_name" label="供货商" width="190px"> </el-table-column>

                <el-table-column prop="up_price" label="涨跌">
                  <template slot-scope="scope">
                    <div style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                      <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; overflow: auto">
                        {{ scope.row.up_price }}
                      </div>
                    </div>

                    <div style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 2">
                      <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center">
                        {{ scope.row.up_price }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div @click="godeilt($event)" style="position: relative">
                <vue-seamless-scroll :data="tableList4" class="seamless-warp2" style="width: 100%" :class-option="classOption3">
                  <el-table
                    :data="tableList4"
                    class="table_scroll"
                    :class="['commonScrollbarTable']"
                    :row-class-name="tableRowClassName3"
                    :header-cell-style="{
                      'text-align': 'center',
                      'background-color': '#EFEFEF',
                      'font-weight': '900',
                      padding: '0',
                      height: '32px',
                      'font-family': 'Microsoft YaHei UI Bold',
                      'font-size': '14px'
                    }"
                    :row-style="{
                      padding: '0px',
                      cursor: 'pointer',
                      'font-weight': '400',
                      color: '#227BFF'
                    }"
                    :cell-style="{
                      color: '#227BFF',
                      'text-align': 'center',
                      'font-size': '14px',
                      padding: '0',
                      height: '45.5px'
                    }"
                  >
                    <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="font-size: 14px; text-align: center; color: #227bff">
                          {{ scope.row.add_time.slice(5, scope.row.date?.length) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="good_name" label="产品名称">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                          {{ scope.row.type ? scope.row.good_name + "/" + scope.row.type : scope.row.good_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">{{ scope.row.price }}/{{ scope.row.spec }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="real_name" label="供货商" width="190px">
                      <template slot-scope="scope">
                        <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                          <span style="vertical-align: middle"> {{ scope.row.real_name }}</span>
                          <img src="../assets/newImg3/黄金会员@1x.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" />
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="up_price" label="涨跌">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 0">
                          <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; overflow: auto; font-weight: 290">
                            {{ scope.row.up_price }}
                          </div>
                        </div>

                        <div :data-id="JSON.stringify(scope.row)" style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                          <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; font-weight: 400">
                            {{ scope.row.up_price }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lun-right">
        <div style="font-weight: 400; font-size: 26px; color: #227bff; line-height: 40px; width: 200px; margin-left: 4px; padding: 10px 3px">期货指数</div>
        <div style="width: 100%; display: flex; flex-wrap: wrap; height: 90%">
          <div class="micleBo" v-for="(item, index) in qihuoList.slice(0, 6)" @click="priceRadio(item)">
            <div class="micleBo1">
              {{ item.goodName }}
            </div>
            <div class="micleBo2">
              {{ parseInt(item.ytPrice).toFixed(0) }}
            </div>
            <div class="micleBo3">
              <span style="font-size: 14px; vertical-align: middle; color: #d8d8d8" v-if="item.changeQuantity == 0">{{ item.upPercentage }}</span>
              <span style="font-size: 14px; vertical-align: middle; color: red" v-else-if="item.changeQuantity > 0">{{ item.upPercentage }}</span>
              <span style="font-size: 14px; vertical-align: middle; color: green" v-else>{{ item.upPercentage }}</span>
              <img src="../assets/newImg3/路径@1x (9).png" v-show="item.changeQuantity > 0" style="vertical-align: middle; margin-right: 4px" alt="" />
              <img src="../assets/newImg3/路径@1x (10).png" v-show="item.changeQuantity < 0" style="vertical-align: middle; margin-right: 4px" alt="" />
              <span style="font-size: 12px; font-weight: 400; color: #c5c5c5; margin-left: 14px">{{ item.updateTime.slice(0, 10) }}</span>
            </div>
            <div class="micleBo4" :ref="'echart' + index"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="lun2">
      <div class="lun-left">
        <div style="font-weight: 400; font-size: 26px; color: #227bff; line-height: 40px; width: 200px; margin-left: 4px; padding: 4px 0px">最新视频</div>
        <div class="edit-box">
          <div class="edit-item" v-for="item in videoList" @click="toVedio(item)" :key="item.vId">
            <div style="position: relative">
              <div style="position: absolute; bottom: 8px; right: 3px; font-size: 12px; color: #ffffff"></div>
              <img :src="item.vImgUrl" style="width: 100%; height: 116px; object-fit: cover; border-radius: 12px" alt="" />
            </div>
            <div style="color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ item.vTitle }}
            </div>
            <div style="color: gray; font-size: 12px; padding-top: 3px; color: #9499a0">
              <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

              <span style="vertical-align: middle">{{ item.vUploader }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lun-right">
        <div style="font-weight: 400; font-size: 26px; color: #227bff; line-height: 40px; width: 200px; margin-left: 4px; padding: 10px 3px">最新资讯</div>
        <div class="consultBox">
          <div style="display: flex">
            <div class="six-item" v-for="item in IndustryInformationList1" @click="toTitle(item)" style="cursor: pointer">
              <div class="six-left">
                <img :src="item.imgUrl" style="width: 100%; height: 96%; border-radius: 4px" alt="" />
              </div>
              <div class="six-right">
                <div class="six-right-1">{{ item.bgcTitle }}</div>
                <div class="six-right-2">{{ item.bgcContent }}</div>
                <div class="six-right-3">
                  <img src="../assets/newImg/Vector@0.75x.png" style="vertical-align: middle; line-height: 8px; line-height: 13px; width: 13px; height: 13px" alt="" />
                  <span style="vertical-align: middle; margin-left: 3px; line-height: 14px; font-size: 12px">{{ item.addTime }}</span>

                  <span style="margin-left: 8px; line-height: 14px">阅读：{{ item.readNum }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-wrap: wrap; gap: 0; align-content: flex-start; justify-content: flex-start">
            <div class="title-item" v-for="item in IndustryInformationList2" @click="toTitle(item)">• {{ item.bgcTitle }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="six">
            <div class="six-item" v-for="item in IndustryInformationList3" @click="toTitle(item)"
                style="cursor: pointer;">
                <div class="six-left">
                    <img :src="item.imgUrl" style="width: 100%;height: 96%;border-radius: 4px;" alt="">
                </div>
                <div class="six-right">
                    <div class="six-right-1">{{ item.bgcTitle }}</div>
                    <div class="six-right-2">{{ item.bgcContent }}
                    </div>
                    <div class="six-right-3">
                        <img src="../assets/newImg/Vector@0.75x.png"
                            style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                            alt="">
                        <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                            }}</span>

                        <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
import { getQuotToIndex, getAllGoodsConsult, getBstQuotedPrice, getAllVod, getForwardPrice, getKline, getBriefs, getSymbols } from "@/api/user";
import dayjs from "dayjs";

export default {
  data() {
    return {
      tableList4: [],
      dataList: [],
      qihuoList: [],
      IndustryInformationList: [],
      videoList: [],
      tableList3: [],
      tableList2: [],
      params2: {},
      areaCategoryList: [],
      wangNumber: 0,
      size: 30,
      page: 1,
      IndustryInformationList1: {},

      IndustryInformationList2: [],
      IndustryInformationList3: []
    };
  },
  // props: ['areaCategoryList2', 'titleName'],
  props: {
    areaCategoryList2: {
      type: Array,
      default: []
    },
    titleName: {
      type: String
    },
    isShow: {
      type: Number,
      default: 1
    },
    gId: {
      type: String,
      default: ""
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, "rw----");
      if (row.is_supper_vip == 1) {
      } else if (row.is_bus_vip == 1) {
        return "warning-row2";
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      return "warning-row";
    },
    tableRowClassName3({ row, rowIndex }) {
      return "warning-row2";
    },
    getEchartData(index, yData) {
      const chart = eval("this.$refs.echart" + index)[0];
      if (chart) {
        const myChart = this.$echarts.init(chart);
        let spec = this.spec;
        const option = {
          tooltip: {
            trigger: "axis", // 触发类型，可选为'item'、'axis'
            axisPointer: {
              type: "shadow" // 指示器类型
            },
            formatter: function (params) {
              // 自定义显示内容，params 是包含当前数据点信息的数组
              var value = params[0].value; // 获取当前数据点的值
              var seriesName = params[0].seriesName; // 获取系列名称
              // 根据需要格式化显示内容
              return `价格：${value}`;
            }
          },
          xAxis: {
            type: "category",
            data: this.dataList
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false // 隐藏Y轴轴线
            },
            axisTick: {
              show: false // 隐藏Y轴刻度线
            },
            axisLabel: {
              show: false // 隐藏Y轴刻度标签
            }
          },
          series: [
            {
              data: yData,
              type: "line",
              smooth: true
            }
          ]
        };
        myChart.setOption(option);
      }
    },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId
        }
      });
      window.open(href, "_blank");
    },
    godeilt(e) {
      //  let uid=e.target.dataset.id
      const event = JSON.parse(e.target.dataset.id);
      this.tableClick2(event);
    },
    tableClick2(row) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: row.cat_one,
            catTwo: row.cat_two,
            catThree: row.cat_three,
            label: row.good_name
          })
        }
      });
      window.open(href, "_blank");
    },

    toTitle(item) {
      const { href } = this.$router.resolve({
        path: "/titleDetails",
        query: {
          id: item.bgcId
        }
      });
      window.open(href, "_blank");
    }
  },
  created() {},
  computed: {
    classOption2() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      };
    },
    classOption3() {
      return {
        step: 10, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 182, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 4000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  mounted() {
    // getBriefs({
    //     symbols: 'ag2410'
    // }).then((res) => {

    // })
    // getSymbols({}).then((res) => {

    // })
    // getKline({
    //     symbols: 'MA2409',
    //     limit: 10,

    // }).then((res) => {

    // })
    let today = dayjs();

    // 创建一个空数组来存储日期
    // let datesArray = [];

    // 循环四次，获取前四天的日期
    for (let i = 1; i >= 0; i--) {
      this.dataList.push(today.subtract(i, "day").format("MM-DD"));
    }
    const loading = this.$loading({
      lock: true,
      text: "",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.2)"
    });
    let data = {
      industry: this.industry,
      industry2: "",
      page: 1,
      size: 6,
      title: ""
    };
    getForwardPrice({ page: 1, size: 6 }).then((res) => {
      this.qihuoList = res.data.data;
      this.qihuoList.forEach((item, index) => {
        setTimeout(() => {
          this.getEchartData(index, item.fList);
        }, 1000);
      });
    });

    getAllVod(data).then((res) => {
      this.videoList = res.data.data;
    });
    let data2 = {
      cat_one: "",
      cat_two: "",
      cat_three: "",
      industry: "",
      industry2: "",
      size: 4,
      page: 1
    };

    getQuotToIndex({ size: 6 }).then((res) => {
      this.tableList3 = res.data.data;
    });

    getBstQuotedPrice({ size: 61, is_vip: 2 }).then((res) => {
      loading.close();
      this.tableList2 = res.data.data;
    });

    getBstQuotedPrice({ size: 61, is_vip: 1 }).then((res) => {
      loading.close();
      this.tableList4 = res.data.data;
    });
    this.wangNumber = this.gId;
    let params2 = {
      cat_one: this.gId,
      cat_two: "",
      cat_three: "",
      industry: "",
      industry2: "",
      size: 12,
      page: this.page
    };
    getAllGoodsConsult(params2).then((res) => {
      this.total_page = res.data.totalPage;
      res.data.data.forEach((item, index) => {
        item.add_time = dayjs(item.add_time * 1000).format("YYYY-MM-DD");
      });
      res.data.data.forEach((item, index) => {
        item.addTime = item.addTime.slice(0, 10);
      });
      res.data.data.forEach((item) => {
        item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, " ");
      });
      this.IndustryInformationList1 = res.data.data.slice(0, 2);
      this.IndustryInformationList2 = res.data.data.slice(2, 12);
      this.IndustryInformationList3 = res.data.data.slice(15, 21);
    });
    // getBstGoodsGategory1().then((res) => {
    //     this.areaCategoryList = res.data.data
    //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
    //     console.log(this.areaCategoryList, 'this.areaCategoryList ');
    // })
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
  .zh-box {
    width: 1200px;
    height: 1080px;
    background-color: white;
    padding: 10px 20px;
    font-family: "MicrosoftYaHeiLight";
    margin: auto;

    .wz-top {
      height: 50px;
      width: 100%;
      display: flex;

      .classifyList {
        display: flex;
        height: 100%;
        margin-left: 26px;
        overflow: hidden;

        .classify-item {
          font-size: 16px;
          color: #333333;
          font-weight: 400;
          height: 20px;
          padding: 12px 8px;
          cursor: pointer;
        }

        .classify-item:hover {
          // color: #227BFF;
          // border-bottom: 1px solid #227BFF;
        }
      }
    }

    .lun {
      height: 685px;
      width: 100%;
      display: flex;
      // background-color: skyblue;
      margin-top: 2px;

      .lun-left {
        width: 48.5%;
        height: 98% !important;
        padding: 5px;
        position: relative;
        // overflow: hidden;

        // background-color: pink;
        .edit-box {
          width: 100%;
          height: 50%;
          flex-wrap: wrap;
          display: flex;
          margin-top: 6px;

          .edit-item {
            box-sizing: border-box;
            cursor: pointer;
            width: 33.3%;
            height: 11.2rem;
            padding: 0px 4px;
            // background-color: skyblue;
          }
        }
      }

      .lun-right {
        width: 52.8%;
        height: 98%;
        padding: 5px 0px 0px 15px;

        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;

        .micleBo {
          width: 31%;
          height: 49%;
          margin-right: 15px;
          // border: 1px solid #D8D8D8;
          border-radius: 10px;
          margin-bottom: 10px;
          border: 1px solid #227bff;

          .micleBo1 {
            margin-top: 28px;
            padding: 8px 36px;
          }

          .micleBo2 {
            padding: 0px 27px;
            font-weight: 700;
            font-size: 40px;
          }

          .micleBo3 {
            padding: 8px 35px;
          }
        }

        .title-item {
          width: 47%;
          height: 20px;
          padding: 12px 8px;
          overflow: hidden !important;
          /* 超出部分隐藏 */
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
        }

        .title-item:hover {
          color: #227bff;
        }
      }
    }

    .lun2 {
      height: 420px;
      width: 100%;
      display: flex;
      // background-color: skyblue;
      margin-top: 2px;

      .lun-left {
        width: 48.5%;
        height: 98%;
        padding: 5px;
        position: relative;
        overflow: hidden;

        .edit-box {
          width: 100%;
          height: 50%;
          flex-wrap: wrap;
          display: flex;
          margin-top: 6px;

          .edit-item {
            box-sizing: border-box;
            cursor: pointer;
            width: 33.3%;
            height: 11.2rem;
            padding: 0px 4px;
            // background-color: skyblue;
          }
        }
      }

      .lun-right {
        width: 52.8%;
        height: 98%;
        padding: 5px 0px 0px 15px;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;

        .micleBo {
          width: 31%;
          height: 49%;
          margin-right: 15px;
          border-radius: 10px;
          margin-bottom: 10px;
          border: 1px solid #227bff;

          .micleBo1 {
            margin-top: 28px;
            padding: 8px 36px;
          }

          .micleBo2 {
            padding: 0px 27px;
            font-weight: 700;
            font-size: 40px;
          }

          .micleBo3 {
            padding: 8px 35px;
          }
        }

        .consultBox {
          width: 100%;

          .six-item {
            width: 48%;
            height: 100px;
            padding: 2px 0px;
            display: flex;
            margin-top: 5px;
            margin-left: 1px;

            .six-left {
              width: 46%;
              height: 100%;
            }

            .six-right {
              width: 64%;
              height: 100%;
              padding: 2px 6px 2px 3px;

              .six-right-1 {
                height: 19px;
                padding: 4px 1px 1px 1px;
                font-weight: 900;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #000000;
                font-size: 14px;
              }

              .six-right-2 {
                height: 30px;
                padding: 2px 2px 2px 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                font-size: 12px;
                color: #000000;
              }

              .six-right-3 {
                display: flex;
                height: 15%;
                padding: 9px 0px 0px 4px;
                font-size: 12px;
                color: #999999;
              }
            }
          }
        }

        .title-item {
          width: 47%;
          height: 20px;
          padding: 12px 8px;
          overflow: hidden !important;
          /* 超出部分隐藏 */
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
        }

        .title-item:hover {
          color: #227bff;
        }
      }
    }

    .six {
      width: 100%;
      height: 374px;
      margin-top: 8px;
      padding: 8px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;

      .six-item {
        width: 48%;
        height: 33.6%;
        padding: 2px 0px;
        display: flex;
        margin-top: 5px;
        border-bottom: 1px solid #eaeaea;
        margin-left: 1px;

        .six-left {
          width: 32%;
          height: 100%;
        }

        .six-right {
          width: 64%;
          height: 100%;
          padding: 2px 6px 2px 16px;

          .six-right-1 {
            height: 19px;
            padding: 4px 1px 1px 1px;
            font-weight: 900;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #000000;
            font-size: 14px;
          }

          .six-right-2 {
            height: 30px;
            padding: 12px 2px 2px 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 12px;
            color: #000000;
          }

          .six-right-3 {
            display: flex;
            height: 15%;
            padding: 32px 0px 0px 4px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1620px) and (max-width: 25000px) {
  .zh-box {
    margin: auto;

    width: 1500px;
    height: 1122px;
    background-color: white;
    padding: 10px 20px;
    font-family: "MicrosoftYaHeiLight";

    .wz-top {
      height: 50px;
      width: 100%;
      display: flex;

      .classifyList {
        display: flex;
        height: 100%;
        margin-left: 26px;

        .classify-item {
          font-size: 16px;
          color: #333333;
          font-weight: 400;
          height: 20px;
          padding: 12px 12px;
          cursor: pointer;
        }

        .classify-item:hover {
          // color: #227BFF;
          // border-bottom: 1px solid #227BFF;
        }
      }
    }

    .lun {
      height: 685px;
      width: 100%;
      display: flex;
      // background-color: skyblue;
      margin-top: 2px;

      .lun-left {
        width: 48.5%;
        height: 98%;
        padding: 5px;
        position: relative;
        // overflow: hidden;

        .edit-box {
          width: 100%;
          height: 50%;
          flex-wrap: wrap;
          display: flex;
          margin-top: 6px;

          .edit-item {
            box-sizing: border-box;
            cursor: pointer;
            width: 33.3%;
            height: 11.2rem;
            padding: 0px 4px;
            // background-color: skyblue;
          }
        }
      }

      .lun-right {
        width: 52.8%;
        height: 98%;
        padding: 5px 0px 0px 15px;

        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;

        .micleBo {
          width: 31%;
          height: 49%;
          margin-right: 15px;
          border: 1px solid #227bff;
          // border: 1px solid #D8D8D8;
          border-radius: 10px;
          margin-bottom: 10px;

          .micleBo1 {
            margin-top: 28px;
            padding: 8px 36px;
          }

          .micleBo2 {
            padding: 0px 27px;
            font-weight: 700;
            font-size: 40px;
          }

          .micleBo3 {
            padding: 8px 35px;
          }

          .micleBo4 {
            height: 140px;
            width: 95%;
            margin: auto;
            // background-color: red;
          }
        }

        .title-item {
          width: 47%;
          height: 20px;
          padding: 12px 8px;
          overflow: hidden !important;
          /* 超出部分隐藏 */
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
        }

        .title-item:hover {
          color: #227bff;
        }
      }
    }

    .lun2 {
      height: 420px;
      width: 100%;
      display: flex;
      // background-color: skyblue;
      margin-top: 2px;

      .lun-left {
        width: 48.5%;
        height: 98.5%;
        padding: 5px;
        position: relative;
        overflow: hidden;

        .edit-box {
          width: 100%;
          height: 50%;
          flex-wrap: wrap;
          display: flex;
          margin-top: 6px;

          .edit-item {
            box-sizing: border-box;
            cursor: pointer;
            width: 33.3%;
            height: 11.2rem;
            padding: 0px 4px;
            // background-color: skyblue;
          }
        }
      }

      .lun-right {
        width: 52.8%;
        height: 98%;
        margin-left: 20px;
        padding: 5px 0px 0px 2px;

        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;

        .micleBo {
          width: 31%;
          height: 49%;
          margin-right: 15px;
          border: 1px solid #d8d8d8;
          border-radius: 10px;

          .micleBo1 {
            margin-top: 28px;
            padding: 8px 36px;
          }

          .micleBo2 {
            padding: 0px 27px;
            font-weight: 700;
            font-size: 40px;
          }

          .micleBo3 {
            padding: 8px 35px;
          }

          .micleBo4 {
            height: 100px;
            width: 95%;
            background-color: red;
          }
        }

        .consultBox {
          width: 100%;

          .six-item {
            width: 48%;
            height: 100px;
            padding: 2px 0px;
            display: flex;
            margin-top: 5px;
            margin-left: 1px;

            .six-left {
              width: 46%;
              height: 100%;
            }

            .six-right {
              width: 64%;
              height: 100%;
              padding: 2px 6px 2px 16px;

              .six-right-1 {
                height: 19px;
                padding: 4px 1px 1px 1px;
                font-weight: 900;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #000000;
                font-size: 14px;
              }

              .six-right-2 {
                height: 30px;
                padding: 2px 2px 2px 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                font-size: 12px;
                color: #000000;
              }

              .six-right-3 {
                display: flex;
                height: 15%;
                padding: 9px 0px 0px 4px;
                font-size: 12px;
                color: #999999;
              }
            }
          }
        }

        .title-item {
          width: 47%;
          height: 20px;
          padding: 12px 8px;
          overflow: hidden !important;
          /* 超出部分隐藏 */
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
        }

        .title-item:hover {
          color: #227bff;
        }
      }
    }

    .six {
      width: 100%;
      height: 374px;
      margin-top: 8px;
      padding: 8px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;

      .six-item {
        width: 48%;
        height: 33.6%;
        padding: 2px 0px;
        display: flex;
        margin-top: 5px;
        border-bottom: 1px solid #eaeaea;
        margin-left: 1px;

        .six-left {
          width: 32%;
          height: 100%;
        }

        .six-right {
          width: 64%;
          height: 100%;
          padding: 2px 6px 2px 16px;

          .six-right-1 {
            height: 19px;
            padding: 4px 1px 1px 1px;
            font-weight: 900;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: #000000;
            font-size: 14px;
          }

          .six-right-2 {
            height: 30px;
            padding: 12px 2px 2px 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 12px;
            color: #000000;
          }

          .six-right-3 {
            display: flex;
            height: 15%;
            padding: 32px 0px 0px 4px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
}

.bgc {
  color: #227bff !important;
  border-bottom: 1px solid #227bff;
}

.scroll_table {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 100%;
  // height: 900px !important;

  .table_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 582px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      > span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  .seamless-warp {
    height: 545px;
    overflow: hidden;

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }

  // .seamless-warp2 {
  //     height: 560px;
  //     // height: 82px;
  //     overflow: hidden;
  //     // background-color: rgba(230, 239, 243);

  //     ::v-deep .table_scroll .el-table__header-wrapper {
  //         display: none;
  //     }
  // }
}

.scroll_table2 {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 98.3%;
  position: absolute;
  top: 110px;
  // left: 8px;

  .table2_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 135px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      > span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  // .seamless-warp {
  //     height: 122px !important;
  //     overflow: hidden;

  //     ::v-deep .table_scroll .el-table__header-wrapper {
  //         display: none;
  //     }
  // }

  .seamless-warp2 {
    // height: 482px;
    height: 182px;
    overflow: hidden;
    // background-color: rgba(230, 239, 243);

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}

.scroll_table3 {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 98.3%;
  position: absolute;
  top: 292px;
  // left: 8px;

  .table2_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 135px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      > span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  // .seamless-warp {
  //     height: 122px !important;
  //     overflow: hidden;

  //     ::v-deep .table_scroll .el-table__header-wrapper {
  //         display: none;
  //     }
  // }

  .seamless-warp2 {
    // height: 482px;
    height: 182px;
    overflow: hidden;
    // background-color: rgba(230, 239, 243);

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}

.market {
  // margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  // align-items: flex-start;

  .line1 {
    // margin-top: 10px;
    position: relative;
    width: 446px;
    background-color: white;
    // height: 100px !important;
    // padding: 16px;
    margin-right: 30px;
    box-sizing: border-box;
    padding-bottom: 0;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        // height: 80px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      // height: 2px;
      // background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 8px;
    }
  }

  .line2 {
    width: 240px;
    background-color: white;
    padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;
    padding-bottom: 0;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        height: 80px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 5px;
      height: 480px;

      .info9 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        height: 43px;
        line-height: 43px;
        font-size: 14px !important;
        border-bottom: 1px dashed #ebeef5;
      }
    }

    .contaion :hover {
      color: #006eb7 !important;

      .info {
        border-bottom: 1px solid #006eb7;
      }
    }
  }

  .line3 {
    width: 276px;
    background-color: white;
    // padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;
    padding-bottom: 0;

    .contaion {
      padding: 3px 8px;
    }

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        height: 80px;
        overflow: hidden;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 5px;
      height: 480px;

      .info9 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        height: 43px;
        line-height: 43px;
        font-size: 14px !important;
        border-bottom: 1px dashed #ebeef5;
      }
    }

    .contaion :hover {
      color: #006eb7 !important;

      .info {
        border-bottom: 1px solid #006eb7;
      }
    }
  }
}

::v-deep .el-table__row {
  // background-color: rgb(230, 239, 243) !important;
}

::v-deep #czh .el-table__header-wrapper {
  display: none;
}

::v-deep .el-table__header-wrapper {
  border-radius: 7px 7px 0px 0px;
}

::v-deep .el-table .warning-row {
  color: #0256ff;
  font-weight: 1000;
  // background-color: rgb(223, 179, 103, 0.4);
}

::v-deep .el-table .warning-row2 {
  color: #227bff;
  font-weight: 400;
  // background-color: rgb(119, 162, 229, 0.4);
}

::v-deep #czh .el-table__row {
  // display: none;
  color: red;
}
</style>
