const quillOption = {
    toolbarOptionns: [
      ["bold", "italic", "underline", "strike"],//加粗 斜体 下划线 删除线
      ["blockquote", "code-block"],//引用 代码块
      [{ header: 1 }, { header: 2 }],//1、2 级标题
      [{ list: "ordered" }, { list: "bullet" }],//有序、无序列表
      [{ script: "sub" }, { script: "super" }],//上标/下标
      [{ indent: "-1" }, { indent: "+1" }],//缩进
      //[{'direction': 'rtl'}], // 文本方向
      [{ size: ["12px", "14px","16px","18px","20px","24px","28px","32px"] }],//字体大小
      [{ header: [1, 2, 3, 4, 5, 6, false] }],//标题
      [{ color: ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }, { background: [] }],//字体颜色、字体背景颜色
      [{
        font: ["Microsoft-YaHei",
          "SimSun",
          "SimHei",
          "KaiTi",
          "FangSong",
          "Arial",
          "Times-New-Roman",
          "sans-serif",]
      }],//字体种类
      [{ align: [] }],//对齐方式
      ["clean"],//清除文本格式
      ["link", "image", "video"]//链接、图片、视频
    ],
    titleConfig: [
      { Choice: '.ql-bold', title: '加粗' },
      { Choice: '.ql-italic', title: '斜体' },
      { Choice: '.ql-underline', title: '下划线' },
      { Choice: '.ql-header', title: '段落格式' },
      { Choice: '.ql-strike', title: '删除线' },
      { Choice: '.ql-blockquote', title: '块引用' },
      { Choice: '.ql-code', title: '插入代码' },
      { Choice: '.ql-code-block', title: '插入代码段' },
      { Choice: '.ql-font', title: '字体' },
      { Choice: '.ql-size', title: '字体大小' },
      { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
      { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
      { Choice: '.ql-direction', title: '文本方向' },
      { Choice: '.ql-header[value="1"]', title: 'h1标题' },
      { Choice: '.ql-header[value="2"]', title: 'h2标题' },
      { Choice: '.ql-align', title: '对齐方式' },
      { Choice: '.ql-color', title: '字体颜色' },
      { Choice: '.ql-background', title: '背景颜色' },
      { Choice: '.ql-image', title: '图像' },
      { Choice: '.ql-video', title: '视频' },
      { Choice: '.ql-link', title: '添加链接' },
      { Choice: '.ql-formula', title: '插入公式' },
      { Choice: '.ql-clean', title: '清除字体格式' },
      { Choice: '.ql-script[value="sub"]', title: '下标' },
      { Choice: '.ql-script[value="super"]', title: '上标' },
      { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
      { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
      { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
      { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
      { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
      { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
      { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
      { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
      { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
      { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
      { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
      { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
      { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
      { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
      { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
    ]
  }
  export default quillOption
