<template>
    <div class="contain">
        <div class="loginBox" :class="{ 'heights': isRegister == 1 }">
            <div class="box"></div>

            <el-image style="width: 200px; margin-top: 32px; margin-left: 60px;" :src="require('../assets/theLogo.png')"
                :fit="fit">
            </el-image>

            <div class="loginContent" v-if="isRegister == 0
            ">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="短信登录" name="first">
                        <div class="loginInfo">
                            <div class="title">
                                手机号
                            </div>
                            <el-input placeholder="请输入手机号" v-model="phone1" style="width: 430px;"></el-input>
                        </div>

                        <div class="loginInfo">
                            <div class="title">
                                验证码
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <el-input placeholder="请输入验证码" v-model="registerVerification" show-password
                                    style="width: 313px;"></el-input>

                                <div class="getVerification" @click="loginSendCode(2)">
                                    获取验证码
                                </div>
                            </div>
                        </div>

                        <div class="btn" @click="loginMobileCheck">
                            <span>登</span>
                            <span>录</span>
                            <img src="../assets/login/a.png" alt="">
                        </div>

                        <div class="register" @click="checkOutRegister">注册用户</div>
                    </el-tab-pane>


                    <el-tab-pane label="密码登录" name="second">
                        <div class="loginInfo">
                            <div class="title">
                                手机号
                            </div>
                            <el-input placeholder="请输入手机号" v-model="loginPhone" style="width: 430px;"></el-input>
                        </div>

                        <div class="loginInfo">
                            <div class="title">
                                密码
                            </div>
                            <div class="forget">忘记密码</div>
                            <el-input placeholder="请输入密码" v-model="loginPwd" show-password
                                style="width: 430px;"></el-input>
                        </div>

                        <div class="btn" @click="loginMobilePassCheck">
                            <span>登</span>
                            <span>录</span>
                            <img src="../assets/login/a.png" alt="">
                        </div>

                        <div class="register" @click="checkOutRegister">注册用户</div>
                    </el-tab-pane>
                </el-tabs>


            </div>

            <div class="loginContent" v-if="isRegister
            == 1">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="注册" name="first">

                        <div class="loginInfo">
                            <div class="title">
                                用户名
                            </div>
                            <el-input placeholder="请输入用户名" v-model="names" style="width: 430px;"></el-input>
                        </div>

                        <div class="loginInfo">
                            <div class="title">
                                手机号
                            </div>
                            <el-input placeholder="请输入手机号" v-model="registerPhone" style="width: 430px;"></el-input>
                        </div>

                        <div class="loginInfo">
                            <div class="title">
                                密码
                            </div>
                            <el-input type="password" placeholder="请输入密码" v-model="registerPwd"
                                style="width: 430px;"></el-input>
                        </div>

                        <div class="loginInfo">
                            <div class="title">
                                验证码
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <el-input placeholder="请输入验证码" v-model="registerVerification" show-password
                                    style="width: 313px;"></el-input>

                                <div class="getVerification" @click="loginSendCode(1)">
                                    获取验证码
                                </div>
                            </div>

                        </div>

                        <div class="btn" @click="registerInfo">
                            <span>注</span>
                            <span>册</span>
                            <img src="../assets/login/a.png" alt="">
                        </div>

                        <div class="register" @click="backLogin">返回登录</div>
                    </el-tab-pane>

                </el-tabs>


            </div>
        </div>

        <el-image style="width: 956px; height: 100vh;  margin-left: 202.5px;" :src="webConfig.login_home" :fit="fit">
        </el-image>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'first',
            phone1: '',
            verification: '',
            phone1: '',
            pw: '',
            isRegister: '1',
            //注册数据
            registerPhone: '',
            registerPwd: '',
            registerVerification: '',
            //登录数据
            loginPhone: '',
            loginPwd: '',
            names: '',
            webConfig: ''
        };
    },
    methods: {
        handleClick(e) {
            console.log(e)
        },
        checkOutRegister() {
            console.log('123')
            this.isRegister = '1'
            this.activeName = 'first'
        },
        backLogin() {
            this.isRegister = '0'
        },


        //登录接口
        async loginMobileCheck() {
            if (this.loginPhone.length < 11 || this.loginPhone.length > 11 || isNaN(this.loginPhone)) {
                this.$message.error('请输入正确的手机号码');
                return
            }
            let res = await this.$http('/mini/ApiConnect/loginMobileCheck', { mobile: this.loginPhone })

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                window.localStorage.setItem('user_id', res.data.user_id)

                setTimeout(() => {
                    window.location = '/'

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }
        },
        async loginMobilePassCheck() {
            if (this.loginPhone.length < 11 || this.loginPhone.length > 11 || isNaN(this.loginPhone)) {
                this.$message.error('请输入正确的手机号码');
                return
            }
            let res = await this.$http('/mini/ApiConnect/loginMobilePassCheck', { mobile: this.loginPhone, password: this.loginPwd })

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                window.localStorage.setItem('user_id', res.data.user_id)

                setTimeout(() => {
                    window.location = '/'

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }
        },

        //注册接口
        async registerInfo() {
            if (this.registerPhone.length < 11 || this.registerPhone.length > 11 || isNaN(this.registerPhone)) {
                this.$message.error('请输入正确的手机号码');
                return
            }
            let res = await this.$http('/mini/ApiConnect/registerInfo', { mobile: this.registerPhone, password: this.registerPwd, real_name: this.names })

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.backLogin()
            } else {
                this.$message.error(res.msg);
            }
        },

        //验证码接口
        async loginSendCode(num) {
            if (num == 1) {
                let res = await this.$http('/mini/ApiConnect/loginSendCode', { mobile: this.registerPhone, type: 1 })

                if (res.status == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    // this.backLogin()
                } else {
                    this.$message.error(res.msg);

                }
                console.log('验证码', res)
            } else {
                let res = await this.$http('/mini/ApiConnect/loginSendCode', { mobile: this.phone1, type: 2 })

                if (res.status == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    // this.backLogin()
                } else {
                    this.$message.error(res.msg);
                }

                console.log('验证码', res)
            }
        },
        //公共接口
        async WebConfig() {
            let res = await this.$http('/mini/ApiConnect/WebConfig')

            this.webConfig = res.result
        },
    },

    mounted() {
        // this.WebConfig()
    },

    watch: {

        $route(to, from) {
            console.log('133')
            this.$router.go(0);

        }

    }

}
</script>

<style lang="scss" scoped>
.box {
    width: 1000px;
    height: 50px;
    background-color: #F2F2F2;
    position: absolute;
    top: -18px;
    left: 0;
}

.contain {
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #F2F2F2;
    margin-top: -29px;
    display: flex;
    flex-direction: row;
}

.loginBox {
    width: 568px;
    height: 550px;
    background-color: white;
    margin-top: 10%;
    margin-left: 10%;
    box-shadow: 0 19px 40px #c4c3c3;
    border-radius: 20px;
}

@media screen and (max-width: 1400px) {
    .loginBox {
        width: 568px;
        height: 520px;
        background-color: white;
        margin-top: 7%;
        margin-left: 10%;
        box-shadow: 0 19px 40px #c4c3c3;
        border-radius: 20px;
    }
}

.heights {
    margin-top: 5%;
    height: 700px;
}

@media screen and (max-width: 1400px) {
    .heights {
        margin-top: 2%;
        height: 672px;
        margin-bottom: 50px;
    }
}

::v-deep .el-tabs__item {
    width: 205px !important;
    height: 53px !important;
    font-size: 40px !important;
    color: #000000;
}

::v-deep .el-input__inner {
    background-color: #EFF8FE;
    border: none !important;
}

::v-deep .el-tabs__item.is-active {
    color: #006EB7 !important;
}

::v-deep .el-tabs__active-bar {
    background-color: #006EB7;
    width: 162px !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background: none;
}

.loginContent {
    margin-top: 30px;
    margin-left: 61px;

    .loginInfo {
        width: 478px;
        margin-bottom: 15px;
        margin-top: 13px;

        .getVerification {
            width: 120px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background-color: #006EB7;
            color: white;
            cursor: pointer;
        }

        .title {
            margin-bottom: 8px;
        }

        .el-input__inner {
            background-color: #EFF8FE !important;
        }


    }

    .btn {
        width: 150px;
        height: 46px;
        border-radius: 23px;
        background-color: #006eb7;
        text-align: center;
        line-height: 46px;
        color: white;
        margin-left: 145px;
        margin-top: 40px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0 10px;
        box-sizing: border-box;
        align-items: center;
    }

    .register {
        margin-left: 188px;
        margin-top: 23px;
        color: #00000033;
        cursor: pointer;
    }

    .forget {
        position: absolute;
        right: 53px;
        top: 114px;
        font-size: 14px;
        color: #000000;
        opacity: 0.5;
    }
}

@media screen and (max-width: 1400px) {
    .loginContent {
        margin-top: 27px;
        margin-left: 41px;
    }
}
</style>