<template>
    <div>
        <div style="height: 114vh;width: 100vw;background-color: white;">
            <div>
                可提现金额：xxx
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {},
    created() { },
    mounted() { }
}
</script>

<style lang=scss></style>