<template>
    <div class="content">
        <div class="logoBox flexRowCenter">
            <div class="logo">
                <img src="../assets/theLogo.png" style="width: 150px; height: 44px;">

                <div class="btnBox">
                    <div class="btn" @click="marketChekout(1)" :class="{ 'btnBgc': marketChekoutFlag == 1 }">
                        买家中心
                    </div>
                    <div class="btn" @click="marketChekout(2)" :class="{ 'btnBgc': marketChekoutFlag == 2 }">
                        卖家中心
                    </div>
                </div>
            </div>
        </div>

        <div class="contain1 flexColCenter">
            <div class="location">
                <span>当前位置：</span>
                <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/buyerCenter' }">个人中心</el-breadcrumb-item>
                    </el-breadcrumb></div>
            </div>

            <div class="master">
                <div class="name">
                    <span v-if="real_name != 'undefined'"> {{ this.real_name }} </span>

                </div>
                <div id="chatTxt" class="chatTxt" ref="chatTxt22" @click="tapTest">
                    <div class="left" v-for="(item, index) in messageList"
                        :style="item.isRight == 1 ? ' justify-content:  flex-start;flex-direction: row-reverse' : ''">
                        <div class="clientName" v-if="item.isRight == 1">我</div>
                        <div class="clientName" v-if="item.isRight == 0">对方</div>
                        <div class="mesBox">
                            <div class="time"
                                style="margin-left: 20px; margin-right: 20px; text-align: right; font-size: 12px; color: #DDDDDD;margin-bottom: 5px;">
                                {{ item.time }}</div>
                            <span class="info" :class="{ 'bgc': item.isRight == 1 }" v-if="item.text[0]">{{
                        item.text[0].content }}</span>
                            <span v-if="item.img">
                                <img :src="item.img" style="width: 100px;  margin-left: 20px; margin-right: 20px;"
                                    alt="" @click="preview(item.img)">
                            </span>
                        </div>


                    </div>
                </div>

                <div class="biaoqingBar">
                    <img src="../assets/buyerCenter/emoji.png" style="cursor: pointer;" alt="" @click="emojiBtn2">

                    <el-upload class="avatar-uploader" action="https://zhnl.ydlweb.com/mini/ApiConnect/upload"
                        :before-upload="beforeAvatarUpload" :show-file-list="false" :on-success="handleAvatarSuccess"
                        :data="importData" name="file">
                        <img src="../assets/buyerCenter/pic.png" style="cursor: pointer; padding-top: 5px;" alt="">
                    </el-upload>



                    <div class="emojiBox" v-if="emojiFlag == 1">
                        <span v-for="(item, index) in emojis" style="cursor: pointer; padding:5px;"
                            @click="emojiBtn(index)">{{
                        item }}</span>
                    </div>
                </div>

                <div class="messageContain">
                    <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="message" resize="none">
                    </el-input>
                </div>

                <div class="send" @click="sendMsg">
                    发送
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import buyerLeftBar from '../components/buyerLeftBar.vue'
import buyerLeftBar2 from '../components/buyerLeftBar2.vue'
import getParams from '../utils/getParams'


export default {
    data() {
        return {
            marketChekoutFlag: 1,
            messageList: [{ identity: 'client', message: '你好' }, { identity: 'user', message: '在的' }],
            message: '',
            //传递过来的数据
            admin_id: '',
            goods_id: '',
            meetingId: '',

            emojiFlag: '0',
            importData: {},
            real_name: '',
            emojis: [
                '😠',
                '😩',
                '😲',
                '😃',
                '😞',
                '😵',
                '😰',
                '😒',
                '😍',
                '😤',
                '😜',
                '😝',
                '😋',
                '😘',
                '😚',
                '😷',
                '😳',
                '😃',
                '😅',
                '😆',
                '😁',
                '😂',
                '😊',
                '☺',
                '😄',
                '😢',
                '😭',
                '😨',
                '😣',
                '😡',
                '😌',
                '😖',
                '😔',
                '😱',
                '😪',
                '😏',
                '😓',
                '😥',
                '😫',
                '😉'
            ],
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            console.log('993', res)
            if (res.status == 1) {
                this.imgSrc = res.result.showUrl
                this.sendMessage2()
                setTimeout(() => {
                    this.getMessage()
                    this.message = ''

                }, 500);
            }
        },
        beforeAvatarUpload(file) {
            this.importData.file = file
            this.importData.module = 'company_license'

        },

        scrollBottom() {
            let dv = document.getElementById('chatTxt')
            dv.scrollTop = dv.scrollHeight
            console.log('scrollBottom', dv.scrollHeight)
        },

        marketChekout(num) {
            this.marketChekoutFlag = num
            console.log(this.marketChekoutFlag)
            if (this.marketChekoutFlag == 1) {
                this.$router.push({ path: '/buyerCenter/message' })

            } else {
                this.$router.push({ path: '/buyerCenter/home' })

            }
        },

        emojiBtn(index) {
            this.emojiFlag = 0
            console.log('emojiBtn', this.emojis[index])
            this.message += this.emojis[index]
        },

        emojiBtn2() {
            if (this.emojiFlag == 0) {
                this.emojiFlag = 1
            } else {
                this.emojiFlag = 0
            }
        },

        tapTest() {
            this.scrollBottom()
        },

        sendMsg() {
            this.sendMessage()
            setTimeout(() => {
                this.getMessage()
                this.message = ''
            }, 500);
        },
        preview(img) {
            window.open(img)
        },
        //询盘用户商品
        async contactUserTa() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                user_id: userId,
                goods_id: this.goods_id
            }

            let res = await this.$http('/mini/ImChat/contactUserTa', params)
            this.meetingId = res.result.meetingId
            this.getMessage()
        },

        //发送信息
        async sendMessage() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                meeting_id: this.meetingId,
                // admin_id: this.admin_id,
                user_id: userId,
                text: [{ type: 1, content: this.message }]
            }
            this.scrollBottom()
            let res = await this.$http('/mini/ImChat/sendMessage', params)
        },

        //发送图片
        async sendMessage2() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                meeting_id: this.meetingId,
                // admin_id: this.admin_id,
                user_id: userId,
                text: [{ type: 3, content: this.imgSrc }]
            }
            this.scrollBottom()
            let res = await this.$http('/mini/ImChat/sendMessage', params)
        },

        //注册会话服务
        async imRegister(client_id) {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                client_type: 2,
                client_id: client_id,
                user_id: userId
            }

            let res = await this.$http('/mini/ImChat/imRegister', params)
        },


        //会话框信息
        async getMessage() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                type: 2,
                admin_id: this.admin_id,
                user_id: userId,
                meeting_id: this.meetingId,
                message_id: '',
                page: 1,
                size: 10
            }

            let res = await this.$http('/mini/ImChat/getMessage', params)

            this.messageList = res.result.row

            setTimeout(() => {
                this.scrollBottom()
            }, 500)
        },


        initWebSocket() {
            let _this = this;
            //判断页面有没有存在websocket连接
            if (window.WebSocket) {

                let ws = new WebSocket("wss://zhnl.ydlweb.com:8787");
                _this.ws = ws;
                ws.onopen = function (e) {
                    console.log("服务器连接成功", e);
                };
                ws.onclose = function (e) {
                    console.log("服务器连接关闭");
                };
                ws.onerror = function () {
                    console.log("服务器连接出错");
                };
                ws.onmessage = function (e) {
                    //接收服务器返回的数据
                    let data = JSON.parse(e.data);
                    console.log('成功', e)
                    switch (data.route) {
                        case 'new_message': // 返回的数据类型（表示有新消息）
                            //接到新消息时，在消息列表页面，刷新消息列表
                            setTimeout(() => {
                                console.log('new_message')
                                _this.getMessage()

                                // TODO: 刷新列表数据
                                // this.form.page = 1;
                                // this.list = [];
                                // this.getMessages();
                            }, 1000);
                            break;
                        case 'client_id': // 返回的数据类型 (登录成功)
                            _this.imRegister(data.result.client_id)
                            break;
                    }
                };
            }
        }
    },

    mounted() {
        let userId = window.localStorage.getItem('user_id')
        this.importData.user_id = userId
        this.admin_id = getParams().admin_id
        this.goods_id = getParams().goods_id
        this.real_name = decodeURI(getParams().real_name)
        if (getParams().meeting_id) {
            this.meetingId = getParams().meeting_id
            setTimeout(() => {
                this.getMessage()
                setTimeout(() => {
                    this.scrollBottom()
                }, 500)
            }, 1000)

        } else {
            this.contactUserTa()
        }
        this.initWebSocket()
    },

    components: {
        buyerLeftBar,
        buyerLeftBar2

    }
}
</script>

<style lang="scss" scoped>
.logoBox {
    height: 80px;
    background-color: white;

    .logo {
        width: 1200px;
        display: flex;
        align-items: center;
    }

    .btnBox {
        display: flex;
        align-items: center;
        margin-left: 52px;

        .btn {
            margin-right: 35px;
            width: 120px;
            height: 36px;
            border-radius: 18px;
            border: 1px solid #006eb7;
            color: #006eb7;
            box-sizing: border-box;
            text-align: center;
            line-height: 36px;
            font-size: 14px;
            cursor: pointer;
        }

        .btnBgc {
            background-color: #006EB7 !important;
            color: white !important;
            border: none !important;
        }
    }
}

.contain1 {}

.location {
    width: 1200px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .nav {
        margin-top: 5px;
    }
}

.master {
    width: 1200px;
    height: 708px;
    background-color: white;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    .name {
        padding: 20px 0;
        font-size: 20px;
        border-bottom: 1px solid #F2F2F2;
    }

    .chatTxt {
        margin-top: 30px;
        height: 55%;
        padding: 0 20px;
        box-sizing: border-box;
        overflow-y: scroll;

        .left {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            // justify-content: flex-end;
            .info {
                display: inline-block;
                padding: 10px;
                background-color: rgba(172, 255, 47, 0.795);
                margin-left: 20px;
                margin-right: 20px;
                border-radius: 10px;
                text-align: center;
            }

            .bgc {
                background-color: rgba(47, 196, 255, 0.795) !important;

            }
        }
    }

    .biaoqingBar {
        border-top: 1px solid #F2F2F2;
        padding-top: 10px;
        position: relative;
        display: flex;
        align-items: center;

        .emojiBox {
            font-size: 15px;
            display: flex;
            flex-wrap: wrap;
            width: 330px;
            padding: 10px;
            border: 1px solid #d3d1d1;
            position: absolute;
            background-color: white;
            top: -235px;

            span {
                display: inline-block;
                width: 30px;
                height: 30px;

            }
        }
    }

    .messageContain {
        margin-top: 10px;
    }

    .send {
        width: 94px;
        height: 42px;
        background: #006eb7;
        text-align: center;
        line-height: 42px;
        color: white;
        border-radius: 10px;
        position: absolute;
        right: 19px;
        bottom: -23px;
        margin-bottom: 30px;
        margin-top: 20px;
        cursor: pointer;
    }
}
</style>