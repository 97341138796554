import { render, staticRenderFns } from "./informationBox3.vue?vue&type=template&id=59ef3c5c&scoped=true"
import script from "./informationBox3.vue?vue&type=script&lang=js"
export * from "./informationBox3.vue?vue&type=script&lang=js"
import style0 from "./informationBox3.vue?vue&type=style&index=0&id=59ef3c5c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ef3c5c",
  null
  
)

export default component.exports