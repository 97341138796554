import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './modules/userStore'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    age:12
  },
  getters: {
  },
  mutations: {
    Login(){
      console.log('出发了');
    }
  },
  actions: {
  },
  modules: {
    userStore
  }
})
