<template>
    <div>


        <div class="bottom">
            <!-- <div class="line1">
                <div class="title">消息通知</div>
                <div style="height: 661px; overflow-y: scroll;">
                    <div class="title2" v-for="(item, index) in msgList"
                        @click="skipPage('/chat?meeting_id=' + item.interview_id + '&real_name=' + item.real_name) + '&admin_id=' + item.user_id">
                        <img :src="item.head_pic"
                            style="width: 52px; height: 52px; margin-left: 12px; border-radius: 300px;">
                        <div class="txtBox">
                            <div class="name">{{ item.real_name }}</div>
                            <div class="mes">
                                {{ item.message ? item.message[0].content : '' }}
                            </div>

                        </div>
                        <div class="time">
                            {{ item.last_time }}
                        </div>
                    </div>
                </div>

            </div> -->

            <div class="line1">
                <div class="title" style="font-weight:700">消息通知</div>
                <div class="topBtn">
                    <div>
                        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    </div>
                    <div>
                        <el-button type="primary" size="small" @click="readAll" round>批量已读</el-button>
                        <el-button type="primary" size="small" @click="readAll2" round>全部已读</el-button>
                        <el-button type="primary" size="small"
                            style="background:#fff; color:#006EB7;border:1px solid #006EB7; " @click="delAll" plain
                            round>批量删除</el-button>
                    </div>
                </div>

                <div class="title2" v-for="(item, index) in msgList">
                    <div>
                        <el-checkbox @change="handleCheckedCitiesChange(index)"
                            v-model="checkedCities[index]"></el-checkbox>
                    </div>
                    <div class="title2" style="border:0">
                        <!-- @click="skipPage('/chat?meeting_id=' + item.interview_id + '&real_name=' + item.real_name) + '&admin_id=' + item.user_id" -->
                        <img :src="item.headPic"
                            style="width: 52px; height: 52px; margin-left: 12px; border-radius: 300px;">
                        <div class="txtBox">
                            <div class="name">{{ item.realName }}</div>
                            <div class="mes">
                                {{ item.msgContent }}
                            </div>

                        </div>
                        <div class="time">
                            <div> {{ item.addTime }}</div>
                            <div v-if="item.isRead == 1"> 已读</div>
                            <div v-if="item.isRead == 0" style="color: red;">未读 </div>
                        </div>

                    </div>
                    <!-- <div class="delBtn" @click="delBtn(item.interview_id)" style="width: 35px;font-size: 14px;">删除</div> -->
                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-sizes="[10, 15]" style="margin: auto;width: 30vw;margin-top: 40px;margin-bottom: 20px;"
                    :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="allNumber">
                </el-pagination>


            </div>
        </div>
    </div>
</template>

<script>
import { getUserMsg, UpdateMsgRead, deleteMsgById, UpdateMsgRead1 } from '@/api/user'
import axios from 'axios';

export default {
    data() {
        return {
            msgList: '',
            baseUrl: 'https://zhnl.ydlweb.com',
            checkAll: false,
            checkedCities: [],
            allNumber: '',
            type: 1,//企业 用户端
            page: 1,
            size: 10
        }
    },

    methods: {    // 已读
        handleSizeChange(e) {
            this.checkedCities.forEach((item, index) => {
                this.$set(this.checkedCities, index, false);
            })
            this.checkedCities = []

            this.checkAll = false
            console.log(e, 'e1');
            this.size = e
            let params = {
                msg_type: 1,
                is_read: '',
                page: this.page,
                size: this.size,
            }
            getUserMsg(params).then((res) => {
                console.log(res, '--');
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
                this.msgList = res.data.data
                this.msgList.forEach((item) => {
                    this.checkedCities.push(false)
                })

            })
        },
        handleCurrentChange(e) {
            this.checkedCities.forEach((item, index) => {
                this.$set(this.checkedCities, index, false);
            })
            this.checkAll = false
            this.checkedCities = []
            this.page = e
            let params = {
                msg_type: 1,
                is_read: '',
                page: this.page,
                size: this.size,
            }
            getUserMsg(params).then((res) => {
                console.log(res, '--');
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
                this.msgList = res.data.data
                this.msgList.forEach((item) => {
                    this.checkedCities.push(false)
                })

            })
            console.log(e, 'e2');
        },
        readAll2() {
            UpdateMsgRead1({}).then((res) => {
                if (res.data.code == 200) {
                    let params2 = {
                        msg_type: 1,
                        is_read: 0,
                        page: 1,
                        size: 100000,
                    }
                    getUserMsg(params2).then((res) => {
                        this.$eventBus.$emit('eventName', res.data.totalPage);
                    })
                    this.$message({
                        type: 'success',
                        message: '已读成功'
                    })
                    this.checkAll = false
                    this.checkedCities = []

                    this.getUserMeeting()
                }
            })
        },
        readAll() {
            this.checkAll = false
            let userId = window.localStorage.getItem('user_id')
            let interview_id = ''
            this.checkedCities.forEach((item, i) => {
                console.log(item, 'itemjia--');
                console.log(this.msgList, 'this.msgList');
                if (item) {
                    interview_id += this.msgList[i].umId + ','
                    console.log(this.msgList[i].umId, 'this.msgList[i].umId');
                }
            })
            var numbersAsArray = interview_id.split(",").map(Number)
            let params = {
                msg_id: numbersAsArray,
                // admin_id: this.admin_id,
                // user_id: userId,
                // type: 1
            }
            UpdateMsgRead(params).then((res) => {
                if (res.data.code == 200) {

                    this.$message({
                        type: 'success',
                        message: '已读成功'
                    })
                    this.checkedCities = []
                    this.getUserMeeting()
                }
            })
            let params2 = {
                msg_type: 1,
                is_read: 0,
                page: 1,
                size: 100000,
            }
            getUserMsg(params2).then((res) => {
                this.$eventBus.$emit('eventName', res.data.totalPage);

            })

            // this.$http('/mini/ImChat/readAll', params).then(res => {
            //     this.$message({
            //         message: '已读成功',
            //         type: 'success'
            //     });
            // }).cath(err => {
            //     console.log(err);
            // })

        },
        // 批量删除
        delAll() {
            this.checkAll = false
            let interview_id = ''
            this.checkedCities.forEach((item, i) => {
                if (item) {
                    interview_id += this.msgList[i].umId + ','
                }
            })


            console.log(interview_id, 'interview_id');
            var numbersAsArray2 = interview_id.split(",").map(Number)
            console.log(numbersAsArray2, 'numbersAsArray2');
            let params = {
                msg_id: numbersAsArray2,
            }

            deleteMsgById(params).then((res) => {
                console.log(res, -'res,,,,');
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.checkedCities = []
                    this.getUserMeeting()
                }
            })
            // this.$http('/mini/ImChat/del', params).then(res => {
            //     if (res.stutas == 1) {
            //         this.$message({
            //             message: '删除成功',
            //             type: 'success'
            //         })
            //     } else {
            //         this.$message({
            //             message: '会话消息不存在',
            //             type: 'error'
            //         })
            //     }
            // }).cath(err => {
            //     console.log(err);
            // })

        },
        // 删除
        delBtn(val) {
            let params = {
                meeting_id: val,
            }
            this.$http('/mini/ImChat/del', params).then(res => {
                console.log(res);
            }).cath(err => {
                console.log(err);
            })
        },
        // 全选
        handleCheckAllChange(val) {
            // this.checkedCities = []
            // let arr = [true, false, true]
            // let b = arr.map((res) => {
            //     return true
            // })
            // console.log(b, 'arr--');
            // console.log(val, 'val');
            console.log(val, 'val-----');
            console.log(this.checkedCities, 'this.checkedCities');
            if (val == false) {
                this.checkedCities.forEach((item, index) => {
                    this.$set(this.checkedCities, index, false);
                })
            } else {
                this.checkedCities.forEach((item, index) => {
                    this.$set(this.checkedCities, index, true);
                }
                )
                // let a = this.checkedCities.map(item => val);
                // this.checkedCities = a;
            }

        },
        // 多选
        handleCheckedCitiesChange(val) {
            console.log(val, this.checkedCities[val]);
            this.checkedCities.indexOf(false) == -1 ? this.checkAll = true : this.checkAll = false
        },
        skipPage(page) {
            window.open(page)
        },
        //发送信息
        async getUserMeeting() {
            let params = {
                msg_type: 1,
                is_read: '',
                page: this.page,
                size: this.size,
            }
            getUserMsg(params).then((res) => {
                console.log(res, '--');
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
                this.msgList = res.data.data
                this.msgList.forEach((item) => {
                    this.checkedCities.push(false)
                })

            })
            // let userId = window.localStorage.getItem('user_id')

            // let params = {
            //     meetingType: 1,
            //     // admin_id: this.admin_id,
            //     user_id: userId,
            // }

            // let res = await this.$http('/mini/ImChat/getUserMeeting', params)

            // this.msgList = res.result.row
            // this.msgList.forEach((e, index) => {
            //     e.head_pic = this.baseUrl + e.head_pic
            // })
        },
    },

    mounted() {

        this.getUserMeeting()
    }
}
</script>

<style lang="scss" scoped>
.top {
    width: 970px;
    height: 235px;
    background-image: url('../assets/buyerCenter/Rectangle.png');

    .left {
        width: 330px;
        border: 235px solid;
        border-right: 120px solid;
        border-left: 11px solid;
        border-color: white;
        font-size: 26px;
        position: relative;
        // margin: 20px 0;
        color: #FFFFFF;
        line-height: 50px;
        border-top: none;
        border-right-color: transparent;
        filter: drop-shadow(4px 0 13px #00000040);

        .photoBox {
            position: absolute;
            left: 35px;
            top: 32px;
            width: 100%;
            display: flex;
            // justify-content: space-between;

            .leftInfo {
                // display: flex;
                width: 120px;
            }

            .bottomTxt {
                font-weight: 400;
                font-size: 20px;
                line-height: 23.4px;
                text-align: center;
                color: #006eb7;
            }

            .rightInfo {
                font-weight: 400;
                font-size: 20px;
                line-height: 32.4px;
                text-align: left;
                color: #006eb7;
                margin-top: 14px;
                margin-left: 23px;
            }

            .rightInfo div {
                font-size: 16px;
            }
        }
    }

}

.line1 {
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 7px;
    box-sizing: border-box;
    // margin-top: 25px;
    box-shadow: 0 4px 4px #00000014;
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
    cursor: pointer;

    .topBtn {
        width: 98% !important;

        margin: 0 auto;
        box-sizing: border-box;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dddddd;
    }

    .title {
        width: 100%;
        margin: 0 auto;
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .title2 {
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #d3d2d2;
        line-height: 80px;
        margin: 0 auto;
        padding-left: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;

        .txtBox {
            display: flex;
            flex-direction: column;
            margin-left: 19px;

            flex-wrap: wrap;

            .name {
                height: 20px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18.72px;
                text-align: left;
                color: #000;
            }

            .mes {
                font-weight: 400;
                font-size: 12px;
                line-height: 18.72px;
                text-align: left;
                color: #aeaeae;
                flex-wrap: wrap;
                width: 850px;
            }
        }

        .time {
            font-weight: 400;
            font-size: 12px;
            line-height: 18.72px;
            text-align: left;
            color: #aeaeae;
            position: absolute;
            right: 35px;
        }
    }

}

.bottom {
    // height: 750px;
}
</style>