<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                帮助管理
            </div>

            <div class="line1">
                <div style="padding: 10px; box-sizing: border-box; background-color: white;">
                    <div class="titleBox">
                        <div class="title">
                            {{detail.title}}
                        </div>
                        <div class="date">
                            发布时间：{{ detail.time }}&nbsp;编辑：{{ detail.author }}
                        </div>
                    </div>

                    <div class="info" v-html="detail.content">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'

export default {
    data() {
        return {
            activeName: 'first',
            detail: ''
        }
    },
    methods: {
       //帮助管理详情
       async IndustryInformationDetail(id) {
            let res = await this.$http('/mini/ApiConnect/IndustryInformationDetail', { 'article_id': id })
            this.detail = res.result
            this.detail.content = res.result.content.replaceAll('img', 'img style="width: 100%"')
        },
    },
    mounted() {
        let id = getParams().article_id
        this.IndustryInformationDetail(id)
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }
    }

}


.titleBox {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #c5c5c5;
            padding-bottom: 13px;

            .title {
                font-size: 28px;
                text-align: left;
                color: #000;
                margin-top: 16px;
            }

            .date {
                font-size: 16px;
                text-align: left;
                color: #979797;
                margin-top: 12px;
            }
        }

        .info {
            color: #515151ff;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0;
            padding: 0 25px;
            box-sizing: border-box;
            margin-top: 10px;
        }
</style>