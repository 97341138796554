var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content-item"},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-weight":"900","font-size":"18px"}},[_vm._v("使用指南")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"},on:{"click":_vm.toRegister}},[_vm._v("注册账号")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"},on:{"click":_vm.enterInfo}},[_vm._v("企业认证")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-left",staticStyle:{"padding-right":"90px"}},[_c('div',{staticStyle:{"padding-top":"43px"}}),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-weight":"900","font-size":"18px"}},[_vm._v("交易指南")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("交易流程")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("支付方式")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("合同签署")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("发票说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-weight":"900","font-size":"18px"}},[_vm._v("服务中心")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("售后服务")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("积分规则")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("常见问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-weight":"900","font-size":"18px"}},[_vm._v("平台规则")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("注册服务协议")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("商户入住协议")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("免责声明")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("隐私政策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-weight":"900","font-size":"18px"}},[_vm._v("关于我们")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("平台介绍")]),_c('div',{staticStyle:{"padding":"10px","color":"gray"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-item"},[_c('div'),_c('div',{staticStyle:{"color":"white"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","text-align":"center","background-color":"rgb(63, 63, 63)","color":"white"}},[_c('div',{staticStyle:{"padding":"15px 0px"}},[_c('img',{staticStyle:{"padding-right":"3px","vertical-align":"middle"},attrs:{"src":require("../assets/zhnlImg/image 12@1x.png"),"alt":""}}),_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" 浙公网安备 "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("浙ICP备2023048993号")]),_vm._v(" | Copyright © 2022 杭州搜原电子商务有限公司 zhnl.com 版权所有 | 中海南联原材料商城 ")])]),_c('div',{staticStyle:{"padding":"15px 0px"}},[_vm._v("公司地址：广州市天河区黄埔大道西76号809室810房")]),_c('div',{staticStyle:{"padding":"15px 0px"}},[_vm._v("危险化学品经营许可证 | 经营证照")])])
}]

export { render, staticRenderFns }