<template>
    <div class="search">
        <div class="search-input">
            <div class="search-input-left">
                <img src="../assets/newImg3/Vector@1x (9).png"
                    style="  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%); /* 偏移自身宽高的50% */  max-width: 100%;   max-height: 100%;  "
                    alt="">
            </div>
            <div class="search-input-middle">
                <input v-model="input" placeholder="请输入内容" style="height: 54px;" @keydown.enter="toSearch"
                    class="custom-button"></input>
            </div>
            <div class="search-input-right">
                <el-button type="primary" style="margin-top: 6px;" @click="toSearch">搜索</el-button>
            </div>
        </div>
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="视频" name="first">
                    <div class="tabs2">
                        <div class="tabs2-item" @click="changeVideo(null)" :class="{ bgc: videoShow == null, }">综合排序
                        </div>
                        <div class="tabs2-item" @click="changeVideo(1)" :class="{ bgc: videoShow == 1, }">最多播放</div>
                        <div class="tabs2-item" @click="changeVideo(2)" :class="{ bgc: videoShow == 2, }">最新发布</div>
                        <div class="tabs2-item" @click="changeVideo(3)" :class="{ bgc: videoShow == 3, }">最多收藏</div>
                    </div>
                    <div class="video">
                        <div class="edit-box">
                            
                            <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
                                <div style="position: relative;">
                                    <div
                                        style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;">
                                        {{item.vAddTime.slice(0, 10) }}
                                    </div>
                                    <img :src="item.vImgUrl"
                                        style="width: 100%;height: 116px;object-fit: cover;border-radius: 12px;" alt="">
                                </div>
                                <div
                                    style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                    {{ item.vTitle }}</div>
                                <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                                    <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">

                                    <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <el-pagination v-model:current-page="page" v-model:page-size="size"
                        style="margin-left: 30%;margin-top: 30px;" :page-sizes="[21, 28]"
                        layout="total, sizes, prev, pager, next, jumper" :total="totalPage"
                        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </el-tab-pane>
                <el-tab-pane label="资讯" name="second">
                    <div class="tabs2">
                        <div class="tabs2-item" @click="changeConsult(null)" :class="{ bgc: consultShow == null, }">综合排序
                        </div>
                        <div class="tabs2-item" @click="changeConsult(1)" :class="{ bgc: consultShow == 1, }">最多播放</div>
                        <div class="tabs2-item" @click="changeConsult(2)" :class="{ bgc: consultShow == 2, }">最新发布</div>
                        <div class="tabs2-item" @click="changeConsult(3)" :class="{ bgc: consultShow == 3, }">最多收藏</div>
                    </div>


                    <div class="conslut">
                        <div class="six-item" v-for="item in IndustryInformationList3" @click="toTitle(item)"
                            style="cursor: pointer;">
                            <div class="six-left">
                                <img :src="item.imgUrl" style="width: 100%;height: 96%;border-radius: 4px;" alt="">
                            </div>
                            <div class="six-right">
                                <div class="six-right-1">{{ item.bgcTitle }}</div>
                                <div class="six-right-2">{{ item.bgcContent.replace(/<[^>]*>/g, '') }}</div>
                                <div class="six-right-3">
                                    <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">

                                    <span style="vertical-align: middle;color: #9499A0;font-size: 12px;"> {{
                    item.bgcAuthor
                }}</span>
                                    <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>
                                    <div style="position: absolute;right: 0;">
                                        <img src="../assets/newImg/Vector@0.75x.png"
                                            style="vertical-align: middle;line-height: 12px;width: 13px;height: 13px;"
                                            alt="">
                                        <span style="vertical-align: middle;margin-left: 3px;line-height: 15px;">{{
                    item.addTime
                }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-pagination v-model:current-page="page2" v-model:page-size="size2"
                        style="margin-left: 30%;margin-top: 30px;" :page-sizes="[10, 20]"
                        layout="total, sizes, prev, pager, next, jumper" :total="totalPage2"
                        @size-change="handleSizeChange2" @current-change="handleCurrentChange2" />

                </el-tab-pane>
                <!-- <el-tab-pane label="报价" name="third">角色管理</el-tab-pane>
                <el-tab-pane label="商品" name="fourth">定时任务补偿</el-tab-pane>
                <el-tab-pane label="用户" name="six">定时任务补偿</el-tab-pane> -->
            </el-tabs>
        </div>


    </div>
</template>

<script>
import { getVodByParam, getGoodsConsultByParam } from '@/api/user'

export default {
    data() {
        return {
            currentState: 0,
            activeName: 'first',
            input: '',
            videoShow: null,
            consultShow: null,
            videoList2: [],
            page: 1,
            size: 21,
            totalPage: 0,
            page2: 1,
            size2: 10,
            totalPage2: 0,
            index: null,
            IndustryInformationList3: [],
        }
    },
    methods: {
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/titleDetails',
                query: {
                    id: item.bgcId
                }
            });
            window.open(href, "_blank");

        },
        toSearch() {

            if (this.currentState == 0) {
                let params2 = {
                    page: this.page,
                    size: this.size,
                    index: this.videoShow,
                    param: this.input,
                }
                getVodByParam(params2).then((res) => {
                    this.videoList2 = res.data.data
                    this.totalPage = res.data.totalPage
                })
            } else if (this.currentState == 1) {
                let params2 = {
                    page: this.page,
                    size: this.size,
                    index: this.consultShow,
                    param: this.input,
                }
                getGoodsConsultByParam(params2).then((res) => {
                    this.IndustryInformationList3 = res.data.data
                    this.totalPage = res.data.totalPage
                })
            }
        },
        consultShow(id) {
            this.videoShow = id
            this.page = 1
            this.size = 21

        },
        toVedio(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,

                },
            });
            window.open(href, "_blank");
        },

        changeVideo(id) {
            this.videoShow = id
            let params2 = {
                page: this.page,
                size: this.size,
                index: id,
                param: this.input,
            }
            getVodByParam(params2).then((res) => {
                this.videoList2 = res.data.data
                this.totalPage = res.data.totalPage
            })
        },

        changeConsult(id) {
            this.consultShow = id
            let params2 = {
                page: this.page,
                size: this.size,
                index: this.consultShow,
                param: this.input,
            }
            getGoodsConsultByParam(params2).then((res) => {
                this.IndustryInformationList3 = res.data.data
                this.totalPage = res.data.totalPage
            })
        },

        handleClick(e) {
            this.currentState = e.index

            if (this.currentState == 0) {
                let params2 = {
                    page: this.page,
                    size: this.size,
                    index: this.videoShow,
                    param: this.input,
                }
                getVodByParam(params2).then((res) => {
                    this.videoList2 = res.data.data
                    this.totalPage = res.data.totalPage
                })
            } else if (this.currentState == 1) {
                let params2 = {
                    page: this.page2,
                    size: this.size2,
                    index: this.consultShow,
                    param: this.input,
                }
                getGoodsConsultByParam(params2).then((res) => {
                    this.IndustryInformationList3 = res.data.data
                    this.totalPage2 = res.data.totalPage
                })
            }
        },
        handleSizeChange(e) {
            this.size = e
            let params2 = {
                page: this.page,
                size: this.size,
                index: null,
                param: '',
            }
            getVodByParam(params2).then((res) => {
                this.videoList2 = res.data.data
                this.totalPage = res.data.totalPage
            })

        },
        handleCurrentChange(val) {
            this.page = val
            let params2 = {
                page: this.page,
                size: this.size,
                index: null,
                param: '',
            }
            getVodByParam(params2).then((res) => {
                this.videoList2 = res.data.data
                this.totalPage = res.data.totalPage
            })
        },
        handleSizeChange2(e) {
            this.size2 = e
            let params2 = {
                page: this.page2,
                size: this.size2,
                index: this.consultShow,
                param: this.input,
            }
            getGoodsConsultByParam(params2).then((res) => {
                this.IndustryInformationList3 = res.data.data
                this.totalPage2 = res.data.totalPage
            })

        },
        handleCurrentChange2(val) {
            this.page2 = val
            let params2 = {
                page: this.page2,
                size: this.size2,
                index: this.consultShow,
                param: this.input,
            }
            getGoodsConsultByParam(params2).then((res) => {
                this.IndustryInformationList3 = res.data.data
                this.totalPage2 = res.data.totalPage
            })
        },
    },
    created() { },
    mounted() {

        if (this.$route.query.activeName == 'second') {
            this.activeName = 'second'
        }

        // let param = {
        //     index: this.consultShow,
        //     param: this.input,
        //     page: this.page2,
        //     size: this.size2
        // }
        // getGoodsConsultByParam(param).then((res) => {

        // })

        if (this.$route.query.label) {
            this.input = this.$route.query.label
        }

        let params2 = {
            page: this.page,
            size: this.size,
            index: null,
            param: this.input,
        }
        getVodByParam(params2).then((res) => {
            this.videoList2 = res.data.data
            this.totalPage = res.data.totalPage
        })


    }
}
</script>

<style lang=scss scoped>
.bgc {
    color: #227BFF !important;
    background: rgba(34, 123, 255, 0.19);

}

input:focus {
    outline: none;
}

::v-deep {
    .custom-button {
        height: 54px !important;
        width: 100%;
        background: #F6F7F8;
        border: 0px solid red !important;
    }

    .el-tabs__nav-wrap::after {
        height: 0px !important;
    }

    .el-tabs__item {
        text-decoration: none !important;
        /* 取消可能的文本下划线 */
        border-bottom: none !important;
        /* 取消可能的底部边框，有时可能被误认为是下划线 */
    }
}

.search {
    margin-top: 90px !important;
    width: 1600px;
    // height: 100vh;
    margin: auto;
    margin-bottom: 300px;

    .search-input {
        width: 640px;
        height: 54px;
        background: #F6F7F8;
        margin: auto;
        display: flex;

        .search-input-left {
            position: relative;
            height: 100%;
            width: 50px;
        }

        .search-input-middle {
            height: 100%;
            width: 516px;
        }

        .search-input-right {
            height: 100%;
            width: 74px;
        }
    }

    .tabs {
        margin-top: 40px;

        .tabs2 {
            margin-top: 12px;
            height: 44px;
            width: 100%;
            // background-color: pink;
            display: flex;

            .tabs2-item {
                cursor: pointer;
                width: 86px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: #61666D;
                font-size: 14px;
                font-weight: 400;
                border-radius: 7px;
            }
        }

        .video {
            width: 100%;

            .edit-box {
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                gap: 0;
                align-content: flex-start;
                justify-content: flex-start;

                .edit-item {
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 228px;
                    height: 174px;
                    padding: 0px 6px;
                    // background-color: skyblue;
                }
            }
        }

        .conslut {
            width: 100%;
            // height: 374px;
            margin-top: 8px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .six-item {
                width: 48%;
                height: 116px;
                padding: 12px 0px;
                display: flex;
                margin-top: 5px;
                // border-bottom: 1px solid #EAEAEA;

                .six-left {
                    width: 22%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 25px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 400;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #333333;
                        font-size: 20px;
                    }

                    .six-right-2 {
                        height: 38px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 14px;
                        color: #666666;
                        font-weight: 400;
                    }

                    .six-right-3 {
                        position: relative;
                        display: flex;
                        height: 15%;
                        padding: 10px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

    }


}
</style>