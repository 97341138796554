export default	function getUrlParams(params){
    var urlObj = {};
    if(!window.location.search){return false;}
    var urlParams = window.location.search.substring(1);
    var urlArr = urlParams.split('&');
    for(var i = 0; i < urlArr.length; i++){
        var urlArrItem = urlArr[i].split('=');
        urlObj[urlArrItem[0]] = urlArrItem[1]
    }
　　// 判断是否有参数
    if(arguments.length>=1){
        return urlObj[params]
    }
    return urlObj;
}