<template>
    <div style="background-color: rgb(246, 247, 248);width: 100%;height: 100%;">
        <el-dialog title="支付宝提现" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="提现金额" :label-width="formLabelWidth" prop="amount">
                    <el-input v-model="form.amount" autocomplete="off" @input="handleInput"></el-input>
                </el-form-item>
                <el-form-item label="支付宝账号" :label-width="formLabelWidth" prop="zfb_name">
                    <el-input v-model="form.zfb_name" disabled autocomplete="off" @input="handleInput3"></el-input>
                </el-form-item>
                <el-form-item label="支付宝实名姓名" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form.name" @input="handleInput2" disabled autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="getMoney2('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="支付宝绑定" :visible.sync="dialogFormVisible2">
            <el-form :model="form2" :rules="rules2" ref="ruleForm2">

                <el-form-item label="支付宝账号" :label-width="formLabelWidth" prop="zfb_name">
                    <el-input v-model="form2.zfb_name" autocomplete="off" @input="handleInput3"></el-input>
                </el-form-item>
                <el-form-item label="支付宝实名姓名" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form2.name" @input="handleInput2" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="zfbIng('ruleForm2')">确 定</el-button>
            </div>
        </el-dialog>
        <div class="myMoney">
            <div class="title">我的收入</div>
            {{ data }}
            <div class="sky-box">
                <div style="padding: 8px 12px;font-size: 14px;color: #333333;font-weight: 290;margin-left: 2px;">收入金额
                </div>
                <div style="padding: 6px 12px;display: flex;">
                    <div style="color: #333333;font-size: 20px;font-weight: 700;line-height: 25px;">￥{{ info.user_money
                        }}</div>
                    <div style="margin-left: 30px;margin-top: 0px;">
                        <el-button type="primary" size="mini" @click="dialogFormVisible2 = true"
                            v-if="info.is_bind_alipay == 0">绑定支付宝账号</el-button>
                        <el-button type="primary" size="mini" @click="dialogFormVisible2 = true"
                            v-if="info.is_bind_alipay == 1">更改支付宝账号</el-button>
                        <el-button type="primary" size="mini" @click="getMoney">去提现</el-button>

                    </div>


                </div>
                <div style="display: flex;">
                    <div style="margin-left: 8px;">
                        <!-- <el-input v-model.number="input" placeholder="请输入提现金额" size="mini" max="10" min="1"
                            style="width: 140px;"></el-input> -->
                    </div>

                </div>
            </div>
        </div>
        <div class="myMoney">
            <div class="title">我的额度</div>
            <div class="sky-box">
                <div style="padding: 8px 12px;font-size: 14px;color: #333333;font-weight: 290;margin-left: 2px;">可用额度
                </div>
                <div style="padding: 6px 12px;display: flex;">
                    <div style="color: #333333;font-size: 20px;font-weight: 700;line-height: 25px;">￥{{
            info.total_amount }}</div>
                    <div style="margin-left: 30px;"><el-button type="primary" size="mini">申请额度</el-button></div>
                </div>
            </div>
        </div>
        <div class="myMoney">
            <div class="title">我的积分</div>
            <div class="sky-box">
                <div style="padding: 8px 12px;font-size: 14px;color: #333333;font-weight: 290;margin-left: 2px;">可用积分
                </div>
                <div style="padding: 6px 12px;display: flex;">
                    <div style="color: #333333;font-size: 20px;font-weight: 700;line-height: 25px;">￥{{ info.pay_points
                        }}</div>
                    <!-- <div style="margin-left: 30px;"><el-button type="primary" size="mini">去提现</el-button></div> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { getUserById, getAlipayBind, alipayBind, alipayWithdraw } from '@/api/user.js'

export default {
    data() {
        return {
            info: {},
            input: '',
            ifShow: '',
            dialogFormVisible: false,
            dialogFormVisible2: false,
            data: '',
            form: {
                zfb_name: '',
                name: '',
                amount: '',
            },
            form2: {
                zfb_name: '',
                name: '',
                amount: '',
            },
            formLabelWidth: '130px',
            rules: {
                zfb_name: [
                    { required: true, message: '请填写支付宝实名姓名', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请填写支付宝账号', trigger: 'blur' },
                ],
                amount: [
                    { required: true, message: '请填写提现金额', trigger: 'blur' },
                ],
            },
            rules2: {
                name: [
                    { required: true, message: '请填写支付宝实名姓名', trigger: 'blur' },
                ],
                zfb_name: [
                    { required: true, message: '请填写支付宝账号', trigger: 'blur' },
                ],

            }
        }
    },
    methods: {
        handleInput3(value) {

            const regex = /^[\da-zA-Z]*$/g;

            if (!regex.test(value)) {

                this.form2.zfb_name = value.replace(/[^\da-zA-Z]/g, '');

            }


        },

        handleInput2(value) {
            // 使用正则表达式匹配中文字符  
            // 注意：这个正则表达式可能不完全准确，具体取决于你的需求  
            // 这里只匹配了基本的汉字范围，不包括一些特殊字符或扩展汉字  
            const chineseRegex = /[\u4e00-\u9fa5]+/g;
            this.form2.name = value.match(chineseRegex) ? value.match(chineseRegex).join('') : '';


        },
        handleInput(value) {
            // 使用正则表达式来限制只能输入数字  
            this.form.amount = value.replace(/[^\d]/g, '');
        },
        getMoney() {
            getAlipayBind().then((res) => {
                this.dialogFormVisible = true
                this.form.name = res.data.data.realName
                this.form.zfb_name = res.data.data.zfbName

            })

        },

        zfbIng(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        real_name: this.form2.name,
                        zfb_name: this.form2.zfb_name
                    }
                    alipayBind(params).then((res) => {
                        if (res.data.code == 200) {
                            this.$message({
                                message: '绑定成功',
                                type: 'success'
                            })
                            getUserById().then((res) => {
                                this.info = res.data.data
                            })
                            this.dialogFormVisible2 = false

                        }
                    })

                }
            });

        },
        getMoney2(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.form.amount < 1) {
                        return this.$message({
                            message: '提现金额需大于1',
                            type: 'warning'
                        })
                    }
                    let params = {
                        name: this.form.name,
                        zfb_name: this.form.zfb_name,
                        amount: this.form.amount,
                    }
                    alipayWithdraw(params).then((res) => {
                        if (res.data.code == 200) {
                            this.$message({
                                message: '提现成功',
                                type: 'success'
                            })
                            this.dialogFormVisible = false
                            getUserById().then((res) => {
                                this.info = res.data.data
                            })
                            this.form.name = ''
                            this.form.zfb_name = ''
                            this.form.amount = ''

                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            })
                        }
                    })
                }
            });

        }
    },
    created() { },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)'
        });
        getAlipayBind().then((res) => {
            if (res.data != null) {
                this.ifShow = false
            } else if (res.data == null) {
                this.ifShow = true
            }


        })
        getUserById().then((res) => {
            loading.close()
            this.info = res.data.data
        })
    }
}
</script>

<style lang=scss scoped>
.myMoney {
    width: 98%;
    height: 220px;
    margin-top: 20px !important;
    box-sizing: content-box;
    margin: auto;
    border-radius: 8px;
    background-color: white;

    .title {
        color: #333333;
        font-size: 18px;
        font-style: 'Microsoft YaHei';
        font-weight: 400;
        padding: 9px 15px;
    }

    .sky-box {
        height: 150px;
        width: 99%;
        margin: auto;
        background-color: rgb(215, 231, 255);
        border-radius: 10px;
    }
}
</style>