<template>
  <div class="box" style="margin-top: 80px">
    <div class="catery-box">
      <div class="catery-big" @mouseleave="handleMouseLeave()">
        <div
          class="catery-big-item"
          v-for="(item, index) in newCateryList"
          :key="item.value"
          :class="{ bgc2: wangNumber == item.value }"
          @mouseenter="handleMouseEnter(index, item.value)"
          @click="radioChange0(item)"
        >
          <div style="position: absolute; top: 16px; right: 0; width: 1px; height: 50%; background-color: white" v-show="index != 9 && index != 19"></div>
          <div>{{ item.label }}</div>
          <div class="catery-absolute" v-show="cateryShowList[index]" :class="{ specialMargin: (wangNumber2 > 4 && wangNumber2 < 10) || (wangNumber2 > 14 && wangNumber2 < 20) }">
            <div class="absolute-item" v-for="data2 in item.children" :key="data2.value">
              <div class="absolute-left" @click.stop="radioChange4(data2, item)">
                <img src="../assets/newImg3/路径@1x (6).png" alt="" style="vertical-align: middle; padding-right: 1px" />
                <span style="vertical-align: middle">
                  {{ data2.label }}
                </span>
              </div>

              <div class="absolute-right">
                <div style="padding: 2px 5px; cursor: pointer" v-for="data3 in data2.children" :key="data3.value" @click.stop="radioChange5(data3)" class="changeColor">
                  {{ data3.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <!-- <searchBar />
      <classifyBar :isNo="1" /> -->

      <div class="topInfo">
        <!-- <classifyContain /> -->

        <div class="priceBox">
          <div class="productBox">
            <div class="imglogo">
              <img src="@/assets/quotedPrice/logo.png" alt="" />
            </div>
            <div class="produt" v-if="goodName != 'undefined'">
              {{ typeName }}
            </div>
            <img src="../assets/buyerCenter/c.png" style="width: 100%" />
          </div>
          <!-- <div class="location">
            <span>当前位置：</span>
            <div class="nav">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item style="cursor: pointer"><span
                    @click="skipPage('/quotedPrice')">产品行情</span></el-breadcrumb-item>
                <el-breadcrumb-item style="cursor: pointer"><span @click="goBack()">{{
              typeName
            }}</span></el-breadcrumb-item>
                <el-breadcrumb-item style="cursor: pointer"><span>产品价格中心</span></el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div> -->
          <!-- <div>
            <el-carousel height="365px" width="1317px" arrow="always">
              <el-carousel-item>
                <img src="../assets/zhnlImg/轮播图 大宗原材料商城，供应链金融 820x355 20240516.png"
                  style="width: 1317px; height: 365px" />
              </el-carousel-item>

              <el-carousel-item>
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" style="width: 1317px; height: 365px" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/zhnlImg/1.png" style="width: 1317px; height: 365px" />
              </el-carousel-item>
            </el-carousel>
          </div> -->

          <div class="selectBox">
            <div class="btnBox">
              <div
                class="btn"
                v-for="(item, index) in MarketList"
                @click="marketChekout(index + 1, FactoryMarket[index].id)"
                :class="{
                  btnBgc: marketChekoutFlag == index + 1,
                  hhy: marketChekoutFlag != index + 1
                }"
                v-if="item.is_show == 1"
              >
                {{ FactoryMarket[index].name }}
              </div>
              <!-- <div class="btn" @click="marketChekout(1, FactoryMarket[0].id)"
                                :class="{ 'btnBgc': marketChekoutFlag == 1 }">
                                {{ FactoryMarket[0].name }}
                            </div>
                            <div class="btn" @click="marketChekout(2, FactoryMarket[1].id)"
                                :class="{ 'btnBgc': marketChekoutFlag == 2 }">
                                {{ FactoryMarket[1].name }}

                            </div>
                            <div class="btn" @click="marketChekout(3, FactoryMarket[2].id)"
                                :class="{ 'btnBgc': marketChekoutFlag == 3 }">
                                {{ FactoryMarket[2].name }}
                            </div> -->
            </div>

            <div class="district" style="padding-bottom: 5px">
              <span style="margin-right: 50px; width: 46px; padding-bottom: 20px; font-size: 14px; font-weight: 290; color: #000000">区域：</span>
              <span style="margin-right: 50px; cursor: pointer; width: 46px" @click="quyuBtn1" :class="{ btnCol: quyuFlag == 1 }" class="hhe">不限</span>
              <div class="select hhe">
                <!-- <el-checkbox-group v-model="checkList" @change="checkListClick">
                  <el-checkbox :label="item.value" :value="item.value" v-for="(item, index) in areaCategoryList"
                    :key="item.value" @click="checkListClick">{{ item.name }}</el-checkbox>
                </el-checkbox-group> -->
                <el-radio-group v-model="radio" @change="radioChange1">
                  <el-radio :label="item.id" v-for="(item, index) in areaCategoryList">{{ item.area }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="district" style="padding-bottom: 5px">
              <span style="margin-right: 50px; width: 46px; padding-bottom: 20px">省份：</span>
              <span style="margin-right: 50px; cursor: pointer; width: 46px" @click="quyuBtn" :class="{ btnCol: quyuFlag2 == 1 }" class="hhe">不限</span>
              <div class="select hhe">
                <!-- <el-checkbox-group v-model="checkList" @change="checkListClick">
                  <el-checkbox :label="item.rId" v-for="(item, index) in areaCategoryList2" @click="checkListClick">{{
        item.rName }}</el-checkbox>
                </el-checkbox-group> -->
                <el-radio-group v-model="radio2" @change="radioChange2">
                  <el-radio :label="item.rId" v-for="(item, index) in areaCategoryList2"> {{ item.rName }}</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="district" style="padding-bottom: 5px">
              <span style="margin-right: 50px; width: 46px; padding-bottom: 20px">企业：</span>
              <span style="margin-right: 50px; cursor: pointer; width: 46px" @click="shichangBtn2" :class="{ btnCol: shichang == 1 }" class="hhe">不限</span>
              <div class="select hhe">
                <el-radio-group v-model="radio3" @change="radioChange3">
                  <el-radio :label="item?.busId" v-for="(item, index) in areaCategorySonList" style="padding-bottom: 12px"> {{ item?.busNameAbbrev }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="district" style="padding-bottom: 5px">
              <span style="margin-right: 50px; width: 46px; padding-bottom: 20px">产品：</span>
              <span style="margin-right: 50px; cursor: pointer; width: 46px" @click="shichangBtn3" :class="{ btnCol: shichang2 == 1 }" class="hhe">不限</span>
              <div class="select hhe">
                <el-radio-group v-model="radio4" @change="radioChangeProduct">
                  <el-radio :label="item?.goodsName" v-for="(item, index) in areaCategorySonList2" style="padding-bottom: 12px"> {{ item?.goodsName }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="district" style="padding-bottom: 5px">
              <span style="margin-right: 50px; width: 46px; padding-bottom: 20px">规格：</span>
              <span style="margin-right: 50px; cursor: pointer; width: 46px" @click="shichangBtn4" :class="{ btnCol: shichang3 == 1 }" class="hhe">不限</span>
              <div class="select hhe">
                <el-radio-group v-model="radio5" @change="radioChangeProduct2">
                  <el-radio :label="item?.goodsType" v-for="(item, index) in areaCategorySonList3" style="padding-bottom: 12px"> {{ item?.goodsType }}</el-radio>
                </el-radio-group>
              </div>
            </div>

            <!-- <div class="district">
              <span style="margin-right: 50px">频率：</span>
              <span style="margin-right: 50px; cursor: pointer" @click="pirceBtn(1)" :class="{ btnCol: pirceFlag == 1 }"
                class="hhe">日价格</span>
              <span style="margin-right: 53px; cursor: pointer" @click="pirceBtn(2)" :class="{ btnCol: pirceFlag == 2 }"
                class="hhe">周价格</span>
              <span style="margin-right: 53px; cursor: pointer" @click="pirceBtn(3)" :class="{ btnCol: pirceFlag == 3 }"
                class="hhe">月价格</span>

              <span style="margin-right: 53px; cursor: pointer" @click="pirceBtn(4)" :class="{ btnCol: pirceFlag == 4 }"
                class="hhe">季度价格</span>

              <span style="margin-right: 53px; cursor: pointer" @click="pirceBtn(5)" :class="{ btnCol: pirceFlag == 5 }"
                class="hhe">半年价格</span>

              <span style="margin-right: 53px; cursor: pointer" @click="pirceBtn(6)" :class="{ btnCol: pirceFlag == 6 }"
                class="hhe">年价格</span>
            </div> -->

            <!-- <div class="checkedBox">
              <div class="checked" v-for="(item, index) in checkList">
                <span v-if="index == 0">已选：{{ item }}</span>
                <span v-else> {{ item }}</span>
              </div>
              <div class="checked" v-for="(item, index) in checkList2">
                {{ item }}
              </div>
              <div class="clear flexRowCenter" @click="clear" v-if="checkList.length >= 1 || checkList2.length >= 1">
                <img src="../assets/todayQuotedPrice/a.png" />
                清空
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="quotedBox">
        <div class="h">
          <div class="checked" v-for="(item, index) in checkList">
            <span> {{ item }}</span>
          </div>
        </div>

        <div class="tableBox" style="position: relative">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName"
            height="480"
            :cell-style="{
              'text-align': 'center'
            }"
            :header-cell-style="{ 'text-align': 'center', height: '4px', background: '#227BFF', border: '0px solid #227BFF', color: 'white' }"
          >
            <el-table-column prop="goodsName" label="商品名称">
              <template #default="scope">
                <div @click="toBus(scope.row)" style="cursor: pointer">
                  {{ scope.row.goodsName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="bqType" label="规格/参数">
              <template #default="scope">
                <div>
                  {{ scope.row.bqType ? scope.row.bqType : "/" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="busNameAbbrev" label="企业" width="200">
              <template #default="scope">
                <div @click="toBus(scope.row)" style="cursor: pointer">
                  <span style="vertical-align: middle"> {{ scope.row.busNameAbbrev }}</span>
                  <img
                    src="../assets/newImg3/黄金会员皇冠.png"
                    style="vertical-align: middle; padding-left: 2px; width: 18px; height: 16px; margin-left: 1px"
                    alt=""
                    v-if="scope.row.isSupperVip == 1"
                  />
                  <img
                    v-else-if="scope.row.isBusVip1 == 1"
                    src="../assets/newImg3/超级会员皇冠.png"
                    style="vertical-align: middle; padding-left: 2px; width: 18px; height: 16px; margin-left: 1px"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="busName" label="省份"></el-table-column> -->
            <el-table-column prop="bqCity" label="发货地">
              <template #default="scope">
                <div>
                  {{ scope.row.bqCity ? scope.row.bqCity : scope.row.bqProvince }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="bqPrice" label="价格"></el-table-column> -->

            <!-- <el-table-column prop="upPrice" label="涨跌">
              <template #default="scope">
                <div style="">

                  <span v-if="scope.row.upPrice > 0" style="color: red;">{{ scope.row.upPrice }}↑</span>
                  <span v-if="scope.row.upPrice == 0">{{ scope.row.upPrice }}</span>
                  <span v-else-if="scope.row.upPrice < 0" style="color: green;">{{ scope.row.upPrice
                    }}↓</span>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="bqSpec" label="规格单位"></el-table-column>
            <!-- <el-table-column prop="addTime" label="日期"></el-table-column> -->
            <el-table-column prop="bqPrice" :label="dataList[4]">
              <template #default="scope">
                <div>
                  {{ scope.row.bqPrice != 0 ? scope.row.bqPrice : "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="onePrice" :label="dataList[3]">
              <template #default="scope">
                <div>
                  {{ scope.row.onePrice != 0 ? scope.row.onePrice : "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="twoPrice" :label="dataList[2]">
              <template #default="scope">
                <div>
                  {{ scope.row.twoPrice != 0 ? scope.row.twoPrice : "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="threePrice" :label="dataList[1]">
              <template #default="scope">
                <div>
                  {{ scope.row.threePrice != 0 ? scope.row.threePrice : "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fourPrice" :label="dataList[0]">
              <template #default="scope">
                <div>
                  {{ scope.row.fourPrice != 0 ? scope.row.fourPrice : "-" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="onePrice" label="操作">
              <template #default="scope">
                <div>
                  <el-button type="primary" size="mini" @click="toHistory(scope.row)">曲线图</el-button>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="fivePrice" :label="dataList[4]"></el-table-column>
            <template #default="scope">
              <div>
                {{ scope.row.fivePrice ? scope.row.fivePrice != null : '0.00' }}
              </div>
            </template>
            </el-table-column> -->

            <!-- <el-table-column prop="danwei" label="曲线">
              <template slot-scope="scope">
                <div style="width: 180px; height: 70px;" @click="gotoPriceTrend">
                  <pillarData2 :tableData="tableData" />
                </div>
                <div>
                  <img src="../assets/quotedPrice/qux.png" style="width: 20px; height: 20px"
                    @click="gotoPriceTrend(scope.row)" alt="" />
                </div>
              </template>
            </el-table-column> -->
          </el-table>

          <i class="el-icon-caret-right" style="position: absolute; right: 129px; top: 15px; font-size: 28px; z-index: 999" @click="rightLook" v-if="this.click_num > 0 && this.title2Flag == 0"></i>

          <i class="el-icon-caret-right" style="position: absolute; right: 129px; top: 26px; font-size: 28px; z-index: 999" @click="rightLook" v-if="this.click_num > 0 && this.title2Flag == 1"></i>
        </div>
      </div>
      <!-- <div class="information-latest">
        <div class="information-top">
          <div style="margin-left: 20px;">产品最新资讯</div>
          <div style="margin-right: 20px;" @click="goSearch">更多</div>
        </div>
        <div class="information-bottom">
          <div class="box" v-for="item in productList" @click="goTitle(item)">
            <div class="box-left">
              <img :src="item.imgUrl" style="width: 100%;height: 100%;border-radius: 12px;" alt="">
            </div>
            <div class="box-right">
              <div class="box-1">
                {{ item.bgcTitle }}
              </div>
              <div class="box-2">
                {{ item.bgcContent.replace(/<[^>]*>/g, '') }}
              </div>
              <div class="box-3">{{ item.addTime }}</div>
            </div>
          </div>

        </div>
      </div> -->
      <div class="video-content">
        <div class="video-title">
          <div style="margin-left: 20px; font-weight: 900; font-size: 24px; color: #227bff; font-weight: 400">相关视频</div>
          <!-- <div style="margin-right: 20px;color: gray;" @click="allVideo">更多</div> -->
        </div>
        <div class="video-bottom">
          <!-- <div class="video-bottom-box" v-for="item in videoList" @click="goVideo(item)">
            <div class="box-img">
              <img :src="item.vImgUrl" alt="" style="width:100%;height:100%;object-fit: cover;border-radius: 12px;"
                class="video-img">
            </div>
            <div class="box-title">{{ item.vDesc }}</div>
            <div class="box-title2">
              <div style="font-size: 14px;">{{ item.vUploader }}</div>
              <div style="margin-left: 20px;font-size: 14px;" class="box-title3">播放量:{{ item.playNum }}
              </div>

            </div>
          </div> -->
          <div class="video">
            <div class="edit-box">
              <div class="edit-item" v-for="item in videoList" @click="toVedio(item)" :key="item.vId">
                <div style="position: relative">
                  <div style="position: absolute; bottom: 8px; right: 3px; font-size: 12px; color: #ffffff">
                    {{ item.vAddTime.slice(0, 10) }}
                  </div>
                  <img :src="item.vImgUrl" style="width: 100%; height: 116px; object-fit: cover; border-radius: 12px" alt="" />
                </div>
                <div style="color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  {{ item.vTitle }}
                </div>
                <div style="color: gray; font-size: 12px; padding-top: 3px; color: #9499a0">
                  <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

                  <span style="vertical-align: middle">{{ item.vUploader }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchBar from "../components/searchBar.vue";
import classifyBar from "../components/classifyBar.vue";
import classifyContain from "../components/classifyContain.vue";
import loginCon from "../components/loginCon.vue";
import strictProduct from "../components/strictProduct.vue";
import product from "../components/product.vue";
import pillarData2 from "../components/pillarData2.vue";
import getParams from "../utils/getParams";
import dayjs from "dayjs";
import Vue from "vue";

import {
  getRegion,
  getAllVod,
  getAllBusManage,
  getRegionQuotation,
  getFatherCatBySonCat,
  getQuotationGName,
  getBstGoodsGategoryAndSon,
  getAreaById,
  getAreaByCatAndIndus,
  getPriceByAreaId,
  getGoodsCateNameById,
  getBusByCatAndIndus,
  getBstGGateFatherById
} from "@/api/user";
export default {
  data() {
    return {
      areaCategorySonList2: [],
      newCateryList: [],
      cateryShowList: [],
      wangNumber: 0,
      wangNumber2: -1,
      postData: {},
      imgList: ["../assets/index/banner.png"],
      videoList: [],
      productList: [],
      radio: "0",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      dataList: [],
      typeName: "",
      typeName2: "",
      // tableData: [{
      //     shichang: '京津',
      //     date1: '2270',
      //     date2: '2270',
      //     date3: '2270',
      //     date4: '2270',
      //     date5: '2270',
      //     danwei: '元/吨'
      // }, {
      //     shichang: '京津',
      //     date1: '2270',
      //     date2: '2270',
      //     date3: '2270',
      //     date4: '2270',
      //     date5: '2270',
      //     danwei: '元/吨'
      // }],
      tableData: [],
      marketChekoutFlag: 1,
      checkList: [],
      checkList2: [],
      //按钮标签
      quyuFlag: 1,
      shichang: 1,
      shichang2: 1,
      shichang3: 1,
      quyuFlag2: 1,
      pirceFlag: 1,

      //数据列表
      FactoryMarket: "",
      areaCategoryList: [
        // { name: '华北地区', value: 4 },
        // { name: '华中地区', value: 1 },
        // { name: '华东地区', value: 2 },
        // { name: '华南地区', value: 3 },
        // { name: '东北地区', value: 5 },
        // { name: '西北地区', value: 6 },
        // { name: '西南地区', value: 7 },
      ],
      areaCategoryList2: [],
      //出厂id
      market_house_id: 1,
      areaCategorySonList: [],
      //区域id
      shichangId: "",
      parent_id: "",
      dateData: [],
      cat_one: "",
      cat_two: "",
      click_num: 0,
      frequency: 1,
      goodName: "",
      title2Flag: 0,
      isRouterAlive: true,
      MarketList: "",
      search: "",
      area_two: 0
    };
  },

  methods: {
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId
        }
      });
      window.open(href, "_blank");
    },
    handleMouseEnter(indexs, gId) {
      this.wangNumber = gId;
      this.wangNumber2 = indexs;
      this.cateryShowList.forEach((item, index) => {
        this.cateryShowList[index] = false;
      });
      Vue.set(this.cateryShowList, indexs, true);
    },
    handleMouseLeave() {
      this.wangNumber = 0;
      this.cateryShowList.forEach((item, index) => {
        Vue.set(this.cateryShowList, index, false);
      });
    },
    goSearch() {
      const { href } = this.$router.resolve({
        path: "search",
        query: {
          activeName: "second"
        }
      });
      window.open(href, "_blank");
    },
    allVideo() {
      const { href } = this.$router.resolve({
        path: "search"
      });
      window.open(href, "_blank");
    },
    goBack() {
      this.$router.go(-1);
    },
    toHistory(row) {
      const { href } = this.$router.resolve({
        path: "historicalQuotation",
        query: {
          catOne: row.catOne,
          catTwo: row.catTwo,
          catThree: row.catThree,
          catFour: row.catFour,
          goodName: row.goodsName,
          quotation_man: row.quotationMan,
          area_one: row.areaOne,
          area_two: row.areaTwo
        }
      });
      window.open(href, "_blank");
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return;
      }
    },
    toBus(row) {
      console.log(row, "W s rw");
      this.$router.push({
        path: "/facturerPage",
        query: {
          id: row.quotationMan
        }
      });
    },
    goTitle(item) {
      console.log(item, "item");
      this.$router.push({
        path: "/titleDetails",
        query: {
          id: item.bgcId
        }
      });
    },
    goVideo(data) {
      this.$router.push({
        path: "/vudio",
        query: {
          v_id: data.vId,
          industry: this.$route.query.cat_id,
          industry2: this.$route.query.cat_id2
        }
      });
    },
    radioChange1(e) {
      this.radio3 = "";
      this.radio2 = "";
      this.quyuFlag2 = 1;
      this.shichang = 1;
      this.quyuFlag = 0;
      let c = JSON.parse(this.$route.query.data);
      let dataNow = {
        id: e,
        cat_one: c.catOne,
        cat_two: c.catTwo,
        cat_three: c.catThree,
        industry: this.$route.query.cat_id != undefined ? this.$route.query.cat_id : "",
        industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : ""
      };
      getPriceByAreaId(dataNow).then((res) => {
        this.areaCategoryList2 = res.data.data;
      });
      this.radio = e;
      this.getPrice();
    },
    radioChange2(e) {
      this.quyuFlag2 = 0;
      console.log(e, "222");
      this.radio2 = e;
      this.getPrice();
    },
    radioChange3(e) {
      this.shichang = 0;
      this.radio3 = e;
      this.getPrice();
    },
    radioChangeProduct(e) {
      this.shichang2 = 0;
      this.radio4 = e;
      this.getPrice();
    },
    radioChangeProduct2(e) {
      this.shichang3 = 0;
      this.radio5 = e;
      this.getPrice();
    },

    radioChange4(e, item) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPriceCopy",
        query: {
          data: JSON.stringify({
            catOne: item.value,
            catTwo: e.value,
            label: e.label,

            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
    },
    radioChange5(e, catTwo) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: "",
            catTwo: catTwo,
            catThree: e.value,
            label: e.label,
            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
      console.log(e.label);
    },
    getPrice() {
      let data = JSON.parse(this.$route.query.data);
      console.log(data, "data==========");
      let params = {
        cat_one: data.catOne,
        cat_two: data.catTwo,
        cat_three: data.catThree,
        cat_four: data.catFour,
        industry: this.$route.query.cat_id,
        industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : "",
        area: this.radio,
        province: this.radio2,
        enterprise: this.radio3,
        goods_name: this.radio4,
        goods_type: this.radio5
      };
      getRegionQuotation(params).then((res) => {
        this.tableData = res.data.data;
      });
    },

    radioChange0(e) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPriceCopy",
        query: {
          data: JSON.stringify({
            catOne: e.value,
            catTwo: "",
            label: e.label,
            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
    },
    gotoPriceTrend(val) {
      const tableData = [
        {
          production: "白油", //第一
          name: "中国石油", //第二
          number: "123", //第三列
          count: 123 //第四列数据
        },
        {
          production: "红油",
          name: "美国石油",
          number: "132",
          count: 123
        },
        {
          production: "食用油",
          name: "非洲石油",
          number: "123",
          count: 123
        }
      ];

      this.area_two = val.id;
      let objT = {};
      objT.name = this.goodName;
      objT.cat_one = this.cat_one;
      objT.cat_two = this.cat_two;
      objT.cat_three = this.cat_three;
      objT.market_house_id = this.market_house_id;
      objT.area_one = this.$route.query.parent_id;
      objT.area_two = this.area_two;
      objT.tableData = this.tableData;
      objT.dateData = this.dateData;

      this.skipPage("/priceTrend" + `?item=${JSON.stringify(objT)}`);
    },
    cellStyle() {
      return "'color': 'red'";
    },
    marketChekout(num, market_house_id) {
      this.marketChekoutFlag = num;
      this.parent_id = market_house_id;
      this.checkList = [];
      this.market_house_id = "";
      this.shichangId = "";
      this.dateData = [];
      this.tableData = [];
      this.areaCategory();
      this.fiveQuotedPriceList();
      this.fiveQuotedPriceList2();
      // this.areaCategorySon()
    },

    skipPage(page) {
      window.location = page;
    },
    quyuBtn1() {
      this.radio3 = "0";
      this.radio = "0";
      this.getPrice();
      this.quyuFlag = 1;
      let c = JSON.parse(this.$route.query.data);
      let dataNow = {
        id: 0,
        cat_one: c.catOne,
        cat_two: c.catTwo,
        cat_three: c.catThree,

        industry: this.$route.query.cat_id != undefined ? this.$route.query.cat_id : "",
        industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : ""
      };
      getPriceByAreaId(dataNow).then((res) => {
        this.areaCategoryList2 = res.data.data;
      });
      // if (this.quyuFlag == 0) {
      //   this.quyuFlag = 1;
      // } else {
      //   this.quyuFlag = 0;
      // }
      // if (this.quyuFlag == 0) {
      //   this.quyuFlag = 1;
      //   this.market_house_id = "";
      //   this.checkList = [];
      //   this.areaCategorySon();
      // } else {
      //   this.quyuFlag = 0;
      // }
    },
    //选择框按钮事件
    quyuBtn() {
      this.radio2 = "";
      this.getPrice();
      this.quyuFlag2 = 1;
      // if (this.quyuFlag2 == 0) {
      //   this.quyuFlag2 = 1;
      // } else {
      //   this.quyuFlag2 = 0;
      // }
      // if (this.quyuFlag == 0) {
      //   this.quyuFlag = 1;
      //   this.market_house_id = "";
      //   this.checkList = [];
      //   this.areaCategorySon();
      // } else {
      //   this.quyuFlag = 0;
      // }
    },

    //选择框按钮事件
    shichangBtn2() {
      this.radio3 = "";
      this.shichang = 1;
      this.getPrice();
    },
    shichangBtn3() {
      this.radio4 = "";
      this.shichang2 = 1;
      this.getPrice();
    },
    shichangBtn4() {
      this.radio5 = "";
      this.shichang3 = 1;
      this.getPrice();
    },

    checkListClick(e) {
      console.log(this.checkList, "checkList");
      console.log(e, 111);
      if (this.checkList.length >= 1) {
        this.quyuFlag = 0;
      }
      if (e.length > 0) {
        let arr = [];
        this.areaCategoryList.forEach((item, index) => {
          // e.forEach((item2, index2) => {
          // console.log('123', item.name)

          //     if (item.name == item2) {

          //         arr.push[item[index].id]

          //     }
          // })
          for (var i = 0; i < e.length; i++) {
            if (item.name == e[i]) {
              arr.push(item.id);
            }
          }
        });

        this.market_house_id = arr.join();
        this.dateData = [];
        this.tableData = [];
        this.areaCategorySon();
        this.fiveQuotedPriceList();
      }
    },
    shichangBtn() {
      if (this.shichang == 0) {
        this.shichang = 1;
      } else {
        this.shichang = 0;
      }
    },
    checkList2Click(e) {
      console.log(this.radio3, "cecece");
      if (this.checkList2.length >= 1) {
        this.shichang = 0;
      }
      if (e.length > 0) {
        let arr = [];
        this.areaCategorySonList.forEach((item, index) => {
          // e.forEach((item2, index2) => {
          // console.log('123', item.name)

          //     if (item.name == item2) {

          //         arr.push[item[index].id]

          //     }
          // })
          for (var i = 0; i < e.length; i++) {
            if (item.name == e[i]) {
              arr.push(item.id);
            }
          }
        });
        this.shichangId = arr.join();
        this.fiveQuotedPriceList();
        this.fiveQuotedPriceList2();

        this.isRouterAlive = false; // 1.让组件消失
        this.$nextTick(() => {
          this.isRouterAlive = true; // 在nextTick方法中，再让组件重现，完成重载
        });
      } else {
      }
    },
    pirceBtn(num) {
      this.pirceFlag = num;
      this.frequency = num;
      this.fiveQuotedPriceList();
      this.fiveQuotedPriceList2();
      this.$nextTick(() => {
        this.isRouterAlive = true; // 在nextTick方法中，再让组件重现，完成重载
      });
    },
    clear() {
      this.checkList = [];
      this.checkList2 = [];
      this.market_house_id = "";
      this.shichangId = "";
      this.quyuFlag = 0;
      this.shichang = 0;
    },

    changeCellStyle(row, column, rowIndex, columnIndex) {
      //列的label的名称
      if (row.column.label != "市场" && row.column.label != "单位") {
        return "text-align: center; color:black"; //修改的样式
      } else {
        return "text-align: center;";
      }
    },

    //数据表往左
    leftLook() {
      this.click_num = this.click_num + 1;
      this.fiveQuotedPriceList();
    },

    //数据表往右
    rightLook() {
      this.click_num = this.click_num - 1;
      this.fiveQuotedPriceList();
    },

    //出厂列表
    async ExFactoryMarket() {
      let res = await this.$http("/mini/ApiConnect/ExFactoryMarket");
      this.FactoryMarket = res.result;
      if (!getParams().parent_id) {
        this.parent_id = this.FactoryMarket[0].id;
      }
      this.MarketList = res.result;
      // this.parent_id = ''

      this.areaCategory();
    },

    //区域列表
    async areaCategory() {
      let res = await this.$http("/mini/ApiConnect/areaCategory", {
        market_house_id: this.parent_id
      });
      this.areaCategoryList = res.result;
      if (!getParams().market_house_id) {
        this.market_house_id = "";
      }
      // this.parent_id = ''

      this.areaCategorySon();
    },

    //市场列表
    async areaCategorySon() {
      console.log("areaCategorySon", this.parent_id);
      let parmas = {
        parent_id: this.market_house_id,
        market_house_id: this.parent_id
      };

      let res = await this.$http("/mini/ApiConnect/areaCategorySon", parmas);

      this.areaCategorySonList = res.result;
      // this.shichangId = this.areaCategorySonList[0].id
      this.shichangId = "";
    },

    //报价列表
    async fiveQuotedPriceList() {
      let parmas = {
        frequency: this.frequency,
        cat_one: this.cat_one,
        cat_two: this.cat_two,
        cat_three: this.cat_three,
        market_house_id: this.parent_id,
        area_one: this.market_house_id,
        area_two: this.shichangId,
        click_num: this.click_num
      };
      let res = await this.$http("/mini/ApiConnect/fiveQuotedPriceList", parmas);
      this.dateData = [];
      res.result[0]?.son.forEach((e, index) => {
        if (e.title && e.title2) {
          this.dateData.push(e.title + "\n" + e.title2);
          this.title2Flag = 1;
        } else {
          this.dateData.push(e.title);
          this.title2Flag = 0;
        }
      });
      this.tableData = [];

      res.result.forEach((e, index) => {
        let obj = {};
        obj.shichang = e.name;
        obj.date1 = e?.son[0].price;
        obj.date2 = e?.son[1].price;
        obj.date3 = e?.son[2].price;
        obj.date4 = e?.son[3].price;
        obj.date5 = e?.son[4].price;
        obj.danwei = e?.son[0].unit;
        obj.id = e.id;
        // this.$set(this.tableData, index, obj)
        this.tableData.push(obj);
        console.log("tableData", index);
      });
    },

    async fiveQuotedPriceList2() {
      let parmas = {
        frequency: this.frequency,
        cat_one: this.cat_one,
        cat_two: this.cat_two,
        cat_three: this.cat_three,
        market_house_id: this.parent_id,
        area_one: this.market_house_id,
        area_two: this.shichangId,
        click_num: this.click_num,
        fun: 1
      };
      let res = await this.$http("/mini/ApiConnect/fiveQuotedPriceList", parmas);

      res.result.forEach((e, index) => {
        if (this.tableData[index].yData) {
          this.tableData[index].yData = e.data;
        }
        if (this.tableData[index].xData) {
          this.tableData[index].xData = e.title;
        }
      });
    }
  },
  mounted() {
    getBstGoodsGategoryAndSon().then((res) => {
      this.newCateryList = res.data.data;
      this.newCateryList.forEach((item, index) => {
        this.cateryShowList[index] = false;
      });
    });

    let c = JSON.parse(this.$route.query.data);
    if (c.cat_id) {
      getBstGGateFatherById({ cat_id: c.cat_id }).then((res) => {
        if (res.data.code == 200) {
          var params2 = {
            cat_one: res.data.data,
            cat_two: "",
            cat_three: "",
            industry: "",
            industry2: "",
            page: 1,
            size: 30
          };

          getAllVod(params2).then((res) => {
            this.videoList = res.data.data;
          });
        }
      });
    } else {
      getBstGGateFatherById({ cat_id: c.catTwo }).then((res) => {
        if (res.data.code == 200) {
          var params2 = {
            cat_one: res.data.data,
            cat_two: "",
            cat_three: "",
            industry: "",
            industry2: "",
            page: 1,
            size: 30
          };

          getAllVod(params2).then((res) => {
            this.videoList = res.data.data;
          });
        }
      });
    }

    this.postData = JSON.parse(this.$route.query.data);
    this.typeName = this.postData.label;

    let params = {
      cat_one: c?.catOne,
      cat_two: c?.catTwo,
      cat_three: c?.catThree,
      cat_four: c?.catFour,
      industry: this.$route.query.cat_id != undefined ? this.$route.query.cat_id : "",
      industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : ""
    };
    getQuotationGName(params).then((res) => {
      if (res.data.code == 200) {
        this.areaCategorySonList2 = res.data.data.name;
        this.areaCategorySonList3 = res.data.data.type.filter((item) => {
          return item.goodsType != "";
        });
      }
    });

    getBusByCatAndIndus(params).then((res) => {
      this.areaCategorySonList = res.data.data;
    });
    getAreaByCatAndIndus(params).then((res) => {
      this.areaCategoryList = res.data.data;
    });
    let dataNow = {
      id: "0",
      cat_one: c.catOne,
      cat_two: c.catTwo,
      // cat_three: c.catThree != undefined ? c.catThree : '',
      cat_three: c.catThree,
      cat_three: c.catThree,
      industry: this.$route.query.cat_id != undefined ? this.$route.query.cat_id : "",
      industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : ""
    };
    getPriceByAreaId(dataNow).then((res) => {
      this.areaCategoryList2 = res.data.data;
    });
    // 获取当前日期
    let today = dayjs();

    // 创建一个空数组来存储日期
    // let datesArray = [];

    // 循环四次，获取前四天的日期
    for (let i = 4; i >= 0; i--) {
      this.dataList.push(today.subtract(i, "day").format("YYYY-MM-DD"));
    }
    let data = JSON.parse(this.$route.query.data);
    this.goodName = data.goods_name;

    // getAllBusManage().then((res) => {
    //   this.areaCategorySonList = res.data.data
    // })
    // getAllGoodsConsult(params2).then((res) => {
    //   this.productList = res.data.data
    // })
    this.getPrice();
    // this.cat_one = getParams().cat_one;
    // this.cat_two = getParams().cat_two;
    // this.cat_three = getParams().cat_three;
    // this.goodName = decodeURI(getParams().goodName);
    // console.log("wuwuwuw", getParams().thename);
    // if (getParams().parent_id) {
    //   this.parent_id = getParams().parent_id;
    //   this.marketChekoutFlag = this.parent_id;
    // }

    // if (getParams().market_house_id) {
    //   this.market_house_id = getParams().market_house_id;
    // }

    // if (getParams().thename) {
    //   this.checkList.push(decodeURI(getParams().thename));
    // }

    // this.ExFactoryMarket();
    // this.fiveQuotedPriceList();
    // this.fiveQuotedPriceList2();
  },
  components: {
    searchBar,
    classifyBar,
    classifyContain,
    loginCon,
    strictProduct,
    product,
    pillarData2
  }
};
</script>

<style lang="scss">
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #006eb7;
  font-size: 14px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #006eb7;
  font-size: 14px !important;
}

.el-table .cell {
  white-space: pre-wrap !important;
}
</style>

<style lang="scss" scoped>
.btnCol {
  color: #227bff;
  font-weight: 700;
}

@media screen and (min-width: 1px) and (max-width: 1680px) {
  .hhe {
    width: 990px;
  }

  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227bff 0%, #5f9efa 51%, #227bff 99%);

    .catery-big {
      width: 1200px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        cursor: pointer;
        position: relative;
        margin-top: 1.4px;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 600%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #d8d8d8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227bff;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;
            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7;
              }
            }
          }
        }
      }
    }
  }

  .content2 {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .selectBox {
    width: 1200px;
    // background-color: red;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 0px;
    margin-top: 18px;
    // height: 292px;

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }

      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }

  .video {
    width: 100%;

    .edit-box {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;
      margin-top: 14px;

      .edit-item {
        box-sizing: border-box;
        cursor: pointer;
        width: 239px;
        height: 174px;
        padding: 0px 15px;
        // background-color: skyblue;
      }
    }
  }

  .quotedBox {
    width: 1200px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 24px 0px;
    box-sizing: border-box;
    background-color: white;

    .h {
      display: flex;
      align-items: center;

      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }
    }

    .tableBox {
      margin-top: 22px;
      border-top: 1px solid #e4e4e4;
    }

    .title {
      width: 83px;
      height: 28px;
      border-radius: 14px;
      background: #006eb7;
      font-size: 12px;
      text-align: center;
      line-height: 28px;
      color: white;
    }

    :deep(.el-table .el-table__cell) {
      padding: 17px 0 !important;
      white-space: pre-wrap !important;
    }
  }

  .video-content {
    cursor: pointer;
    width: 100%;
    height: 1080px;
    background-color: white;
    margin-bottom: 100px;

    .video-title {
      width: 100%;
      height: 50px;
      // background-color: skyblue;
      // background-image: linear-gradient(rgb(212, 230, 255), white);

      // padding-left: 5px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
    }

    .video-bottom {
      height: 600px;
      width: 100%;
      display: flex;
      // flex-flow: column wrap;
      background-color: white;
      flex-wrap: wrap;

      .video-bottom-box {
        padding: 5px 10px;
        width: 20%;
        height: 158px;
        box-sizing: border-box;

        .box-img {
          width: 100%;
          height: 116px;
        }

        .video-img:hover {
          box-shadow: 5px 5px 5px gray;
        }

        .box-title {
          width: 100%;
          height: 21px;
          white-space: nowrap;
          /* 文本不换行 */
          overflow: hidden;
          /* 溢出部分隐藏 */
          text-overflow: ellipsis;
          padding-top: 5px;
          font-weight: 900;
        }

        .box-title:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-title2:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-title2 {
          color: gray;
          width: 100%;
          height: 21px;
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: 1680px) and (max-width: 10620px) {
  .hhe {
    width: 1200px;
  }

  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227bff 0%, #5f9efa 51%, #227bff 99%);

    .catery-big {
      width: 1500px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        position: relative;
        cursor: pointer;

        margin-top: 1.4px;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 600%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #d8d8d8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227bff;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;
            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7;
              }
            }
          }
        }
      }
    }
  }

  .content2 {
    width: 1500px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .selectBox {
    width: 1500px;
    // background-color: red;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 0px;
    margin-top: 18px;
    // height: 292px;

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }

      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }

  .video {
    width: 100%;

    .edit-box {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;
      margin-top: 14px;

      .edit-item {
        box-sizing: border-box;
        cursor: pointer;
        width: 250px;
        height: 174px;
        padding: 0px 15px;
        // background-color: skyblue;
      }
    }
  }

  .quotedBox {
    width: 1500px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 24px 0px;
    box-sizing: border-box;
    background-color: white;

    .h {
      display: flex;
      align-items: center;

      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }
    }

    .tableBox {
      margin-top: 22px;
      border-top: 1px solid #e4e4e4;
    }

    .title {
      width: 83px;
      height: 28px;
      border-radius: 14px;
      background: #006eb7;
      font-size: 12px;
      text-align: center;
      line-height: 28px;
      color: white;
    }

    :deep(.el-table .el-table__cell) {
      padding: 17px 0 !important;
      white-space: pre-wrap !important;
    }
  }

  .video-content {
    cursor: pointer;
    width: 100%;
    height: 960px;
    background-color: white;
    margin-bottom: 100px;

    .video-title {
      width: 100%;
      height: 50px;
      // background-color: skyblue;
      // background-image: linear-gradient(rgb(212, 230, 255), white);

      // padding-left: 5px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
    }

    .video-bottom {
      height: 600px;
      width: 100%;
      display: flex;
      // flex-flow: column wrap;
      background-color: white;
      flex-wrap: wrap;

      .video-bottom-box {
        padding: 5px 10px;
        width: 20%;
        height: 158px;
        box-sizing: border-box;

        .box-img {
          width: 100%;
          height: 116px;
        }

        .video-img:hover {
          box-shadow: 5px 5px 5px gray;
        }

        .box-title {
          width: 100%;
          height: 21px;
          white-space: nowrap;
          /* 文本不换行 */
          overflow: hidden;
          /* 溢出部分隐藏 */
          text-overflow: ellipsis;
          padding-top: 5px;
          font-weight: 900;
        }

        .box-title:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-title2:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-title2 {
          color: gray;
          width: 100%;
          height: 21px;
          display: flex;
        }
      }
    }
  }
}

.topInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .priceBox {
    width: 100%;
    // width: 989px;
    // margin-left: 210px;
    margin-top: 40px;
    // height: 365px;

    .productBox {
      position: relative;

      .imglogo {
        width: 48px;
        height: 81px;
        border-radius: 2.28px;
        position: absolute;
        bottom: 0;
        left: 24px;
        opacity: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .produt {
        position: absolute;
        left: 91px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.1px;
        line-height: 23.4px;
        text-align: left;
        color: #fff;
      }
    }

    .location {
      margin-top: 10px;
      display: flex;
      align-items: center;
      transform: translateY(10px);
      height: 50px;

      .nav {
        // margin-top: 5px;
      }
    }

    .top {
      width: 100%;
      height: 167px;
      border-radius: 4px;
      background-color: white;
      padding: 12px 0;
      box-sizing: border-box;

      .title {
        width: 188px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background: #006eb7;
        text-align: center;
        line-height: 30px;
        color: white;
      }

      .contaionBox {
        margin-top: 21px;

        .line1 {
          width: 238px;
          height: 82px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e4e4e4;

          .top2 {
            font-size: 28px;
          }

          .bottom {
            margin-top: 4px;
            display: flex;
            align-items: center;
            color: #21a00c;

            .left {
              padding: 0 10px;
              border-right: 2px solid #e4e4e4;
            }

            .right {
              padding: 0 10px;
            }
          }

          .bottom2 {
            margin-top: 4px;
            display: flex;
            align-items: center;
            color: #ff0c0c;

            .left {
              padding: 0 10px;
              border-right: 2px solid #e4e4e4;
            }

            .right {
              padding: 0 10px;
            }
          }
        }

        .line1:last-child {
          border-right: none;
        }
      }
    }
  }
}

.market {
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // align-items: flex-start;

  .line2 {
    width: 350px;
    height: 476px;
    background-color: white;
    padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        border-left: 3px solid #006eb7;
        padding-left: 5px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
      margin-bottom: 5px;
    }

    .contaion {
      .info {
        width: 70%;
        height: 50px;
        line-height: 50px;
      }

      .date {
        width: 30%;
        font-size: 16px;
        color: #909090;
      }
    }
  }

  .rightLine {
    width: 828px;
    height: 476px;

    .top3 {
      width: 828px;
      height: 227px;
      background-color: white;
      border-radius: 4px;
      padding: 16px;
      // margin-right: 24px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          border-left: 3px solid #006eb7;
          padding-left: 5px;
        }

        .date {
          font-size: 10px;
          color: #aeaeae;
          margin-left: 6px;
        }
      }

      .theLine {
        height: 2px;
        background-color: #e4e4e4;
        margin-top: 7px;
        margin-bottom: 5px;
      }

      .contaion {
        padding: 20px 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .left {
          .btn {
            margin-bottom: 20px;
            width: 81px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid #006eb7;
            color: #006eb7;
            box-sizing: border-box;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            cursor: pointer;
          }

          .btnBgc {
            background-color: #006eb7 !important;
            color: white !important;
            border: none !important;
          }
        }

        .right {
          width: 690px;
          display: flex;
          flex-wrap: wrap;

          .region {
            width: 33%;
            text-align: center;
            font-size: 23.85px;
            letter-spacing: 2.39px;
            cursor: pointer;
          }

          .region:first-child {
            margin-bottom: 44px;
          }
        }
      }
    }

    .top3 {
      margin-bottom: 22px;
      width: 828px;
      height: 227px;
      background-color: white;
      // padding: 16px;
      // margin-right: 24px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          border-left: 3px solid #006eb7;
          padding-left: 5px;
        }

        .date {
          font-size: 10px;
          color: #aeaeae;
          margin-left: 6px;
        }
      }

      .theLine {
        height: 2px;
        background-color: #e4e4e4;
        margin-top: 7px;
        margin-bottom: 7px;
      }

      .contaion2 {
        padding: 0 !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;

        .info {
          width: 70%;
          height: 50px;
          line-height: 50px;
        }

        .date {
          width: 30%;
          font-size: 16px;
          color: #909090;
        }
      }

      .contaion2:last-child {
        border: none;
      }
    }
  }
}

.productBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .productInfo {
    margin-right: 15px;
  }
}

.productInfo:nth-child(5n) {
  margin-right: 0;
}

.footBar {
  width: 1920px;
  height: 284px;
  background-color: #006eb7;
}

.information-bottom::-webkit-scrollbar {
  width: 8px;
}

.information-bottom::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.information-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.information-latest {
  cursor: pointer;
  width: 100%;
  height: 800px;
  background-color: white;

  .information-top {
    width: 100%;
    height: 50px;
    background-image: linear-gradient(rgb(212, 230, 255), white);
    line-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .information-bottom {
    width: 100%;
    height: 750px;

    overflow: auto;

    .box {
      width: 100%;
      height: 18%;
      // background-color: red;
      display: flex;
      margin-top: 12px;

      .box-left {
        width: 22%;
        height: 100%;
      }

      .box-right {
        width: 78%;
        height: 100%;
        // display: flex;
        padding: 10px 6px 6px 20px;

        .box-1 {
          height: 20%;
          width: 100%;
          padding-bottom: 8px;
          font-weight: 900;
        }

        .box-2 {
          height: 31%;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .box-2:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-3:hover {
          color: rgb(32, 123, 255) !important;
        }

        .box-3 {
          padding-top: 10px;
          height: 20%;
          width: 100%;
          color: gray;
          font-size: 14px;
        }
      }
    }
  }
}

.el-radio__label {
  padding-left: 0px !important;
}

::v-deep .el-radio {
  // width: 120px;
}

::v-deep .el-table .warning-row {
  background-color: #f3f7fc;
}

.bgc2 {
  background-color: white;
  color: #227bff !important;
}

.specialMargin {
  // background-color: yellow !important;
  left: -500% !important;
}

::v-deep.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /* 或者你想要的宽度 */
  height: 8px;
  /* 垂直滚动条的高度 */
}

/* 自定义滚动条滑块样式 */
::v-deep.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* 滑块颜色 */
  border-radius: 10px;
  /* 圆角 */
}

/* 自定义滚动条轨道样式 */
::v-deep el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 轨道颜色 */
  border-radius: 10px;
  /* 圆角 */
}

/* 当鼠标悬停在滑块上时，改变滑块样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* 滑块悬停颜色 */
}

.has-gutter {
  height: 10px !important;
}

// ::v-deep .el-table__header-wrapper {
//   height: 30px !important;
//   line-height: 30px !important;
//   // margin-bottom:20px !important;
//   box-sizing: border-box
// }
</style>
