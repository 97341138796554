<template>
    <div class="BaiDuMap">
      <baidu-map class="map-wrap" :center="mapData.center" :zoom="mapData.zoom" @ready="mapHandler" @click="getLocation">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      </baidu-map>
  
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        address: null, // 地址信息
        businessDetail: {},  //商家信息(包含坐标信息)
        //地图数据
        mapData: {
          center: { lng: 113.443304, lat: 23.168608 },//中心坐标
          zoom: 18,    //缩放级别
        },
        BMap: null, //BMap类
        map: null,  //地图对象
      };
    },
    methods: {async mapHandler({ BMap, map }) {
              if (this.businessId) {
                  //可以在此处请求接口获取坐标数据
                  await this.getMallBusinessDetail()
              }
  
              this.BMap = BMap //保存百度地图类
              this.map = map //保存地图对象
              //如果一开始坐标存在(编辑的时候)
              if (this.businessDetail.longitude && this.businessDetail.latitude) {
                  //设置坐标
                  this.mapData.center.lng = this.businessDetail.longitude
                  this.mapData.center.lat = this.businessDetail.latitude
              } else {
                  //如果坐标不存在则动态获取当前浏览器坐标（创建的时候）
                  let geolocation = new BMap.Geolocation()
                  //获取当前的坐标（使用promise 将异步转换为同步）
                  await new Promise((resolve) => {
                      geolocation.getCurrentPosition(() => {
                          // this.mapData.center.lng = this.businessDetail.longitude = r.point.lng
                          // this.mapData.center.lat = this.businessDetail.latitude = r.point.lat
                          this.mapData.center.lng = this.businessDetail.longitude = 113.443304
                          this.mapData.center.lat = this.businessDetail.latitude = 23.168608 //这里的经纬度是固定的，没有动态获取，上面注释的两行是动态获取的
                          resolve()
                      })
                  })
              }
              //创建定位标记
              let marker = new BMap.Marker(
                  new BMap.Point(
                      this.businessDetail.longitude,
                      this.businessDetail.latitude
                  )
              )
            
              map.addOverlay(marker)  //将标记添加到地图上
          },
          //在地图上选择区域
          getLocation(e) {
              this.businessDetail.longitude = e.point.lng  //设置经度
              this.businessDetail.latitude = e.point.lat  //设置纬度
              let BMapGL = this.BMap  //百度地图类
              let map = this.map//地图对象 
              map.clearOverlays()   //清除地图上所有的覆盖物(保证每次点击只有一个标记)
              //创建定位标记
              let marker = new BMapGL.Marker(new BMapGL.Point(e.point.lng, e.point.lat))   //将标记添加到地图上
              map.addOverlay(marker)      //创建坐标解析对象
              let geoc = new BMapGL.Geocoder()  //解析当前的坐标成地址
              geoc.getLocation(e.point, (rs) => {
                  let addressComp = rs.addressComponents  //获取地址对象
                  //拼接出详细地址
                  this.businessDetail.address =
                      addressComp.province +
                      addressComp.city +
                      addressComp.district +
                      addressComp.street +
                      addressComp.streetNumber
              })
          },
    },
  };
  </script>
  <style scoped>
  .BaiDuMap {
    width: 1000px;
    height: 400px;
  }
  .map-wrap {
    height: 400px;
  
  }
  </style>