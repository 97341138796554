<template>
    <div>
        <el-table :data="tableData" style="width: 100%;">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="order_sn" label="订单编号" />


            <el-table-column prop="goods_name" label="商品名称" />
            <el-table-column prop="mobile" label="手机号码" />
            <el-table-column prop="consignee" label="收货人" />
            <el-table-column prop="address" label="收货地址" />


            <el-table-column prop="add_time" label="订单时间" />

            <el-table-column label="审核结果">
                <template #default="scope">
                    <div>
                        <span v-if="scope.row.shipping_status == '0'">未发货</span>
                        <span v-else-if="scope.row.shipping_status == '1'">已发货</span>
                        <span v-else-if="scope.row.shipping_status == '2'">已收货</span>
                        <!-- <span v-else-if="scope.row.vStatus == '2'" style="color: red;">未通过</span> -->
                    </div>
                </template>
            </el-table-column>

        </el-table>

        <div>
            <el-pagination v-model:current-page="page" v-model:page-size="size"
                style="margin-left: 30%;margin-top: 30px;" :page-sizes="[5, 8]"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>

</template>

<script>
import { getOrderByUserId } from '@/api/user'
export default {
    data() {
        return {
            tableData: [],
            page: 1,
            size: 5,
            total: 0
        }
    },
    methods: {

        handleSizeChange(e) {
            this.size = e
            getOrderByUserId({
                user_id: window.localStorage.getItem('user_id'),
                pay_status: 0,
                shipping_status: 0,
                order_status: 0,
                page: this.page,
                size: this.size
            }).then((res) => {
                this.total = res.data.total_page
                this.tableData = res.data.data
            })
        },
        handleCurrentChange(e) {
            this.page = e
            getOrderByUserId({
                user_id: window.localStorage.getItem('user_id'),
                pay_status: 0,
                shipping_status: 0,
                order_status: 0,
                page: this.page,
                size: this.size
            }).then((res) => {
                this.total = res.data.total_page
                this.tableData = res.data.data
            })
        },
    },
    created() { },
    mounted() {
        getOrderByUserId({
            user_id: window.localStorage.getItem('user_id'),
            pay_status: 0,
            shipping_status: 0,
            order_status: 0,
            page: this.page,
            size: this.size
        }).then((res) => {
            this.total = res.data.total_page
            this.tableData = res.data.data
            this.tableData.forEach((item, index) => {
                // item.add_time = item.add_time.slice(0, 8)
            })
        })
    }
}
</script>

<style lang=scss></style>