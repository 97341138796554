<template>
    <div class="zh-box">
        <div class="wz-top">
            <div
                style="font-weight: 400;font-size: 22px;color:#333333;line-height: 40px;width: 120px;margin-left: 7px;">
                <!-- {{ titleName }} -->
                全部商品
            </div>
            <div class="classifyList">
                <!-- <div class="classify-item" @click="numberChange(item)">
                    推荐
                </div> -->
                <div class="classify-item" v-for="item in areaCategoryList2 " :class="{ bgc: cat_one == item.gId }"
                    @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>

        </div>
        <div class="store">
            <!-- <div style="height: 2.5rem; color:#227BFF;font-size: 26px;margin-left: 12px;">严选产品推荐
                    </div> -->
            <div class="store-box">
                <div class="store-item" v-for="item in productsList " @click="skip(item.goodId)">
                    <div style="width: 100%;height: 218px;">
                        <img :src="item.goodsOriginalImg" style="width: 100%;height: 100%;border-radius: 4px;" lt="">
                    </div>
                    <div style="color: #000000;font-size: 12px;padding: 8px;margin-top: 2px;">{{
                    item.goodsName }}</div>
                    <div style="color: #FF0C0C;font-size: 18px;padding: 0px 4px;margin-top: 2px;">￥{{
                    item.goodsPrice }}/{{
                    item.specName }}
                    </div>
                </div>
            </div>
            <!-- </div> -->
            <!-- <div class="productInfo" v-for="(item, index) in productsList" @click="skip(item.goodId)">
                        <product @add="StrictSelectionOfProducts" :img="item.goodsOriginalImg" :title="item.goodsName"
                            :price="item.goodsPrice" :specName="item.specName" :click_count="item.click_count"
                            :item="item" :type="3" /> 
                            
                    </div> -->

            <div style="width: 1200px; margin: 50px auto">
                <el-empty description="没有你想要的商品" v-if="productsList.length < 1"></el-empty>
            </div>
        </div>

    </div>
</template>

<script>
import { getBstGoodsGategoryByBrName, getAllGoodsConsult } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            productsList: [],
            areaCategoryList: [],
            wangNumber: 0,
            size: 30,
            page: 1,
            cat_one: '',

        }
    },
    props: ['areaCategoryList2', 'titleName'],
    methods: {
        skip(id) {
            const { href } = this.$router.resolve({
                path: "/detailsPage",
                query: { goods_id: id, productType: 'strictStore' },
            });
            window.open(href, "_blank");

        },
        numberChange(item) {
            this.cat_one = item.gId
            console.log(this.wangNumber, 'gou');
            let params = { type: 3, user_id: window.localStorage.getItem('user_id'), index: 1, page: 1, goods_name: '', size: 1000, cat_one: this.cat_one }
            getBstGoodsGategoryByBrName(params).then((res) => {
                this.productsList = res.data.data
            })
        },
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/titleDetails',
                query: {
                    id: item.bgcId
                }
            });
            window.open(href, "_blank");

        }
    },
    created() { },
    mounted() {
        getBstGoodsGategoryByBrName({ type: 3, user_id: window.localStorage.getItem('user_id'), index: 1, page: 1, goods_name: '', size: 1000, cat_one: this.cat_one }).then((res) => {
            this.productsList = res.data.data
        })

        // getBstGoodsGategory1().then((res) => {
        //     this.areaCategoryList = res.data.data
        //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
        //     console.log(this.areaCategoryList, 'this.areaCategoryList ');
        // })
    }
}
</script>

<style lang=scss scoped>
.zh-box {
    width: 1210px;
    // height: 800px;
    // background-color: white;
    margin-top: 30px;
    padding: 10px 0px;
    font-family: 'MicrosoftYaHeiLight';

    .wz-top {
        height: 50px;
        width: 100%;
        display: flex;

        .classifyList {
            display: flex;
            height: 100%;
            margin-left: 26px;

            .classify-item {
                font-size: 16px;
                color: #333333;
                font-weight: 400;
                height: 20px;
                padding: 12px 12px;
                cursor: pointer;
                font-weight: 290;
            }

            .classify-item:hover {
                // color: #227BFF;
                // border-bottom: 1px solid #227BFF;
            }

        }
    }

    .lun {
        height: 310px;
        width: 100%;
        display: flex;
        // background-color: skyblue;
        margin-top: 2px;

        .lun-left {
            width: 48.5%;
            height: 98%;
            padding: 5px;
            position: relative;
            // background-color: pink;
        }

        .lun-right {
            width: 52.8%;
            height: 98%;
            padding: 5px 0px 0px 2px;

            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .title-item {
                width: 46%;
                height: 20px;
                padding: 12px 8px;
                overflow: hidden !important;
                /* 超出部分隐藏 */
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                font-size: 16px;
                color: #333333;
                cursor: pointer;
            }

            .title-item:hover {
                color: #227BFF;
                ;
            }
        }
    }


}

.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}

.store-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 6px;

    .store-item {
        cursor: pointer;
        margin-top: 13px;
        width: 225px;
        height: 290px;
        // padding: 0px 7px;
        margin-right: 16px;
        border-radius: 4px;
        padding: 0px 0px;
        background: #FFFFFF;
        // box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
        // box-shadow: 2px 2px 2px 2px gray;
    }
}
</style>