<template>
    <div class="bigBox">
        <div class="content">
            <searchBar />
            <!-- <div class="line"></div> -->
            <div class="classifyBox">
                <classifyBar />

            </div>

            <div class="location">
                <!-- <span>当前位置：</span>
                <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/navigation' }">基础油</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/navigation' }">15号基础油</el-breadcrumb-item>
                    </el-breadcrumb></div> -->
            </div>

            <div class="commodityBox" v-for="(item, index) in goodslist">
                <div class="left">
                    <img :src="item.original_img" style="width: 133px; height: 133px;">
                </div>
                <div class="right">
                    <div class="commodityTitle">
                        {{ item.goods_name }}
                    </div>


                    <div class="serve" v-if="item.goods_sn">
                        <span>商品号:</span>
                        <span style="margin-left: 20px;">{{ item.goods_sn }}</span>
                    </div>

                    <div class="serve" v-if="item.order_sn">
                        <span>商品号:</span>
                        <span style="margin-left: 20px;">{{ item.order_sn }}</span>
                    </div>


                    <div class="numBox">
                        <span>数量</span>
                        <div class="numBtn">
                            x{{ item.goods_num }}
                        </div>
                    </div>

                    <div class="price">
                        <span style="font-size: 16px; color: #000000ff;">价格</span>
                        <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">{{ item.goods_price }}</span>

                    </div>
                </div>
            </div>

            <div class="countPirce" style="margin-top: 30px;">
                <span style="font-size: 20px;">总价： <span style="font-size: 28px; color: rgb(255, 12, 12);">{{ allprices
                        }}</span></span>
            </div>

            <div class="orderInfo">
                <div class="orderTitle">地址信息</div>

                <div class="putBox">
                    <div class="line1">
                        <div class="put1">
                            姓名：{{ address.consignee }}
                        </div>
                        <div class="put1">
                            电话：{{ address.mobile }}
                        </div>
                        <div class="put1">
                            您的地址：{{ address.region }} {{ address.consignee }}
                        </div>

                        <!-- <div class="btnBox" style="margin-top: 0; width: 70px; height: 30px; line-height: 30px;"
                            @click="checkOutAddress">
                            更换地址
                        </div> -->
                    </div>


                    <div class="subBox" style="display: flex; align-items: center;">
                        <el-popover placement="right" width="900" trigger="manual" v-model="popoverFlag">

                            <div class="tableBox">
                                <el-table :data="tableData" style="width: 100%; border: 1px solid #AEAEAE;"
                                    :header-cell-style="{ background: '#EBECF0', color: '#606266' }">
                                    <el-table-column prop="perpeo" label="收货人" width="90px">
                                    </el-table-column>
                                    <el-table-column prop="region" label="所在地区" width="200px">
                                    </el-table-column>
                                    <el-table-column prop="address" label="详细地址" width="180px">
                                    </el-table-column>
                                    <el-table-column prop="code" label="邮政编码" width="100px">
                                    </el-table-column>
                                    <el-table-column prop="phone" label="手机号码">
                                    </el-table-column>
                                    <el-table-column prop="operate" label="操作" width="100px" align="center">
                                        <template slot-scope="scope">
                                            <el-button style="margin-right: 5px;" type="text" size="small"
                                                @click="selectAddress(scope.row)">选中地址</el-button>

                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                            <el-button class="subBtn"
                                style="background: none; border: 1px solid #006EB7; color: #006EB7; box-sizing: border-box; line-height: 10px; font-size: 16px;"
                                slot="reference" @click="flagClick">更改地址</el-button>
                        </el-popover>

                        <div class="btnBox" style="margin-top: 0; margin-left: 30px;" @click="createOrder">
                            确认并支付
                        </div>

                    </div>



                </div>
            </div>

        </div>

    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import getParams from '../utils/getParams'


export default {
    data() {
        return {
            checkOutImgList: [require("../assets/detailsPage/image1.png"), require("../assets/detailsPage/Group.png")],
            checkOutImgFlag: 0,
            info: '',
            comment_rate: '',
            productsList: '',
            type: 0,

            commentList: '',
            levelList: '',
            tableData: [],
            number: 1,
            numberFlag1: 0,

            options: [],
            value: '',
            options2: [],
            value2: '',
            options3: [],
            value3: '',
            goodslist: '',
            allprices: '',
            allnumber: '',

            //订单参数
            name: '',
            phone: '',
            address: '',
            popoverFlag: false,
            addressId: ''
        }
    },

    components: {
        searchBar,
        classifyBar,
    },

    methods: {
        selectAddress(e) {
            console.log(e)
            this.addressId = e.address_id
            this.popoverFlag = false
            this.UserAddressDefault(e.address_id)
            setTimeout(() => {
                window.location = '/order?addressId=' + e.address_id

            }, 1000)
        },
        flagClick() {
            if (this.popoverFlag) {
                this.popoverFlag = false
            } else {
                this.popoverFlag = true
            }
        },
        select1() {
            this.UserRegionList2()
        },

        select2() {
            this.UserRegionList3()
        },
        min() {
            if (this.number <= 1) {
                this.numberFlag1 = 0
            } else {
                this.number = this.number - 1
            }
        },

        max() {
            this.numberFlag1 = 1

            this.number = this.number + 1
        },

        barBtn(type) {
            this.type = type
        },

        createOrder() {
            this.CreatOrderInfo()
        },

        checkOutAddress() {
            window.location = '/buyerCenter/address'

        },

        //购物车结算
        async SettleAccounts() {
            let userId = window.localStorage.getItem('user_id')

            let res = await this.$http('/mini/ApiConnect/SettleAccounts', { user_id: userId })
            this.goodslist = res.goodslist
            this.allprices = res.allprices
            this.allnumber = res.allnumber
            this.address = res.address
        },

        //修改默认地址
        async UserAddressDefault(addressId) {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserAddressDefault', { user_id: userId, address_id: addressId })
            if (res.status == 1) {
                window.location = '/order'
            }
        },

        //购物车提交订单
        async CreatOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                type: 'cart',
                action: 'submit_order',
                ship_type: 1,
                pay_code: 'weixin',
                address_id: this.address.address_id
            }
            let res = await this.$http('/mini/ApiConnect/CreatOrderInfo', params)

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location = '/buyerCenter/myOrder'

                })
            } else {
                this.$message.error(res.msg);
            }

        },

        //地址下拉列表
        async UserRegionList() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList')

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options.push(obj)
            })
        },

        async UserRegionList2() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value })

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options2.push(obj)
            })
        },

        async UserRegionList3() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value2 })

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options3.push(obj)
            })
        },

        //获取地址
        async UserAddressList() {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserAddressList', { user_id: userId })
            res.result.forEach((e, index) => {
                let obj = {}
                obj.perpeo = e.consignee
                obj.region = e.region
                obj.address = e.address
                obj.code = e.zipcode
                obj.phone = e.mobile
                obj.address_id = e.address_id
                this.tableData.push(obj)
            })
        },
    },

    mounted() {
        let userId = window.localStorage.getItem('user_id')
        let id = getParams().goods_id
        this.UserRegionList()
        this.SettleAccounts()
        this.UserAddressList()
    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.bigBox {
    background-color: white;
}

::v-deep .content1 {
    background-color: white;
}

.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.commodityBox {
    margin-top: 18px;
    display: flex;

    .collectBox {
        width: 80px;
        height: 25px;
        color: white;
        text-align: center;
        background-color: #006EB7;
        line-height: 25px;
        margin-top: 10px;
        cursor: pointer;
    }

    .right {
        margin-left: 23px;

        .commodityTitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 23.4px;
            text-align: left;
            color: #000;
        }

        .price {
            margin-top: 17px;
        }

        .serve {
            font-size: 16px;
            color: #898989;
            margin-top: 10px;
        }

        .btnBox {
            display: flex;

            .btn1 {
                width: 200px;
                height: 64px;
                border-radius: 4px;
                background: #006eb7;
                text-align: center;
                line-height: 64px;
                color: white;
                font-size: 24px;
                margin-right: 6px;
                margin-top: 30px;
            }
        }

        .checkOutImg {
            display: flex;
            align-items: center;
            margin-top: 18px;

            .box {
                width: 82px;
                height: 82px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .box :last-child {
                margin-right: 0px;

            }

            .imgBorder {
                border: 5px solid #006EB7;
                box-sizing: border-box;
            }
        }

        .numBox {
            margin-top: 10px;
            display: flex;
            align-items: center;

            .numBtn {
                margin-left: 20px;
                display: flex;
                align-items: center;

                .min {
                    width: 30px;
                    height: 30px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 30px;
                    color: white;
                }

                .max {
                    width: 30px;
                    height: 30px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 30px;
                    color: white;
                }

                .put {
                    height: 30px;
                    width: 60px;
                }

                ::v-deep .el-input__inner {
                    height: 30px !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    text-align: center;
                }
            }

        }
    }
}

.orderInfo {
    margin-top: 50px;

    .orderTitle {
        padding-left: 10px;
        border-left: 3px solid #006EB7;
    }

    .putBox {
        .line1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 30px;

            .put1 {
                height: 40px;
                width: 48%;
            }

            ::v-deep .el-input__inner {
                height: 40px !important;
            }
        }

        .btnBox {
            width: 100px;
            height: 40px;
            background-color: #006EB7;
            color: white;
            text-align: center;
            line-height: 40px;
            margin-top: 30px;
            cursor: pointer;
        }

    }
}

.btnBgc2 {
    background-color: #a8a8a8 !important;
}
</style>