<template>
    <div>
        <VipDialog2 ref="VipDialog2" :form="form" />
        <VipDialog4 ref="VipDialog4" />

        <div class="quotedBox">
            <el-dialog title="" :visible.sync="dialogVisible2" width="56%" top="6vh">
                <div></div>
                <div style="white-space:pre-wrap;height: 600px;    overflow-y: scroll;">

                    <span v-html="info2.pContent"></span>

                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
                </span>
            </el-dialog>
            <div class="title">企业认证 </div>
            <div style="margin: auto;width: 720px;position: relative;">
                <el-form :model="form" ref="ruleFormRef" :rules="rules" style="margin-top: 40px;">
                    <el-form-item label="上传营业执照" label-width="145px" prop="v_img_url" required>
                        <el-upload action="https://www.zhnl.com/zhnl/uploadBusFile" list-type="picture-card" :limit="1"
                            :on-success="handleSuccess3" :headers="headerObj" :before-upload="beforeAvatarUpload2"
                            :before-remove="beforeRemove" :class="{ hide_box: upload_btn }">
                            <i class="el-icon-plus"></i>


                        </el-upload>

                        <el-dialog :visible.sync="dialogVisible">

                            <img width="100%" :src="dialogImageUrl" alt="" v-show="form.v_img_url == ''">
                        </el-dialog>

                        <div style="position: absolute;top: 18px;right: 120px;">
                            <div style="font-size: 14px;height: 25px;font-weight: 290;color: #333333;">
                                注意事项：</div>
                            <div style="font-size: 14px;height: 25px;font-weight: 300;color: #333333;margin-top: 6px;">
                                <span>(1) 上传清晰可见的营业执照,文件大小限于5M</span>
                            </div>
                            <div style="font-size: 14px;height:25px;font-weight: 300;color: #333333;margin-top: 4px;">
                                <span>(2) 营业执照可自动识别文字,无需手动填写</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="公司名称" label-width="145px" prop="shop_name">
                        <el-input v-model="form.shop_name" autocomplete="off" required style="width: 490px; "
                            show-word-limit maxlength="28" />
                    </el-form-item>
                    <el-form-item label="公司简称" label-width="145px" prop="company_abbrev">
                        <el-input v-model="form.company_abbrev" autocomplete="off" required style="width: 490px; "
                            show-word-limit maxlength="6" />
                    </el-form-item>
                    <el-form-item label="企业法人" label-width="145px" prop="bus_legal_person">
                        <el-input v-model="form.bus_legal_person" @input="handleInput" autocomplete="off" required
                            style="width: 490px; " show-word-limit maxlength="6" />
                    </el-form-item>
                    <el-form-item label="运营者" label-width="145px" prop="company_operator">
                        <el-input v-model="form.company_operator" @input="handleInput2" autocomplete="off" required
                            style="width: 490px; " show-word-limit maxlength="6" />
                    </el-form-item>



                    <!-- 
                    <el-form-item label="公司地址" label-width="145px" prop="shop_address">
                        <el-input v-model="form.shop_address" autocomplete="off" required style="width: 490px;"
                            maxlength="40" show-word-limit />
                    </el-form-item> -->

                    <el-form-item label="运营者手机号" label-width="145px" prop="shop_phone">
                        <el-input v-model="form.shop_phone" autocomplete="off" required style="width: 490px;"
                            maxlength="11" show-word-limit />
                    </el-form-item>

                    <!-- <el-form-item label="经营范围" label-width="145px" prop="company_manage">
                        <el-input v-model="form.company_manage" autocomplete="off" required style="width: 490px;"
                            maxlength="500" :rows="6" show-word-limit type="textarea" placeholder="请输入内容" />
                    </el-form-item> -->



                </el-form>
                <div>
                    <div>
                        <el-button type="primary" @click="sub('ruleFormRef')"
                            style="margin-left: 330px;margin-top: 30px;">提交认证</el-button>
                        <div>
                            <el-checkbox v-model="checked" style="margin-left: 250px;margin-top: 19px;"></el-checkbox>
                            <span
                                style="font-size: 14px;font-weight: 290;margin-left: 5px;color: #333333;cursor: pointer;"
                                @click="showXie">我已同意《搜原网官方认证服务协议》</span>

                        </div>
                    </div>
                </div>


            </div>

            <!-- <div class="formBox">

                <div class="txt1">
                    <div class="txtName">
                        公司名称：
                    </div>
                    <div>
                        <el-input v-model="shop_name">
                        </el-input>
                    </div>
                </div>

                <div class="txt1">
                    <div class="txtName">
                        地址：
                    </div>
                    <div>
                        <el-input v-model="shop_address">
                        </el-input>
                    </div>
                </div>


                <div class="txt1">
                    <div class="txtName">
                        联系方式：
                    </div>
                    <div>
                        <el-input v-model="shop_phone">
                        </el-input>
                    </div>
                </div>

                <div class="txt1">
                    <div class="txtName">
                        经营范围：
                    </div>
                    <div>
                        <el-input type="textarea" rows="5" resize="none" v-model="company_manage">
                        </el-input>
                    </div>
                </div>

                <div class="imgBox">
                    <div class="img" style="overflow: hidden;">
                        <img :src="imageUrl" style="width: 100%; height: 100%; object-fit: fill;" alt=""
                            v-if="imageUrl">
                    </div>


                    <el-upload class="avatar-uploader" :on-success="handleAvatarSuccess" :headers="headerObj"
                        action="https://www.zhnl.com/zhnl/uploadBusFile" :show-file-list="false" name="file">

                        <div class="imgBtn">
                            上传营业执照
                        </div>
                    </el-upload>
                </div>

            </div>

            <div class="line1 ">
                <div class="btn"
                    style="border: 1px solid #006eb7; background: none; color: #006EB7; margin-right: 20px;"
                    @click="back">取消
                </div>

                <div class="btn" style="background-color: #006EB7;" @click="sub('ruleFormRef')">确认信息</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { addPersonBus, getUserById, uploadBusFile, getUserProtocol, BusOcr } from '@/api/user'
import VipDialog2 from '../components/VipDialog2.vue'
import VipDialog4 from '../components/VipDialog4.vue'


export default {
    data() {
        return {
            upload_btn: false, // 是否隐藏上传图片按钮
            info: {},
            info2: {},
            is_bus_auth: '',
            checked: false,
            headerObj: {
                token: window.localStorage.getItem('token'),
            },
            dialogVisible2: false,
            info: {},
            dialogVisible: false,
            dialogImageUrl: '',

            form: {
                shop_name: '',
                shop_address: '',
                v_img_url: '',
                company_manage: '',
                shop_phone: '',
                bus_legal_person: '',
                company_operator: '',
                company_abbrev: '',
            },
            rules: {
                shop_name: [
                    {
                        required: true,
                        message: '请填写公司名称',
                        trigger: 'change',
                    },
                ],
                company_abbrev: [
                    {
                        required: true,
                        message: '请填写公司简称',
                        trigger: 'change',
                    },
                ],


                bus_legal_person: [
                    {
                        required: true,
                        message: '请填写公司法人',
                        trigger: 'change',
                    },
                ],

                shop_address: [
                    {
                        required: true,
                        message: '请填写公司地址',
                        trigger: 'change',
                    },
                ],
                shop_phone: [
                    {
                        required: true,
                        message: '请填写运营者手机号',
                        trigger: 'change',
                    },
                ],
                company_operator: [
                    {
                        required: true,
                        message: '请填写运营者',
                        trigger: 'change',
                    },
                ],


                company_manage: [
                    {
                        required: true,
                        message: '请填写经营范围',
                        trigger: 'change',
                    },
                ],

            },
            user_name: '',
            value: '',
            imageUrl: '',
            shop_name: '',
            shop_phone: '',
            company_manage: '',
            headers: {},
            token: "",
            importData: {},
            business_license: '',
            shop_address: '',
            user_phone: '',
            shop_id: '',
            user_email: '',
        }
    },
    components: {

        VipDialog2,
        VipDialog4,

    },
    mounted() {
        getUserProtocol({ mark_id: 1 }).then((res) => {
            this.info2 = res.data.data
        })
        getUserById().then((res) => {
            if (res.data.code == 200) {
                this.info = res.data.data
                if (this.info.distribut_type == 2) {
                    this.form.shop_name = this.info.shop_name
                    this.form.company_abbrev = this.info.company_abbrev

                    this.form.bus_legal_person = this.info.company_operator
                    this.form.company_operator = this.info.company_legal_person
                    this.form.shop_phone = this.info.shop_phone
                }
            }

        })
        let userId = window.localStorage.getItem('user_id')
        this.importData.user_id = userId

        this.UserIndexInfo()

    },
    methods: {
        handleInput(value) {
            // 使用正则表达式移除所有数字  
            this.form.bus_legal_person = value.replace(/\d+/g, '');
        },
        handleInput2(value) {
            // 使用正则表达式移除所有数字  
            this.form.company_operator = value.replace(/\d+/g, '');
        },
        beforeRemove(file) {
            console.log('file,', file);
            this.form.v_img_url = ''
            this.upload_btn = false
        },
        getVip() {
            this.$refs.VipDialog2.visible = true
            this.$refs.VipDialog2.getCode()
        },
        showXie() {
            this.dialogVisible2 = true
        },
        beforeAvatarUpload2(file) {
            this.upload_btn = true
            const isLt500K = file.size / 1024 / 1024 < 5;
            if (!isLt500K) {
                this.$message.error('上传图片大小不能超过 5000KB!');
                return false;
            }
        },
        handleSuccess3(file) {
            console.log(file, 'file');
            if (file.code == 4017) {
                this.$message({
                    type: 'error',
                    message: '登录过期，请重新登录'
                })
                setTimeout(() => {
                    window.location = '/login'
                }, 2000)
                localStorage.removeItem('token')
                localStorage.removeItem('user_id')
            } else {
                this.upload_btn = true;
                BusOcr({ url: file.url }).then((res) => {

                    if (res.data.data) {
                        this.form.bus_legal_person = res.data.data?.legalPerson
                        this.form.shop_name = res.data.data?.companyName
                    }
                    // this.form.shop_phone = res.data.data.companyName

                })
                this.form.v_img_url = file.url

            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.url
            // console.log('993')
            // this.imageUrl = res.result.showUrl
            // this.imageUrl2 = res.result.path
        },
        beforeAvatarUpload(file) {
            let formData = new FormData()
            formData.append("file", file.raw)
            uploadBusFile(formData).then((res) => {
            })
            // this.importData.file = file
            // this.importData.module = 'company_license'

            console.log(file)
        },

        sub(ruleFormRef) {
            if (!this.checked) {
                return this.$message({
                    type: 'warning',
                    message: '请勾选搜原网官方认证服务协议'
                })
            }
            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!chinaPhoneRegex.test(this.form.shop_phone)) {
                return this.$message.error('请输入正确的手机号码');
            }

            if (!this.form.v_img_url) {
                return this.$message({
                    type: 'warning',
                    message: '请上传营业执照'
                })
            }

            this.$refs[ruleFormRef].validate((valid) => {
                if (valid) {

                    let user_id = window.localStorage.getItem('user_id')
                    let params = {

                        company_name: this.form.shop_name,
                        company_abbrev: this.form.company_abbrev,
                        company_address: this.form.shop_address,
                        company_phone: this.form.shop_phone,
                        company_manage: this.form.company_manage,
                        company_license: this.form.v_img_url,
                        u_attestation: '',
                        bus_legal_person: this.form.bus_legal_person,
                        company_operator: this.form.company_operator
                    }
                    addPersonBus(params).then((res) => {
                        console.log(res, 'res-----');
                        if (res.data.code == 200) {
                            // this.$message({
                            //     type: 'success',
                            //     message: '企业认证申请成功，请等待管理员审核'
                            // })
                            this.$refs.VipDialog4.visible = true

                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.data.msg,
                                offset: 85
                            })
                        }
                    })
                    // this.$router.push({
                    //     path: '/buyerCenter/corVip'
                    // })
                    // if (this.is_bus_auth == 1) {
                    //     this.$refs.VipDialog2.visible = true
                    //     this.$refs.VipDialog2.getCode()
                    // } else if (this.is_bus_auth == 0) {
                    //     let params = {
                    //         company_name: this.form.shop_name,
                    //         company_address: this.form.shop_address,
                    //         company_phone: this.form.shop_phone,
                    //         company_manage: this.form.company_manage,
                    //         company_license: this.form.v_img_url,
                    //         u_attestation: '',
                    //         bus_legal_person: this.form.bus_legal_person
                    //     }
                    //     addPersonBus(params).then((res) => {
                    //         if (res.data.code == 200) {
                    //             this.$message({
                    //                 type: 'success',
                    //                 message: '企业认证申请成功，请等待管理员审核'
                    //             })
                    //         }
                    //     })
                    //     this.$router.push({
                    //         path: '/buyerCenter/qyData'
                    //     })
                    // }


                    // let params = {
                    //     company_name: this.form.shop_name,
                    //     company_address: this.form.shop_address,
                    //     company_phone: this.form.shop_phone,
                    //     company_manage: this.form.company_manage,
                    //     company_license: this.form.v_img_url,
                    //     u_attestation: '',

                    // }
                    // if (this.form.v_img_url) {
                    //     addPersonBus(params).then((res) => {
                    //         if (res.data.code == 200) {
                    //             this.$message({
                    //                 type: 'success',
                    //                 message: '申请成功'
                    //             })
                    //             this.$router.push({ path: '/buyerCenter/enterpriseInfo' })

                    //         }
                    //     })
                    // } else {
                    //     return this.$message({
                    //         type: 'warning',
                    //         message: '请上传营业执照'
                    //     })
                    // }

                } else {
                    return false;
                }
            });
            // this.ComUserInfoHandle()
        },

        back() {
            this.$router.go(-1)
        },
        //修改个人信息
        async ComUserInfoHandle() {
            this.$refs[ruleFormRef].validate((valid) => {
                if (valid) {

                    let user_id = window.localStorage.getItem('user_id')
                    let params = {
                        company_name: this.form.shop_name,
                        company_address: this.form.shop_address,
                        company_phone: this.form.shop_phone,
                        company_manage: this.form.company_manage,
                        company_license: this.form.v_img_url,
                        u_attestation: '',
                        bus_legal_person: this.form.bus_legal_person


                    }
                    addPersonBus(params).then((res) => {
                        if (res.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '申请成功'
                            })
                            this.$router.push({ path: '/buyerCenter/enterpriseInfo' })

                        }
                    })

                } else {
                    return false;
                }
            });


        },

        //个人(企业)信息
        async UserIndexInfo() {
            let user_id = window.localStorage.getItem('user_id')
            getUserById().then((res) => {
                this.user_name = res.data.data.user_name
                this.shop_name = res.data.data.shop_name
                this.shop_phone = res.data.data.shop_phone
                this.user_email = res.data.data.user_email
                this.shop_id = res.data.data.shop_id
                this.imageUrl = res.data.data.business_license
                this.company_manage = res.data.data.company_manage
                this.business_license = res.data.data.business_license
                this.shop_address = res.data.data.shop_address
                this.user_phone = res.data.data.user_phone
                this.bus_legal_person = res.data.data.bus_legal_person
                this.is_bus_auth = res.data.data.is_bus_auth
            })

        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body h1 {
    /* 你的样式 */
    margin: 0;
}

#el-h2 {
    margin: 0
}

.hide_box ::v-deep .el-upload--picture-card {
    display: none;
}

.avatar-uploader .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    // height: 956px;
    height: 135vh;
    // margin-bottom: 50px;
    // padding: 24px;
    // padding-bottom: 50px;
    box-sizing: border-box;
    background-color: white;
    position: relative;

    .title {
        font-size: 21px;
        // border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 136px;
        padding: 70px 0px 5px 0px;
        line-height: 50px;
        margin: auto;
        font-weight: 700;
        width: 100%;
        text-align: center;
        font-size: 48px;
        font-weight: bold;
        // text-align: center;
    }

    .imgBox {

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 60px;

        .img {
            width: 166px;
            height: 104px;
            border-radius: 4px;
            background: #fff;
            border: 1px solid #e5e5e5;
        }

        .imgBtn {
            width: 108px;
            height: 28px;
            border-radius: 33px;
            opacity: 1;
            background: #006eb7;
            border: 1px solid #006eb7;
            line-height: 28px;
            color: #fff;
            text-align: center;
            margin-top: 20px;
            margin-left: 27px;
        }
    }

    .line1 {
        padding: 20px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        // align-items: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            width: 80px;
            height: 28px;
            border-radius: 33px;
            opacity: 1;
            border: 1px solid #006eb7;
            text-align: center;
            line-height: 28px;
            color: white;
            // margin-top: 119px;
            margin-bottom: 10px;
            cursor: pointer;

        }
    }

    .tableBox {
        width: 850px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        ::v-deep .cell {
            font-size: 21px;
            color: #000;
            font-weight: normal !important;
        }

        ::v-deep .el-button--text {
            font-size: 21px;
            color: #000;
            font-weight: normal !important;
            ;
        }
    }

    .formBox {
        width: 333px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 21px;

        .txt1 {
            margin-bottom: 29px;

            .txtName {
                font-size: 21px;
                line-height: 18.72px;
                text-align: left;
                color: #000;
                margin-bottom: 7px;
            }

            .select {
                width: 332px;
                height: 34px;
            }
        }
    }
}

::v-deep .el-upload--picture-card .el-upload__input {
    display: none;
    /* 默认隐藏 */
}

::v-deep .el-upload--picture-card.hide-upload-icon .el-upload__input {
    display: block;
    /* 当有文件时显示 */
}
</style>