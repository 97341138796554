<template>
  <div style="display: flex; width: 100%; margin-top: 20px; margin-left: 15px">
    <!-- <button @click="quxiao">quxiao</button> -->
    <VipDialog ref="VipDialog" />
    <VipDialog3 ref="VipDialog3" />
    <VipDialog5 ref="VipDialog5" />
    <VipDialog7 ref="VipDialog7" />
    <VipDialog8 ref="VipDialog8" />

    <div style="width: 70%">
      <el-dialog
        title="绑定手机号码"
        :visible.sync="dialogFormVisible"
        center
        width="440px"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="closeDia"
        style="padding: 0px"
      >
        <img src="../assets/newImg3/Logo - 搜原网 - syw@1x.png" style="position: absolute; left: -156px; top: 39px" alt="" />
        <el-form :model="form">
          <el-form-item label="手机号码" :label-width="formLabelWidth">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" :label-width="formLabelWidth">
            <el-input v-model="form.code" autocomplete="off" style="width: 136px"></el-input>
            <el-button type="primary" :disabled="ifDisabled" @click="sentCode" style="margin-left: 20px">
              <span v-if="showCode">获取验证码</span>
              <span v-else>{{ wddCount }}</span>
            </el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sentMobile" style="">确认绑定</el-button>
        </div>
      </el-dialog>
      <div class="user">
        <div style="margin-right: 20px; position: relative">
          <img src="@/assets/newImg2/会员标识@1x.png" style="position: absolute; right: 0px; bottom: 5px" alt="" v-if="is_vip" />
          <img :src="head_pic" style="width: 60px; height: 60px; border-radius: 50%; padding-top: 5px" alt="" />
        </div>
        <div>
          <div style="display: flex; padding-top: 12px">
            <div>
              <span style="color: #333333; font-size: 15px; vertical-align: middle">{{ user_name }}</span>
            </div>
            <div style="color: #227bff; padding-left: 10px; cursor: pointer" v-if="!is_vip" @click="getVip">
              <span style="font-weight: 290; vertical-align: middle; font-size: 15px">大v未认证 </span>
            </div>
            <div style="margin-left: 8px" v-if="info.is_bus_vip == 1 && info.distribut_type == 1">
              <!-- <img src="../assets/newImg3/Frame@1x.png" style="vertical-align: middle;" alt=""> -->
              <img src="../assets/newImg3/超级黄金会员.gif" style="width: 52px; height: 17px" alt="" v-if="info.is_super_vip == 1" />
              <img src="../assets/newImg3/黄金会员.gif" style="width: 52px; height: 18px" alt="" v-else-if="info.is_bus_vip == 1" />

              <span v-if="info.is_super_vip == 1" class="vip_sty"
                >超级黄金会员 <span>{{ info.super_vip_arr_time }} </span> 到期</span
              >
              <span v-else-if="info.is_free_vip == 0" class="vip_sty"
                >黄金会员(试用版) <span> {{ info.bus_vip_arr_time }}</span> 到期</span
              >
              <span v-else-if="info.is_bus_vip == 1" class="vip_sty"
                >黄金会员 <span>{{ info.bus_vip_arr_time }}</span> 到期</span
              >
            </div>
            <div style="color: #227bff; padding-left: 10px; cursor: pointer; font-weight: 290; font-size: 15px" v-else-if="distribut_type == 1">
              <span style="vertical-align: middle">企业已认证</span>
            </div>
            <div style="color: #227bff; padding-left: 10px; font-weight: 290; font-size: 15px" v-else-if="distribut_type == 0">
              <span style="vertical-align: middle">企业审核中</span>
            </div>
            <div style="color: #227bff; padding-left: 10px; cursor: pointer; font-weight: 290; font-size: 15px" @click="goPrivi" v-else-if="distribut_type == 3">
              <span style="vertical-align: middle">企业未认证</span>
            </div>
          </div>

          <div style="display: flex; margin-top: 10px; color: #9e9e9e">
            <div>
              关注: <span style="color: black">{{ total_follow_num }}</span>
            </div>
            <div style="padding-left: 18px">
              粉丝: <span style="color: #333333">{{ total_fans_num }}</span>
            </div>
            <div style="padding-left: 18px">
              积分: <span style="color: #333333">{{ pay_points }}</span>
            </div>
            <div style="padding-left: 18px">
              额度: <span style="color: #333333">{{ total_amount }}</span>
            </div>
            <div style="padding-left: 18px">
              等级: <span style="color: #333333">LV{{ levelId }}</span>
            </div>
            <div style="color: #227bff; padding-left: 10px; width: 110px">
              <el-progress :percentage="percentage" style="padding-top: 2px"> </el-progress>
            </div>
            <div style="position: relative; left: -10px">
              <i class="el-icon-question" style="margin-top: 2px; margin-left: 0px" @mouseleave="handleMouseOver2" @mouseenter="handleMouseOver1"></i>
              <div style="position: absolute; top: -37px; left: -10px; z-index: 999; color: gray; font-size: 12px; width: 200px" v-show="cateryShow">
                <div>视频贡献度：3点贡献度；</div>
                <div>资讯贡献度：1点贡献度；</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create">
        <div style="padding: 18px 28px; font-size: 18px; color: #333333">新的创作</div>
        <div class="create-img">
          <div @click="goVideo" style="cursor: pointer">
            <img src="../assets/newImg/按钮 发布视频@1.5x.png" style="width: 240px; height: 65px; margin-left: 20px" alt="" />
          </div>
          <div style="cursor: pointer" @click="goTitle">
            <img src="../assets/newImg/按钮 发布视频@1.5x (1).png" style="width: 240px; height: 65px; margin-left: 20px" alt="" />
          </div>
          <div style="cursor: pointer" @click="toReport">
            <img src="../assets/newImg3/按钮 发布视频@1x (2).png" style="width: 240px; height: 65px; margin-left: 20px" alt="" />
          </div>
        </div>
      </div>
      <div class="vedio">
        <div class="vedio-top">
          <div style="font-size: 20px; padding: 13px; color: #333333">视频数据</div>
          <div style="padding: 20px 15px; color: #9e9e9e; font-size: 12px">统计周期：{{ sevenday }}-{{ today }}</div>
          <div style="padding-top: 13px">
            <el-select v-model="value" placeholder="请选择" style="width: 6rem" size="mini" @change="dayChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>
        <!-- <div
                    style="width:20%;height: 2rem;margin-left: 82%;padding-right: 20px;  color: gray !important;font-size: 15px;margin-top: 2rem;font-weight: 900;">
                    {{ videoTitle }}

                </div> -->
        <div ref="chart" style="width: 100%; height: 216px"></div>
        <div style="width: 100%; height: 10rem; display: flex; flex-wrap: wrap; margin-left: 1rem">
          <div class="box-item" @click="videoNum(1)" :class="{ bgc: classify == 1 }">
            <div>浏览量</div>
            <div style="padding-top: 10px">
              {{ playNum }}
            </div>
          </div>
          <div class="box-item" @click="videoNum(2)" :class="{ bgc: classify == 2 }">
            <div>主页访问量</div>
            <div style="padding-top: 10px">{{ visitedNum }}</div>
          </div>
          <div class="box-item" @click="videoNum(3)" :class="{ bgc: classify == 3 }">
            <div>点赞</div>
            <div style="padding-top: 10px">{{ likeNum }}</div>
          </div>
          <div class="box-item" @click="videoNum(4)" :class="{ bgc: classify == 4 }">
            <div>分享</div>
            <div style="padding-top: 10px">{{ relayNum }}</div>
          </div>
          <div class="box-item" @click="videoNum(5)" :class="{ bgc: classify == 5 }">
            <div>评论</div>
            <div style="padding-top: 10px">{{ commentNum }}</div>
          </div>
          <div class="box-item" @click="videoNum(6)" :class="{ bgc: classify == 6 }">
            <div>粉丝</div>
            <div style="padding-top: 10px">{{ fansNum }}</div>
          </div>
        </div>
      </div>
      <div class="vedio">
        <div class="vedio-top">
          <div style="color: #333333; font-size: 20px; padding: 13px">资讯数据</div>
          <div style="padding: 20px 15px; color: #9e9e9e; font-size: 12px">统计周期：{{ sevenday2 }}-{{ today2 }}</div>
          <div style="padding-top: 13px">
            <el-select v-model="value2" placeholder="请选择" style="width: 6rem" size="mini" @change="dayChange2">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>
        <!-- <div
                    style="width:20%;height: 2rem;margin-left: 82%;padding-right: 20px;  color: gray !important;font-size: 15px;margin-top: 2rem;font-weight: 900;">
                    {{ videoTitle2 }}

                </div> -->
        <div ref="chart2" style="width: 100%; height: 216px"></div>
        <div style="width: 100%; height: 10rem; display: flex; flex-wrap: wrap; margin-left: 2rem">
          <div class="box-item" @click="titleNum(1)" :class="{ bgc: classify2 == 1 }">
            <div>浏览量</div>
            <div style="padding-top: 10px">
              {{ playNum2 }}
            </div>
          </div>
          <div class="box-item" @click="titleNum(2)" :class="{ bgc: classify2 == 2 }">
            <div>主页访问量</div>
            <div style="padding-top: 10px">{{ visitedNum2 }}</div>
          </div>
          <div class="box-item" @click="titleNum(3)" :class="{ bgc: classify2 == 3 }">
            <div>点赞</div>
            <div style="padding-top: 10px">{{ likeNum2 }}</div>
          </div>
          <div class="box-item" @click="titleNum(4)" :class="{ bgc: classify2 == 4 }">
            <div>分享</div>
            <div style="padding-top: 10px">{{ relayNum2 }}</div>
          </div>
          <div class="box-item" @click="titleNum(5)" :class="{ bgc: classify2 == 5 }">
            <div>评论</div>
            <div style="padding-top: 10px">{{ commentNum2 }}</div>
          </div>
          <div class="box-item" @click="titleNum(6)" :class="{ bgc: classify2 == 6 }">
            <div>粉丝</div>
            <div style="padding-top: 10px">{{ fansNum2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 26%; padding: 2px 20px">
      <div class="lunbo">
        <!-- <div style="width: 100%;height: 169px;position: absolute;top: 400px;z-index: 9999;">
                    <img src="../assets/newImg/矩形 1@1x.png" style="width: 100px;height: 169px;" alt="">
                </div> -->
        <el-carousel arrow="always" height="169px">
          <el-carousel-item>
            <img src="../assets/newImg/Banner 工业原材料商城@1x.png" style="width: 100%; height: 169px; border-radius: 8px" />
          </el-carousel-item>

          <el-carousel-item>
            <img src="../assets/newImg/Banner 工业原材料商城@1x.png" style="width: 100%; height: 169px; border-radius: 8px" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="background-color: #ffffff; height: 745px">
        <div
          style="background-color: #ffffff; height: 55px; line-height: 55px; margin-top: 20px; border-radius: 4px; padding-left: 5px; display: flex; justify-content: space-between; padding: 0px 10px"
        >
          <div style="font-size: 18px; color: #333333; font-weight: 400">热门视频</div>
          <div style="color: #9e9e9e; font-size: 12px; cursor: pointer" @click="lookMany">查看更多</div>
        </div>
        <div style="width: 100%; height: 690px; display: flex; flex-wrap: wrap">
          <div style="height: 320px; width: 44%; margin-left: 12px; border-radius: 4px; cursor: pointer" @click="toVedio(item)" v-for="item in videoList">
            <div style="width: 100%; height: 266px">
              <img :src="item.vImgUrl" style="width: 100%; height: 100%; object-fit: cover; border-radius: 10px" alt="" />
            </div>
            <div style="color: #333333; font-size: 15px; font-weight: 400; padding: 3px 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ item.vTitle }}
            </div>
            <div style="color: #9499a0; font-size: 13px; padding: 1px 5px">
              <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

              <span style="vertical-align: middle; color: #9499a0; font-size: 12px"> {{ item.vUploader }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoInfoByUserId, getConsultInfoByUserId, getUserById, getHotVod, WeiChatCallback, getCode, updateUserByCode, unBind, getUserMsg } from "@/api/user";
import VipDialog from "../components/VipDialog.vue";
import VipDialog3 from "../components/VipDialog3.vue";
import VipDialog5 from "../components/VipDialog5.vue";
import VipDialog7 from "../components/VipDialog7.vue";
import VipDialog8 from "../components/VipDialog8.vue";

import dayjs from "dayjs";
export default {
  data() {
    return {
      showCode: true,
      loading: "",
      ifDisabled: false,
      formLabelWidth: "90px",
      wddCount: 60,
      ifClose: true,
      form: {
        code: "",
        phone: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      distribut_type: "",
      dialogFormVisible: false,
      info: {},
      cateryShow: false,
      percentage: 0,
      levelId: 0,
      videoList: [],
      sevenday: "",
      today: "",
      sevenday2: "",
      today2: "",
      value2: "7",
      classify: 1,
      classify2: 1,
      videoTitle: "浏览量",
      videoTitle2: "浏览量",
      mark: 1,
      mark2: 1,
      value: "7",
      options: [
        {
          value: "7",
          label: "近7天"
        },
        {
          value: "15",
          label: "近15天"
        }
      ],
      options2: [
        {
          value: "7",
          label: "近7天"
        },
        {
          value: "15",
          label: "近15天"
        }
      ],
      is_vip: "",
      total_follow_num: "",
      pay_points: "",
      total_fans_num: "",
      total_amount: "",
      head_pic: "",
      user_name: "",
      zheyList: [],
      zheyList2: [],
      zhexList: [],
      zhexList2: [],
      collectNum: 0, //收藏
      commentNum: 0, //评论数量
      fansNum: 0, //粉丝数量
      likeNum: 0, //点赞数量
      playNum: 0, //播放量
      relayNum: 0, //转发量
      visitedNum: 0, //访问主页量

      collectNum2: 0, //收藏
      commentNum2: 0, //评论数量
      fansNum2: 0, //粉丝数量
      likeNum2: 0, //点赞数量
      playNum2: 0, //播放量
      relayNum2: 0, //转发量
      visitedNum2: 0 //访问主页量
    };
  },
  components: {
    VipDialog,
    VipDialog3,
    VipDialog5,
    VipDialog7,
    VipDialog8
  },
  methods: {
    goVideo() {
      this.$router.push({
        path: "/buyerCenter/contribute",
        query: {
          video: 1
        }
      });
    },
    goTitle() {
      this.$router.push({
        path: "/buyerCenter/contribute",
        query: {
          video: 2
        }
      });
    },

    closeDia() {
      // window.location.reload()
    },
    quxiao() {
      unBind().then((res) => {});
    },
    sentMobile() {
      console.log(this.form.phone, "this.form.phone");
      if (!this.form.phone) {
        return;
      }
      let params = {
        mobile: this.form.phone,
        code: this.form.code,
        openid: this.openid
        // openid: 'oDKHO6a0bT_Ofjbq6IyxuOX86SEU'
      };
      updateUserByCode(params).then((res) => {
        console.log(res, "绑定成功");
        if (res.data.code == 200) {
          window.localStorage.setItem("token", res.data.data.token);
          window.localStorage.setItem("user_id", res.data.data.userId);
          window.localStorage.setItem("userName", res.data.data.userName);
          window.localStorage.setItem("headPic", res.data.data.headPic);
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "绑定成功",
            offset: 82
          });
          // window.location.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    sentCode() {
      let params = {
        mobile: this.form.phone
      };
      this.ifDisabled = true;
      getCode(params).then((res) => {
        console.log(res, "我是res.s");
        if (res.data.code == 200) {
          this.showCode = false;
          this.positionTimer = setInterval(() => {
            if (this.wddCount < 1) {
              clearInterval(this.positionTimer);
              this.positionTimer = null;

              this.showCode = true;
              this.ifDisabled = false;
              this.wddCount = 60;
            } else {
              this.ifDisabled = true;
              this.showCode = false;
              this.wddCount -= 1;
            }
          }, 1000);
        }
      });
    },
    toReport() {
      console.log(this.info.distribut_type);
      if (this.info.distribut_type == 3) {
        this.$refs.VipDialog3.visible = true;
      } else if (this.info.distribut_type == 0) {
        this.$refs.VipDialog7.visible = true;
      } else if (this.info.distribut_type == 2) {
        this.$refs.VipDialog8.visible = true;
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.VipDialog5.visible = true;
      } else {
        this.$router.push({
          path: "/buyerCenter/productPrice"
        });
      }
      // if (this.info.distribut_type == 1 && this.info.is_bus_vip == 1) {
      //     this.$router.push({
      //         path: '/buyerCenter/productPrice'
      //     })

      // } else if (this.info.distribut_type == 3 && this.info.is_bus_vip == 0) {
      //     this.$refs.VipDialog3.visible = true

      // } else if (this.info.distribut_type == 3) {
      //     this.$refs.VipDialog3.visible = true

      // }
      // else if (this.info.is_bus_vip == 0) {
      //     this.$refs.VipDialog5.visible = true
      // } else if (this.info.distribut_type != 3) {
      //     this.$router.push({
      //         path: '/buyerCenter/corVip'
      //     })
      // }
    },
    handleMouseOver1() {
      this.cateryShow = true;
    },
    handleMouseOver2() {
      this.cateryShow = false;
    },
    getVip() {
      this.$refs.VipDialog.visible = true;
      this.$refs.VipDialog.getCode();
    },
    goPrivi() {
      this.$router.push({
        path: "/buyerCenter/amendEnterpriseInfo"
      });
    },
    lookMany() {
      const { href } = this.$router.resolve({
        path: "/hotVideo"
      });
      window.open(href, "_blank");
    },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId
        }
      });
      window.open(href, "_blank");
    },
    dayChange(index) {
      this.index = index;
      const sevenDaysAgo = dayjs().subtract(index, "day");

      // 格式化日期为 YYYY.MM.DD 的形式
      this.sevenday = sevenDaysAgo.format("YYYY.MM.DD");

      // console.log(data, '..1111');
      getVideoInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: this.index,
        mark: this.mark
      }).then((res) => {
        this.collectNum = res.data.data.videoMap.collectNum;
        this.commentNum = res.data.data.videoMap.commentNum;
        this.fansNum = res.data.data.videoMap.fansNum;
        this.likeNum = res.data.data.videoMap.likeNum;
        this.playNum = res.data.data.videoMap.playNum;
        this.relayNum = res.data.data.videoMap.relayNum;
        this.zhexList = res.data.data.VideoPlayNum.map((item) => {
          return item.dates;
        });
        this.zheyList = res.data.data.VideoPlayNum.map((item) => {
          return item.play_num;
        });

        this.getEchartData();
      });
    },
    dayChange2(index) {
      this.index2 = index;
      const sevenDaysAgo = dayjs().subtract(index, "day");

      // 格式化日期为 YYYY.MM.DD 的形式
      this.sevenday2 = sevenDaysAgo.format("YYYY.MM.DD");
      getConsultInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: this.index2,
        mark: this.mark2
      }).then((res) => {
        this.collectNum2 = res.data.data.consultMap.collectNum;
        this.commentNum2 = res.data.data.consultMap.commentNum;
        this.fansNum2 = res.data.data.consultMap.fansNum;
        this.likeNum2 = res.data.data.consultMap.likeNum;
        this.playNum2 = res.data.data.consultMap.playNum;
        this.relayNum2 = res.data.data.consultMap.relayNum;
        this.zhexList2 = res.data.data.consultPlayNum.map((item) => {
          return item.dates;
        });
        this.zheyList2 = res.data.data.consultPlayNum.map((item) => {
          return item.play_num;
        });
        this.getEchartData2();
      });
    },
    videoNum(num) {
      if (num == 1) {
        this.videoTitle = "浏览量";
        this.classify = 1;
      } else if (num == 2) {
        this.videoTitle = "主页访问量";
        this.classify = 2;
      } else if (num == 3) {
        this.classify = 3;
        this.videoTitle = "点赞";
      } else if (num == 4) {
        this.classify = 4;
        this.videoTitle = "分享";
      } else if (num == 5) {
        this.classify = 5;
        this.videoTitle = "评论";
      } else if (num == 6) {
        this.classify = 6;
        this.videoTitle = "粉丝";
      }
      this.mark = num;
      getVideoInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: this.value,
        mark: this.mark
      }).then((res) => {
        this.collectNum = res.data.data.videoMap.collectNum;
        this.commentNum = res.data.data.videoMap.commentNum;
        this.fansNum = res.data.data.videoMap.fansNum;
        this.likeNum = res.data.data.videoMap.likeNum;
        this.playNum = res.data.data.videoMap.playNum;
        this.relayNum = res.data.data.videoMap.relayNum;
        this.zhexList = res.data.data.VideoPlayNum.map((item) => {
          return item.dates;
        });
        this.zheyList = res.data.data.VideoPlayNum.map((item) => {
          return item.play_num;
        });

        this.getEchartData();
      });
    },
    titleNum(num) {
      this.index2 = num;
      this.mark2 = num;
      if (num == 1) {
        this.videoTitle2 = "浏览量";
        this.classify2 = 1;
      } else if (num == 2) {
        this.videoTitle2 = "主页访问量";
        this.classify2 = 2;
      } else if (num == 3) {
        this.classify2 = 3;
        this.videoTitle2 = "点赞";
      } else if (num == 4) {
        this.classify2 = 4;
        this.videoTitle2 = "分享";
      } else if (num == 5) {
        this.classify2 = 5;
        this.videoTitle2 = "评论";
      } else if (num == 6) {
        this.classify2 = 6;
        this.videoTitle2 = "粉丝";
      }

      getConsultInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: this.value2,
        mark: this.mark2
      }).then((res) => {
        this.collectNum2 = res.data.data.consultMap.collectNum;
        this.commentNum2 = res.data.data.consultMap.commentNum;
        this.fansNum2 = res.data.data.consultMap.fansNum;
        this.likeNum2 = res.data.data.consultMap.likeNum;
        this.playNum2 = res.data.data.consultMap.playNum;
        this.relayNum2 = res.data.data.consultMap.relayNum;
        this.zhexList2 = res.data.data.consultPlayNum.map((item) => {
          return item.dates;
        });
        this.zheyList2 = res.data.data.consultPlayNum.map((item) => {
          return item.play_num;
        });

        this.getEchartData2();
        console.log(this.zhexList, " this.zhexList");
        console.log(this.zheyList, " this.zhexList");
      });
    },
    getEchartData() {
      console.log(this.videoTitle, "MMMMMMMMMMMMMM");

      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        let czh = this.videoTitle;
        const option = {
          tooltip: {
            trigger: "axis", // 触发类型，可选为'item'、'axis'
            axisPointer: {
              type: "shadow" // 指示器类型
            },
            formatter: function (params) {
              // 自定义显示内容，params 是包含当前数据点信息的数组
              var value = params[0].value; // 获取当前数据点的值
              var seriesName = params[0].seriesName; // 获取系列名称
              // 根据需要格式化显示内容
              return `${czh + ":" + value}`;
            }
          },
          xAxis: {
            type: "category",
            data: this.zhexList
          },
          // 假设这是你的视频标题
          yAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333"
              },
              formatter: function (value, index) {
                // console.log(value, this.videoTitle, 'MMMMMMMMMMMMMM');
                // 在这里添加你的单位，例如 'm'
                return value;
              }
            }
          },
          series: [
            {
              lineStyle: {
                color: "rgb(34,134,255)" // 设置线的颜色为红色
              },
              itemStyle: {
                color: "rgb(34,134,255)" // 设置数据点的颜色为蓝色
              },
              data: this.zheyList,
              type: "line"
            }
          ]
        };
        myChart.setOption(option);
      }
    },
    getEchartData2() {
      const chart2 = this.$refs.chart2;
      if (chart2) {
        const myChart = this.$echarts.init(chart2);
        let czh2 = this.videoTitle2;
        const option = {
          tooltip: {
            trigger: "axis", // 触发类型，可选为'item'、'axis'
            axisPointer: {
              type: "shadow" // 指示器类型
            },
            formatter: function (params) {
              // 自定义显示内容，params 是包含当前数据点信息的数组
              var value = params[0].value; // 获取当前数据点的值
              var seriesName = params[0].seriesName; // 获取系列名称
              // 根据需要格式化显示内容
              return `${czh2 + ":" + value}`;
            }
          },
          xAxis: {
            type: "category",
            data: this.zhexList2
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333"
              },
              formatter: function (value, index) {
                // 在这里添加你的单位，例如 'm'
                return value;
              }
            }
          },
          series: [
            {
              lineStyle: {
                color: "rgb(34,134,255)" // 设置线的颜色为红色
              },
              itemStyle: {
                color: "rgb(34,134,255)" // 设置数据点的颜色为蓝色
              },
              data: this.zheyList2,
              type: "line"
            }
          ]
        };
        myChart.setOption(option);
      }
    }
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.2)"
    });

    let a = window.localStorage.getItem("user_id");
    if (this.$route.query.code != null) {
      if (a == null || a == undefined) {
        let params = {
          code: this.$route.query.code,
          state: ""
        };
        WeiChatCallback(params).then((res) => {
          if (res.data.code == 4014) {
            this.dialogFormVisible2 = true;
            return;
          }
          this.openid = res.data.data.openid;

          if (res.data.code == 200) {
            if (res.data.data.index == 0) {
              this.dialogFormVisible = true;
              window.localStorage.setItem("token", res.data.data.token);
              window.localStorage.setItem("user_id", res.data.data.userId);
              window.localStorage.setItem("userName", res.data.data.userName);
              window.localStorage.setItem("headPic", res.data.data.headPic);
            } else if (res.data.data.index == 1) {
              window.localStorage.setItem("token", res.data.data.token);
              window.localStorage.setItem("user_id", res.data.data.userId);
              window.localStorage.setItem("userName", res.data.data.userName);
              window.localStorage.setItem("headPic", res.data.data.headPic);
              this.dialogFormVisible = false;
              // this.$router.go(0);
            }

            getUserMsg({
              msg_type: 1,
              is_read: 0,
              page: 1,
              size: 100000
            }).then((res) => {
              this.$bus.$emit("userMsg", { userMsg: res.data });
            });
            getUserById().then((res) => {
              if (res.data.code == 200) {
                this.loading.close();
                this.$bus.$emit("userData", { userData: res.data.data });

                this.is_vip = res.data.data.is_vip;
                this.user_name = res.data.data.user_name;
                this.head_pic = res.data.data.head_pic;
                this.total_follow_num = res.data.data.total_follow_num;
                this.total_fans_num = res.data.data.total_fans_num;
                this.total_amount = res.data.data.total_amount;
                this.pay_points = res.data.data.pay_points;
                this.levelId = res.data.data.vip_info?.levelId;
                this.percentage = res.data.data.user_exp;
                this.distribut_type = res.data.data.distribut_type;
                this.info = res.data.data;
              } else {
                return;
              }
            });
            let params = {
              title: "",
              size: 4,
              page: 1
            };
            getHotVod(params).then((res) => {
              this.videoList = res.data.data;
              this.total = res.data.totalPage;
            });
            getVideoInfoByUserId({
              user_id: window.localStorage.getItem("user_id"),
              day: 7,
              mark: 1
            }).then((res) => {
              console.log(res, "res===");
              if (res.data.code == 200) {
                this.collectNum = res.data.data.videoMap.collectNum;
                this.commentNum = res.data.data.videoMap.commentNum;
                this.fansNum = res.data.data.videoMap.fansNum;
                this.likeNum = res.data.data.videoMap.likeNum;
                this.playNum = res.data.data.videoMap.playNum;
                this.relayNum = res.data.data.videoMap.relayNum;
                this.zhexList = res.data.data.VideoPlayNum.map((item) => {
                  return item.dates;
                });
                this.zheyList = res.data.data.VideoPlayNum.map((item) => {
                  return item.play_num;
                });
                this.getEchartData();
              } else {
                return;
              }
            });

            getConsultInfoByUserId({
              user_id: window.localStorage.getItem("user_id"),
              day: 7,
              mark: this.mark2
            }).then((res) => {
              this.collectNum2 = res.data.data.consultMap.collectNum;
              this.commentNum2 = res.data.data.consultMap.commentNum;
              this.fansNum2 = res.data.data.consultMap.fansNum;
              this.likeNum2 = res.data.data.consultMap.likeNum;
              this.playNum2 = res.data.data.consultMap.playNum;
              this.relayNum2 = res.data.data.consultMap.relayNum;
              this.zhexList2 = res.data.data.consultPlayNum.map((item) => {
                return item.dates;
              });
              this.zheyList2 = res.data.data.consultPlayNum.map((item) => {
                return item.play_num;
              });

              this.getEchartData2();
            });
            const now = dayjs();

            // 获取七天前的日期
            const sevenDaysAgo = now.subtract(7, "day");

            // 格式化日期为 YYYY.MM.DD 的形式
            this.sevenday = sevenDaysAgo.format("YYYY.MM.DD");
            this.today = dayjs().format("YYYY.MM.DD");
            this.sevenday2 = sevenDaysAgo.format("YYYY.MM.DD");
            this.today2 = dayjs().format("YYYY.MM.DD");

            this.getEchartData2();
          }
        });
      }
    } else {
      getUserMsg({
        msg_type: 1,
        is_read: 0,
        page: 1,
        size: 100000
      }).then((res) => {
        this.$bus.$emit("userMsg", { userMsg: res.data });
      });
      getUserById().then((res) => {
        this.$bus.$emit("userData", { userData: res.data.data });
        this.loading.close();

        if (res.data.code == 200) {
          this.is_vip = res.data.data.is_vip;
          this.user_name = res.data.data.user_name;
          this.head_pic = res.data.data.head_pic;
          this.total_follow_num = res.data.data.total_follow_num;
          this.total_fans_num = res.data.data.total_fans_num;
          this.total_amount = res.data.data.total_amount;
          this.pay_points = res.data.data.pay_points;
          this.levelId = res.data.data.vip_info.levelId;
          this.percentage = res.data.data.user_exp;
          this.distribut_type = res.data.data.distribut_type;
          this.info = res.data.data;
        } else {
          return;
        }
      });
      let params = {
        title: "",
        size: 4,
        page: 1
      };
      getHotVod(params).then((res) => {
        this.videoList = res.data.data;
        this.total = res.data.totalPage;
      });
      getVideoInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: 7,
        mark: 1
      }).then((res) => {
        console.log(res, "res===");
        if (res.data.code == 200) {
          this.collectNum = res.data.data.videoMap.collectNum;
          this.commentNum = res.data.data.videoMap.commentNum;
          this.fansNum = res.data.data.videoMap.fansNum;
          this.likeNum = res.data.data.videoMap.likeNum;
          this.playNum = res.data.data.videoMap.playNum;
          this.relayNum = res.data.data.videoMap.relayNum;
          this.zhexList = res.data.data.VideoPlayNum.map((item) => {
            return item.dates;
          });
          this.zheyList = res.data.data.VideoPlayNum.map((item) => {
            return item.play_num;
          });
          this.getEchartData();
        } else {
          return;
        }
      });

      getConsultInfoByUserId({
        user_id: window.localStorage.getItem("user_id"),
        day: 7,
        mark: this.mark2
      }).then((res) => {
        this.collectNum2 = res.data.data.consultMap.collectNum;
        this.commentNum2 = res.data.data.consultMap.commentNum;
        this.fansNum2 = res.data.data.consultMap.fansNum;
        this.likeNum2 = res.data.data.consultMap.likeNum;
        this.playNum2 = res.data.data.consultMap.playNum;
        this.relayNum2 = res.data.data.consultMap.relayNum;
        this.zhexList2 = res.data.data.consultPlayNum.map((item) => {
          return item.dates;
        });
        this.zheyList2 = res.data.data.consultPlayNum.map((item) => {
          return item.play_num;
        });

        this.getEchartData2();
      });
      const now = dayjs();

      // 获取七天前的日期
      const sevenDaysAgo = now.subtract(7, "day");

      // 格式化日期为 YYYY.MM.DD 的形式
      this.sevenday = sevenDaysAgo.format("YYYY.MM.DD");
      this.today = dayjs().format("YYYY.MM.DD");
      this.sevenday2 = sevenDaysAgo.format("YYYY.MM.DD");
      this.today2 = dayjs().format("YYYY.MM.DD");

      this.getEchartData2();
    }
  }
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-input__inner .el-select__caret .el-input__icon .el-icon-arrow-up {
//     height: 32px !important;
// }
.el-select .el-select__wrapper {
  height: 32px;
  /* 自定义的高度 */
  line-height: 32px;
  /* 与高度保持一致，使文本垂直居中 */
}

.user {
  display: flex;
  width: 100%;
  height: 70px;

  .vip_sty {
    font-weight: 290;
    color: #227bff;
    font-size: 15px;
    margin-right: 5px;
    vertical-align: middle;
    margin-left: 2px;
  }
}

.create {
  margin-top: 30px;
  width: 100%;
  height: 9rem;
  background: #ffffff;
  opacity: 1;

  .create-img {
    width: 100%;
    height: 6rem;
    display: flex;
  }
}

.vedio {
  height: 28rem;
  width: 100%;
  margin-top: 20px;
  background-color: #ffffff;
  opacity: 1;

  .vedio-top {
    width: 100%;
    height: 3.05rem;
    display: flex;
  }
}

.box-item {
  text-align: center;
  width: 22%;
  height: 2rem;
  padding: 1.5rem;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
}

.box-item:hover {
  color: rgb(32, 123, 255) !important;
}

.bgc {
  color: rgb(32, 123, 255) !important;
}

.lunbo {
  margin-top: 6.2rem;
  width: 100%;
  height: 165px;
  background-color: #ffffff;
  opacity: 1;

  // background-color: red;
  border-radius: 8px;
}
</style>
