<template>
    <div class="content">
        <searchBar />
        <!-- <div class="line"></div> -->
        <div class="classifyBox">
            <classifyBar />

        </div>

        <div class="location">
            <span>当前位置：</span>
            <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/navigation' }">网页导航</el-breadcrumb-item>
                </el-breadcrumb></div>
        </div>

        <div class="line1" v-for="(item, index) in WebNav">
            <div class="title">{{ item.name }}</div>
            <div class="selectBox">
                <div class="select" v-for="(item2, index2) in item.son" @click="skipPage(item2.link)">{{ item2.name }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'

export default {
    data() {
        return {
            WebNav: ''
        }
    },
    methods: {
        //网站导航
        async getWebNav() {
            let res = await this.$http('/mini/ApiConnect/getWebNav')
            // let links = ['index', 'buyerCenter/message', 'quotedPrice', '', 'consult', 'strictStore', 'aboutUs', '', 'buyerCenter', 'buyerCenter', 'aboutUs']
            // res.result.forEach((e, index) => {
            //     e.son.forEach((e2, index2) => {
            //         e2.link = links[index2]
            //     })
            // })
            this.WebNav = res.result
        },

        skipPage(link) {
            window.location = link
        }
    },
    components: {
        searchBar,
        classifyBar,

    },

    mounted() {
        this.getWebNav()
    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 1920px;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.line1 {
    width: 100%;
    background-color: white;
    padding: 7px;
    box-sizing: border-box;
    margin-top: 25px;
    box-shadow: 0 4px 4px #00000014;

    .title {
        width: 1176px;
        height: 40px;
        border-bottom: 1px solid #bdbdbd;
        line-height: 40px;
        margin: 0 auto;
        padding-left: 10px;
        box-sizing: border-box;

    }

    .selectBox {
        display: flex;
        flex-wrap: wrap;

        .select {
            cursor: pointer;
            width: 168px;
            height: 50px;
            line-height: 50px;
            text-align: center;

        }
    }
}
</style>