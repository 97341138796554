<template>
    <div>
        <searchBar v-show="search"></searchBar>
        <div class="quotedBox">
            <div class="title" v-show="!search">关注的商家 </div>

            <div class="line1 " v-for="(item, index) in UserInterestList">
                <div class="left">
                    <div>
                        <el-avatar :size="120" src="https://empty" @error="errorHandler">
                            <img :src="$img + item.head_pic" />
                        </el-avatar>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="title2" style="font-weight: 700;">
                            <div style="display: flex;align-items: center;">
                                <div>{{ item.shop_name }}</div>
                                <div class="yes" v-if="item.is_attestation == 1">认证企业 <i class="el-icon-success"
                                        style="color: #299e19;"></i></div>
                                <div class="no" v-else>未认证 </div>
                            </div>

                        </div>

                        <div class="title2" style="font-size: 16px;margin: 16px 0;">
                            <!-- 联系电话：{{ item.shop_phone }} -->
                            <i class="el-icon-phone-outline"> {{ item.shop_phone }}</i>

                        </div>

                        <div class="title2" style="font-size: 16px;">
                            <!-- 联系人：{{ item.company_real_name }} -->
                            <i class="el-icon-office-building"> {{ item.company_real_name }}</i>
                        </div>
                    </div>

                </div>
                <a style="cursor: pointer;" class="right" @click="skip(item.com_id)">
                    商家主页
                </a>
            </div>
            <el-empty description="您没有关注商家" v-if="UserInterestList?.rows?.length < 1"></el-empty>

        </div>
    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import { getInterestByUserId } from '@/api/user'
export default {
    components: {
        searchBar
    },
    data() {
        return {
            UserInterestList: '',
            search: '',
            keyword: ''
        }
    },
    created() {
        let uu = this.$route.query.keywords
        if (uu) {
            this.search = uu
            this.keyword = uu
        } else {
            this.search = ''
        }
    },
    methods: {
        skip(id) {
            window.location = '/storeHome?com_id=' + id
        },
        //关注的商家列表
        async getUserInterestList() {
            let user_id = window.localStorage.getItem('user_id')

            getInterestByUserId({ user_id }).then((res) => {
                this.UserInterestList = res.data.data
                console.log(res, '》》》》》》》》》》》》》');
            })
            //     let params = { user_id: userId,keyword:this.keyword } 
            // let res = await this.$http('/mini/ApiConnect/getUserInterestList', params)
            // this.UserInterestList = res.result




        },
    },
    mounted() {
        this.getUserInterestList()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;

    .title {
        font-size: 16px;
        // border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        background-color: white;
        height: 50px;
        line-height: 50px;
        font-weight: 700;

    }


    .line1 {
        padding: 20px;
        // border-bottom: 1px solid #e4e4e4;
        background-color: white;

        box-sizing: border-box;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        position: relative;
        border-radius: 10px;

        .left {
            width: 65%;
            display: flex;
            align-items: center;

            .title2 {
                font-size: 20px;
                margin-right: 45px;

                .yes {
                    margin-left: 20px;
                    width: 86px;
                    height: 21px;
                    border-radius: 10px;
                    opacity: 1;
                    border: 1px solid #006eb7;
                    line-height: 21px;
                    text-align: center;
                    opacity: 1;
                    color: #006eb7;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: "Microsoft YaHei UI";
                }

                .no {
                    margin-left: 40px;
                    width: 86px;
                    height: 21px;
                    border-radius: 10px;
                    opacity: 1;
                    border: 1px solid #f11d1d;
                    line-height: 21px;
                    text-align: center;
                    opacity: 1;
                    color: #f11d1d;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: "Microsoft YaHei UI";
                }
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 20%;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            // background: #006eb7;
            border: 1px solid #177ea7;
            text-align: center;
            line-height: 36px;
            color: #177ea7;
            position: absolute;
            right: 48px;
        }

        .right:hover {
            background-color: #006eb7;
            color: #fff;
        }
    }
}
</style>