<template>
    <div class="box" @mouseenter="allMouseEnter()">
        <div style="width: 100vw;height: 400px;margin-top: 0px;">
            <el-carousel :interval="2000" arrow="always" height="550px">
                <el-carousel-item>
                    <img src="../assets/newImg/Banner 工业原材料商城@1x.png" style="width: 100vw; height: 550px" />
                </el-carousel-item>

                <el-carousel-item>
                    <img src="../assets/newImg/轮播图 大宗原材料商城，供应链金融@1x.png" style="width: 100vw; height: 550px" />
                </el-carousel-item>

                <el-carousel-item>
                    <img src="../assets/newImg/轮播图 中海南联原材料商城@1x.png" style="width: 100vw; height: 550px" />
                </el-carousel-item>

            </el-carousel>
            <!-- <el-carousel :interval="4000" type="card" height="400px">
                <el-carousel-item>
                    <img src="../assets/zhnlImg/轮播图 大宗原材料商城，供应链金融 820x355 20240516.png"
                        style="width: 880px; height: 400px" />
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" style="width: 880px; height: 400px" />
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/zhnlImg/轮播图 中海南联原材料商城 820x355 20240516.png"
                        style="width: 880px; height: 400px" />
                </el-carousel-item>
            </el-carousel> -->
        </div>
        <div class="content2">
            <!-- <searchBar />
            <classifyBar @isFBtn="isFBtn" :classify="4" :isNo="1" />
            <div class="location">
                <span>当前位置：</span>
                <div class="nav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">主页</el-breadcrumb-item>
                        <el-breadcrumb-item to=""><span @click="$router.go(0)">{{ showName
                                }}</span></el-breadcrumb-item>
                        <el-breadcrumb-item>{{ goodsName }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div> -->

            <!-- <div class="wdd-content">
                <div class="left-">
                    <el-carousel height="355px" width="820px" arrow="always">
                        <el-carousel-item>
                            <img src="../assets/zhnlImg/轮播图 大宗原材料商城，供应链金融 820x355 20240516.png"
                                style="width: 720px; height: 355px" />
                        </el-carousel-item>

                        <el-carousel-item>
                            <img src="../assets/3a1b573801371d71b0e81cc47263a05.png"
                                style="width: 720px; height: 355px" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img src="../assets/zhnlImg/轮播图 中海南联原材料商城 820x355 20240516.png"
                                style="width: 720px; height: 355px" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right-">
                    <div class="wdd-box" v-for="item in videoList" @click="goVideo(item)" style="  cursor: pointer;">
                        <div class="right-item">
                            <div style="height: 100%;width: 100%;">
                                <img :src="item.vImgUrl" style="height: 100%;width: 100%;object-fit: cover;" alt="">
                            </div>

                        </div>
                        <div class="right-item2">
                            <div class="right-item2-1">
                                {{ item.vDesc }}

                            </div>
                            <div class="right-item2-2">
                                <img src="../assets/zhnlImg/Clip path group@1x.png" alt=""
                                    style="vertical-align: middle;">
                                <span style="vertical-align: middle;">
                                    {{ item.vAddTime }}
                                </span>

                            </div>
                        </div>
                    </div>


                </div>
            </div> -->
            <div class="topInfo" style="margin-top: 16px;">
                <div class="ttu" v-show="isF == 1" @mouseleave="closeIsF">
                    <div class="ttp">
                        <classifyContain />
                    </div>
                </div>
                <div class="priceBox">
                    <div class="top">
                        <div style="
                                display: flex;
                                justify-content: space-between;
                            ">
                            <div class="title op" style="line-height: 20px;">
                                <!-- {{ showName }} -->
                                <!-- 主力厂商产品价格走势 -->
                                <span style="color:#227BFF; font-size: 20px;">主流产品价格展示</span>
                                <span style="margin-left: 20px;font-size: 14px;color: #9499A0;font-weight: 300;">{{
        dateFormat()
    }}</span>
                            </div>
                            <!-- <div style="font-size: 14px">
                                {{ dateFormat() }}
                            </div> -->
                        </div>
                        <div style="border: 1px solid #e3e3e3; margin-top: 5px"></div>
                        <div @click="hd_data_price($event)">
                            <vue-seamless-scroll :data="randomMainstreamList" class="seamless-warp"
                                style="width: 100%; overflow: hidden" :class-option="classOption">
                                <div class="contaionBox" style="  cursor: pointer;">
                                    <!-- @click="oneprice(item)" -->
                                    <div class="line1" :data-id="item.id" :data-name="item.label"
                                        @click="hd_data_price2(item)" v-for="(
                                            item, index
                                        ) in randomMainstreamList" v-if="item.goodsName">
                                        <div class="shelter" :data-item="JSON.stringify(item)"></div>
                                        <!-- <div style="color:gray;font-size: 14px;">{{ item.busName }}</div>
                                        <div style="color:gray;font-size: 14px;">{{ item.addTime }}</div>
                                        <div style="color: #006eb7;font-size: 14px;">{{ item.catTwoName }}</div> -->

                                        <!-- <div style="color: gray;font-size: 14px;">{{ item.addTime }}</div> -->
                                        <div class="top2">

                                            <div style="color: #227BFF;font-size: 14px;font-weight: 400;">{{
        item.catTwoName }}</div>
                                            <div style="color: #000000;font-size: 14px;font-weight: 400;">{{
        item.goodsName }}</div>
                                            <!-- <div v-if="item.isUp == 0" style="">
                                                {{ item.bqPrice }}
                                            </div> -->
                                            <div style="color: #21a00c" v-if="item.isUp == 0">
                                                {{ item.bqPrice }}
                                            </div>
                                            <div style="color: #ff0c0c" v-if="item.isUp == 1">
                                                {{ item.bqPrice }}
                                            </div>
                                        </div>

                                        <div class="bottom">
                                            <div class="left">
                                                <img src="../assets/newImg/路径@1x (5).png" v-if="item.isUp == 0" />
                                                <img src="../assets/newImg/路径@1x (4).png" v-if="item.isUp == 1" />
                                                <!-- <span v-if="item.isUp == 0">
                                                    {{ item.upPrice }}
                                                </span> -->
                                                <span style="color: #21a00c" v-if="item.isUp == 0">
                                                    {{ item.upPrice }}
                                                </span>
                                                <span style="color: #ff0c0c" v-if="item.isUp == 1">
                                                    {{ item.upPrice }}
                                                </span>
                                            </div>
                                            <div class="right">
                                                <!-- <span v-if="item.isUp == 0">
                                                    {{ item.upPercentage }}%
                                                </span> -->
                                                <span style="color: #21a00c" v-if="item.isUp == 0">
                                                    {{ item.upPercentage }}%
                                                </span>
                                                <span style="color: #ff0c0c" v-if="item.isUp == 1">
                                                    {{ item.upPercentage }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vue-seamless-scroll>
                        </div>
                    </div>

                    <!-- <div class="top" style="margin-top: 30px">
                        <div style="
                                display: flex;
                                justify-content: space-between;
                            ">
                            <div class="title">
                                {{ goodsName || '主流化工产品' }}市场价格
                                <span style="color: #000000; font-size: 14px">Market Price</span>
                            </div>

                            <div style="font-size: 14px">
                                {{ dateFormat() }}
                            </div>
                        </div>
                        <div style="border: 1px solid #e3e3e3; margin-top: 5px"></div>
                        <div @click="hd_data_price($event)">
                            <vue-seamless-scroll :data="MainstreamProductsList" class="seamless-warp" style="width: 100%;
                                 overflow: hidden;" :class-option="classOption">
                                <div class="contaionBox">
                    
                                    <div class="line1" :data-id="item.id" :data-name="item.area_name" v-for="(
                                            item, index
                                        ) in MainstreamProductsList" v-if="item.area_name">
                                     
                                        <div class="top2">
                                            {{ item.area_name }}
                                            <div v-if="item.is_up == 0" style="">
                                                {{ item.price }}
                                            </div>
                                            <div style="color: #21a00c" v-if="item.is_up == 1">
                                                {{ item.price }}
                                            </div>
                                            <div style="color: #ff0c0c" v-if="item.is_up == 2">
                                                {{ item.price }}
                                            </div>
                                        </div>

                                        <div class="bottom">
                                            <div class="left">
                                                <img src="../assets/quotedPrice/a.png" v-if="item.is_up == 1" />
                                                <img src="../assets/quotedPrice/c.png" v-if="item.is_up == 2" />
                                                <span v-if="item.is_up == 0">
                                                    - {{ item.up_price }}
                                                </span>
                                                <span style="color: #21a00c" v-if="item.is_up == 1">
                                                    {{ item.up_price }}
                                                </span>
                                                <span style="color: #ff0c0c" v-if="item.is_up == 2">
                                                    {{ item.up_price }}
                                                </span>
                                            </div>
                                            <div class="right">
                                                <span v-if="item.is_up == 0">
                                                    {{ item.up_percentage }}%
                                                </span>
                                                <span style="color: #21a00c" v-if="item.is_up == 1">
                                                    {{ item.up_percentage }}%
                                                </span>
                                                <span style="color: #ff0c0c" v-if="item.is_up == 2">
                                                    {{ item.up_percentage }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vue-seamless-scroll>
                        </div>
                    </div> -->
                </div>
            </div>
            <informationBox2 titleName="行业最新资讯" :areaCategoryList2="areaCategoryList2" :isShow="0"
                ref="informationBox2" />
            <videoBox titleName="行业最新视频" :areaCategoryList2="areaCategoryList2" :isShow="0" ref="videoBox" />




            <!-- <hangNewPrice :title-name="this.$route.query.label" v-if="$route.query.label" /> -->
            <hangNewPrice2 :title-name="item.label" industry="295" v-for="item in hangYeList"
                :quoteList="item.quoteList" :videoList="item.videoList" :consultList="item.consultList" />
            <!-- <hangNewPrice2 title-name="涂料，油墨制造业" industry="296" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="日化产品制造业" industry="297" v-if="!$route.query.label" /> -->
            <!-- <hangNewPrice2 title-name="化学纤维制造业" industry="298" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="塑料制造业" industry="299" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="橡胶制造业" industry="300" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="合成皮革制造业" industry="302" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="肥料制造业" industry="307" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="肥料制造业" industry="307" v-if="!$route.query.label" />
            <hangNewPrice2 title-name="肥料制造业" industry="307" v-if="!$route.query.label" /> -->
            <!-- <div class="Industry">
                <div class="Industry-left">
                    <div class="industry-top">
                        <div style="padding: 16px 0px 0px 18px;font-weight:900;font-size: 20px;"> {{ showName }}百科</div>
                    </div>
                    <div class="industry-bottom">
                        <img src="@/assets/img/1709727155608.jpg" alt="" style="height: 100%;width: 100%;">
                    </div>
                </div>
                <div class="Industry-right">
                    <div class="information-top">
                        <div style="padding: 16px 0px 0px 18px;font-weight:900;font-size: 20px;">
                            {{ showName }}资讯
                        </div>
                    </div>
                    <div class="information-bottom">

                        <div class="information-box" style="display: flex; cursor: pointer;"
                            v-for="(item, index) in articleList" @click="goTitle(item)">
                            <div class="box-img" style="width: 22%;height: 100%;">
                                <img :src="item.imgUrl" alt="" style="height: 100%;width: 100%;">
                            </div>
                            <div class="box-title" style="margin-left: 6px;padding: 8px;width: 70%;">
                                <div class="title-1"
                                    style="height: 19px;padding: 2px 3px 5px 2px;font-size: 20px; font-weight:900;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
                                    {{ item.bgcTitle }}
                                </div>
                                <div class="title-2"
                                    style="height: 32px;padding: 10px 2px 2px 2px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;font-size: 14px;">
                                    {{ item.bgcContent }}
                                </div>
                                <div class="title-3"
                                    style="display: flex;height: 15%;padding: 6px 0px 0px 4px;color: gray;font-size: 15px;">
                                    <div>{{ item.addTime }}</div>
                                    <div style="margin-left: 20px;">阅读：{{ item.readNum }}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> -->
            <!-- <div>
                <div
                    style="height: 56px;line-height: 56px; width: 1180px; 
                     background-image: linear-gradient(rgba(202, 221, 250), rgba(242, 247, 255));padding-left: 20px;font-weight: 900;font-size: 20px;">
                    {{ showName }}产品最新资讯
                </div>
                <div class="content-box">
                    <div class="content-item" v-for="(item, index) in IndustryInformationList" @click="goTitle(item)"
                        style="cursor: pointer;">
                        <div class="item-left">
                            <img :src="item.imgUrl" style="height: 100%;width: 100%;" alt="">
                        </div>
                        <div class="item-right">
                            <div class="right-one">{{ item.bgcTitle }}</div>
                            <div class="right-two">
                                {{ item.bgcContent }}
                            </div>
                            <div class="right-three">
                                <div style="display: flex;">
                                    <img src="../assets/zhnlImg/Clip path group@1x.png"
                                        style="height: 18px;width: 18px;" alt="">
                                    <div style="margin-left: 8px;">{{ item.addTime }}</div>
                                </div>
                                <div style="margin-left: 30px;display: flex;">
                
                                    <div style="line-height: 18px;margin-left: 4px;">阅读量:{{ item.readNum }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="height: 56px;line-height: 56px; width: 1180px; 
                     background-image: linear-gradient(rgba(202, 221, 250), rgba(242, 247, 255));padding-left: 20px;font-weight: 900;font-size: 20px;">
                {{ showName }}最新视频
            </div>
            <div class="video-box">
                <div class="box-item" v-for="item in videoList" @click="toVideo(item)" style="cursor: pointer;">
                    <div class="box-one">
                        <img :src="item.vImgUrl" style="width: 100%;height: 100%;border-radius: 10px;object-fit: cover;"
                            alt="">
                    </div>
                    <div class="box-two" style="font-weight: 900;">{{ item.vTitle }}</div>
                    <div class="box-three" style="">
                        <img src="../assets/zhnlImg/Icon-YouTube@1x.png" alt=""
                            style="vertical-align: middle;height: 19px;">
                        <span style="vertical-align: middle">{{ item.vUploader }}</span>
                        <span style="margin-left: 16px;">播放量:{{ item.playNum }}</span>
                    </div>
                </div>

            </div> -->
            <!-- <div style="width: 100%;margin-bottom: 200px;">

                <el-pagination v-model:current-page="page" v-model:page-size="size"
                    style="margin-left: 35%;margin-top: 30px;" :page-sizes="[10, 20]"
                    layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
            </div> -->
            <!-- <div class="market" style="">
                <div class="line2">
                    <div class="top">
                        <div class="title" style="font-weight: 700">
                            {{ goodName || '化工' }}市场价格行情
                        </div>
                    </div>
                    <div class="theLine"></div>

                    <div class="contaion flexRowCenter" v-for="(item, index) in PriceQuotationsList" @click="
                        skipPage(
                            '/consultDetails?article_id=' + item.article_id
                        )
                        " style="cursor: pointer">
                        <div class="info">{{ item.title }}</div>
                        <div class="dete">{{ item.add_time }}</div>
                    </div>
                    <el-empty v-if="PriceQuotationsList.length < 1" :description="`暂无${goodName}价格行情`"></el-empty>
                </div>

                <div class="rightLine" v-show="!goodName">
                    <div class="top3" style="min-height: 234px">
                        <div class="top">
                            <div style="
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                ">
                                <div class="title" style="font-weight: 700">
                                    主流化工产品价格查询
                                </div>
                                <div style="display: flex" class="nonext" v-if="isCateList === 1"></div>
                                <div style="display: flex" class="nonext" v-else @click="nonext">
                                    返回上一级
                                </div>
                            </div>
                        </div>
                        <div class="theLine"></div>

                        <div class="contaion1" v-if="isCateList === 1">
                            <div class="lik hhe ov1" v-for="(item, i) in CateList" :key="item.gId"
                                @click="areaMainstreamProducts(item, 1)">
                                {{ item.gName }}
                            </div>
                        </div>
                        <div class="contaion1" v-show="isCateList === 2">
                            <div class="lik hhe ov1" v-for="(item, i) in CateList" :key="item.gId"
                                @click="areaMainstreamProducts(item, 2)">
                                {{ item.gName }}
                            </div>
                        </div>
                        <div class="contaion1" v-show="isCateList === 3">
                            <div class="lik hhe ov1" v-for="(item, i) in CateList" :key="item.id"
                                @click="areaMainstreamProducts(item, 3)">
                                {{ item.gName }}
                            </div>
                        </div>
                    </div>

                    <div class="top3" style="overflow: hidden; height: 221px">
                        <div class="top">
                            <div class="title" style="font-weight: 700">
                                主流国际市场动态
                            </div>
                        </div>
                        <div class="theLine"></div>

                        <div class="contaion2" v-for="(item, index) in InternationalMarketList" @click="
                            skipPage(
                                '/consultDetails?article_id=' +
                                item.article_id
                            )
                            ">
                            <div class="info">{{ item.title }}</div>
                            <div class="dete">{{ item.add_time }}</div>
                        </div>
                    </div>
                </div>

                <div class="rightLine" v-show="goodName">
                    <div class="top3">
                        <div class="top">
                            <div style="
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                ">
                                <div class="title" style="font-weight: 700">
                                    {{ goodName }}价格中心导航
                                </div>
                                <div style="display: flex"></div>
                            </div>
                        </div>
                        <div class="theLine"></div>

                        <div class="contaion">
                            <div class="left">
                                <div class="btn" v-for="(item, index) in MarketList" @click="
                                    marketChekout(
                                        index + 1,
                                        FactoryMarket[index].id
                                    )
                                    " :class="{
        btnBgc: marketChekoutFlag == index + 1,
        hhy: marketChekoutFlag != index + 1
    }" v-if="item.is_show == 1">
                                    {{ FactoryMarket[index].name }}
                                </div>
                            </div>

                            <div class="right">
                                <div class="region hhe" v-for="(item, index) in areaCategoryList" @click="
                                    skipTodayQuotedPrice(
                                        item.id,
                                        item.name,
                                        goodName
                                    )
                                    ">
                                    <span style="color: #006eb7">|</span>{{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="top3" style="overflow: hidden; height: 221px">
                        <div class="top">
                            <div class="title" style="font-weight: 700">
                                {{ goodNameOne || '主流化工' }}国际市场动态
                            </div>
                        </div>
                        <div class="theLine"></div>
                        <el-empty style="transform: translateY(-50px)" v-if="InternationalMarketList.length < 1"
                            :image-size="100" :description="`暂无${goodNameOne}国际市场动态数据`"></el-empty>
                        <div class="contaion2" v-for="(item, index) in InternationalMarketList" @click="
                            skipPage(
                                '/consultDetails?article_id=' +
                                item.article_id
                            )
                            ">
                            <div class="info">{{ item.title }}</div>
                            <div class="dete">{{ item.add_time }}</div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- 
            <div class="quotedBox">

                <div style="background-color: #fff">
                    <div class="title" style="">
                        {{ goodsName ? goodsName + '相关产品' : '主流化工产品' }}
                    </div>
                    <div style="
                            width: 96.5%;
                            border: 0.5px solid #ccc;
                            margin: 5px 0 0 20px;
                        "></div>
                    <div class="chooseBtn" style="
                            position: relative;
                            padding: 10px 20px;
                            box-sizing: border-box;
                        ">
                        <div class="iconM" :class="isA == 1 ? 'Xzbai' : ''" @click="zhBtn">
                            <div style="transform: translateX(22%)">综合</div>
                        </div>
                        <div class="iconM" @click.stop="getUserProductBtn">
                            <div>价格</div>
                            <div class="iconxzBox">
                                <div class="iconI hhe">
                                    <i class="el-icon-caret-top lv" :class="isB == 2 ? 'bai' : ''"></i>
                                </div>
                                <div class="iconI">
                                    <i class="el-icon-caret-bottom lv" :class="isB == 1 ? 'bai' : ''"></i>
                                </div>
                            </div>
                        </div>
                        <div class="iconM" style="position: absolute; right: 100px" :class="isBai == 1 ? 'Xzbai' : ''"
                            @click="daBtn(1)">
                            <div>大图</div>
                            <div><i class="el-icon-menu"></i></div>
                        </div>
                        <div class="iconM" style="position: absolute; right: -7px" :class="isBai == 2 ? 'Xzbai' : ''"
                            @click="daBtn(2)">
                            <div>列表</div>
                            <div><i class="el-icon-s-unfold"></i></div>
                        </div>
                    </div>
                </div>

                <div v-if="isBai == 2">
                    <div class="line1" style="margin-top: 10px" v-for="(item, index) in UserProduct">
                        <div class="boxL">
                            <div class="left1">
                                <el-image fit="cover" :src="item.original_img"></el-image>
                            </div>
                            <div class="left2">
                                <div>{{ item.goods_name }}</div>
                                <div>
                                    {{
                                        item.location_name
                                        ? item.location_name.slice(0, 2)
                                        : '未知'
                                    }}
                                </div>
                                <div class="Stype" v-if="item.shop_type">
                                    <i class="el-icon-trophy" style="color: #ffaa10"></i>
                                    {{ item.shop_type }}
                                </div>
                            </div>
                            <div class="left3">
                                <div class="info" v-for="e in item.service" :key="e.id">
                                    {{ `${e.title}: ${e.content}` }}
                                </div>
                     
                            </div>
                            <div class="left4">
                                ¥{{ item.shop_price }}/{{ item.spec_name }}
                            </div>
                            <div class="left5">
                                <div class="tt5" @click="skip(item.goods_id)">
                                    查看详情
                                </div>
                                <div class="tt5" @click.stop="
                                    collection(
                                        item.goods_id,
                                        item.is_collect
                                    )
                                    ">
                                    <i class="el-icon-star-off" v-if="isCollect == 100
                                        ? item.is_collect == 0
                                        : isCollect == 0
                                        ">收藏</i>
                                    <i class="el-icon-star-on" v-else>已收藏</i>
                                </div>
                            </div>
                            <div class="left6">
                                <div>{{ item.company_name }}</div>
                                <div><span>公司资质:</span>*</div>
                                <div>
                                    <span>联系人:</span>{{ item.real_name }}
                                </div>
                                <div>
                                    <span>联系方式:</span>{{ item.company_phone }}
                                </div>
                                <div style="color: #016fb8" @click="
                                    skipPageGoods(
                                        '/storeHome?admin_id=' +
                                        item.admin_id +
                                        '&goods_id=' +
                                        item.goods_id
                                    )
                                    ">
                                    <i class="el-icon-s-shop">商家主页></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="productBox">
                        <div class="productInfo" v-for="(item, index) in UserProduct" @click="skip(item.goods_id)">
                            <product v-if="(item.type = 2)" :img="item.original_img" :title="item.goods_name"
                                :price="item.shop_price" :specName="item.spec_name" :click_count="item.click_count"
                                :item="item" />
                        </div>
                    </div>
                </div>
                <div v-if="UserProduct.length < 1" style="margin-top: 100px">
                    <el-empty :image-size="200" :description="`暂无${goodName}相关产品`"></el-empty>
                </div>

    
            </div> -->
        </div>

    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import classifyContain from '../components/classifyContain.vue'
import loginCon from '../components/loginCon.vue'
import strictProduct from '../components/strictProduct.vue'
import product from '../components/product.vue'
import getParams from '../utils/getParams'
import VideoList3 from '../components/VideoList3.vue'
import informationBox2 from '../components/informationBox2.vue'
import videoBox from '../components/videoBox.vue'
import hangNewPrice from '../components/hangNewPrice.vue'
import hangNewPrice2 from '../components/hangNewPrice2.vue'
import { getMainStreamGoods, getMainStreaMchemicalIndustryGoods, getArticle, getHotVod, getAllRawMaterial, getAllGoodsConsultByInId, getAllVod, getBstUserGoods, getAllGoodsConsult, getGoodsIndustryAdvisory, getIndustryIsIndexPlay } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            consultList: [],
            videoList: [],
            quoteList: [],
            hangYeList: [],
            page: 1,
            total: 0,
            size: 20,
            IndustryInformationList: [],
            cat_id: '',
            articleList: [],

            imgList: ['../assets/index/banner.png'],
            showName: '',
            tableData: [
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    address: '4600',
                    down: '250'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    address: '4600',
                    down: '250'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    address: '4600',
                    down: '250'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    address: '4600',
                    down: '250'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    address: '4600',
                    down: '250'
                }
            ],
            tableData2: [
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    down: '3800'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    down: '3800'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    down: '3800'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    down: '3800'
                },
                {
                    date: '醋酸甲酯',
                    name: '中海南联',
                    down: '3800'
                }
            ],
            marketChekoutFlag: 1,

            //跳转数据
            cat_one: '',
            cat_two: '',
            cat_three: '',
            goodName: '',
            areaCategoryList2: [],
            //后台数据
            MainstreamProductsList: [],
            randomMainstreamList: [],

            //出厂列表数据
            FactoryMarket: '',
            parent_id: '',

            //区域列表数据
            areaCategoryList: '',
            market_house_id: '',

            PriceQuotationsList: '',
            InternationalMarketList: '',
            // 原材料产品数据
            UserProduct: '',

            MarketList: '',
            isBai: 1,
            isCollect: 100,
            isB: 1,
            isA: 1,
            isF: 0,
            isseamless: true,
            noData: [
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' },
                { name: '暂无数据' }
            ],
            CateList: [],
            CateList2: [],
            CateList1: [],
            CateList1: [],
            CateListson: [],
            CateListson2: [],
            isCateList: 1,
            cat_id_2: null,
            cat_id_1: 112,
            cat_id_3: null,
            cat_id2: '',
            goodNameOne: '',
            goodsName: ''
        }
    },
    computed: {
        classOption() {
            return {
                step: 1.5, // 数值越大速度滚动越快
                limitMoveNum: 5, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    methods: {
        allMouseEnter() {
            this.$emit('oneMouse')
        },
        toVideo(row) {
            // this.$router.push({
            //     path: '/vudio',
            //     query: {
            //         v_id: row.vId,
            //         industry: this.$route.query.cat_id,
            //         industry2: this.$route.query.cat_id2,
            //     }
            // })
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    industry2: this.$route.query.cat_id2,
                    industry: this.$route.query.cat_id,
                    v_id: row.vId,
                },
            });
            window.open(href, "_blank");
        },
        goTitle(item) {
            const { href } = this.$router.resolve({
                path: "/titleDetails",
                query: {
                    id: item.bgcId
                },
            });
            window.open(href, "_blank");
            // this.$router.push({
            //     path: '/titleDetails',
            //     query: {
            //         id: item.bgcId
            //     }
            // })
        },
        goVideo(data) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: data.vId,
                    industry: this.$route.query.cat_id,
                    industry2: this.$route.query.cat_id2,
                },
            });
            window.open(href, "_blank");
            // this.$router.push({
            //     path: '/vudio',
            //     query: {
            //         v_id: data.vId,
            //         industry: this.$route.query.cat_id,
            //         industry2: this.$route.query.cat_id2,
            //     }
            // })
        },
        isFBtn(val) {
            console.log('出发了。。。');
            this.isF = val
            console.log(isF, 'isF');
        },
        closeIsF() {
            this.isF = 0
        },

        getUserProductBtn() {
            this.isB = this.isB === 1 ? 2 : 1
            this.isA = 2
            this.getUserProduct(false, this.isB)
        },

        zhBtn() {
            this.isA = 1
            this.getUserProduct(false, 0)
        },
        daBtn(num) {
            this.isBai = num
        },
        dateFormat() {
            var date = new Date()
            var year = date.getFullYear()
            var month =
                date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1
            var day =
                date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
            // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            return year + '-' + month + '-' + day
        },
        skipPage(page) {
            window.location = page
        },
        skip(id) {
            window.location = '/detailsPage?goods_id=' + id
        },
        skipPage(page) {
            window.location = page
        },
        cellStyle() {
            return "'color': 'red'"
        },
        marketChekout(num, market_house_id) {
            this.marketChekoutFlag = num
            this.parent_id = market_house_id
            this.areaCategory()
            console.log(this.marketChekoutFlag)
        },

        skipTodayQuotedPrice(market_house_id, thename) {
            window.location =
                '/todayQuotedPrice?cat_one=' +
                this.cat_id_1 +
                '&cat_two=' +
                this.cat_id_2 +
                '&cat_three=' +
                this.cat_id_3 +
                '&parent_id=' +
                this.parent_id +
                '&market_house_id=' +
                market_house_id +
                '&thename=' +
                thename +
                '&goodName=' +
                this.goodName
        },

        //分类主流产品市场价格
        async OneMainstreamProducts() {
            let params = {
                cat_one: this.cat_id_1,
                cat_two: this.cat_id_2,
                cat_three: this.cat_id_3
            }
            console.log(params, 'params');

        },
        // try {

        //     let res = await this.$http('/zhnl/getMainStreamGoods', x)
        //     let arr = []
        //     res.result.forEach((item) => {
        //         if (item.name) arr.push(item)
        //     })
        //     if (arr.length - 4 < 0) {
        //         if (4 - arr.length === 1) {
        //             this.MainstreamProductsList = [
        //                 ...arr,
        //                 ...arr.slice(0, 1)
        //             ]
        //         } else if (4 - arr.length === 2) {
        //             this.MainstreamProductsList = [
        //                 ...arr,
        //                 ...arr.slice(0, 2)
        //             ]
        //         } else if (4 - arr.length === 3) {
        //             this.MainstreamProductsList = [
        //                 ...arr,
        //                 ...arr.slice(0, 1),
        //                 ...arr.slice(0, 1),
        //                 ...arr.slice(0, 1)
        //             ]
        //         }
        //     }
        // } catch (error) {}
        //能源主流产品市场价格
        async randomMainstreamProducts() {
            let params = {
                cat_one: this.cat_id_1,
                cat_two: this.cat_id_2,
                cat_three: this.cat_id_3,
            }
            // getMainStreamGoods({
            //     cat_one: this.cat_id_1,
            //     cat_two: this.cat_id_2,
            //     cat_three: this.cat_id_3
            // }).then((res) => {
            //     this.randomMainstreamList = res.data.data
            // })
            getBstUserGoods({ industry: this.cat_id, industry2: this.cat_id2 }).then((res) => {
                this.randomMainstreamList = res.data.data
                this.randomMainstreamList.forEach((item) => {
                    // item.addTime = item.addTime.substring(0, 10)
                })
            })
            // getMainStreaMchemicalIndustryGoods(params).then((res) => {
            // console.log(res,'第二行');
            // let arr = []
            // res.data.data.forEach((item) => {
            //     if (item.area_name) arr.push(item)
            // })
            // console.log(arr, 'arr------');
            // this.MainstreamProductsList = arr
            // this.MainstreamProductsList = res.data.data
            // console.log(this.MainstreamProductsList,'MainstreamProductsList');
            // if (arr.length - 4 < 0) {
            //     if (4 - arr.length === 1) {
            //         this.MainstreamProductsList = [
            //             ...arr,
            //             ...arr.slice(0, 1)
            //         ]
            //     } else if (4 - arr.length === 2) {
            //         this.MainstreamProductsList = [
            //             ...arr,
            //             ...arr.slice(0, 2)
            //         ]
            //     } else if (4 - arr.length === 3) {
            //         this.MainstreamProductsList = [
            //             ...arr,
            //             ...arr.slice(0, 1),
            //             ...arr.slice(0, 1),
            //             ...arr.slice(0, 1)
            //         ]
            //     }
            // }            
            // })
            // getArticle(params).then((res) => {
            //     res.data.data.forEach((item, index) => {
            //         item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
            //     })

            //     this.PriceQuotationsList = res.data.data.slice(0, 8)
            //     this.InternationalMarketList = res.data.data.slice(0, 8)


            // })
            // try {
            //     let params = {
            //         cat_one: this.cat_id_1 || this.cat_one,
            //         cat_two: this.cat_id_2 || this.cat_two,
            //         cat_three: this.cat_id_3 || this.cat_three
            //     }
            //     let res = await this.$http('/zhnl/getMainStreamGoods', params)
            //     this.randomMainstreamList = res.result
            // } catch (error) {}
        },

        //出厂列表
        async ExFactoryMarket() {
            try {
                let res = await this.$http('/mini/ApiConnect/ExFactoryMarket')
                this.FactoryMarket = res.result
                if (!getParams().parent_id) {
                    this.parent_id = this.FactoryMarket[0].id
                }
                this.MarketList = res.result
                // this.parent_id = ''

                this.areaCategory()
            } catch (error) { }
        },

        //区域列表
        async areaCategory() {
            let res = await this.$http('/mini/ApiConnect/areaCategory', {
                market_house_id: this.parent_id
            })
            this.areaCategoryList = res.result
            this.market_house_id = ''

            // this.parent_id = ''
        },

        //市场价格行情
        async PriceQuotations() {
            let res = await this.$http('/mini/ApiConnect/PriceQuotations', {
                cat_one: this.cat_id_1 || this.cat_one,
                cat_two: this.cat_id_2 || this.cat_two,
                cat_three: this.cat_id_3 || this.cat_three
            })
            this.PriceQuotationsList = res.result.slice(0, 8)
            this.PriceQuotationsList.forEach((e, index) => {
                e.add_time = e.add_time.substring(0, 11)
            })
        },

        //国际市场动态
        async InternationalMarket() {
            let res = await this.$http('/mini/ApiConnect/InternationalMarket', {
                cat_one: this.cat_id_1 || this.cat_one,
                cat_two: this.cat_id_2 || this.cat_two,
                cat_three: this.cat_id_3 || this.cat_three
            })
            this.InternationalMarketList = res.result.slice(0, 4)
            this.InternationalMarketList.forEach((e, index) => {
                e.add_time = e.add_time.substring(0, 11)
            })
        },

        //产品列表
        async getUserProduct(userId, price = 0) {
            let user_id = localStorage.getItem('user_id')

            let params = {
                user_id,
                page: 1,
                price,
                cat_two: this.cat_id_2,
                cat_one: this.cat_id_1,
                type: 2
            }
            let res = await this.$http(
                '/mini/ApiConnect/StrictSelectionOfProducts',
                params
            )
            this.UserProduct = []
            this.UserProduct = res.result
        },

        //原材料商品分类
        async GoodscCateList() {
            // getAllRawMaterial().then((res) => {
            //     this.CateList = res.data.data
            // })
            // try {
            //     let res = await this.$http('/mini/ApiConnect/GoodscCateList')

            //     if (res.status == 1) {
            //         res.result.forEach((item) => {
            //             if (item.id == 112) {
            //                 this.cat_one = item.id
            //                 this.cat_two = item.son[0].id
            //                 this.cat_three = item.son[0].son[0].id
            //                 this.CateList = item.son
            //                 this.isCateList = 2
            //             }
            //         })
            //     }

            //   this.goodName = res.result[0].son[0].son[0].name;
            //     this.CateList1 = res.result
            // } catch (error) {}
            // /mini/ApiConnect/GoodscCateList
        },
        async hdareaMainstream(params) {
            let res = await this.$http(
                '/mini/ApiConnect/areaMainstreamProducts',
                params
            )
            this.ExFactoryMarket()
            this.areaCategory()
            this.PriceQuotations()
            this.InternationalMarket()
            this.randomMainstreamProducts()
            this.getUserProduct()
            if (res.result === null || res.result.length < 1) {
                this.MainstreamProductsList = this.noData
            } else {
                this.MainstreamProductsList = res.result
            }
        },

        async areaMainstreamProducts(item, i) {
            if (i === 1) {
                this.goodNameOne = item.name
                this.cat_id_1 = item.id
                this.CateList = item.son
                this.CateList2 = item.son
                this.isCateList = 2
            } else if (i === 2) {
                this.cat_id_2 = item.id
                this.CateList = item.son
                this.CateList3 = item.son
                this.isCateList = 3
            } else {
                this.isseamless = false
                this.cat_three = item.id
                this.cat_id_3 = item.id
                let params = {
                    cat_three: item.id
                }
                this.isseamless = true
                this.goodName = item.name
                this.hdareaMainstream(params)
            }
        },

        nonext() {
            console.log(this.isCateList)
            if (this.isCateList === 2) {
                this.isCateList = 1
                this.CateList = this.CateList1
            } else if (this.isCateList === 3) {
                this.isCateList = 2
                this.CateList = this.CateList2
            }
        },

        oneprice(item) {
            this.goodName = item.name
            let params = {
                cat_three: item.id
            }
            this.hdareaMainstream(params)
            this.PriceQuotations()
            this.InternationalMarket()
        },
        hd_data_price2(val, item) {
            console.log(val);
            const { href } = this.$router.resolve({
                path: '/todayQuotedPrice',
                query: {
                    data: JSON.stringify({
                        catOne: val.catOne,
                        catTwo: val.catTwo,
                        catThree: val.catThree,
                        catFour: val.catFour,
                        label: val.catThreeName,
                        cat_id: val.catThree


                    })
                }

            });
            window.open(href, "_blank");
        },
        hd_data_price(val, item) {
            // console.log(val, 'val-----');
            // let a = JSON.parse(val.target.dataset.item)
            // console.log(val.target.dataset.item, 'val.target.dataset.item');
            // this.$router.push({
            //     path: '/todayQuotedPrice',
            //     query: {
            //         data: val.target.dataset.item,
            //         catOne: this.cat_id,
            //         catTwo: this.cat_id2,
            //     }
            // })
            // console.log(val.target.dataset)

            // let v = JSON.parse(val.target.dataset.item)
            // this.cat_three = v.cat_three
            // this.cat_one = v.cat_one
            // this.cat_two = v.cat_two
            // this.cat_id_3 = v.cat_three
            // this.cat_id_1 = v.cat_one
            // this.cat_id_2 = v.cat_two
            // let arrA = this.CateList1
            // for (let i in arrA) {
            //     console.log(arrA[i].id, v.cat_one)
            //     if (arrA[i].id == v.cat_one) {
            //         this.goodNameOne = arrA[i].name
            //         console.log(arrA[i])
            //         break
            //     }
            // }

            // this.goodName = v.name
            // let params = {
            //     cat_three: this.cat_id_3,
            //     cat_two: this.cat_id_2,
            //     cat_one: this.cat_id_1
            // }
            // this.hdareaMainstream(params)
            // this.PriceQuotations()
            // this.InternationalMarket()
        }
    },

    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)',
        });
        getIndustryIsIndexPlay({ size: 10, video_size: 11, consult_size: 10, index: 1, quote_size: 30 }).then((res) => {
            res.data.data.forEach((item) => {
                item.consultList.forEach((item) => {
                    item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                })
            })
            if (res.data.code == 200) {

                this.hangYeList = res.data.data
            }
        })
        this.$refs.informationBox2.numberChange({ gId: this.$route.query?.cat_one })
        this.$refs.videoBox.numberChange({ gId: this.$route.query?.cat_one })

        getGoodsIndustryAdvisory().then((res) => {
            loading.close();
            this.areaCategoryList2 = res.data.data
            this.areaCategoryList2.unshift({ label: '推荐', gId: 0 })
        })

        let datas = {
            title: '',
            size: this.size,
            page: this.page,
            Industry: this.$route.query.cat_one,
            Industry2: this.$route.query.cat_two,
        }
        // getHotVod(datas).then((res) => {
        //     this.videoList = res.data.data
        //     this.total = res.data.totalPage
        // })
        // getAllGoodsConsultByInId({
        //     industry: this.$route.query.cat_one,
        //     page: 1,
        //     size: 100,
        //     industry: '',
        // }).then((res) => {
        //     this.IndustryInformationList = res.data.data
        // })
        this.showName = this.$route.query.label
        this.cat_id = this.$route.query.cat_one
        this.cat_id2 = this.$route.query.cat_two != undefined ? this.$route.query.cat_two : null
        let params = {
            cat_one: '',
            cat_two: '',
            cat_three: '',
            industry: this.$route.query.cat_one,
            industry2: this.cat_id2,
            page: 1,
            size: 30
        }
        // getAllGoodsConsult(params).then((res) => {
        //     this.articleList = res.data.data
        // })

        // getAllArticle({ page: 1 }).then((res) => {
        //     res.data.data.forEach((item, index) => {
        //         item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
        //     })
        //     this.articleList = res.data.data

        // })

        this.cat_id_1 = this.$route.query.cat_one
        this.cat_id_2 = this.$route.query.cat_two
        this.cat_id_3 = this.$route.query.cat_three
        this.goodsName = this.$route.query.goodName
        if (this.$route.query.goodName) {
            getBstUserGoods({
                cat_one: this.cat_id_1,
                cat_two: this.cat_id_2,
                cat_three: this.cat_id_3
            }).then((res) => {

                this.randomMainstreamList = res.data.data

            })
            let params = {
                cat_one: this.$route.query.cat_id_1,
                cat_two: this.$route.query.cat_id_2,
                cat_three: this.$route.query.cat_id_3,
            }
            getMainStreaMchemicalIndustryGoods(params).then((res) => {
                this.MainstreamProductsList = res.data.data

            })
        } else {
            this.randomMainstreamProducts()
        }


    },
    created() {



        // if (!getParams().cat_one) {
        //     this.GoodscCateList();
        // } else {
        //     this.cat_one = getParams().cat_one || getParams().cat_id_1 || 112;
        //     this.cat_two = getParams().cat_two || getParams().cat_id_2;
        //     this.cat_three = getParams().cat_three || getParams().cat_id_3;

        // }
        // if (getParams().cat_id_1) {
        //     this.cat_id_1 = getParams().cat_id_1 || 112;
        //     this.cat_id_2 = getParams().cat_id_2 || null;
        //     this.cat_id_3 = getParams().cat_id_3 || null;
        //     this.goodName = decodeURI(getParams().goodName) || '';
        //     this.goodNameOne = decodeURI(getParams().goodNameOne) || '';
        //     this.hdareaMainstream({ cat_three: this.cat_id_3 })
        // }

        this.GoodscCateList()

        // this.ExFactoryMarket();
        // this.areaCategory();
        // this.PriceQuotations();
        // this.InternationalMarket();

        // setTimeout(() => {
        //     this.OneMainstreamProducts();
        // }, 500);
    },
    components: {
        searchBar,
        classifyBar,
        classifyContain,
        loginCon,
        strictProduct,
        product,
        VideoList3,
        informationBox2,
        videoBox,
        hangNewPrice,
        hangNewPrice2,

    }
}
</script>
<style lang="scss">
.ttu {
    position: relative;
    z-index: 99999999;

    .ttp {
        z-index: 9999999;
        position: absolute;
        top: -215px;
    }
}
</style>

<style lang="scss" scoped>
.nonext {
    font-size: 14px;
    color: #999;
    cursor: pointer;
}

.nonext:hover {
    color: #006eb7;
}

.content2 {
    // width: 1530px;
    // margin: auto;
    display: flex;
    flex-direction: column;

    .wdd-content {
        display: flex;
        width: 100%;
        height: 355px;
        // background-color: red;

        .left- {
            width: 58%;
            height: 100%;
            margin-right: 2%;
            border-radius: 80%;
        }

        .right-::-webkit-scrollbar {
            width: 8px;
        }

        .right-::-webkit-scrollbar-thumb {
            border-radius: 20px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
        }

        .right-::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);

        }

        .right- {
            width: 40%;
            height: 100%;
            // margin: ;
            overflow-x: hidden;
            overflow-y: auto;
            // border: 1px solid rgb(225, 238, 255);
            background-color: white;

            .wdd-box {
                width: 100%;
                height: 31%;
                display: flex;
                padding: 5px 2px;
                background-color: white;

                .right-item {
                    width: 40%;
                    height: 100%;
                }

                .right-item:hover {
                    color: rgb(32, 123, 255) !important;
                }

                .right-item2 {
                    width: 60%;
                    height: 100%;
                    padding: 5px 5px;

                    .right-item2-1 {
                        height: 74%;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                    }

                    .right-item2-1:hover {
                        color: rgb(32, 123, 255) !important;


                    }

                    .right-item2-2:hover {
                        color: rgb(32, 123, 255) !important;

                    }

                    .right-item2-2 {
                        padding: 3px 2px;
                        color: gray;
                    }

                }
            }


        }
    }
}

.location {
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 1200px;
    transform: translateY(10px);

    .nav {}
}

@media screen and (min-width: 600px) and (max-width: 1620px) {
    .topInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .priceBox {
            margin-top: 145px !important;
            width: 1240px;
            // width: 989px;
            height: 215px;
            margin: auto;
            // margin-left: 210px;

            .top {
                width: 100%;
                height: 207px;
                border-radius: 4px;
                background-color: white;
                padding-top: 12px;
                box-sizing: border-box;
                padding-left: 10px;
                padding-right: 10px;

                .title {
                    // width: 188px;
                    height: 30px;
                    border-radius: 0 4px 4px 0;
                    // background: #006eb7;
                    text-align: left;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: #0270ad;
                }

                .contaionBox::-webkit-scrollbar {
                    display: none;
                    /* Chrome Safari */
                }

                .contaionBox {
                    height: 103px !important;
                    margin-top: 21px;
                    width: 70%;
                    // overflow-x: scroll;
                    // overflow-y: hidden;
                    padding-bottom: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    scrollbar-width: none;
                    /* firefox */
                    // -ms-overflow-style: none;

                    /* IE 10+ */
                    .line1 {
                        width: 338px;
                        height: 82px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #e4e4e4;
                        position: relative;

                        .shelter {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }

                        .top2 {
                            font-size: 18px;
                            text-align: center;
                            font-weight: 700;
                            width: 238px;
                        }

                        .bottom {
                            width: 238px;
                            margin-top: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #21a00c;

                            span {
                                font-size: 14px;
                            }

                            .left {
                                padding: 0 10px;
                                border-right: 2px solid #e4e4e4;
                            }

                            .right {
                                padding: 0 10px;
                            }
                        }

                        .bottom2 {
                            margin-top: 4px;
                            display: flex;
                            align-items: center;
                            color: #ff0c0c;

                            .left {
                                padding: 0 10px;
                                border-right: 2px solid #e4e4e4;
                            }

                            .right {
                                padding: 0 10px;
                            }
                        }
                    }

                    .line1:last-child {}
                }
            }
        }
    }

}

@media screen and (min-width: 1620px) and (max-width: 50500px) {
    .topInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .priceBox {
            margin-top: 145px !important;
            width: 1530px;
            // width: 989px;
            height: 215px;
            margin: auto;
            // margin-left: 210px;

            .top {
                width: 100%;
                height: 207px;
                border-radius: 4px;
                background-color: white;
                padding-top: 12px;
                box-sizing: border-box;
                padding-left: 10px;
                padding-right: 10px;

                .title {
                    // width: 188px;
                    height: 30px;
                    border-radius: 0 4px 4px 0;
                    // background: #006eb7;
                    text-align: left;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: #0270ad;
                }

                .contaionBox::-webkit-scrollbar {
                    display: none;
                    /* Chrome Safari */
                }

                .contaionBox {
                    height: 103px !important;
                    margin-top: 21px;
                    width: 70%;
                    // overflow-x: scroll;
                    // overflow-y: hidden;
                    padding-bottom: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    scrollbar-width: none;
                    /* firefox */
                    // -ms-overflow-style: none;

                    /* IE 10+ */
                    .line1 {
                        width: 338px;
                        height: 82px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #e4e4e4;
                        position: relative;

                        .shelter {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }

                        .top2 {
                            font-size: 18px;
                            text-align: center;
                            font-weight: 700;
                            width: 238px;
                        }

                        .bottom {
                            width: 238px;
                            margin-top: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #21a00c;

                            span {
                                font-size: 14px;
                            }

                            .left {
                                padding: 0 10px;
                                border-right: 2px solid #e4e4e4;
                            }

                            .right {
                                padding: 0 10px;
                            }
                        }

                        .bottom2 {
                            margin-top: 4px;
                            display: flex;
                            align-items: center;
                            color: #ff0c0c;

                            .left {
                                padding: 0 10px;
                                border-right: 2px solid #e4e4e4;
                            }

                            .right {
                                padding: 0 10px;
                            }
                        }
                    }

                    .line1:last-child {}
                }
            }
        }
    }

}


.market {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    height: 462px;
    overflow: hidden;
    justify-content: space-between;

    // align-items: flex-start;
    .line2 :hover {
        color: #006eb7 !important;
    }

    .line2 {
        width: 350px;
        height: 462px;
        background-color: white;
        padding: 16px;
        padding-bottom: 0px;
        margin-right: 24px;
        box-sizing: border-box;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                border-left: 3px solid #006eb7;
                padding-left: 5px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
            margin-bottom: 5px;
        }

        .contaion {
            border-bottom: 1px dashed #e4e4e4;

            .info {
                width: 65%;
                height: 50px;
                line-height: 50px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .dete {
                width: 30%;
                font-size: 16px;
                color: #909090;
                margin-left: 10px;
            }
        }

        .contaion:last-child {
            border: none;
        }
    }

    .rightLine {
        width: 828px;
        height: 476px;

        .contaion1 {
            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            box-sizing: border-box;
            height: 182px;
            overflow-y: scroll;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .lik {
                width: calc(100% / 6);
                height: 76px;
                line-height: 76px;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                border-right: #ccc dotted 1px;
                box-sizing: border-box;
                cursor: pointer;
            }

            .lik:nth-child(7),
            .lik:nth-child(8),
            .lik:nth-child(9),
            .lik:nth-child(10),
            .lik:nth-child(11),
            .lik:nth-child(12) {
                border-top: #ccc dotted 1px;
            }

            .lik:nth-child(6),
            .lik:nth-child(12) {
                border-right: 0;
            }
        }

        .top3 {
            width: 828px;
            // height: 227px;
            background-color: white;
            border-radius: 4px;
            padding: 16px;
            padding-bottom: 0px;
            // margin-right: 24px;
            box-sizing: border-box;

            .top {
                display: flex;
                align-items: center;

                .title {
                    font-size: 16px;
                    border-left: 3px solid #006eb7;
                    padding-left: 5px;
                }

                .date {
                    font-size: 10px;
                    color: #aeaeae;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                margin-top: 7px;
                margin-bottom: 5px;
            }

            .contaion {
                padding: 20px 30px;
                padding-bottom: 0;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;

                .left {
                    min-height: 144px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        margin-bottom: 20px;
                        width: 81px;
                        height: 28px;
                        border-radius: 14px;
                        border: 1px solid #006eb7;
                        color: #006eb7;
                        box-sizing: border-box;
                        text-align: center;
                        line-height: 28px;
                        font-size: 14px;
                        cursor: pointer;
                    }

                    .btnBgc {
                        background-color: #006eb7 !important;
                        color: white !important;
                        border: none !important;
                    }
                }

                .right {
                    width: 690px;
                    display: flex;
                    flex-wrap: wrap;

                    .region {
                        width: 33%;
                        text-align: center;
                        font-size: 19px;
                        letter-spacing: 2.39px;
                        cursor: pointer;
                        margin-bottom: 8px;
                    }

                    .region:first-child {}
                }
            }
        }

        .top3 {
            margin-bottom: 22px;
            width: 828px;
            // height: 227px;
            background-color: white;
            // padding: 16px;
            // margin-right: 24px;
            box-sizing: border-box;

            .top {
                display: flex;
                align-items: center;

                .title {
                    font-size: 16px;
                    border-left: 3px solid #006eb7;
                    padding-left: 5px;
                }

                .date {
                    font-size: 10px;
                    color: #aeaeae;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                margin-top: 7px;
                margin-bottom: 7px;
            }

            .contaion2 :hover {
                color: #006eb7 !important;
            }

            .contaion2 {
                padding: 0 !important;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px dashed #e4e4e4;
                cursor: pointer;

                .info {
                    width: 70%;
                    height: 50px;
                    line-height: 50px;
                }

                .date {
                    width: 30%;
                    font-size: 16px;
                    color: #909090;
                }
            }

            .contaion2:last-child {
                border: none;
            }
        }
    }
}

.productBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .productInfo {
        margin-right: 15px;
        margin-top: -10px;
    }
}

.productInfo:nth-child(5n) {
    margin-right: 0;
}

.footBar {
    width: 1920px;
    height: 284px;
    background-color: #006eb7;
}

.quotedBox {
    width: 1200px;
    margin-top: 7px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    // background-color: white;

    .chooseBtn {
        width: 100%;
        display: flex;
        justify-content: left;

        .iconMXz {
            width: 80px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid #006eb7;
            text-align: center;
            line-height: 28px;
            color: #fff;
            background-color: #006eb7;
            margin-right: 20px;
        }

        .Xzbai {
            color: #fff !important;
            background-color: #006eb7;
        }

        .iconM {
            width: 80px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid #006eb7;
            text-align: center;
            line-height: 28px;
            color: #006eb7;
            margin-right: 26px;
            display: flex;
            text-align: center;
            // align-items: center;
            padding-left: 1.5%;
            box-sizing: border-box;
            cursor: pointer;
            /* 火狐 */
            -moz-user-select: none;
            /* Safari 和 欧朋 */
            -webkit-user-select: none;
            /* IE10+ and Edge */
            -ms-user-select: none;
            /* Standard syntax 标准语法(谷歌) */
            user-select: none;

            .iconxzBox {
                transform: translateY(-15%);

                .iconI {
                    width: 10px;
                    height: 10px;
                    cursor: pointer;

                    .lv:hover {
                        color: #006eb7 !important;
                    }

                    .bai {
                        color: #b0b1b2;
                    }
                }
            }
        }
    }

    .line1 {
        padding: 0 !important;
        position: relative;
        height: 171px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .boxL {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding-left: 20px;
            box-sizing: border-box;
            height: 100%;
        }

        .left1 {
            width: 100px;
            height: 100px;
        }

        .left2 {
            width: 180px;
            height: 100px;
            margin-left: 15px;

            .Stype {
                width: 69px;
                height: 20px;
                border-radius: 2px;
                opacity: 1;
                background: linear-gradient(270deg,
                        #ffa80b 0%,
                        #ffc252 48%,
                        #fdefd5 100%);
                font-size: 12px;
                color: #fff;
                box-sizing: border-box;
                padding-left: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &>div:nth-child(2) {
                font-size: 14px;
                padding: 2px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                width: 44px;
                background-color: #0370b7;
                margin-bottom: 10px;
            }
        }

        .left3 {
            width: 180px;
            height: 100px;
            font-size: 14px;
        }

        .left4 {
            width: 120px;
            color: #f23c24;
            font-weight: 700;
        }

        .left5 {
            display: flex;

            .tt5 {
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                border-radius: 33px;
                opacity: 1;
                border: 1px solid #006eb7;
                color: #006eb7;
                margin-left: 20px;
            }
        }

        .left6 {
            position: absolute;

            right: 0;
            width: 320px;
            padding: 23px 50px;
            margin-left: 8px;
            box-sizing: border-box;
            background-color: #e8effd;

            &>div {
                margin-bottom: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                color: #006eb7;
                font-weight: 700;
            }

            &>div:nth-child(2),
            &>div:nth-child(3),
            &>div:nth-child(4) {
                color: #90959f;
            }
        }

        .left {
            width: 80%;

            .title2 {
                font-size: 20px;
                cursor: pointer;
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 160px;
                    font-size: 16px;
                    height: 23px;
                    white-space: nowrap;
                    /* 文本不换行 */
                    overflow: hidden;
                    /* 溢出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 文本超出部分用省略号表示 */
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background: #006eb7;
            text-align: center;
            line-height: 36px;
            color: white;
        }
    }

    .title {
        font-size: 24px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        padding: 15px 0 10px 20px;
        font-weight: 700;
    }

    .line1 {
        // padding: 20px;
        background-color: #fff;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left {
            width: 80%;

            .title2 {
                font-size: 20px;
                cursor: pointer;
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 160px;
                    font-size: 16px;
                    height: 23px;
                    white-space: nowrap;
                    /* 文本不换行 */
                    overflow: hidden;
                    /* 溢出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 文本超出部分用省略号表示 */
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background: #006eb7;
            text-align: center;
            line-height: 36px;
            color: white;
        }
    }
}

::-webkit-scrollbar-track {
    background-color: white !important;
}

::-webkit-scrollbar {}

.scroll_table {
    margin: 15px;
    display: flex;

    ::v-deep .table .el-table__body-wrapper {
        display: none;
    }

    // 重点注意这段样式
    .seamless-warp {
        height: 200px;
        // width: 989px;
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        ::v-deep .table_scroll .el-table__header-wrapper {
            display: none;
        }
    }
}

.information-bottom::-webkit-scrollbar {
    width: 8px;
}

.information-bottom::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

.information-bottom::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);

}

.Industry {
    margin-top: 10px;
    display: flex;
    height: 550px;
    width: 100%;
    margin-bottom: 30px;
    // background-color: red;

    .Industry-left {
        width: 30%;
        height: 100%;
        background-color: white;

        .industry-top {
            height: 10%;
            width: 100%;
            background-image: linear-gradient(rgb(212, 230, 255), white);
        }

        .industry-bottom {
            height: 90%;
            width: 100%;
        }
    }



    .Industry-right {
        margin-left: 20px;
        width: 68%;
        height: 100%;

        // background-color: green;

        .information-top {
            height: 10%;
            width: 100%;
            background-image: linear-gradient(rgb(212, 230, 255), white);
        }

        .information-bottom {
            background-color: white;
            height: 90%;
            width: 100%;
            overflow-y: scroll;

            overflow-x: hidden;

            .information-box {
                width: 96%;
                height: 23%;
                // background-color: red;
                padding: 9px 5px;
                // margin-right: 10px;
                border-bottom: 1px solid gray;
            }
        }
    }
}

.content-box::-webkit-scrollbar {
    width: 8px;
}

.content-box::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

.content-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);

}

.content-box {
    height: 800px;
    width: 1164px;
    background-color: white;
    margin-bottom: 100px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0px 18px;

    .content-item {
        border-bottom: 1px solid gray;
        padding: 20px 9px;
        height: 140px;
        width: 96%;
        margin: auto;
        // background-color: green;
        display: flex;

        .item-left {
            // padding: 5px;
            width: 19%;
            height: 100%
        }

        .item-right {
            height: 100%;
            width: 79%;

            .right-one {
                font-family: "微软雅黑";
                // background-color: red;
                height: 20%;
                padding: 0px 10px;
                padding-top: 8px;
                padding-bottom: 1px;
                font-weight: 900;
                width: 100%;
                font-size: 20px;
                white-space: nowrap;
                /* 文本不换行 */
                overflow: hidden;
                /* 溢出部分隐藏 */
                text-overflow: ellipsis;

            }

            .right-two {

                height: 60px;
                padding: 0px 12px;
                // background-color: yellow;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;

            }

            .right-three {
                color: gray;
                height: 21%;
                width: 100%;
                display: flex;
                padding: 0px 10px;
                padding-top: 20px
            }
        }
    }
}

.title-2:hover {
    color: rgb(32, 123, 255) !important;
}

.title-3:hover {
    color: rgb(32, 123, 255) !important;
}

.right-three:hover {
    color: rgb(32, 123, 255) !important;

}

.right-two:hover {
    color: rgb(32, 123, 255) !important;

}

.box-two:hover {
    color: rgb(32, 123, 255) !important;
}

.video-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 300px;



    .box-item {
        width: 19%;
        height: 200px;
        // background-color: green;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 10px;


        .box-one {
            height: 75%;
            border-radius: 10px;
        }

        .box-one:hover {
            box-shadow: 5px 5px 5px gray;
        }

        .box-two {
            height: 10%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 3px 1px;
        }

        .box-three {
            height: 10%;
            padding: 1px 1px;
            color: gray;
            font-size: 13px;
        }

        .box-three:hover {

            color: rgb(32, 123, 255) !important;

        }
    }
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    width: 1000px;
    height: 400px;
}

.el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
    // margin-left: 500px;
}

.el-carousel__item:nth-child(2n+1) {
    // background-color: #d3dce6;
    // margin-right: 500px;
}
</style>
