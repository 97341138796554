<template>
    <div>
        <transition name="dialog-fade">
            <div v-if="visible" class="my-dialog-overlay">
                <div @click="goEnterprise">

                    <!-- <div style="margin-left: 610px;" @click="close"> <i class="el-icon-error" size="small"></i></div> -->
                    <div style="margin-left: 650px;cursor: pointer;" @click="close"> <i class="el-icon-error"
                            size="small"></i></div>

                    <img src="../assets/newImg3/62fb3196c3f97018549d729de402917.png" style="width: 650px;height: 201px;    cursor: pointer;margin-bottom: 300px;
" alt="">

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { addMember, getStatusByOrderSn } from '../api/user'
export default {
    data() {
        return {
            visible: false,
            total_amount: '0.01',
            order_description: 0,
            wangNumber: 1,
            timer: '',
        }
    },
    methods: {
        goEnterprise() {
            this.$router.push({
                path: '/buyerCenter/amendEnterpriseInfo'
            })
        },
        open() {
            this.$alert('支付成功!', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.visible = false

                }
            });
        },
        getCode() {
            clearInterval(this.timer)

            addMember({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
                this.timer = setInterval(() => {
                    getStatusByOrderSn({ orderNo: res.data.data.orderSn }).then((res) => {
                        if (res.data.trade_state_desc == '支付成功') {

                            this.open()
                            clearInterval(this.timer)
                        }
                    })
                }, 3000);
                new QRCode(this.$refs.qrcode, {
                    text: res.data.data.codeUrl,
                    width: 158,
                    height: 158
                });

            })
        },
        changeNumber(id) {
            clearInterval(this.timer)

            this.wangNumber = id
            if (id == 1) {
                const container = document.getElementById('qrcode-container');
                container.innerHTML = ''; // 清空容器 
                this.total_amount = 0.01
                addMember({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
                    this.timer = setInterval(() => {
                        getStatusByOrderSn({ orderNo: res.data.data.orderSn }).then((res) => {
                            console.log(res, 'res...----');
                            if (res.data.trade_state_desc == '支付成功') {

                                this.open()
                                clearInterval(this.timer)
                            }
                        })
                    }, 3000);
                    new QRCode(this.$refs.qrcode, {
                        text: res.data.data.codeUrl,
                        width: 158,
                        height: 158
                    });

                })
            } else {
                clearInterval(this.timer)

                this.total_amount = 0.01
                const container = document.getElementById('qrcode-container');
                container.innerHTML = ''; // 清空容器 
                addMember({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
                    this.timer = setInterval(() => {
                        getStatusByOrderSn({ orderNo: res.data.data.orderSn }).then((res) => {
                            console.log(res, 'res...----');
                            if (res.data.trade_state_desc == '支付成功') {

                                this.open()
                                clearInterval(this.timer)
                            }
                        })
                    }, 3000);
                    new QRCode(this.$refs.qrcode, {
                        text: res.data.data.codeUrl,
                        width: 158,
                        height: 158
                    });

                })
            }
        },
        chufa() {

        },
        close() {
            this.visible = false
            clearInterval(this.timer)
        }
    },
    created() { },
    mounted() {

        // addMember({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
        //     console.log(res, 'dddd');

        //     new QRCode(this.$refs.qrcode, {
        //         text: res.data.data.codeUrl,
        //         width: 158,
        //         height: 158
        //     });

        // })
    }
}
</script>

<style lang=scss scoped>
.my-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}


.my-dialog {
    // background-color: pink;
    background: url('../assets/newImg2/84652697ca554fa9636206cbd849f43.png') no-repeat center center;
    // background-image: url('../assets/newImg2/Gold wave abstract background illustration@1x.png');
    // background-size: 100% 100%;
    // background-color: pink;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    width: 1060px;
    height: 500px;
    display: flex;
    z-index: 99;
    cursor: pointer;

    // background-repeat: no-repeat;
    /* 或者其他宽度 */
    .dialog1 {
        flex: 1;
        position: relative;
        box-sizing: content-box;

        .dialog1-img {
            width: 73%;
            height: 81%;
            position: absolute;
            right: 20px;
            bottom: 60px;
            border-radius: 16px;


        }
    }

    .dialog2 {
        flex: 1;
        position: relative;
        box-sizing: content-box;

        .dialog2-img {
            border-radius: 16px;
            width: 73%;
            height: 81%;
            position: absolute;
            right: 40px;
            bottom: 60px;

        }
    }

    .dialog3 {
        flex: 1.3;

        .dialog3-img {
            width: 100%;
            height: 400px;
            margin-top: 50px;
        }
    }
}

.my-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-dialog-body {
    /* 样式 */
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
    // transition: opacity 0.3s;
}

.dialog-fade-enter,
.dialog-fade-leave-to

/* .dialog-fade-leave-active in <2.1.8 */
    {
    // opacity: 0;
}

.bgc {
    border: 3px solid yellow;
}
</style>