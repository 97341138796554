<template>
    <div class="contain" :style="'width:' + width + 'px;' + 'height:' + height + 'px;'">

        <div class="topNav">

            <div class="imgText">
                <div @click="goIndex" style="width: 184px; height: 130px;cursor: pointer;z-index: 10000;">
                    <img src="../assets/theLogo.png" style="width: 152px; height: 54px" alt="" />
                    <img src="../assets/login/口号_工业原材料商城 1.png" style="
                            width: 184px;
                            height: 65px;
                            transform: translate(-13px, -18px);
                        " />
                </div>
                <div class="icon-nav">
                    <div class="icon">
                        <i style="font-size: 39px !important" class="iconfont icon-zhengpinbaozhangxian"></i>
                        <el-button type="text" class="iconBtn"> 正品</el-button>
                    </div>
                    <div class="icon">
                        <i class="iconfont icon-dc-icon-shengji"></i>
                        <el-button type="text" class="iconBtn"> 价优</el-button>
                    </div>
                    <div class="icon">
                        <i class="iconfont icon-cir-umbrella"></i>
                        <el-button type="text" class="iconBtn">
                            服务好</el-button>
                    </div>
                    <div class="icon">
                        <i class="iconfont icon-dianhua-xianxingyuankuang"></i>
                        <el-button type="text" class="iconBtn">
                            0668-3330000</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <el-image style="width: 956px; height: 100vh;  margin-left: 202.5px;" :src="webConfig.login_home" :fit="fit">
        </el-image> -->
        <!-- <el-image style="width: 100vw; height: 500px" :src="webConfig.login_home" :fit="fit" class="bgcImg">
        </el-image> -->
        <div style="width: 100%; height: 900px; position: absolute">
            <img style="width: 100%; height: 520px" src="../assets/login/lognBgc1.png" class="bgcImg" />
            <div class="loginBox" :class="{
        heights: isRegister == 1
    }">
                <!-- <div class="box"></div> -->
                <!-- <el-image
						style="
							width: 185px;
                            height: 100px;
							margin-top: 32px;
							margin-left: 60px;
						"
						:src="require('../assets/theLogo.png')"
						:fit="fit"
					>
					</el-image> -->

                <div class="loginBoxT">

                    <div class="titleT">登录</div>
                    <div class="rightWXlogin">
                        <div style="width: 118px; display: flex" class="yyP">
                            <div>
                                <i class="iconfont icon-weixin" style="color: #45e175"></i>
                            </div>
                            <div style="color: #4f93bb; font-size: 12px">
                                微信扫码登录
                            </div>
                            <div class="sanjiao"></div>
                        </div>
                        <div style="position: relative" @click="getWxCode" class="erw">
                            <i class="iconfont icon-erweima" style="color: #1b79ba; font-size: 50px"></i>
                            <div class="sj"></div>
                        </div>
                    </div>

                    <div class="wxma" v-show="iswxma">
                        <div style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                            ">
                            <div class="tt">
                                <div style="
                                        text-align: right;
                                        margin: 10px 10px 0 0;
                                    " class="t">
                                    <div class="closeFn" @click="iswxma = false">
                                        <span style="
                                                display: block;
                                                transform: translate(1px, -1px);
                                            ">x</span>
                                    </div>
                                </div>
                                <div id="erweima">

                                    <!-- <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQAQAAAACoxAthAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAd2KE6QAAAAJcEhZcwAAFxEAABcRAcom8z8AAAItSURBVHja7dtNcsIwDIZhMVmw5AgchaORo+UoHIEliwyqZcmOQ9spMf1bvBom07R+uvrGthIjurkEAoFA/huRWnsd9/bbaVCd5TSL7Oz2soyAQLrIIQJn429yToNLBCcnu7i9QiC9pM2ktrEUOWaSkwmB/A0Z85yYkjyJkZOPh0C+j9hsWZJpH7FFGQJ5nSwrcroO+WaOfHos36/IEMgGUstnv6FkcrBdX8mkFwTSQz4oz2S+yu7hbxBIDxHfvJUtnDSx1Lt1Cn69CgTSRa4xr519hZ0blVrRe/7EszgIpIfYT6k7uFksU9VkRnksjSxTHwTyC6QmOVlpOgtZPYXzFgQC2Uy8LI37IFN5BDflwWm2tOckTSwhkK2kfZWwdK/iI3Nl8rhRhECeI8tTOIkVdvJhddenyyILgfQRW1vLLm4s815dYUtBIJ1E6xMS38XNzVvRu/0ykqkiAoF0kbxqju+ekNQ58OgQAuklsbzexCdA8UD67Hcv5yfzXu6gEMgvEUtyM752r1beveZ/eZF1+CGQp4nt+uqpNu9hfVEeciwlrD6c6YVAniZtJXVuG4py4ihfRSCQLrIMWB+e1HlRed774AUEBPIUOZRM7qO/mNYrrMYWzkdBID3EMxlkjl3cqh6mPgikl3i/kOqk5YBuvj1qLQjkJeKHwLUmc/fpdxMgkJ8mXmP9/ssc5y2tyotU+WTXB4F8TWo1nYVqkMtqYYZAesjGgkAgkH9F3gDiIoKZb8ktNwAAAABJRU5ErkJggg=="
                                        style="width: 300px; height: 300px"
                                        alt=""
                                    />
                                    <div style="font-size: 14px; color: #999">
                                        请扫二维码进行登录
                                    </div>
                                    <div
                                        style="font-size: 12px; color: #e0e0e0"
                                    >
                                        登录即代表你同意中海南联的协议
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="loginContent" v-if="isRegister == 0">
                    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="isRegister == 0">
                        <el-tab-pane label="短信登录" name="first">
                            <div class="loginInfo">
                                <!-- <div class="title">手机号</div> -->
                                <el-input placeholder="请输入手机号" v-model="loginPhone" class="iptStyle" style="
                                        width: calc(90% + 15px);
                                        margin-bottom: 10px;
                                        height: 40px;
                                    ">
                                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                                </el-input>
                            </div>

                            <div class="loginInfo">
                                <!-- <div class="title">验证码</div> -->
                                <div style="
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                    ">
                                    <el-input placeholder="请输入验证码" class="iptStyle" v-model="registerVerification"
                                        style="width: 57%; height: 40px">
                                        <i slot="prefix" class="el-input__icon el-icon-message"></i>
                                    </el-input>


                                    <div class="getVerification" v-if="ishd" @click="loginSendCode(2)">
                                        获取验证码
                                    </div>
                                    <div class="getVerification onclass" v-else>
                                        {{ codenum }}
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="btn" @click="loginMobileCheck" @keyup.enter="keyDown(e)">
                                <el-button type="primary" :loading="false" style="width: 100%;">加载中</el-button>
                                登录中...
                            </div> -->
                            <el-button type="primary" :loading="loginStatus" style="width: 95%;margin-top: 5px;"
                                @click="loginMobileCheck" @keyup.enter="keyDown(e)">
                                登录
                            </el-button>
                            <div style=" display: flex; justify-content: space-between; ">
                                <div class=" hhe" style="margin-top: 23px;cursor: pointer" @click="forgetpwd">
                                    忘记密码
                                </div>
                                <div class="register" style="margin-right: 22px" @click="checkOutRegister">
                                    注册用户
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="密码登录" name="second">
                            <div class="loginInfo">
                                <!-- <div class="title">手机号</div> -->
                                <el-input placeholder="请输入手机号" class="iptStyle" v-model="loginPhone" style="width: 95%">
                                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                                </el-input>
                            </div>

                            <div class="loginInfo">
                                <!-- <div class="title">密码</div> -->

                                <el-input placeholder="请输入密码" class="iptStyle" v-model="loginPwd" show-password
                                    @keyup.enter.native="loginMobilePassCheck"
                                    style="width: 95%; margin-top: 10px;z-index: 10000;">
                                    <i slot="prefix" class="el-input__icon el-icon-message"></i>
                                </el-input>
                            </div>

                            <!-- <div class="btn" @click="loginMobilePassCheck">
                         
                                登录
                            </div> -->
                            <el-button type="primary" :loading="loginStatus" style="width: 95%;margin-top: 5px;"
                                @click="loginMobilePassCheck" @keyup.enter="keyDown(e)">
                                登录
                            </el-button>
                            <div style="
                                    display: flex;
                                    justify-content: space-between;
                                ">
                                <div class="hhe" style="margin-top: 23px" @click="forgetpwd">
                                    忘记密码
                                </div>
                                <div class="register" style="margin-right: 22px" @click="checkOutRegister">
                                    注册用户
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="loginContent" v-if="isRegister == 1">
                    <el-tabs v-if="isRegister == 1" v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="注册" name="first">
                            <div class="loginInfo uup">
                                <!-- <div class="title">用户名</div> -->
                                <el-input placeholder="请输入用户名" v-model="names" style="width: 95%" class="iptStyle">
                                </el-input>
                            </div>

                            <div class="loginInfo uup">
                                <!-- <div class="title">手机号</div> -->
                                <el-input placeholder="请输入手机号" v-model="registerPhone" style="width: 95%"
                                    class="iptStyle"></el-input>
                            </div>

                            <div class="loginInfo uup">
                                <!-- <div class="title">密码</div> -->
                                <el-input type="password" placeholder="请输入密码" show-password v-model="registerPwd"
                                    style="width: 95%" @keyup.enter.native="loginMobilePassCheck"
                                    class="iptStyle"></el-input>

                            </div>
                            <div style="color: gray;font-size: 14px;margin-left: 5px;margin-top: 2px;">
                                密码必须包含大小写字母和数字且长度为8-16</div>
                            <div class="loginInfo uup">
                                <!-- <div class="title">密码</div> -->
                                <el-input type="password" placeholder="再次输入密码" show-password v-model="registerPwd2"
                                    style="width: 95%" @keyup.enter.native="loginMobilePassCheck"
                                    class="iptStyle"></el-input>
                            </div>

                            <div class="loginInfo uup">
                                <!-- <div class="title">验证码</div> -->
                                <div style="
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                    ">
                                    <el-input placeholder="请输入验证码" v-model="registerVerification" style="width: 57%"
                                        class="iptStyle"></el-input>

                                    <div class="getVerification" v-if="ishd" @click="loginSendCode(1)">
                                        获取验证码
                                    </div>
                                    <div class="getVerification onclass" v-else>
                                        {{ codenum }}
                                    </div>
                                </div>
                            </div>

                            <div class="btn" style="background-color: #1b79ba" @click="registerInfo">
                                <!-- <span>注</span>
                            <span>册</span>
                            <img src="../assets/login/a.png" alt="" /> -->
                                注册
                            </div>

                            <div class="register ppt" @click="backLogin">
                                返回登录
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <!-- <footBar /> -->
        <div class="authentication">
            <div style="width: 100vw;height: 10vh">
                <footBar style="width: 100vw;height: 10vh" />
            </div>
            <!-- <footBar /> -->
            <!-- <div style="margin-bottom: 6px;"><a href="https://zhnlb.zhnl.com">中海南联原材料商城管理后台</a></div>
            <div style="padding: 5px;">
                Copyright © 2022 广东中海南联能源有限公司版权所有 |
                粤ICP备20056570号
            </div>
            <div>公司地址：广州市天河区黄埔大道西76号809室810房</div> -->
        </div>
    </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { login, getCode, toRegist, loginByCode,getLoginParam,checkTask } from '@/api/user'
import '../assets/login/icon/font_4210042_jr5y4vpg6/iconfont.css'
import QRCode from 'qrcode';  
import footBar from '../components/footBar.vue'

export default {
    data() {
        return {
            loginStatus:false,
            path:'',
            v_id:'',
            width: 0,
            height: 0,
            activeName: 'first',
            phone1: '',
            verification: '',

            pw: '',
            isRegister: '0',
            //注册数据
            registerPhone: '',
            registerPwd: '',
            registerPwd2:'',
            registerVerification: '',
            //登录数据
            loginPhone: '',
            loginPwd: '',
            names: '',
            webConfig: '',
            iswxma: false,
            fit: 'cover',
            istab: 0,
            dialogpassword: false, //忘记密码
            positionTimer: null,
            codenum: 60,
            ishd: true
        }
    },

    components: {
        footBar
    },
    created() {
        this.width = document.body.clientWidth
        this.height = document.body.clientHeight
    },
    methods: {
        keyDown(e){
            console.log(e,'e...........');
        },
        goIndex(){
            console.log('123');
            window.location = '/'
        },
        getWxCode(){
            this.isRegister = '0'
            this.activeName = 'first'
                this.iswxma = true
                console.log('ddddddppppppppp', document.getElementById('erweima'));

        },
        handleClick(e) {
            console.log(e.index)
            this.istab = e.index
        },
        checkOutRegister() {
            console.log('123')
            this.isRegister = '1'
            this.activeName = 'first'
        },
        backLogin() {
            this.isRegister = '0'
        },

        //登录接口验证码
        async loginMobileCheck() {
            
            if (this.loginPhone.length != 11) {
                this.$message({
                    type: 'error',
                    message:'请输入正确的手机号码',
                    offset: 100,
                
                })
                return
            }
            let obj = {
                mobile: this.loginPhone,
                code: this.registerVerification
            }
            loginByCode(obj).then((res) => {
                this.loginStatus = true
                if(res?.data.code == 4019){
                    this.$message({
            type: 'error',
            message: res.data.msg,
            offset: 100,
        })
                    this.loginStatus = false
                }else if(res?.data.code == 200){
                    window.localStorage.setItem('userName', res.data.data.userName)
                window.localStorage.setItem('token', res.data.data.token)
                window.localStorage.setItem('user_id', res.data.data.user_id)
                window.localStorage.setItem('headPic', res.data.data.headPic)
                checkTask({}).then((res)=>{

                })
                this.$message({
                    type: 'success',
                    message:'登录成功',
                    offset: 100,
                
                })
                setTimeout(() => {
                    window.location = '/buyerCenter/home'
                        window.localStorage.setItem('marketChekoutFlag', '2')
                    }, 1000)
                }
            })

            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success',
            //     })
            //     window.localStorage.setItem('user_id', res.data.user_id)

            //     setTimeout(() => {
            //         window.location = '/'
            //     }, 1000)
            // } else {
            //     this.$message.error(res.msg)
            // }
        },
        //账号密码登录
         loginMobilePassCheck() {
            if (this.loginPhone.length != 11) {
                this.$message({
                    type: 'error',
                    message:'请输入正确的手机号码',
                    offset: 100,
                
                })
                return
            }
            this.loginStatus = true
            let data = {
                mobile: this.loginPhone,
                password: this.loginPwd
            }
            login(data).then((res) => {
                if (res.data.code == 200) {
                    window.localStorage.setItem('token', res.data.data.token)
                    window.localStorage.setItem(
                        'user_id',
                        res.data.data.user_id
                    )
                    window.localStorage.setItem('userName', res.data.data.userName)
                    window.localStorage.setItem('headPic', res.data.data.headPic)
                    checkTask({}).then((res)=>{
                    
                })
                    this.$message({
                    type: 'success',
                    message:'登录成功',
                    offset: 100,
                
                })
                    setTimeout(() => {
                       if(this.path&&this.v_id){
                        window.location = `${this.path}?v_id=${this.v_id}`
                       }else if(this.path){
                        window.location = `${this.path}`
                       }else{
                        window.location = '/buyerCenter/home'
                       }
                        window.localStorage.setItem('marketChekoutFlag', '2')
                    }, 1000)
                }else if(res.data.code ==4001){
                    console.log('走到这里了吗');
                        this.$message({
            type: 'error',
            message: res.data.msg,
            offset: 100,
        })
                    this.loginStatus = false


                }else{
                    this.$message({
                    type: 'error',
                    message:res.data.msg,
                    offset: 100,
                
                })
                }
            })

            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success',
            //     })
            //     window.localStorage.setItem('user_id', res.data.user_id)

            //     setTimeout(() => {
            //         window.location = '/'
            //     }, 1000)
            // } else {
            //     this.$message.error(res.msg)
            // }
        },

        //注册接口
        async registerInfo() {
            if (this.registerPhone.length != 11) {
                this.$message({
                    type: 'error',
                    message:'请输入正确的手机号码',
                    offset: 100,
                
                })
                return
            }
            if(this.registerPwd !=this.registerPwd2){
                this.$message({
                    type: 'warning',
                    message:'两次密码输入不一致',
                    offset: 100,
                
                })
                return
            }
            // let res = await this.$http('/mini/ApiConnect/registerInfo', {
            //     mobile: this.registerPhone,
            //     password: this.registerPwd,
            //     real_name: this.names,
            // })
            let obj = {
                mobile: this.registerPhone,
                password: this.registerPwd,
                userName: this.names,
                code: this.registerVerification
            }
            var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
          if (passwordRegex.test(this.registerPwd)) {
  console.log("密码符合要求");
} else {
  return  this.$message({
                    message: '密码必须包含大小写字母和数字且长度为8-16',
                    type: 'warning',
                    offset: 100,

                   
                })
}
            toRegist(obj).then((res) => {
                console.log(res, '注册测试')
                if (res.data.code == 200) {
                    this.$message({
                    message: '注册成功',
                    type: 'success',
                    offset: 100,

                   
                })
                this.registerVerification = ''
                this.backLogin()
                }else if(res.data.code ==4018){
                    this.$message({
                    message: '验证码错误',
                    type: 'error',
                    offset: 100,

                })
                }else{
                    this.$message({
                    message: res.data.msg,
                    type: 'error',
                    offset: 100,

                })
                // window.location = '/login'
                }
            })

            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success'
            //     })
            //     this.backLogin()
            // } else {
            //     this.$message.error(res.msg)
            // }
        },

        //验证码接口
        async loginSendCode(num) {
            
            if (num == 1) {
                if (
                    this.registerPhone === null
                        ? true
                        : this.registerPhone.length !== 11
                )
                    return      this.$message({
                    type: 'error',
                    message:'请输入正确的手机号码',
                    offset: 100,
                
                })
                let data = {
                    mobile: this.registerPhone
                }
                this.positionTimer = setInterval(() => {
                    if (this.codenum < 1) {
                        clearInterval(this.positionTimer)
                        this.positionTimer = null
                        this.codenum = 60
                        this.ishd = true
                    } else {
                        this.ishd = false
                        this.codenum -= 1
                    }
                }, 1000)
                getCode(data).then((res) => {
                    console.log(res, 'zhonghainanl')
                })
            } else if (num == 2) {
                if (
                    this.loginPhone === null
                        ? true
                        : this.loginPhone.length !== 11
                )
                this.$message({
                    type: 'error',
                    message:'请输入正确的手机号码',
                    offset: 100,
                
                })
                getCode({ mobile: this.loginPhone }).then((res) => {
                    console.log(res, 'zhonghainanl')
                })
                this.positionTimer = setInterval(() => {
                    if (this.codenum < 1) {
                        clearInterval(this.positionTimer)
                        this.positionTimer = null
                        this.codenum = 60
                        this.ishd = true
                    } else {
                        this.ishd = false
                        this.codenum -= 1
                    }
                }, 1000)
            }
            // let res = await this.$http('/mini/ApiConnect/loginSendCode', {
            //     mobile: this.registerPhone,
            //     type: 1,
            // })

            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success',
            //     })
            //     // this.backLogin()
            // } else {
            //     this.$message.error(res.msg)
            // }

            // }else if(num==2){
            //     if (this.loginPhone === null ? true : this.loginPhone.length !== 11) return this.$message.error('请输入正确的手机号')
            //     let res = await this.$http('/mini/ApiConnect/loginSendCode', {
            //         mobile: this.loginPhone,
            //         type: 2,
            //     })

            //     if (res.status == 1) {
            //         this.$message({
            //             message: res.msg,
            //             type: 'success',
            //         })
            //         // this.backLogin()
            //     } else {
            //         this.$message.error(res.msg)
            //     }

            // } else {
            //     if (this.phone1 === null ? true : this.phone1.length !== 11) return this.$message.error('请输入正确的手机号')
            //     let res = await this.$http('/mini/ApiConnect/loginSendCode', {
            //         mobile: this.phone1,
            //         type: 2,
            //     })

            //     if (res.status == 1) {
            //         this.$message({
            //             message: res.msg,
            //             type: 'success',
            //         })
            //         // this.backLogin()
            //     } else {
            //         this.$message.error(res.msg)
            //     }

            // }
            //      this.ishd = false
        },
        //公共接口
        async WebConfig() {
            let res = await this.$http('/mini/ApiConnect/WebConfig')

            this.webConfig = res.result
        },
        forgetpwd() {
            this.$router.push('/forgetpassword')
        }

    },

    mounted() {
        console.log(this.$route,'打印了router');
        this.path = this.$route.query.path
        this.v_id = this.$route.query.v_id
     
            if (this.$route.query.ifRedis == 1) {
                this.isRegister = '1'
            this.activeName = 'first'

}
           var obj =   new WxLogin({
                   self_redirect: false, // 是否在iframe内跳转 redirect_uri
                  id: 'erweima', // 希望二维码嵌入容器的 id
                  appid:"wx18b332ba52498422",
                   scope: "snsapi_login",
                  redirect_uri: encodeURIComponent("https://www.zhnl.com"),
                  href:'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMjAwcHg7DQogIGhlaWdodDogMjAwcHg7DQp9DQo=',
    })
        // let params = {  
        //     code:
        // }
   
    //   var obj =   new WxLogin({
    //                self_redirect: true, // 是否在iframe内跳转 redirect_uri
    //               id: 'wechat-login', // 希望二维码嵌入容器的 id
    //               appid:"wxed9954c01bb89b47",
    //                scope: "snsapi_login",
    //               redirect_uri: "https://www.baidu.com",
    //               href:'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMjAwcHg7DQogIGhlaWdodDogMjAwcHg7DQp9DQo=',
    // })
    // console.log(obj,'obj');
        // this.WebConfig()
    },

    watch: {
        $route(to, from) {
            this.$router.go(0)
        }
    }
}
</script>

<style lang="scss" scoped>
#wechat-login {
    // background-color: red;
    border: 1px solid red;
    width: 100px;
    height: 100px;
}

>>>.el-tabs__nav .el-tabs__item {
    font-size: 14px !important;
}

.authentication {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 620px;
    background-color: white;
    transform: translateX(-50%);
    color: #9e9e9e;
    height: 100px;
    text-align: center;
    // z-index: 99;
}

.authentication:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.box {
    width: 1000px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: -18px;
    left: 0;
}

.contain {
    min-width: 100vw;
    height: 100vh !important;
    // min-height: 100vh;
    z-index: 99;
    background-color: #fff;
    margin-top: -29px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.loginBox {
    width: 415px;
    height: 336px;
    // width: 568px;
    // height: 550px;
    background-color: white;
    // margin-top: 10%;
    // margin-left: 10%;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 126px;
    transform: translate(-5%, 0);
    box-shadow: 0 19px 40px #c4c3c3;
    border-radius: 20px;

    .loginBoxT {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        position: relative;

        .wxma {
            width: 100%;
            height: 60px;
            transform: all 0.6s;

            .tt {
                position: absolute;
                width: 415px;
                height: 385px;
                background-color: #fff;
                z-index: 999999;
                border-radius: 20px;

                .closeFn {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #e0e0e0;
                    text-align: center;
                    line-height: 30px;
                    position: absolute;
                    color: #fff;
                    top: 10px;
                    right: 10px;
                    font-weight: 700;
                    font-size: 20px;
                }

                .closeFn:hover {
                    box-shadow: 0 0 10px #999;
                }

                #erweima {
                    width: 300px;
                    height: 330px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }
            }
        }

        .closewx:hover {
            transform: rotate(-360deg);
            color: #006eb7;
        }

        .rightWXlogin {
            display: flex;
            position: absolute;
            right: 10px;
            top: 10px;

            .yyP {
                height: 28px;
                width: 118px;
                text-align: center;
                line-height: 28px;
                opacity: 1;
                border: 1px solid #f0e8d7;
                background: #fef9ee;
                position: relative;
                margin-right: 20px;
                box-sizing: border-box;
                padding-left: 10px;

                .sanjiao {
                    width: 0;
                    height: 0;
                    position: absolute;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-50%);

                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 10px solid #f5ecd8;
                }
            }

            .sj {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30px;
                height: 30px;
                background: linear-gradient(45deg,
                        #fff,
                        #fff 50%,
                        transparent 50%,
                        transparent 100%);
                transition: all 0.6s;

                display: block;
            }

            .erw:hover .sj {
                // display: none;
                width: 0;
                height: 0;
            }
        }

        .titleT {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 32px;
            height: 18px;
            opacity: 1;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            font-family: 'Microsoft YaHei UI';
            text-align: left;
            line-height: 18px;
        }
    }
}

// @media screen and (max-width: 1400px) {
//     .loginBox {
//         width: 568px;
//         height: 520px;
//         background-color: white;
//         // margin-top: 7%;
//         // margin-left: 10%;
//         box-shadow: 0 19px 40px #c4c3c3;
//         border-radius: 20px;
//     }
// }

.heights {
    // margin-top: 5%;
    height: 499px;
}

// @media screen and (max-width: 1400px) {
//     .heights {
//         // margin-top: 2%;
//         // height: 318px;
//         // margin-bottom: 50px;
//     }
// }

::v-deep .el-tabs__item {
    width: 88px !important;
    // width: 205px !important;
    height: 37px !important;
    // height: 53px !important;
    font-size: 14px !important;
    text-align: center !important;
    // font-size: 40px !important;
    color: #000000;
}

::v-deep .el-input__inner {
    // background-color: #eff8fe;
    // border: none !important;
}

::v-deep .el-tabs__item.is-active {
    color: #006eb7 !important;
}

::v-deep .el-tabs__active-bar {
    background-color: #006eb7;
    // width: 88px !important;
    // width: 162px !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background: none;
}

.loginContent {
    margin-top: 0px;
    margin-left: 20px;

    .uup {
        margin-top: 10px;
    }

    .loginInfo {
        width: 100%;
        // margin-bottom: 15px;
        // margin-top: 13px;

        ::v-deep .el-input__inner {
            height: 40px !important;
        }

        .iptStyle {
            height: 40px;
            width: 100%;
            border: 1px solid #eee;
            background-color: #fff;
        }

        .getVerification {
            width: 33%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 13px;
            // background-color: #006eb7;
            margin-left: 13px;
            border-radius: 4px;
            border: 1px solid #d8d8d8;
            color: #878787;
            cursor: pointer;
        }

        .title {
            margin-bottom: 8px;
        }

        .el-input__inner {
            background-color: #eff8fe !important;
        }
    }

    .el-button--primary {
        background: linear-gradient(#25a1ee 10%, #0379c5 50%);
        height: 48px;
    }

    .btn {
        width: 94%;
        // width: 150px;
        height: 46px;
        // border-radius: 23px;
        // background-color: #006eb7;
        text-align: center;
        line-height: 46px;
        color: white;
        // margin-left: 145px;
        margin-top: 15px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        // padding: 0 10px;
        box-sizing: border-box;
        align-items: center;
        background: linear-gradient(#25a1ee 10%, #0379c5 50%);
    }

    .ppt {
        margin-top: 10px !important;
    }

    .register {
        // margin-left: 188px;
        margin-top: 23px;
        text-align: center;
        color: #006eb7;
        // color: #00000033;
        cursor: pointer;
    }

    .forget {
        position: absolute;
        right: 53px;
        top: 114px;
        font-size: 14px;
        color: #000000;
        opacity: 0.5;
    }
}

@media screen and (max-width: 1500px) {
    // .loginContent {
    //     margin-top: 27px;
    //     margin-left: 41px;
    // }

    .imgText {
        padding-left: 200px !important;
    }

    .icon-nav {
        margin-right: 200px !important;
    }

    .authentication {
        width: 100vw;
        position: absolute;
        left: 50%;
        top: 670px;
        transform: translateX(-50%);
        color: #9e9e9e;
        text-align: center;
        // z-index: 99;
    }

    .authentication:after {
        /*伪元素是行内元素 正常浏览器清除浮动方法*/
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

}

.bgcImg {
    min-width: 1500px;
    position: absolute;
    top: 100px;
    left: 0;
    display: block;
}

.topNav {
    width: 100%;
    height: 120px;
    background-color: white;
    position: relative;
    box-sizing: border-box;
    padding: 5px 0;

    .imgText {
        padding-left: 360px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        min-width: 100%;
        overflow: hidden;

        .icon-nav {
            display: flex;
            width: 545px;
            height: 100px;
            line-height: 100px;
            justify-content: space-between;
            margin-right: 345px;
        }

        .icon {
            height: 32px;

            // width: 32px;
            .iconfont {
                font-size: 32px !important;
                cursor: pointer;
            }

            .iconBtn {
                color: #303030;
                font-size: 16px;
                font-weight: 400;
                transform: translate(10%, -8%);
                cursor: pointer;
            }
        }

        .icon:hover {
            color: #006eb7 !important;

            .iconBtn {
                color: #006eb7 !important;
            }
        }
    }
}
</style>
