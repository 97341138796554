<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible2" width="56%" top="6vh">
            <div></div>
            <div style="white-space:pre-wrap;height: 600px;    overflow-y: scroll;">

                <span v-html="info2.pContent"></span>

            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
            </span>
        </el-dialog>
        <transition name="dialog-fade">
            <div v-if="visible" class="my-dialog-overlay">
                <div class="login-box">
                    <!-- <div style="z-index: -1;">
                        <img src="../assets/newImg3/73d3ab589bdcefc5e0b800c6cfc85e9.png"
                            style="position: absolute;top: 201px;width: 722px;height: 389px;left: 50px;z-index: 1;"
                            alt="">
                    </div> -->
                    <img src="../assets/newImg3/Logo-搜原网-20240723 1@1x.png" alt=""
                        style="bottom: 0;left: -80px;position: absolute;z-index: 1;">
                    <div class="login-top">
                        <img src="../assets/newImg3/按钮-关闭@1x.png"
                            style="margin-right: 12px;margin-top: 6px;cursor: pointer;" @click="closeDialog" alt="">
                    </div>
                    <div class="login-middle" v-show="ifRedister == 0">
                        <div class="wxCode">
                            <div id="erweima"></div>
                        </div>
                        <div class="inputBox">
                            <div class="inputBox-1">
                                <div class="selectTitle" style="margin-left: 150px;" :class="{ bgc: wzNum == 1 }"
                                    @click="changeWz(1)">密码登录
                                </div>
                                <div
                                    style="width: 1px;height: 20px;border-radius: 8px;opacity: 1;background: #E3E5E7;margin-top: 15px;">
                                </div>
                                <div class="selectTitle" :class="{ bgc: wzNum == 2 }" @click="changeWz(2)">短信登录</div>
                            </div>
                            <div class="inputBox-2" v-show="wzNum == 1">
                                <div
                                    style="border: 1px solid #E3E5E7; width: 398px;height: 88px;margin-left: 64px;border-radius: 5px;margin-top: 13px;">
                                    <div
                                        style="height: 44px;width: 100%;display: flex;border-bottom: 1px solid #E3E5E7;">
                                        <div
                                            style="width: 40px;padding: 9px 4px 9px 20px;color: #212121;font-weight: 290;">
                                            账号</div>
                                        <div style="width: 358px;">
                                            <el-input v-model="form.mobile" placeholder="请输入账号"
                                                style="width: 70%;"></el-input>
                                        </div>
                                    </div>
                                    <div style="height: 44px;width: 100%;display: flex;">
                                        <div
                                            style="width: 40px;padding: 9px 4px 9px 20px;color: #212121;font-weight: 290;">
                                            密码</div>
                                        <div style="width: 358px;display: flex;">
                                            <el-input v-model="form.password" style="width: 70%;border: 0px solid red;"
                                                type="password" show-password placeholder="请输入密码"
                                                @keyup.enter.native="toLogin"></el-input>
                                            <div @click="forgetPassword"
                                                style="color: #227BFF;font-weight: 290;font-size: 14px;cursor: pointer;z-index: 20000;line-height: 40px;margin-left: 10px;">
                                                忘记密码?
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="inputBox-2" v-show="wzNum == 2">
                                <div
                                    style="border: 1px solid #E3E5E7; width: 398px;height: 88px;margin-left: 64px;border-radius: 5px;margin-top: 13px;">
                                    <div
                                        style="height: 44px;width: 100%;display: flex;border-bottom: 1px solid #E3E5E7;">
                                        <div
                                            style="width: 55px;padding: 9px 4px 9px 20px;color: #212121;font-weight: 290;">
                                            手机号</div>
                                        <div style="width: 358px;">
                                            <el-input v-model="form.mobile" placeholder="请输入账号"
                                                style="width: 65%;"></el-input>
                                            <!-- <div
                                                style="width: 1px;height: 20px;border-radius: 8px;opacity: 1;background: #E3E5E7;margin-top: 15px;display: inline;">

                                            </div> -->
                                            <span style="color:#E3E5E7 ;margin-right: 5px;">|</span>
                                            <span
                                                style="color: #C0C0C0;font-weight: 290;font-size: 14px;cursor: pointer;margin-left: 9px;"
                                                @click="loginSendCode(1)" v-if="ishd">获取验证码</span>
                                            <span
                                                style="color: #C0C0C0;font-weight: 290;font-size: 14px;margin-left: 28px;"
                                                v-else>{{
            codenum
        }}</span>


                                        </div>

                                    </div>
                                    <div style="height: 44px;width: 100%;display: flex;">
                                        <div
                                            style="width: 55px;padding: 9px 4px 9px 21px;color: #212121;font-weight: 290;">
                                            验证码</div>
                                        <div style="width: 358px;display: flex;">
                                            <el-input v-model="form.code" style="width: 70%;border: 0px solid red;"
                                                type="password" show-password placeholder="请输入密码"></el-input>
                                            <!-- <div
                                                style="color: #227BFF;font-weight: 290;font-size: 14px;cursor: pointer;z-index: 20000;line-height: 40px;margin-left: 10px;">
                                                忘记密码?
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="inputBox-3" v-show="wzNum == 1">
                                <el-button style="margin-left: 68px;" class="clickable-button" @click="register">
                                    注册</el-button>
                                <el-button type="primary" class="clickable-button" @click="toLogin"
                                    :loading="loginStatus">登录</el-button>
                            </div>
                            <div class="inputBox-3" v-show="wzNum == 2">
                                <!-- <el-button style="margin-left: 68px;" class="clickable-button" @click="register">
                                    注册</el-button> -->
                                <el-button type="primary" style="margin-left: 32%;" class="clickable-button"
                                    @click="toCodeLogin">登录 / 注册</el-button>
                            </div>

                        </div>
                    </div>
                    <div class="login-middle2" v-show="ifRedister == 1">
                        <div class="redister-box">
                            <div
                                style="text-align: center;font-size: 18px;color: #227BFF;font-weight: 400;margin-bottom: 5px;">
                                忘记密码</div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    手机号</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form2.mobile" placeholder="请输入手机号" style="width: 100%;"
                                        class="inputHidenBorder"></el-input>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    验证码</div>
                                <div style="width: 300px;display: flex;">
                                    <el-input v-model="form2.code" placeholder="请输入验证码" style="width: 60%;height: 40px;"
                                        class="inputHidenBorder"></el-input>
                                    <div>
                                        <el-button style="margin-left: 13px;" @click="loginSendCode(2)">
                                            <span v-if="ishd2">获取验证码</span>
                                            <span v-else>{{ codenum2 }}</span>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    重置密码</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form2.password" placeholder="请输入密码" style="width: 100%;"
                                        class="inputHidenBorder" show-password></el-input>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    确认密码</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form2.password2" placeholder="请输入密码" style="width: 100%;"
                                        class="inputHidenBorder" show-password></el-input>
                                </div>
                            </div>
                            <div class="input-item2" style="margin-top: 5px">
                                <el-button style="margin-left: 64px;" class="clickable-button2" @click="back">
                                    返回</el-button>
                                <el-button type="primary" class="clickable-button2" @click="editPassword">确认</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="login-middle2" v-show="ifRedister == 2">
                        <div class="redister-box">
                            <div
                                style="text-align: center;font-size: 18px;color: #227BFF;font-weight: 400;margin-bottom: 5px;">
                                注册</div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    手机号</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form3.mobile" placeholder="请输入手机号" style="width: 100%;"
                                        class="inputHidenBorder"></el-input>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    验证码</div>
                                <div style="width: 300px;display: flex;">
                                    <el-input v-model="form3.code" placeholder="请输入验证码" style="width: 60%;height: 40px;"
                                        class="inputHidenBorder"></el-input>
                                    <div>
                                        <el-button style="margin-left: 13px;" @click="loginSendCode(3)">
                                            <span v-if="ishd3">获取验证码</span>
                                            <span v-else>{{ codenum3 }}</span>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    密码</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form3.password" placeholder="请输入密码" style="width: 100%;"
                                        class="inputHidenBorder" show-password></el-input>
                                </div>
                            </div>
                            <div class="input-item">
                                <div
                                    style="width: 64px;color: #333333;font-size: 16px;font-weight: 400;line-height: 38px;padding: 0px 10px;text-align: right;">
                                    确认密码</div>
                                <div style="width: 300px;">
                                    <el-input v-model="form3.password2" placeholder="请输入密码" style="width: 100%;"
                                        class="inputHidenBorder" show-password></el-input>
                                </div>
                            </div>
                            <div class="input-item2" style="margin-top: 5px">
                                <el-button style="margin-left: 64px;" class="clickable-button2" @click="back">
                                    返回</el-button>
                                <el-button type="primary" class="clickable-button2" @click="toRegister">确认</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="login-bottom">
                        <div class="bottom-text">
                            <div style="color: #999999;font-size: 12px;font-weight: 290;">登录或完成注册即代表你同意</div>
                            <div style="color: #227BFF;font-size: 12px;font-weight: 290;cursor: pointer;"
                                @click="dialogVisible2 = true">用户协议</div>
                            <div style="color: #999999;font-size: 12px;font-weight: 290;">和</div>
                            <div style="color: #227BFF;font-size: 12px;font-weight: 290;cursor: pointer;">隐私政策</div>
                        </div>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { addMember, getStatusByOrderSn } from '../api/user'
import { login, getCode, toRegist, loginByCode, getLoginParam, checkTask, updatePwd, getUserProtocol } from '@/api/user'

export default {
    data() {
        return {
            loginStatus: false,
            info2: {},
            dialogVisible2: false,
            visible: false,
            total_amount: '0.01',
            order_description: 0,
            timer: '',
            form: {
                mobile: '',
                password: '',
                code: '',
            },
            form2: {
                mobile: '',
                password: '',
                password2: '',
                code: '',
            },
            form3: {
                mobile: '',
                password: '',
                password2: '',
                code: '',
            },
            data: {},

            wzNum: 1,
            codenum: 60,
            ishd: true,
            codenum2: 60,
            ishd2: true,
            codenum3: 60,
            ishd3: true,
            ifRedister: 0
        }
    },
    methods: {
        keyDown(e) {
            console.log(e, 'e...........');
        },
        forgetPassword() {
            this.ifRedister = 1
        },
        //注册

        editPassword() {

            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!chinaPhoneRegex.test(this.form2.mobile)) {
                return this.$message({
                    type: 'error',
                    message: '请输入正确的手机号码',
                    offset: 120,
                })
            }
            if (!this.form2.password || !this.form2.password || !this.form2.code) {
                return this.$message({
                    type: 'error',
                    message: '密码和验证码不能为空',
                    offset: 120,
                })
            }
            if (this.form2.password != this.form2.password2) {
                this.$message({
                    type: 'warning',
                    message: '两次密码输入不一致',
                    offset: 100,

                })
                return
            }
            let obj = {
                mobile: this.form2.mobile,
                password: this.form2.password,
                password: this.form2.password2,
                code: this.form2.code
            }
            updatePwd(obj).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '密码修改成功！',
                        type: 'success',
                        offset: 100,
                    })
                    this.form2.code = ''
                    this.form2.password2 = ''
                    this.form2.password = ''
                    this.form2.mobile = ''
                    this.ifRedister = 0
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error',
                        offset: 100,

                    })
                }
            })

        },
        toRegister() {
            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!chinaPhoneRegex.test(this.form3.mobile)) {
                return this.$message({
                    type: 'error',
                    message: '请输入正确的手机号码',
                    offset: 120,
                })
            }
            if (!this.form3.password || !this.form3.password || !this.form3.code) {
                return this.$message({
                    type: 'error',
                    message: '密码和验证码不能为空',
                    offset: 120,
                })
            }
            if (this.form3.password != this.form3.password2) {
                this.$message({
                    type: 'warning',
                    message: '两次密码输入不一致',
                    offset: 100,

                })
                return
            }
            let obj = {
                mobile: this.form3.mobile,
                password: this.form3.password,
                code: this.form3.code
            }
            toRegist(obj).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '注册成功,快去登录吧！',
                        type: 'success',
                        offset: 100,
                    })
                    this.ifRedister = 0
                    this.form3.code = ''
                    this.form3.password = ''
                    this.form3.mobile = ''
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error',
                        offset: 100,

                    })
                }
            })

        },
        //密码登录
        toLogin() {

            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!chinaPhoneRegex.test(this.form.mobile)) {
                return this.$message({
                    type: 'error',
                    message: '请输入正确的手机号码',
                    offset: 120,
                })
            }
            let data = {
                mobile: this.form.mobile,
                password: this.form.password
            }
            this.loginStatus = true
            login(data).then((res) => {

                if (res.data.code == 200) {
                    window.localStorage.setItem('token', res.data.data.token)
                    window.localStorage.setItem(
                        'user_id',
                        res.data.data.user_id
                    )
                    window.localStorage.setItem('userName', res.data.data.userName)
                    window.localStorage.setItem('headPic', res.data.data.headPic)
                    checkTask({}).then((res) => {

                    })
                    this.loginStatus = false
                    this.$message({
                        type: 'success',
                        message: '登录成功',
                        offset: 120,
                    })

                    const { href } = this.$router.resolve({
                        path: "/buyerCenter/home",

                    });
                    window.open(href, "_blank");
                    this.visible = false
                } else {
                    this.loginStatus = false
                    this.$message({
                        type: 'error',
                        message: res.data.msg,
                        offset: 120,
                    })
                }
            })
        },
        //验证码登录
        toCodeLogin() {
            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!chinaPhoneRegex.test(this.form.mobile)) {
                return this.$message({
                    type: 'error',
                    message: '请输入正确的手机号码',
                    offset: 120,
                })
            }
            let obj = {
                mobile: this.form.mobile,
                code: this.form.code
            }
            loginByCode(obj).then((res) => {
                if (res.data.code == 200) {
                    window.localStorage.setItem('userName', res.data.data.userName)
                    window.localStorage.setItem('token', res.data.data.token)
                    window.localStorage.setItem('user_id', res.data.data.user_id)
                    window.localStorage.setItem('headPic', res.data.data.headPic)
                    checkTask({}).then((res) => {

                    })
                    this.$message({
                        type: 'success',
                        message: '登录成功',
                        offset: 120,

                    })
                    const { href } = this.$router.resolve({
                        path: "/buyerCenter/home",

                    });
                    window.open(href, "_blank");
                    this.visible = false
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.msg,
                        offset: 120,

                    })
                }
            })
        },
        //获取验证码
        loginSendCode(id) {
            const chinaPhoneRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;

            if (id == 1) {
                if (!chinaPhoneRegex.test(this.form.mobile)) {
                    return this.$message({
                        type: 'error',
                        message: '请输入正确的手机号码',
                        offset: 120,
                    })
                }
                this.data = {
                    mobile: this.form.mobile
                }
                this.positionTimer = setInterval(() => {
                    if (this.codenum < 1) {
                        clearInterval(this.positionTimer)
                        this.positionTimer = null
                        this.codenum = 60
                        this.ishd = true
                    } else {
                        this.ishd = false
                        this.codenum -= 1
                    }
                }, 1000)
            } else if (id == 2) {
                if (!chinaPhoneRegex.test(this.form2.mobile)) {
                    return this.$message({
                        type: 'error',
                        message: '请输入正确的手机号码',
                        offset: 120,
                    })
                }
                this.data = {
                    mobile: this.form2.mobile
                }
                this.positionTimer = setInterval(() => {
                    if (this.codenum2 < 1) {
                        clearInterval(this.positionTimer)
                        this.positionTimer = null
                        this.codenum2 = 60
                        this.ishd2 = true
                    } else {
                        this.ishd2 = false
                        this.codenum2 -= 1
                    }
                }, 1000)
            }
            else if (id == 3) {
                if (!chinaPhoneRegex.test(this.form3.mobile)) {
                    return this.$message({
                        type: 'error',
                        message: '请输入正确的手机号码',
                        offset: 120,
                    })
                }
                this.data = {
                    mobile: this.form3.mobile
                }
                this.positionTimer = setInterval(() => {
                    if (this.codenum3 < 1) {
                        clearInterval(this.positionTimer)
                        this.positionTimer = null
                        this.codenum3 = 60
                        this.ishd3 = true
                    } else {
                        this.ishd3 = false
                        this.codenum3 -= 1
                    }
                }, 1000)
            }

            getCode(this.data).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '验证码已发送',
                        offset: 120,

                    })
                }
            })

        },
        closeDialog() {
            this.visible = false
        },
        changeWz(id) {
            this.form.mobile = ''
            this.form.password = ''
            this.form.code = ''
            this.wzNum = id
        },
        register() {
            this.ifRedister = 2
        },
        back() {
            this.ifRedister = 0
            console.log(this.ifRedister, 'this.ifRedister');
        },
        goEnterprise() {
            this.$router.push({
                path: '/buyerCenter/amendEnterpriseInfo'
            })
        },
        open() {
            this.$alert('支付成功!', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.visible = false

                }
            });
        },
        loginDialog() {
            this.visible = true
            this.$nextTick(() => {
                var obj = new WxLogin({
                    self_redirect: false, // 是否在iframe内跳转 redirect_uri
                    id: 'erweima', // 希望二维码嵌入容器的 id
                    appid: "wx18b332ba52498422",
                    scope: "snsapi_login",
                    redirect_uri: encodeURIComponent("https://www.zhnl.com/buyerCenter/home"),
                    href: 'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMjAwcHg7DQogIGhlaWdodDogMjAwcHg7DQp9DQo=',
                })
            });
        },
        close() {
            this.visible = false
            clearInterval(this.timer)
        }
    },
    created() { },
    mounted() {
        getUserProtocol({ mark_id: 2 }).then((res) => {
            this.info2 = res.data.data
        })

        // this.$nextTick(() => {
        //     var obj = new WxLogin({
        //         self_redirect: false, // 是否在iframe内跳转 redirect_uri
        //         id: 'erweima', // 希望二维码嵌入容器的 id
        //         appid: "wx18b332ba52498422",
        //         scope: "snsapi_login",
        //         redirect_uri: encodeURIComponent("https://www.zhnl.com/buyerCenter/home"),
        //         href: 'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMjAwcHg7DQogIGhlaWdodDogMjAwcHg7DQp9DQo=',
        //     })
        // });

    }
}
</script>

<style lang=scss scoped>
.my-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1900;

}

::v-deep .el-input__inner {
    border: 0px solid red;
}

::v-deep .inputHidenBorder {
    border: 1px solid #DCDFE6ed !important;

}

::v-deep .el-button {
    // padding: 15px 81px;
    // font-family: 'Microsoft YaHei' !important;

}

.login-box {
    width: 820px;
    height: 430px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    .login-top {
        width: 100%;
        height: 55px;
        text-align: right;
    }

    .login-middle {
        width: 100%;
        height: 240px;
        display: flex;

        .wxCode {
            width: 40%;
            height: 100%;

            #erweima {
                margin-left: 75px;
                width: 80%;
                height: 50%;

            }
        }

        .inputBox {
            width: 60%;
            height: 100%;
            // background-color: purple;

            .inputBox-1 {
                width: 100%;
                height: 20%;
                // background-color: yellow;
                display: flex;

                .selectTitle {
                    padding: 10px;
                    color: #505050;
                    font-size: 18px;
                    cursor: pointer;
                }

            }

            .inputBox-2 {
                width: 100%;
                height: 48%;
                // background-color: green;
            }

            .inputBox-3 {
                // background-color: gray;
                width: 100%;
                height: 20%;
                z-index: 10000 !important;
            }
        }
    }

    .login-middle2 {
        width: 100%;
        height: 320px;

        .redister-box {
            width: 60%;
            height: 100%;
            margin: auto;

            .input-item {
                width: 100%;
                height: 30px;
                display: flex;
                padding: 19px 50px 5px 50px;
            }

            .input-item2 {
                width: 100%;
                height: 49px;
                display: flex;
                padding: 19px 50px 5px 50px;
            }
        }
    }

    .login-bottom {
        width: 100%;
        height: 135px;
        // background-color: pink;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .bottom-text {
            width: 100%;
            text-align: center;
            display: flex;
            margin-left: 72%;
            margin-bottom: 20px;
            // margin-top:
        }
    }
}

.clickable-button {
    z-index: 9999991 !important;
    padding: 15px 81px;
    font-family: 'Microsoft YaHei' !important;
}

.clickable-button2 {
    z-index: 9999991 !important;
    padding: 2px 60px;
    font-family: 'Microsoft YaHei' !important;
}

.my-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-dialog-body {
    /* 样式 */
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
    // transition: opacity 0.3s;
}

.dialog-fade-enter,
.dialog-fade-leave-to

/* .dialog-fade-leave-active in <2.1.8 */
    {
    // opacity: 0;
}

.bgc {
    color: #227BFF !important;
}
</style>