import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import login from "../views/login.vue";
import login2 from "../views/login2.vue";
import navigation from "../views/navigation.vue";
import quotedPrice from "../views/quotedPrice.vue";
import todayQuotedPrice from "../views/todayQuotedPrice.vue";
import todayQuotedPriceCopy from "../views/todayQuotedPriceCopy.vue";
import buyerCenter from "../views/buyerCenter.vue";
import message from "../views/mesCenter.vue";
import home from "../views/home.vue";
import test from "../views/test.vue";
import aboutUs from "../views/aboutUs.vue";
import issueProduct from "../views/issueProduct.vue";
import store from "../views/store.vue";
import consult from "../views/consult.vue";
import consultDetails from "../views/consultDetails.vue";
import strictStore from "../views/strictStore.vue";
import concatMe from "../views/concatMe.vue";
import theMessage from "../views/theMessage.vue";
import myOrder from "../views/myOrder.vue";
import myCollect from "../views/myCollect.vue";
import attention from "../views/attention.vue";
import personal from "../views/personal.vue";
import enterpriseInfo from "../views/enterpriseInfo.vue";
import address from "../views/address.vue";
import amendPersonal from "../views/amendPersonal.vue";
import amendEnterpriseInfo from "../views/amendEnterpriseInfo.vue";
import detailsPage from "../views/detailsPage.vue";
import storeHome from "../views/storeHome.vue";
import order from "../views/order.vue";
import carts from "../views/carts.vue";
import afterSale from "../views/afterSale.vue";
import afterSalePut from "../views/afterSalePut.vue";
import orderInfo from "../views/orderInfo.vue";
import help from "../views/help.vue";
import helpDetails from "../views/helpDetails.vue";
import systemNotice from "../views/systemNotice.vue";
import systemNoticeDetails from "../views/systemNoticeDetails.vue";
import suppliers from "../views/suppliers.vue";
import chat from "../views/chat.vue";
import evaluate from "../views/evaluate.vue";
import buyOrder from "../views/buyOrder.vue";
import play from "../views/play.vue";
import theProduct from "../views/theProduct.vue";
import PriceTrend from "../views/PriceTrend.vue";
import vudio from "../views/vudio";
import creatorPage from "../views/creatorPage";
import facturerPage from "../views/facturerPage";

import application from "../views/application.vue";
import productPrice from "../views/productPrice.vue";
import publishVideo from "../views/publishVideo.vue";
import pushNews from "../views/pushNews.vue";
import historicalQuotation from "../views/historicalQuotation.vue";
import integral from "../views/integral.vue";
import selectStore from "../views/selectStore.vue";
import hotVideo from "../views/hotVideo.vue";
import baiduMap from "../views/baiduMap.vue";
import withdrawal from "../views/withdrawal.vue";
import contribute from "../views/contribute.vue";
import luoSearch from "../views/luoSearch.vue";
import taskCenter from "../views/taskCenter.vue";
import dataPage from "../views/dataPage.vue";
import priviLege from "../views/priviLege.vue";
import qyData from "../views/qyData.vue";
import commentManage from "../views/commentManage.vue";
import wxTry from "../views/wxTry.vue";
import strictStoreAdmin from "../views/strictStoreAdmin.vue";
import corVip from "../views/corVip.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: "搜原网中海南联原材料商城",
    component: index
  },
  {
    path: "/search",
    name: "搜索",
    meta: "搜索",

    component: luoSearch
  },
  {
    path: "/vudio",
    name: "视频",
    meta: "视频",
    component: vudio
  },
  {
    path: "/creatorPage",
    name: "创作者主页",
    meta: "创作者主页",
    component: creatorPage
  },
  {
    path: "/facturerPage",
    name: "厂商主页",
    meta: "厂商主页",
    component: facturerPage
  },

  {
    path: "/hotVideo",
    name: "视频",
    meta: "视频",

    component: hotVideo
  },
  {
    path: "/baiduMap",
    meta: "搜原网",

    component: baiduMap
  },

  {
    path: "/PriceTrend",
    meta: "搜原网",

    component: PriceTrend
  },
  {
    path: "/encyclopedia",
    meta: "搜原网",

    component: () => import("../views/encyclopedia.vue")
  },
  {
    path: "/titleDetails",
    meta: "搜原网",

    component: () => import("../views/titleDetails.vue")
  },

  {
    path: "/storeDetails",
    meta: "搜原网",
    component: () => import("../views/storeDetails.vue")
  },

  {
    path: "/encyclopediaDeilt",
    meta: "搜原网",
    component: () => import("../views/encyclopediaDeilt.vue")
  },
  {
    path: "/message",
    meta: "搜原网",
    component: () => import("../views/message.vue")
  },

  {
    path: "/test",
    meta: "搜原网",
    component: test
  },
  {
    path: "/login",
    name: "登录",
    component: login
  },
  {
    path: "/play",
    meta: "搜原网",
    component: play
  },
  {
    path: "/login2",
    meta: "搜原网",
    redirect: "/login"
  },
  {
    path: "/navigation",
    meta: "搜原网",
    component: navigation
  },
  {
    path: "/quotedPrice",
    name: "行业行情",
    meta: "行业行情",

    component: quotedPrice
  },
  {
    path: "/todayQuotedPrice",
    meta: "搜原网",
    component: todayQuotedPrice
  },
  {
    path: "/todayQuotedPriceCopy",
    meta: "搜原网",
    component: todayQuotedPriceCopy
  },
  {
    path: "/aboutUs",
    meta: "搜原网",
    component: aboutUs
  },
  {
    path: "/chat",
    meta: "搜原网",
    component: chat
  },
  {
    path: "/issueProduct",
    meta: "搜原网",
    component: issueProduct
  },
  {
    path: "/store",
    meta: "原材料报价",

    component: store
  },
  {
    path: "/consult",
    meta: "原材料资讯",
    component: consult
  },
  {
    path: "/consultDetails",
    meta: "资讯详情",
    component: consultDetails
  },
  {
    path: "/strictStore",
    meta: "严选商城",

    component: strictStore
  },
  {
    path: "/concatMe",
    name: "concatMe",
    component: concatMe
  },
  {
    path: "/detailsPage",
    meta: "商品详情",
    component: detailsPage
  },
  {
    path: "/storeHome",
    meta: "搜原网",
    component: storeHome
  },
  {
    path: "/historicalQuotation",
    meta: "搜原网",
    component: historicalQuotation
  },
  {
    path: "/order",
    meta: "搜原网",
    component: order
  },
  {
    path: "/buyOrder",
    meta: "搜原网",
    component: buyOrder
  },
  {
    path: "/forgetpassword",
    meta: "修改密码",
    component: () => import("@/views/forgetpassword.vue")
  },
  {
    path: "/buyerCenter",
    name: "buyerCenter",
    component: buyerCenter,
    redirect: "/message",
    children: [
      {
        path: "home",
        meta: "个人中心",

        component: home
      },
      {
        path: "message",
        meta: "买家中心",
        component: message
      },
      {
        path: "theMessage",
        meta: "消息中心",
        component: theMessage
      },
      {
        path: "theProduct",
        meta: "产品管理",
        component: theProduct
      },
      {
        path: "myOrder",
        meta: "我的订单",
        component: myOrder
      },
      {
        path: "myCollect",
        meta: "我的收藏",
        component: myCollect
      },
      {
        path: "attention",
        meta: "关注的商家",
        component: attention
      },
      {
        path: "personal",
        meta: "个人信息",
        component: personal
      },
      {
        path: "enterpriseInfo",
        meta: "企业信息",
        component: enterpriseInfo
      },
      {
        path: "address",
        meta: "地址管理",

        component: address
      },
      {
        path: "amendPersonal",
        meta: "修改信息",

        component: amendPersonal
      },
      {
        path: "amendEnterpriseInfo",
        meta: "企业认证",
        component: amendEnterpriseInfo
      },
      {
        path: "carts",
        meta: "购物车",

        component: carts
      },
      {
        path: "afterSale",
        component: afterSale
      },
      {
        path: "afterSalePut",
        component: afterSalePut
      },
      {
        path: "orderInfo",
        component: orderInfo
      },
      {
        path: "help",
        meta: "帮助管理",

        component: help
      },
      {
        path: "helpDetails",
        name: "帮助详情",
        component: helpDetails
      },
      {
        path: "systemNotice",
        meta: "系统公告推送",
        component: systemNotice
      },
      {
        path: "systemNoticeDetails",
        meta: "公告推送",
        component: systemNoticeDetails
      },
      {
        path: "suppliers",
        meta: "申请供应商",
        component: suppliers
      },
      {
        path: "application",
        meta: "厂商申请",
        component: application
      },
      {
        path: "productPrice",
        meta: "产品报价",

        component: productPrice
      },
      {
        path: "evaluate",
        component: evaluate
      },
      {
        path: "publishVideo",
        component: publishVideo,
        meta: "视频管理"
      },
      {
        path: "pushNews",
        component: pushNews,
        meta: "资讯管理"
      },
      {
        path: "integral",
        component: integral,
        meta: "收益管理"
      },
      {
        path: "selectStore",
        component: selectStore,
        meta: "严选商城订单"
      },
      {
        path: "withdrawal",
        component: withdrawal,
        meta: "严选商城订单"
      },
      {
        path: "contribute",
        component: contribute,
        meta: "投稿"
      },
      {
        path: "taskCenter",
        component: taskCenter,
        meta: "任务中心"
      },
      {
        path: "dataPage",
        component: dataPage,
        meta: "数据页面"
      },
      {
        path: "priviLege",
        component: priviLege,
        meta: "企业特权"
      },
      {
        path: "qyData",
        component: qyData,
        meta: "企业信息"
      },
      {
        path: "commentManage",
        component: commentManage,
        meta: "评论管理"
      },
      {
        path: "wxTry",
        component: wxTry,
        name: "wxTry"
      },
      {
        path: "strictStoreAdmin",
        component: strictStoreAdmin,
        meta: "严选商城"
      },
      {
        path: "corVip",
        component: corVip,
        meta: "企业会员"
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  console.log(to, "我是to");
  document.title = to.meta;
});
router.beforeEach((to, from, next) => {
  console.log("ces");
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});

export default router;
