<template>
  <div style="background-color: white; height: 172vh; padding-top: 20px">
    <el-form :model="form" ref="ruleFormRef" :rules="rules" :inline="true">
      <el-form-item label="一级分类" label-width="135px" required prop="cat_one">
        <el-select v-model="form.cat_one" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false" @change="select1">
          <el-option v-for="item in form.product_list1" :key="item.gId" :label="item.label" :value="item.gId" />
        </el-select>
      </el-form-item>

      <el-form-item label="二级分类" label-width="135px" required prop="cat_two">
        <el-select v-model="form.cat_two" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false" @change="select2">
          <el-option v-for="item in form.product_list2" :key="item.gId" :label="item.label" :value="item.gId" />
        </el-select>
      </el-form-item>

      <el-form-item label="三级分类" label-width="135px" required prop="cat_three">
        <el-select v-model="form.cat_three" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option v-for="item in form.product_list3" :key="item.gId" :label="item.label" :value="item.gId" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否参与积分" label-width="135px" required prop="exchange_integral">
        <el-select v-model="form.exchange_integral" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否上架" label-width="135px" required prop="is_on_sale">
        <el-select v-model="form.is_on_sale" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否包邮" label-width="135px" required prop="is_free_shipping">
        <el-select v-model="form.is_free_shipping" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否推荐" label-width="135px" required prop="is_recommend">
        <el-select v-model="form.is_recommend" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否新品" label-width="135px" required prop="is_new">
        <el-select v-model="form.is_new" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否热卖" label-width="135px" required prop="is_hot">
        <el-select v-model="form.is_hot" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="2" label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品规格" label-width="135px" required prop="spec_id">
        <el-select v-model="form.spec_id" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="1" label="千克" :value="1" />
          <el-option :key="6" label="吨" :value="6" />
          <el-option :key="8" label="罐" :value="8" />
        </el-select>
      </el-form-item>
      <el-form-item label="物流方式" label-width="135px" required prop="logistics_type_id">
        <el-select v-model="form.logistics_type_id" placeholder="Select" size="large" style="width: 220px" :popper-append-to-body="false">
          <el-option :key="2" label="不限" :value="2" />
          <el-option :key="3" label="罐车发货" :value="3" />
          <el-option :key="5" label="快递发货" :value="5" />
          <el-option :key="6" label="包邮" :value="6" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品编码" label-width="135px" prop="goods_sn">
        <el-input v-model="form.goods_sn" autocomplete="off" style="width: 220px" required />
      </el-form-item>
      <el-form-item label="商品名称" label-width="135px" prop="goods_name">
        <el-input v-model="form.goods_name" autocomplete="off" style="width: 220px" required />
      </el-form-item>
      <el-form-item label="商品关键字" label-width="135px" prop="keywords">
        <el-input v-model="form.keywords" autocomplete="off" style="width: 220px" required />
      </el-form-item>
      <el-form-item label="商品发货地" label-width="135px" prop="location_name">
        <el-input v-model="form.location_name" autocomplete="off" style="width: 220px" required />
      </el-form-item>
      <el-form-item label="商品库存量" label-width="135px" prop="store_count">
        <el-input v-model="form.store_count" autocomplete="off" maxlength="11" required type="number" @input="handleInput" style="width: 220px" />
      </el-form-item>
      <el-form-item label="购买可获得积分" label-width="135px" prop="give_integral">
        <el-input v-model="form.give_integral" autocomplete="off" maxlength="11" required type="number" @input="handleInput" style="width: 220px" />
      </el-form-item>
      <el-form-item label="兑换商品所需积分" label-width="135px" prop="need_integral">
        <el-input v-model="form.need_integral" autocomplete="off" maxlength="11" required type="number" @input="handleInput" style="width: 220px" />
      </el-form-item>
      <el-form-item label="商品价格" label-width="135px" prop="shop_price">
        <el-input v-model="form.shop_price" autocomplete="off" maxlength="11" required type="number" @input="handleInput" style="width: 220px" />
      </el-form-item>
      <el-form-item label="商品重量" label-width="135px" prop="weight">
        <el-input v-model="form.weight" autocomplete="off" maxlength="11" required type="number" @input="handleInput" style="width: 220px" />
      </el-form-item>
      <el-form-item label="上传商品封面" label-width="141px">
        <el-upload class="upload-demo" action="https://www.zhnl.com/zhnl/" :on-success="handlePreview" :headers="headerObj" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <div>
        <el-form-item label="商品描述" label-width="130px" prop="goods_content" required>
          <vueQuill serviceUrl="https://www.zhnl.com/zhnl/uploadConFiles" @getEditorData="getEditorData" @beforeAvatarUpload="beforeAvatarUpload" class="ql-editor" style="height: 630px" />
        </el-form-item>
      </div>
    </el-form>
    <el-button @click="pushShop('ruleFormRef')" type="primary" style="margin-left: 48%">发布严选商品</el-button>
  </div>
</template>

<script>
import { getBstGoodsGategory1, addStrictSelGoods } from "../api/user";
import vueQuill from "@/components/vueQuill.vue";

export default {
  data() {
    return {
      headerObj: {
        token: window.localStorage.getItem("token")
      },
      rules: {
        weight: [
          {
            required: true,
            message: "请填写商品重量",
            trigger: "change"
          }
        ],
        goods_content: [
          {
            required: true,
            message: "请填写商品描述",
            trigger: "change"
          }
        ],
        location_name: [
          {
            required: true,
            message: "请填写商品发货地",
            trigger: "change"
          }
        ],
        need_integral: [
          {
            required: true,
            message: "请填写兑换商品所需积分",
            trigger: "change"
          }
        ],
        give_integral: [
          {
            required: true,
            message: "请填写商品购买可获得积分数",
            trigger: "change"
          }
        ],
        keywords: [
          {
            required: true,
            message: "请填写商品关键字",
            trigger: "change"
          }
        ],
        store_count: [
          {
            required: true,
            message: "请填写商品商品库存量",
            trigger: "change"
          }
        ],
        shop_price: [
          {
            required: true,
            message: "请填写商品价格",
            trigger: "change"
          }
        ],

        goods_name: [
          {
            required: true,
            message: "请填写商品名称",
            trigger: "change"
          }
        ],
        goods_sn: [
          {
            required: true,
            message: "请填写商品编码",
            trigger: "change"
          }
        ],

        logistics_type_id: [
          {
            required: true,
            message: "请选择物流方式",
            trigger: "change"
          }
        ],
        spec_id: [
          {
            required: true,
            message: "请选择规格",
            trigger: "change"
          }
        ],

        is_hot: [
          {
            required: true,
            message: "请选择是否热卖",
            trigger: "change"
          }
        ],
        is_new: [
          {
            required: true,
            message: "请选择是否新品",
            trigger: "change"
          }
        ],
        is_recommend: [
          {
            required: true,
            message: "请选择是否推荐",
            trigger: "change"
          }
        ],
        is_free_shipping: [
          {
            required: true,
            message: "请选择是否包邮",
            trigger: "change"
          }
        ],
        exchange_integral: [
          {
            required: true,
            message: "请选择是否参与积分",
            trigger: "change"
          }
        ],
        is_on_sale: [
          {
            required: true,
            message: "请选择是否上架",
            trigger: "change"
          }
        ],

        cat_one: [
          {
            required: true,
            message: "请选择产品一级分类",
            trigger: "change"
          }
        ],
        cat_two: [
          {
            required: true,
            message: "请选择产品二级分类",
            trigger: "change"
          }
        ],
        cat_three: [
          {
            required: true,
            message: "请选择产品三级分类",
            trigger: "change"
          }
        ]
      },
      fileList: [],
      form: {
        product_list1: [],
        product_list2: [],
        product_list3: [],
        product_list4: [],
        product_list5: [],
        cat_one: "",
        cat_two: "",
        cat_three: "",
        goods_name: "",
        goods_sn: "",
        store_count: "",
        keywords: "",
        spec_id: "",
        exchange_integral: "",
        is_on_sale: "",
        is_free_shipping: "",
        is_recommend: "",
        is_new: "",
        is_hot: "",
        weight: "",
        give_integral: "",
        need_integral: "",
        logistics_type_id: "",
        location_name: "",
        goods_content: "",
        shop_price: "",
        market_price: ""
      }
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList, "第一次");
      // this.fileList = fileList
    },
    handlePreview(file, data, fileList) {
      console.log(file, fileList, "第二");
      this.fileList.push(file.imgUrl);
      console.log(this.fileList, "this.fileList");
    },
    beforeUpload() {},
    handlePictureCardPreview(file) {
      console.log(file, "file---");
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    handleSuccess(file) {
      console.log(file, "file----s");
      // this.fileList.push(file.imgUrl[0])
    },
    handleInput(value) {
      // 转换为字符串以进行长度检查
    },
    getEditorData(data) {
      console.log(data, "data========");
      this.form.goods_content = data;
    },
    beforeAvatarUpload(file) {
      console.log(file, "file---------");
    },
    pushShop(ruleFormRef) {
      console.log(this.form, "form.--------");
      console.log(this.fileList, "fileList.--------");
      this.$refs[ruleFormRef].validate((valid) => {
        if (valid) {
          let params = {
            cat_id: this.form.cat_one,
            cat_id_2: this.form.cat_two,
            cat_id_3: this.form.cat_three,
            goods_name: this.form.goods_name,
            goods_sn: this.form.goods_sn,
            store_count: this.form.store_count,
            keywords: this.form.keywords,
            exchange_integral: this.form.exchange_integral,
            is_on_sale: this.form.is_on_sale,
            is_free_shipping: this.form.is_free_shipping,
            is_recommend: this.form.is_recommend,
            is_new: this.form.is_new,
            is_hot: this.form.is_hot,
            weight: this.form.weight,
            give_integral: this.form.give_integral,
            need_integral: this.form.need_integral,
            logistics_type_id: this.form.logistics_type_id,
            location_name: this.form.location_name,
            goods_content: this.form.goods_content,
            img_list: this.fileList,
            shop_price: this.form.shop_price,
            spec_id: this.form.spec_id,
            market_price: 0
          };
          addStrictSelGoods(params).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "发布成功"
              });

              this.$router.push({
                path: "/buyerCenter/home"
              });
            }
          });
        } else {
        }
      });
    },
    select1(e) {
      this.form.cat_three = "";
      this.form.cat_two = "";

      let newArray = this.form.product_list1.filter((item, index) => {
        return item.gId == e;
      });

      this.form.product_list2 = newArray[0].children;
    },
    select2(e) {
      this.form.cat_three = "";
      let newArray = this.form.product_list2.filter((item, index) => {
        return item.gId == e;
      });
      this.form.product_list3 = newArray[0].children;
    }
  },
  components: {
    vueQuill
  },
  created() {},
  mounted() {
    getBstGoodsGategory1().then((res) => {
      this.form.product_list1 = res.data.data;
    });
  }
};
</script>

<style lang="scss" scoped></style>
