<template>
  <div class="content">
    <searchBar />
    <!-- <div class="line"></div> -->
    <div class="classifyBox">
      <classifyBar :classify="6" />

    </div>

    <div class="location">
      <span>当前位置：</span>
      <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/navigation' }">关于我们</el-breadcrumb-item>
        </el-breadcrumb></div>
    </div>

    <div class="contain2 flexColCenter">
      <div class="titleBox flexColCenter">
        <div class="title">中海南联</div>
        <div class="title2">做有温度的原材料商城</div>
      </div>
      <div class="theLine"></div>

      <div class="imgBox2">
        <div class="details">
          <p>中海南联作为一家互联网销售平台企业，融入革新的时代概念，建立集石化产品生产加工、
          工业原材料
          的销售、仓储、运输、金融服务于一体的原材料商城，全面细致地服务广大客户。</p>
          <p>
            我司根据市场分布建设茂名、东莞、惠州、上海、福建、广州、江门七大销售服务中心以
          及仓储中心，
          专业储备油品、化工产品、橡塑等各类石化产品，现桶装物料总仓储能力达1万
          立方，产品年销售量逾10万
          吨以上，产品涵盖白油、基础油、溶剂油、润滑油、化工产品、石
          蜡、凡士林、橡胶塑料、增塑剂、钛白粉、
          填充剂、硅油等十多大类500种产品；为了把货品
          快捷地送到客户手上，我们自建专业的运输车队，完善每
          个服务细节；同时组建了百人以上的
          客户服务精英团队，以专业的素养为客户提供更完善的产品销售服务。　　2018年，历经无数个紧张的筹备时刻，历经一次次反复的试验调整，历经重重难题的突破
          落地，中海南联
          </p>
        </div>

        <img src="../assets/aboutUs/Rectangle2.png" style="position: absolute; bottom: -500px; left: -7%;">
      </div>

      <div class="theLine" style="margin-top: 286px;"></div>

      <img src="../assets/aboutUs/a.png" alt="" style="margin-top: 32px;">

      <div class="theLine" style="margin-top: 30px;"></div>

      <img src="../assets/aboutUs/b.png" alt="" style="margin-top: 32px;">

      <div class="theLine" style="margin-top: 50px;"></div>


      <img src="../assets/aboutUs/c.png" alt="" style="margin-top: 32px; margin-bottom: 50px;">
    </div>
  </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
export default {
  data() {
    return {

    }
  },
  methods: {
    //关于我们
    async getAboutUs() {
            let res = await this.$http('/mini/ApiConnect/getAboutUs')

        },
  },

  mounted() {
    this.getAboutUs()
  },

  components: {
    searchBar,
    classifyBar,

  }
}
</script>

<style lang="scss" scoped>

.content {
  width: 1200px;
  margin: 0 auto;
}

.line {
  width: 1920px;
  height: 2px;
  background: #006eb7;
  position: absolute;
  top: 157px;
  left: 0;
}

.classifyBox {
  margin-top: 29px;
}

.location {
  margin-top: 20px;
  display: flex;
  align-items: center;

  .nav {}
}


.contain2 {
  margin-top: 15px;

  .titleBox {
    .title {
      font-size: 35px;
      letter-spacing: 5px;
    }

    .title2 {
      font-size: 25px;
      letter-spacing: 5px;
      margin-top: 10px;
    }
  }

  .theLine {
    width: 711px;
    height: 7px;
    background: #d0d5dd;
    margin-top: 15px;
  }

  .imgBox2 {
    width: 1000px;
    min-height: 557px;
    background: url("../assets/aboutUs/Rectangle.png") no-repeat;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #F2F2F2;
    .details {
    margin-top: 20px;

      width: 95%;
      font-weight: 400;
      font-size: 20px;
      line-height: 31.64px;
      color: #fff;

    }
  }
}
</style>