<template>
  <div style="background-color: white; margin-top: 12px; margin-left: 12px; padding-bottom: 12px">
    <el-dialog title="" :visible.sync="dialogVisible2" width="990px" style="" top="40px">
      <div style="width: 100%; text-align: center; font-weight: 400; font-size: 14px; color: #613c14; padding: 10px 100px; box-sizing: border-box; border-bottom: 1px solid #d8d8d8">
        用户协议及隐私条款
      </div>
      <div style="padding: 5px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; margin-top: 20px">
        欢迎您阅读《搜原网企业认证服务协议》并成为搜原网企业认证！ 
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        《搜原网企业认证服务协议》（简称为“本协议”）是您与搜原网网站（以下统称为“搜原网”）之间关于“搜原网企业认证”以及“搜原网企业认证”中搜原网提供的各项服务（以下统称为“企业认证”）的法律协议。
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        本协议中与您的权益（可能）存在重大关系的条款（包括相关免除搜原网责任的条款、限制您权利的条款、约定的争议解决方式及司法管辖条款等），搜原网已采用字体加粗的方式来特别提醒您，请您留意重点查阅。您应当在确认充分阅读字体加粗条款，明确理解的前提下使用企业认证服务。 
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        根据国家法律法规、监管政策及行业规范变化、互联网高速发展态势及服务运营需要，搜原网有权修改、替换、升级搜原网软件/服务，并提前向用户进行公示（包括但不限于弹出公告、网站公告、系统消息、邮件等方式进行公示），同时据实修改搜原网服务相关协议条款/规则以覆盖您与搜原网的所有权利与义务，前述修改的协议条款/规则自公示期满之日起生效。如果用户不同意或者不接受搜原网相关软件/服务的修改、替代、升级，有权拒绝、停止、取消使用搜原网服务。如用户继续使用搜原网服务，那么视为悉知变动内容并同意接受。
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        如果您未满18周岁，请在法定监护人的陪同下阅读本协议。 
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        本协议是基于《搜原网用户使用协议》以及搜原网相关协议规范（统称为“搜原网服务条款”）制定，与其是不可分割的；同时，搜原网不时发布的关于企业认证的相关协议、活动规则等也是本协议的一部分，您在使用企业认证服务的同时应遵守本协议、搜原网服务条款及前述相关协议、活动规则等所有条款。 
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        特别提示：您通过搜原网指定渠道开通企业认证服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向搜原网提出任何主张或追究搜原网任何责任。同时，《搜原网企业认证服务协议》可能由于您使用的软件版本存在差别，您理解并同意本协议最终版本以搜原网网站网页端提供版本为最终版本。
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        （一） 企业认证服务是搜原网在搜原网网站及其软件客户端中设立的网络增值服务。 
      </div>
      <div style="padding: 4px 0px; font-weight: 290; font-size: 14px; color: #613c14; box-sizing: border-box; text-align: left; line-height: 28px">
        （二）
        企业认证服务秉承以下宗旨：为成为企业认证的您提供各种更完善的线上线下增值服务，促进您与其他企业认证、企业认证与搜原网之间的相互交流。 搜原网郑重声明： 搜原网企业认证服务，不影响搜原网网站现有的一切免费服务并保证服务质量。搜原网企业认证服务，与搜原网现存正式会员服务体系并存。 
      </div>
      <!-- <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                （三）成为企业认证的方法  1.您可通过搜原网指定的包括但不限于银行卡、支付宝、微信支付等现有方式或未来搜原网指定方式，在依约支付一定费用后开通企业认证；或通过搜原网官方活动/他人赠与获 </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="freeVip2">确 定</el-button>
      </span>
    </el-dialog>
    <VipDialog3 ref="VipDialog3" />
    <VipDialog5 ref="VipDialog5" />
    <VipDialog7 ref="VipDialog7" />
    <VipDialog8 ref="VipDialog8" />
    <allTitle ref="allTitle" />

    <VipDialog2Copy ref="VipDialog2Copy" />
    <VipDialogAll ref="VipDialogAll" imgUrl="https://video.zhnl.com/2024/08/28/4863706f4aae47e0930326ee63c3280d.jpg" routeUrl="/buyerCenter/priviLege" />
    <el-dialog title="设置首页报价展示" :visible.sync="dialogTableVisible">
      <el-table
        :data="gridData"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center', border: '0px solid #227BFF' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column property="goodsName" label="产品名称" width="150"></el-table-column>
        <el-table-column property="bqSpec" label="规格单位" width="200"></el-table-column>
        <el-table-column property="bqPrice" label="价格"></el-table-column>
        <el-table-column label="涨跌" width="130">
          <template #default="scope">
            <div style="">
              <span v-if="scope.row.upPrice > 0" style="color: red">{{ scope.row.upPrice }}↑</span>
              <span v-if="scope.row.upPrice == 0">{{ scope.row.upPrice }}</span>
              <span v-else-if="scope.row.upPrice < 0" style="color: green">{{ scope.row.upPrice }}↓</span>
              <!-- <span v-else-if="scope.row.upPrice == 0">{{ scope.row.upPrice }}</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="涨跌百分比" width="130">
          <template #default="scope">
            <div style="">
              <span v-if="scope.row.upPrice > 0" style="color: red">{{ scope.row.upPercentage }}↑</span>
              <span v-if="scope.row.upPrice == 0">{{ scope.row.upPercentage }}</span>
              <span v-if="scope.row.upPrice < 0" style="color: green">{{ scope.row.upPercentage }}↓</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="sentPrice">确 定</el-button>
      </div>
    </el-dialog>
    <el-button type="primary" size="small" style="margin-left: 6px; margin-bottom: 16px; margin-top: 18px" @click="apply('ruleFormRef')">添加报价厂商</el-button>
    <el-table
      :data="enterpriseList"
      style="width: 100%"
      v-loading="tableLoading"
      :row-class-name="tableRowClassName"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center', border: '0px solid #227BFF' }"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="busName" label="代理厂商名称" />
      <el-table-column label="厂商简称" width="205">
        <template #default="scope">
          <div>
            <span style="vertical-align: middle">{{ scope.row.busNameAbbrev }}</span>
            <img src="../assets/newImg3/超级会员静态.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" v-if="scope.row.isSupperVip == 1" />
            <span v-else-if="scope.row.isFree == 0"></span>
            <img v-else-if="scope.row.isFree == 1" src="../assets/newImg3/黄金会员@1x.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" />
          </div>
        </template>
        <template slot="header" slot-scope="scope">
          <el-select v-model="form.busName2" placeholder="厂商筛选" size="mini" style="padding-bottom: 0px" @change="qioterListChange">
            <el-option v-for="item in qioterList2" :key="item.gId" :label="item.busNameAbbrev" :value="item.busId" />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column prop="busLegalPerson" label="法人名称" />
      <el-table-column prop="busMobile" label="厂商联系方式" />
      <!-- <el-table-column prop="proxyMobile" label="申请人联系方式" /> -->

      <el-table-column label="营业执照" width="85">
        <template #default="scope">
          <div style="width: 50px; height: 50px">
            <img :src="scope.row.busLicense" alt="" @click="openImg(scope.row.busLicense)" style="width: 50px; height: 50px; cursor: pointer" />
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="LOGO" width="155">
                <template #default="scope">
                    <div>
                        <img :src="scope.row.busLogo" alt="" style="width: 140px;height: 130px;">
                    </div>
                </template>
            </el-table-column> -->

      <el-table-column label="审核结果">
        <template #default="scope">
          <div>
            <span v-if="scope.row.busStatus == '0'">未审核</span>
            <span v-else-if="scope.row.busStatus == '1'" style="color: green">审核通过</span>
            <span v-else-if="scope.row.busStatus == '2'" style="color: red">未通过</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="busReason" label="未通过原因" />

      <!-- <el-table-column prop="superVipArrTime" label="会员到期时间" /> -->
      <el-table-column label="会员到期时间" width="130" prop="addTime">
        <template #default="scope">
          <div>
            <div>{{ scope.row.superVipArrTime ? scope.row.superVipArrTime : scope.row.busVipArrTime }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="130" prop="addTime">
        <template #default="scope">
          <div>
            <div>{{ formatDate(scope.row.addTime.slice(0, 10)) }}</div>
            <div>{{ scope.row.addTime.slice(10, 19) }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="首页报价展示">
        <template #default="scope">
          <div>
            <img src="../assets/newImg3/容器.png" alt="" style="width: 20px; height: 20px; margin-left: 30px" v-if="scope.row.indexStatus != 0" />
            <img src="../assets/newImg3/容器-2.png" alt="" v-else style="width: 20px; height: 20px; margin-left: 30px" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template #default="scope">
          <div>
            <el-button
              type="primary"
              size="small"
              @click="freeVip(scope.row)"
              style="margin-left: 10px"
              v-show="scope.row.isFreeUseBusVip == 0 && scope.row.busStatus == '1' && scope.row.isSupperVip != 1"
            >
              试用会员
            </el-button>

            <el-button type="primary" size="small" @click="indexPriceShow(scope.row)" v-show="scope.row.isSupperVip == 1 || scope.row.isBusVip == 1"> 首页报价展示 </el-button>
            <el-button
              type="primary"
              size="small"
              @click="indexPriceShow2(scope.row)"
              style="margin-left: 10px"
              v-if="scope.row.isBusVip == 0 && scope.row.busStatus == '1' && scope.row.isSupperVip == 0"
            >
              开通会员
            </el-button>
            <el-button type="primary" size="small" @click="indexPriceShow2(scope.row)" style="margin-left: 10px" v-else-if="scope.row.isSupperVip == 1 && scope.row.busStatus == '1'">
              续费会员
            </el-button>
            <el-button type="primary" size="small" @click="indexPriceShow2(scope.row)" style="margin-left: 10px" v-else-if="scope.row.isBusVip == 1 && scope.row.busStatus == '1'">
              升级会员
            </el-button>
            <el-button type="primary" size="small" @click="edit(scope.row)" v-if="scope.row.busStatus != '1'"> 修改 </el-button>
            <el-button type="danger" size="small" @click="del(scope.row)" v-if="scope.row.busStatus != '1'"> 删除 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%">
      <el-pagination
        v-model:current-page="form.page"
        v-model:page-size="form.size"
        style="margin-left: 30%; margin-top: 30px"
        :page-sizes="[5, 10]"
        :page-size="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :visible.sync="dialogFormVisible" title="添加报价厂商" width="34%" @close="beforeC">
      <el-form :model="form" ref="ruleFormRef" :rules="rules" label-width="130px">
        <el-form-item label="上传营业执照">
          <el-upload
            class="upload-demo"
            action="https://www.zhnl.com/zhnl/uploadLicense"
            :limit="1"
            :file-list="fileList1"
            :headers="headerObj"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <el-button type="primary">点击上传</el-button>
            <div class="el-upload__tip" slot="tip">只能上传png和jpg格式文件,营业执照可自动识别</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="厂商名称" prop="busName">
          <el-input v-model="form.busName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="厂商简称" prop="bus_name_abbrev">
          <el-input v-model="form.bus_name_abbrev" autocomplete="off" />
        </el-form-item>

        <el-form-item label="法人名称" prop="busLegalPerson">
          <el-input v-model="form.busLegalPerson" autocomplete="off" maxlength="11" type="text" />
        </el-form-item>
        <el-form-item label="厂商联系方式" prop="busMobile">
          <el-input v-model="form.busMobile" autocomplete="off" maxlength="11" type="number" show-word-limit @input="handleInput" />
        </el-form-item>

        <!-- <el-form-item label="申请人联系方式" prop="proxyMobile">
                    <el-input v-model="form.proxyMobile" autocomplete="off" @input="handleInput2" maxlength="11"
                        type="number" show-word-limit />
                </el-form-item> -->

        <!-- <el-form-item label="上传LOGO">
                    <el-upload class="upload-demo" action="https://www.zhnl.com/zhnl/uploadBusLogo" :limit="1"
                        :file-list="fileList2" :headers="headerObj" :on-success="handleSuccess2"
                        :before-upload="beforeUpload">
                        <el-button type="primary">点击上传</el-button>
                        <div class="el-upload__tip" slot="tip">只能上传png和jpg格式文件</div>
                    </el-upload>
                </el-form-item> -->
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancel('ruleFormRef')">取消</el-button>
          <el-button type="primary" @click="Confirm('ruleFormRef')" :loading="loading"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog title="是否删除厂商" :visible.sync="dialogVisible" v-model="dialogVisible" width="34%" center>
      <span slot="footer" class="dialog-footer" center>
        <div style="text-align: center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmDel">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getBusManage, addProxyUser, updateBusManageById, addBusFreeVip, delBusById, BusOcr, getUserById, getAllRegion, getQuotationByBusId, addQuotToIndex } from "@/api/user.js";
import VipDialog3 from "../components/VipDialog3.vue";
import VipDialog5 from "../components/VipDialog5.vue";
import VipDialog7 from "../components/VipDialog7.vue";
import VipDialog8 from "../components/VipDialog8.vue";
import allTitle from "../components/allTitle.vue";
import VipDialogAll from "../components/VipDialogAll.vue";
import VipDialog2Copy from "../components/VipDialog2Copy.vue";

export default {
  data() {
    return {
      dialogVisible2: false,
      bqId: "",
      bqIndex: "",
      tableLoading: false,
      bus_id: "",
      bq_id: "",
      multipleSelection: [], // 用于存储选中项的数组
      gridData: [],
      page: 1,
      size: 10,
      dialogTableVisible: false,
      ifEdit: "",
      loading: false,
      busId2: "",
      dialogVisible: false,
      qioterList2: [],

      form: {
        busId: "",
        page: 1,
        size: 5,
        total: 0,
        busLegalPerson: "",
        busMobile: "",
        busName: "",
        bus_name_abbrev: "",
        proxyMobile: "",
        img_url: "",
        img_url2: "",
        formLabelWidth: 120
      },
      headerObj: {
        busId: "",
        token: window.localStorage.getItem("token")
      },
      fileList1: [],
      fileList2: [],
      dialogFormVisible: false,

      enterpriseList: [],
      rules: {
        busLegalPerson: [
          {
            required: true,
            message: "请填写标法人名称",
            trigger: "change"
          }
        ],
        busMobile: [
          {
            required: true,
            message: "请填写法人联系电话",
            trigger: "change"
          }
        ],
        busName: [
          {
            required: true,
            message: "请填写厂商名称",
            trigger: "change"
          }
        ],
        bus_name_abbrev: [
          {
            required: true,
            message: "请填写厂商简称",
            trigger: "change"
          }
        ],

        proxyMobile: [
          {
            required: true,
            message: "请填写申请人联系方式",
            trigger: "change"
          }
        ]
      },
      selectedRow: null, // 存储当前选中的行
      showAlert: false // 控制提示的显示
    };
  },

  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, "rw----");
      if (row.isSupperVip == 1) {
        return "warning-row";
      } else if (row.isFree == 1) {
        return "warning-row2";
      }
    },
    freeVip(row) {
      this.dialogVisible2 = true;
      console.log(row);
      this.bus_id = row.busId;
    },
    qioterListChange(e) {
      this.form.bus_id = e;
      this.tableLoading = true;

      getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
        if (res.data.code == 200) {
          this.form.total = res.data.totalPage;
          this.enterpriseList = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    freeVip2() {
      addBusFreeVip({ bus_id: this.bus_id }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = true;

          getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
            if (res.data.code == 200) {
              this.form.total = res.data.totalPage;
              this.enterpriseList = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              this.tableLoading = false;
            }
          });
          this.$refs.allTitle.msg = "操作成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;

          this.dialogVisible2 = false;
        }
      });
    },
    formatDate(dateStr) {
      // 使用'-'分割字符串，得到年、月、日的数组
      const parts = dateStr.split("-");
      // 将'-'替换为'/'，并重新组合成字符串
      return parts[0] + "/" + parts[1] + "/" + parts[2];
    },
    sentPrice() {
      let params = {
        bus_id: this.bus_id,
        bq_id: this.bq_id
      };
      addQuotToIndex(params).then((res) => {
        if (res.data.code == 200) {
          this.$refs.allTitle.msg = "操作成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = true;
          getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
            if (res.data.code == 200) {
              this.form.total = res.data.totalPage;
              this.enterpriseList = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              this.tableLoading = false;
            }
          });
          this.dialogTableVisible = false;
          this.$refs.multipleTable.clearSelection();
        } else {
          this.tableLoading = false;

          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        }
      });
      console.log(this.selectedRow, "this.selectedRow3333");
    },
    handleSelectionChange(val) {
      console.log(val, "val-");
      if (val.length > 1) {
        // 如果用户尝试选择多个，则清空选择并显示提示
        this.$refs.multipleTable.clearSelection();
        console.log(this.selectedRow, "this.selectedRow2222");
        this.bq_id = "";
        // if (this.selectedRow) {
        //     // 如果已经有一个选中的行，则重新选中它
        //     console.log(this.selectedRow, 'this.selectedRow');
        //     this.$refs.multipleTable.toggleRowSelection(this.selectedRow, true);
        // }
        this.$refs.allTitle.msg = "只能选择一条报价";
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
      } else if (val.length === 1) {
        // 更新选中的行
        this.selectedRow = val[0];
        this.bq_id = val[0].bqId;
        console.log(this.selectedRow, "this.selectedRow");
      } else {
        // 如果没有行被选中
        this.selectedRow = null;
      }
    },
    indexPriceShow(row) {
      if (row.isSupperVip == 0) {
        this.$refs.allTitle.msg = "设置首页报价须开通超级黄金会员";
        this.$refs.allTitle.routerPath = null;
        return (this.$refs.allTitle.dialogVisible = true);
      }

      this.bqId = row.bqId;
      this.bus_id = row.busId;
      this.dialogTableVisible = true;
      let params = {
        page: this.page,
        size: this.size,
        bus_id: row.busId
      };
      console.log(this.bqId, "this.bqId");

      getQuotationByBusId(params).then((res) => {
        this.gridData = res.data.data;
        console.log(this.gridData, "this.gridData");
        if (this.gridData.length > 0) {
          this.gridData.forEach((item, index) => {
            if (item.bqId == this.bqId) {
              this.bq_id = item.bqId;
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(this.gridData[index], true);
              });
            } else {
            }
          });

          if (this.bqIndex != -1) {
            console.log("走进来了");
          }
        } else {
          return;
        }
      });
    },
    indexPriceShow2(row) {
      this.$refs.VipDialog2Copy.visible = true;
      this.$refs.VipDialog2Copy.busId = row.busId;
      this.$refs.VipDialog2Copy.getCode();
      if (row.isBusVip == 0) {
        this.$refs.VipDialog2Copy.useIfXu = 0;
      } else {
        this.$refs.VipDialog2Copy.useIfXu = 1;
      }
      if (row.isSupperVip == 0) {
        this.$refs.VipDialog2Copy.busIfXu = 0;
      } else {
        this.$refs.VipDialog2Copy.busIfXu = 1;
      }
    },
    openImg(url) {
      window.open(url);
    },
    handleInput(value) {
      // 转换为字符串以进行长度检查
      const strValue = String(value);
      // 如果长度超过限制，则截断
      if (strValue.length > 11) {
        this.form.busMobile = parseFloat(strValue.slice(0, 11));
      }
    },
    handleInput2(value) {
      // 转换为字符串以进行长度检查
      const strValue = String(value);
      // 如果长度超过限制，则截断
      if (strValue.length > 11) {
        this.form.proxyMobile = parseFloat(strValue.slice(0, 11));
      }
    },
    beforeUpload(file) {
      console.log(file, "file");
      if (file.type.includes("jpeg") || file.type.includes("png")) {
        // return ture
      } else {
        this.$refs.allTitle.msg = "只能上传png、jpg文件";
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
        // this.$message({
        //     type: 'warning',
        //     message: '只能上传png、jpg文件'
        // })
        return false;
      }
    },
    beforeC() {
      this.form.busLegalPerson = "";
      this.form.img_url = "";
      this.form.img_url2 = "";
      this.form.busMobile = "";
      this.form.busName = "";
      this.form.bus_name_abbrev = "";

      this.form.proxyMobile = "";
      this.form.busId = "";
      this.fileList1 = [];
      this.fileList2 = [];
    },
    del(row) {
      this.dialogVisible = true;
      this.busId2 = row.busId;
    },
    confirmDel() {
      delBusById({ bus_id: this.busId2 }).then((res) => {
        if (res.data.code == 200) {
          this.$refs.allTitle.msg = "删除成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;

          this.dialogVisible = false;
          this.tableLoading = true;
          getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
            if (res.data.code == 200) {
              this.form.total = res.data.totalPage;
              this.enterpriseList = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;

              this.tableLoading = false;
            }
          });
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        }
      });
    },
    edit(row) {
      console.log(this.fileList, "111111.");
      let newObj = {
        name: row.busLicense,
        url: row.busLicense
      };
      this.fileList1.push(newObj);
      let newObj2 = {
        name: row.busLogo,
        url: row.busLogo
      };
      this.fileList2.push(newObj2);
      this.ifEdit = 1;
      this.dialogFormVisible = true;
      console.log(row, "..");
      this.form.busLegalPerson = row.busLegalPerson;
      this.form.img_url = row.busLicense;
      this.form.img_url2 = row.busLogo;
      this.form.busMobile = row.busMobile;
      this.form.busName = row.busName;
      this.form.bus_name_abbrev = row?.bus_name_abbrev;

      // this.form.proxyMobile = row.proxyMobile
      this.form.busId = row.busId;
      // this.$refs.ruleFormRef.clearValidate();
    },
    handleSuccess(file) {
      BusOcr({ url: file.imgUrl }).then((res) => {
        console.log(res, "....");
        if (res.data.data) {
          this.form.busName = res.data.data.companyName;
          this.form.img_url = file.imgUrl;
          this.form.busLegalPerson = res.data.data.legalPerson;
        } else {
          this.fileList1 = [];
          this.$refs.allTitle.msg = "请上传正确的营业执照";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          // this.$message({
          //     type: 'warning',
          //     message: '请上传正确的营业执照'
          // })
        }
      });
      console.log(file, "ddddd");
      console.log(file);
      // if (file.code == 200) {
      //     this.form.img_url = file.imgUrl
      // } else {

      //     this.$message({
      //         type: 'warning',
      //         message: '上传失败'
      //     })
      // }
    },

    handleSuccess2(file) {
      console.log(file);

      if (file.code == 200) {
        this.form.img_url2 = file.imgUrl;
      } else {
        this.$refs.allTitle.msg = "登录过期";
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
        // this.$message({
        //     type: 'warning',
        //     message: '登录过期'
        // })
        setTimeout(() => {
          window.location = "/login";
        }, 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
      }
    },
    handleSizeChange(e) {
      this.form.size = e;
      console.log(e, "----123");

      this.tableLoading = true;
      getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
        if (res.data.code == 200) {
          this.form.total = res.data.totalPage;
          this.enterpriseList = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;

          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(val) {
      console.log(val, "----");
      this.form.page = val;
      this.tableLoading = true;
      getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
        if (res.data.code == 200) {
          this.form.total = res.data.totalPage;
          this.enterpriseList = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    apply() {
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
      // if (this.info.distribut_type == 3) {
      //     return this.$refs.VipDialog3.visible = true
      // } else if (this.info.distribut_type == 0) {
      //     return this.$refs.VipDialog7.visible = true
      // } else if (this.info.distribut_type == 2) {
      //     return this.$refs.VipDialog8.visible = true
      // }
      // else if (this.info.is_bus_vip == 0) {
      //     return this.$refs.VipDialog5.visible = true
      // }
      console.log("123");
      this.dialogFormVisible = true;
      this.ifEdit = 0;
      this.$refs.ruleFormRef.clearValidate();
    },
    cancel() {
      this.dialogFormVisible = false;
    },
    Confirm(formName) {
      if (!this.form.img_url) {
        this.$refs.allTitle.msg = "请上传营业执照";
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            bus_name: this.form.busName,
            bus_name_abbrev: this.form.bus_name_abbrev,
            bus_legal_person: this.form.busLegalPerson,
            // proxyer_mobile: this.form.proxyMobile,
            bus_mobile: this.form.busMobile,
            bus_license: this.form.img_url,
            bus_logo: this.form.img_url2
          };
          let params2 = {
            bus_name: this.form.busName,
            bus_legal_person: this.form.busLegalPerson,
            // proxyer_mobile: this.form.proxyMobile,
            bus_mobile: this.form.busMobile,
            bus_license: this.form.img_url,
            bus_logo: this.form.img_url2,
            bus_id: this.form.busId,
            bus_name_abbrev: this.form.bus_name_abbrev
          };
          if (this.ifEdit) {
            updateBusManageById(params2).then((res) => {
              console.log(res, "------res");
              if (res.data.code == 200) {
                this.loading = false;

                this.fileList2 = [];
                this.fileList1 = [];
                this.$refs.allTitle.msg = "修改成功,待审核";
                this.$refs.allTitle.routerPath = null;
                this.$refs.allTitle.dialogVisible = true;

                this.tableLoading = true;
                getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
                  if (res.data.code == 200) {
                    this.form.total = res.data.totalPage;
                    this.enterpriseList = res.data.data;
                    this.tableLoading = false;
                  } else {
                    (this.$refs.allTitle.msg = res.data.msg), (this.$refs.allTitle.routerPath = null);
                    this.$refs.allTitle.dialogVisible = true;

                    this.tableLoading = false;
                  }
                });
                this.dialogFormVisible = false;
              } else {
                this.tableLoading = false;
                (this.$refs.allTitle.msg = res.data.msg), (this.$refs.allTitle.routerPath = null);
                this.$refs.allTitle.dialogVisible = true;
              }
            });
          } else {
            addProxyUser(params).then((res) => {
              console.log(res, "------res");
              if (res.data.code == 200) {
                this.$refs.allTitle.msg = "申请成功,请耐心等待审核";
                this.$refs.allTitle.routerPath = null;
                this.$refs.allTitle.dialogVisible = true;

                this.loading = false;

                this.tableLoading = true;
                getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
                  if (res.data.code == 200) {
                    this.form.total = res.data.totalPage;
                    this.enterpriseList = res.data.data;
                    this.tableLoading = false;
                  } else {
                    this.$refs.allTitle.msg = res.data.msg;
                    this.$refs.allTitle.routerPath = null;
                    this.$refs.allTitle.dialogVisible = true;

                    this.tableLoading = false;
                  }
                });
                this.dialogFormVisible = false;
                this.fileList2 = [];
                this.fileList1 = [];
              } else {
                this.tableLoading = false;

                this.loading = false;

                this.$refs.allTitle.msg = res.data.msg;
                this.$refs.allTitle.routerPath = null;
                this.$refs.allTitle.dialogVisible = true;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
  components: {
    VipDialog3,
    VipDialog5,
    VipDialog7,
    VipDialog8,
    allTitle,
    VipDialogAll,
    VipDialog2Copy
  },
  created() {},
  mounted() {
    // this.$message({
    //     type: 'warning',
    //     message: '只能上传png、jpg文件',
    //     offset: 280,
    // })
    getAllRegion({}).then((res) => {});
    this.tableLoading = true;
    getBusManage({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id }).then((res) => {
      let newArray = res.data.data.filter((item) => {
        return item.busStatus == "1";
      });

      if (res.data.code == 200) {
        this.form.total = res.data.totalPage;
        this.enterpriseList = res.data.data;
        this.tableLoading = false;
      } else {
        this.$refs.allTitle.msg = res.data.msg;
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
        this.tableLoading = false;
      }
    });
    getBusManage({ page: this.form.page, size: 100, bus_id: this.form.bus_id }).then((res) => {
      this.qioterList2 = res.data.data;
      this.qioterList2.unshift({ busId: "", busNameAbbrev: "所有厂商" });
    });
    getUserById().then((res) => {
      if (res.data.code == 200) {
        this.info = res.data.data;
        if (this.info.distribut_type == 3) {
          this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
          this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
          return (this.$refs.allTitle.dialogVisible = true);
        } else if (this.info.distribut_type == 0) {
          this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
        } else if (this.info.distribut_type == 2) {
          this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
        } else if (this.info.is_bus_vip == 0) {
          this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
        } else {
        }
      } else {
      }
    });
  }
};
</script>

<style lang="scss" scoped>
// ::v-deep .cell {
//     height: 100vh !important;
// }
//
::v-deep .el-message {
  top: 500px !important;
  background-color: red;
  /* 其他你需要的样式 */
}

// el-message--warning

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}

::v-deep .el-table .warning-row {
  background-color: rgb(223, 179, 103, 0.4);
}
::v-deep .el-upload {
  width: 120px;
}
::v-deep .el-upload__tip {
  text-align: left;
}

.el-upload {
  // width: 1000px !important;
  text-align: center;
}
::v-deep .el-table .warning-row2 {
  background-color: rgb(119, 162, 229, 0.4);
}
</style>
