<template>
  <div style="width: 100vw">
    <div class="userCard">
      <div class="userContent">
        <div class="left">
          <img src="../assets/02b8050c91227b471d3aedbd55ecb557_1.png" style="width: 110px; height: 110px; border-radius: 50%; padding: 66px 4px" alt="" />
        </div>
        <div class="middle" style="display: flex">
          <div class="middle-1">
            {{ dataList.busName }}

            <img src="../assets/newImg3/超级黄金会员.gif" style="width: 52px; height: 17px" alt="" v-if="dataList.isSupperVip == 1" />
            <img src="../assets/newImg3/黄金会员.gif" style="width: 52px; height: 18px" alt="" v-else-if="dataList.isBusVip == 1" />
          </div>

          <!-- <div class="middle-1">中海南联编辑部</div> -->
        </div>
      </div>
    </div>
    <div class="factureContent">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 5px">
        <el-tab-pane label="全部" name="first">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="500"
            stripe
            :header-cell-style="{
              'font-weight': '900',
              color: '#227BFF',

              'font-family': 'MicrosoftYaHeiLight'
            }"
          >
            <el-table-column prop="goodsName" label="产品"> </el-table-column>
            <el-table-column prop="bqType" label="规格/参数">
              <template #default="scope">
                <div>
                  {{ scope.row.bqType ? scope.row.bqType : "/" }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="bqPrice" label="价格"> </el-table-column>
            <!-- <el-table-column prop="fourPrice" label="昨日报价"> </el-table-column> -->

            <el-table-column prop="bqSpec" label="单位"> </el-table-column>
            <el-table-column prop="bqProvince" label="发货地"> </el-table-column>
            <el-table-column prop="addTime" label="报价日期">
              <template #default="scope">
                <div>
                  {{ scope.row.addTime.slice(0, 11) }}
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="涨跌值">
              <template #default="scope">
                <div v-if="scope.row.bqPrice - scope.row.fourPrice > 0" style="color: red">
                  {{ scope.row.bqPrice - scope.row.fourPrice }}
                </div>
                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice < 0" style="color: green">
                  {{ scope.row.bqPrice - scope.row.fourPrice }}
                </div>
                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice == 0">0</div>
              </template>
            </el-table-column>
            <el-table-column label="涨跌幅">
              <template #default="scope">
                <div v-if="scope.row.fourPrice == 0 || scope.row.bqPrice - scope.row.fourPrice == 0">0%</div>
                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice > 0" style="color: red">
                  {{ (((scope.row.bqPrice - scope.row.fourPrice) * 100) / scope.row.fourPrice).toFixed(2) }}%
                </div>
                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice < 0" style="color: green">
                  {{ (((scope.row.bqPrice - scope.row.fourPrice) * 100) / scope.row.fourPrice).toFixed(2) }}%
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="onePrice" label="操作">
              <template #default="scope">
                <div>
                  <el-button type="primary" size="mini" @click="toHistory(scope.row)">曲线图</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="consultBox">
            <div class="contaion3-item" @click="skip(item)" v-for="(item, index) in consultList">
              <div class="con-left">
                <img :src="item.imgUrl" style="width: 100%; height: 100%; border-radius: 12px" alt="" />
              </div>
              <div class="con-right">
                <div style="font-weight: 700; padding: 4px 15px; font-size: 17px; color: #000000">{{ item.bgcTitle }}</div>
                <div class="con-right-middel">{{ item.bgcContent.replace(/<[^>]*>/g, "") }}</div>
                <div style="display: flex; padding: 5px 5px 5px 15px; color: #999999; font-size: 12px; font-weight: 290" class="con-bottom">
                  <div>
                    <img src="../assets/zhnlImg/Clip path group@1x.png" style="vertical-align: middle; margin-right: 2px" alt="" />
                    <span style="vertical-align: middle">{{ item.addTime }}</span>
                  </div>
                  <div style="margin-left: 20px">
                    <span style="vertical-align: middle">阅读量:{{ item.readNum }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="videoBox">
            <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
              <div style="position: relative">
                <div style="position: absolute; bottom: 8px; right: 3px; font-size: 12px; color: #ffffff">
                  {{ item.vAddTime.slice(0, 10) }}
                </div>
                <img :src="item.vImgUrl" style="width: 100%; height: 116px; object-fit: cover; border-radius: 12px" alt="" />
              </div>
              <div style="color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                {{ item.vTitle }}
              </div>
              <div style="color: gray; font-size: 12px; padding-top: 3px; color: #9499a0">
                <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

                <span style="vertical-align: middle">{{ item.vUploader }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="产品报价" name="second">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="500"
            stripe
            :header-cell-style="{
              'font-weight': '900',
              color: '#227BFF',

              'font-family': 'MicrosoftYaHeiLight'
            }"
          >
            <el-table-column prop="goodsName" label="产品"> </el-table-column>
            <el-table-column prop="bqPrice" label="今日报价"> </el-table-column>
            <el-table-column prop="fourPrice" label="昨日报价"> </el-table-column>

            <el-table-column prop="bqSpec" label="单位"> </el-table-column>
            <el-table-column label="涨跌值">
              <template #default="scope">
                <!-- <span>{{ scope.row.upPrice }}</span> -->
                <div v-if="scope.row.bqPrice - scope.row.fourPrice > 0" style="color: red">
                  {{ scope.row.bqPrice - scope.row.fourPrice }}
                </div>
                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice < 0" style="color: green">
                  {{ scope.row.bqPrice - scope.row.fourPrice }}
                </div>
                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice == 0">0</div>
              </template>
            </el-table-column>
            <el-table-column label="涨跌幅">
              <template #default="scope">
                <div v-if="scope.row.fourPrice == 0 || scope.row.bqPrice - scope.row.fourPrice == 0">0%</div>
                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice > 0" style="color: red">
                  {{ (((scope.row.bqPrice - scope.row.fourPrice) * 100) / scope.row.fourPrice).toFixed(2) }}%
                </div>
                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice < 0" style="color: green">
                  {{ (((scope.row.bqPrice - scope.row.fourPrice) * 100) / scope.row.fourPrice).toFixed(2) }}%
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="视频" name="third">
          <div class="videoBox">
            <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
              <div style="position: relative">
                <div style="position: absolute; bottom: 8px; right: 3px; font-size: 12px; color: #ffffff">
                  {{ item.vAddTime.slice(0, 10) }}
                </div>
                <img :src="item.vImgUrl" style="width: 100%; height: 116px; object-fit: cover; border-radius: 12px" alt="" />
              </div>
              <div style="color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                {{ item.vTitle }}
              </div>
              <div style="color: gray; font-size: 12px; padding-top: 3px; color: #9499a0">
                <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

                <span style="vertical-align: middle">{{ item.vUploader }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资讯" name="fourth">
          <div class="consultBox">
            <div class="contaion3-item" @click="skip(item)" v-for="(item, index) in consultList">
              <div class="con-left">
                <img :src="item.imgUrl" style="width: 100%; height: 100%; border-radius: 12px" alt="" />
              </div>
              <div class="con-right">
                <div style="font-weight: 700; padding: 4px 15px; font-size: 17px; color: #000000">{{ item.bgcTitle }}</div>
                <div class="con-right-middel">{{ item.bgcContent.replace(/<[^>]*>/g, "") }}</div>
                <div style="display: flex; padding: 5px 5px 5px 15px; color: #999999; font-size: 12px; font-weight: 290" class="con-bottom">
                  <div>
                    <img src="../assets/zhnlImg/Clip path group@1x.png" style="vertical-align: middle; margin-right: 2px" alt="" />
                    <span style="vertical-align: middle">{{ item.addTime }}</span>
                  </div>
                  <div style="margin-left: 20px">
                    <span style="vertical-align: middle">阅读量:{{ item.readNum }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getShippingMain, getShippingMainGoods, getQuotationByBusId, getBusManageById, getVodAndConsultByBusId } from "@/api/user";

export default {
  data() {
    return {
      activeName: "first",
      dataList: {},
      tableData: [],
      consultList: [],
      videoList2: [],
      allNumber: 0,
      page: 1,
      size: 30
    };
  },
  methods: {
    toHistory(row) {
      const { href } = this.$router.resolve({
        path: "historicalQuotation",
        query: {
          catOne: row.catOne,
          catTwo: row.catTwo,
          catThree: row.catThree,
          catFour: row.catFour,
          goodName: row.goodsName,
          quotation_man: this.$route.query.id,
          area_one: row.areaOne,
          area_two: row.areaTwo
        }
      });
      window.open(href, "_blank");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    skip(item) {
      const { href } = this.$router.resolve({
        path: "/titleDetails",
        query: { id: item.bgcId }
      });
      window.open(href, "_blank");
    },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId
        }
      });
      window.open(href, "_blank");
    }
  },
  created() {},
  mounted() {
    getBusManageById({ bus_id: this.$route.query.id }).then((res) => {
      this.dataList = res.data.data;
    });
    let params = {
      bus_id: this.$route.query.id,
      page: this.page,
      size: this.size
    };
    getQuotationByBusId(params).then((res) => {
      this.tableData = res.data.data;
      this.allNumber = res.data.totalPage;
    });
    let params2 = {
      bus_id: this.$route.query.id,
      v_size: 8,
      c_size: 8
    };
    getVodAndConsultByBusId(params2).then((res) => {
      this.consultList = res.data.data.consultList;
      this.videoList2 = res.data.data.videoList;
    });
  }
};
</script>

<style lang="scss" scoped>
.el-table {
  border: 1px solid #d8d8d8;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 60px;
}
.userCard {
  margin-top: 80px;
  background-image: url("../assets/newImg3/背景-蓝色@1x.png");
  width: 100vw;
  background-size: cover;
  .userContent {
    width: 1200px;

    height: 280px;
    margin: auto;
    z-index: 10;
    display: flex;
    .left {
      width: 120px;
      height: 100%;
    }
    .middle {
      height: 100%;
      width: 700px;
      padding: 0px 30px;
      .middle-1 {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        margin-top: 88px;
      }
    }
  }
}
.factureContent {
  width: 1200px;
  margin: auto;
  .consultBox {
    width: 1200px;
    margin: auto;
    margin-bottom: 50px;
    .contaion3-item {
      height: 100px;
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;
      margin-top: 20px;
      .con-left {
        width: 200px;
        height: 100%;
      }

      .con-right {
        width: 990px;
        height: 100%;

        .con-bottom:hover {
          color: rgb(32, 123, 255) !important;
        }

        .con-right-middel {
          padding: 0px 5px 5px 15px;
          height: 33px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: #000000;
          font-size: 14px;
          font-weight: 290;
        }

        .con-right-middel:hover {
          color: rgb(32, 123, 255) !important;
        }
      }
    }
  }
  .videoBox {
    width: 1200px;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    gap: 0;
    align-content: flex-start;
    justify-content: flex-start;
    .edit-item {
      box-sizing: border-box;
      cursor: pointer;
      width: 228px;
      height: 174px;
      padding: 0px 1px;
      margin-right: 11px;
    }
  }
}
</style>
