<template>
  <div>
    <!-- <div class="otherDiv">
            <div>123123</div>
        </div> -->

    <el-dialog title="" :visible.sync="dialogVisible" width="1200px" top="50px">
      <div style="display: flex; font-size: 18px">
        <div :class="{ bgc2: tabNumber == 1 }" @click="tabNumber = 1" style="cursor: pointer">本地上传</div>
        <div :class="{ bgc2: tabNumber == 2 }" @click="tabNumber = 2" style="margin-left: 8px; cursor: pointer">搜原图库</div>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 100px; margin-bottom: 100px" v-show="tabNumber == 1">
        <el-button type="primary" @click="uploadFile">本地上传</el-button>
        <div style="margin-top: 5px">支持jpg、jpeg、png等多种格式，单张图片最大支持20MB</div>
      </div>
      <div style="width: 100%" v-show="tabNumber == 2">
        <input v-model="searchImg" autocomplete="off" style="margin-top: 20px; width: 80%;height: 35px;border: 1px solid #d8d8d8;border-radius: 5px;" maxlength="20" show-word-limit placeholder="请输入内容"></input>
        <el-button type="primary" icon="el-icon-search" @click="goSearch"></el-button>
        <div class="imgBox" @scroll="handleScroll">
          <div class="imgItem" v-for="(item, index) in imgList" :key="item.gId" @click="toggleSelection(index)">
            <img :src="item.imgUrl" style="width: 100%; height: 100%; border-radius: 10px; cursor: pointer" alt="" />
            <div class="overlay" v-if="isSelected(index)">
              <span class="number">
                {{ selectedIndices.indexOf(index) + 1 }}
              </span>
            </div>
          </div>
        </div>
        <!-- <div style="width: 100%">
          <el-pagination
            v-model:current-page="page"
            v-model:page-size="size"
            style="margin-left: 30%; margin-top: 30px; margin-bottom: 20px"
            :page-sizes="[10, 20, 50, 500]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addImg">确 定</el-button>
      </span>
    </el-dialog>
    <el-upload
      :headers="headerObj"
      :multiple="multiple"
      :limit="limit"
      class="quill-upload"
      :action="serviceUrl"
      accept=".jpg,.png,.jpeg"
      style="display: none; width: 0"
      :show-file-list="false"
      :on-success="success"
      :before-upload="beforeAvatarUpload"
    >
      <!-- <el-button size="small" type="primary">点击上传</el-button> -->
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
    <el-upload
      :headers="headerObj"
      :multiple="multiple"
      :limit="limit"
      class="quill-upload"
      :action="serviceUrl2"
      style="display: none; width: 0"
      :show-file-list="false"
      :on-success="success2"
      :before-upload="beforeAvatarUpload"
    >
      <!-- <el-button size="small" type="primary">点击上传</el-button> -->
      <i class="el-icon-upload2"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
    <el-button class="btns" type="primary" @click="showDialog2" style="display: none"> 展示</el-button>
    <div class="editor"></div>
    <quill-editor
      ref="myQuillEditor"
      :content="content"
      v-model="editorData"
      style="min-height: 900px; border-bottom: 1px solid rgb(232, 232, 232)"
      :options="editorOption"
      class="editor"
      @click.native="handleEditAblequestion"
    ></quill-editor>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.0-dev.3/quill.min.js" type="text/javascript"></script>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quillOption from "./quillOption.js";
import { getGalleryByPage } from "../api/user.js";
import QuillBetterTable from "quill-better-table";
import Video from "./video.js";

// import Quill from "quill";
import { quillEditor, Quill } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module"; // 图片拖动组件引用
import ImageResize from "quill-image-resize-module"; // 图片缩放组件引用
Quill.register(
  {
    "modules/better-table": QuillBetterTable
  },
  true
);

Quill.register(Video, true);
Quill.register("modules/imageDrop", ImageDrop); // 注册
Quill.register("modules/imageResize", ImageResize); // 注册
import Parchment from "parchment";

const ICON_SVGS = {
  "table-insert-row": `<svg viewbox="0 0 18 18">
      <g class="ql-fill ql-stroke ql-thin ql-transparent">
        <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="2.5"></rect>
        <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="12.5"></rect>
      </g>
      <rect class="ql-fill ql-stroke ql-thin" height="3" rx="0.5" ry="0.5" width="7" x="8.5" y="7.5"></rect>
      <polygon class="ql-fill ql-stroke ql-thin" points="4.5 11 2.5 9 4.5 7 4.5 11"></polygon>
      <line class="ql-stroke" x1="6" x2="4" y1="9" y2="9"></line>
    </svg>`,
  "table-insert-column": `<svg viewbox="0 0 18 18">
      <g class="ql-fill ql-transparent">
        <rect height="10" rx="1" ry="1" width="4" x="12" y="2"></rect>
        <rect height="10" rx="1" ry="1" width="4" x="2" y="2"></rect>
      </g>
      <path class="ql-fill" d="M11.354,4.146l-2-2a0.5,0.5,0,0,0-.707,0l-2,2A0.5,0.5,0,0,0,7,5H8V6a1,1,0,0,0,2,0V5h1A0.5,0.5,0,0,0,11.354,4.146Z"></path>
      <rect class="ql-fill" height="8" rx="1" ry="1" width="4" x="7" y="8"></rect>
    </svg>`,
  "table-delete-row": `<svg viewbox="0 0 18 18">
      <g class="ql-fill ql-stroke ql-thin ql-transparent">
        <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="2.5"></rect>
        <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="12.5"></rect>
      </g>
      <rect class="ql-fill ql-stroke ql-thin" height="3" rx="0.5" ry="0.5" width="7" x="8.5" y="7.5"></rect>
      <line class="ql-stroke ql-thin" x1="6.5" x2="3.5" y1="7.5" y2="10.5"></line>
      <line class="ql-stroke ql-thin" x1="3.5" x2="6.5" y1="7.5" y2="10.5"></line>
    </svg>`,
  "table-delete-column": `<svg viewbox="0 0 18 18">
      <g class="ql-fill ql-transparent">
        <rect height="10" rx="1" ry="1" width="4" x="2" y="6"></rect>
        <rect height="10" rx="1" ry="1" width="4" x="12" y="6"></rect>
      </g>
      <rect class="ql-fill" height="8" rx="1" ry="1" width="4" x="7" y="2"></rect>
      <path class="ql-fill" d="M9.707,13l1.146-1.146a0.5,0.5,0,0,0-.707-0.707L9,12.293,7.854,11.146a0.5,0.5,0,0,0-.707.707L8.293,13,7.146,14.146a0.5,0.5,0,1,0,.707.707L9,13.707l1.146,1.146a0.5,0.5,0,0,0,.707-0.707Z"></path>
    </svg>`
};
const titleConfig = {
  "ql-bold": "加粗",
  "ql-color": "颜色",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-italic": "斜体",
  "ql-link": "添加链接",
  "ql-background": "颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
  "ql-upload": "文件",
  "ql-table": "插入表格",
  "ql-table-insert-row": "插入行",
  "ql-table-insert-column": "插入列",
  "ql-table-delete-row": "删除行",
  "ql-table-delete-column": "删除列"
};

// let config = {
//     scope: Parchment.Scope.BLOCK,
//     whitelist: ['right', 'center', 'justify']
// };
// let AlignStyle = new Parchment.Attributor.Style('align', 'text-align', config);
// export { AlignStyle };
var Align = Quill.import("attributors/style/align");
Align.whitelist = ["right", "center", "justify"];
Quill.register(Align, true);
export default {
  name: "editor",
  components: { quillEditor },
  props: ["serviceUrl", "getHeader", "content", "limit", "multiple"],
  data() {
    // 富文本配置
    const toolbarOptions = [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "code-block",
        "clean",
        { header: 1 },
        { header: 2 },
        { list: "ordered" },
        { list: "bullet" },
        { script: "sub" },
        { script: "super" },
        { indent: "-1" },
        { indent: "+1" },
        { direction: "rtl" },

        { color: [] },
        { background: [] },
        { align: [] },
        "video",
        "image",
        { size: ["small", false, "large", "huge"] },
        { header: [1, 2, 3, 4, 5, 6, false] }
      ],
      [{ table: "TD" }, { "table-insert-row": "TIR" }, { "table-insert-column": "TIC" }, { "table-delete-row": "TDR" }, { "table-delete-column": "TDC" }]
    ];

    return {
      imgShowList: [],
      selectedIndices: [], // 存储被选中的图片索引
      totalPage: "",
      imgList: [],
      key_word: "",
      page: 1,
      size: 15,
      searchImg: "",
      tabNumber: 1,
      activeName: "second",
      dialogVisible: false,
      qillInit: true,
      headerObj: {
        token: window.localStorage.getItem("token")
      },
      serviceUrl2: "https://www.zhnl.com/zhnl/breakPointUpload",
      editorOption: {
        theme: "snow", //主题 snow：有工具栏的；bubble：只有文本域的
        placeholder: "请输入正文",
        fontSize: "20px",
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
        },
        modules: {
          imageDrop: true, //图片拖拽
          imageResize: {
            //放大缩小
            displayStyles: {
              backgroundColor: "red",
              border: "none",
              color: "white",
              width: "12px",
              height: "12px"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
          toolbar: {
            "table-better": {
              language: "en_US",
              menus: ["column", "row", "merge", "table", "cell", "wrap", "delete"],
              toolbarTable: true
            },
            container: toolbarOptions,
            handlers: {
              table: function (val) {
                // let vm = this;
                // let quill = this.$refs.myQuillEditor.quill;
                console.log(this, "this---");

                this.quill.getModule("table").insertTable(2, 3);
              },
              "table-insert-row": function () {
                this.quill.getModule("table").insertRowBelow();
              },
              "table-insert-column": function () {
                this.quill.getModule("table").insertColumnRight();
              },
              "table-delete-row": function () {
                this.quill.getModule("table").deleteRow();
              },
              "table-delete-column": function () {
                this.quill.getModule("table").deleteColumn();
              },

              // 重写点击组件上的图片按钮要执行的代码
              image: function (value) {
                console.log("触发了，，，", this);

                this.dialogVisible = true;
                //    console.log(document.querySelector('.btns'),'dddds');
                document.querySelector(".btns").click();
              },
              video: function (value) {
                console.log("触发了dddddd");
                document.querySelector(".quill-upload .el-icon-upload2").click();
              }
            }
          }
        }
      },
      editorData: ""
    };
  },
  methods: {
    handleScroll(event) {
      const target = event.target;
      const scrollDistance = target.scrollTop;
      const clientHeight = target.clientHeight;
      const scrollHeight = target.scrollHeight;

      // 判断是否滚动到底部
      if (scrollDistance + clientHeight >= scrollHeight) {
        console.log("已经滚动到底部了！");
        this.size += 10;
        getGalleryByPage({ key_word: this.key_word, page: this.page, size: this.size }).then((res) => {
          if (res.data.code == 200) {
            this.imgList = res.data.data;
            this.totalPage = res.data.totalPage;
          }
        });
      }
    },
    addImg() {
      console.log(this.selectedIndices, "selectedIndices");
      this.imgList.forEach((item, index) => {
        console.log(item);
        if (this.selectedIndices.includes(index)) {
          let vm = this;
          let quill = this.$refs.myQuillEditor.quill;
          let pos = quill.selection.savedRange.index; //这个得注意下，网上很多都是不对的
          quill.insertEmbed(pos, "image", item.imgUrl);
        }
      });
      this.dialogVisible = false;
      this.selectedIndices = [];
    },
    toggleSelection(index) {
      const indexInArray = this.selectedIndices.indexOf(index);
      if (indexInArray === -1) {
        // 如果索引不在数组中，则添加它
        this.selectedIndices.push(index);
      } else {
        // 如果索引在数组中，则移除它
        this.selectedIndices.splice(indexInArray, 1);
      }
    },
    isSelected(index) {
      // 检查索引是否在selectedIndices数组中
      return this.selectedIndices.includes(index);
    },
    goSearch() {
      this.key_word = this.searchImg;
      getGalleryByPage({ key_word: this.searchImg, page: this.page, size: this.size }).then((res) => {
        if (res.data.code == 200) {
          this.imgList = res.data.data;
          this.totalPage = res.data.totalPage;
        }
      });
    },
    handleSizeChange(e) {
      this.size = e;
      getGalleryByPage({ key_word: this.key_word, page: this.page, size: this.size }).then((res) => {
        if (res.data.code == 200) {
          this.imgList = res.data.data;
          this.totalPage = res.data.totalPage;
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;

      getGalleryByPage({ key_word: this.key_word, page: this.page, size: this.size }).then((res) => {
        if (res.data.code == 200) {
          this.imgList = res.data.data;
          this.totalPage = res.data.totalPage;
        }
      });
    },
    showDialog2() {
      this.dialogVisible = true;
    },
    uploadFile() {
      document.querySelector(".quill-upload .el-icon-upload").click();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    _initCustomToolbarIcon() {
      // 获取quill的原生图标库
      let icons = Quill.import("ui/icons");

      // 从自定义图标SVG列表中找到对应的图标填入到原生图标库中
      _.forEach(ICON_SVGS, (iconValue, iconName) => {
        icons[iconName] = iconValue;
      });
    },
    showDialog() {
      this.$nextTick(() => {
        setTimeout(() => {
          // let content = document.getElementById('myQuillEditor');
          let dom = this.$refs.myQuillEditor.$el;
          this.quill = new Quill(dom, this.editorOption);
        });
      });
    },
    handleEditAblequestion() {
      this.$refs.myQuillEditor.quill.focus();
    },
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar");
      const aButton = oToolBar.querySelectorAll("button");
      const aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function (item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1" ? (item.title = "向右缩进") : (item.title = "向左缩进");
        } else {
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function (item) {
        item.parentNode.title = titleConfig[item.classList[0]];
      });
    },

    addQuillTitle() {
      document.getElementsByClassName("ql-editor")[0].dataset.placeholder = "";
      for (const item of quillOption.titleConfig) {
        const tip = document.querySelector(".quill-editor " + item.Choice);

        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    },
    beforeAvatarUpload(file) {
      this.loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.2)"
      });
      console.log(file, "file,,..");
      const isLt500K = file.size / 1024 / 1024 < 1;
      console.log(file.size / 1024 / 1024, "file.size / 1024 / 1024");
      console.log(isLt500K, "isLt500K");
      // if (!isLt500K) {
      //     this.$message.error('上传图片大小不能超过 1000KB!');
      //     return false;
      // }
      this.$emit("beforeAvatarUpload", file);
    },
    success(res, file, fileList) {
      this.dialogVisible = false;
      this.loading.close();
      console.log(res, "dddres======");
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let vm = this;
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == "200") {
        this.editorData = "起飞了吗";
        console.log(this.editorData);

        this.$emit("getEditorData", this.editorData);
        console.log("进来了吗1");
        // 获取光标所在位置
        let pos = quill.selection.savedRange.index; //这个得注意下，网上很多都是不对的
        // 插入图片到光标位置

        quill.insertEmbed(pos, "image", res.imgUrl);

        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        vm.$Message.error("图片插入失败");
      }
      // loading动画消失
      this.quillUpdateImg = false;
      console.log("走到这里了");
    },
    success2(res, file, fileList) {
      this.loading.close();

      console.log(res, "dddres======");
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let vm = this;
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == "200") {
        console.log("进来了吗1");
        // 获取光标所在位置
        let pos = quill.selection.savedRange.index; //这个得注意下，网上很多都是不对的
        // 插入图片到光标位置
        quill.insertEmbed(pos, "video", res.data.video_url);

        // quill.insertEmbed(pos, 'video', res.data.video_url)

        console.log("进来了吗2");

        // 调整光标到最后
        quill.setSelection(length + 1);
        console.log("进来了吗3");
      } else {
        vm.$Message.error("图片插入失败");
      }
      // loading动画消失
      this.quillUpdateImg = false;
      console.log("走到这里了");
    }
  },
  watch: {
    serviceUrl(val) {
      this.serviceUrl = val;
    },
    getHeader(val) {
      this.getHeader = val;
    },
    editorData(val) {
      this.$emit("getEditorData", this.editorData);
    },
    content(val) {
      this.content = val;
    }
  },
  mounted() {
    getGalleryByPage({ key_word: this.key_word, page: this.page, size: this.size }).then((res) => {
      if (res.data.code == 200) {
        this.imgList = res.data.data;
        this.totalPage = res.data.totalPage;
      }
    });
    this.addQuillTitle();
    // let dom = this.$refs.myQuillEditor.$el;
    // this.quill = new Quill(dom, this.editorOption);

    // this._initCustomToolbarIcon();

    // this.$el.querySelector(
    //   ".ql-table"
    // ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6483"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6483)"><g><path d="M10,3C10.552,3,11,3.448,11,4L11,20C11,20.552,10.552,21,10,21L4,21C3.448,21,3,20.552,3,20L3,4C3,3.448,3.448,3,4,3L10,3ZM9,5L5,5L5,19L9,19L9,5ZM18,7C20.761,7,23,9.239,23,12C23,14.761,20.761,17,18,17C15.239,17,13,14.761,13,12C13,9.239,15.239,7,18,7ZM19,9L17,9L17,10.998999999999999L15,11L15,13L17,12.999L17,15L19,15L19,12.999L21,13L21,11L19,10.998999999999999L19,9Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;

    // this.$el.querySelector(
    //   ".ql-table-insert-row"
    // ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6483"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6483)"><g><path d="M10,3C10.552,3,11,3.448,11,4L11,20C11,20.552,10.552,21,10,21L4,21C3.448,21,3,20.552,3,20L3,4C3,3.448,3.448,3,4,3L10,3ZM9,5L5,5L5,19L9,19L9,5ZM18,7C20.761,7,23,9.239,23,12C23,14.761,20.761,17,18,17C15.239,17,13,14.761,13,12C13,9.239,15.239,7,18,7ZM19,9L17,9L17,10.998999999999999L15,11L15,13L17,12.999L17,15L19,15L19,12.999L21,13L21,11L19,10.998999999999999L19,9Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;

    // this.$el.querySelector(
    //   ".ql-table-insert-column"
    // ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6487"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6487)"><g><path d="M12,13C14.761,13,17,15.239,17,18C17,20.761,14.761,23,12,23C9.239,23,7,20.761,7,18C7,15.239,9.239,13,12,13ZM13,15L11,15L11,16.999000000000002L9,17L9,19L11,18.999000000000002L11,21L13,21L13,18.999000000000002L15,19L15,17L13,16.999000000000002L13,15ZM20,3C20.552,3,21,3.448,21,4L21,10C21,10.552,20.552,11,20,11L4,11C3.448,11,3,10.552,3,10L3,4C3,3.448,3.448,3,4,3L20,3ZM5,5L5,9L19,9L19,5L5,5Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    // this.$el.querySelector(
    //   ".ql-table-delete-row"
    // ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6479"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6479)"><g><path d="M20,3C20.552,3,21,3.448,21,4L21,20C21,20.552,20.552,21,20,21L14,21C13.448,21,13,20.552,13,20L13,4C13,3.448,13.448,3,14,3L20,3ZM19,5L15,5L15,19L19,19L19,5ZM6,7C8.761,7,11,9.239,11,12C11,14.761,8.761,17,6,17C3.239,17,1,14.761,1,12C1,9.239,3.239,7,6,7ZM7,9L5,9L5,10.998999999999999L3,11L3,13L5,12.999L5,15L7,15L7,12.999L9,13L9,11L7,10.998999999999999L7,9Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;
    // this.$el.querySelector(
    //   ".ql-table-delete-column"
    // ).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="master_svg0_152_6491"><rect x="0" y="0" width="24" height="24" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_152_6491)"><g><path d="M20,13C20.552,13,21,13.448,21,14L21,20C21,20.552,20.552,21,20,21L4,21C3.448,21,3,20.552,3,20L3,14C3,13.448,3.448,13,4,13L20,13ZM19,15L5,15L5,19L19,19L19,15ZM12,1C14.761,1,17,3.239,17,6C17,8.761,14.761,11,12,11C9.239,11,7,8.761,7,6C7,3.239,9.239,1,12,1ZM13,3L11,3L11,4.9990000000000006L9,5L9,7L11,6.999L11,9L13,9L13,6.999L15,7L15,5L13,4.9990000000000006L13,3Z" fill="#444444" fill-opacity="1"/></g></g></svg>`;

    // const qlToolbar = document.getElementsByClassName('ql-toolbar')
    // console.log(qlToolbar, 'qlToolbar');
    // const newDiv = document.getElementsByClassName('otherDiv');
    // qlToolbar.appendChild(newDiv)
  }
};
</script>

<style>
.bgc2 {
  color: rgb(64, 158, 255);
}

.el-dialog__body {
  padding: 13px 20px;
}

.ql-toolbar .ql-snow {
}

/* video {
    width: 400px !important;
    height: 400px !important;
} */

.ql-editor img {
  margin: auto !important;
  text-align: center;
  display: block;
  max-height: 560px;
  max-width: 760px;
}
.quill-editor .editor {
  border-bottom: 1px solid rgb(232, 232, 232) !important;
}

/* .ql-video {
    width: 400px !important;
    height: 400px !important;
} */

.ql-editor ul li {
  list-style-type: disc !important;
  padding-left: 0.5em;
}
.ql-editor ol li {
  list-style-type: decimal !important;
  padding-left: 0.5em;
}
.ql-editor {
  padding: 2px 9px !important;
}

.ql-bold {
  /* background-color: pink !important; */
}

.el-upload {
  width: 240px;
  margin-left: 20px;
  height: 50%;
  /* padding: 10px */
}

.ql-editor video {
  width: 300px;
  height: 300px;
  margin: auto !important;
  /* width: 300px !important;
    height: 300px !important; */
}

.editor {
  /* max-height: 790px !important; */
}

/* .ql-color .ql-picker-options [data-value='custom-color'] {
    background: none !important;
    width: 100% !important;
    height: 20px !important;
    text-align: center;
    background-color: red;

}

.ql-color .ql-picker-options [data-value='custom-color']:before {
    content: 'Custom Color';
    background-color: red;

}

.ql-color .ql-picker-options [data-value='custom-color']:hover {
    border-color: transparent !important;
    background-color: red;
} */

.ql-editing .ql-blank {
  left: 100px !important;
  font-size: 20px !important;
}

.ql-container {
  border: 0px solid red !important;
  padding: 8px 0px;
  margin-top: 94px;
  /* color: #999; */
  font-size: 17px !important;
  font-weight: 400 !important;
}

.ql-toolbar {
  border: 0px solid red !important;
  padding: 0px 5px !important;
  margin-bottom: 3px;
}

.imgBox {
  margin-top: 30px;
  width: 100%;
  /* height: 100px; */
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  overflow-y: scroll;
  gap: 0;
  align-content: flex-start;
  justify-content: flex-start;
  .imgItem {
    position: relative;
    width: 208px;
    height: 180px;
    padding: 10px;
  }
}

.imgBox::-webkit-scrollbar {
  width: 8px;
}

.imgBox::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.imgBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.overlay {
  width: 210px;
  height: 180px;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  /* 灰色遮罩 */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.imgBgc {
  background-color: rgba(0, 0, 0, 0.5);
}
input:focus {  
    outline: none;

}
</style>
