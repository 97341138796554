<!-- //有可能需要随机生成id才能使在切换时不会渲染失败 -->

<!--
<template>
    <div class="echart2" :id="theId" :style="myChartStyle"></div>
</template>
  
<script>
import * as echarts2 from "echarts";

export default {
    props: ["thexData", "theyData", 'indexs', 'isRouterAlive'],
    data() {
        return {
            xData: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //横坐标
            yData: [5, 7, 9, 1, 7, 8, 3], //数据
            myChartStyle: { float: "left", width: "80%", height: "100%" }, //图表样式
            type: 'lately-7',
            theId: ''
        };
    },
    watch: {
        dataCountFlag(newName, oldName) {
            if (newName == 1) {
                this.type = 'lately-7'
                this.getUserGoodsLinkCount()
            } else if (newName == 2) {
                this.type = 'lately-30'
                this.getUserGoodsLinkCount()

            } else if (newName == 3) {
                this.type = 'year'
                this.getUserGoodsLinkCount()

            }
        },
        // isRouterAlive(newName, oldName) {
        //     console.log('999', newName)
        //     this.theId = this.getuuid()
        //     echarts2.dispose(document.getElementById(this.theId))

        //     // this.theId = 'mychart' + this.indexs
        //     setTimeout(() => {
        //         this.xData = this.thexData
        //         this.yData = this.theyData
        //         console.log('999', this.yData)
        //         this.$nextTick(() => {
        //             this.initEcharts();

        //         })

        //     }, 1500)

        // }
    },
    mounted() {
        this.theId = this.getuuid()
        // this.theId = 'mychart' + this.indexs
        setTimeout(() => {
            this.xData = this.thexData
            this.yData = this.theyData

                this.initEcharts();


        }, 1500)
        // this.getUserGoodsLinkCount();

    },
    methods: {
        getuuid() {
            //需要16位就把下方return的字符串改成想要的格式
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString();
            })
        },
        initEcharts() {

            // 基本柱状图
            const option = {
                color: ['#477BFF'],
                xAxis: {
                    data: this.xData,
                    show: false
                },
                yAxis: {
                    show: false

                },
                series: [
                    {
                        type: "line", //形状为柱状图
                        data: this.yData
                    }
                ],
                // tooltip: {
                //     trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                //     axisPointer: {// 坐标轴指示器，坐标轴触发有效
                //         type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                //     }
                // },
            };

            
            const myChart = echarts2.init(document.getElementById(this.theId));
            document.getElementById(this.theId).removeAttribute('_echarts_instance_');
            myChart.setOption(option);
            //随着屏幕大小调节图表
            window.addEventListener("resize", () => {
                myChart.resize();
            });

            console.log('99', myChart)

        },

        //商品询盘统计
        async getUserGoodsLinkCount() {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/getUserGoodsLinkCount', {
                user_id: userId,
                type: this.type
            })
            this.xData = res.result.xData
            this.yData = res.result.yData
            this.initEcharts();

        },
    }
};
</script>
  
  -->

<template>
    <div class="echart" id="mychart" :style="myChartStyle" :eopts="{ seriesTemplate: { smooth: true } }"
        tooltipFormat="tooltipTemp1"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ['tableData', 'yData', 'xData'],
    data() {
        return {
            myChart: {},
            // xData: [], //横坐标
            // yData: [], //人数数据
            myChartStyle: { float: "left", width: "100%", height: "100%" } //图表样式
        };
    },
    created() {

        // this.xData=this.tableData[0].xData
        // this.yData=this.tableData[0].yData
    },
    watch: {
        tableData(newName, oldName) {
            // this.xData=newName[0].xData
            // this.yData=newName[0].yData
            this.initEcharts();
        }
    },
    mounted() {
        this.initEcharts();
    },
    methods: {
        initEcharts() {
            const option = {
                xAxis: {
                    disableGrid: true,
                    data: this.xData,
                    labelCount: 5

                },
                yAxis: {
                    gridType: "dash",
                    labelCount: 3,
                    axisLabel: {
                        formatter: function (value, index) {
                            return value
                        }
                    }
                },
                extra: {
                    tooltip: {
                        xAxisLabel: true,
                        yAxisLabel: true
                    },
                    line: {
                        type: "straight",
                        width: 2,
                        activeType: "hollow",
                        xAxisLabel: true
                    }
                },
                series: [
                    {
                        data: this.yData,
                        type: "line",// 类型设置为折线图
                        label: {
                            show: true,
                            formatter: function (data) {
                                return data.value
                            }
                        }

                    }
                ]
            };
            this.myChart = echarts.init(document.getElementById("mychart"));
            this.myChart.setOption(option);
            //随着屏幕大小调节图表
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });



        },

    }
};
</script>