<template>
    <div>
        <!-- <searchBar />
        <classifyBar :classify="1" :isNo="1" /> -->
        <div style="margin-top: 80px;">
            <div style="width: 80%;margin: auto;display: flex;">
                <div>
                    <h1 style="color: rgb(34,134,255);">{{ $route.query.goodName }}</h1>
                </div>
                <div style="margin-left: 50px;padding-top: 25px">
                    <div style="color: gray;">{{ currentDate }}</div>
                    <div style="color: red;">当前最新报价：{{ new_price }}</div>
                </div>
            </div>
            <div class="seePrice">
                <div class="seePrice-item">
                    <div class="see-box"></div>
                    <div class="see-box">日价格</div>
                    <div class="see-box">周价格</div>
                    <div class="see-box">月价格</div>

                </div>

                <div class="seePrice-item">
                    <div class="see-box">最低价</div>
                    <div class="see-box">{{ bottom.day_bt_price }}</div>
                    <div class="see-box">{{ bottom.week_bt_price }}</div>
                    <div class="see-box">{{ bottom.month_bt_price }}</div>

                </div>
                <div class="seePrice-item">
                    <div class="see-box">最高价</div>
                    <div class="see-box">{{ highest.day_he_price }}</div>
                    <div class="see-box">{{ highest.week_he_price }}</div>
                    <div class="see-box">{{ highest.month_he_price }}</div>

                </div>
                <div class="seePrice-item">
                    <div class="see-box">涨跌值</div>
                    <div class="see-box" style="color: red;" v-show="day.today_price - day.last_day_price >= 0">{{
                        (day.today_price - day.last_day_price).toFixed(1) }}</div>
                    <div class="see-box" style="color: green;" v-show="day.today_price - day.last_day_price < 0">{{
                        (day.today_price - day.last_day_price).toFixed(1) }}</div>

                    <div class="see-box" style="color: red;" v-show="week.this_week_price - week.last_week_price >= 0">
                        {{
                        (week.this_week_price - week.last_week_price).toFixed(1) }}</div>

                    <div class="see-box" style="color: green;" v-show="week.this_week_price - week.last_week_price < 0">
                        {{
                        (week.this_week_price - week.last_week_price).toFixed(1) }}</div>

                    <div class="see-box" style="color: red;"
                        v-if="month.this_month_price - month.last_month_price >= 0">
                        {{
                        (month.this_month_price - month.last_month_price).toFixed(1) }}</div>

                    <div class="see-box" style="color: green;"
                        v-show="month.this_month_price - month.last_month_price < 0">
                        {{
                        (month.this_month_price - month.last_month_price).toFixed(1) }}</div>

                </div>
                <div class="seePrice-item">
                    <div class="see-box">涨跌幅</div>
                    <div class="see-box" v-if="day.last_day_price == 0">0%</div>
                    <div class="see-box" style="color: red;" v-else-if="day.today_price - day.last_day_price > 0">{{
                        ((day.today_price - day.last_day_price) / day.last_day_price * 100).toFixed(1) }}%</div>
                    <div class="see-box" style="color: green;" v-else-if="day.today_price - day.last_day_price < 0">{{
                        ((day.today_price - day.last_day_price) / day.last_day_price * 100).toFixed(1) }}%</div>


                    <div class="see-box" v-if="week.last_week_price == 0">0%</div>
                    <div class="see-box" style="color: red;"
                        v-else-if="week.this_week_price - week.last_week_price > 0">
                        {{
                        ((week.this_week_price - week.last_week_price) / week.last_week_price * 100).toFixed(1) }}%
                    </div>
                    <div class="see-box" style="color: green;"
                        v-else-if="week.this_week_price - week.last_week_price < 0">{{
                        ((week.this_week_price - week.last_week_price) / week.last_week_price * 100).toFixed(1) }}%
                    </div>



                    <div class="see-box" v-if="month.last_month_price == 0">0%</div>
                    <div class="see-box" style="color: red;"
                        v-else-if="month.this_month_price - month.last_month_price > 0">
                        {{
                        ((month.this_month_price - month.last_month_price) / month.last_month_price * 100
                        ).toFixed(1) }}%</div>
                    <div class="see-box" style="color: green;"
                        v-else-if="month.this_month_price - month.last_month_price < 0">{{
                        ((month.this_month_price - month.last_month_price) / month.last_month_price * 100).toFixed(1)
                    }}%</div>

                </div>
            </div>
            <div style="width: 80%;margin: auto;">
                <el-button :type="primary1" @click="getPrice1()">
                    日均价
                </el-button> <el-button :type="primary2" @click="getPrice2()">
                    周价格
                </el-button>
                <el-button :type="primary3" @click="getPrice3()">
                    月价格
                </el-button>
                <el-button :type="primary4" @click="getPrice4()">
                    季度价格
                </el-button>
                <el-button :type="primary6" @click="getPrice6()">
                    半年价格
                </el-button>
                <el-button :type="primary5" @click="getPrice5()">
                    年价格
                </el-button>

            </div>
            <div ref="chart" style="width: 90%; height: 376px;margin: auto;"></div>
            <div style="width: 80%;margin: auto;margin-bottom: 18px;">
                <span style="font-weight: 900;font-size: 30px;margin-right: 20px;">历史报价记录</span>
                <el-select v-model="months" placeholder="请选择月份筛选" style="margin-right: 10px;" @change="monthsChange">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="years" placeholder="请选择年度筛选" style="margin-right: 10px;" @change="yearsChange">
                    <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                    @change="dataChange" end-placeholder="结束日期" style="margin-right: 10px;">
                </el-date-picker>
                <el-button @click="reset()" type="primary">
                    重置
                </el-button>
            </div>
            <el-table :data="tableData" style="width: 80%;margin-top: 200px;text-align: center;margin: auto;" border
                :row-class-name="tableRowClassName" :cell-style="{
                        'text-align': 'center'
                    }"
                :header-cell-style="{ 'text-align': 'center', height: '4px', background: '#227BFF', border: '0px solid #227BFF', color: 'white', }">
                <!-- <el-table-column type="index" width="50">
                </el-table-column> -->
                <!-- <el-table-column prop="bqIndustry" label="行业分类" /> -->
                <!-- <el-table-column prop="bqIndustry2" label="二级行业分类" /> -->
                <!-- <el-table-column prop="catOneName" label="产品一级分类" />
                <el-table-column prop="catTwoName" label="产品二级分类" /> -->
                <el-table-column prop="bus_name_abbrev" label="报价厂商" />

                <el-table-column prop="city" label="发货地">
                    <template #default="scope">
                        <div>
                            {{ scope.row.city ? scope.row.city : scope.row.province }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsName" label="产品名称" />
                <el-table-column prop="bqType" label="规格/参数">
                    <template #default="scope">
                        <div>
                            {{ scope.row.bqType ? scope.row.bqType : '/' }}
                        </div>
                    </template>
                </el-table-column> <el-table-column prop="price" label="价格" />

                <el-table-column label="涨跌" width="130">
                    <template #default="scope">
                        <div style="">

                            <span v-if="scope.row.upPrice > 0" style="color: red;">{{ scope.row.upPrice }}↑</span>
                            <span v-if="scope.row.upPrice == 0">{{ scope.row.upPrice }}</span>
                            <span v-else-if="scope.row.upPrice < 0" style="color: green;">{{ scope.row.upPrice
                                }}↓</span>
                            <!-- <span v-else-if="scope.row.upPrice == 0">{{ scope.row.upPrice }}</span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="涨跌百分比" width="130">
                    <template #default="scope">
                        <div style="">
                            <span v-if="scope.row.upPrice > 0" style="color: red;">{{ scope.row.upPercentage }}↑</span>
                            <span v-if="scope.row.upPrice == 0">{{ scope.row.upPercentage }}</span>
                            <span v-if="scope.row.upPrice < 0" style="color: green;">{{ scope.row.upPercentage
                                }}↓</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="addTime" label="报价日期" />



            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20]"
                style="margin: auto;width: 30vw;margin-top: 40px;margin-bottom: 20px;" :page-size="100"
                layout="total, sizes, prev, pager, next, jumper" :total="allNumber">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import *as echarts from 'echarts'
import { getHistoryPriceByCat, getHistoryPriceForDateByCat, getQuotByDate } from '@/api/user.js'

import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
export default {
    data() {
        return {
            new_price: 0,
            quotation_man: '',
            bottom: {
                day_bt_price: '',
                month_bt_price: '',
                week_bt_price: '',
            },
            highest: {
                day_he_price: '',
                month_he_price: '',
                week_he_price: '',
            },
            day: {
                last_day_price: '',
                today_price: '',
            },
            week: {
                last_week_price: '',
                this_week_price: '',
            },
            month: {
                last_month_price: '',
                this_month_price: '',
            },
            newPrice: '',
            currentDate: '',
            months: '',//月
            value1: '',
            value2: '',//年
            begin_time: '',
            end_time: '',
            years: '',
            options: [
                {
                    value: '',
                    label: ''
                },
                {
                    value: '1',
                    label: '一月'
                }, {
                    value: '2',
                    label: '二月'
                }, {
                    value: '3',
                    label: '三月'
                }, {
                    value: '4',
                    label: '四月'
                }, {
                    value: '5',
                    label: '五月'
                },
                {
                    value: '6',
                    label: '六月'
                },
                {
                    value: '7',
                    label: '七月'
                },
                {
                    value: '8',
                    label: '八月'
                },
                {
                    value: '9',
                    label: '九月'
                },
                {
                    value: '10',
                    label: '十月'
                },
                {
                    value: '11',
                    label: '十一月'
                },
                {
                    value: '12',
                    label: '十二月'
                },



            ],
            options2: [
                {
                    value: '',
                    label: ''
                },
                {
                    value: '2022',
                    label: '2022'
                }, {
                    value: '2023',
                    label: '2023'
                }, {
                    value: '2024',
                    label: '2024'
                },



            ],
            tableData: [],
            size: 10,
            page: 1,
            allNumber: 0,
            priceList: [],
            priceList2: [],
            primary1: 'primary',
            primary2: '',
            primary3: '',
            primary4: '',
            primary5: '',
            primary6: '',
        }
    },
    components: {
        searchBar,
        classifyBar,


    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            // return 'warning-row';
            if (rowIndex % 2 == 0) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return
            }
        },
        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以需要+1，并使用padStart确保总是两位数  
            const day = String(now.getDate()).padStart(2, '0'); // 使用padStart确保日期总是两位数  
            this.currentDate = `${year}/${month}/${day}`;
        },
        reset() {
            this.years = ''
            this.end_time = ''
            this.begin_time = ''
            this.months = ''
            this.value1 = ''
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,

            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        yearsChange() {
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        monthsChange() {
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        dataChange(e) {
            console.log(e[0], '....');
            console.log(e[1], '....');
            this.value1 = e
            this.begin_time = e[0]
            this.end_time = e[1]
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        getWeekNumber(date) {
            // Copy the date so don't modify the original  
            var target = new Date(date.getTime());

            // ISO week date weeks start on Monday  
            // so correct the day number  
            var day = target.getDay() || 7;
            target.setDate(target.getDate() - day + 1);

            // Get first day of the year  
            var yearStart = new Date(target.getFullYear(), 0, 1);

            // Calculate full weeks to nearest Thursday  
            var weekNumber = Math.ceil((((target - yearStart) / 86400000 + 1) / 7));

            return weekNumber;
        },
        getPrice1() {
            this.primary1 = 'primary'
            this.primary2 = ''
            this.primary3 = ''
            this.primary4 = ''
            this.primary5 = ''
            this.primary6 = ''

            getHistoryPriceForDateByCat({
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                type: 0,
            }).then((res) => {
                console.log(res, '..dddd');
                let newList = res.data.data.list.map((item) => {
                    return item.days.substring(8)
                })
                let newList2 = res.data.data.list.map((item) => {
                    return item.price
                })

                this.priceList2 = newList2
                this.priceList = newList
                this.getEchartData()
            })
        },
        getPrice2() {
            this.primary1 = ''
            this.primary6 = ''
            this.primary2 = 'primary'
            this.primary3 = ''
            this.primary4 = ''
            this.primary5 = ''
            let currentDate = new Date();
            let weekNumber = this.getWeekNumber(currentDate);
            console.log("Current week number: " + weekNumber);
            getHistoryPriceForDateByCat({
                cat_one: this.$route.query?.catOne,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                type: 1,
            }).then((res) => {
                console.log(res, '..dddd');
                let weekList1 = []
                let weekList2 = []
                res.data.data.list.forEach((item) => {
                    // console.log(item);
                    if (weekNumber < 5) {
                        if (item.weeks <= 5) {
                            weekList1.push(`${item.weeks}周`)
                            weekList2.push(item.price)
                        }
                    } else {
                        if (item.weeks > weekNumber - 5 && item.weeks <= weekNumber) {
                            console.log(item.weeks, '测试。');
                            weekList1.push(`${item.weeks}周`)
                            weekList2.push(item.price)
                        }
                    }
                })

                this.priceList = weekList1
                this.priceList2 = weekList2
                this.getEchartData()
            })
        },
        getPrice3() {
            this.primary1 = ''
            this.primary2 = ''
            this.primary6 = ''
            this.primary3 = 'primary'
            this.primary4 = ''
            this.primary5 = ''
            getHistoryPriceForDateByCat({
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,

                type: 2,
            }).then((res) => {
                console.log(res, '..dddd');
                let newList = res.data.data.list.map((item) => {
                    return item.months
                })
                let newList2 = res.data.data.list.map((item) => {
                    return item.price
                })

                this.priceList2 = newList2
                this.priceList = newList
                this.getEchartData()
            })
        },
        getPrice4() {
            this.primary1 = ''
            this.primary2 = ''
            this.primary3 = ''
            this.primary4 = 'primary'
            this.primary5 = ''
            this.primary6 = ''
            getHistoryPriceForDateByCat({
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,

                type: 3,
            }).then((res) => {
                let newList = res.data.data.list.map((item) => {
                    return `第${item.quarters}季度`
                })
                let newList2 = res.data.data.list.map((item) => {
                    return item.price
                })

                this.priceList2 = newList2
                this.priceList = newList
                this.getEchartData()
            })
        },
        getPrice5() {
            this.primary1 = ''
            this.primary2 = ''
            this.primary3 = ''
            this.primary4 = ''
            this.primary5 = 'primary'
            this.primary6 = ''
            getHistoryPriceForDateByCat({
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
                type: 5,
            }).then((res) => {
                console.log(res, '..dddd');
                let newList = res.data.data.list.map((item) => {
                    return item.years
                })
                let newList2 = res.data.data.list.map((item) => {
                    return item.price
                })

                this.priceList2 = newList2
                this.priceList = newList
                this.getEchartData()
            })
        },
        getPrice6() {
            this.primary1 = ''
            this.primary2 = ''
            this.primary3 = ''
            this.primary4 = ''
            this.primary5 = ''
            this.primary6 = 'primary'
            getHistoryPriceForDateByCat({
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,

                type: 4,
            }).then((res) => {
                console.log(res, '..dddd');
                let newList = res.data.data.list.map((item) => {
                    return item.half
                })
                let newList2 = res.data.data.list.map((item) => {
                    return item.price
                })

                this.priceList2 = newList2
                this.priceList = newList
                this.getEchartData()
            })
        },
        handleSizeChange(e) {
            console.log(e, 'e1');
            this.size = e
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        handleCurrentChange(e) {
            this.page = e
            let params = {
                cat_one: this.$route.query?.catOne,
                cat_two: this.$route.query?.catTwo,
                cat_three: this.$route.query?.catThree,
                cat_four: this.$route.query?.catFour,
                page: this.page,
                size: this.size,
                months: this.months,
                begin_time: this.begin_time,
                end_time: this.end_time,
                years: this.years,
                quotation_man: this.quotation_man,
                area_one: this.$route.query?.area_one,
                area_two: this.$route.query?.area_two,
            }
            getHistoryPriceByCat(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
            console.log(e, 'e2');
        },
        getEchartData() {

            const chart = this.$refs.chart;
            if (chart) {
                const myChart = this.$echarts.init(chart);
                const option = {
                    tooltip: {
                        trigger: 'axis', // 触发类型，可选为'item'、'axis'  
                        axisPointer: {
                            type: 'shadow' // 指示器类型  
                        },
                        formatter: function (params) {
                            // 自定义显示内容，params 是包含当前数据点信息的数组  
                            var value = params[0].value; // 获取当前数据点的值  
                            var seriesName = params[0].seriesName; // 获取系列名称  
                            // 根据需要格式化显示内容  
                            return `价格：${value}`;
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: this.priceList,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#333'
                            },
                            formatter: function (value, index) {
                                // 在这里添加你的单位，例如 'm'  
                                return value + ' 元';
                            }
                        }
                    },
                    series: [

                        {
                            lineStyle: {
                                color: 'rgb(34,134,255)'// 设置线的颜色为红色  
                            },
                            itemStyle: {
                                color: 'rgb(34,134,255)' // 设置数据点的颜色为蓝色  
                            },
                            data: this.priceList2,
                            type: 'line'
                        }
                    ]
                };
                myChart.setOption(option);
            }
        }

    },
    created() {


    },
    mounted() {
        this.quotation_man = this.$route.query?.quotation_man
        this.getCurrentDate();
        getQuotByDate({
            cat_one: this.$route.query?.catOne,
            cat_two: this.$route.query?.catTwo,
            cat_three: this.$route.query?.catThree,
            cat_four: this.$route.query?.catFour,
            area_one: this.$route.query?.area_one,
            area_two: this.$route.query?.area_two,
        }).then((res) => {
            let data = res.data.data
            this.bottom = data.bottom
            this.highest = data.highest
            this.day = data.day
            this.week = data.week
            this.month = data.month
            this.new_price = data.new_price
        })
        getHistoryPriceForDateByCat({
            cat_one: this.$route.query?.catOne,
            cat_two: this.$route.query?.catTwo,
            cat_three: this.$route.query?.catThree,
            cat_four: this.$route.query?.catFour,
            quotation_man: this.quotation_man,
            area_one: this.$route.query?.area_one,
            area_two: this.$route.query?.area_two,

            type: 0,
        }).then((res) => {
            console.log(res, '..dddd');
            let newList = res.data.data.list.map((item) => {
                return item.days.substring(8)
            })
            let newList2 = res.data.data.list.map((item) => {
                return item.price
            })

            this.priceList2 = newList2
            this.priceList = newList
            this.getEchartData()
        })


        // Get the week number  


        let params = {
            cat_one: this.$route.query?.catOne,
            cat_two: this.$route.query?.catTwo,
            cat_three: this.$route.query?.catThree,
            cat_four: this.$route.query?.catFour,
            page: this.page,
            size: this.size,
            months: this.months,
            end_time: this.end_time,
            begin_time: this.begin_time,
            years: this.years,
            quotation_man: this.quotation_man,
            area_one: this.$route.query?.area_one,
            area_two: this.$route.query?.area_two,
        }
        console.log(params, 'params');
        getHistoryPriceByCat(params).then((res) => {
            this.tableData = res.data.data
            this.allNumber = res.data.totalPage
            this.newPrice = res.data.data[0]?.price
        })

    }
}
</script>

<style lang=scss scoped>
::v-deep .el-table .warning-row {
    background-color: #F3F7FC !important;
}

.seePrice {
    height: 180px;
    width: 80%;
    margin: auto;
    padding: 10px 0px;
    display: flex;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    margin-bottom: 20px;

    .seePrice-item {
        width: 110px;
        height: 150px;
        padding: 10px 3px;
        border-right: 1px solid gray;

        .see-box {
            width: 100%;
            height: 28px;
            padding: 6px 14px;
        }
    }
}
</style>