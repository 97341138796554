<template>
  <div class="scroll_table">

    <vue-seamless-scroll :data="MainstreamProductsList" class="seamless-warp" style="width: 100%"
      :class-option="classOption">
      <div class="contaionBox">
        <div class="line1" v-for="(item, index) in MainstreamProductsList" v-if="item.name">
          <div class="top2">{{ item.name }} <span v-if="item.is_up == 0" style="margin-left: 5px;">{{
      item.price }}</span> <span style="color: #21A00C; margin-left: 5px;" v-if="item.is_up == 1">{{ item.price
              }}</span>
            <span style="color: #FF0C0C; margin-left: 5px;" v-if="item.is_up == 2">{{ item.price
              }}</span>
          </div>

          <div class="bottom">
            <div class="left">
              <img src="../assets/quotedPrice/a.png" v-if="item.is_up == 1">
              <img src="../assets/quotedPrice/c.png" v-if="item.is_up == 2">
              <span v-if="item.is_up == 0">
                {{ item.up_price }}
              </span>
              <span style="color: #21A00C" v-if="item.is_up == 1">
                {{ item.up_price }}
              </span>
              <span style="color: #FF0C0C" v-if="item.is_up == 2">
                {{ item.up_price }}
              </span>
            </div>
            <div class="right">
              <span v-if="item.is_up == 0">
                {{ item.up_percentage }}%
              </span>
              <span style="color: #21A00C" v-if="item.is_up == 1">
                {{ item.up_percentage }}%
              </span>
              <span style="color: #FF0C0C" v-if="item.is_up == 2">
                {{ item.up_percentage }}%
              </span>

            </div>
          </div>
        </div>

      </div>
    </vue-seamless-scroll>
  </div>
</template>


<script>
export default {
  name: "ScrollTable",
  data() {
    return {
      tableData2: [],

      columns: [
        { prop: 'goods_name', label: '产品名称', minWidth: '100', sort: true },
        { prop: 'company_name', label: '企业', minWidth: '180', sort: true },
        { prop: 'goods_price', label: '价格', minWidth: '180', sort: true }
      ],
      // columns: [
      //   { prop: 'name', label: '姓名', minWidth: '100', sort: true },
      //   { prop: 'date', label: '日期', minWidth: '180', sort: true },
      //   { prop: 'age', label: '年龄', minWidth: '180', sort: true },
      //   { prop: 'status', label: '状态', minWidth: '180', sort: true },
      //   { prop: 'address', label: '地址', minWidth: '220', sort: true }
      // ],
      tableList: [

      ],
      MainstreamProductsList: []
      // tableList: [
      //   {
      //     goods_name: '1',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '2',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '3',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '4',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '5',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '6',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '7',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '8',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '9',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },{
      //     goods_name: '0',
      //     company_name: '2019-09-25',
      //     name: '张三'
      //   },
      // ]
    }
  },
  methods: {
    //最新报价
    async newQuotedPrice() {
      let res = await this.$http('/mini/ApiConnect/newQuotedPrice')

      this.newQuotedPriceData = res.result[0].add_time
      this.tableList = res.result

      // res.result.forEach((e, index) => {
      //   let obj = {};
      //   obj.goods_name = e.goods_name
      //   obj.company_name = e.company_name
      //   obj.goods_price = e.shop_price
      //   obj.goods_id = e.goods_id


      // })

    },

    //分类主流产品市场价格
    async OneMainstreamProducts() {
      let params = {
        cat_one: this.cat_one,
        cat_two: this.cat_two,
        cat_three: this.cat_three
      }
      let res = await this.$http('/mini/ApiConnect/OneMainstreamProducts', params)
      this.MainstreamProductsList = res.result
    },
  },
  computed: {
    classOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  mounted() {
    // this.OneMainstreamProducts()
  }
}
</script>

<style lang="scss" scoped>
.contaionBox {
  margin-top: 21px;
  width: 989px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .line1 {
    width: 238px;
    height: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e4e4e4;

    .top2 {
      font-size: 24px;
      text-align: center;
      width: 238px;
    }

    .bottom {
      width: 238px;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #21A00C;

      .left {
        padding: 0 10px;
        border-right: 2px solid #e4e4e4;
      }

      .right {
        padding: 0 10px;
      }
    }

    .bottom2 {
      margin-top: 4px;
      display: flex;
      align-items: center;
      color: #FF0C0C;

      .left {
        padding: 0 10px;
        border-right: 2px solid #e4e4e4;
      }

      .right {
        padding: 0 10px;
      }
    }
  }

  .line1:last-child {
    border-right: none;

  }
}

.scroll_table {
  margin: 15px;
  display: flex;

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  .seamless-warp {
    height: 200px;
    width: 100%;
    overflow: hidden;

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}
</style>
