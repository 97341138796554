<template>
    <div>
        <transition name="dialog-fade">
            <div v-if="visible" class="my-dialog-overlay">
                <div>

                    <!-- <div style="margin-left: 610px;" @click="close"> <i class="el-icon-error" size="small"></i></div> -->
                    <div style="margin-left: 650px;cursor: pointer;" @click="close"> <i class="el-icon-error"
                            size="small"></i></div>

                    <img :src="imgUrl" style="width: 650px;height: 201px;    cursor: pointer;margin-bottom: 300px;"
                        alt="" @click="goEnterprise">

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { addMember, getStatusByOrderSn } from '../api/user'
export default {
    data() {
        return {
            visible: false,
            total_amount: '0.01',
            order_description: 0,
            wangNumber: 1,
            timer: '',
            routeUrl: '',

        }
    },
    // props: ['imgUrl'],
    methods: {
        goEnterprise() {
            if (this.routeUrl) {
                this.$router.push({
                    path: this.routeUrl
                })
            } else {
                this.visible = false
            }

        },
        changeUrl() {
            console.log(this.imgUrl, 'imgUrl22222');

        },
        chufa() {

        },
        close() {
            this.visible = false
            clearInterval(this.timer)
        }
    },
    created() { },
    mounted() {
        console.log(this.imgUrl, 'imgUrl1111');
        // addMember({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
        //     console.log(res, 'dddd');

        //     new QRCode(this.$refs.qrcode, {
        //         text: res.data.data.codeUrl,
        //         width: 158,
        //         height: 158
        //     });

        // })
    }
}
</script>

<style lang=scss scoped>
.my-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}


.my-dialog {
    // background-color: pink;
    background: url('../assets/newImg2/84652697ca554fa9636206cbd849f43.png') no-repeat center center;
    // background-image: url('../assets/newImg2/Gold wave abstract background illustration@1x.png');
    // background-size: 100% 100%;
    // background-color: pink;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    width: 1060px;
    height: 500px;
    display: flex;
    z-index: 99;
    cursor: pointer;

    // background-repeat: no-repeat;
    /* 或者其他宽度 */
    .dialog1 {
        flex: 1;
        position: relative;
        box-sizing: content-box;

        .dialog1-img {
            width: 73%;
            height: 81%;
            position: absolute;
            right: 20px;
            bottom: 60px;
            border-radius: 16px;


        }
    }

    .dialog2 {
        flex: 1;
        position: relative;
        box-sizing: content-box;

        .dialog2-img {
            border-radius: 16px;
            width: 73%;
            height: 81%;
            position: absolute;
            right: 40px;
            bottom: 60px;

        }
    }

    .dialog3 {
        flex: 1.3;

        .dialog3-img {
            width: 100%;
            height: 400px;
            margin-top: 50px;
        }
    }
}

.my-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-dialog-body {
    /* 样式 */
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
    // transition: opacity 0.3s;
}

.dialog-fade-enter,
.dialog-fade-leave-to

/* .dialog-fade-leave-active in <2.1.8 */
    {
    // opacity: 0;
}

.bgc {
    border: 3px solid yellow;
}
</style>