<template>
    <div class="content" style="margin-top: 100px;">
        <!-- <searchBar /> -->
        <!-- <div class="line"></div> -->
        <!-- <div class="classifyBox">
            <classifyBar />

        </div> -->

        <!-- <div class="location">
            <span>当前位置：</span>
            <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/consult' }">行业资讯</el-breadcrumb-item>
                </el-breadcrumb></div>
        </div> -->
        <div>
            <el-carousel height="365px" width="1200px" arrow="always">
                <el-carousel-item>
                    <img src="../assets/zhnlImg/1.png" style="width: 1200px; height: 365px" />
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/zhnlImg/轮播图 大宗原材料商城，供应链金融 820x355 20240516.png"
                        style="width: 1200px; height: 365px" />
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/zhnlImg/轮播图 中海南联原材料商城 820x355 20240516.png"
                        style="width: 1200px; height: 365px" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="contaion">
            <div class="left">
                <div class="line2">
                    <div class="top">
                        <div class="title" style="font-weight: 900;font-size: 22px;padding-left: 3px;">行业资讯</div>
                    </div>
                    <div class="theLine"></div>

                    <div class="contaion3">
                        <div class="contaion3-item" @click="skip(item)"
                            v-for="(item, index) in IndustryInformationList">
                            <div class="con-left">
                                <img :src="item.imgUrl" style="width: 100%;height: 100%;" alt="">
                            </div>
                            <div class="con-right">
                                <div style="font-weight: 900;padding: 8px;font-size: 19px;">{{ item.bgcTitle }}</div>
                                <div class="con-right-middel">{{ item.bgcContent.replace(/<[^>]*>/g, '') }}</div>
                                <div style="display: flex;padding: 5px 5px 5px 8px;color: gray;font-size: 14px;"
                                    class="con-bottom">
                                    <div>
                                        <img src="../assets/zhnlImg/Clip path group@1x.png"
                                            style="vertical-align: middle;margin-right: 2px;" alt="">
                                        <span style="vertical-align: middle;">{{ item.addTime }}</span>
                                    </div>
                                    <div style="margin-left: 20px;">
                                        <span style="vertical-align: middle;">阅读量:{{ item.readNum }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="info" @click="skip(item)" v-for="(item, index) in IndustryInformationList">
                            <div class="yuan"></div>
                            <div class="title">
                                {{ item.bgcTitle }}
                            </div>
                            <div class="timer">[{{ item.addTime }}]</div>
                        </div> -->

                    </div>
                </div>
            </div>

            <div class="right">
                <!-- <div class="region">
                    <div class="top3">
                        <div class="top">
                            <div class="title">甲苯价格中心导航</div>
                        </div>
                        <div class="theLine"></div>

                        <div class="contaion">
                            <div class="left2">
                                <div class="btn" @click="marketChekout(1)" :class="{ 'btnBgc': marketChekoutFlag == 1 }">
                                    公司出厂
                                </div>
                                <div class="btn" @click="marketChekout(2)" :class="{ 'btnBgc': marketChekoutFlag == 2 }">
                                    国内市场
                                </div>
                                <div class="btn" @click="marketChekout(3)" :class="{ 'btnBgc': marketChekoutFlag == 3 }">
                                    国际市场
                                </div>
                            </div>

                            <div class="right2">
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                                <div class="region2"><span style="color: #006EB7;">华东</span>地区</div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="strictSelection">
                    <div class="top">
                        <div class="title"
                            style="background-image: linear-gradient(rgba(210, 228, 255), rgba(255, 255, 255));">产品严选
                        </div>
                    </div>
                    <div class="theLine"></div>

                    <div class="productBox">
                        <div class="product" v-for="(item, index) in productsList" @click="skip2(item.goodId)">
                            <img :src="item.goodsOriginalImg" style="object-fit: cover; width: 156px; height: 156px;">
                            <div class="infoBox">
                                <div class="title" style="font-size: 16px;font-weight: 900;">{{ item.goodsName }}</div>
                                <div>
                                    <div style="color: red;font-size: 13px;">￥{{ item.goodsPrice }}</div>
                                    <div style="font-size: 12px;color: gray;margin-top: 1px;">浏览量:{{ item.clickCount }}+
                                    </div>
                                </div>
                                <!-- <div class="bottom">
                                    <div class="left" style="width: 45%;">
                                        <div class="one">¥ {{ item.goodsPrice }}</div>
                                    </div>
                                    <div class="right"
                                        style="width: 50%; font-size: 12px; height: 16px; overflow: hidden; display: flex; align-items: center; justify-content: flex-end; color: #AEAEAE;">
                                        浏览 <span style="color: #006EB7; margin-left: 5px; "> {{ item.clickCount
                                            }}+</span>
                                    </div>
                                </div> -->
                                <!-- <div class="bottom2">
                                        <div class="address">广东中海南...</div>
                                        <div class="location2">广东广州</div>
                                    </div> -->
                            </div>
                        </div>


                    </div>

                </div>

            </div>


        </div>

        <div class="page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15]"
                style="margin: auto;width: 30vw;margin-top: 40px;margin-bottom: 20px;" :page-size="100"
                layout="total, sizes, prev, pager, next, jumper" :total="total_page">
            </el-pagination>
            <!-- 当前页数：{{ this.page }} -->
        </div>
    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import { getAllGoodsConsult, getBstGoodsGategoryById } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            allNumber: 0,
            marketChekoutFlag: 1,
            IndustryInformationList: [],
            productsList: '',
            total_page: '',
            page: 1,
            size: 10
        }
    },
    methods: {
        handleSizeChange(e) {
            console.log(e, 'e1');
            this.size = e
            this.IndustryInformation()
        },
        handleCurrentChange(e) {
            this.page = e
            this.IndustryInformation()
        },
        marketChekout(num) {
            this.marketChekoutFlag = num
            console.log(this.marketChekoutFlag)
        },

        nextclick() {
            this.page = this.page + 1
            // this.getUserProduct()
            this.IndustryInformation()
        },

        prevclick() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.IndustryInformation()
            }
        },

        currentChange(e) {
            this.page = e
            this.IndustryInformation()
        },

        skip(item) {

            const { href } = this.$router.resolve({
                path: "/titleDetails",
                query: { id: item.bgcId },
            });
            window.open(href, "_blank");
        },

        skip2(id) {
            const { href } = this.$router.resolve({
                path: "/detailsPage",
                query: { goods_id: id },
            });
            window.open(href, "_blank");

        },

        //行业资讯
        async IndustryInformation() {
            let params2 = {
                cat_one: '',
                cat_two: '',
                cat_three: '',
                industry: '',
                industry2: '',
                size: this.size,
                page: this.page
            }
            getAllGoodsConsult(params2).then((res) => {
                this.total_page = res.data.totalPage
                res.data.data.forEach((item, index) => {
                    item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
                })

                this.IndustryInformationList = res.data.data
            })
            // let params = {
            //     article_type: 10,
            //     p: this.p,
            //     num: 20
            // }
            // let res = await this.$http('/mini/ApiConnect/IndustryInformation', params)
            // this.total_page = res.total_page
            // this.IndustryInformationList = this.IndustryInformationList.concat(res.result)
        },

        //严选产品
        async StrictSelectionOfProducts() {
            getBstGoodsGategoryById({ type: 3, page: this.page, size: 6 }).then((res) => {
                this.productsList = res.data.data.slice(0, 6)

            })
            // let params = {
            //     category_id: 0,
            //     page: 0
            // }
            // let res = await this.$http('/mini/ApiConnect/StrictSelectionOfProducts', params)
            // this.productsList = ""
            // this.productsList = res.result.slice(0, 4)
        },
    },
    mounted() {
        this.IndustryInformation()
        this.StrictSelectionOfProducts()
        // let btn = document.getElementsByTagName('button')

        // setTimeout(() => {
        //     for (let i = 0; i < btn.length; i++) {
        //         btn[i].style.cursor = 'pointer'

        //     }
        // }, 1000)

    },
    components: {
        searchBar,
        classifyBar,

    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.btnBgc {
    background-color: #006EB7 !important;
    color: white !important;
    border: none !important;
}

.content {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 50%;
    transform: translateX(-50%);
}

.info :hover {
    color: #006EB7 !important;

}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: -10px;
    display: flex;
    align-items: center;

    .nav {}
}

.contaion {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .left {
        width: 830px;

        .line2 {
            width: 100%;
            background-color: white;
            // padding: 16px;
            // margin-right: 24px;
            box-sizing: border-box;

            .top {
                display: flex;
                align-items: center;
                height: 55px;
                background-image: linear-gradient(rgba(210, 228, 255), rgba(255, 255, 255));


                .title {
                    font-size: 16px;
                    // border-left: 3px solid #006EB7;
                    padding-left: 5px;
                    margin-left: 18px;
                }

                .date {
                    font-size: 10px;
                    color: #AEAEAE;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                margin-top: 7px;
            }

            .contaion3::-webkit-scrollbar {
                width: 8px;
            }

            .contaion3::-webkit-scrollbar-thumb {
                border-radius: 20px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            .contaion3::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);

            }

            .contaion3 {
                margin-top: 8px;
                width: 100%;
                height: 713px;
                overflow: scroll;
                overflow-x: hidden;



                .contaion3-item {
                    height: 135px;
                    width: 100%;
                    display: flex;
                    margin-bottom: 10px;
                    cursor: pointer;

                    .con-left {
                        width: 30%;
                        height: 100%;
                    }

                    .con-right {
                        width: 68%;
                        height: 100%;

                        .con-bottom:hover {
                            color: rgb(32, 123, 255) !important;

                        }

                        .con-right-middel {
                            padding: 0px 5px 5px 8px;
                            height: 60px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                        }

                        .con-right-middel:hover {
                            color: rgb(32, 123, 255) !important;

                        }


                    }
                }

                .info {
                    // margin: 11px 0;
                    margin-top: 20px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    .yuan {
                        width: 8px;
                        height: 8px;
                        border-radius: 20px;
                        background: transparent;
                        border: 1px solid #006eb7;
                        position: absolute;
                        left: 0;
                        margin-left: 10px;
                    }

                    .title {
                        margin-left: 25px;
                        font-family: 'SourceSansPro';
                        color: #000000;
                    }

                    .timer {
                        color: #000000;

                    }
                }
            }
        }
    }

    .right {
        width: 350px;

        // height: 1000px;
        .region {
            width: 100%;
            height: 246px;

            .top3 {
                width: 100%;
                height: 100%;
                background-color: white;
                border-radius: 4px;
                padding: 16px;
                // margin-right: 24px;
                box-sizing: border-box;

                .top {
                    display: flex;
                    align-items: center;

                    .title {
                        margin-left: 10px;
                        font-size: 16px;
                        // border-left: 3px solid #006EB7;
                        padding-left: 5px;
                    }

                    .date {
                        font-size: 10px;
                        color: #AEAEAE;
                        margin-left: 6px;
                    }
                }

                .theLine {
                    height: 2px;
                    background-color: #e4e4e4;
                    margin-top: 7px;
                    margin-bottom: 5px;
                }

                .contaion {
                    width: 100%;
                    padding: 17px 0;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .left2 {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .btn {
                            margin-bottom: 20px;
                            width: 81px;
                            height: 28px;
                            border-radius: 14px;
                            border: 1px solid #006eb7;
                            color: #006eb7;
                            box-sizing: border-box;
                            text-align: center;
                            line-height: 28px;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .btnBgc {
                            background-color: #006EB7 !important;
                            color: white !important;
                            border: none !important;
                        }
                    }

                    .right2 {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;

                        .region2 {
                            width: 33.3%;
                            text-align: center;
                            font-size: 14px;
                            letter-spacing: 2.39px;
                            cursor: pointer;
                        }

                        .region2:first-child {
                            margin-bottom: 44px;
                        }

                    }
                }

            }
        }

        .strictSelection {
            width: 100%;
            // height: 627px;
            background-color: white;
            // padding: 12px;
            box-sizing: border-box;
            overflow: hidden;

            .top {
                display: flex;
                align-items: center;

                .title {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 900;
                    padding-left: 18px !important;
                    padding-top: 15px; // margin-left: 10px;
                    // border-left: 3px solid #006EB7;
                    padding-left: 5px;
                    height: 29px;
                    font-size: 20px;
                    padding-bottom: 16px;
                }

                .date {
                    font-size: 10px;
                    color: #AEAEAE;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                // margin-top: 7px;
                // margin-bottom: 5px;
            }


            .productBox {
                display: flex;
                flex-wrap: wrap;
            }

            .product:nth-child(2n) {
                margin-left: 12px !important;
            }

            .product {
                overflow: hidden;
                width: 157px;
                margin-top: 13px;
                padding-bottom: 10px;
                border: 0.87px solid #ddd;
                box-sizing: border-box;
                cursor: pointer;

                .infoBox {
                    padding: 0 0px 0px 5px;

                    background-color: white;

                    .title {
                        font-size: 10px;
                        height: 22px;
                        overflow: hidden;
                    }

                    .bottom {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        padding: 0 5px;
                        box-sizing: border-box;
                    }

                    .bottom2 {
                        width: 103%;
                        font-weight: 400;
                        font-size: 8.72px;
                        line-height: 10.2px;
                        text-align: right;
                        color: #aeaeae;
                        display: flex;
                        justify-content: space-between;
                        border-top: 0.87px solid #ddd;
                        border-bottom: 0.87px solid #ddd;
                        padding: 0 10px;
                        box-sizing: border-box;
                        margin-top: 8px;
                        margin-left: -5px;

                        .address {
                            padding: 10px 0;
                        }

                        .location2 {
                            padding: 10px 0;

                        }
                    }

                    .left {
                        display: flex;
                        align-items: flex-end;
                        margin-top: 11px;


                        .one {
                            font-size: 14px;
                            font-weight: 700;
                            color: #FF0000;
                        }

                        .two {
                            font-size: .1em;
                            margin-left: 3px;
                            color: #AEAEAE;
                        }
                    }

                    .right3 {
                        font-size: 8px;
                    }
                }
            }
        }
    }

    .page {}
}

::v-deep .el-pager li {
    width: 56px !important;
    height: 35px !important;
    line-height: 35px !important;
}

::v-deep .el-pagination .btn-prev {
    width: 56px !important;
    height: 35px !important;
}

::v-deep .el-pagination .btn-next {

    width: 56px !important;
    height: 35px !important;
}

::v-deep .el-pagination .btn-prev {
    padding-right: 0 !important;
}

::v-deep .btn-next {
    padding-left: 0 !important;
}

::v-deep .el-pagination {
    margin-top: 10px;
    padding-left: 0 !important;
}
</style>