<template>
    <div>
        <div class="quotedBox">
            <div class="myoder">我的订单</div>
            <div class="title">

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane style="font-size: 14px;" label="全部" name="first"></el-tab-pane>
                    <el-tab-pane style="font-size: 14px;" label="未付款" name="second"></el-tab-pane>
                    <el-tab-pane style="font-size: 14px;" label="待发货" name="third"></el-tab-pane>
                    <el-tab-pane style="font-size: 14px;" label="已发货" name="fourth"></el-tab-pane>
                    <el-tab-pane style="font-size: 14px;" label="已完成" name="five"></el-tab-pane>
                    <el-tab-pane style="font-size: 14px;" label="退换货" name="six"></el-tab-pane>
                </el-tabs>

                <!-- <div class="put">
                    <div class="inputBar flexRowCenter">
                        <el-input v-model="search" placeholder="请输入内容" prefix-icon="el-icon-search"></el-input>

                    </div>
                </div> -->
            </div>

            <div class="line1" v-for="(item2, index) in OrderList">
                <div class="left">
                    <div class="commodityBox">
                        <div class="left">
                            <img :src="item2.original_img" style="width: 133px; height: 133px;">
                        </div>
                        <div class="right">
                            <div class="commodityTitle">
                                {{ item2.goods_name }}
                            </div>


                            <div class="serve" v-if="item2.goods_sn">
                                <span>商品号:</span>
                                <span style="margin-left: 20px;">{{ item2.goods_sn }}</span>
                            </div>

                            <div class="serve" v-if="item2.order_sn">
                                <span>订单编号:</span>
                                <span style="margin-left: 20px;">{{ item2.order_sn }}</span>
                            </div>


                            <div class="numBox">
                                <span>数量:</span>
                                <div class="numBtn">
                                    x {{ item2.goods_num }}
                                </div>
                            </div>

                            <div
                                style="width: 435%; position: relative;display: flex;justify-content: space-between;align-items: center;">
                                <div class="price" v-if="item2.goods_price">
                                    <span style="font-size: 16px; color: #ff0c0cff;">￥</span>
                                    <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">{{ item2.goods_price
                                        }}</span>
                                    <span style="font-size: 12px; color: #ff0c0cff;">/{{ item2.spec_key_name }}</span>
                                </div>

                                <!-- <div class="price" v-if="item2.order_amount">
                                    <span style="font-size: 16px; color: #ff0c0cff;">￥</span>
                                    <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">{{ item2.order_amount
                                    }}</span>
                                    <span style="font-size: 12px; color: #ff0c0cff;">/{{ item2.spec_key_name }}</span>
                                </div> -->

                                <div style=" width: 260px;position: absolute;right: 0; display: flex;justify-content: space-between;align-items: ;"
                                    v-if="item2.type_name == '已取消' || item2.type_name == '退款退货'">
                                    <div class="price" style="color: black;" v-if="item2.total_amount">总价格：<span
                                            style="font-size: 18px; color: #FF0C0C;">￥</span><span
                                            style="font-size: 28px; color: rgb(255, 12, 12);"
                                            v-if="item2.total_amount">{{
                    item2.total_amount
                }}</span>
                                    </div>

                                    <div class="rrt"
                                        @click="skipPage('/buyerCenter/orderInfo?orderId=' + item2.order_id)">
                                        查看详情
                                    </div>
                                </div>
                            </div>


                            <div class="btn1" v-if="item2.type == 4"
                                @click="skip(item2.goods_id, item2.order_id, item2.rec_id)">
                                立即评价
                            </div>
                        </div>
                    </div>

                </div>

                <div class="right2" v-if="item2.type_name != '换货'">
                    <div class="status" style="position: absolute; top: 15px; right: 20px;">订单状态：{{ item2.orStatus }}
                    </div>
                    <div v-if="item2.type_name != '已取消' && item2.type_name != '退款退货'">
                        <div class="status" style="color: black;" v-if="item2.total_amount">总价格：<span
                                style="font-size: 28px; color: rgb(255, 12, 12);" v-if="item2.total_amount">{{
                    item2.total_amount
                }}</span>
                        </div>
                    </div>


                    <div class="status" style="color: black;" v-if="item2.refund_money">退返价格：

                        <span style="font-size: 28px; color: rgb(255, 12, 12);" v-if="item2.refund_money">{{
                    item2.refund_money }}</span>
                    </div>

                    <div class="statusBtn">

                        <div v-if="!item2.type_name == '已取消' || !item2.type_name == '退款退货'" class="btn1"
                            @click="skipPage('/buyerCenter/orderInfo?orderId=' + item2.order_id)">
                            查看详情
                        </div>

                        <!-- <el-dialog title="微信支付" :visible.sync="centerDialogVisible" width="30%" center>
                            <div
                                style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <img :src="payinfo.payinfo" style="width: 200px; height: 200px;" alt="">
                                <span style="font-size: 16px;">支付单号: 202304121540012866</span>
                                <span style="font-size: 16px;">支付金额: <span
                                        style="font-size: 22px; color: rgb(255, 12, 12); margin-top: 20px;"> {{
                                            payinfo.order_amount }}</span></span>
                            </div>


                        </el-dialog> -->


                        <div class="btn1" v-if="item2.type == 1" @click="play(item2.order_id)">
                            立即支付
                        </div>
                        <div class="btn1"
                            v-if="item2.type != 1 && item2.type != 5 && item2.type != 6 && item2.type != 2"
                            @click="skipPage('/buyerCenter/afterSale?orderId=' + item2.order_id)">
                            申请售后
                        </div>

                        <div class="btn1" v-if="item2.order_status == 0" @click="affirm(item2.order_id)">
                            确认收货
                        </div>
                        <!-- <div class="btn1" @click="skipPage('/buyerCenter/orderInfo?orderId=' + item2.order_id + '&type=3')" v-if="item2.type_name == '退款退货'">
                           退换货详情
                        </div> -->

                        <div class="btn1" v-if="item2.type == 1 || item2.type == 2"
                            @click="cancelOrder(item2.order_id)">
                            取消订单
                        </div>
                    </div>
                </div>

                <div class="right2" v-if="item2.type_name == '换货'">
                    <div class="status" style="position: absolute; top: 15px; right: 0;">订单状态：{{ item2.type_name }}
                    </div>

                    <div class="statusBtn">
                        <div class="btn1"
                            @click="skipPage('/buyerCenter/orderInfo?orderId=' + item2.order_id + '&type=3')">
                            退换货详情
                        </div>
                        <!-- <div class="btn1" @click="skipPage('/buyerCenter/orderInfo?orderId=' + item2.order_id)">
                            查看详情
                        </div> -->

                    </div>
                </div>
            </div>
            <el-empty description="暂无商品" v-if="OrderList.length < 1"></el-empty>

        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'
import { getOrderByUserId } from '@/api/user'
export default {
    data() {
        return {
            activeName: 'first',
            type: 0,
            search: '',
            OrderList: '',
            centerDialogVisible: false,
            payinfo: '',
            timer: '',
            pay_status: '',
            shipping_status: '',
            order_status: '',
            page: 1,
            size: '',

        }
    },
    methods: {
        handleClick(tab, event) {
            this.type = tab.index
            console.log(this.type, 'type');
            if (this.type == 0) {
                this.getOrderList('', '', '')
            } else if (this.type == 1) {
                this.getOrderList(0, '', '')
            } else if (this.type == 2) {
                this.getOrderList('', 0, '')
            } else if (this.type == 3) {
                this.getOrderList('', 1, '')

            } else if (this.type == 4) {
                this.getOrderList('', '', 1)

            } else if (this.type == 5) {
                this.getOrderList('', '', 7)

            }
            // if (this.type == 5) {
            //     this.type = 6
            //     this.UserOrderList()
            // } else {
            //     this.UserOrderList()

            // }
        },
        getOrderList(pay_status, shipping_status, order_status) {
            let params = {
                // user_id: window.localStorage.getItem('user_id'),
                user_id: '659',
                pay_status: pay_status,
                shipping_status: shipping_status,
                order_status: order_status,
                page: this.page,
                size: 5
            }
            console.log(params, 'params');
            getOrderByUserId(params).then((res) => {
                console.log(res, '得出了');
                this.OrderList = res.data.data
                this.OrderList.forEach((item) => {
                    if (item.order_status == 0) {
                        item.orStatus = '未确认'
                    } else if (item.order_status == 1) {
                        item.orStatus = '已确认'
                    } else if (item.order_status == 2) {
                        item.orStatus = '已收货'
                    } else if (item.order_status == 3) {
                        item.orStatus = '已取消'
                    } else if (item.order_status == 4) {
                        item.orStatus = '已完成'
                    } else if (item.order_status == 5) {
                        item.orStatus = '已作废'
                    } else if (item.order_status == 6) {
                        item.orStatus = '退款退货'
                    } else if (item.order_status == 7) {
                        item.orStatus = '换货'
                    }
                })

            })
        },

        skipPage(page) {
            this.$router.push(page)
        },
        skip(goods_id, orderId, rec_id) {
            this.$router.push('/buyerCenter/evaluate?goodId=' + goods_id + '&orderId=' + orderId + '&recId=' + rec_id)
        },
        play(orderId) {
            this.PayOrder(orderId)
            window.location = '/play?orderId=' + orderId
        },

        affirm(orderId) {
            this.UserOrderAffirm(orderId)
        },

        cancelOrder(order_id) {
            this.AllOrderOut(order_id)
        },

        //检测订单是否支付
        async checkOrderPaySuccess(order_id) {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: order_id
            }
            let res = await this.$http('/mini/ApiConnect/checkOrderPaySuccess', params)
            if (res.status == 1) {
                clearInterval(this.timer)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
            } else {
            }

        },

        //订单列表
        async UserOrderList() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                type: this.type,
                size: 25
            }
            let res = await this.$http('/mini/ApiConnect/UserOrderList', params)
            this.OrderList = ''
            this.OrderList = res.result
        },

        //PC端支付
        async PayOrder(orderId) {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                order_id: orderId,
                weixin_type: "PC"
            }
            let res = await this.$http('/mini/ApiConnect/MiniPayOrder', params)
            this.payinfo = res
            this.timer = setInterval(() => {
                this.checkOrderPaySuccess(orderId)
            }, 3000);
        },

        //确认收货
        async UserOrderAffirm(orderId) {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: orderId
            }
            let res = await this.$http('/mini/ApiConnect/UserOrderAffirm', params)

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload()

                }, 3000)
            } else {
                this.$message.error(res.msg);
            }
        },


        //退换货处理中
        async OrderRetundList() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                status: 1
            }
            let res = await this.$http('/mini/ApiConnect/OrderRetundList', params)
            this.OrderList = ''
            this.OrderList = res.result
        },

        //取消订单
        async AllOrderOut(orderId) {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: orderId,
            }
            let res = await this.$http('/mini/ApiConnect/AllOrderOut', params)

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload()

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }
        },
    },

    mounted() {
        // if (getParams().type == 2) {
        //     this.type = getParams().type
        //     this.activeName = 'third'
        // }

        // if (getParams().type == 5) {
        //     this.type = 6
        //     this.activeName = 'six'
        // }
        this.handleClick({ index: 0 })
        // this.UserOrderList()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    // background-color: white;

    .myoder {

        width: 100%;
        // height: 18px;
        opacity: 1;
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        font-family: "Microsoft YaHei UI";
        text-align: left;
        line-height: 18px;
        // padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 2px solid #e9e9e9;
        background-color: white;
        padding: 10px;

    }

    .title {
        font-size: 24px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 0 10px;


        ::v-deep .el-tabs__item {
            font-size: 14px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }

        .put {
            width: 350px;
            margin-top: -14px;
            display: flex;
            align-items: center;

            .inputBar {
                width: 95%;
                height: 42px;
                border-radius: 500px;

                .el-color-picker__icon,
                .el-input,
                .el-textarea {
                    // border: 1px solid #006eb7;
                    box-sizing: border-box;

                }

                .el-input__inner:focus {
                    // el-input输入时设置边框颜色
                    // border: #006eb7;
                    box-sizing: border-box;

                }

                .el-input__icon {
                    line-height: 32px;
                }

            }

            ::v-deep .el-input--prefix .el-input__inner {
                height: 39px;
                border-radius: 300px !important;
                border: none !important;
                background-color: #F2F2F2;

            }

            ::v-deep .el-input--prefix {
                height: 42px;
                border: none;
                border-radius: 500px;
            }

            .btn {
                width: 109px;
                height: 32px;
                background-color: #006EB7;
                text-align: center;
                line-height: 32px;
                color: white;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    .line1 {
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        .left {
            display: flex;
            flex-direction: column;
        }

        .right2 {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            margin-top: 20px;

            .status {
                font-size: 16px;
                color: #006EB7;
                margin-bottom: 10px;
                margin-left: 3px;
            }

            .statusBtn {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .btn1 {
                    width: 120px;
                    height: 40px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    margin-left: 20px;
                    border-radius: 100px;
                    cursor: pointer;
                }
            }
        }

        .commodityBox {
            margin-top: 18px;
            display: flex;

            .collectBox {
                width: 80px;
                height: 25px;
                color: white;
                text-align: center;
                background-color: #006EB7;
                line-height: 25px;
                margin-top: 10px;
                cursor: pointer;
            }

            .right {
                margin-left: 23px;
                position: relative;

                .commodityTitle {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23.4px;
                    text-align: left;
                    color: #000;
                }

                .price {
                    margin-top: 19px;
                }

                .rrt {
                    margin-top: 19px;
                    width: 80px;
                    height: 28px;
                    border-radius: 33px;
                    opacity: 1;
                    border: 1px solid #006eb7;
                    color: #006eb7;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Microsoft YaHei UI";
                    text-align: center;
                    line-height: 28px;
                }

                .btn1 {
                    width: 120px;
                    height: 40px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    margin-left: 20px;
                    border-radius: 100px;
                    cursor: pointer;
                    position: absolute;
                    right: -597px;
                    bottom: 0;
                }

                .serve {
                    font-size: 16px;
                    color: #898989;
                    margin-top: 10px;
                }

                .btnBox {
                    display: flex;

                    .btn1 {
                        width: 200px;
                        height: 64px;
                        border-radius: 4px;
                        background: #006eb7;
                        text-align: center;
                        line-height: 64px;
                        color: white;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-top: 30px;
                    }
                }

                .checkOutImg {
                    display: flex;
                    align-items: center;
                    margin-top: 18px;

                    .box {
                        width: 82px;
                        height: 82px;
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .box :last-child {
                        margin-right: 0px;

                    }

                    .imgBorder {
                        border: 5px solid #006EB7;
                        box-sizing: border-box;
                    }
                }

                .numBox {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .numBtn {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;

                        .min {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;
                        }

                        .max {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;

                        }

                        .put {
                            height: 30px;
                            width: 60px;
                        }

                        ::v-deep .el-input__inner {
                            height: 30px !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }

                }
            }
        }
    }

}
</style>