<template>
    <div>
        <div class="edit">

            <div style="height: 2.9rem;display: flex;position: relative; ">
                <div style="color:#227BFF;font-size: 26px;margin-left: 5px;width: 17%;">原材料最新视频</div>
                <div style="width: 75%;display: flex;margin-left: 9px;">
                    <div style="font-size: 16px;color: #333333;font-weight: 290;height: 20px;padding: 8px 11px;cursor: pointer;"
                        v-for="item in areaCategoryList2.slice(0, 12) " :class="{ bgc: wangNumber == item.gId }"
                        @click="numberChange(item)">{{ item.label }}</div>
                </div>
                <div
                    style="vertical-align: middle;line-height: 3.1rem;margin-right: 1px;cursor: pointer;width: 80px;position: absolute;right:10px">
                    <!-- <img src="../assets/newImg/Vector@1x (3).png" alt="" style="margin-right: 10px;"
                        @click="reduceNumber" v-show="videoNumber <= 1">
                    <img src="../assets/newImg/左箭头 黑.png" alt="" style="margin-right: 10px;" v-show="videoNumber > 1"
                        @click="reduceNumber">
                    <img src="../assets/newImg/Vector@1x (1).png" alt="" @click="addNumber"
                        v-show="videoNumber < allPage">
                    <img src="../assets/newImg/右箭头淡色.png" style="width: 21px;height: 20px;" alt=""
                        v-show="videoNumber >= allPage"> -->





                </div>
            </div>
            <div class="edit-box">
                <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
                    <div style="position: relative;">
                        <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;"> {{
                            item.vAddTime.slice(0, 10) }}
                        </div>
                        <img :src="item.vImgUrl"
                            style="width: 100%;height: 116px;object-fit: cover;border-radius: 12px;" alt="">
                    </div>
                    <div
                        style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        {{ item.vTitle }}</div>
                    <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                        <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                            style="vertical-align: middle;margin-right: 1px;" alt="">

                        <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAllVod } from '@/api/user'

export default {
    data() {
        return {
            videoList2: [],
            videoNumber: 1,
            totalPage: 0,
            allPage: 0,
            wangNumber: 0,
        }
    },
    props: ['areaCategoryList2', 'titleName'],

    methods: {
        toVedio(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,

                },
            });
            window.open(href, "_blank");
        },
        numberChange(item) {
            this.allPage = 0
            this.videoNumber = 1
            this.wangNumber = item.gId
            console.log(this.wangNumber, 'gou');

            let params2 = {
                cat_one: this.wangNumber,
                page: this.videoNumber,
                size: 14,
            }
            getAllVod(params2).then((res) => {
                this.videoList2 = res.data.data
                this.totalPage = res.data.totalPage
                this.allPage = Math.ceil(res.data.totalPage / 14)

            })
        },
        addNumber() {
            if (this.totalPage < 14) {
                return
            }
            this.videoNumber += 1
            let params2 = {
                page: this.videoNumber,
                size: 14,
            }
            getAllVod(params2).then((res) => {
                this.videoList2 = res.data.data
            })
        },
        reduceNumber() {
            if (this.videoNumber > 1) {
                this.videoNumber -= 1
                let params2 = {
                    page: this.videoNumber,
                    size: 14,
                }
                getAllVod(params2).then((res) => {
                    this.videoList2 = res.data.data
                })
            }
        },
    },
    created() { },
    mounted() {
        let params2 = {
            page: this.videoNumber,
            size: 18,
        }
        getAllVod(params2).then((res) => {
            this.videoList2 = res.data.data
            this.totalPage = res.data.totalPage
        })
        let datas = {
            page: 1,
            size: 10000,

        }
        getAllVod(datas).then((res) => {
            this.allPage = Math.ceil(res.data.totalPage / 14)
            // this.allPage = Number((res.data.totalPage / 14).toFixed(0))
            console.log(this.videoNumber, this.allPage, '1-2');
        })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 120px) and (max-width: 1400px) {
    .edit {
        width: 88vw;
        // margin: auto;
        margin-top: 0rem;
        // background-color: pink;
        // background-color: white;
        overflow: hidden;

        .edit-box {
            width: 100%;
            height: 24rem;
            flex-wrap: wrap;
            display: flex;
            overflow: hidden;

            .edit-item {
                cursor: pointer;
                width: 19.8%;
                height: 50%;
                padding: 0px 4px;
                box-sizing: border-box;

                // background-color: skyblue;
            }
        }
    }
}

@media screen and (min-width: 1400px) and (max-width: 1890px) {
    .edit {
        width: 88vw;
        // margin: auto;
        margin-top: 0rem;

        overflow: hidden;

        .edit-box {
            width: 100%;
            height: 24rem;
            flex-wrap: wrap;
            display: flex;
            overflow: hidden;

            .edit-item {
                cursor: pointer;
                width: 16.5%;
                height: 50%;
                padding: 0px 4px;
                box-sizing: border-box;

                // background-color: skyblue;
            }
        }
    }
}

@media screen and (min-width: 1890px)and (max-width: 2200px) {
    .edit {
        overflow: hidden;

        width: 88vw;
        // margin: auto;
        margin-top: 0rem;

        // background-color: pink;
        // background-color: white;
        overflow: hidden;

        .edit-box {
            width: 100%;
            height: 24rem;
            flex-wrap: wrap;
            display: flex;

            .edit-item {
                cursor: pointer;
                width: 14.2%;
                height: 50%;
                padding: 0px 4px;
                box-sizing: border-box;

                // background-color: skyblue;
            }
        }
    }
}

@media screen and (min-width: 2200px)and (max-width: 22000px) {
    .edit {
        width: 88vw;
        // margin: auto;
        margin-top: 0rem;
        // background-color: pink;
        // background-color: white;
        overflow: hidden;

        .edit-box {
            width: 100%;
            height: 24rem;
            flex-wrap: wrap;
            display: flex;

            .edit-item {
                cursor: pointer;
                width: 11%;
                height: 50%;
                padding: 0px 4px;
                box-sizing: border-box;

                // background-color: skyblue;
            }
        }
    }
}

.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}
</style>