<template>
  <div class="content">
    <!-- <searchBar />
    <classifyBar :classify="1" :isNo="1" /> -->

    <div class="contain1 flexColCenter">
      <!-- <div class="location" v-show="!search">
        <span>当前位置：</span>
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{ nowCetnerStr }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/buyerCenter/personal' }"
              v-if="$route.path == '/buyerCenter/amendPersonal'">个人信息</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/buyerCenter/enterpriseInfo' }"
              v-if="$route.path == '/buyerCenter/amendEnterpriseInfo'">企业信息</el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.path !== '/buyerCenter/message' &&
      $route.path !== '/buyerCenter/home'
      " :to="{ path: $route.path }">{{ $route.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div> -->
      <div class="master">
        <div class="left" v-show="!search">
          <!-- <buyerLeftBar :marketChekoutFlag="marketChekoutFlag" v-if="marketChekoutFlag == 1" /> -->
          <buyerLeftBar2 :marketChekoutFlag="marketChekoutFlag" />
        </div>
        <!-- :style="!search ? 'width:1300px' : ''" -->
        <div class="right">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buyerLeftBar from "../components/buyerLeftBar.vue";
import buyerLeftBar2 from "../components/buyerLeftBar2.vue";
import { getBusManage } from '@/api/user.js'

import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'

export default {
  data() {
    const CetnerStr = ["买家中心", "卖家中心"];
    return {
      CetnerStr,
      nowCetnerStr: CetnerStr[0],
      marketChekoutFlag: window.localStorage.getItem('marketChekoutFlag'),
      buyerLeftBarFlag: true,
      search: "",
      menShow: false,
    };
  },

  watch: {
    $route(nVal) {
      const filterCenterStr = this.CetnerStr.filter(
        (item) => item === nVal.name
      );
      if (filterCenterStr.length) this.nowCetnerStr = filterCenterStr[0];
    },
  },
  created() {
    let uu = this.$route.query.keywords;
    if (uu) {
      this.search = uu;
    } else {
      this.search = "";
    }
  },
  methods: {
    toIndex() {
      this.$router.push({
        path: '/'
      })
    },
    skipPage(page) {
      window.location = page;
    },
    marketChekout(num) {
      this.marketChekoutFlag = num;
      window.localStorage.setItem('marketChekoutFlag', num)
      if (this.marketChekoutFlag == 1) {
        if (this.$route.path !== "/buyerCenter/message")
          this.$router.push({ path: "/buyerCenter/message" });
      } else {
        if (this.$route.path !== "/buyerCenter/home")
          this.$router.push({ path: "/buyerCenter/home" });
      }
    },
  },
  mounted() {
    // this.$isLogin();
    // getBusManage({ page: 1, size: 100 }).then((res) => {
    //   console.log(res, '/');
    //   if (res.data.data.length <= 0) {
    //     this.menShow = false
    //   } else {
    //     this.menShow = true
    //   }
    // })
  },
  components: {
    buyerLeftBar,
    buyerLeftBar2,
    searchBar,
    classifyBar
  },
};
</script>

<style lang="scss" scoped>
.content {
  // margin-bottom: 30px;
}

.logoBox {
  height: 108px;
  background-color: white;

  .logo {
    width: 1400px;
    display: flex;
    height: 308px;
    align-items: center;

    .img1 {
      object-fit: contain;
      width: 300px !important;
      height: 70px;
      cursor: pointer;
      position: absolute;
      top: -14px;
      left: 33%;
      transform: translateX(-92%);
    }

    .img2 {
      object-fit: contain;
      width: 188px;
      height: 57px;
      cursor: pointer;
      position: absolute;
      top: 25px;
      left: 42%;
      transform: translateX(-92%);
    }
  }

  .btnBox {
    display: flex;
    align-items: center;
    margin-left: 52px;

    .btn {
      margin-right: 35px;
      width: 120px;
      height: 36px;
      border-radius: 18px;
      border: 1px solid #006eb7;
      color: #006eb7;
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      cursor: pointer;
    }

    .btnBgc {
      background-color: #006eb7 !important;
      color: white !important;
      border: none !important;
    }
  }
}

.contain1 {}

.location {
  width: 1400px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  transform: translateY(10px);

  .nav {
    margin-top: 5px;
  }
}

.master {
  width: 100vw;
  margin-top: 70px;
  display: flex;
  // justify-content: space-between;

  .left {
    width: 13vw;
    // margin-right: 20px;
  }

  .right {
    width: 87vw;
    background-color: rgb(246, 247, 248)
  }
}
</style>
