<template>
    <div class="content">
        <div class="line1">
            <div class="left">
                <div class="title">原材料商城</div>
                <img src="../assets/index/f.png" style="
                        object-fit: cover;
                        width: 16px;
                        height: 16px;
                        margin-left: 7px;
                    " />
            </div>
            <div class="right">
                <div class="inputBar flexRowCenter">
                    <el-input v-model="search" placeholder="请输入内容" prefix-icon="el-icon-search" @change="searchPruduct"
                        @keyup.enter.native="searchPruduct"></el-input>
                </div>
                <div class="btn hht" @click="searchPruduct">搜索</div>
            </div>
        </div>

        <div class="line2">
            <div style="color: #0772a8; font-size: 16px; width: 73px"
                :style="moreFlag == 2 ? 'transform: translateY(-25px);' : ''">
                商品分类:
            </div>
            <div class="classifyBox" v-if="moreFlag == 1">
                <div class="classify hhe" v-for="(item, index) in classifyList"
                    :class="{ theActive: theActiveFlag == index }" @click="classifyTap(index, item.gId)">
                    {{ item.gName }}
                </div>
            </div>

            <div class="classifyBox2" v-if="moreFlag == 2">
                <div class="classify hhe" v-for="(item, index) in classifyList"
                    :class="{ theActive: theActiveFlag == index }" @click="classifyTap(index, item.gId)">
                    {{ item.gName }}
                </div>
            </div>

            <div class="more" @click="more" :style="moreFlag == 2 ? 'transform: translateY(-25px);' : ''">
                更多<i class="el-icon-arrow-down" style="margin-left: 7px" v-if="moreFlag == 1"></i>
                <i class="el-icon-arrow-up" style="margin-left: 7px" v-if="moreFlag == 2"></i>
            </div>
        </div>
        <div class="chooseBtn">
            <div class="iconM" @click.stop="hdproduct">
                <div>价格</div>
                <div class="iconxzBox">
                    <div class="iconI">
                        <i class="el-icon-caret-top hei" :class="isB == 2 ? 'bai' : ''"></i>
                    </div>
                    <div class="iconI">
                        <i class="el-icon-caret-bottom hei" :class="isB == 1 ? 'bai' : ''"></i>
                    </div>
                </div>
            </div>
            <div class="iconM hhy" :class="isBai == 1 ? 'Xzbai' : ''" @click="daBtnS(1)">
                <div>大图</div>
                <div><i class="el-icon-menu"></i></div>
            </div>
            <div class="iconM hhy" :class="isBai == 2 ? 'Xzbai' : ''" @click="daBtnS(2)">
                <div>列表</div>
                <div><i class="el-icon-s-unfold"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBstGoodsGategory } from '@/api/user'
export default {
    props: ['isBai'],
    data() {
        return {
            classifyList: '',
            theActiveFlag: 0,
            moreFlag: 1,
            search: '',
            productId: 0,
            isB: 1,
            isIdorName: 0
        }
    },

    methods: {
        hdproduct() {
            this.isB = this.isB == 1 ? 2 : 1
            console.log(this.isB, 'this.isB');
            this.isIdorName = 1
            if (this.isIdorName == 0) {
                this.$emit("getUserProduct", false, this.isB);
            } else {
                this.$emit("getUserProduct2", this.isB, this.search);
            }
        },
        getUserProductBtn() {
            this.isB = 1
            this.$emit('getUserProduct', false, 1)
        },
        getUserProductBtn2() {
            this.isB = 2
            this.$emit('getUserProduct', false, 2)
        },

        daBtnS(val) {
            this.$emit('daBtn', val)
        },
        searchPruduct() {
            // console.log('555')
            this.$emit('searchProduct2', this.productId, this.search)
        },
        classifyTap(index, id) {
            this.theActiveFlag = index
            this.productId = id
            this.$emit('productInfo', id)
        },
        more() {
            if (this.moreFlag == 1) {
                this.moreFlag = 2
            } else {
                this.moreFlag = 1
            }
        },

        //原材料商品分类
        async RawMaterialCateList() {
            // let res = await this.$http('/mini/ApiConnect/RawMaterialCateList')
            getBstGoodsGategory().then((res) => {
                console.log(res, 'w s res')
                this.classifyList = res.data.data
            })
            // this.classifyList = res.result
            // this.classifyList.unshift({ id: 0, name: '全部' })
        }
    },
    mounted() {
        this.RawMaterialCateList()
    }
}
</script>

<style lang="scss">
.inputBar {
    width: 232px;
    height: 32px;

    .el-color-picker__icon,
    .el-input,
    .el-textarea {
        border: 1px solid #006eb7;
        box-sizing: border-box;
    }

    .el-input__inner:focus {
        // el-input输入时设置边框颜色
        border: #006eb7;
        box-sizing: border-box;
    }

    .el-input__icon {
        line-height: 32px;
    }
}

.el-input--prefix .el-input__inner {
    height: 30px;
}
</style>

<style lang="scss" scoped>
.content {
    width: 100%;
    // padding-bottom: 20px;
    background-color: white;
    // margin-top: 20px;
    // height: 148px;
    box-sizing: border-box;
    padding: 5px;
}

.chooseBtn {
    width: 100%;
    display: flex;
    justify-content: left;
    padding-bottom: 10px;

    .iconMXz {
        width: 80px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        text-align: center;
        line-height: 28px;
        color: #fff;
        background-color: #006eb7;
        margin-right: 20px;
    }

    .Xzbai {
        color: #fff !important;
        background-color: #006eb7;
    }

    .iconM {
        width: 80px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        text-align: center;
        line-height: 26px;
        color: #006eb7;
        margin-right: 20px;
        margin-left: 10px;
        display: flex;
        text-align: center;
        // align-items: center;
        padding-left: 1.5%;
        box-sizing: border-box;
        cursor: pointer;
        /* 火狐 */
        -moz-user-select: none;
        /* Safari 和 欧朋 */
        -webkit-user-select: none;
        /* IE10+ and Edge */
        -ms-user-select: none;
        /* Standard syntax 标准语法(谷歌) */
        user-select: none;

        .iconxzBox {
            transform: translateY(-15%);

            >div:nth-child(2) {
                transform: translateY(1px);
            }

            >div:nth-child(1) {
                transform: translateY(-1px);
            }

            .iconI {
                width: 10px;
                height: 10px;

                .hei:hover {
                    color: #006eb7;
                    cursor: pointer;
                }

                .bai {
                    color: #b0b1b2;
                }
            }
        }
    }
}

.line1 {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .title {
            font-size: 24px;
            font-weight: 700;
        }
    }

    .right {
        width: 341px;
        height: 32px;
        display: flex;
        align-items: center;
        margin-left: 630px;

        .btn {
            width: 109px;
            height: 32px;
            background-color: #006eb7;
            text-align: center;
            line-height: 32px;
            color: white;
            cursor: pointer;
        }
    }
}

.line2 {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    box-sizing: border-box;

    div {
        cursor: pointer;
    }
}

.classifyBox {
    width: 1000px;
    text-align: start;
    display: flex;
    align-items: center;
    padding-left: 33px;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    height: 8px;
    padding-bottom: 15px;

    .classify {
        // width: 97px;
        margin-right: 44px;

        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 省略号标记 */
    }
}

.classifyBox2 {
    width: 1040px;
    text-align: start;
    display: flex;
    align-items: center;
    padding-left: 33px;
    position: relative;
    // overflow: hidden;
    flex-wrap: wrap;

    .classify {
        // width: 97px;
        margin-right: 44px;

        font-size: 16px;
        margin-bottom: 15px;
        height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 省略号标记 */
    }
}

.more {
    // margin-left: 20px;
    width: 58px;
    transform: translateY(10);
}

.theActive {
    color: #006eb7 !important;
}

::v-deep .inputBar .el-input__icon {
    color: #006eb7;
}
</style>
