import { render, staticRenderFns } from "./hangNewPrice2.vue?vue&type=template&id=858d3a26&scoped=true"
import script from "./hangNewPrice2.vue?vue&type=script&lang=js"
export * from "./hangNewPrice2.vue?vue&type=script&lang=js"
import style0 from "./hangNewPrice2.vue?vue&type=style&index=0&id=858d3a26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "858d3a26",
  null
  
)

export default component.exports