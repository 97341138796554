<template>
    <div class="zh-box">
        <div class="wz-top">
            <div style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;">
                {{ titleName }}
            </div>
            <div class="classifyList">
                <div class="classify-item" v-for="item in areaCategoryList2.slice(0, 7) "
                    :class="{ bgc: wangNumber == item.gId }" @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>
            <div>

            </div>
        </div>
        <div class="lun">
            <div class="lun-left" style="cursor: pointer;" @click="toTitle(videoListType1)">
                <div style="position: absolute;bottom: 14px ;left: 28px;color: #FFFFFF;font-size: 16px;">{{
                    videoListType1?.vTitle }}</div>
                <img :src="videoListType1?.vImgUrl"
                    style="width: 100%;height: 100%;border-radius: 12px;object-fit: cover;" alt="">
            </div>
            <div class="lun-right">
                <div class="title-item" v-for="item in videoListType2" @click="toTitle(item)">
                    <div class="item-left"><img :src="item.vImgUrl"
                            style="width: 100%;height: 100%;border-radius: 12px;object-fit: cover" alt=""></div>
                    <div class="item-right">
                        <div
                            style="color: #333333;font-size: 16px;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;width: 100%;overflow: hidden;">
                            {{ item.vTitle }}</div>
                        <div style="color: #9499A0;font-size: 12px;padding-top: 5px;">
                            <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                style="vertical-align: middle;margin-right: 1px;object-fit: cover" alt="">

                            <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six">
            <div class="edit-item" v-for="item in videoListType3" @click="toTitle(item)" :key="item.vId">
                <div style="position: relative;">
                    <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;"> {{
                    item.vAddTime.slice(0, 10) }}
                    </div>
                    <img :src="item.vImgUrl" style="width: 100%;height: 124px;object-fit: cover;border-radius: 12px;"
                        alt="">
                </div>
                <div
                    style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{ item.vTitle }}</div>
                <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                    <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle;margin-right: 1px;"
                        alt="">

                    <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                </div>
            </div>
            <!-- <div class="six-item" v-for="item in videoListType3" @click="toTitle(item)" style="cursor: pointer;">
                <div class="six-left">
                    <img :src="item.imgUrl" style="width: 100%;height: 96%;" alt="">
                </div>
                <div class="six-right">
                    <div class="six-right-1">{{ item.bgcTitle }}</div>
                    <div class="six-right-2">{{ item.bgcContent }}</div>
                    <div class="six-right-3">
                        <img src="../assets/newImg/Vector@0.75x.png"
                            style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                            alt="">
                        <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                            }}</span>
                        <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { getBstGoodsGategory1, getAllGoodsConsult, getAllVod, getFatherCatBySonCat } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            areaCategoryList: [],
            videoListType: [],
            wangNumber: 0,
            size: 30,
            page: 1,
            videoListType1: {},
            videoListType2: [],
            videoListType3: [],

        }
    },
    props: {
        areaCategoryList2: {
            type: Array,
            default: []
        },
        titleName: {
            type: String
        },
        isShow: {
            type: Number,
            default: 1
        },
    }
    ,
    methods: {
        numberChange(item) {
            this.wangNumber = item.gId
            if (this.isShow == 1) {
                this.params2 = {
                    industry: '',
                    Industry2: "",
                    page: 1,
                    size: 30,
                    title: '',
                    cat_one: this.wangNumber
                }
            } else {
                this.params2 = {
                    industry: this.wangNumber,
                    Industry2: "",
                    page: 1,
                    size: 30,
                    title: '',
                    cat_one: ''
                }
            }

            getAllVod(this.params2).then((res) => {
                this.videoListType1 = res.data.data[0]
                this.videoListType2 = res.data.data.slice(1, 5)
                this.videoListType3 = res.data.data.slice(5, 23)
            })
        },
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/vudio',
                query: {
                    v_id: item.vId
                }
            });
            window.open(href, "_blank");

        }
    },
    created() { },
    mounted() {

        let data = {
            industry: '',
            Industry2: "",
            page: 1,
            size: 30,
            title: '',
        }
        getAllVod(data).then((res) => {
            console.log(res.data, '0000000000');
            this.videoListType1 = res.data.data[0]
            this.videoListType2 = res.data.data.slice(1, 5)
            this.videoListType3 = res.data.data.slice(5, 23)
        })
        // getBstGoodsGategory1().then((res) => {
        //     this.areaCategoryList = res.data.data
        //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
        //     console.log(this.areaCategoryList, 'this.areaCategoryList ');
        // })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
    .zh-box {
        margin-top: 30px !important;
        width: 1200px;
        height: 920px;
        background-color: white;
        margin-top: 30px;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';
        margin: auto;

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 8px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48%;
                height: 98%;
                padding: 5px;
                position: relative;
                // background-color: pink;
            }

            .lun-right {
                width: 54.5%;
                height: 101%;
                padding: 5px 0px 0px 2px;
                display: flex;
                flex-wrap: wrap;

                .title-item {
                    width: 48.5%;
                    height: 46%;
                    padding: 5px 4px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    display: flex;

                    .item-left {
                        height: 91%;
                        width: 69%;
                    }

                    .item-right {
                        padding: 2px 0px;
                        padding-left: 15px;
                        height: 99%;
                        width: 31%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .six {
            width: 100%;
            height: 400px;
            margin-top: 8px;
            padding: 8px 3px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                cursor: pointer;
                width: 192px;
                height: 179px;
                padding: 0px 4px;
                // background-color: skyblue;
            }

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 5px 4px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 10px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 62px;
                        padding: 7px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 10px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1620px) and (max-width: 50500px) {
    .zh-box {
        width: 1500px;
        height: 920px;
        background-color: white;
        margin-top: 30px;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';
        margin: auto;

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 11px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48%;
                height: 98%;
                padding: 5px;
                position: relative;
                // background-color: pink;
            }

            .lun-right {
                width: 54.5%;
                height: 101%;
                padding: 5px 0px 0px 2px;
                display: flex;
                flex-wrap: wrap;

                .title-item {
                    width: 370px;
                    height: 46%;
                    padding: 5px 4px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    display: flex;

                    .item-left {
                        height: 98%;
                        width: 62%;
                    }

                    .item-right {
                        padding: 2px 15px;
                        height: 99%;
                        width: 38%;

                    }
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .six {
            width: 100%;
            height: 400px;
            margin-top: 8px;
            padding: 8px 3px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                cursor: pointer;
                width: 240px;
                height: 181px;
                padding: 0px 4px;
                // background-color: skyblue;
            }

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 5px 4px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 10px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 62px;
                        padding: 7px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 10px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}


.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}
</style>