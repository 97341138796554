<template>
    <div class="zh-box" style="margin-bottom: 30px;">
        <!-- <div class="wz-top">
            <div
                style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;margin-left: 4px;">
                {{ titleName }}
            </div>
            <div class="classifyList">

                <div class="classify-item" v-for="item in areaCategoryList2.slice(0, 7) "
                    :class="{ bgc: wangNumber == item.gId }" @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>
            <div>

            </div>
        </div> -->
        <div class="lun">
            <div class="lun-left" style="cursor: pointer;">
                <div
                    style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 300px;margin-left: 4px;padding: 10px 0px;">
                    {{ titleName }}
                </div>
                <div style="width: 100%;display: flex;flex-wrap: wrap;height: 90%;">
                    <div class="micleBo" v-for="item in tableList2" @click="priceRadio(item)">
                        <div class="micleBo1">
                            {{ item.good_name }}
                        </div>
                        <div class="micleBo2">
                            {{ item.price }}
                        </div>
                        <div class="micleBo3">
                            <img src="../assets/newImg3/路径@1x (9).png" v-show="item.up_price > 0"
                                style="vertical-align: middle;margin-right: 4px" alt="">
                            <img src="../assets/newImg3/路径@1x (10).png" v-show="item.up_price < 0"
                                style="vertical-align: middle;margin-right: 4px;" alt="">
                            <span style="font-size: 14px;vertical-align: middle;color: #D8D8D8;"
                                v-show="item.up_price == 0">{{
                        item.up_price
                    }}</span>
                            <span style="color: red;font-size: 14px;vertical-align: middle;"
                                v-show="item.up_price > 0">{{ item.up_price
                                }}</span>
                            <span style="color: green;font-size: 14px;vertical-align: middle;"
                                v-show="item.up_price < 0">{{ item.up_price
                                }}</span>
                            <span style="font-size: 12px;font-weight: 400;color: #C5C5C5;margin-left: 14px;">{{
                        item.date }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lun-right">
                <div
                    style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;margin-left: 4px;padding: 10px 3px;height: 39px;">

                </div>
                <div class="echartsBox">
                    <div class="eTitle">热门产品走势</div>
                    <div ref="nengEchart" class="chartStyle">

                    </div>

                </div>
            </div>
        </div>
        <div class="videoClass">
            <div class="edit-item" v-for="item in videoList" @click="toVedio(item)" :key="item.vId">
                <div style="position: relative;">
                    <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;">

                    </div>
                    <img :src="item.vImgUrl" style="width: 100%;height: 124px;object-fit: cover;border-radius: 12px;"
                        alt="">
                </div>
                <div
                    style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{
                        item.vTitle }}</div>
                <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;display: flex;">
                    <div>
                        <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                            style="vertical-align: middle;margin-right: 1px;" alt="">

                        <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                    </div>
                    <div style="margin-left: 18px;font-size: 12px;">
                        <img src="../assets/zhnlImg/Clip path group@1x.png"
                            style="vertical-align: middle;margin-right: 2px;" alt="">
                        <span style="vertical-align: middle;line-height: 9px;">{{ item.vAddTime.slice(0, 10) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="consultClass">
            <div class="six-item" v-for="item in IndustryInformationList1" @click="toTitle(item)"
                style="cursor: pointer;">
                <div class="six-left">
                    <img :src="item.imgUrl" style="width: 100%;height: 96%;border-radius: 8px;" alt="">
                </div>
                <div class="six-right">
                    <div class="six-right-1">{{ item.bgcTitle }}</div>
                    <div class="six-right-2">{{ item.bgcContent }}
                    </div>
                    <div class="six-right-3">
                        <img src="../assets/newImg/Vector@0.75x.png"
                            style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                            alt="">
                        <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                            }}</span>

                        <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="six">
            <div class="six-item" v-for="item in IndustryInformationList3" @click="toTitle(item)"
                style="cursor: pointer;">
                <div class="six-left">
                    <img :src="item.imgUrl" style="width: 100%;height: 96%;border-radius: 4px;" alt="">
                </div>
                <div class="six-right">
                    <div class="six-right-1">{{ item.bgcTitle }}</div>
                    <div class="six-right-2">{{ item.bgcContent }}
                    </div>
                    <div class="six-right-3">
                        <img src="../assets/newImg/Vector@0.75x.png"
                            style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                            alt="">
                        <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                            }}</span>

                        <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { getQuotToIndex, getAllGoodsConsult, getQuotByParams, getAllVod, getQutoByCatOne } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            createArray: [],
            createArray1: [],
            createArray2: [],
            createArray3: [],
            createArray4: [],
            createArray5: [],
            createArray6: [],
            dataList: [],
            lineArray: [],
            IndustryInformationList: [],
            videoList: [],
            tableList3: [],
            tableList2: [],
            params2: {},
            areaCategoryList: [],
            wangNumber: 0,
            size: 30,
            page: 1,
            IndustryInformationList1: [],
            IndustryInformationList2: [],
            IndustryInformationList3: []
        }
    },
    // props: ['areaCategoryList2', 'titleName'],
    props: {
        areaCategoryList2: {
            type: Array,
            default: []
        },
        titleName: {
            type: String
        },
        isShow: {
            type: Number,
            default: 1
        },
        gId: {
            type: String,
            default: ''
        }
    },
    methods: {
        getEchartData(index) {
            const chart = this.$refs.nengEchart
            if (chart) {
                const myChart = this.$echarts.init(chart);
                const option = {
                    title: {
                        // text: 'Stacked Line'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: [this.lineArray[0]?.name, this.lineArray[1]?.name, this.lineArray[2]?.name, this.lineArray[3]?.name, this.lineArray[4]?.name, this.lineArray[5]?.name, this.lineArray[6]?.name,]
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.dataList
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: this.lineArray[0]?.name,
                            type: 'line',
                            data: this.lineArray[0].data
                        },
                        {
                            name: this.lineArray[1]?.name,
                            type: 'line',
                            data: this.lineArray[1].data
                        },
                        {
                            name: this.lineArray[2]?.name,
                            type: 'line',
                            data: this.lineArray[2].data
                        },
                        {
                            name: this.lineArray[3]?.name,
                            type: 'line',
                            data: this.lineArray[3].data
                        },
                        {
                            name: this.lineArray[4]?.name,
                            type: 'line',
                            data: this.lineArray[4].data
                        },
                        {
                            name: this.lineArray[5]?.name,
                            type: 'line',
                            data: this.lineArray[5].data
                        },
                        {
                            name: this.lineArray[6]?.name,
                            type: 'line',
                            data: this.lineArray[6].data
                        }
                    ]
                };
                myChart.setOption(option);
            }
        },
        priceRadio(e) {
            const { href } = this.$router.resolve({
                path: '/todayQuotedPrice',
                query: {
                    data: JSON.stringify({
                        catOne: e?.cat_one,
                        catTwo: e?.cat_two,
                        catThree: e?.cat_three,
                        catFour: e?.cat_four,
                        label: e.good_name,
                        cat_id: e.value
                    })
                }

            });
            window.open(href, "_blank");
            getFatherCatBySonCat({ cat_id: e.value }).then((res) => {



            })

        },
        toVedio(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,
                },
            });
            window.open(href, "_blank");
        },
        godeilt(e) {
            //  let uid=e.target.dataset.id
            const event = JSON.parse(e.target.dataset.id)
            this.tableClick2(event)
        },
        tableClick2(row) {
            const { href } = this.$router.resolve({
                path: "/todayQuotedPrice",
                query: {
                    data: JSON.stringify({
                        catOne: row.cat_one,
                        catTwo: row.cat_two,
                        catThree: row.cat_three,
                        label: row.good_name
                    })
                },
            });
            window.open(href, "_blank");
        },
        numberChange(item) {
            this.wangNumber = item.gId
            if (this.isShow == 1) {
                this.params2 = {
                    cat_one: this.wangNumber,
                    cat_two: '',
                    cat_three: '',
                    industry: '',
                    industry2: '',
                    size: this.size,
                    page: this.page
                }
            } else {
                this.params2 = {
                    cat_one: '',
                    cat_two: '',
                    cat_three: '',
                    industry: this.wangNumber,
                    industry2: '',
                    size: this.size,
                    page: this.page
                }
            }


            getAllGoodsConsult(this.params2).then((res) => {
                this.total_page = res.data.totalPage
                res.data.data.forEach((item, index) => {
                    item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD').slice(0, 10);
                })
                this.IndustryInformationList1 = res.data.data.slice(0, 2)
                this.IndustryInformationList2 = res.data.data.slice(1, 15)
                this.IndustryInformationList3 = res.data.data.slice(15, 21)
                this.IndustryInformationList3.forEach((item) => {
                    item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
                })

            })
        },
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/titleDetails',
                query: {
                    id: item.bgcId
                }
            });
            window.open(href, "_blank");

        }
    },
    created() { },
    computed: {

        classOption2() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
            }
        },
        classOption3() {
            return {
                step: 0.3, // 数值越大速度滚动越快
                limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        let today = dayjs();

        // 创建一个空数组来存储日期  
        // let datesArray = [];

        // 循环四次，获取前四天的日期  
        for (let i = 6; i >= 0; i--) {
            this.dataList.push(today.subtract(i, 'day').format('MM-DD'));
        }
        getQutoByCatOne({ cat_one: 297, size: 7 }).then((res) => {
            this.lineArray = res.data.data
  
            this.getEchartData()

        })

        let data = {
            industry: this.industry,
            industry2: '',
            page: 1,
            size: 6,
            title: '',

        }
        getAllVod(data).then((res) => {

            this.videoList = res.data.data
        })
        let data2 = {
            cat_one: '',
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            size: 4,
            page: 1
        }
        let params = {
            cat_one: 297,
            cat_two: '',
            size: 6,
            hits: '',
            rise_fall: '',
        }
        // getQuotToIndex({ size: 50 }).then((res) => {
        //     this.tableList3 = res.data.data
        // })
        getQuotByParams(params).then((res) => {
            this.newDayMarketData = res.data.data[0]?.addTime
            this.tableList2 = res.data.data

        })
        this.wangNumber = this.gId
        let params2 = {
            cat_one: 297,
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            size: 2,
            page: this.page
        }
        getAllGoodsConsult(params2).then((res) => {
            this.total_page = res.data.totalPage
            res.data.data.forEach((item, index) => {
                item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
            })
            res.data.data.forEach((item, index) => {
                item.addTime = item.addTime.slice(0, 10)
            })
            res.data.data.forEach((item) => {
                item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
            })
            this.IndustryInformationList1 = res.data.data.slice(0, 2)
            this.IndustryInformationList2 = res.data.data.slice(2, 15)
            this.IndustryInformationList3 = res.data.data.slice(15, 21)


        })
        // getBstGoodsGategory1().then((res) => {
        //     this.areaCategoryList = res.data.data
        //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
        // })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
    .zh-box {
        width: 1200px;
        height: 890px;
        background-color: white;
        padding: 2px 20px;
        font-family: 'MicrosoftYaHeiLight';
        margin: auto;

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;
                overflow: hidden;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 8px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 470px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 50%;
                height: 98% !important;
                padding: 5px;
                position: relative;
                overflow: hidden;

                .micleBo {
                    width: 30.3%;
                    height: 46.5%;
                    margin-right: 15px;
                    border: 1px solid #D8D8D8;
                    border-radius: 10px;

                    .micleBo1 {
                        margin-top: 28px;
                        padding: 8px 36px;
                    }

                    .micleBo2 {
                        padding: 0px 27px;
                        font-weight: 700;
                        font-size: 30px;
                    }

                    .micleBo3 {
                        padding: 8px 35px;
                    }
                }

                // background-color: pink;
                .edit-box {
                    width: 100%;
                    height: 50%;
                    flex-wrap: wrap;
                    display: flex;
                    margin-top: 6px;

                    .edit-item {
                        box-sizing: border-box;
                        cursor: pointer;
                        width: 33.3%;
                        height: 11.2rem;
                        padding: 0px 4px;
                        // background-color: skyblue;
                    }
                }
            }

            .lun-right {
                width: 50%;
                height: 98%;
                padding: 5px 0px 0px 10px;

                display: flex;
                flex-wrap: wrap;
                gap: 0;
                align-content: flex-start;
                justify-content: flex-start;

                .echartsBox {
                    width: 100%;
                    border: 1px solid #D8D8D8;
                    height: 393px;
                    border-radius: 10px;

                    .eTitle {
                        color: #227BFF;
                        font-weight: 400;
                        font-size: 18px;
                        padding: 10px 20px;
                    }

                    .chartStyle {
                        width: 97%;
                        height: 320px;
                        margin: auto;
                        margin-top: 20px;
                        // padding: 20px;
                    }
                }

                .title-item {
                    width: 47%;
                    height: 20px;
                    padding: 12px 8px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .videoClass {
            width: 100%;
            height: 200px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 200px;
                height: 207px;
                padding: 0px 2px;
                // background-color: skyblue;
            }
        }

        .consultClass {
            width: 100%;
            height: 170px;
            display: flex;

            .six-item {

                box-sizing: border-box;
                width: 50%;
                height: 100%;
                padding: 2px 4px;
                display: flex;
                margin-top: 5px;
                // border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 17px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }


        .six {
            width: 100%;
            height: 374px;
            margin-top: 8px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 2px 0px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 32px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1620px) and (max-width: 25000px) {
    .zh-box {
        margin: auto;

        width: 1500px;
        height: 900px;
        background-color: white;
        padding: 2px 20px;
        font-family: 'MicrosoftYaHeiLight';

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 12px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 470px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 50%;
                height: 450px;
                padding: 5px;
                position: relative;
                overflow: hidden;


                .micleBo {
                    width: 31%;
                    height: 46.5%;
                    margin-right: 15px;
                    border: 1px solid #D8D8D8;
                    border-radius: 10px;

                    .micleBo1 {
                        margin-top: 28px;
                        padding: 8px 36px;
                    }

                    .micleBo2 {
                        padding: 0px 27px;
                        font-weight: 700;
                        font-size: 40px;
                    }

                    .micleBo3 {
                        padding: 8px 35px;
                    }
                }

                .edit-box {
                    width: 100%;
                    height: 50%;
                    flex-wrap: wrap;
                    display: flex;
                    margin-top: 6px;

                    .edit-item {
                        box-sizing: border-box;
                        cursor: pointer;
                        width: 33.3%;
                        height: 11.2rem;
                        padding: 0px 4px;
                        // background-color: skyblue;
                    }
                }
            }

            .lun-right {
                width: 50%;
                height: 450px;
                padding: 5px 0px 0px 10px;

                display: flex;
                flex-wrap: wrap;
                gap: 0;
                align-content: flex-start;
                justify-content: flex-start;

                .echartsBox {
                    width: 100%;
                    border: 1px solid #D8D8D8;
                    height: 393px;
                    border-radius: 10px;

                    .eTitle {
                        color: #227BFF;
                        font-weight: 400;
                        font-size: 18px;
                        padding: 10px 20px;
                    }

                    .chartStyle {
                        width: 97%;
                        height: 320px;
                        margin: auto;
                        margin-top: 20px;
                        // padding: 20px;
                    }
                }

                .title-item {
                    width: 47%;
                    height: 20px;
                    padding: 12px 8px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .videoClass {
            width: 100%;
            height: 206px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 250px;
                height: 207px;
                padding: 0px 4px;
                // background-color: skyblue;
            }
        }

        .consultClass {
            width: 100%;
            height: 170px;
            display: flex;

            .six-item {

                box-sizing: border-box;
                width: 50%;
                height: 100%;
                padding: 2px 4px;
                display: flex;
                margin-top: 5px;
                // border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 17px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }


        .six {
            width: 100%;
            height: 374px;
            margin-top: 8px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 2px 0px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 32px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}

.scroll_table {
    margin-bottom: 15px;
    // margin-top: 5px;
    display: flex;
    width: 100%;
    // height: 900px !important;

    .table_ul {
        display: flex;
        align-items: center;
        height: 32px;
        box-sizing: border-box;
        width: 100%;
        // height: 51px;
        background: #efefef;

        .li_nav {
            font-size: 13px;
            font-weight: 700;
            width: 88px;
            height: 32px;
            overflow: hidden;
            text-align: center;
            line-height: 32px;
        }
    }

    .warpw {
        height: 582px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .table_ul1 {
        // height: 514px;
        width: 100%;
        box-sizing: border-box;

        .ulis {
            width: 100%;

            >span {
                width: 88px;
                height: 45px;
                display: inline-block;
                text-align: center;
                font-size: 13px;
                line-height: 45px;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }

    ::v-deep .table .el-table__body-wrapper {
        display: none;
    }

    // 重点注意这段样式
    .seamless-warp {
        height: 570px;
        overflow: hidden;


        ::v-deep .table_scroll .el-table__header-wrapper {
            display: none;
        }
    }

    // .seamless-warp2 {
    //     height: 560px;
    //     // height: 82px;
    //     overflow: hidden;
    //     // background-color: rgba(230, 239, 243);

    //     ::v-deep .table_scroll .el-table__header-wrapper {
    //         display: none;
    //     }
    // }
}

.scroll_table2 {
    margin-bottom: 15px;
    // margin-top: 5px;
    display: flex;
    width: 98%;
    position: absolute;
    top: 110px;
    left: 7px;

    .table2_ul {
        display: flex;
        align-items: center;
        height: 32px;
        box-sizing: border-box;
        width: 100%;
        // height: 51px;
        background: #efefef;

        .li_nav {
            font-size: 13px;
            font-weight: 700;
            width: 88px;
            height: 32px;
            overflow: hidden;
            text-align: center;
            line-height: 32px;
        }
    }

    .warpw {
        height: 514px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .table_ul1 {
        // height: 514px;
        width: 100%;
        box-sizing: border-box;

        .ulis {
            width: 100%;

            >span {
                width: 88px;
                height: 45px;
                display: inline-block;
                text-align: center;
                font-size: 13px;
                line-height: 45px;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }

    ::v-deep .table .el-table__body-wrapper {
        display: none;
    }

    // 重点注意这段样式
    // .seamless-warp {
    //     height: 122px !important;
    //     overflow: hidden;


    //     ::v-deep .table_scroll .el-table__header-wrapper {
    //         display: none;
    //     }
    // }

    .seamless-warp2 {
        // height: 482px;
        height: 122px;
        overflow: hidden;
        // background-color: rgba(230, 239, 243);

        ::v-deep .table_scroll .el-table__header-wrapper {
            display: none;
        }
    }
}


.market {
    // margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    // align-items: flex-start;

    .line1 {
        // margin-top: 10px;
        position: relative;
        width: 446px;
        background-color: white;
        // height: 100px !important;
        // padding: 16px;
        margin-right: 30px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                // height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            // height: 2px;
            // background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 8px;
        }
    }

    .line2 {
        width: 240px;
        background-color: white;
        padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }

    .line3 {
        width: 276px;
        background-color: white;
        // padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .contaion {
            padding: 3px 8px;
        }

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
                overflow: hidden;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }
}

::v-deep .el-table__row {
    background-color: rgb(230, 239, 243) !important;
}

::v-deep #czh .el-table__header-wrapper {
    display: none;
}

::v-deep #czh .el-table__row {
    // display: none;
    color: red
}
</style>