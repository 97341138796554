<template>
    <div>
        <div class="money-box">
            <div class="money-1">￥{{ user_money }}</div>
            <div class="money-2" @click="goIntegral">创作收入</div>
        </div>
        <div class="new-plan">
            <div class="new-plan-1">
                <div style="color: #333333;font-weight: 400;font-size: 16px;">新峰计划</div>
                <div style="color: #999999;font-size: 14px;font-weight: 290;margin-left: 10px;">带你初步了解创作投稿</div>
            </div>
            <div class="new-plan-2">
                <div style="color: #999999">规则：1. 发布优质的产品科普视频；2. 限于LV1行业新手；3. 日期不限</div>
            </div>
            <div class="new-plan-3">
                <div class="new-plan-3-item" v-for="(item, index) in taskList">
                    <div class="item-left">
                        <img :src="item.newTaskInfo.tImgUrl"
                            style="width: 60px;height: 60px;border-radius: 30px;margin-left: 38%;" alt="">
                    </div>
                    <div class="item-middle">
                        <div style="color: #333333;font-size: 16px;">{{ item.newTaskInfo.tTitle }}</div>
                        <div style="color: #227BFF;font-size: 12px;margin-top: 3px;margin-left: 2px;">{{
                item.newTaskInfo.tContent
            }}</div>
                    </div>
                    <div class="item-right">

                        <el-button type="primary" style="margin-top: 12px;" plain v-if="item.isComplate == 1"
                            :disabled="item.isComplate == 1">已完成</el-button>
                        <el-button type="primary" style="margin-top: 12px;" plain
                            v-else-if="item.isComplate == 0 && (index == 1 || index == 2 || index == 0)"
                            @click="goVideo(item)">去投稿</el-button>
                        <el-button type="primary" style="margin-top: 12px;" plain
                            v-else-if="item.isComplate == 0 && (index == 3 || index == 4 || index == 5)"
                            @click="goVideo(item)">去分享</el-button>
                        <el-button type="primary" style="margin-top: 12px;" plain v-else>未完成</el-button>
                    </div>
                </div>

            </div>
        </div>
        <div class="new-plan" v-show="user_exp == 2">
            <div class="new-plan-1">
                <div style="color: #333333;font-weight: 400;font-size: 16px;">新时代计划</div>
            </div>
            <div class="new-plan-2">
                <div style="color: #999999">参加条件：（1）LV2行业达人；（2）发布优质的产品视频；（3）日期不限</div>
            </div>

            <div class="new-plan-3">
                <div class="new-plan-3-item" v-for="(item, index) in taskList2">
                    <div class="item-left">
                        <img :src="item.newTaskInfo.tImgUrl"
                            style="width: 60px;height: 60px;border-radius: 30px;margin-left: 38%;" alt="">
                    </div>
                    <div class="item-middle">
                        <div style="color: #333333;font-size: 16px;">{{ item.newTaskInfo.tTitle }}</div>
                        <div style="color: #227BFF;font-size: 12px;margin-top: 3px;margin-left: 2px;">{{
                item.newTaskInfo.tContent
            }}</div>
                    </div>
                    <div class="item-right">

                        <el-button type="primary" style="margin-top: 12px;" plain v-if="item.isComplate == 1"
                            :disabled="item.isComplate == 1">已完成</el-button>
                        <el-button type="primary" style="margin-top: 12px;" plain
                            v-else-if="item.isComplate == 0 && (index == 1 || index == 2 || index == 0 || index == 3)"
                            @click="goVideo2(item)">去分享</el-button>

                        <el-button type="primary" style="margin-top: 12px;" plain v-else>未完成</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { getUserNewbieTaskById, getUserById } from '../api/user'
export default {
    data() {
        return {
            taskList: [],
            taskList2: [],
            user_money: 0,
            user_exp: '',
        }
    },
    methods: {
        goIntegral() {
            this.$router.push({
                path: '/buyerCenter/integral'
            })
        },
        goVideo(item) {
            if (item.newTaskInfo.tId == 3 || item.newTaskInfo.tId == 4 || item.newTaskInfo.tId == 5) {
                this.$router.push({
                    path: '/buyerCenter/contribute',
                    query: {
                        video: 1,
                    }
                })
            } else {
                this.$router.push({
                    path: '/buyerCenter/publishVideo',

                })
            }
        },
        goVideo2(item) {
            this.$router.push({
                path: '/buyerCenter/publishVideo',

            })

        }
    },
    created() { },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)'
        });
        getUserNewbieTaskById({}).then((res) => {
            loading.close()

            this.taskList = res.data.data.LV1Task
            this.taskList2 = res.data.data.LV2Task
            console.log(this.taskList, ' this.taskList');

        })
        getUserById().then((res) => {
            loading.close()
            this.user_money = res.data.data.user_money
            this.user_exp = res.data.data.user_exp
        })
    }
}
</script>

<style lang=scss scoped>
.money-box {
    width: 98%;
    height: 99px;
    margin: auto;
    border-radius: 12px;
    background-image: url('https://video.zhnl.com/2024/07/18/e09342bccea64e2f8a8aa583142fb65780e423a420b162a0e72aa8b9a487faa.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;

    .money-1 {
        margin-left: 20px;
        color: #FFFFFF;
        font-size: 28px;
        font-weight: 400;
        width: 120px;
        height: 100%;
        line-height: 99px;
    }

    .money-2 {
        cursor: pointer;
        margin-left: 8px;
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 290;
        width: 80px;
        text-align: center;
        line-height: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin-top: 36px;

    }
}

.new-plan {
    width: 98%;
    height: 345px;
    margin: auto;
    border-radius: 12px;
    margin-top: 20px;
    background-color: white;
    padding: 12px 16px;
    box-sizing: border-box;

    .new-plan-1 {
        height: 32px;
        width: 100%;
        padding: 5px;
        display: flex;
    }

    .new-plan-2 {
        height: 32px;
        width: 100%;
        padding: 0px 5px;
        display: flex;
        font-size: 12px;
        font-weight: 290;
        color: #999999;
    }

    .new-plan-3 {
        height: 280px;
        width: 100%;
        padding: 0px 5px;
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;

        .new-plan-3-item {
            width: 49%;
            height: 65px;
            display: flex;
            margin-top: 10px;

            .item-left {
                width: 136px;
                height: 100%;
            }

            .item-middle {
                width: 60%;
                height: 100%;
                padding: 10px 1px
            }

            .item-right {
                width: 35%;
                height: 100%;
            }
        }
    }
}
</style>