<template>
    <div :class="{ content: classify == 1, content2: classify == 2, }">

        <el-menu :default-active="activeMenu" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
            :unique-opened="true" style="border-right: 1px solid #ebeef5;" @select="select" router>
            <div style="height: 50px;width: 100%;cursor: pointer;margin-top: 20px;" @click="goTou">

                <img src="../assets/newImg2/按钮 投稿@1x.png" style="width: 60%;margin-left: 20%;">

            </div>

            <el-menu-item index="home">
                <i class="el-icon-house"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="corVip">
                <i class="el-icon-s-goods"></i>
                <span slot="title">会员中心</span>
            </el-menu-item>

            <!-- <el-menu-item index="theMessage">
                <i class="el-icon-message"></i>
                <span slot="title">消息中心</span>
            </el-menu-item>
            <el-menu-item index="theProduct">
                <i class="el-icon-receiving"></i>
                <span slot="title">产品管理</span>
            </el-menu-item>

            <el-submenu index="order" v-if="marketChekoutFlag == 1">
                <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>我的订单</span>
                </template>
<el-menu-item-group>
    <el-menu-item index="myOrder">
        <i class="el-icon-s-order"></i>
        我的订单
    </el-menu-item>
    <el-menu-item index="carts">
        <i class="el-icon-shopping-cart-full"></i>
        购物车
    </el-menu-item>

</el-menu-item-group>
</el-submenu>

<el-menu-item index="myCollect">
    <i class="el-icon-receiving"></i>
    <span slot="title">我的收藏</span>
</el-menu-item>
<el-menu-item index="systemNotice">
    <i class="el-icon-bell"></i>
    <span slot="title">系统公告推送</span>
</el-menu-item>
<el-menu-item index="help">
    <i class="el-icon-question"></i>
    <span slot="title">帮助管理</span>
</el-menu-item>
<el-menu-item index="suppliers" v-if="marketChekoutFlag == 2">
    <i class="el-icon-edit"></i>
    <span slot="title">申请成为供应商</span>
</el-menu-item>
<el-menu-item index="attention">
    <i class="el-icon-user"></i>
    <span slot="title">关注的商家</span>
</el-menu-item> -->
            <el-submenu index="info5">
                <template slot="title">
                    <i class="el-icon-s-data"></i>
                    <span>原材料商家</span>
                </template>
                <el-menu-item-group>

                    <el-menu-item index="productPrice">
                        <i class="el-icon-s-marketing"></i>
                        <span slot="title">原料报价</span>
                    </el-menu-item>
                    <el-menu-item index="application">
                        <i class="el-icon-s-goods"></i>
                        <span slot="title">厂商管理</span>
                    </el-menu-item>
                    <!-- <el-menu-item index="selectStore">
                        <i class="el-icon-bank-card"></i>
                        <span slot="title">商城订单</span>
                    </el-menu-item> -->
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="info">
                <template slot="title">
                    <i class="el-icon-s-grid"></i>
                    <span>个人中心</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="personal">
                        <i class="el-icon-user"></i>
                        个人信息
                    </el-menu-item>

                    <!-- <el-menu-item index="qyData"
                        v-show="distribut_type == 1 || distribut_type == 2 || distribut_type == 0">
                        <i class="el-icon-office-building"></i>
                        企业信息
                    </el-menu-item> -->
                    <el-menu-item index="integral">
                        <i class="el-icon-present"></i>
                        <span slot="title">收益管理</span>
                    </el-menu-item>
                    <el-menu-item index="commentManage">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">评论管理</span>
                    </el-menu-item>
                    <el-menu-item index="strictStoreAdmin" v-show="info.is_see_add_goods == 1">
                        <i class="el-icon-present"></i>
                        <span slot="title">严选商城</span>
                    </el-menu-item>

                    <!-- <el-menu-item index="priviLege" v-show="distribut_type != 1">
                        <i class="el-icon-office-building"></i>
                        开通企业会员
                    </el-menu-item> -->

                    <!-- <el-menu-item index="address">
                        <i class="el-icon-location-outline"></i>
                        地址管理
                    </el-menu-item> -->
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="info3">
                <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span>创作内容</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="publishVideo">
                        <i class="el-icon-s-ticket"></i>
                        <span slot="title">视频管理</span>
                    </el-menu-item>
                    <el-menu-item index="pushNews">
                        <i class="el-icon-picture"></i>
                        <span slot="title">资讯管理</span>
                    </el-menu-item>
                    <!-- <el-menu-item :disabled="disFalse">
                        <i class="el-icon-picture"></i>
                        <span slot="title">互动管理</span>
                    </el-menu-item>
                    <el-menu-item :disabled="disFalse">
                        <i class="el-icon-picture"></i>
                        <span slot="title">数据中心</span>
                    </el-menu-item> -->
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="info4">
                <template slot="title">
                    <i class="el-icon-video-camera-solid"></i>
                    <span>任务学院</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="taskCenter">
                        <i class="el-icon-s-ticket"></i>
                        <span slot="title">任务中心</span>
                    </el-menu-item>
                    <el-menu-item index="sd" :disabled="disFalse">
                        <i class="el-icon-s-ticket"></i>
                        <span slot="title">账号规则</span>
                    </el-menu-item>

                </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="info2">
                <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>企业号管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="enterpriseInfo">
                        <i class="el-icon-office-building"></i>
                        企业信息
                    </el-menu-item>
                    <el-menu-item index="application" :disabled="disabled">
                        <i class="el-icon-s-goods"></i>
                        <span slot="title">厂商代理申请</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu> -->



            <!-- <el-submenu index="user">
                <template slot="title">
                    <i class="el-icon-s-grid"></i>
                    <span>切换账户</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item v-for="item in userList" :key="item.user_id" @click="hduser(item.user_id)">
                        <img :src="item.head_pic" style="width: 18px;height: 18px;border-radius: 50%;" alt="">
                        {{ item.nickname }}
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu> -->





            <el-menu-item index="theMessage">
                <i class="el-icon-message"></i>

                <span>消息中心</span>
                <span
                    style="border: 1px solid red;border-radius: 100px;font-size: 12px;color: red;margin-left:6px;padding: 0px 3px;"
                    v-show="totalPage > 0">{{
                    totalPage }}</span>

            </el-menu-item>

            <!-- <el-menu-item index="withdrawal">
                <i class="el-icon-message"></i>
                <span slot="title">账户提现</span>

            </el-menu-item> -->


        </el-menu>
    </div>
</template>

<script>
import { getBusManage, getUserMsg, getUserById } from '@/api/user.js'

export default {
    data() {
        return {
            info: {},
            distribut_type: '',
            disabled: false,
            thePath: 'home',
            userList: [],
            menShow: false,
            msgList: [],
            totalPage: '',
            show: false,
            disFalse: true,
            classify: 2,
            // activeMenu: '',
        }
    },
    async created() {
        try {
            let user_id = localStorage.getItem('user_id')
            // let res =await this.$http('/mini/ApiConnect/switchUser')
            // let res = await this.$http('/mini/ApiConnect/userList',{user_id})
            // if (res.status == 1) {
            //     this.userList = res.result
            // }

        } catch (error) {

        }


    },
    computed: {
        activeMenu() {
            let str = this.$route.path;
            let parts = str.split("/"); // 将字符串按照 "/" 分割成数组  
            let application = parts[2]; // 获取数组的第二个元素，即 "application"  
            return application;
        },
    },
    mounted() {
        this.$bus.$on('userData', (data) => {
            this.info = data.userData
            if (this.info.is_distribut == 0) {
                this.show = true
                this.disabled = true
            }
            this.distribut_type = this.info.distribut_type
            console.log(data, 'datasa===========');
        });

        this.$bus.$on('userMsg', (allData) => {
            console.log(allData, 'datasa===========2222');

            this.msgList = allData.userMsg.data
            this.totalPage = allData.userMsg.totalPage
        });
        // getUserById().then((res) => {
        //     this.info = res.data.data
        //     if (res.data.data.is_distribut == 0) {
        //         this.show = true
        //         this.disabled = true
        //     }
        //     this.distribut_type = res.data.data.distribut_type
        // })
        this.$eventBus.$on('eventName', (data) => {
            this.totalPage = data
            // 处理事件  
        });
        let params = {
            msg_type: 1,
            is_read: 0,
            page: 1,
            size: 100000,
        }
        // getUserMsg(params).then((res) => {

        //     this.msgList = res.data.data
        //     this.totalPage = res.data.totalPage

        // })
        // getBusManage({ page: 1, size: 100 }).then((res) => {
        //     if (res.data.data.length <= 0) {
        //         this.menShow = false
        //     } else {
        //         this.menShow = true
        //     }
        // })
    },
    props: ['marketChekoutFlag'],
    watch: {
        marketChekoutFlag(newVal, old) {
            if (newVal == 2) {
                // this.thePath = 'home'
            } else {
                // this.thePath = 'message'

            }
        },
    },
    methods: {
        goTou() {
            // if()
            this.$router.push({
                path: 'contribute'
            })
        },
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        async select(e) {
            // this.activeMenu2 = e
            // if (typeof e !== 'number') return
            if (e == 'home') {
                this.classify = 1
            } else {
                this.classify = 2
            }


        },
        hduser(id) {

            let params = {
                user_id: id
            }
            this.$http('/mini/ApiConnect/switchUser', params).then(res => {
                if (res.status == 1) {
                    localStorage.setItem('user_id', id)
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.$router.go(0)
                }
            })


        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 13vw;
    height: 1330px;
    background-color: white;
}

.content2 {
    width: 13vw;
    height: 135vh;
    background-color: white;
}

.item {
    margin-top: 12px;
    margin-right: 4px;

}

.el-menu-item {
    min-width: 170px;
}
</style>