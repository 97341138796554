<template>
  <div>
    <div class="contentBox">
      <div class="content">
        <div class="content-left" style="padding-right: 90px">
          <div style="padding-top: 43px">
            <!-- <img src="../assets/zhnlImg/Logo@1x.png" style="width: 100%;height: 30%;" alt=""> -->
          </div>
          <div>
            <!-- <img src="../assets/zhnlImg/平台服务热线_ 0668-3330000@1x.png" alt=""
                            style="width: 100%;height: 30%;padding-top: 30px;"> -->
          </div>
        </div>
        <div class="content-item">
          <div style="padding: 8px; color: white; font-weight: 900; font-size: 18px">交易指南</div>
          <div style="padding: 10px; color: white; color: gray">交易流程</div>
          <div style="padding: 10px; color: white; color: gray">支付方式</div>
          <div style="padding: 10px; color: white; color: gray">合同签署</div>
          <div style="padding: 10px; color: white; color: gray">发票说明</div>
        </div>
        <div class="content-item">
          <div style="padding: 8px; color: white; font-weight: 900; font-size: 18px">使用指南</div>
          <div style="padding: 10px; color: white; color: gray" @click="toRegister">注册账号</div>
          <div style="padding: 10px; color: white; color: gray" @click="enterInfo">企业认证</div>
        </div>
        <div class="content-item">
          <div style="padding: 8px; color: white; font-weight: 900; font-size: 18px">服务中心</div>
          <div style="padding: 10px; color: white; color: gray">售后服务</div>
          <div style="padding: 10px; color: white; color: gray">积分规则</div>
          <div style="padding: 10px; color: white; color: gray">常见问题</div>
        </div>
        <div class="content-item">
          <div style="padding: 8px; color: white; font-weight: 900; font-size: 18px">平台规则</div>
          <div style="padding: 10px; color: white; color: gray">注册服务协议</div>
          <div style="padding: 10px; color: white; color: gray">商户入住协议</div>
          <div style="padding: 10px; color: white; color: gray">免责声明</div>
          <div style="padding: 10px; color: white; color: gray">隐私政策</div>
        </div>
        <div class="content-item">
          <div style="padding: 8px; color: white; font-weight: 900; font-size: 18px">关于我们</div>
          <div style="padding: 10px; color: white; color: gray">平台介绍</div>
          <div style="padding: 10px; color: white; color: gray">联系我们</div>
        </div>
        <div class="content-item">
          <div>
            <!-- <img src="../assets/zhnlImg/二维码 - 公众号 2@1x.png" alt=""> -->
          </div>
          <div style="color: white">
            <!-- 中海南联公众号 -->
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; text-align: center; background-color: rgb(63, 63, 63); color: white">
      <div style="padding: 15px 0px">
        <img src="../assets/zhnlImg/image 12@1x.png" style="padding-right: 3px; vertical-align: middle" alt="" />
        <span style="vertical-align: middle">
          浙公网安备 <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: white; text-decoration: none">浙ICP备2023048993号</a>

          | Copyright © 2022 杭州搜原电子商务有限公司 zhnl.com 版权所有 | 中海南联原材料商城

          <!-- 浙ICP备2023048993号-1 -->
        </span>
      </div>
      <div style="padding: 15px 0px">公司地址：广州市天河区黄埔大道西76号809室810房</div>
      <div style="padding: 15px 0px">危险化学品经营许可证 | 经营证照</div>
    </div>
  </div>
</template>

<script>
import { getContactUs, getWebNav } from "@/api/user";
export default {
  data() {
    return {
      nav: [],
      info: {},
      about: {}
    };
  },
  methods: {
    toRegister() {
      this.$router.push({
        path: "/login"
      });
    },
    enterInfo() {
      this.$router.push("/buyerCenter/enterpriseInfo");
    }
  },
  mounted() {
    // getContactUs().then((res) => {
    //     console.log(res, 'res=====123123');
    //     this.info = res.data.data[0]
    // })
    // getWebNav({ type: 1 }).then((res) => {
    //     this.nav = res.data.data
    // })
  },
  async created() {
    try {
      // let res = await this.$http("/mini/ApiConnect/getWebNav", params);
      // if (res.status == 1) {
      //     this.nav = res.result;
      // }
      // let res2 = await this.$http("/mini/ApiConnect/getContactUs");
      // if (res2.status == 1) {
      //     this.info = res2.result;
      // }
      // let res3 = await this.$http("/mini/ApiConnect/WebConfig");
      // if (res3.status == 1) {
      //     this.about = res3.result;
      // }
    } catch (error) {}
  }
};
</script>

<style lang="scss" scoped>
.contentBox {
  // margin-top: 30px;
  width: 100%;
  height: 318px;
  background-color: rgb(63, 63, 63);
  display: flex;
  justify-content: center;
  position: relative;

  .content {
    width: 1200px;
    display: flex;

    align-items: flex-start;
    border-bottom: 1px solid gray;
    height: 300px;
    display: flex;

    // flex-wrap: wrap;
    .content-left {
      width: 11%;
      height: 100%;
    }

    .content-item {
      width: 13%;
      height: 100%;
      padding-top: 43px;
      padding-right: 50px;
      cursor: pointer;
    }

    .one {
      width: 33.3%;

      .title {
        font-size: 16px;
        color: white;
        margin-top: 38px;
      }

      .lineBox {
        width: 65px;
        margin-top: 16px;

        .line1 {
          width: 20px;
          height: 2px;
          background-color: #ff0000;
        }

        .line2 {
          width: 45px;
          height: 2px;
          background-color: white;
        }
      }

      .info {
        width: 200px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;

        .line1 {
          width: 70px;
          margin-bottom: 10px;
          font-size: 14px;
          color: white;
          cursor: pointer;
        }

        .line1:nth-child(2n) {
          margin-left: 42px;
        }
      }

      .info2 {
        margin-top: 25px;
        position: relative;
        width: 220px;
        height: 113px;
        box-sizing: border-box;

        .imgbox {
          position: absolute;
          top: 16px;
          left: 18px;
          width: 81px;
          height: 81px;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        .line2 {
          margin-bottom: 15px;
          display: flex;
          align-items: center;

          .title2 {
            font-size: 14px;
            color: white;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.bottom {
  height: 50px;
  // border-top: 1px solid #158edf;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  text-align: center;
  line-height: 50px;
  color: white;
}
</style>
