<template>
    <div>
        <div class="store">
            <div style="font-size: 22px;color: #333333;height: 60px;line-height: 80px;padding-left: 8px;">{{ title }}
            </div>
            <!-- <div style="height: 2.5rem; color:#227BFF;font-size: 26px;margin-left: 12px;">严选产品推荐
                    </div> -->
            <div class="store-box">
                <div class="store-item" v-for="item in productsList " @click="skip(item.goodId)">
                    <div style="width: 100%;height: 218px;">
                        <img :src="item.goodsOriginalImg" style="width: 100%;height: 100%;border-radius: 4px;" lt="">
                    </div>
                    <div style="color: #000000;font-size: 12px;padding: 8px;margin-top: 2px;">{{
                item.goodsName }}</div>
                    <div style="color: #FF0C0C;font-size: 18px;padding: 0px 4px;margin-top: 2px;">￥{{
                item.goodsPrice }}/{{
                item.specName }}
                    </div>
                </div>
            </div>
            <!-- </div> -->
            <!-- <div class="productInfo" v-for="(item, index) in productsList" @click="skip(item.goodId)">
                        <product @add="StrictSelectionOfProducts" :img="item.goodsOriginalImg" :title="item.goodsName"
                            :price="item.goodsPrice" :specName="item.specName" :click_count="item.click_count"
                            :item="item" :type="3" /> 
                            
                    </div> -->

            <div style="width: 1200px; margin: 50px auto">
                <el-empty description="没有你想要的商品" v-if="productsList.length < 1"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { getBstRecommendGoods } from '@/api/user'

export default {
    data() {
        return {
            productsList: []
        }
    },
    props: ['title'],
    methods: {
        skip(id) {
            const { href } = this.$router.resolve({
                path: "/detailsPage",
                query: { goods_id: id, productType: 'strictStore' },
            });
            window.open(href, "_blank");

        },
    },
    created() { },
    mounted() {
        getBstRecommendGoods({ type: 3, }).then((res) => {
            this.productsList = res.data.data
        })
    }
}
</script>

<style lang=scss scoped>
.store-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 6px;

    .store-item {
        cursor: pointer;
        margin-top: 13px;
        width: 225px;
        height: 290px;
        // padding: 0px 7px;
        margin-right: 15px;
        border-radius: 4px;
        padding: 0px 0px;
        background: #FFFFFF;
        // box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
        // box-shadow: 2px 2px 2px 2px gray;
    }
}
</style>