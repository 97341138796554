<template>
    <div>
        <VipDialog6 ref="VipDialog6" />

        <transition name="dialog-fade">

            <div v-if="visible" class="my-dialog-overlay">
                <div class="my-dialog">
                    <!-- <div class="dialog1">
                        <div class="dialog1-img" :class="{ bgc: wangNumber == 1 }" @click="changeNumber(1)">
                            <img src="../assets/newImg2/2f77c0161ce376b141f6be53ffe6147.png"
                                style="width: 100%;height: 100%;" alt="">
                        </div>
                    </div> -->
                    <div class="dialog2">
                        <div class="dialog2-img">
                            <img src="../assets/newImg2/Background@1x (3).png" :class="{ bgc: wangNumber == 2 }"
                                style="width: 80%;height: 100%;" alt="">
                        </div>
                    </div>
                    <div class="dialog3">
                        <div style="text-align: right;" @click="close"> <i class="el-icon-error" size="small"></i></div>
                        <div class="dialog3-img">

                            <div ref="qrcode" @click="chufa" style="margin-left: 50px;margin-top: 100px;"
                                id="qrcode-container">
                            </div>
                            <div style="margin-left: 70px;margin-top: 20px;">
                                <img src="../assets/newImg2/Vector@1x (8).png" style="vertical-align: middle" alt="">
                                微信支付
                                <!-- <span style="vertical-align: middle;margin-left: 10px;">微信支付</span> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { busNativePay, getUserById, getStatusByOrderSn1, getStatusByOrderSn, getBusStatusByOrderSn1, getBusStatusByOrderSn } from '../api/user'
import VipDialog6 from '../components/VipDialog6.vue'

export default {
    data() {
        return {
            visible: false,
            total_amount: '0.01',
            order_description: 0,
            wangNumber: 2,
            timer: '',
            term: 1,
            mark: 3,
            is_bus_vip: '',
            is_super_vip: '',
            busId: '',
            useIfXu: 0,
            busIfXu: 0
        }
    },
    props: ['form', 'info', 'dialogVisible'],
    components: {

        VipDialog6
    },
    methods: {
        open() {
            this.$alert('支付成功!', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$router.push({
                        path: '/buyerCenter/qyData'
                    })
                    this.visible = false
                    window.location.reload()


                }
            });
        },
        getCode() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.2)',
            });

            clearInterval(this.timer)

            getUserById().then((res) => {
                this.is_bus_vip = res.data.data.is_bus_vip
                this.is_super_vip = res.data.data.is_super_vip
                busNativePay({ total_amount: this.total_amount, order_description: '一年会员', term: this.term, mark: this.mark, bus_id: this.busId }).then((res) => {
                    loading.close()

                    if (this.useIfXu == 0) {
                        this.timer = setInterval(() => {
                            getBusStatusByOrderSn({ orderNo: res.data.data.orderSn, }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false

                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    } else {
                        this.timer = setInterval(() => {
                            getBusStatusByOrderSn1({ orderNo: res.data.data.orderSn, }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false

                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    }
                    new QRCode(this.$refs.qrcode, {
                        text: res.data.data.codeUrl,
                        width: 158,
                        height: 158
                    });

                })
            })

        },
        changeNumber(id) {

            clearInterval(this.timer)
            this.wangNumber = id

            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.2)',
            });

            if (id == 1) {
                this.mark = 2
                const container = document.getElementById('qrcode-container');
                container.innerHTML = ''; // 清空容器 
                this.total_amount = 0.01
                busNativePay({ total_amount: this.total_amount, order_description: '一年会员', term: this.term, mark: this.mark, bus_id: this.busId }).then((res) => {
                    if (this.useIfXu == 0) {
                        this.timer = setInterval(() => {
                            getStatusByOrderSn({ orderNo: res.data.data.orderSn, }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false

                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    } else {
                        this.timer = setInterval(() => {
                            getStatusByOrderSn1({ orderNo: res.data.data.orderSn, }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false

                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    }
                    new QRCode(this.$refs.qrcode, {
                        text: res.data.data.codeUrl,
                        width: 158,
                        height: 158
                    });
                    loading.close();

                })
            } else {

                this.mark = 3
                clearInterval(this.timer)

                this.total_amount = 0.01
                const container = document.getElementById('qrcode-container');
                container.innerHTML = ''; // 清空容器 
                busNativePay({ total_amount: this.total_amount, order_description: '一年会员', term: this.term, mark: this.mark, bus_id: this.busId }).then((res) => {
                    if (this.busIfXu == 0) {
                        this.timer = setInterval(() => {
                            getBusStatusByOrderSn({ orderNo: res.data.data.orderSn }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false
                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    } else {
                        this.timer = setInterval(() => {
                            getBusStatusByOrderSn1({ orderNo: res.data.data.orderSn }).then((res) => {
                                if (res.data.trade_state_desc == '支付成功') {
                                    this.$refs.VipDialog6.visible = true
                                    this.visible = false
                                    clearInterval(this.timer)
                                }
                            })
                        }, 3000);
                    }

                    new QRCode(this.$refs.qrcode, {
                        text: res.data.data.codeUrl,
                        width: 158,
                        height: 158
                    });
                    loading.close();

                })
            }
        },
        chufa() {

        },
        close() {
            this.visible = false
            clearInterval(this.timer)
            this.mark = 3
            this.wangNumber = 1
        }
    },
    created() { },
    mounted() {

        // busNativePay({ total_amount: this.total_amount, order_description: '一年会员' }).then((res) => {
        //     console.log(res, 'dddd');

        //     new QRCode(this.$refs.qrcode, {
        //         text: res.data.data.codeUrl,
        //         width: 158,
        //         height: 158
        //     });

        // })
    }
}
</script>

<style lang=scss scoped>
.my-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}


.my-dialog {
    // background-color: pink;
    background: url('../assets/newImg2/84652697ca554fa9636206cbd849f43.png') no-repeat center center;
    // background-image: url('../assets/newImg2/Gold wave abstract background illustration@1x.png');
    // background-size: 100% 100%;
    // background-color: pink;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    width: 720px;
    height: 500px;
    display: flex;
    z-index: 99;

    // background-repeat: no-repeat;
    /* 或者其他宽度 */
    .dialog1 {
        flex: 1;
        position: relative;
        box-sizing: content-box;

        .dialog1-img {
            box-sizing: border-box;
            width: 73%;
            height: 81%;
            position: absolute;
            right: 20px;
            bottom: 60px;
            border-radius: 16px;


        }
    }

    .dialog2 {
        width: 52%;
        position: relative;
        box-sizing: content-box;
        margin-left: 20px;

        .dialog2-img {
            border-radius: 16px;
            width: 80%;
            height: 81%;
            position: absolute;
            left: 60px;
            // right: 40px;
            bottom: 60px;

        }
    }

    .dialog3 {
        flex: 1.3;

        .dialog3-img {
            width: 80%;
            height: 400px;
            margin-top: 50px;
        }
    }
}

.my-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-dialog-body {
    /* 样式 */
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
    // transition: opacity 0.3s;
}

.dialog-fade-enter,
.dialog-fade-leave-to

/* .dialog-fade-leave-active in <2.1.8 */
    {
    // opacity: 0;
}

.bgc {
    // border: 3px solid yellow;
}
</style>