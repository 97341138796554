import request from "../utils/request";
//登录接口
export const login = (data) => {
  return request({
    url: "zhnl/login",
    method: "post",
    data: data
  });
};
//微信提现
export const wxTx = (data) => {
  return request({
    url: "/connect/oauth2/authorize",
    method: "post",
    data: data
  });
};
export const getToken = (data) => {
  return request({
    url: "/zhnl/getToken",
    method: "post",
    data: data
  });
};
//微信登录获取参数
export const getLoginParam = (data) => {
  return request({
    url: "zhnl/getLoginParam",
    method: "get",
    data: data
  });
};
//微信接口获取二维码
// export const getQrconnectm = (data) => {
//     return request({
//     url: `connect/qrconnect?appid=wx18b332ba52498422&redirect_uri=${data.redirect_uri}&response_type=${data.code}&scope=${data.scope}&state=${data.state}#wechat_redirect`,
//      method: "get" ,
// })
// }
// export const WeiChatCallback = (data) => {
//     return request({
//     url: "zhnl/getLoginParam",
//      method: "post" ,
//      data: data
// })
// }

export const loginByCode = (data) => {
  return request({
    url: "zhnl/loginByCode",
    method: "post",
    data: data
  });
};

//发送验证码
export const getCode = (data) => {
  return request({
    url: "/zhnl/getCode",
    method: "post",
    data: data
  });
};
//注册接口
export const toRegist = (data) => {
  return request({
    url: "/zhnl/regist",
    method: "post",
    data
  });
};
//获取底部数据
export const getContactUs = (data) => {
  return request({
    url: "/zhnl/getContactUs",
    method: "get"
  });
};
//获取导航栏
export const getWebNav = (data) => {
  return request({
    url: "/zhnl/getWebNav",
    method: "post",
    data
  });
};
//获取产品行情数据
export const getBstGoodsGategory = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategory",
    method: "get",
    data
  });
};

//获取原材料商品分类
export const getAllRawMaterial = (data) => {
  return request({
    url: "/zhnl/getAllRawMaterial",
    method: "get",
    data
  });
};
//获取最新报价
export const getBstQuotedPrice = (data) => {
  return request({
    url: "/zhnl/getBstQuotedPrice",
    method: "post",
    data
  });
};

//主页最新报价
export const getBstUserGoods = (data) => {
  return request({
    url: "/zhnl/getBstUserGoods",
    method: "post",
    data
  });
};

//能源主流产品市场价格
export const getMainStreamGoods = (data) => {
  return request({
    url: "/zhnl/getMainStreamGoods",
    method: "post",
    data
  });
};
// 2--化工产品主流市场价格

export const getMainStreaMchemicalIndustryGoods = (data) => {
  return request({
    url: "/zhnl/getMainStreaMchemicalIndustryGoods",
    method: "post",
    data
  });
};
//化工市场价格行情 3-左
export const getArticle = (data) => {
  return request({
    url: "/zhnl/getArticle",
    method: "post",
    data
  });
};
//原材料商城商品数据接口
export const getRawMaterialByBrId = (data) => {
  return request({
    url: "/zhnl/getRawMaterialByBrId",
    method: "post",
    data
  });
};
//首页行业资讯接口
export const getAllArticle = (data) => {
  return request({
    url: "/zhnl/getAllArticle",
    method: "post",
    data
  });
};

//原材料商城查看详情接口
export const getGoodsInfoById = (data) => {
  return request({
    url: "/zhnl/getGoodsInfoById",
    method: "post",
    data
  });
};
//严选商城获取全部商品接口
export const getBstGoodsGategoryById = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategoryById",
    method: "post",
    data
  });
};
//产品百科
export const getAllWikipedia = (data) => {
  return request({
    url: "/zhnl/getAllWikipedia",
    method: "post",
    data
  });
};
//收藏接口

export const goodsCollect = (data) => {
  return request({
    url: "/zhnl/goodsCollect",
    method: "post",
    data
  });
};

//原材料商城筛选
export const getRawMaterialByBrName = (data) => {
  return request({
    url: "/zhnl/getRawMaterialByBrName",
    method: "post",
    data
  });
};

//严选商城搜索筛选
export const getBstGoodsGategoryByBrName = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategoryByBrName",
    method: "post",
    data
  });
};
//获取个人信息
export const getUserById = (data) => {
  return request({
    url: "/zhnl/getUserById",
    method: "get",
    data
  });
};

//我的订单
export const getOrderByUserId = (data) => {
  return request({
    url: "/zhnl/getOrderByUserId",
    method: "post",
    data
  });
};

//购物车页面
export const getBstCart = (data) => {
  return request({
    url: "/zhnl/getBstCart",
    method: "post",
    data
  });
};
//获取视频
export const getVodById = (data) => {
  return request({
    url: "/zhnl/getVodById",
    method: "post",
    data: data
  });
};

//收货地址
export const getRegion = (data) => {
  return request({
    url: "/zhnl/getRegion",
    method: "post",
    data: data
  });
};

//新增收货地址
export const addUserAddress = (data) => {
  return request({
    url: "/zhnl/addUserAddress",
    method: "post",
    data: data
  });
};
//查询收货地址
export const getUserAddressByUserId = (data) => {
  return request({
    url: "/zhnl/getUserAddressByUserId",
    method: "post",
    data: data
  });
};
//修改收货地址
export const updateUserAddress = (data) => {
  return request({
    url: "/zhnl/updateUserAddress",
    method: "post",
    data: data
  });
};
//删除收货地址
export const delUserAddress = (data) => {
  return request({
    url: "/zhnl/delUserAddress",
    method: "post",
    data: data
  });
};
//微信登录回调接口
export const WeiChatCallback = (data) => {
  return request({
    url: "/zhnl/WeiChatCallback",
    method: "post",
    data: data
  });
};
//绑定手机号接口
export const updateUserByCode = (data) => {
  return request({
    url: "/zhnl/updateUserByCode",
    method: "post",
    data: data
  });
};
//产品百科详情

export const getWikipediaById = (data) => {
  return request({
    url: "/zhnl/getWikipediaById",
    method: "post",
    data: data
  });
};
//2.17 获取行业资讯详情
export const getArticleById = (data) => {
  return request({
    url: "/zhnl/getArticleById",
    method: "post",
    data: data
  });
};
//推荐文章

export const getArticleByRem = (data) => {
  return request({
    url: "/zhnl/getArticleByRem",
    method: "post",
    data: data
  });
};
//价格中心导航
export const getAllMarketPrice = (data) => {
  return request({
    url: "/zhnl/getAllMarketPrice",
    method: "get",
    data: data
  });
};
//上传视频
export const uploadVod = (data) => {
  return request({
    url: "/zhnl/uploadVod",
    method: "post",
    data: data
  });
};

//申请成为供应商
export const addSupply = (data) => {
  return request({
    url: "/zhnl/addSupply",
    method: "post",
    data: data
  });
};

// 我的收藏
export const getCollect = (data) => {
  return request({
    url: "/zhnl/getCollect",
    method: "post",
    data: data
  });
};
//修改个人信息
export const updateUserByUserId = (data) => {
  return request({
    url: "/zhnl/updateUserByUserId",
    method: "post",
    data: data
  });
};
//上传头像
export const uploadFile = (data) => {
  return request({
    url: "/zhnl/uploadFile",
    method: "post",
    data: data
  });
};
//上传营业执照
export const uploadBusFile = (data) => {
  return request({
    url: "/zhnl/uploadBusFile",
    method: "post",
    data: data
  });
};
//关注商家
export const getInterestByUserId = (data) => {
  return request({
    url: "/zhnl/getInterestByUserId",
    method: "post",
    data: data
  });
};

//购物车增减
export const updateCart = (data) => {
  return request({
    url: "/zhnl/updateCart",
    method: "post",
    data: data
  });
};
//删除购物车
export const delCart = (data) => {
  return request({
    url: "/zhnl/delCart",
    method: "post",
    data: data
  });
};
//发布产品
export const addGoods = (data) => {
  return request({
    url: "/zhnl/addGoods",
    method: "post",
    data: data
  });
};
//查询产品列表
export const getGoods = (data) => {
  return request({
    url: "/zhnl/getGoods",
    method: "post",
    data: data
  });
};
//发货方式
export const getAllLogisticsType = (data) => {
  return request({
    url: "/zhnl/getAllLogisticsType",
    method: "get",
    data: data
  });
};

//商家主页
export const getShippingMain = (data) => {
  return request({
    url: "/zhnl/getShippingMain",
    method: "post",
    data: data
  });
};
export const getShippingMainGoods = (data) => {
  return request({
    url: "/zhnl/getShippingMainGoods",
    method: "post",
    data: data
  });
};
//重量单位
export const getAllSpec = (data) => {
  return request({
    url: "/zhnl/getAllSpec",
    method: "get",
    data: data
  });
};
//删除产品
export const deleteGoods = (data) => {
  return request({
    url: "/zhnl/deleteGoods",
    method: "post",
    data: data
  });
};

//产品上架
export const groundingGoods = (data) => {
  return request({
    url: "/zhnl/groundingGoods",
    method: "post",
    data: data
  });
};

//产品下架
export const underGoods = (data) => {
  return request({
    url: "/zhnl/underGoods",
    method: "post",
    data: data
  });
};
//产品图片上传
export const uploadFiles = (data) => {
  return request({
    url: "/zhnl/uploadFiles",
    method: "post",
    data: data
  });
};
//忘记密码
export const updatePwd = (data) => {
  return request({
    url: "/zhnl/updatePwd",
    method: "post",
    data: data
  });
};

export const getBstGoodsGategory1 = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategory1",
    method: "get",
    data: data
  });
};

//获取视频列表
export const getAllVod = (data) => {
  return request({
    url: "/zhnl/getAllVod",
    method: "post",
    data: data
  });
};

//获取企业列表
export const getAllBusManage = (data) => {
  return request({
    url: "/zhnl/getAllBusManage",
    method: "get",
    data: data
  });
};
//获取报价价格
export const getRegionQuotation = (data) => {
  return request({
    url: "/zhnl/getRegionQuotation",
    method: "post",
    data: data
  });
};

//获取具体视频内容
export const playVideoById = (data) => {
  return request({
    url: "/zhnl/playVideoById",
    method: "post",
    data: data
  });
};
//获取全部产品资讯
export const getAllGoodsConsult = (data) => {
  return request({
    url: "/zhnl/getAllGoodsConsult",
    method: "post",
    data: data
  });
};
//行业资讯分类
export const getGoodsIndustryAdvisory = (data) => {
  return request({
    url: "/zhnl/getGoodsIndustryAdvisory",
    method: "get",
    data: data
  });
};
//查询产品省份
export const getAreaById = (data) => {
  return request({
    url: "/zhnl/getAreaById",
    method: "post",
    data: data
  });
};

//添加视频评论
export const addVideoCom = (data) => {
  return request({
    url: "/zhnl/addVideoCom",
    method: "post",
    data: data
  });
};
//查询区域
export const getAreaByCatAndIndus = (data) => {
  return request({
    url: "/zhnl/getAreaByCatAndIndus",
    method: "post",
    data: data
  });
};
//根据区域查省份
export const getPriceByAreaId = (data) => {
  return request({
    url: "/zhnl/getPriceByAreaId",
    method: "post",
    data: data
  });
};
//根据二级产品id查名称
export const getGoodsCateNameById = (data) => {
  return request({
    url: "/zhnl/getGoodsCateNameById",
    method: "post",
    data: data
  });
};
//查询资讯详情
export const getGoodsConsultById = (data) => {
  return request({
    url: "/zhnl/getGoodsConsultById",
    method: "post",
    data: data
  });
};
//筛选企业
export const getBusByCatAndIndus = (data) => {
  return request({
    url: "/zhnl/getBusByCatAndIndus",
    method: "post",
    data: data
  });
};
//厂家数据
export const getQuotationByBusId = (data) => {
  return request({
    url: "/zhnl/getQuotationByBusId",
    method: "post",
    data: data
  });
};
//厂商主页
export const getBusManageById = (data) => {
  return request({
    url: "/zhnl/getBusManageById",
    method: "post",
    data: data
  });
};
//厂商申请接口
export const addProxyUser = (data) => {
  return request({
    url: "/zhnl/addProxyUser",
    method: "post",
    data: data
  });
};
//查询厂商列表
export const getBusManage = (data) => {
  return request({
    url: "/zhnl/getBusManage",
    method: "post",
    data: data
  });
};
//报价列表
export const getLatestQuotation = (data) => {
  return request({
    url: "/zhnl/getLatestQuotation",
    method: "post",
    data: data
  });
};
//新增产品报价
export const addLatestQuotation = (data) => {
  return request({
    url: "/zhnl/addLatestQuotation",
    method: "post",
    data: data
  });
};
//获取二级分类
export const getGoodsIndustryByPid = (data) => {
  return request({
    url: "/zhnl/getGoodsIndustryByPid",
    method: "post",
    data: data
  });
};
//批量报价
export const updateAllQuotation = (data) => {
  return request({
    url: "/zhnl/updateAllQuotation",
    method: "post",
    data: data
  });
};
//发布视频
export const addVideo = (data) => {
  return request({
    url: "/zhnl/addVideo",
    method: "post",
    data: data
  });
};
//查询视频列表
export const getAllVodById = (data) => {
  return request({
    url: "/zhnl/getAllVodById",
    method: "post",
    data: data
  });
};
//资讯列表
// export const getAllGoodsConsult = (data) => {
//     return request({
//     url: "/zhnl/getAllGoodsConsult",
//      method: "post" ,
//      data: data
// })
// }
//发布资讯
export const addGoodsConsult = (data) => {
  return request({
    url: "/zhnl/addGoodsConsult",
    method: "post",
    data: data
  });
};
//资讯列表 用户个人
export const getAllGoodsConsultById = (data) => {
  return request({
    url: "/zhnl/getAllGoodsConsultById",
    method: "post",
    data: data
  });
};
//删除资讯
export const delGoodsConsult = (data) => {
  return request({
    url: "/zhnl/delGoodsConsult",
    method: "post",
    data: data
  });
};
//删除视频
export const delVideo = (data) => {
  return request({
    url: "/zhnl/delVideo",
    method: "post",
    data: data
  });
};
//消息中心
export const getUserMsg = (data) => {
  return request({
    url: "/zhnl/getUserMsg",
    method: "post",
    data: data
  });
};
//申请企业信息
export const addPersonBus = (data) => {
  return request({
    url: "/zhnl/addPersonBus",
    method: "post",
    data: data
  });
};
//已读全部信息
export const UpdateMsgRead = (data) => {
  return request({
    url: "/zhnl/UpdateMsgRead",
    method: "post",
    data: data
  });
};
//删除消息
export const deleteMsgById = (data) => {
  return request({
    url: "/zhnl/deleteMsgById",
    method: "post",
    data: data
  });
};
//热门视频
export const getHotVod = (data) => {
  return request({
    url: "/zhnl/getHotVod",
    method: "post",
    data: data
  });
};
//删除视频评论
export const delVideoComByUserId = (data) => {
  return request({
    url: "/zhnl/delVideoComByUserId",
    method: "post",
    data: data
  });
};
//更新视频行业分类
export const updateQuotByQId = (data) => {
  return request({
    url: "/zhnl/updateQuotByQId",
    method: "post",
    data: data
  });
};
//资讯回复评论
export const addGoodsConsultComment = (data) => {
  return request({
    url: "/zhnl/addGoodsConsultComment",
    method: "post",
    data: data
  });
};
//删除资讯评论
export const delGoodsConComById = (data) => {
  return request({
    url: "/zhnl/delGoodsConComById",
    method: "post",
    data: data
  });
};
//修改厂商信息
export const updateBusManageById = (data) => {
  return request({
    url: "/zhnl/updateBusManageById",
    method: "post",
    data: data
  });
};
//删除厂商
export const delBusById = (data) => {
  return request({
    url: "/zhnl/delBusById",
    method: "post",
    data: data
  });
};
//更新资讯
export const updateGoodsConsultById = (data) => {
  return request({
    url: "/zhnl/updateGoodsConsultById",
    method: "post",
    data: data
  });
};
//根据三个id查历史报价
export const getHistoryPriceByCat = (data) => {
  return request({
    url: "/zhnl/getHistoryPriceByCat",
    method: "post",
    data: data
  });
};
//申请样品
export const appGoodsSample = (data) => {
  return request({
    url: "/zhnl/appGoodsSample",
    method: "post",
    data: data
  });
};
//添加订单
export const addOrder = (data) => {
  return request({
    url: "/zhnl/addOrder",
    method: "post",
    data: data
  });
};

//折线图
export const getHistoryPriceForDateByCat = (data) => {
  return request({
    url: "/zhnl/getHistoryPriceForDateByCat",
    method: "post",
    data: data
  });
};
//加入购物车
export const addBstCart = (data) => {
  return request({
    url: "/zhnl/addBstCart",
    method: "post",
    data: data
  });
};
//查最低价
export const getQuotByDate = (data) => {
  return request({
    url: "/zhnl/getQuotByDate",
    method: "post",
    data: data
  });
};
//获取营业执照文字信息
export const BusOcr = (data) => {
  return request({
    url: "/zhnl/BusOcr",
    method: "post",
    data: data
  });
};
//行业查产品
export const getAllGoodsConsultByInId = (data) => {
  return request({
    url: "/zhnl/getAllGoodsConsultByInId",
    method: "post",
    data: data
  });
};
//评论校验
export const getComAudit = (data) => {
  return request({
    url: "/zhnl/getComAudit",
    method: "post",
    data: data
  });
};
//审核视频
export const getVideoAudit = (data) => {
  return request({
    url: "/zhnl/getVideoAudit",
    method: "post",
    data: data
  });
};
//审核视频是否通过
export const getVodAuditResult = (data) => {
  return request({
    url: "/zhnl/getVodAuditResult",
    method: "post",
    data: data
  });
};
//
export const getWaterMarkVideoById = (data) => {
  return request({
    url: "/zhnl/getWaterMarkVideoById",
    method: "post",
    data: data
  });
};
//点赞
export const addLikeById = (data) => {
  return request({
    url: "/zhnl/addLikeById",
    method: "post",
    data: data
  });
};
//个人主页折线图
export const getUserInfoByUserId = (data) => {
  return request({
    url: "/zhnl/getUserInfoByUserId",
    method: "post",
    data: data
  });
};
export const getVideoInfoByUserId = (data) => {
  return request({
    url: "/zhnl/getVideoInfoByUserId",
    method: "post",
    data: data
  });
};
export const getConsultInfoByUserId = (data) => {
  return request({
    url: "/zhnl/getConsultInfoByUserId",
    method: "post",
    data: data
  });
};

//视频转发
export const addVideoRelayById = (data) => {
  return request({
    url: "/zhnl/addVideoRelayById",
    method: "post",
    data: data
  });
};
//查二级id
export const getFatherCatBySonCat = (data) => {
  return request({
    url: "/zhnl/getFatherCatBySonCat",
    method: "post",
    data: data
  });
};
//查询编辑推荐视频
export const getRecommendVod = (data) => {
  return request({
    url: "/zhnl/getRecommendVod",
    method: "post",
    data: data
  });
};

//首页查询
export const getIndustryIsIndexPlay = (data) => {
  return request({
    url: "/zhnl/getIndustryIsIndexPlay",
    method: "post",
    data: data
  });
};
//获取严选推荐商品
export const getBstRecommendGoods = (data) => {
  return request({
    url: "/zhnl/getBstRecommendGoods",
    method: "post",
    data: data
  });
};
//提现
export const wxSendWallet = (data) => {
  return request({
    url: "/zhnl/wxSendWallet",
    method: "post",
    data: data
  });
};
//全部已读
export const UpdateMsgRead1 = (data) => {
  return request({
    url: "/zhnl/UpdateMsgRead1",
    method: "post",
    data: data
  });
};
//获取主页面视频
export const getIndexPlayVod = (data) => {
  return request({
    url: "/zhnl/getIndexPlayVod",
    method: "post",
    data: data
  });
};

//关注
export const addFollowByUserId = (data) => {
  return request({
    url: "/zhnl/addFollowByUserId",
    method: "post",
    data: data
  });
};
//上架视频
export const updateVodIsPutOnById = (data) => {
  return request({
    url: "/zhnl/updateVodIsPutOnById",
    method: "post",
    data: data
  });
};
//开通会员 微信支付
export const addMember = (data) => {
  return request({
    url: "/zhnl/addMember",
    method: "post",
    data: data
  });
};
//查询订单
export const getStatusByOrderSn = (data) => {
  return request({
    url: "/zhnl/getStatusByOrderSn",
    method: "post",
    data: data
  });
};
//搜索页视频
export const getVodByParam = (data) => {
  return request({
    url: "/zhnl/getVodByParam",
    method: "post",
    data: data
  });
};

//资讯搜索页
export const getGoodsConsultByParam = (data) => {
  return request({
    url: "/zhnl/getGoodsConsultByParam",
    method: "post",
    data: data
  });
};

//获取任务
export const getUserNewbieTaskById = (data) => {
  return request({
    url: "/zhnl/getUserNewbieTaskById",
    method: "post",
    data: data
  });
};
//收藏视频
export const addCollectById = (data) => {
  return request({
    url: "/zhnl/addCollectById",
    method: "post",
    data: data
  });
};
//数据中心折线图
export const getVodDrawById = (data) => {
  return request({
    url: "/zhnl/getVodDrawById",
    method: "post",
    data: data
  });
};
//
export const getVodInfoById = (data) => {
  return request({
    url: "/zhnl/getVodInfoById",
    method: "post",
    data: data
  });
};
//企业认证协议
export const getUserProtocol = (data) => {
  return request({
    url: "/zhnl/getUserProtocol",
    method: "post",
    data: data
  });
};
//微信提现
export const mchToUser = (data) => {
  return request({
    url: "/zhnl/mchToUser",
    method: "post",
    data: data
  });
};
//wx code
export const getWChatCode = (data) => {
  return request({
    url: "/zhnl/getWChatCode",
    method: "get"
  });
};

//视频评论管理
export const getVodCom = (data) => {
  return request({
    url: "/zhnl/getVodCom",
    method: "post",
    data: data
  });
};
//资讯评论
export const getGoodsConsultCom = (data) => {
  return request({
    url: "/zhnl/getGoodsConsultCom",
    method: "post",
    data: data
  });
};
//严选发布
export const addStrictSelGoods = (data) => {
  return request({
    url: "/zhnl/addStrictSelGoods",
    method: "post",
    data: data
  });
};
//免费试用会员
export const addUserBusVip = (data) => {
  return request({
    url: "/zhnl/addUserBusVip",
    method: "post",
    data: data
  });
};
///支付企业会员
export const addBusMember = (data) => {
  return request({
    url: "/zhnl/addBusMember",
    method: "post",
    data: data
  });
};

//查原材料子级
export const getBstGoodsGategory1ByPid = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategory1ByPid",
    method: "post",
    data: data
  });
};
//取消认证
export const removePersonBus = (data) => {
  return request({
    url: "/zhnl/removePersonBus",
    method: "post",
    data: data
  });
};
//原材料全部分类
export const getBstGoodsGategoryAndSon = (data) => {
  return request({
    url: "/zhnl/getBstGoodsGategoryAndSon",
    method: "get"
  });
};
//支付订单查询
export const getOrderById = (data) => {
  return request({
    url: "/zhnl/getOrderById",
    method: "post",
    data: data
  });
};
//删除报价
export const delQuotation = (data) => {
  return request({
    url: "/zhnl/delQuotation",
    method: "post",
    data: data
  });
};
//任务检测
export const checkTask = (data) => {
  return request({
    url: "/zhnl/checkTask",
    method: "post",
    data: data
  });
};
//取消微信绑定手机号
export const unBind = (data) => {
  return request({
    url: "/zhnl/unBind",
    method: "post",
    data: data
  });
};
export const alipayWithdraw = (data) => {
  return request({
    url: "/zhnl/alipayWithdraw",
    method: "post",
    data: data
  });
};
//省市
export const getAllRegion = () => {
  return request({
    url: "/zhnl/getAllRegion",
    method: "get"
  });
};
//支付宝绑定

export const alipayBind = (data) => {
  return request({
    url: "/zhnl/alipayBind",
    method: "post",
    data: data
  });
};
//获取支付宝绑定
export const getAlipayBind = () => {
  return request({
    url: "/zhnl/getAlipayBind",
    method: "get"
  });
};
//原材料查最顶级id
export const getBstGGateFatherById = (data) => {
  return request({
    url: "/zhnl/getBstGGateFatherById",
    method: "post",
    data: data
  });
};
//查原材料页面规格类型
export const getQuotationGName = (data) => {
  return request({
    url: "/zhnl/getQuotationGName",
    method: "post",
    data: data
  });
};
//自动报价
export const addAutoQuotation = (data) => {
  return request({
    url: "/zhnl/addAutoQuotation",
    method: "post",
    data: data
  });
};
//修改报价列表排序
export const updateQuotationSortNum = (data) => {
  return request({
    url: "/zhnl/updateQuotationSortNum",
    method: "post",
    data: data
  });
};
//重复原材料名称
export const CopyQuotation = (data) => {
  return request({
    url: "/zhnl/CopyQuotation",
    method: "post",
    data: data
  });
};

//根据用户ID查询该用户下所有报价的产品名称
export const getGoodsNameByUserId = (data) => {
  return request({
    url: "/zhnl/getGoodsNameByUserId",
    method: "post",
    data: data
  });
};
//
export const addQuotToIndex = (data) => {
  return request({
    url: "/zhnl/addQuotToIndex",
    method: "post",
    data: data
  });
};
//获取黄金会员报价
export const getQuotToIndex = (data) => {
  return request({
    url: "/zhnl/getQuotToIndex",
    method: "post",
    data: data
  });
};
//原材料一级页面
export const getQuotByParams = (data) => {
  return request({
    url: "/zhnl/getQuotByParams",
    method: "post",
    data: data
  });
};
//多个列表表单数据
export const getQutoByCatIds = (data) => {
  return request({
    url: "/zhnl/getQutoByCatIds",
    method: "post",
    data: data
  });
};
//会员续费订单
export const getStatusByOrderSn1 = (data) => {
  return request({
    url: "/zhnl/getStatusByOrderSn1",
    method: "post",
    data: data
  });
};
//原材料折线图
export const getQutoByCatOne = (data) => {
  return request({
    url: "/zhnl/getQutoByCatOne",
    method: "post",
    data: data
  });
};
//大盘搜原

export const getForwardPrice = (data) => {
  return request({
    url: "/zhnl/getForwardPrice",
    method: "post",
    data: data
  });
};
//厂商开通会员
export const busNativePay = (data) => {
  return request({
    url: "/zhnl/busNativePay",
    method: "post",
    data: data
  });
};
//查询厂商
export const getBusStatusByOrderSn = (data) => {
  return request({
    url: "/zhnl/getBusStatusByOrderSn",
    method: "post",
    data: data
  });
};
export const getBusStatusByOrderSn1 = (data) => {
  return request({
    url: "/zhnl/getBusStatusByOrderSn1",
    method: "post",
    data: data
  });
};

//大盘编号
export const getBriefs = (data) => {
  return request({
    url: "/zhnl/getBriefs",
    method: "post",
    data: data
  });
};
//K线图
export const getKline = (data) => {
  return request({
    url: "/zhnl/getKline",
    method: "post",
    data: data
  });
};
//免费使用会员
export const addBusFreeVip = (data) => {
  return request({
    url: "/zhnl/addBusFreeVip",
    method: "post",
    data: data
  });
};
//大盘大类
export const getSymbols = (data) => {
  return request({
    url: "/zhnl/getSymbols",
    method: "post",
    data: data
  });
};
//图库列表
export const getGalleryByPage = (data) => {
  return request({
    url: "/zhnl/getGalleryByPage",
    method: "post",
    data: data
  });
};
//查厂商资讯 视频
export const getVodAndConsultByBusId = (data) => {
  return request({
    url: "/zhnl/getVodAndConsultByBusId",
    method: "post",
    data: data
  });
};
