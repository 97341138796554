<template>
    <div class="store">
        <div style="height: 2.5rem; color:#227BFF;font-size: 26px;margin-left: 12px;">严选产品推荐
        </div>
        <div class="store-box">
            <div class="store-item" v-for="item in productsList " @click="goDetail(item.goodId)">
                <div style="width: 100%;height: 176px;">
                    <img :src="item.goodsOriginalImg" style="width: 99%;height: 100%;border-radius: 4px;" lt="">
                </div>
                <div style="color: #000000;font-size: 12px;padding: 6px;margin-top: 14px;">{{ item.goodsName }}</div>
                <div style="color: #FF0C0C;font-size: 18px;padding: 2px 4px;margin-top: 10px;">￥{{ item.goodsPrice }}/{{
                item.specName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBstGoodsGategoryById } from '@/api/user'


export default {
    data() {
        return {
            page: 1,
            size: 17,
            productsList: [],
            isB: 1,
        }
    },
    methods: {
        goDetail(id) {
            const { href } = this.$router.resolve({
                name: "detailsPage",
                query: { goods_id: id, productType: 'strictStore' },
            });
            window.open(href, "_blank");
        }
    },
    created() { },
    mounted() {
        getBstGoodsGategoryById({ type: 3, user_id: window.localStorage.getItem('user_id'), index: this.isB, page: this.page, cat_one: 0, size: this.size }).then((res) => {
            this.productsList = res.data.data.slice(0, 16)
        })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
    .store {
        width: 1200px;
        margin: auto;
        height: 600px;
        margin-top: 20px;
        margin-bottom: 30px;
        // background-color: #FFFFFF;
        padding: 10px 0px;
        border-radius: 8px;
        opacity: 1;

        background: #FFFFFF;

        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);


        .store-box {
            width: 100%;
            height: 92%;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;
            margin-left: 6px;
            overflow: hidden;
            margin-bottom: 30px;

            .store-item {
                cursor: pointer;
                margin-top: 13px;
                background-color: white;
                width: 192px;
                height: 258px;
                margin-left: 5px;
                // background-color: skyblue;
                border-radius: 4px;
                padding: 2px 0px;
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
                // box-shadow: 2px 2px 2px 2px gray;
            }
        }
    }
}

@media screen and (min-width: 1620px) and (max-width: 50500px) {
    .store {
        width: 1480px;
        margin: auto;
        height: 590px;
        margin-top: 20px;
        // background-color: #FFFFFF;
        padding: 10px 0px;
        border-radius: 8px;
        opacity: 1;
        overflow: hidden;
        background: #FFFFFF;
        margin-bottom: 30px;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);


        .store-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;
            margin-left: 6px;

            .store-item {
                cursor: pointer;
                margin-top: 13px;
                background-color: white;
                width: 200px;
                height: 258px;
                margin-right: 10px;
                // margin-left: 5px;
                // background-color: skyblue;
                border-radius: 4px;
                padding: 2px 0px;
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
                // box-shadow: 2px 2px 2px 2px gray;
            }
        }
    }
}
</style>