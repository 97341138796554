import { render, staticRenderFns } from "./historicalQuotation.vue?vue&type=template&id=533eb8cf&scoped=true"
import script from "./historicalQuotation.vue?vue&type=script&lang=js"
export * from "./historicalQuotation.vue?vue&type=script&lang=js"
import style0 from "./historicalQuotation.vue?vue&type=style&index=0&id=533eb8cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533eb8cf",
  null
  
)

export default component.exports