<template>
  <div>
    <div class="userCard">
      <div class="userContent">
        <div class="left">
          <img src="../assets/02b8050c91227b471d3aedbd55ecb557_1.png" style="width: 110px; height: 110px; border-radius: 50%; padding: 46px 4px" alt="" />
        </div>
        <div class="middle">
          <div class="middle-1">中海南联编辑部</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.userCard {
  margin-top: 80px;
  //   background-color: red;
  width: 100vw;
  height: 280px;
  background-image: url("../assets/newImg3/背景-蓝色@1x.png");
  .userContent {
    width: 1200px;
    height: 280px;
    margin: auto;
    background-color: pink;
    z-index: 10;
    display: flex;
    .left {
      width: 120px;
      height: 100%;
    }
    .middle {
      height: 100%;
      width: 700px;
      padding: 0px 40px;
      .middle-1 {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        margin-top: 46px;
      }
    }
  }
}
</style>
