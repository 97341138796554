<template>
    <div>
        <div class="topBarBox">
            <div class="topBar">
                <div class="left flexRowCenter">
                    <div class="line1" style="cursor: pointer;" @click="toIndex">欢迎来到中海南联</div>
                    <a class="line2" href="/login" v-if="!userId">登录</a>
                    <a class="line2" style="color: #FF0C0C" href="/login2" v-if="!userId">免费注册</a>
                    <a class="line2" href="/buyerCenter/message" style="color: #FF0C0C" v-if="userId">{{ userName
                        }},欢迎登录！</a>

                    <a class="line2" style="margin-left: 6px;" href="#" @click="exitLogin" v-if="userId">退出登录</a>
                </div>

                <div class="right">
                    <img src="../assets/index/Notification.png" style="object-fit: contain; vertical-align: text-top;">
                    <a class="line2" href="/" style="margin-left: 10px;">中海南联首页</a>
                    <!-- <a class="line2" href="/buyerCenter/home">个人主页</a> -->
                    <span class="line2" style="cursor: pointer;" @click="toUser">个人主页</span>
                    <!-- <a class="line2" href="/navigation">网站导航</a> -->
                    <a class="line2" href="http://zhnlh.ydlweb.com">掌上中海南联</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userId: '',
            info: '',
            userName: '',
        }
    },
    methods: {
        toUser() {
            let userId = window.localStorage.getItem('user_id')
            if (userId) {
                this.$router.push(
                    {
                        path: '/buyerCenter/personal'
                    }
                )
            } else {
                this.$message({
                    type: 'warning',
                    message: '请先登录您的账号',
                    offset: 230,
                })
                this.$router.push({
                    path: '/login'
                })
            }
        },
        toIndex() {
            this.$router.push({
                path: '/'
            })
        },
        //个人(企业)信息
        async UserIndexInfo() {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserIndexInfo', {
                user_id: userId
            })
            this.info = res.result
        },

        exitLogin() {
            this.$router.push({
                path: '/login',
                query: {
                    did: '1'
                }

            })
            window.localStorage.removeItem('user_id')
            window.localStorage.removeItem('userName')
            window.localStorage.removeItem('token')
            window.location.reload()
        },
    },
    mounted() {
        this.userId = window.localStorage.getItem('user_id')
        // this.UserIndexInfo()
        this.userName = window.localStorage.getItem('userName')
    }
}
</script>

<style lang="scss">
.flexRowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flexColCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topBarBox {
    background-color: #DDDDDD;

    .topBar {
        display: flex;
        flex-direction: row;
        width: 1200px;
        height: 28px;
        margin: 0 auto;
        line-height: 28px;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        .line2 {
            margin-left: 33px;
            text-decoration: none;
        }



        a:active {
            text-decoration: none;
        }
    }

    .right :hover {
        color: rgb(255, 12, 12) !important;
    }

    .left :hover {
        color: rgb(255, 12, 12) !important;
    }

}
</style>