<template>
    <div style="width: 1200px;margin: auto;margin-top: 120px;" @mouseenter="allMouseEnter()">

        <!-- <div class="left-box">

            <div style="padding-top: 6px;">
                <img src="../assets/newImg/Logo 中海南联原材料视频资讯网@4x.png" style="height: 58px;width: 172px;" alt="">
            </div>
            <div class="left-item" @click="toTargetBox(item, index)" v-for="(item, index) in areaCategoryList"
                :class="{ bgc: wzNum == item.gId }">
                {{ item.label }}
            </div>
        </div> -->
        <div class="content5">
            <VipDialog />
            <!-- <searchBar />

            <div class="classifyBox">
                <classifyBar :classify="3" v-if="!search" />
            </div> -->

            <!-- <div class="location" v-show="!search">
                <span>当前位置：</span>
                <div class="nav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">主页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/strictStore' }">严选商城</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div> -->
            <!-- <div class="pai-top">
                <div class="pai-left">
                    <img src="../assets/theLogo.png" alt="" style="width: 100%;height: 100%;">
                </div>
                <div class="pai-right">
                    <div class="pai-item">
                        丨 手机
                    </div>
                    <div class="pai-item">
                        丨 电脑
                    </div>
                    <div class="pai-item">
                        丨 白油
                    </div>

                    <div class="pai-item">
                        丨 平板
                    </div>
                    <div class="pai-item">
                        丨 液化油
                    </div>
                    <div class="pai-item">
                        丨 手机
                    </div>
                    <div class="pai-item">
                        丨 工业油
                    </div>
                    <div class="pai-item">
                        丨 石油
                    </div>

                </div>
            </div> -->
            <div class="banner" v-show="!search">
                <el-carousel trigger="click" height="365px" arrow="always">
                    <el-carousel-item>
                        <img src="../assets/newImg/01@1x.png"
                            style="width: 100%; height: 355px; border-radius: 20px;" />
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="../assets/newImg/02@1x.png"
                            style="width: 100%; height: 355px; border-radius: 20px;" />
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="../assets/newImg/03@1x.png"
                            style="width: 100%; height: 355px; border-radius: 20px;" />
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="../assets/newImg/04@1x.png"
                            style="width: 100%; height: 355px; border-radius: 20px;" />
                    </el-carousel-item>


                </el-carousel>
            </div>
            <!-- <div class="banner2" style="margin-top: 40px;border-radius: 8px;color: rgb(51, 51, 51);font-size: 18px;">
                <div class="banner2-item">
                    <div style="width: 100%;">
                        <div style="margin: auto;width: 36%; ">
                            <img src="../assets/rhz.png" alt="">
                        </div>
                    </div>
                    <div style="text-align: center;">高价回收</div>
                </div>

                <div class="banner2-item">
                    <div style="width: 100%;">
                        <div style="margin: auto;width: 36%; ">
                            <img src="../assets/rhz.png" alt="">
                        </div>
                    </div>
                    <div style="text-align: center;">液化油</div>
                </div>
                <div class="banner2-item">
                    <div style="width: 100%;">
                        <div style="margin: auto;width: 36%; ">
                            <img src="../assets/rhz.png" alt="">
                        </div>
                    </div>
                    <div style="text-align: center;">工业油</div>
                </div>
                <div class="banner2-item">
                    <div style="width: 100%;">
                        <div style="margin: auto;width: 36%; ">
                            <img src="../assets/rhz.png" alt="">
                        </div>
                    </div>
                    <div style="text-align: center;">高价回收</div>
                </div>
                <div class="banner2-item">
                    <div style="width: 100%;">
                        <div style="margin: auto;width: 36%; ">
                            <img src="../assets/rhz.png" alt="">
                        </div>
                    </div>
                    <div style="text-align: center;">白油</div>
                </div>

            </div> -->

            <!-- <strictProduct ref="strictProduct" v-if="!search" @getUserProduct="StrictSelectionOfProducts" id="wz"
                @getUserProduct2="StrictSelectionOfProducts2" :isBai="isBai" @daBtn="daBtn" @productInfo="productInfo"
                @reset="reset" @searchProduct2="searchProduct" /> -->

            <!-- <div v-if="isBai == 2" id="targetBox">
                <div class="line1 hhu" v-for="(item, index) in productsList">
                    <div class="boxL">
                        <div class="left1">
                            <el-image fit="cover" :src="item.goodsOriginalImg"
                                style="width: 100px; height: 100px"></el-image>
                        </div>
                        <div class="left2">
                            <div>{{ item.goodsName }}</div>
                            <div>
                                {{
                item.locationName
                    ? item.locationName.slice(0, 2)
                    : '未知'
            }}
                            </div>
                            <div class="" v-if="item.shopTypeName">
                                <img :src="$img + item.shopTypeName" style="width: 69px; height: 20px" alt="" />
                            </div>
                        </div>
                        <div class="left3">

                            <div style="padding: 3px 1px">购买可获得积分:{{ item.giveIntegral }}</div>
                            <div style="padding: 3px 1px">兑换商品所需积分:{{ item.needIntegral }}</div>
                            <div style="padding: 3px 1px">物流方式:{{ item.logisticsTypeName }}</div>
                            <div style="padding: 3px 1px">浏览量：{{ item.clickCount }}</div>
                   
                        </div>
                        <div class="left4">
                            ¥{{ item.goodsPrice }}/{{ item.specName }}
                        </div>
                        <div class="left5">
                            <div class="tt5 hhy" @click="skip(item.goodId)">
                                查看详情
                            </div>
                            <div class="tt5 hhy" @click.stop="
                collection(item.goodId, item.isCollect, item)
                ">
                                <i class="el-icon-star-off" v-if="item.isCollect == 0">收藏</i>
                                <i class="el-icon-star-on" v-else>已收藏</i>

                            </div>
                            <div class="tt5 hhy" v-if="item.appRecord == 0" @click="addY(item)"> 申请样品</div>
                            <div class="tt5 hhy" v-if="item.appRecord == 1"> 已申请</div>
                        </div>
                        <div class="left6">
                            <div>
                                <div class="">
                                    <img src="../assets/888.png" style="
                                        width: 184px;
                                        height: 32px;
                                        border-radius: 10px;
                                    " alt="" />
                                </div>
                                <div style="width: 182px; border: 1px solid #4096c7"></div>
                                <div style="color: #4096c7; font-size: 15px">
                                    <span style="margin-right: 5px">联系方式:18929760113</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-empty description="没有你想要的商品" v-if="productsList.length < 1"></el-empty>
            </div> -->
            <div id="targetBox">
                <!-- <div class="productBox"> -->
                <strictBox title="严选推荐" />
                <strictBox2 title="严选推荐" :areaCategoryList2="areaCategoryList" />
            </div>
            <!-- <div class="productBox">
            <div class="productInfo" v-for="(item, index) in productsList" @click="skip(item.goods_id)">
                <product :img="item.original_img" :title="item.goodsName" :price="item.shop_price"
                    :specName="item.spec_name" :click_count="item.click_count" :item="item" />
            </div>
        </div> -->

            <!-- <div class="page">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-sizes="[20, 30]" style="margin: auto;width: 30vw;margin-top: 40px;margin-bottom: 20px;"
                    :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                </el-pagination>
            </div> -->
            <el-dialog :visible.sync="dialogFormVisible2" title="申请样品" width="500">
                <el-form :model="form" ref="ruleFormRef" :rules="rules">
                    <el-form-item label="详细地址" label-width="130px" prop="u_address">
                        <el-input v-model="form.u_address" autocomplete="off" required />
                    </el-form-item>
                    <el-form-item label="联系方式" label-width="130px" prop="u_phone">
                        <el-input v-model="form.u_phone" autocomplete="off" required />
                    </el-form-item>
                    <el-form-item label="申请理由" label-width="130px" prop="app_reason">
                        <el-input v-model="form.app_reason" autocomplete="off" required />
                    </el-form-item>

                </el-form>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="noVideo('ruleFormRef')">取消</el-button>
                        <el-button type="primary" @click="sentProduction('ruleFormRef')">
                            申请
                        </el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
    </div>

</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import strictProduct from '../components/strictProduct.vue'
import product from '../components/product.vue'
import strictBox from '../components/strictBox.vue'
import strictBox2 from '../components/strictBox2.vue'
import VipDialog from '../components/VipDialog.vue'


import getParams from '../utils/getParams'
import { getBstGoodsGategoryByBrName, getBstGoodsGategoryById, goodsCollect, appGoodsSample, getBstGoodsGategory1 } from '@/api/user'
export default {
    data() {


        return {
            wzNum: '',
            areaCategoryList: [],
            cat_one: '',
            rules: {
                u_address: [
                    {
                        required: true,
                        message: '请填写详细地址',
                        trigger: 'change',
                    },
                ],
                u_phone: [
                    {
                        required: true,
                        message: '请填写联系方式',
                        trigger: 'change',
                    },
                ],
                app_reason: [
                    {
                        required: true,
                        message: '请填写申请理由',
                        trigger: 'change',
                    },
                ],





            },
            form: {
                app_reason: '',
                u_phone: '',
                u_address: '',
            },
            dialogFormVisible2: false,
            goods_id: '',
            goodId: '',

            productsList: [],
            page: 1,
            size: 20,
            totalCount: 0,
            isBai: 1,
            isCollect: 100,
            search: '',
            // imgList: ['../assets/index/banner.png'],
            price: 1,
            keyword: '',
            isB: 1,
            totalCount: 0
        }
    },
    created() {
        // let uu = this.$route.query.keywords
        // if (uu) {
        //     this.search = uu
        //     this.keyword = uu
        //     searchProduct(uu)
        // } else {
        //     this.search = ''
        // }
    },
    methods: {
        toTargetBox(e, index) {
            console.log('eee1', e.gId);
            console.log('eee2', index);
            this.wzNum = e.gId
            const targetBox = document.getElementById('wz');
            this.$refs.strictProduct.classifyTap(index, e.gId)
            if (targetBox) {
                window.scrollTo({
                    top: targetBox.offsetTop - 100,
                    behavior: 'smooth' // 平滑滚动（可选）  
                });
            }
        },
        reset() {
            this.StrictSelectionOfProducts(0);
        },
        allMouseEnter() {
            this.$emit('oneMouse')
        },
        handleSizeChange(e) {
            console.log(e, 'e1');
            this.size = e
            getBstGoodsGategoryById({ type: 3, user_id: window.localStorage.getItem('user_id'), index: this.isB, page: this.page, cat_one: 0, size: this.size }).then((res) => {
                this.totalCount = res.data.total_page
                this.productsList = res.data.data
            })
        },
        handleCurrentChange(e) {
            this.page = e
            getBstGoodsGategoryById({ type: 3, user_id: window.localStorage.getItem('user_id'), index: this.isB, page: this.page, cat_one: 0, size: this.size }).then((res) => {
                this.totalCount = res.data.total_page
                this.productsList = res.data.data
            })
        },

        addY(item) {
            console.log(item, '。。。。');

            if (!window.localStorage.getItem('user_id')) {
                this.$message({
                    type: 'warning',
                    message: '请登录后操作'
                })
            } else {
                this.dialogFormVisible2 = true
                this.goodId = item.goodId
            }
            // this.form.app_reason = item.app_reason
            // this.form.u_phone = item.u_phone
            // this.form.u_address = item.u_address
        },
        noVideo() {
            this.dialogFormVisible2 = false
        },
        sentProduction(ruleFormRef) {
            this.$refs[ruleFormRef].validate((valid) => {
                if (valid) {

                    let params = {
                        goods_id: this.goodId,
                        app_reason: this.form.app_reason,
                        u_phone: this.form.u_phone,
                        u_address: this.form.u_address
                    }
                    appGoodsSample(params).then((res) => {
                        if (res.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '申请成功,请耐心等待客服联系'
                            })
                            this.dialogFormVisible2 = false
                            getBstGoodsGategoryById({ type: 3, user_id: window.localStorage.getItem('user_id'), index: this.isB, page: this.page, cat_one: 0, size: this.size }).then((res) => {
                                this.totalCount = res.data.total_page
                                this.productsList = res.data.data
                            })
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.data.msg
                            })
                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });


        },
        collection(goodId, isval) {
            if (!window.localStorage.getItem('user_id')) {
                this.$message({
                    type: 'warning',
                    message: '请登录后操作'
                })
            }
            let params = {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: goodId,
                type: 3
            }
            goodsCollect(params).then((res) => {
                console.log(res, 'eee');
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: res.data.msg
                    })
                } else {

                }
            })
            this.StrictSelectionOfProducts(this.cat_one)
            this.StrictSelectionOfProducts(this.cat_one)
            // this.isCollect = isval
            // let userId = parseInt(window.localStorage.getItem('user_id'))
            // if (isval == 1) {
            //     this.$http('/mini/ApiConnect/setCancelCollectUserGoods', {
            //         user_id: userId,
            //         goods_id: parseInt(goodsId)
            //     }).then((res) => {
            //         this.$notify({
            //             title: '操作成功',
            //             message: res.msg,
            //             type: 'success'
            //         })
            //     })
            // } else {
            //     this.$http('/mini/ApiConnect/setCollectUserGoods', {
            //         user_id: userId,
            //         goods_id: goodsId
            //     }).then((res) => {
            //         this.$notify({
            //             title: '操作成功',
            //             message: res.msg,
            //             type: 'success'
            //         })
            //     })
            // }
            // this.StrictSelectionOfProducts()
        },
        daBtn(num) {
            this.isBai = num
        },
        //点击分类获取数据
        productInfo(id) {
            this.StrictSelectionOfProducts(id)
            this.cat_one = id
            this.wzNum = id
        },
        searchProduct(search, id) {
            this.page = 1
            getBstGoodsGategoryByBrName({ goods_name: search, user_id: window.localStorage.getItem('user_id'), page: this.page, index: this.isB, size: this.size, cat_one: '' }).then((res) => {
                this.productsList = res.data.data
            })
            // this.StrictSelectionOfProducts2(search, id)
        },
        skip(id) {
            // window.open(
            //     '/detailsPage?goods_id=' + id + '&productType=strictStore'
            // )
            const { href } = this.$router.resolve({
                name: "detailsPage",
                query: { goods_id: id, productType: 'strictStore' },
            });
            window.open(href, "_blank");
            // if (!window.localStorage.getItem('user_id')) {
            //     this.$message({
            //         type: 'warning',
            //         message: '请登录后操作'
            //     })
            // } else {
            //     this.$router.push({
            //         path: '/detailsPage',
            //         query: {
            //             goods_id: id,
            //             productType: 'strictStore'
            //         }
            //     })
            // }
        },

        nextclick() {
            this.page = this.page + 1
            this.StrictSelectionOfProducts()
        },

        prevclick() {
            if (this.page > 0) {
                this.page = this.page - 1
                this.StrictSelectionOfProducts()
            }
        },

        currentChange(e) {
            this.page = e
            this.StrictSelectionOfProducts()
        },

        //严选产品
        async StrictSelectionOfProducts(cat_one, isB) {
            // let user_id = localStorage.getItem('user_id')
            console.log(isB, 'isB');
            if (isB) {
                this.isB = isB
            }
            this.cat_one = cat_one
            // let params = {
            //     user_id,
            //     category_id: id,
            //     page: this.page,
            //     size: 25,
            //     type: 3,
            //     price,
            //     keyword: this.keyword
            // }
            // let res = await this.$http(
            //     '/mini/ApiConnect/StrictSelectionOfProducts',
            //     params
            // )
            // this.productsList = ""
            getBstGoodsGategoryById({ type: 3, user_id: window.localStorage.getItem('user_id'), index: this.isB, page: this.page, cat_one: this.cat_one, size: this.size }).then((res) => {
                this.totalCount = res.data.total_page
                this.productsList = res.data.data
            })
            // this.productsList = []
            // this.productsList = this.productsList.concat(res.result)
            // this.totalCount = res.count
        },

        StrictSelectionOfProducts2(isB, search) {
            getBstGoodsGategoryByBrName({ type: 3, user_id: window.localStorage.getItem('user_id'), index: isB, page: this.page, goods_name: search, size: this.size, cat_one: '' }).then((res) => {
                this.totalCount = res.data.total_page
                this.productsList = res.data.data
            })
            // let user_id = localStorage.getItem('user_id')
            // let params = {
            //     user_id,
            //     category_id: id,
            //     page: this.page,
            //     size: 25,
            //     keyword: search
            // }
            // let res = await this.$http(
            //     '/mini/ApiConnect/StrictSelectionOfProducts',
            //     params
            // )
            // this.productsList = []
            // this.productsList = this.productsList.concat(res.result)
            // this.totalCount = res.count
        }
    },
    mounted() {
        getBstGoodsGategory1().then((res) => {
            this.areaCategoryList = res.data.data.slice(0, 11)

        })
        let uu = this.$route.query.keywords
        if (uu) {
            this.$refs.strictProduct.souSearch(uu)
        } else {
            this.StrictSelectionOfProducts(0);

        }
        // this.$isLogin()
        // getParams().keywords
        // if (getParams().keywords) {
        //     //   this.StrictSelectionOfProducts2(decodeURI(getParams().keywords), 0);
        // } else {
        //     //   this.StrictSelectionOfProducts(0);
        // }

        // let btn = document.getElementsByTagName('button')

        // setTimeout(() => {
        //     for (let i = 0; i < btn.length; i++) {
        //         let btn2 = btn[i].disabled = false

        //     }
        // }, 1000)
    },
    components: {
        searchBar,
        classifyBar,
        strictProduct,
        product,
        strictBox,
        strictBox2,
        VipDialog
    }
}
</script>
<style lang="scss">
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
    color: #006eb7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    font-weight: normal;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006eb7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    font-weight: normal;
}
</style>
<style lang="scss" scoped>
.threeD {
    width: 155px;
    padding: 3px;
    background-color: #006eb7;
    color: #f7fefe !important;
    font-size: 80px;
    /*设置字体大小*/
    font-weight: 800;
    /*设置字体粗细*/
    text-shadow: 1px 0px #4096c7, 1px 2px #4096c7, 3px 1px #eee, 2px 3px #4096c7,
        4px 2px #eee, 4px 4px #4096c7;
    /*设置文字阴影*/
}

.content5 {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;

    .banner {

        width: 1193px;
        height: 355px;
        border-radius: 100px;
    }

    .banner2 {
        // width: 820px;
        height: 155px;
        // border-radius: 100px;
        display: flex;
        background-color: white;

        .banner2-item {
            flex: 1;
            padding: 22px 0px;
        }
    }

    .line1 {
        padding-left: 20px;
        margin-top: 10px;
        // border-bottom: 1px solid #e4e4e4;
        height: 160px;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background-color: #fff;

        .boxL {
            display: flex;
            align-items: center;
        }

        .left1 {
            width: 100px;
            height: 100px;
        }

        .left2 {
            width: 150px;
            height: 100px;
            margin-left: 15px;

            .Stype {
                width: 69px;
                height: 20px;
                border-radius: 2px;
                opacity: 1;
                background: linear-gradient(270deg,
                        #ffa80b 0%,
                        #ffc252 48%,
                        #fdefd5 100%);
                font-size: 12px;
                color: #fff;
                box-sizing: border-box;
                padding-left: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &>div:nth-child(2) {
                font-size: 14px;
                padding: 2px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                width: 44px;
                background-color: #0370b7;
                margin-bottom: 10px;
            }
        }

        .left3 {
            width: 180px;
            height: 100px;
            font-size: 14px;
            margin-left: 2%;
        }

        .left4 {
            width: 120px;
            color: #f23c24;
            font-weight: 700;
        }

        .left5 {
            display: flex;

            .tt5 {
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                border-radius: 33px;
                opacity: 1;
                border: 1px solid #006eb7;
                color: #006eb7;
                margin-left: 20px;
            }
        }

        .left6 {
            width: 260px;
            transform: translateX(44px);
            padding: 23px 3px;
            margin-left: 8px;
            box-sizing: border-box;
            position: relative;
            height: 160px;
            background: linear-gradient(121deg,
                    #fff 23%,
                    #e8effd 0,
                    #e8effd 80%,
                    #e8effd 0);

            >div {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-38%, -50%);

                &>div {
                    margin-bottom: 5px;
                }

                &>div:nth-child(1) {
                    font-size: 16px;
                    color: #006eb7;
                    font-weight: 700;
                }

                &>div:nth-child(2),
                &>div:nth-child(3),
                &>div:nth-child(4) {
                    color: #90959f;
                    font-size: 15px;
                }
            }
        }

        .left {
            width: 80%;

            .title2 {
                font-size: 20px;
                cursor: pointer;
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 160px;
                    font-size: 16px;
                    height: 23px;
                    white-space: nowrap;
                    /* 文本不换行 */
                    overflow: hidden;
                    /* 溢出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 文本超出部分用省略号表示 */
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background: #006eb7;
            text-align: center;
            line-height: 36px;
            color: white;
        }
    }
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin: 20px 0;
    display: flex;
    align-items: center;
    transform: translateY(10px);
    border-radius: 100px;

    .nav {}
}

.productBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .productInfo {
        margin-right: 15px;
        cursor: pointer;
        margin-top: -9px;
    }
}

.productInfo:nth-child(5n) {
    margin-right: 0;
}

.page {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 500px) {
    .left-box {
        position: fixed;
        left: 20px;
        top: 130px;

        width: 180px;
        background-color: white;
        height: 600px;
        border-radius: 20px;
        z-index: 99999;

        .left-item {
            width: 80%;
            padding: 12px 0px;
            margin-left: 20px;
            border-bottom: 1px dashed gray;
            cursor: pointer;
        }

        .left-item:hover {
            color: #227BFF !important;

        }

    }
}

@media (min-width: 2000px) {
    .left-box {
        position: fixed;
        left: 170px;
        top: 130px;
        width: 180px;
        background-color: white;
        height: 600px;
        border-radius: 20px;
        z-index: 99999;

        .left-item {
            width: 80%;
            padding: 12px 0px;
            margin-left: 20px;
            border-bottom: 1px dashed gray;
            cursor: pointer;
        }

        .left-item:hover {
            color: #227BFF !important;

        }


    }
}

@media (min-width: 2300px) {
    .left-box {
        position: fixed;
        left: 360px;
        top: 130px;
        width: 180px;
        background-color: white;
        height: 600px;

        border-radius: 20px;
        z-index: 99999;

        .left-item {
            width: 80%;
            padding: 12px 0px;
            margin-left: 20px;
            border-bottom: 1px dashed gray;
            cursor: pointer;
        }

        .left-item:hover {
            color: #227BFF !important;

        }


    }
}

@media (min-width: 2800px) {
    .left-box {
        position: fixed;
        left: 760px;
        top: 130px;
        width: 180px;
        background-color: white;
        height: 600px;

        border-radius: 20px;
        z-index: 99999;

        .left-item {
            width: 80%;
            padding: 12px 0px;
            margin-left: 20px;
            border-bottom: 1px dashed gray;
            cursor: pointer;
        }

        .left-item:hover {
            color: #227BFF !important;

        }


    }
}

.pai-top {
    height: 70px;
    width: 100%;
    display: flex;
    padding: 10px 0px;

    .pai-left {
        flex: 3;
        height: 100%;
        // background-color: red;
    }

    .pai-right {
        flex: 8;
        display: flex;
        height: 100%;
        margin-left: 300px;
        font-size: 20px;
        padding-top: 15px;

        .pai-item {
            height: 70%;
            line-height: 70px;
            width: 80px;
            color: gray;

        }
    }
}

.store {
    width: 1200px;
    margin: auto;
    // height: 610px;
    margin-top: 20px;
    // background-color: #FFFFFF;
    padding: 10px 0px;
    border-radius: 8px;
    opacity: 1;

    // background: #FFFFFF;

    // box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);


    .store-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        align-content: flex-start;
        justify-content: flex-start;
        margin-left: 6px;

        .store-item {
            cursor: pointer;
            margin-top: 13px;
            width: 232px;
            height: 290px;
            // padding: 0px 7px;
            margin-right: 8px;
            border-radius: 4px;
            padding: 0px 0px;
            background: #FFFFFF;
            // box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
            // box-shadow: 2px 2px 2px 2px gray;
        }
    }
}

.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}
</style>
