<template>
    <div class="dayHot" v-show="isP ? isP : isUU">
        <div class="day"> {{ dateFormat() }} 产品最新报价</div>
        <div class="dayText">

            <!-- <vue-seamless-scroll :data="hotList" :class-option="optionLeft" class="seamless-warp2" style="width: 100%;">
                <ul class="item" ref="div1" style="width: 100%;display: flex; align-items: center;">
                    <li v-for="item in hotList" class="lis">
                        <div>{{ item.cat_three_name }}:</div>
                        <div>{{ item.up_price }}</div>
                        <div v-if="item.is_up == 1" style="color: #FF0C0C;">↓{{ item.up_percentage }}%</div>
                        <div v-else style="color: #21A00C;">↑{{ item.up_percentage }}%</div>
                    </li>
                    <li v-if="hotList.length < 1" v-for="item in hotLis" class="lis">
                        <div style="width: 100px;">{{ item }}</div>
                    </li>
                </ul>
            </vue-seamless-scroll> -->

            <vue-seamless-scroll :data="quoteList" class="seamless-warp" style="width: 100%; overflow: hidden"
                :class-option="classOption">
                <div class="contaionBox">
                    <div class="line1" v-for="(item, index) in quoteList" v-if="item.good_name">
                        <div>
                            {{ item.good_name }}：
                        </div>
                        <div class="top2">

                            <!-- <div v-if=" item.is_up==0" style="margin-left: 5px;">{{
                                item.up_price
                                }}</div> -->
                            <div style="color: #21a00c; margin-left: 5px;" v-if="item.is_up == 0">{{ item.up_price
                                }}</div>
                            <div style="color: #ff0c0c; margin-left: 5px;" v-if="item.is_up == 1">{{ item.up_price
                                }}</div>
                        </div>

                        <div class="bottom">
                            <div class="left">
                                <img src="../assets/quotedPrice/a.png" v-if="item.is_up == 0" />
                                <img src="../assets/quotedPrice/c.png" v-if="item.is_up == 1" />
                            </div>
                            <div class="right">
                                <!-- <span v-if="item.is_up == 0">
                                    {{ item.up_percentage }}%
                                </span> -->
                                <span style="color: #21a00c" v-if="item.is_up == 0">
                                    {{ item.up_percentage }}%
                                </span>
                                <span style="color: #ff0c0c" v-if="item.is_up == 1">
                                    {{ item.up_percentage }}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-seamless-scroll>


        </div>
    </div>
</template>

<script>
import { getBstUserGoods } from '@/api/user'
export default {
    props: ['isP', 'quoteList'],
    name: 'dayHot',
    components: {

    },
    data() {
        return {
            hotList: [],
            hotLis: [],
            len: null,
            MainstreamProductsList: [],
            // quoteList: [],

        }
    },

    computed: {

        isUU() {
            return this.$route.name === 'index' ? false : true
        },

        classOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    async created() {
        // let params = {
        //     cat_one: '',
        //     cat_two: '',
        //     cat_three: '',
        // };
        // let res = await this.$http(
        //     "/mini/ApiConnect/randomMainstreamProducts",
        //     params
        // );
        // this.MainstreamProductsList = res.result;
        // this.getIterna()
    },
    methods: {
        getIterna() {
            getBstUserGoods({
                industry1: null,
                industry2: null,
            }).then((res) => {
                this.MainstreamProductsList = res.data.data
            })
        },
        dateFormat() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1 < 10 ?
                '0' + (date.getMonth() + 1) : date.getMonth() + 1
            var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
            // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            return year + '-' + month + '-' + day
        },

    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.contaionBox::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.contaionBox {
    // margin-top: 21px;
    width: 5736px;
    overflow-x: scroll;
    overflow-y: hidden;
    // padding-bottom: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    .line1 {
        // width: 148px;
        padding-right: 20px;
        // margin-right: 10px;
        height: 30px;

        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;

        // border-right: 1px solid #e4e4e4;
        .top2 {
            // width: 150px;
            font-size: 14px;
            text-align: center;
            // width: 238px;
            display: flex;
            align-items: center;
        }

        .bottom {
            // width: 238px;
            // margin-top: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #21a00c;

            span {
                font-size: 14px;
            }

            .left {
                // border-right: 2px solid #e4e4e4;
                margin: 0 5px;
            }

            .right {}
        }

        .bottom2 {
            margin-top: 4px;
            display: flex;
            align-items: center;
            color: #ff0c0c;

            .left {
                padding: 0 10px;
                border-right: 2px solid #e4e4e4;
            }

            .right {
                padding: 0 10px;
            }
        }
    }

    .line1:last-child {}
}

.dayHot {
    width: 100%;
    // height: 30px;
    // background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 20px 0;
    // background-color: #fff;
    // box-shadow: 0 0 10px #ccc;
    box-sizing: border-box;
    padding-left: 10px;
    padding-top: 5px;

    .day {
        width: 180px;
        // height: 16px;
        font-size: 14px;
        opacity: 1;
        color: #000000;
        font-size: 14px;
        // font-weight: 700;
        font-family: "MicrosoftYaHeiLight";
        text-align: left;
        line-height: 16px;
    }

    .dayText {
        font-size: 14px;
        width: calc(100% - 180px);
        color: #000000;

        .seamless-warp2 {
            // overflow: hidden;
            // height: 25px;

            transform: translateY(-7px);
            height: 30px;
            display: flex;

            // align-items: center;
            >div {
                width: auto;
            }

            div {
                display: flex !important;
            }

            ul.item {
                // width: calc(100% + 120px);

                li {
                    float: left;
                    margin-right: 20px;
                    display: flex;
                }
            }

        }
    }
}
</style>