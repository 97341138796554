<template>
    <div class="content">
        <el-menu default-active="message" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
            @select="select" :router="true">
            <el-menu-item index="message" v-if="marketChekoutFlag == 1">
                <i class="el-icon-house"></i>
                <span slot="title">买家首页</span>
            </el-menu-item>
            <el-menu-item index="home" v-if="marketChekoutFlag == 2">
                <i class="el-icon-house"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <!-- <el-menu-item index="theMessage">
                <i class="el-icon-message"></i>
                <span slot="title">消息中心</span>

            </el-menu-item>
            <el-submenu index="order" v-if="marketChekoutFlag == 1">
                <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>我的订单</span>
                </template>
<el-menu-item-group>
    <el-menu-item index="myOrder">
        <i class="el-icon-s-order"></i>
        我的订单
    </el-menu-item>
    <el-menu-item index="carts">
        <i class="el-icon-shopping-cart-full"></i>
        购物车
    </el-menu-item>

</el-menu-item-group>
</el-submenu>

<el-menu-item index="myCollect">
    <i class="el-icon-receiving"></i>
    <span slot="title">我的收藏</span>
</el-menu-item>
<el-menu-item index="systemNotice">
    <i class="el-icon-bell"></i>
    <span slot="title">系统公告推送</span>
</el-menu-item>
<el-menu-item index="help">
    <i class="el-icon-question"></i>
    <span slot="title">帮助管理</span>
</el-menu-item> -->
            <!-- <el-menu-item index="suppliers" v-if="marketChekoutFlag == 2">
                <i class="el-icon-edit"></i>
                <span slot="title">申请成为供应商</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="attention">
                <i class="el-icon-user"></i>
                <span slot="title">关注的商家</span>
            </el-menu-item> -->

            <el-submenu index="info">
                <template slot="title">
                    <i class="el-icon-s-grid"></i>
                    <span>个人中心</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="personal">
                        <i class="el-icon-user"></i>
                        个人信息
                    </el-menu-item>
                    <el-menu-item index="enterpriseInfo">
                        <i class="el-icon-office-building"></i>
                        企业信息
                    </el-menu-item>
                    <!-- <el-menu-item index="address">
                        <i class="el-icon-location-outline"></i>
                        地址管理
                    </el-menu-item> -->
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            thePath: 'message'
        }
    },
    props: ['marketChekoutFlag'],
    watch: {
        marketChekoutFlag(newVal, old) {
            console.log('559', newVal)
            if (newVal == 2) {
                // this.thePath = 'home'
            } else {
                // this.thePath = 'message'

            }
        },
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        select(e) {

        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 200px;
    height: 950px;
    background-color: white;
}
</style>