<template>
    <div>
        <div class="top">
            <div class="left">
                <div class="dd"></div>
                <div class="photoBox">
                    <div class="leftInfo">
                        <img src="../assets/buyerCenter/Ellipse7.png"
                            style="width: 120px; height: 120px; border-radius: 300px;" v-if="!info.head_pic">
                        <img :src="info.head_pic" style="width: 120px; height: 120px; border-radius: 300px;" v-else>
                        <!-- <div class="bottomTxt">
                            企业已认证
                        </div> -->

                    </div>
                    <div class="rightInfo">
                        <div style="font-size: 18px;" v-if="!info.user_name">暂未填写</div>
                        <div v-else style="font-size: 18px; font-weight: 700;">{{ info.user_name }}</div>
                        <div v-if="!info.user_phone">TEL：暂未填写</div>
                        <div v-else><img src="@/assets/buyerCenter/phone.png" style="width: 18px;height: 18px;"
                                alt="">{{
                                info.user_phone }}</div>
                        <div v-if="!info.shop_name">公司：暂未填写</div>
                        <div v-else><img src="@/assets/buyerCenter/Company.png" style="width: 18px;height: 18px;"
                                alt="">{{
                                info.shop_name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div class="line1">
                <div class="title">消息通知</div>
                <div class="topBtn">
                    <div>
                        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    </div>
                    <div>
                        <el-button type="primary" size="small" @click="readAll" round>全部已读</el-button>
                        <el-button type="primary" size="small" @click="delAll" plain round
                            style="background:#fff; color:#006EB7;border:1px solid #006EB7; ">批量删除</el-button>
                    </div>
                </div>

                <div class="title2" v-for="(item, index) in msgList">
                    <div><el-checkbox @change="handleCheckedCitiesChange(index)"
                            v-model="checkedCities[index]"></el-checkbox></div>
                    <div class="title2"
                        @click="skipPage('/chat?meeting_id=' + item.interview_id + '&real_name=' + item.real_name) + '&admin_id=' + item.user_id">
                        <img :src="item.head_pic"
                            style="width: 52px; height: 52px; margin-left: 12px; border-radius: 300px;">
                        <div class="txtBox">
                            <div class="name">{{ item.real_name }}</div>
                            <div class="mes">
                                {{ item.message ? item.message[0].content : '' }}
                            </div>

                        </div>
                        <div class="time">
                            {{ item.last_time }}
                        </div>

                    </div>
                    <div class="delBtn" @click="delBtn(item.interview_id)" style="width: 35px;font-size: 14px;">删除</div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
import { getUserById, getAllArticle } from '@/api/user'
export default {
    data() {
        return {
            info: '',
            msgList: '',
            baseUrl: 'https://zhnl.ydlweb.com',
            checkAll: false,
            checkedCities: [],
            type: 1,//企业 用户端
        }
    },
    methods: {
        // 已读
        readAll() {
            let userId = window.localStorage.getItem('user_id')
            let interview_id = ''
            this.checkedCities.forEach((item, i) => {
                if (item) {
                    interview_id += this.msgList[i].id + ','
                }
            })
            console.log(interview_id);
            let params = {
                meeting_id: interview_id,
                // admin_id: this.admin_id,
                user_id: userId,
                type: 1
            }
            this.$http('/mini/ImChat/readAll', params).then(res => {
                this.$message({
                    message: '已读成功',
                    type: 'success'
                });
            }).cath(err => {
                console.log(err);
            })

        },
        // 批量删除
        delAll() {

            let interview_id = ''
            this.checkedCities.forEach((item, i) => {
                if (item) {
                    interview_id += this.msgList[i].interview_id + ','
                }
            })

            let params = {
                meeting_id: interview_id,
            }
            this.$http('/mini/ImChat/del', params).then(res => {
                if (res.stutas == 1) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: '会话消息不存在',
                        type: 'error'
                    })
                }
            }).cath(err => {
                console.log(err);
            })

        },
        // 删除
        delBtn(val) {
            let params = {
                meeting_id: val,
            }
            this.$http('/mini/ImChat/del', params).then(res => {
                console.log(res);
            }).cath(err => {
                console.log(err);
            })
        },
        // 全选
        handleCheckAllChange(val) {
            console.log(val);

            let a = this.checkedCities.map(item => val);
            console.log(a);
            this.checkedCities = a;
        },
        // 多选
        handleCheckedCitiesChange(val) {
            console.log(val, this.checkedCities[val]);
            this.checkedCities.indexOf(false) == -1 ? this.checkAll = true : this.checkAll = false
        },
        skipPage(page) {
            window.open(page)
        },
        //个人(企业)信息
        async UserIndexInfo() {
            let userId = window.localStorage.getItem('user_id')
            // let res = await this.$http('/mini/ApiConnect/UserIndexInfo', {
            //     user_id: userId
            // })
            // this.info = res.result
            getUserById().then((res) => {
                console.log(res, 'uuuuuuuuuuuu');
                this.info = res?.data.data

            })
        },

        //发送信息
        // async getUserMeeting () {
        //     let userId = window.localStorage.getItem('user_id')

        //     let params = {
        //         meetingType: 1,
        //         // admin_id: this.admin_id,
        //         user_id: userId,
        //     }

        //     let res = await this.$http('/mini/ImChat/getUserMeeting', params)

        //     this.msgList = res.result.row.slice(0, 5)
        //     this.msgList.forEach((e, index) => {
        //         e.head_pic = this.baseUrl + e.head_pic
        //         this.checkedCities.push(false)
        //     })
        // },
    },
    mounted() {

        this.UserIndexInfo()
        // this.getUserMeeting()

    }
}
</script>

<style lang="scss" scoped>
.top {
    width: 1000px;
    height: 235px;
    background-image: url('../assets/buyerCenter/Rectangle.png');
    background-size: 100% 100%;

    .left {
        width: 330px;
        // border: 235px solid;
        // border-right: 120px solid;
        // border-left: 11px solid;
        // border-color: white;
        font-size: 26px;
        position: relative;
        // margin: 20px 0;
        color: #FFFFFF;
        line-height: 50px;
        border-top: none;
        // border-right-color: transparent;
        // filter: drop-shadow(4px 0 13px #00000040);

        .dd {
            position: absolute;
            background-color: #fff;
            width: 400px;
            height: 235px;
            // z-index: 99;
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1)0%, rgba(0, 0, 0, 0.5)66%, rgba(0, 0, 0, 0)99%);
            /* Safari、Chrome */

            background: linear-gradient(to left, rgba(250, 248, 248, 0.2), rgba(253, 253, 253, .4), rgba(253, 253, 253, .7), rgba(253, 253, 253, 1));

        }

        .photoBox {
            position: absolute;
            left: 35px;
            top: 50px;
            width: 100%;
            display: flex;
            // justify-content: space-between;

            .leftInfo {
                // display: flex;
                width: 120px;
            }

            .bottomTxt {
                font-weight: 400;
                font-size: 20px;
                line-height: 23.4px;
                text-align: center;
                color: #006eb7;
            }

            .rightInfo {
                font-weight: 400;
                font-size: 20px;
                line-height: 32.4px;
                text-align: left;
                color: #000;
                margin-top: 14px;
                margin-left: 23px;
            }

            .rightInfo div {
                font-size: 16px;

                >img {
                    margin-right: 7px;
                }
            }
        }
    }

}

.line1 {
    width: 100%;
    background-color: white;
    padding: 7px;
    height: 490px;
    box-sizing: border-box;
    margin-top: 25px;
    box-shadow: 0 4px 4px #00000014;
    position: relative;
    overflow-y: scroll;

    padding-bottom: 20px;

    .line1::-webkit-scrollbar {
        display: none;
    }

    .topBtn {
        height: 60px;
        width: 950px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dddddd;
    }

    .title {
        width: 950px;
        height: 60px;
        border-bottom: 1px solid #d3d2d2;
        line-height: 60px;
        margin: 0 auto;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 700;
    }

    .title2 {
        width: 950px;
        height: 80px;
        border-bottom: 1px solid #d3d2d2;
        line-height: 80px;
        margin: 0 auto;
        padding-left: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;

        .txtBox {
            display: flex;
            flex-direction: column;
            margin-left: 19px;

            .name {
                height: 20px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18.72px;
                text-align: left;
                color: #000;
            }

            .mes {
                font-weight: 400;
                font-size: 12px;
                line-height: 18.72px;
                text-align: left;
                color: #aeaeae;
            }
        }

        .time {
            font-weight: 400;
            font-size: 12px;
            line-height: 18.72px;
            text-align: left;
            color: #aeaeae;
            position: absolute;
            right: 70px;
        }
    }


}
</style>