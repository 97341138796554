<template>
    <div>
        <VipDialog ref="VipDialog" />

        <div class="quotedBox">
            <div class="title">个人信息 </div>

            <div class="line1 ">
                <div class="user">
                    <div style="margin-right: 20px;position: relative;">
                        <img src="@/assets/newImg2/会员标识@1x.png" style="position:absolute;right: 0px;bottom: 5px;" alt=""
                            v-if="info.is_vip">
                        <img :src="info.head_pic" style="width: 60px;height: 60px;border-radius: 50%;padding-top: 5px;"
                            alt="">
                    </div>
                    <div>
                        <div style="display: flex;padding-top: 12px;">
                            <div style="color: #333333;font-size: 14px;">{{ info.user_name }}</div>
                            <div style="color: #006eb7;padding-left: 20px;cursor: pointer;" v-if="!info.is_vip"
                                @click="getVip">
                                认证大v</div>
                        </div>

                        <div style="display: flex;margin-top: 10px;color: #9E9E9E;">
                            <div>关注: <span style="color: black;">{{ info.total_follow_num }}</span></div>
                            <div style="padding-left: 18px;">粉丝: <span style="color: #333333;">{{ info.total_fans_num
                                    }}</span>
                            </div>
                            <div style="padding-left: 18px;">积分: <span style="color: #333333;">{{ info.pay_points
                                    }}</span>
                            </div>
                            <div style="padding-left: 18px;">额度: <span style="color: #333333;">{{ info.total_amount
                                    }}</span>
                            </div>
                            <!-- <div style="padding-left: 18px;">等级: <span style="color: #333333;">LV{{ info.levelId
                                    }}</span>
                            </div> -->
                            <!-- <div style="color: #006eb7;padding-left: 10px;width: 110px;" v-if="info.is_vip">
                                <el-progress :percentage="percentage" style="padding-top: 2px;">
                                </el-progress>

                            </div>
                            <div style="position: relative;left: -10px;">
                                <i class="el-icon-question" style="margin-top: 2px" @mouseleave="handleMouseOver2"
                                    @mouseenter="handleMouseOver1"></i>
                                <div style="position: absolute;top: -37px;left: -100px;z-index: 999;color: gray;font-size: 12px;width: 200px;"
                                    v-show="cateryShow">
                                    <div>
                                        视频贡献度：3点贡献度；
                                    </div>
                                    <div>
                                        资讯贡献度：1点贡献度；
                                    </div>
                                </div>

                            </div> -->
                        </div>
                    </div>

                </div>


                <!-- <div class="left" style="margin-top: 26px; margin-left: 25px;" v-if="!info.shop_name">
                    <div class="yuan"></div>公司名称： 公司未定义

                </div>

                <div class="left" style="margin-top: 26px; margin-left: 25px;" v-else>
                    <div class="yuan"></div>公司名称： {{ info.shop_name }}

                </div> -->

                <div class="left" style="margin-top: 20px; margin-left: 25px;" v-if="!info.user_phone">
                    <div class="yuan"></div>联系方式： 暂未填写

                </div>

                <div class="left" style="margin-top: 20px; margin-left: 25px;" v-else>
                    <div class="yuan"></div>联系方式： {{ info.user_phone }}

                </div>

                <!-- <div class="left" style="margin-top: 20px; margin-left: 25px;" v-if="!info.shop_address">
                    <div class="yuan"></div>公司地址：暂未填写
                </div>
                <div class="left" style="margin-top: 20px; margin-left: 25px;" v-else>
                    <div class="yuan"></div>公司地址：{{ info.shop_address }}
                </div> -->


                <div class="left" style="margin-top: 20px; margin-left: 25px;" v-if="!info.user_email">
                    <div class="yuan"></div>邮箱：暂未填写
                </div>

                <div class="left" style="margin-top: 20px; margin-left: 25px;" v-else>
                    <div class="yuan"></div>邮箱：{{ info.user_email }}
                </div>

                <!-- <div class="left" style="margin-top: 20px; margin-left: 25px;">
                    <div class="yuan"></div>可提现余额：{{ info.user_money }}
                </div> -->
                <div class="left" style="margin-top: 20px; margin-left: 25px;">
                    <el-button type="primary" size="small" @click="skip">修改信息</el-button>
                </div>

                <!-- <div style="margin-top: 100px;margin-left: 400px;">
                    <el-button type="primary" size="small" @click="skip">修改信息</el-button>

                </div> -->
            </div>



        </div>
    </div>
</template>

<script>
import { updateUserByUserId, getUserById, wxSendWallet } from '@/api/user'
import VipDialog from '../components/VipDialog.vue'

export default {
    data() {
        return {
            info: '',
            percentage: 0,
            cateryShow: false,

        }
    },
    components: {

        VipDialog
    },
    methods: {
        getVip() {
            console.log(this.$refs.VipDialog.dialogVisible, 'this.$refs.qrcode');
            this.$refs.VipDialog.visible = true
            this.$refs.VipDialog.getCode()


        },
        handleMouseOver1() {
            this.cateryShow = true
        },
        handleMouseOver2() {
            this.cateryShow = false
        },
        getMoney() {
            wxSendWallet({ total_amount: this.info.user_money, description: '' }).then((res) => {

            })
        },
        skip() {
            // window.location='/buyerCenter/amendPersonal'
            this.$router.push('/buyerCenter/amendPersonal')
        },
        //个人(企业)信息
        async UserIndexInfo() {
            let user_id = window.localStorage.getItem('user_id')

            // let userId = window.localStorage.getItem('user_id')
            // let res = await this.$http('/mini/ApiConnect/UserIndexInfo', {
            //     user_id: userId
            // })
            // this.info = res.result
        },
    },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)'
        });
        getUserById().then((res) => {
            loading.close();

            this.info = res.data.data
            this.percentage = res.data.data.user_exp
        })
        // this.UserIndexInfo()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    height: 950px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
    }


    .line1 {
        padding: 20px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
        position: relative;

        .user {
            display: flex;
            width: 100%;
            height: 70px;
            margin-left: 20px
        }

        .left {
            display: flex;
            align-items: center;
            margin-left: 15px;

            .textBox {
                font-weight: 400;
                font-size: 20px;
                line-height: 23.4px;
                text-align: left;
                color: #000;
                margin-left: 51px;

                .name {
                    margin-bottom: 12px;
                    font-weight: 700;
                    display: flex;
                    font-size: 18px;

                    .yes {
                        margin-left: 40px;
                        width: 86px;
                        height: 21px;
                        border-radius: 10px;
                        opacity: 1;
                        border: 1px solid #006eb7;
                        line-height: 21px;
                        text-align: center;
                        opacity: 1;
                        color: #006eb7;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: "Microsoft YaHei UI";
                    }

                    .no {
                        margin-left: 40px;
                        width: 86px;
                        height: 21px;
                        border-radius: 10px;
                        opacity: 1;
                        border: 1px solid #f11d1d;
                        line-height: 21px;
                        text-align: center;
                        opacity: 1;
                        color: #f11d1d;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: "Microsoft YaHei UI";
                    }

                }
            }

            .yuan {
                // width: 8px;
                // height: 8px;
                // // border-radius: 4px;
                // background: #006eb7;
                width: 2px;
                height: 18px;
                opacity: 1;
                background: #006eb7;
                margin-right: 8px;
            }
        }

        .btn {
            width: 80px;
            height: 28px;
            border-radius: 33px;
            opacity: 1;
            background: #006eb7;
            text-align: center;
            line-height: 28px;
            color: white;
            margin-top: 119px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
        }
    }
}
</style>