<template>
    <div>
        <div class="comment-box">
            <div class="title">评论管理</div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="视频评论" name="first">
                    <el-dialog title="回复" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                        <el-input v-model="comment" autocomplete="off" required style="" maxlength="40" />
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="pushConment2">确 定</el-button>
                        </span>
                    </el-dialog>




                    <div class="video-item" v-for="(item, index) in commentList">
                        <div class="video-left">
                            <div
                                style="width: 70px;height: 70px;padding: 10px;border-radius: 120px;box-sizing: border-box;position: relative;">
                                <img src="@/assets/newImg2/会员标识@1x.png"
                                    style="position:absolute;right: 6px;bottom: 6px;" alt="" v-if="item.is_vip">
                                <img :src="item.head_pic" style="width: 100%;height: 100%;border-radius: 120px;" alt="">
                            </div>

                        </div>
                        <div class="video-middle">
                            <div class="title-1">
                                {{ item.real_name }}
                            </div>
                            <div class="title-2">
                                {{ item.com_content }}

                            </div>
                            <div class="title-3">
                                <div style="display: flex;">
                                    <img src="../assets/newImg/Vector@0.75x.png"
                                        style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                                        alt="">

                                    <div style="margin-left: 3px;line-height: 14px;">{{ item.com_time.slice(0, 10) }}
                                    </div>
                                </div>
                                <div style="margin-left: 20px;line-height: 14px;cursor: pointer;"
                                    @click="toReply(item)">回复</div>
                                <div style="margin-left: 20px;line-height: 14px;" @click="del(item)">删除</div>

                            </div>
                        </div>
                        <div class="video-right">
                            <div style="height: 90px;width: 100%;padding-top: 2px;">
                                <img :src="item.v_img_url" alt="" @click="goVideo(item)"
                                    style="width: 100%;height: 100%;object-fit: cover;border-radius: 10px;cursor: pointer;">
                            </div>
                            <div
                                style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 20px;font-size: 12px;color: #333333;font-weight: 290;padding-top: 3px">
                                {{ item.v_title }}
                            </div>
                        </div>


                    </div>
                    <div style="width: 100%;">

                        <el-pagination v-model:current-page="form.page" v-model:page-size="form.size"
                            style="margin-left: 30%;margin-top: 30px;" :page-sizes="[4, 6]" :page-size="4"
                            layout="total, sizes, prev, pager, next, jumper" :total="form.total"
                            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="资讯评论" name="second">
                    <el-dialog title="回复" :visible.sync="dialogVisible2" width="30%" :before-close="handleClose">
                        <el-input v-model="comment2" autocomplete="off" required style="" maxlength="40" />
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible2 = false">取 消</el-button>
                            <el-button type="primary" @click="pushConment3">确 定</el-button>
                        </span>
                    </el-dialog>

                    <div class="video-item" v-for="(item, index) in consultList">
                        <div class="video-left">
                            <img :src="item.head_pic"
                                style="width: 70px;height: 70px;padding: 10px;border-radius: 120px;box-sizing: border-box;"
                                alt="">
                        </div>
                        <div class="video-middle">
                            <div class="title-1">
                                {{ item.real_name }}
                            </div>
                            <div class="title-2">
                                {{ item.com_content }}

                            </div>
                            <div class="title-3">
                                <div style="display: flex;">
                                    <img src="../assets/newImg/Vector@0.75x.png"
                                        style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                                        alt="">

                                    <div style="margin-left: 3px;line-height: 14px;">{{ item.add_time.slice(0, 10) }}
                                    </div>
                                </div>
                                <div style="margin-left: 20px;line-height: 14px;cursor: pointer;"
                                    @click="toReply2(item)">回复</div>
                                <div style="margin-left: 20px;line-height: 14px;cursor: pointer;" @click="del2(item)">删除
                                </div>

                            </div>
                        </div>
                        <div class="video-right">
                            <div style="height: 90px;width: 100%;padding-top: 2px;">
                                <img :src="item.img_url" alt="" @click="goConsult(item)"
                                    style="width: 100%;height: 100%;object-fit: cover;border-radius: 10px;cursor: pointer;">
                            </div>
                            <div
                                style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 20px;font-size: 12px;color: #333333;font-weight: 290;padding-top: 3px">
                                {{ item.title }}
                            </div>
                        </div>

                    </div>
                    <div style="width: 100%;">

                        <el-pagination v-model:current-page="form2.page" v-model:page-size="form2.size"
                            style="margin-left: 30%;margin-top: 30px;" :page-sizes="[4, 6]" :page-size="4"
                            layout="total, sizes, prev, pager, next, jumper" :total="form2.totalPage"
                            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>
</template>

<script>
import { getVodCom, addVideoCom, delVideoComByUserId, getGoodsConsultCom, delGoodsConComById, addGoodsConsultComment } from '../api/user'
export default {
    data() {
        return {
            activeName: 'first',
            form: {
                page: 1,
                size: 4,
                totalPage: 0,
            },
            form2: {
                page: 1,
                size: 4,
                totalPage: 0,
            },
            commentList: [],
            consultList: [],
            dialogVisible: false,
            dialogVisible2: false,
            v_id: '',
            comment: '',
            comment2: '',
            vcl_id: '',
            v_user_id: '',

        }
    },
    methods: {
        handleClose() {

        },
        goVideo(item) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: item.v_id,

                },
            });
            window.open(href, "_blank");
        },
        goConsult(item) {
            const { href } = this.$router.resolve({
                path: "/titleDetails",
                query: {
                    id: item.id,

                },
            });
            window.open(href, "_blank");
        },

        del(item) {
            console.log(item, 'item');
            delVideoComByUserId({ vcl_id: item.vcl_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '删除成功'
                    })
                    getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
                        if (res.data.code == 200) {
                            this.commentList = res.data.data
                            this.form.total = res.data.totalPage
                        }
                    })
                }
            })
        },

        del2(item) {
            console.log(item, 'item');
            delGoodsConComById({ c_id: item.c_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '删除成功'
                    })
                    getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
                        if (res.data.code == 200) {
                            this.consultList = res.data.data
                            this.form2.totalPage = res.data.totalPage
                            console.log(this.form2.totalPage, 'this.form2.totalPage');
                        }
                    })
                }
            })
        },


        pushConment2() {
            let params = {
                v_id: this.v_id,
                com_content: this.comment,
                p_vcl_id: this.vcl_id,
                reply_vcl_id: this.v_user_id
            }
            addVideoCom(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '回复成功'
                    })
                    this.dialogVisible = false
                    this.comment = ''
                    getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
                        if (res.data.code == 200) {
                            this.commentList = res.data.data
                            this.form.total = res.data.totalPage
                        }
                    })
                }
            })
        },
        pushConment3() {
            let params = {
                con_id: this.p_id,
                rep_user_id: this.rep_user_id,
                com_content: this.comment2,
                p_id: this.con_id
            }
            addGoodsConsultComment(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '回复成功'
                    })
                    this.dialogVisible2 = false
                    this.comment2 = ''
                    getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
                        if (res.data.code == 200) {
                            this.consultList = res.data.data
                            this.form2.totalPage = res.data.totalPage
                        }
                    })
                }
            })
        },
        toReply(data) {
            this.dialogVisible = true
            this.v_id = data.v_id
            this.vcl_id = data.vcl_id
            this.v_user_id = data.v_user_id
        },
        toReply2(data) {
            this.dialogVisible2 = true
            this.rep_user_id = data.user_id
            this.con_id = data.c_id
            this.com_content = this.comment2
            this.p_id = data.id
            // this.v_id = data.v_id
            // this.vcl_id = data.vcl_id
            // this.v_user_id = data.v_user_id
        },
        handleClick(tab, event) {
            console.log(tab, event);
            if (tab.index == 0) {
                getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
                    if (res.data.code == 200) {
                        this.commentList = res.data.data
                        this.form.total = res.data.totalPage

                    }
                })
            } else if (tab.index == 1) {

                getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
                    if (res.data.code == 200) {
                        this.consultList = res.data.data
                        this.form2.totalPage = res.data.totalPage
                        console.log(this.form2.totalPage, 'this.form2.totalPage');
                    }
                })
            }
        },
        handleSizeChange(e) {
            this.form.size = e

            getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
                if (res.data.code == 200) {
                    this.commentList = res.data.data
                    this.form.total = res.data.totalPage

                }
            })
        },
        handleCurrentChange(val) {
            console.log(val, '----');
            this.form.page = val
            getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
                if (res.data.code == 200) {
                    this.commentList = res.data.data
                    this.form.total = res.data.totalPage

                }
            })
        },
        handleSizeChange2(e) {
            this.form2.size = e

            getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
                if (res.data.code == 200) {
                    this.consultList = res.data.data
                    this.form2.totalPage = res.data.totalPage
                    console.log(this.form2.totalPage, 'this.form2.totalPage');
                }
            })
        },
        handleCurrentChange2(val) {
            console.log(val, '----');
            this.form2.page = val
            getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
                if (res.data.code == 200) {
                    this.consultList = res.data.data
                    this.form2.totalPage = res.data.totalPage
                    console.log(this.form2.totalPage, 'this.form2.totalPage');
                }
            })
        },
    },
    created() { },
    mounted() {
        getVodCom({ page: this.form.page, size: this.form.size }).then((res) => {
            if (res.data.code == 200) {
                this.commentList = res.data.data
                this.form.total = res.data.totalPage
                console.log(this.form.total, 'this.form.total');
            }
        })
        getGoodsConsultCom({ page: this.form2.page, size: this.form2.size }).then((res) => {
            if (res.data.code == 200) {
                this.consultList = res.data.data
                this.form2.totalPage = res.data.totalPage
            }
        })

    }
}
</script>

<style lang=scss scoped>
::v-deep .el-tabs__item {
    color: gray;
}

::v-deep .is-active {
    color: #333333 !important;
}

.comment-box {
    width: 100%;
    height: 140vh;
    background-color: white;
    padding: 30px 0px 0px 30px;
    box-sizing: border-box;

    .title {
        font-size: 18px;
        color: #333333;
        font-weight: 500;
    }

    .video-item {
        width: 99.2%;
        height: 120px;
        // background-color: pink;
        display: flex;
        border-bottom: 1px solid #EAEAEA;
        margin-top: 10px;

        .video-left {
            width: 100px;
            height: 100%;
            position: relative;
        }

        .video-middle {
            width: 80%;
            height: 100%;

            .title-1 {
                height: 19px;
                padding: 1px 1px 1px 1px;
                font-weight: 290;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #9E9E9E;
                font-size: 14px;
            }

            .title-2 {
                height: 45px;
                padding: 4px 2px 2px 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-size: 12px;
                color: #000000;
                width: 422px;
            }

            .title-3 {
                display: flex;
                height: 15%;
                padding: 10px 0px 0px 4px;
                font-size: 12px;
                color: #999999;
            }
        }

        .video-right {
            width: 14%;
            height: 100%;
        }

    }
}
</style>