<template>
    <div>
        <div class="quotedBox">
            <div class="title">购物车</div>

            <div class="lineBox">
                <div class="line1" v-for="(item, index) in cartList">
                    <div class="left">
                        <div class="select" v-if="isSelect[index] == 0" @click="select(index, item.id, 3)"></div>
                        <div class="select2" v-if="isSelect[index] == 1" @click="select(index, item.id, 4)">
                            <div class="son"></div>
                        </div>

                        <div class="commodityBox">
                            <div class="left">
                                <img :src="item.original_img" style="width: 133px; height: 133px" />
                            </div>
                            <div class="right">
                                <div>
                                    <div class="commodityTitle">
                                        {{ item.goodsName }}
                                    </div>

                                    <!-- <div class="serve">
                                    <span>服务</span>
                                    <span style="margin-left: 20px;">·七天无理由退货 ·运费险 ·安心购交期险 ·安心购品质险</span>
                                </div> -->
                                    <!-- <div class="serve" v-if="item.goods_sn">
                                    <span>商品号:</span>
                                    <span style="margin-left: 20px;">{{ item.goods_sn }}</span>
                                </div>

                                <div class="serve" v-if="item.order_sn">
                                    <span>商品号:</span>
                                    <span style="margin-left: 20px;">{{ item.order_sn }}</span>
                                </div> -->

                                    <div class="numBox">
                                        <span>数量</span>
                                        <div class="numBtn">x{{ item.goodsNum }}</div>
                                    </div>

                                </div>

                                <div class="price tty">
                                    <div style="font-size: 12px; color: #ff0c0cff">￥</div>
                                    <div style="font-size: 20px; color: #ff0c0cff">
                                        {{ item.goodsPrice }}
                                    </div>
                                    <div style="font-size: 12px; color: #ff0c0cff">
                                        /{{ item.speckeyName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right2">
                        <div class="numBox">
                            <!-- <span>数量</span> -->
                            <div class="numBtn">
                                <div class="min" :class="{ btnBgc2: number <= 1 }"
                                    @click="min(item.goodsNum, item.goodsId, 1)">
                                    -
                                </div>
                                <div class="put">
                                    <el-input v-model="item.goodsNum" style="height: 20px"></el-input>
                                </div>
                                <div class="max" @click="min(item.goodsNum, item.goodsId, 2)">+</div>
                            </div>
                            <el-button type="danger" round style="margin-left: 5px;" size="small"
                                @click="dels(item.goodsId)">删除购物车</el-button>
                        </div>
                    </div>
                </div>
                <el-empty description="购物车空空如也..." v-if="cartList.length < 1"></el-empty>

                <div class="allSelect">
                    <!-- <div class="select" @click="allSelect" v-if="selectFlag == 0"></div>
                    <div class="select2" @click="allSelect" v-if="selectFlag == 1" >
                    <div class="son"></div>
                    </div>
                    <span style="font-size: 16px;margin-left:-20px">全选</span> -->
                    <div class="pirce" style="color: rgb(255, 12, 12);font-size: 16px;">
                        <span>合计：</span>
                        <!-- <span style="font-weight: 500; font-size: 16px; color: rgb(255, 12, 12)">￥{{ allprice }}</span> -->
                        <span style="font-weight: 500; font-size: 16px; color: rgb(255, 12, 12)">￥{{ getAllPrice
                            }}</span>
                    </div>
                    <div class="btn" @click="play">支付</div>
                    <!-- <div class="btn2" @click="del">删除商品</div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBstCart, updateCart, delCart } from '@/api/user'
export default {
    data() {
        return {
            CollectList: "",
            cartList: [
                {
                    goods_name: "www",
                    goods_sn: "123",
                    goods_num: 123,
                    order_sn: "22",
                    goods_price: 2222,
                    spec_key_name: "121121",
                    original_img: "",
                },
            ],
            //数量列表
            number: [],
            //选择列表
            isSelect: [],
            selectFlag: 1,

            //选中价格
            allprice: "",
            ids: "",
        };
    },
    computed: {
        getAllPrice() {
            let price = 0
            this.cartList.forEach((item, index) => {
                // console.log(item,'LLLLLLLLLLLLLL');
                price += item.goodsPrice * item.goodsNum

            })
            return parseInt(price).toFixed(2)
        }
    },

    methods: {
        dels(goods_id) {
            delCart({ goods_id: goods_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.CartAllList()
                }
            })
        },
        play() {
            window.location = "/order";
        },
        min(num, goods_id, type) {
            console.log(num, 'num');
            if (type == 1) {
                num--
            } else if (type == 2) {
                num++
            }
            let params = {
                num: num,
                goods_id: goods_id
            }
            updateCart(params).then((res) => {
                console.log(res, '立立uuuu');
                if (res.data.code == 200) {
                    this.CartAllList()
                }
            })
            this.CartChangeInfo(index, recId, type);
        },

        max(index, recId, type) {
            this.CartChangeInfo(index, recId, type);
        },

        select(index, recId, type) {
            this.CartChangeInfo(index, recId, type);
        },
        del() {
            this.CartDelete();
        },
        allSelect() {
            if (this.selectFlag == 0) {
                this.selectFlag = 1;
            } else {
                this.selectFlag = 0;
            }
            this.CartChangeAll();
        },

        //购物车商品加减和选中
        async CartChangeInfo(index, recId, type) {
            let userId = window.localStorage.getItem("user_id");
            let params = "";
            if (type == 1) {
                params = {
                    user_id: userId,
                    rec_id: recId,
                    goods_number: this.number[index] - 1,
                    is_select: this.isSelect[index],
                };
            }

            if (type == 2) {
                params = {
                    user_id: userId,
                    rec_id: recId,
                    goods_number: this.number[index] + 1,
                    is_select: this.isSelect[index],
                };
            }

            if (type == 3) {
                let select = "";
                if (this.isSelect[index] == 0) {
                    select = 1;
                } else {
                    select = 0;
                }
                params = {
                    user_id: userId,
                    rec_id: recId,
                    goods_number: this.number[index],
                    is_select: select,
                };
            }

            if (type == 4) {
                let select = "";
                if (this.isSelect[index] == 0) {
                    select = 1;
                } else {
                    select = 0;
                }
                params = {
                    user_id: userId,
                    rec_id: recId,
                    goods_number: this.number[index],
                    is_select: select,
                };
            }

            // let res = await this.$http("/mini/ApiConnect/CartChangeInfo", params);
            // this.CartAllList();
        },

        //购物车全选或者不全选
        async CartChangeAll() {
            // let userId = window.localStorage.getItem("user_id");

            // let params = { user_id: userId, is_select: this.selectFlag };
            // let res = await this.$http("/mini/ApiConnect/CartChangeAll", params);

            // this.CartAllList();
        },

        //删除购物车商品
        async CartDelete() {
            // let userId = window.localStorage.getItem("user_id");
            // let ids = this.ids.join(",");
            // let params = { user_id: userId, ids: ids };
            // let res = await this.$http("/mini/ApiConnect/CartDelete", params);

            // this.CartAllList();
        },

        //购物车列表
        async CartAllList() {
            let user_id = window.localStorage.getItem("user_id");
            // let params = { user_id: 653 };
            getBstCart({ user_id }).then((res) => {
                this.cartList = res.data.data
            })
            // let res = await this.$http("/mini/ApiConnect/CartAllList", params);
            // this.allprice = res.allprice;

            // this.cartList = res.result;
            // this.number = [];
            // if (res.result.length > 0) {
            //     res.result.forEach((e, index) => {
            //         this.number.push(e.goods_num);
            //     });
            // }

            // this.isSelect = [];
            // this.ids = [];
            // if (res.result.length > 0) {
            //     res.result.forEach((e, index) => {
            //         this.isSelect.push(e.selected);
            //         if (e.selected == 1) {
            //             this.ids.push(e.goods_id:);
            //         }
            //     });
            // }

            // for (var i = 0; i < this.isSelect.length; i++) {
            //     if (this.isSelect[i] == 0) {
            //         console.log("select1");
            //         this.selectFlag = 0;
            //     }
            // }
        },
    },

    mounted() {
        this.CartAllList();
    },
};
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding-bottom: 30px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .tty {
        display: flex;
        align-items: center;
    }

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 20px;
        box-sizing: border-box;
    }

    .lineBox {
        .line1 {
            position: relative;
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e4e4e4;

            .left {
                display: flex;
                align-items: center;
            }

            .right2 {
                position: absolute;
                right: 0;
                bottom: 20px;

                .numBox {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;

                    .numBtn {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        font-size: 12px;

                        .min {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            font-size: 30px;
                            cursor: pointer;
                            border-radius: 5px 0 0 5px;
                        }

                        .max {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            border-radius: 0 5px 5px 0;
                            color: white;
                            cursor: pointer;
                            font-size: 30px;

                        }

                        .put {
                            height: 30px;
                            width: 60px;
                        }

                        ::v-deep .el-input__inner {
                            height: 30px !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }
                }
            }

            .select {
                width: 14px;
                height: 14px;
                // border-radius: 100px;
                border: 1px solid #000;
                box-sizing: border-box;
                margin-right: 30px;
            }

            .select2 {
                width: 14px;
                height: 14px;
                border: 1px solid #000;
                box-sizing: border-box;
                position: relative;
                // border-radius: 100px;
                // background-color: #000;
                margin-right: 30px;

                .son {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-46%, -52%);
                    width: 10px;
                    height: 10px;
                    // border-radius: 100px;
                    background-color: #000;
                }
            }

            .commodityBox {
                margin-top: 18px;
                display: flex;

                .collectBox {
                    width: 80px;
                    height: 25px;
                    color: white;
                    text-align: center;
                    background-color: #006eb7;
                    line-height: 25px;
                    margin-top: 10px;
                    cursor: pointer;
                }

                .right {
                    margin-left: 23px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .commodityTitle {
                        font-weight: 700;
                        font-size: 26px;
                        line-height: 23.4px;
                        text-align: left;
                        color: #000;
                    }

                    .price {
                        margin-top: 19px;
                    }

                    .serve {
                        font-size: 16px;
                        color: #898989;
                        margin-top: 10px;
                    }

                    .btnBox {
                        display: flex;

                        .btn1 {
                            width: 200px;
                            height: 64px;
                            border-radius: 4px;
                            background: #006eb7;
                            text-align: center;
                            line-height: 64px;
                            color: white;
                            font-size: 24px;
                            margin-right: 6px;
                            margin-top: 30px;
                        }
                    }

                    .checkOutImg {
                        display: flex;
                        align-items: center;
                        margin-top: 18px;

                        .box {
                            width: 82px;
                            height: 82px;
                            margin-right: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .box :last-child {
                            margin-right: 0px;
                        }

                        .imgBorder {
                            border: 5px solid #006eb7;
                            box-sizing: border-box;
                        }
                    }

                    .numBox {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;

                        .numBtn {
                            margin-left: 20px;
                            display: flex;
                            align-items: center;

                            .min {
                                width: 30px;
                                height: 30px;
                                background-color: #006eb7;
                                text-align: center;
                                line-height: 30px;
                                color: white;
                                cursor: pointer;
                            }

                            .max {
                                width: 30px;
                                height: 30px;
                                background-color: #006eb7;
                                text-align: center;
                                line-height: 30px;
                                color: white;
                                cursor: pointer;
                            }

                            .put {
                                height: 30px;
                                width: 60px;
                            }

                            ::v-deep .el-input__inner {
                                height: 30px !important;
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .allSelect {
            display: flex;
            align-items: center;
            margin-top: 30px;
            font-size: 20px;

            .select {
                width: 14px;
                height: 14px;
                box-sizing: border-box;
                // border-radius: 100px;
                border: 1px solid #000;
                box-sizing: border-box;
                margin-right: 26px;
            }

            .select2 {
                width: 14px;
                height: 14px;
                border: 1px solid #000;
                position: relative;
                box-sizing: border-box;

                // border-radius: 100px;
                // background-color: #000;
                margin-right: 26px;

                .son {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-46%, -46%);
                    width: 10px;
                    height: 10px;
                    // border-radius: 100px;
                    background-color: #000;
                }
            }

            .pirce {
                margin-left: 50px;
                display: flex;
                align-items: center;
            }

            .btn {
                width: 80px;
                height: 28px;
                // background-color: #006eb7;
                box-sizing: border-box;
                color: #006eb7;
                border: #006eb7 1px solid;
                border-radius: 20px;
                text-align: center;
                line-height: 28px;

                margin-left: 50px;
                cursor: pointer;
                font-size: 14px;
            }

            .btn:hover {
                background-color: #006eb7;
                color: #fff;
            }

            .btn2 {
                width: 100px;
                height: 40px;
                border: 1px solid #006eb7;
                box-sizing: border-box;
                border-radius: 100px;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                color: #006eb7;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
}
</style>
