<template>
    <div class="content">
        <!-- flexColCenter -->
        <div class="toux" v-if="!userId" style="margin-top: 16px;">
            <div style="width: 68px; height: 68px;padding-top: 40px;">
                <!-- <el-avatar icon="el-icon-user-solid"></el-avatar> -->
                <img src="../assets/zhnlImg/Group 2065@1x.png" style="width: 100%;height: 100%;" alt="">
                <!-- <div>123</div> -->
            </div>
            <!-- <div style=" height: 38px;margin-left: 10px;">
                <div style="font-size: 16px; font-weight: 700;">Hi</div>
                <div style="font-size: 10px;color: #acacac;">欢迎来到中海南联</div>
            </div> -->
        </div>
        <div style=" height: 38px;margin-left: 10px;text-align: center;" v-if="!userId">
            <div style="font-size: 15px;color: #acacac;">Hi,欢迎来到中海南联</div>
        </div>
        <div class="btnBox  topBtn" v-if="!userId">
            <div @click="toLogin" class="btn1">
                立即登录
                <!-- <a class="btn1" href="/login">立即登录</a> -->
            </div>
            <div @click="toLogin2" class="btn1">
                免费注册
                <!-- <a class="btn2" href="/login2">免费注册</a> -->
            </div>
            <!-- <div class="btn3">忘记密码</div> -->
        </div>
        <div class="btnBox flexColCenter" v-else style="margin-top: 55px;">
            <img src="../assets/999.png" style="width: 40px; height: 40px; border-radius: 200px; margin-top: 10px;"
                alt="" v-if="userId == ''">
            <img :src="imgUrl"
                style="width: 40px; height: 40px; border-radius: 200px; margin-top: 10px;cursor: pointer;" alt=""
                v-if="userId != ''" @click="toUser">
            <span style="margin-top: 10px;">{{ userName }}</span>
            <a class="btn1" @click="exitLogin" style="margin-top: 16px;">退出登录</a>

        </div>
        <div class="btnBox2">
            <div class="btn1 ww flexColCenter" @click="skipOpen('/buyerCenter/productPrice')">
                <!-- <img src="../assets/fabu.png" alt="" style="width: 61px;height: 52px;"> -->
                <img src="../assets/图标 发布报价.png" style="width: 32px; height: 32px;">
                <div style="font-size: 14px;margin-top: 6px;">发布报价</div>
            </div>
            <div class="btn1 ww flexColCenter" @click="skipOpen('/buyerCenter/home')">
                <!-- <img src="../assets/gren.png" alt="" style="width: 61px;height: 52px;"> -->
                <img src="../assets/zhnlImg/图标 个人主页.png" style="width: 32px; height: 32px;">
                <div style="font-size: 14px;margin-top: 6px;">个人主页</div>
            </div>
            <!-- <div class="btn1 ww flexColCenter" style="margin-top: 36px;" @click="skipPage('/quotedPrice')">
                <img src="../assets/cpin.png" alt="" style="width: 61px;height: 52px;">
                <img src="../assets/index/r3.png" style="width: 32px; height: 32px;">
                <div style="font-size: 14px;">产品行情</div>
            </div>
            <div class="btn1 ww flexColCenter" style="margin-top: 36px;" @click="skipPage('/strictStore')">
                <img src="../assets/syou.png" alt="" style="width: 61px;height: 52px;">
                <img src="../assets/index/r4.png" style="width: 32px; height: 32px;">
                <div style="font-size: 14px;">所有产品</div>
            </div> -->

        </div>

        <!-- <img src="../assets/index/e.png" style="margin-left: 27px;"> -->

    </div>
</template>

<script>
import { getUserById } from '@/api/user'
export default {
    data() {
        return {
            info: '',
            userId: '',
            IsSupplierFlag: '',
            userName: '',
            imgUrl: ''
        }
    },
    methods: {
        toUser() {
            this.$router.push({
                path: '/buyerCenter/home'
            })
        },
        toLogin() {
            this.$router.push({
                path: '/login',

            })
        },
        toLogin2() {
            this.$router.push({
                path: '/login',
                query: {
                    ifRedis: 1,
                }
            })
        },
        skipOpen(page) {
            let userId = window.localStorage.getItem('user_id')

            if (!userId) {
                // this.skipPage('/login')
                this.$message({
                    type: 'warning',
                    message: '请先登录您的账号',
                    offset: 230,
                })
                this.$router.push({
                    path: '/login'
                })
            } else {

                console.log('??????????');
                window.localStorage.setItem('marketChekoutFlag', '2')
                this.skipPage(page)
                // if (this.IsSupplierFlag == 2) {
                //     this.skipPage('/issueProduct')

                // } else {
                //     this.skipPage('/buyerCenter/suppliers')
                // }

            }
        },

        skipPage(page) {
            // window.localStorage.setItem('marketChekoutFlag', '2')
            // console.log('skipPage', page)
            // window.location = page
            console.log(page, '/、、、、、、');
            const { href } = this.$router.resolve({
                path: page,
            });
            window.open(href, "_blank");
            // this.$router.push({
            //     path: page
            // })
        },
        exitLogin() {
            this.$router.push({
                path: '/login',
                query: {
                    did: '1'
                }

            })
            window.localStorage.removeItem('user_id')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('userName')
            window.localStorage.removeItem('headPic')
            // window.location.reload()

            setTimeout(() => {
                window.location.reload()
            }, 500);

        },

        //是否已成为供应商
        async IsSupplier() {
            console.log('是不是执行了', window.localStorage.getItem('user_id'));
            let user_id = window.localStorage.getItem('user_id')
            if (user_id) {
                getUserById().then((res) => {

                    console.log('是不是执行了', res.data.data.user_status);
                    this.imgUrl = res.data.data.head_pic
                    console.log(res.data);
                    if (res.data.data.admin_type == 2) {
                        this.IsSupplierFlag = 2
                        this.statusTxt = '是'
                    } else if (res.data.data.admin_type == 1) {
                        this.IsSupplierFlag = 1
                        this.statusTxt = '否'
                    }
                    if (res.data.data.user_status == 0) {
                        console.log('进来了吗');
                        this.$message.error('该账号已封禁')
                        this.exitLogin()
                    }
                })
            }


            // let res = await this.$http('/mini/ApiConnect/IsSupplier', { user_id: userId })

            // this.statusTxt = res.msg


            // if(res.msg == "已成为供应商") {
            //     this.IsSupplierFlag = 2
            // }

        },

        //个人(企业)信息
        async UserIndexInfo() {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserIndexInfo', {

                user_id: userId
            })
            this.info = res.result
        },
    },

    mounted() {
        let userId = window.localStorage.getItem('user_id')
        this.userId = userId
        this.userName = window.localStorage.getItem('userName')
        this.imgUrl = window.localStorage.getItem('headPic')
        // this.UserIndexInfo()
        this.IsSupplier()

    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 195px;
    height: 365px;
    background-color: white;
}

.toux {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.topBtn {
    // width: 100% !important;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.btnBox {
    .ww {
        width: 55px !important;
        height: 50px !important;
        font-size: 14px;
        font-weight: 400;
    }

    .btn1 {
        padding: 5px;
        margin-top: 6px;
        // width: 144px;
        // height: 30px;
        // background-color: #E22A36;
        border: 1px solid #5192bf;
        text-align: center;
        line-height: 30px;
        color: #5192bf;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
    }

    .btn1:hover {
        background-color: #006fb6;
        color: #fff;
    }


    .btn2 {
        cursor: pointer;
        padding: 5px;
        // width: 144px;
        // height: 30px;
        background-color: #006fb6;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-size: 14px;
        margin-top: 13px;
        font-weight: 400;

    }

    .btn3 {
        cursor: pointer;

        margin-top: 11px;
        font-size: 10px;
        color: #AEAEAE;
    }
}

.btnBox2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 30px;
    // padding-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;

    .btn1 {
        cursor: pointer;

        // margin-bottom: 20px;
    }

    .btn1:hover {
        color: #006fb6;
    }


}
</style>