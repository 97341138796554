<template>
    <div>
        <div class="quotedBox">
            <div class="title">我的收藏 </div>

            <!-- <div class="line1" v-for="(item, index) in CollectList">
                <div class="left">
                    <div class="title2" @click="skip(item.goods_id, item.user_id)">
                        {{ item.goods_name }}
                    </div>
                    <div class="infoBox">
                        <div class="info">价格：¥ {{ item.goods_price }}/吨</div>
                        <div class="info">规格：吨</div>
                        <div class="info">所在地：{{ item.goods_city }}</div>
                        <div class="info">商品数量：{{ item.goods_num }}吨</div>
                        <div class="info">物流方式：{{ item.name }}</div>
                    </div>

                    <div class="infoBox">
                        <div class="info">{{ item.company_name }}</div>
                        <div class="info">联系人：{{ item.contact_name }}</div>

                    </div>
                </div>
                <a style="cursor: pointer;" class="right" @click="skipPage('/chat?admin_id=' + item.user_id + '&goods_id=' + item.goods_id)">
                    我要询盘
                </a>
            </div> -->
            <div class="line1 hhu" v-for="(item, index) in CollectList">

                <div class="boxL">
                    <div class="left1">
                        <el-image fit="cover" :src="$img + item.company_license"
                            style="width: 100%;height: 100%;"></el-image>
                    </div>
                    <div class="left2">
                        <div>{{ item.goods_name }}</div>
                        <div>{{ item.location_name ? item.location_name.slice(0, 2) : '未知' }}</div>
                        <div class="Stype" v-if="item.shop_type"><img src="@/assets/7771.png"
                                style="width: 69px;height: 22px;" alt=""></div>
                    </div>
                    <div class="left3">
                        <div class="info" v-for="e in item.service" :key="e.id">{{ `${e.title}: ${e.content}` }}</div>
                        <!-- <div class="info">规格：{{ item.spec_name }}</div>
                            <div class="info">品牌：{{ item.location_name }}</div>
                            <div class="info">等级:1111</div>
                            <div class="info">产地:{{ item.logistics_name }}</div> -->
                    </div>
                    <div class="left4">
                        ¥{{ item.shop_price }}/{{ item.spec_name }}
                    </div>
                    <div class="left5">
                        <div class="tt5 hhy" @click="skip(item.goods_id)">查看详情</div>
                        <!-- <div class="tt5" @click.stop="collection(item.goods_id, item.is_collect)">
                                <i class="el-icon-star-off"
                                    v-if="isCollect == 100 ? item.is_collect == 0 : isCollect == 0">收藏</i>
                                <i class="el-icon-star-on" v-else>已收藏</i>
                            </div> -->
                    </div>
                    <div class="left6">
                        <div>{{ item.company_name }}</div>
                        <div><span>公司资质:</span>*</div>
                        <div><span>联系人:</span>{{ item.real_name ? item.real_name : item.contact_name }}</div>
                        <div><span>联系方式:</span>{{ item.company_phone }}</div>
                        <div style="color: #016fb8;"
                            @click="skipPageGoods('/storeHome?admin_id=' + item.admin_id + '&goods_id=' + item.goods_id)">
                            <i class="el-icon-s-shop">商家主页></i>
                        </div>
                    </div>
                </div>

            </div>
            <el-empty description="收藏空空如也..." v-if="CollectList.length < 1"></el-empty>

        </div>
    </div>
</template>

<script>
import { getCollect } from '@/api/user'
export default {
    data() {
        return {
            CollectList: ''
        }
    },

    methods: {
        skipPage(page) {
            window.location = page
        },
        skip(id, admin_id) {
            window.location = '/detailsPage?goods_id=' + id + '&productType=store' + '&adminId=' + admin_id
        },
        //我的收藏
        async getCollect1() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                user_id: userId,
            }
            getCollect(params).then((res) => {
                this.CollectList = res.data.data
            })
            // let res = await this.$http('/mini/ApiConnect/getCollect', params)
            // this.CollectList = res.result.rows
        },
    },
    mounted() {
        this.getCollect1()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    // background-color: white;

    .title {
        font-size: 16px;
        font-weight: 700;
        // border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;

        background-color: #fff;
    }


    // .line1 {
    //     padding: 20px;
    //     border-bottom: 1px solid #e4e4e4;
    //     box-sizing: border-box;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: flex-end;

    //     .left {
    //         width: 90%;

    //         .title2 {
    //             font-size: 20px;
    //             cursor: pointer;
    //         }

    //         .infoBox {
    //             margin-top: 23px;
    //             display: flex;

    //             .info {
    //                 width: 140px;
    //                 font-size: 16px;
    //                 height: 23px;
    //                 white-space: nowrap;
    //                 /* 文本不换行 */
    //                 overflow: hidden;
    //                 /* 溢出部分隐藏 */
    //                 text-overflow: ellipsis;
    //                 /* 文本超出部分用省略号表示 */
    //                 margin-right: 10px;
    //             }
    //         }
    //     }

    //     .right {
    //         width: 120px;
    //         height: 36px;
    //         border-radius: 18px;
    //         background: #006eb7;
    //         text-align: center;
    //         line-height: 36px;
    //         color: white;
    //     }
    // }
    .line1 {
        width: 100%;
        padding-left: 20px;
        border-bottom: 1px solid #e4e4e4;
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 10px;
        height: 160px;

        .boxL {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .left1 {
            width: 100px;
            height: 100px;

        }

        .left2 {
            width: 180px;
            height: 100px;
            margin-left: 15px;

            .Stype {
                width: 69px;
                height: 22px;
                border-radius: 2px;
                opacity: 1;
                // background: linear-gradient(270deg, #ffa80b 0%, #ffc252 48%, #fdefd5 100%);
                font-size: 12px;
                color: #fff;
                box-sizing: border-box;
                padding-left: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &>div:nth-child(2) {
                font-size: 14px;
                padding: 2px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                width: 44px;
                background-color: #0370b7;
                margin-bottom: 10px;
            }

        }

        .left3 {
            width: 180px;
            height: 100px;
            font-size: 14px;
        }

        .left4 {
            width: 120px;
            color: #f23c24;
            font-weight: 700;
        }

        .left5 {
            display: flex;

            .tt5 {
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                border-radius: 33px;
                opacity: 1;
                border: 1px solid #006eb7;
                color: #006eb7;
                margin-left: 20px;
            }
        }

        .left6 {
            width: 320px;
            padding: 16px 0 20px 15px;
            margin-left: 8px;
            box-sizing: border-box;
            background-color: #e8effd;
            height: 160px;

            &>div {
                margin-bottom: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                color: #006eb7;
                font-weight: 700;
            }

            &>div:nth-child(2),
            &>div:nth-child(3),
            &>div:nth-child(4) {
                color: #90959f;
                font-size: 14px;
            }

        }

        .left {
            width: 80%;

            .title2 {
                font-size: 20px;
                cursor: pointer;
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 160px;
                    font-size: 16px;
                    height: 23px;
                    white-space: nowrap;
                    /* 文本不换行 */
                    overflow: hidden;
                    /* 溢出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 文本超出部分用省略号表示 */
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background: #006eb7;
            text-align: center;
            line-height: 36px;
            color: white;
        }
    }
}
</style>