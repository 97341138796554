<template>
  <div style="background-color: white; margin-left: 12px; margin-top: 12px; padding-bottom: 12px">
    <VipDialog3 ref="VipDialog3" />
    <VipDialog5 ref="VipDialog5" />
    <VipDialog7 ref="VipDialog7" />
    <VipDialog8 ref="VipDialog8" />
    <VipDialog9 ref="VipDialog9" />
    <allTitle ref="allTitle" />
    <el-dialog title="提示" :visible.sync="productDialog" width="430px">
      <el-upload
        class="upload-demo"
        drag
        action="https://www.zhnl.com/zhnl/getExcelQuota"
        multiple
        style="width: 100%"
        :before-remove="beforeRemove"
        :limit="3"
        :file-list="fileList"
        :on-success="handleSuccess"
        :headers="headerObj"
        accept=".excel,.xls"
      >
        <i class="el-icon-upload"></i>

        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过5M</div>
        <div class="el-upload__tip" slot="tip"><a href="https://www.zhnl.com/video/xml/%E6%8A%A5%E4%BB%B7%E5%8D%95%E6%A8%A1%E6%9D%BF.xls">下载报价单模板</a></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productDialog = false">取 消</el-button>
        <el-button type="primary" @click="productDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量新增报价" :visible.sync="productDialog2" width="99vw" top="80px" @close="dialogClose" :close-on-click-modal="false">
      <el-button type="primary" style="float: right" @click="addOther"> 新增报价</el-button>

      <el-form :model="seaList" ref="ruleFormRef2" :rules="seaList.rules3">
        <el-table :data="seaList.batchList">
          <el-table-column prop="date" label="产品选择">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="'batchList.' + scope.$index + '.catList'" :rules="[{ required: true, message: '请选择产品', trigger: 'blur' }]">
                  <el-cascader
                    style="width: 80%"
                    v-model="seaList.batchList[scope.$index].catList"
                    :options="options"
                    :props="{ expandTrigger: 'hover' }"
                    popper-class="czh"
                    filterable
                    @blur="blurLeave"
                  ></el-cascader>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="行业分类">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="`batchList.${scope.$index}.industry`" :rules="[{ required: true, message: '请选择行业分类', trigger: 'blur' }]">
                  <el-select style="width: 80%" v-model="seaList.batchList[scope.$index].industry" placeholder="Select" size="large" multiple :popper-append-to-body="false" :multiple-limit="3">
                    <el-option v-for="item in form.product_list4" :key="item.gId" :label="item.label" :value="item.gId" />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="选择省市">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="`batchList.${scope.$index}.areaList`" :rules="[{ required: true, message: '请选择省市', trigger: 'blur' }]">
                  <el-cascader style="width: 80%" v-model="seaList.batchList[scope.$index].areaList" :options="options2" filterable></el-cascader>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="规格/参数">
            <template slot-scope="scope">
              <div>
                <el-form-item required :rules="[{ required: true, message: '请填写规格/参数', trigger: 'blur' }]">
                  <el-input style="width: 80%" v-model="seaList.batchList[scope.$index].bq_type" placeholder="请输入型号" maxlength="12" type="text" />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="报价金额">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="`batchList.${scope.$index}.bq_price`" :rules="[{ required: true, message: '请填写报价金额', trigger: 'blur' }]">
                  <el-input
                    v-model="seaList.batchList[scope.$index].bq_price"
                    style="color: red; width: 80%"
                    maxlength="7"
                    placeholder="请输入报价金额"
                    @input="handleInput3(seaList.batchList[scope.$index].bq_price, scope.$index)"
                    type="text"
                  />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="选择厂商">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="`batchList.${scope.$index}.quotation_man`" :rules="[{ required: true, message: '请选择厂商', trigger: 'blur' }]">
                  <el-select style="width: 80%" v-model="seaList.batchList[scope.$index].quotation_man" placeholder="Select" size="large" filterable :popper-append-to-body="false">
                    <el-option v-for="item in form.qioterList" :key="item.gId" :label="item.busNameAbbrev" :value="item.busId" />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="单位">
            <template slot-scope="scope">
              <div>
                <el-form-item required :prop="`batchList.${scope.$index}.bq_spec`" :rules="[{ required: true, message: '请选择单位', trigger: 'blur' }]">
                  <el-select style="width: 80%" v-model="seaList.batchList[scope.$index].bq_spec" placeholder="Select" size="large" :popper-append-to-body="false">
                    <el-option v-for="item in form.product_list6" :key="item.gId" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作">
            <template slot-scope="scope">
              <div>
                <el-form-item>
                  <el-button type="danger" @click="delPrice(scope.$index)">删除 </el-button>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productDialog2 = false">取 消</el-button>
        <el-button type="primary" @click="batchAdd('ruleFormRef2')">确 定</el-button>
      </span>
    </el-dialog>
    <div style="width: 96%; display: flex; justify-content: space-between">
      <div style="margin-top: 10px; display: flex">
        <!-- <el-button type="primary" style="margin-left: 10px; margin-bottom: 10px" @click="addproduct('ruleForm')">新增产品报价</el-button> -->
        <el-button type="primary" style="margin-left: 10px; margin-bottom: 10px" @click="addproduct2('ruleForm')">批量上传报价</el-button>

        <el-switch v-model="value1" active-text="开启自动报价" inactive-text="" style="margin-left: 8px; line-height: 20px; margin-top: 10px" @change="switchChange"> </el-switch>
        <div style="position: relative; left: -10px; top: 8px">
          <i class="el-icon-question" style="margin-top: 2px; margin-left: 17px" @mouseleave="handleMouseOver2" @mouseenter="handleMouseOver1"></i>
          <div v-show="cateryShow" style="position: absolute; top: 24px; left: -10px; z-index: 999; color: gray; font-size: 12px; width: 139.6px">
            <div>开启自动报价将在每天8点自动将昨日报价自动发布</div>
          </div>
        </div>
      </div>
      <div style="text-align: right; width: 340px; float: right">
        <el-button type="primary" @click="pushDan">批量更新报价</el-button>
        <el-button type="primary" style="margin-top: 10px" @click="onePrice('ruleForm')" :loading="loading">一键报价</el-button>
      </div>
    </div>

    <el-table
      :data="tableData"
      style="width: 97%"
      row-key="bqId"
      @selection-change="handleSelectionChange"
      ref="dragTable"
      class="draggable-table"
      :row-class-name="tableRowClassName"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center', border: '0px solid #227BFF' }"
      @sort-change="sortChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="goodsName" label="产品名称" width="200">
        <template slot="header" slot-scope="scope">
          <el-select v-model="form.busName3" placeholder="产品筛选" size="mini" style="margin-left: 20px; padding-bottom: 0px" @change="qioterListChange2">
            <el-option v-for="item in qioterList3" :key="item.paramGoodsName" :label="item.playGoodsName" :value="item.paramGoodsName" />
          </el-select>
        </template>
        <template #default="scope">
          <div>
            {{ scope.row.catFourName ? scope.row.catThreeName + "/" + scope.row.catFourName : scope.row.catThreeName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bqType" label="规格/参数">
        <template #default="scope">
          <div>
            {{ scope.row.bqType ? scope.row.bqType : "/" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="busNameAbbrev" label="厂商名称" width="200">
        <template slot="header" slot-scope="scope">
          <el-select v-model="form.busName2" placeholder="厂商筛选" size="mini" style="margin-left: 20px; padding-bottom: 0px" @change="qioterListChange">
            <el-option v-for="item in form.qioterList2" :key="item.gId" :label="item.busNameAbbrev" :value="item.busId" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="bqCity" label="发货地">
        <template #default="scope">
          <div>
            {{ scope.row.bqCity ? scope.row.bqCity : scope.row.bqProvince }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="bqSpec" label="规格单位" />
      <el-table-column prop="lastPrice" label="上次报价" />

      <el-table-column prop="bqPrice" label="当前价格" />

      <el-table-column label="更新报价" width="130" prop="newPrice">
        <template #default="scope">
          <div class="wdd">
            <el-input
              v-model="form.input[scope.$index]"
              placeholder="请输入报价"
              class="inputClass"
              style="color: red !important"
              maxlength="6"
              color="red"
              @input="handleInput2(form.input[scope.$index], scope.$index)"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报价日期" width="130" prop="addTime">
        <template #default="scope">
          <div>
            <div>{{ formatDate(scope.row.addTime.slice(0, 10)) }}</div>
            <div>{{ scope.row.addTime.slice(10, 19) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="今日报价" width="130" prop="">
        <template #default="scope">
          <div>
            <img src="../assets/newImg3/容器.png" alt="" v-if="isToday(scope.row.bqDate)" style="width: 20px; height: 20px" />
            <img src="../assets/newImg3/容器-2.png" alt="" v-if="!isToday(scope.row.bqDate)" style="width: 20px; height: 20px" />
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="240">
        <template #default="scope">
          <div style="display: flex">
            <el-button type="primary" size="small" @click="edit(scope.row)"> 修改信息 </el-button>
            <el-button type="danger" size="small" @click="deletePrice(scope.row)"> 删除 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%">
      <el-pagination
        v-model:current-page="form.page"
        v-model:page-size="form.size"
        style="margin-left: 30%; margin-top: 30px; margin-bottom: 20px"
        :page-sizes="[10, 20, 50, 500]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :visible.sync="dialogFormVisible2" title="修改报价信息" width="500" v-dialogDrag>
      <el-form :model="form2" ref="ruleFormRef" :rules="rules2">
        <el-form-item label="产品选择" prop="cat_one1" label-width="130px">
          <template class="test">
            <el-cascader
              v-model="form2.cat_one1"
              :options="options"
              @change="handleChange"
              :props="{ expandTrigger: 'hover' }"
              popper-class="czh"
              style="width: 290px"
              filterable
              @blur="blurLeave"
              disabled
            ></el-cascader>
          </template>
        </el-form-item>
        <el-form-item label="行业分类" label-width="130px" required prop="industry3">
          <el-select v-model="form2.industry3" placeholder="Select" size="large" style="width: 290px" multiple @change="select8(e)" :popper-append-to-body="false">
            <el-option v-for="item in form.product_list4" :key="item.gId" :label="item.label" :value="item.gId" />
          </el-select>
        </el-form-item>
        <el-form-item label="选择省市" prop="cat_one2" label-width="130px">
          <el-cascader v-model="form2.cat_one2" :options="options2" @change="handleChange3" style="width: 290px" filterable></el-cascader>
        </el-form-item>

        <el-form-item label="规格/参数" label-width="130px">
          <el-input v-model="form2.bq_type" style="width: 290px" placeholder="请输入型号" maxlength="12" show-word-limit type="text" />
        </el-form-item>
        <!-- <el-form-item label="所属二级行业" label-width="130px">
                    <el-select v-model="form.industry4" placeholder="Select" size="large" style="width: 290px" multiple
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.product_list5" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item> -->
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="noVideo('ruleFormRef')">取消</el-button>
          <el-button type="primary" @click="sentProduction2('ruleFormRef')"> 修改 </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- //发布报价 -->
    <el-dialog :visible.sync="dialogFormVisible" title="新增产品报价" width="35%" v-dialogDrag>
      <el-form :model="form" ref="ruleForm" :rules="rules" label-width="125px">
        <el-form-item label="产品选择" prop="cat_one" style="position: relative">
          <template class="test">
            <el-cascader
              v-model="form.cat_one1"
              :options="options"
              @change="handleChange"
              :props="{ expandTrigger: 'hover' }"
              popper-class="czh"
              style="width: 290px"
              filterable
              @blur="blurLeave"
            ></el-cascader>
          </template>
          <div style="position: absolute; top: 31px; left: 210px; cursor: pointer; font-size: 12px; font-weight: 400; color: #227bff; width: 200px" @click="showK">找不到产品？</div>
        </el-form-item>
        <el-form-item label="规格/参数">
          <el-input v-model="form.bq_type" style="width: 290px" placeholder="请输入型号" maxlength="12" show-word-limit type="text" />
        </el-form-item>
        <!-- <el-form-item label="二级分类" required prop="cat_two">
                    <el-select v-model="form.cat_two" placeholder="Select" size="large" style="width: 290px"
                        :popper-append-to-body="false" @change="select2">
                        <el-option v-for="item in form.product_list2" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="产品名称" required prop="cat_three">
                    <el-select v-model="form.cat_three" placeholder="Select" size="large" style="width: 290px"
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.product_list3" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item> -->
        <el-form-item label="行业分类" prop="industry">
          <el-select
            v-model="form.industry"
            placeholder="Select"
            size="large"
            style="width: 290px"
            multiple
            :multiple-limit="3"
            @change="select6()"
            @remove-tag="removeTag"
            :popper-append-to-body="false"
          >
            <el-option v-for="item in form.product_list4" :key="item.gId" :label="item.label" :value="item.gId" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属二级行业">
                    <el-select v-model="form.industry2" placeholder="Select" size="large" style="width: 290px" multiple
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.product_list5" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item> -->

        <el-form-item label="发货省市" prop="cat_one2">
          <el-cascader v-model="form.cat_one2" :options="options2" @change="handleChange2" style="width: 290px" filterable popper-class="czh" :props="{ expandTrigger: 'hover' }"></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="选择省份" prop="area_one">
                    <el-select v-model="form.area_one" placeholder="Select" size="large" style="width: 290px"
                        @change="select3" :popper-append-to-body="false">
                        <el-option v-for="item in form.areaList" :key="item.rId" :label="item.rName"
                            :value="item.rId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择市区" prop="area_two">
                    <el-select v-model="form.area_two" placeholder="Select" size="large" style="width: 290px"
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.areaList2" :key="item.rId" :label="item.rName"
                            :value="item.rId" />
                    </el-select>
                </el-form-item> -->
        <el-form-item label="报价金额" prop="meney">
          <el-input v-model="form.meney" style="width: 290px; color: red" maxlength="7" placeholder="请输入报价金额" @input="handleInput" show-word-limit type="text" />
          <!-- <el-input v-model="form.meney" autocomplete="off" type="number" :max="10" /> -->
        </el-form-item>
        <el-form-item label="单位" prop="bq_spec">
          <el-select v-model="form.bq_spec" placeholder="Select" size="large" style="width: 290px" :popper-append-to-body="false">
            <el-option v-for="item in form.product_list6" :key="item.gId" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="选择厂商" prop="busName">
          <el-select v-model="form.busName" placeholder="Select" size="large" style="width: 290px" filterable :popper-append-to-body="false">
            <el-option v-for="item in form.qioterList" :key="item.gId" :label="item.busNameAbbrev" :value="item.busId" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="noAdd('ruleForm')"> 取消 </el-button>
          <el-button type="primary" @click="Confirm('ruleForm')" :loading="loading"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import VipDialog3 from "../components/VipDialog3.vue";
import VipDialog5 from "../components/VipDialog5.vue";
import VipDialog7 from "../components/VipDialog7.vue";
import VipDialog8 from "../components/VipDialog8.vue";
import Sortable from "sortablejs";
import VipDialog9 from "../components/VipDialog9.vue";
import allTitle from "../components/allTitle.vue";
import Vue from "vue";
import {
  getBusManage,
  getLatestQuotation,
  CopyQuotation,
  getBstGoodsGategoryAndSon,
  updateQuotationSortNum,
  getBstGoodsGategory1,
  getRegion,
  updateAllQuotation,
  addAutoQuotation,
  updateQuotByQId,
  addLatestQuotation,
  getGoodsIndustryAdvisory,
  getGoodsIndustryByPid,
  getUserById,
  delQuotation,
  getAllRegion,
  getGoodsNameByUserId
} from "@/api/user.js";
export default {
  data() {
    return {
      seaList: {
        rules3: {
          areaList: [
            {
              required: true,
              message: "请选择省市",
              trigger: "change"
            }
          ],
          bq_spec: [
            {
              required: true,
              message: "请选择单位",
              trigger: "change"
            }
          ],

          industry: [
            {
              required: true,
              message: "请选择所属行业",
              trigger: "change"
            }
          ],
          quotation_man: [
            {
              required: true,
              message: "请填写厂商",
              trigger: "change"
            }
          ],
          bq_spec: [
            {
              required: true,
              message: "请填写规格",
              trigger: "change"
            }
          ],
          bq_price: [
            {
              required: true,
              message: "请填写报价价格",
              trigger: "change"
            }
          ]
        },
        batchList: [
          {
            catList: [],
            industry: [],
            industry2: [],
            areaList: [],
            bq_spec: "",
            bq_price: "",
            quotation_man: "",
            bq_type: "",
            is_up: "0",
            up_price: 0,
            upPercentage: 0,
            market_house_id: "1"
          }
        ]
      },

      fileData: [],
      headerObj: {
        token: window.localStorage.getItem("token")
      },
      fileList: [],
      productDialog: false,
      productDialog2: false,
      dialogVisible: true,
      list: [],
      middleArray: [],
      tableLoading: false,
      bq_id_1: "",
      bq_id_2: "",
      sort_num_1: "",
      sort_num_2: "",
      currentbqId: "",
      nextSortQuto: "",
      goodsName: "",
      cat_id: "",
      loading: false,
      bus_id: "",
      is_auto_quotation: "",
      is_auto: "",
      cateryShow: false,
      value1: "",
      qioterList2: [],
      qioterList3: [],
      enterprise: "",
      pastList: [],
      cList: [],
      options: [],
      options2: [],
      pArr: [1, 2, 3],
      pObj: { age: 12 },
      info: {},
      tableData: [],
      tableData2: [],
      tableData3: [],
      vId: "",
      dialogFormVisible: false,
      dialogFormVisible2: false,
      rules: {
        meney: [{ required: true, message: "请填写报价金额", trigger: "blur" }],

        cat_one2: [
          {
            required: true,
            message: "请选择省市",
            trigger: "change"
          }
        ],
        area_one: [
          {
            required: true,
            message: "请填写省份",
            trigger: "change"
          }
        ],
        area_two: [
          {
            required: true,
            message: "请填写市区",
            trigger: "change"
          }
        ],
        goodsName: [
          {
            required: true,
            message: "请选择产品名称",
            trigger: "change"
          }
        ],
        busName: [
          {
            required: true,
            message: "请选择厂商名称",
            trigger: "change"
          }
        ],
        cat_one: [
          {
            type: "date",
            required: true,
            message: "请填写一级分类",
            trigger: "change"
          }
        ],
        cat_two: [
          {
            type: "date",
            required: true,
            message: "请填写二级分类",
            trigger: "change"
          }
        ],
        bq_spec: [
          {
            required: true,
            message: "请选择规格单位",
            trigger: "change"
          }
        ],
        cat_three: [
          {
            required: true,
            message: "请填写三级分类",
            trigger: "change"
          }
        ],
        industry: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change"
          }
        ],
        industry3: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change"
          }
        ]
      },

      rules2: {
        cat_one2: [
          {
            required: true,
            message: "请选择省市",
            trigger: "change"
          }
        ],
        industry3: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change"
          }
        ]
      },
      form2: {
        cat_one1: [],
        cat_one2: [],
        catOne: "",
        catTwo: "",
        catThree: "",
        catFour: "",
        area_one: "",
        area_two: "",
        industry3: [],
        bq_type: ""
      },
      form: {
        bq_type: "",
        cat_four: "",
        total: 0,
        page: 1,
        size: 10,
        bq_spec: "",
        currentPrice: "",
        bus_id: "",
        input: [],
        busName2: "",
        boforePrice: "",
        id: "",
        moneyCount: "",
        industry: [],
        industry2: [],
        industry3: [],
        industry4: [],
        goodsName: "",
        goods_id: "",
        productionList: [],
        cat_one: "",
        cat_one1: "",
        cat_one2: "",
        cat_two: "",
        meney: "",
        cat_three: "",
        area_two: "",
        busName: "",
        area_one: "",
        market_house_id: "",
        is_up: 0,
        up_price: "",
        upPercentage: "",
        areaList: [],
        areaList2: [],
        areaList3: [],
        product_list1: [],
        product_list2: [],
        product_list3: [],
        product_list4: [],
        product_list5: [],
        qioterList: [],
        product_list6: [
          {
            value: "吨",
            label: "吨"
          },
          {
            value: "千克",
            label: "千克"
          },
          {
            value: "桶",
            label: "桶"
          },
          {
            value: "升",
            label: "升"
          }
        ]
      }
    };
  },
  methods: {
    delPrice(indexs) {
      this.seaList.batchList.splice(indexs, 1);
      console.log(this.seaList.batchList, "this.seaList.batchList");

      // this.seaList.batchList.filter((item, index) => {
      //   return index != indexs;
      // });
    },
    dialogClose() {
      this.seaList.batchList = [
        {
          catList: [],
          industry: [],
          industry2: [],
          areaList: [],
          bq_spec: "",
          bq_price: "",
          quotation_man: "",
          bq_type: "",
          is_up: "0",
          up_price: 0,
          upPercentage: 0,
          market_house_id: "1"
        }
      ];
    },
    addOther() {
      this.seaList.batchList.push({
        catList: [],
        industry: [],
        industry2: [],
        areaList: [],
        bq_spec: "",
        bq_price: "",
        quotation_man: "",
        bq_type: "",
        is_up: "0",
        up_price: 0,
        upPercentage: 0,
        market_house_id: "1"
      });
    },
    batchAdd(formName) {
      console.log(this.seaList.batchList, "------");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addLatestQuotation({ q_list: this.seaList.batchList }).then((res) => {
            if (res.data.code == 200) {
              this.$refs.allTitle.msg = "添加成功";
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              this.productDialog2 = false;
              CopyQuotation({ q_list: this.seaList.batchList }).then((res) => {});
              getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
                if (res.data.code == 200) {
                  this.tableData = res.data.data;
                  this.form.total = res.data.totalPage;
                  this.tableData3 = res.data.data;
                  this.tableLoading = false;
                  this.seaList.batchList = [
                    {
                      catList: [],
                      industry: [],
                      industry2: [],
                      areaList: [],
                      bq_spec: "",
                      bq_price: "",
                      quotation_man: "",
                      bq_type: "",
                      is_up: "0",
                      up_price: 0,
                      upPercentage: 0,
                      market_house_id: "1"
                    }
                  ];
                } else {
                  this.$refs.allTitle.msg = res.data.msg;
                  this.$refs.allTitle.routerPath = null;
                  this.$refs.allTitle.dialogVisible = true;
                  this.tableLoading = false;
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    pushDan() {
      this.productDialog = true;
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
    },
    handleSuccess(file) {
      this.fileData = file.data;
      this.tableData.forEach((item1, index1) => {
        file.data.forEach((item2, index2) => {
          if (item2.goodsName == null) {
            if (item2.bqSpecs == item1.bqType) {
              Vue.set(this.form.input, index1, item2.bqPrice);
            }
          } else if (item2.goodsName == item1.goodsName && item2.busName == item1.busNameAbbrev && item2.bqSpecs == item1.bqType) {
            Vue.set(this.form.input, index1, item2.bqPrice);
          }
          // if (item2.goodsName == item1.goodsName && item2.busName == item1.busNameAbbrev && item2.bqSpecs == item1.bqType) {
          //   Vue.set(this.form.input, index1, item2.bqPrice);
          //   console.log(this.form.input, "this.form.input");
          // }
        });
      });
    },
    showK() {
      this.$refs.VipDialog9.visible = true;
    },
    beforeRemove() {},
    handlePreview() {},
    sortChange({ column, prop, order }) {
      // 根据 column, prop, order 获取排序后的数据
      // 这里使用了 Vue 实例的方法来对数据进行排序
      this.sortedData = this.tableData.sort((a, b) => {
        let result = 0;
        if (a[prop] < b[prop]) {
          result = order === "ascending" ? -1 : 1;
        } else if (a[prop] > b[prop]) {
          result = order === "ascending" ? 1 : -1;
        }
        return result;
      });
      console.log(this.sortedData, "this.sortedData");
    },
    addSort(row) {
      console.log("加");
      let params = {
        bq_id: row.bqId,
        sort_num: row.sortQuto + 1
      };
      updateQuotationSortNum(params).then((res) => {
        this.tableLoading = true;
        if (res.data.code == 200) {
          getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.data;
              this.form.total = res.data.totalPage;
              this.tableData3 = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              this.tableLoading = false;
            }
          });
          this.form.input = [];
          this.cList = [];
          this.$refs.allTitle.msg = "操作成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.dialogVisible = true);
        } else {
          this.tableLoading = false;
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        }
      });
    },
    reduceSort(row) {
      console.log(row, "row====");
      let params = {
        bq_id: row.bqId,
        sort_num: row.sortQuto - 1
      };
      updateQuotationSortNum(params).then((res) => {
        this.tableLoading = true;

        if (res.data.code == 200) {
          getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.data;
              this.form.total = res.data.totalPage;
              this.tableData3 = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              this.tableLoading = false;
            }
          });
          this.form.input = [];
          this.cList = [];
          this.$refs.allTitle.msg = "操作成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        } else {
          this.tableLoading = false;
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        }
      });
    },
    switchChange() {
      console.log(this.value1);
      if (this.value1 == true) {
        this.is_auto = 1;
      } else {
        this.is_auto = 0;
      }
      addAutoQuotation({ is_auto: this.is_auto }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (res.data.data.is_auto_quotation == 1) {
            this.value1 = true;
            this.$refs.allTitle.msg = "已开启自动报价";
            this.$refs.allTitle.routerPath = null;
            this.$refs.allTitle.dialogVisible = true;
          } else {
            this.value1 = false;
            this.$refs.allTitle.msg = "已关闭自动报价";
            this.$refs.allTitle.routerPath = null;
            this.$refs.allTitle.dialogVisible = true;
          }
        }
      });
      // getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: 1000 }).then((res) => {

      //     if (res.data.code == 200) {
      //         this.is_auto_quotation = res.data.userInfo.is_auto_quotation
      //         res.data.data.forEach((item, index) => {
      //             let params = {
      //                 id: item.id,
      //                 price: item.price
      //             }
      //             this.tableData2.push(params)
      //         })

      //     } else {
      //         this.$message({
      //             type: 'error',
      //             message: res.data.msg
      //         })
      //     }
      // })
    },
    formatDate(dateStr) {
      // 使用'-'分割字符串，得到年、月、日的数组
      const parts = dateStr.split("-");
      // 将'-'替换为'/'，并重新组合成字符串
      return parts[0] + "/" + parts[1] + "/" + parts[2];
    },
    handleMouseOver1() {
      this.cateryShow = true;
    },
    handleMouseOver2() {
      this.cateryShow = false;
    },
    blurLeave() {
      if ((this.form.cat_one1 = "")) {
        this.form.cat_one1 = "";
      }
    },
    isToday(dateString) {
      // 将给定的日期字符串转换为Date对象
      const givenDate = new Date(dateString + "T00:00:00"); // 确保时间是00:00:00

      // 获取当前日期（仅日期部分），将时间设置为00:00:00以进行比较
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // 比较年份、月份和日期
      return givenDate.getFullYear() === today.getFullYear() && givenDate.getMonth() === today.getMonth() && givenDate.getDate() === today.getDate();
    },
    searchAction() {
      getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.enterprise, goods_name: this.goodsName }).then((res) => {
        this.tableData = res.data.data;
        this.form.total = res.data.totalPage;
        this.tableData3 = res.data.data;
      });
    },

    handleSelectionChange(val) {
      this.pastList = [];
      this.multipleSelection = val;
      val.forEach((item) => {
        let newObj = {
          id: item.bqId,
          price: item.bqPrice,
          upPercentage: 0,
          isUp: 0,
          upPrice: 0
        };
        this.pastList.push(newObj);
      });
      console.log(this.pastList, "this.pastList");
    },
    handleInput(value) {
      // 使用正则表达式来限制只能输入数字
      // this.form.meney = value.replace(/\D/g, '');
      this.form.meney = value.replace(/[^\d]/g, "");
    },
    handleInput2(value, index) {
      this.form.input[index] = value.replace(/[^\d]/g, "");
    },
    handleInput3(value, index) {
      this.seaList.batchList[index].bq_price = value.replace(/[^\d]/g, "");
    },
    deletePrice(row) {
      console.log(row, "---row");
      // delQuotation
      this.$confirm("此操作将删除所有报价,是否继续?", "删除报价", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delQuotation({ id: row.bqId }).then((res) => {
            if (res.data.code == 200) {
              getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
                if (res.data.code == 200) {
                  this.tableData = res.data.data;
                  this.form.total = res.data.totalPage;
                  this.tableData3 = res.data.data;
                  this.tableLoading = false;
                } else {
                  this.$refs.allTitle.msg = res.data.msg;
                  this.$refs.allTitle.routerPath = null;
                  this.$refs.allTitle.dialogVisible = true;
                  this.tableLoading = false;
                }
              });
              this.$refs.allTitle.msg = "删除成功";
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
            } else {
              this.$refs.allTitle.msg = "操作失败";
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
              // this.$message({
              //     type: 'error',
              //     message: '操作失败',
              //     offset: 300

              // });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            offset: 300
          });
        });
    },
    batchChange(value, index) {
      console.log(value, index, "ddddd");

      this.batchList[index].cat_one = value[0];
      this.batchList[index].cat_two = value[0];
      this.batchList[index].cat_three = value[0];
      this.batchList[index].cat_four = value[0];
    },
    batchIndustyChange(value, index) {
      console.log(this.batchList, "this.batchList");
    },
    handleChange(value) {
      console.log(value);
      this.form.cat_one = value[0];
      this.form.cat_two = value[1];
      this.form.cat_three = value[2];
      this.form.cat_four = value[3];
    },
    handleChange2(value) {
      console.log(value);
      this.form.area_one = value[0];
      this.form.area_two = value[1];
    },
    handleChange3(value) {
      console.log(value);
      this.form2.area_one = value[0];
      this.form2.area_two = value[1];

      console.log(this.form2, "form2/...");
    },
    // reset() {
    //     // this.form.bus_id = ''
    //     this.enterprise = ''
    //     this.form.busName2 = ''
    //     getLatestQuotation({ bus_id: '', page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
    //         this.tableData = res.data.data
    //         this.form.total = res.data.totalPage
    //         this.tableData3 = res.data.data

    //     })
    // },
    noVideo() {
      this.dialogFormVisible2 = false;
    },
    sentProduction2() {
      let params = {
        id: this.vId,
        industry: this.form2.industry3,
        industry2: this.form.industry4,
        area_one: this.form2.area_one,
        area_two: this.form2.area_two,
        bq_type: this.form2.bq_type
      };
      updateQuotByQId(params).then((res) => {
        this.tableLoading = true;

        if (res.data.code == 200) {
          this.dialogFormVisible2 = false;
          getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
            if (res.data.code == 200) {
              this.tableData = res.data.data;
              this.form.total = res.data.totalPage;
              this.tableData3 = res.data.data;
              this.tableLoading = false;
            } else {
              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;

              console.log("zoulema");
              this.tableLoading = false;
            }
          });
          this.$refs.allTitle.msg = "修改成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;

          this.form2.industry3 = [];
          this.form.industry4 = [];
        } else {
          this.tableLoading = false;

          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
        }
      });
    },
    edit(row) {
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
      // if (this.info.distribut_type == 3) {
      //     return this.$refs.VipDialog3.visible = true
      // } else if (this.info.distribut_type == 0) {
      //     return this.$refs.VipDialog7.visible = true
      // } else if (this.info.distribut_type == 2) {
      //     return this.$refs.VipDialog8.visible = true
      // }
      // else if (this.info.is_bus_vip == 0 && this.info.is_super_vip == 0) {
      //     return this.$refs.VipDialog5.visible = true
      // }
      console.log(row, "ddddd");
      this.dialogFormVisible2 = true;
      this.vId = row.bqId;
      this.form2.industry3 = row.bqIndustryList;
      // this.form.industry4 = row.bqIndustry2List
      this.form2.cat_one1 = [row.catOne, row.catTwo, row.catThree];
      this.form2.cat_one2 = [row.areaOne, row.areaTwo];
      this.form2.area_one = row.areaOne;
      this.form2.area_two = row.areaTwo;

      this.form2.catOne = row.catOne;
      this.form2.catTwo = row.catTwo;
      this.form2.catThree = row.catThree;
      this.form2.catFour = row.catFour;

      this.form2.bq_type = row.bqType;

      console.log(this.form2, "form2---");
      this.select7(row.bqIndustryList);
    },
    chufa() {
      // this.pArr[0] = 99
      this.$set(this.pObj, "num", 99);
      this.pObj.num = 12;
      console.log(this.pObj, "this.pArr");
    },
    handleSizeChange(e) {
      this.form.input.forEach((item, index) => {
        Vue.set(this.form.input, index, "");
      });
      this.form.page = 1;
      this.form.size = e;
      console.log(e, "----123");
      this.tableLoading = true;

      getLatestQuotation({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id, goods_name: this.goodsName }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.tableData3 = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(val) {
      this.form.input.forEach((item, index) => {
        Vue.set(this.form.input, index, "");
      });
      console.log(val, "----");
      this.form.page = val;
      this.tableLoading = true;

      getLatestQuotation({ page: this.form.page, size: this.form.size, bus_id: this.form.bus_id, goods_name: this.goodsName }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.tableData3 = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    select1(e) {
      this.form.cat_three = "";
      this.form.cat_two = "";

      let newArray = this.form.product_list1.filter((item, index) => {
        return item.gId == e;
      });
      // let newArray2 = JSON.parse(JSON.stringify(newArray))
      this.form.product_list2 = newArray[0].children;
    },
    select2(e) {
      this.form.cat_three = "";
      let newArray = this.form.product_list2.filter((item, index) => {
        return item.gId == e;
      });

      this.form.product_list3 = newArray[0].children;
    },
    select3(e) {
      this.form.area_two = "";
      getRegion({ parent_id: e }).then((res) => {
        this.form.areaList2 = res.data.data;
      });
    },
    select6(e) {
      console.log("zhixingle");
      getGoodsIndustryByPid({ parent_id: this.form.industry }).then((res) => {
        this.form.product_list5 = res.data.data;
      });
    },
    removeTag() {
      console.log("有没有");
      this.form.industry2 = [];
    },
    select7(e) {
      getGoodsIndustryByPid({ parent_id: e }).then((res) => {
        this.form.product_list5 = res.data.data;
      });
    },
    select8(e) {
      getGoodsIndustryByPid({ parent_id: this.form2.industry3 }).then((res) => {
        this.form.product_list5 = res.data.data;
      });
    },
    addproduct(ruleForm) {
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
      // if (this.info.distribut_type == 3) {
      //     return this.$refs.VipDialog3.visible = true
      // } else if (this.info.distribut_type == 0) {
      //     return this.$refs.VipDialog7.visible = true
      // } else if (this.info.distribut_type == 2) {
      //     return this.$refs.VipDialog8.visible = true
      // }
      // else if (this.info.is_bus_vip == 0 && this.info.is_super_vip == 0) {
      //     return this.$refs.VipDialog5.visible = true
      // }
      this.dialogFormVisible = true;
      this.$refs.ruleForm.clearValidate();
      // this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //         alert('submit!');
      //     } else {
      //         console.log('error submit!!');
      //         return false;
      //     }
      // });
    },
    addproduct2() {
      this.productDialog2 = true;
      this.$refs.ruleFormRef2.clearValidate();
    },
    Confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          // alert('submit!');
          let params2 = {
            cat_one: this.form.cat_one,
            bq_type: this.form.bq_type,
            cat_two: this.form.cat_two,
            cat_three: this.form.cat_three,

            market_house_id: "1",
            area_one: this.form.area_one,
            area_two: this.form.area_two,
            bq_price: this.form.meney,
            is_up: "0",
            up_price: 0,
            upPercentage: 0,
            goods_id: this.form.goods_id,
            quotation_man: this.form.busName,
            industry: this.form.industry,
            industry2: this.form.industry2,
            bq_spec: this.form.bq_spec,
            cat_four: this.form.cat_four
          };
          if (this.form.cat_four) {
            this.cat_id = this.form.cat_four;
          } else if (this.form.cat_three) {
            this.cat_id = this.form.cat_three;
          } else if (this.form.cat_two) {
            this.cat_id = this.form.cat_two;
          } else if (this.form.cat_one) {
            this.cat_id = this.form.cat_one;
          }
          let params3 = {
            bq_type: this.form.bq_type,

            area_one: this.form.area_one,
            area_two: this.form.area_two,
            bq_price: this.form.meney,
            bq_spec: this.form.bq_spec,
            industry: this.form.industry,
            industry2: this.form.industry2,
            is_up: "0",
            up_price: 0,
            up_percentage: 0,
            quotation_man: this.form.busName,
            market_house_id: "1",
            cat_id: this.cat_id
          };
          addLatestQuotation(params2).then((res) => {
            console.log(res, "ces----====111");
            this.tableLoading = true;

            if (res.data.code == 200) {
              CopyQuotation(params3).then((res) => {});
              getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
                if (res.data.code == 200) {
                  this.tableData = res.data.data;
                  this.form.total = res.data.totalPage;

                  this.tableData3 = res.data.data;
                  this.tableLoading = false;
                  getGoodsNameByUserId({}).then((res) => {
                    console.log(res, "res---");
                    this.qioterList3 = res.data.data;
                  });
                } else {
                  this.$refs.allTitle.msg = res.data.msg;
                  this.$refs.allTitle.routerPath = null;
                  this.$refs.allTitle.dialogVisible = true;
                  this.tableLoading = false;
                }
              });
              this.loading = false;

              this.dialogFormVisible = false;
              this.$refs.allTitle.msg = "报价成功";
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;

              // formEl.clearValidate()
              // this.$refs.formName.clearValidate();

              this.form.cat_one = "";
              this.form.cat_two = "";
              this.form.cat_three = "";
              this.form.area_one = "";
              this.form.area_two = "";
              this.form.meney = "";
              this.form.is_up = 0;
              this.form.up_price = "";
              this.form.upPercentage = "";
              this.form.industry = [];
              this.form.industry2 = [];
              this.form.busName = "";
              this.form.bq_spec = "";
              this.form.cat_four = "";
              this.form.cat_one1 = "";
              this.form.cat_one2 = "";
              this.form.bq_type = "";
            } else {
              this.loading = false;
              this.tableLoading = false;

              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
            }
          });
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          console.log("error submit!!");
          return false;
        }
      });
    },
    noAdd(formName) {
      this.dialogFormVisible = false;
    },
    qioterListChange(e) {
      this.form.bus_id = e;
      this.tableLoading = true;

      getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.form.total = res.data.totalPage;
          this.tableData3 = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    qioterListChange2(e) {
      this.goodsName = e;
      this.tableLoading = true;

      getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.form.total = res.data.totalPage;
          this.tableData3 = res.data.data;
          this.tableLoading = false;
        } else {
          this.$refs.allTitle.msg = res.data.msg;
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          this.tableLoading = false;
        }
      });
    },
    onePrice() {
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
      // if (this.info.distribut_type == 3) {
      //     return this.$refs.VipDialog3.visible = true
      // } else if (this.info.distribut_type == 0) {
      //     return this.$refs.VipDialog7.visible = true
      // } else if (this.info.distribut_type == 2) {
      //     return this.$refs.VipDialog8.visible = true
      // }
      // else if (this.info.is_bus_vip == 0 && this.info.is_super_vip == 0) {
      //     return this.$refs.VipDialog5.visible = true
      // }
      this.loading = true;
      let a = [];
      console.log(this.form, "form---");
      this.tableData.forEach((item, index) => {
        this.form.currentPrice = this.tableData[index].bqPrice;

        this.form.up_price = this.form.input[index] - this.form.currentPrice;

        if (this.form.up_price > 0) {
          this.form.is_up = 1;
        } else if (this.form.up_price < 0) {
          this.form.is_up = 0;
        }
        this.form.upPercentage = ((this.form.up_price / this.form.currentPrice) * 100).toFixed(2);
        let b = {
          id: item.bqId,
          price: this.form.input[index] != undefined ? this.form.input[index] : null,
          upPercentage: this.form.upPercentage,
          isUp: this.form.is_up,
          upPrice: this.form.up_price
        };
        a.push(b);
      });
      this.cList = a.filter((item, index) => {
        return item.price != null;
      });
      if (this.cList.length == 0) {
        this.$refs.allTitle.msg = "请填写报价";
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;

        this.loading = false;
      } else {
        updateAllQuotation({ quotations: this.cList }).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.$refs.allTitle.msg = "报价成功";
            this.$refs.allTitle.routerPath = null;
            this.$refs.allTitle.dialogVisible = true;
            // this.$message({
            //     type: 'success',
            //     message: '报价成功',
            //     offset: 300
            // })
            this.form.input = [];
            this.cList = [];
            getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
              this.tableData = res.data.data;
              this.tableData3 = res.data.data;
              this.form.total = res.data.totalPage;
            });
          } else {
            this.loading = false;
            this.$refs.allTitle.msg = res.data.msg;
            this.$refs.allTitle.routerPath = null;
            this.$refs.allTitle.dialogVisible = true;
          }
        });
      }
    },
    onePrice2() {
      if (this.info.distribut_type == 3) {
        this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
        this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
        return (this.$refs.allTitle.dialogVisible = true);
      } else if (this.info.distribut_type == 0) {
        this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
      } else if (this.info.distribut_type == 2) {
        this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
      } else if (this.info.is_bus_vip == 0) {
        this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
        this.$refs.allTitle.dialogVisible = true;
        return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
      } else {
      }
      // if (this.info.distribut_type == 3) {
      //     return this.$refs.VipDialog3.visible = true
      // } else if (this.info.distribut_type == 0) {
      //     return this.$refs.VipDialog7.visible = true
      // } else if (this.info.distribut_type == 2) {
      //     return this.$refs.VipDialog8.visible = true
      // }
      // else if (this.info.is_bus_vip == 0 && this.info.is_super_vip == 0) {
      //     return this.$refs.VipDialog5.visible = true
      // }
      console.log(this.pastList, "this.pastList");
      if (this.pastList.length == 0) {
        return this.$message({
          type: "warning",
          message: "请勾选至少一条报价数据",
          offset: 300
        });
      }
      updateAllQuotation({ quotations: this.pastList }).then((res) => {
        this.tableLoading = true;

        if (res.data.code == 200) {
          this.$refs.allTitle.msg = "报价成功";
          this.$refs.allTitle.routerPath = null;
          this.$refs.allTitle.dialogVisible = true;
          // this.$message({
          //     type: 'success',
          //     message: '报价成功',
          //     offset: 300

          // })
          this.form.input = [];
          this.cList = [];
          getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
            this.tableLoading = false;

            this.tableData = res.data.data;
            this.form.total = res.data.totalPage;
            this.tableData3 = res.data.data;
          });
        }
      });
    },
    initSortable() {
      // 获取表格row的父节点

      const tbody = document.querySelector(".draggable-table .el-table__body-wrapper tbody");
      const that = this;
      let scrollTopTemp = 0;
      // 创建拖拽实例
      let dragTable = Sortable.create(tbody, {
        animation: 150, //动画
        // handle: ".move", //指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
        // filter: ".disabled", //指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
        // dragClass: "dragClass", //设置拖拽样式类名
        // ghostClass: "ghostClass", //设置拖拽停靠样式类名
        // chosenClass: "chosenClass", //设置选中样式类名
        // 开始拖动事件
        onStart: (e) => {
          console.log("开始拖动", e.oldIndex);
          this.bq_id_1 = this.tableData3[e.oldIndex].bqId;
          console.log(scrollTopTemp, "scrollTopTemp ");
        },
        // 结束拖动事件
        onEnd: ({ newIndex, oldIndex }) => {
          var movedItem = this.tableData.splice(oldIndex, 1)[0];
          this.tableData.splice(newIndex, 0, movedItem);
          this.tableLoading = true;
          console.log("结束拖动", `拖动前索引${oldIndex}---拖动后索引${newIndex}`);
          this.middleArray = [];
          // setTimeout(() => {
          //     console.log(this.$refs.dragTable.tableData, 'tableVlue');
          // }, 1000);

          this.bq_id_2 = this.tableData3[newIndex].bqId;
          this.sort_num_1 = this.tableData3[oldIndex].sortQuto;
          this.sort_num_2 = this.tableData3[newIndex].sortQuto;
          this.tableData3.forEach((item, index) => {
            if (oldIndex > newIndex) {
              if (newIndex <= index && index <= oldIndex) {
                let params = {
                  bqId: item.bqId,
                  sortQuto: item.sortQuto
                };
                this.middleArray.push(params);
              } else {
              }
            } else if (oldIndex < newIndex) {
              if (oldIndex <= index && index <= newIndex) {
                let params = {
                  bqId: item.bqId,
                  sortQuto: item.sortQuto
                };
                this.middleArray.push(params);
              }
            } else {
              return;
            }
          });
          console.log(this.middleArray, "this.middleArray");
          if (newIndex < oldIndex) {
            this.middleArray.sort(function (a, b) {
              return b.sortQuto - a.sortQuto; // 升序排序
            });
          } else {
            this.middleArray.sort(function (a, b) {
              return a.sortQuto - b.sortQuto; // 升序排序
            });
          }

          let params = {
            sort_map: this.middleArray
            // bq_id_1: this.bq_id_1,
            // bq_id_2: this.bq_id_2,
            // sort_num_1: this.sort_num_1,
            // sort_num_2: this.sort_num_2,
          };
          updateQuotationSortNum(params).then((res) => {
            // this.tableLoading = true

            if (res.data.code == 200) {
              getLatestQuotation({ bus_id: this.form.bus_id, page: this.form.page, size: this.form.size, goods_name: this.goodsName }).then((res) => {
                this.tableLoading = false;
                that.$nextTick(() => {
                  // this.tableData = []
                  this.tableData = res.data.data;
                  console.log(this.tableData, "tableData-----ce-----");
                  this.tableData3 = res.data.data;
                });
                // this.tableLoading = false
                console.log(res.data.data);
                this.form.total = res.data.totalPage;
                // console.log(this.tableData3, ' this.tableData');
              });
            } else {
              // this.tableLoading = false

              this.$refs.allTitle.msg = res.data.msg;
              this.$refs.allTitle.routerPath = null;
              this.$refs.allTitle.dialogVisible = true;
            }
          });
          // console.log(this.tableData[e.newIndex].sortQuto)
        }
      });
      console.log(dragTable, "dragTable");
    },
    // 设置表格row的class
    tableRowClassName({ row }) {
      // console.log(row, 'ddddd===');
      // return "ghostClass";
    }
  },
  components: {
    VipDialog3,
    VipDialog5,
    VipDialog7,
    VipDialog8,
    VipDialog9,
    allTitle
  },
  created() {},
  mounted() {
    this.initSortable();

    // const loading = this.$loading({
    //     lock: true,
    //     text: 'Loading',
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    // });
    // loading.close();
    // const tbody = document.querySelector(".draggable-table .el-table__body-wrapper tbody");
    // new Sortable(tbody, {
    //     animation: 150,
    //     // 需要在odEnd方法中处理原始eltable数据，使原始数据与显示数据保持顺序一致
    //     onEnd: ({ newIndex, oldIndex }) => {
    //         const targetRow = this.tableData[oldIndex];
    //         this.tableData.splice(oldIndex, 1);
    //         this.tableData.splice(newIndex, 0, targetRow);
    //         console.table(this.tableData);
    //     },
    // });

    getAllRegion().then((res) => {
      this.options2 = res.data.data;
    });
    getBstGoodsGategoryAndSon().then((res) => {
      this.options = res.data.data;
    });
    getBusManage({ page: 1, size: 1000 }).then((res) => {
      let arrList = res.data.data.filter((item) => {
        return item.busStatus == 1;
      });
      if (arrList.length <= 0) {
        // this.$router.push({ path: "/buyerCenter/application" });
        // this.$message({
        //     message: '请先申请厂商',
        //     type: 'warning'
        // });
      }
      let newArray = res.data.data.filter((item) => {
        return item.busStatus == "1";
      });
      this.form.qioterList = newArray.filter((item) => {
        return item.isBusVip == 1 || item.isSupperVip == 1;
      });

      this.form.qioterList2 = newArray.filter((item) => {
        return item.isBusVip == 1 || item.isSupperVip == 1;
      });

      this.form.qioterList2.unshift({ busId: "", busNameAbbrev: "所有厂商" });
    });

    getGoodsNameByUserId({}).then((res) => {
      console.log(res, "res---");
      this.qioterList3 = res.data.data;
    });
    this.tableLoading = true;

    getLatestQuotation({
      bus_id: this.form.bus_id,
      page: this.form.page,
      size: this.form.size,
      goods_name: this.goodsName
    }).then((res) => {
      if (res.data.code == 200) {
        let arrList = [
          {
            goodsName: "溴素",
            price: 200
          },
          {
            goodsName: "甲醇",
            price: 3500
          }
        ];

        this.tableLoading = false;
        this.tableData = res.data.data;
        // this.tableData.forEach((item1, index1) => {
        //     arrList.forEach((item2, index2) => {
        //         if (item2.goodsName == item1.goodsName) {
        //             this.form.input[index1] = item2.price
        //         }
        //     })
        // })
        this.tableData3 = res.data.data;
        this.form.total = res.data.totalPage;
        if (res.data.userInfo.is_auto_quotation == 0) {
          this.value1 = false;
        } else {
          this.value1 = true;
        }
      } else {
        this.tableLoading = false;

        this.$refs.allTitle.msg = res.data.msg;
        this.$refs.allTitle.routerPath = null;
        this.$refs.allTitle.dialogVisible = true;
      }
    });
    getBstGoodsGategory1().then((res) => {
      this.form.product_list1 = res.data.data;
    });
    getRegion({
      parent_id: 0
    }).then((res) => {
      this.form.areaList = res.data.data;
    });

    getGoodsIndustryAdvisory().then((res) => {
      this.form.product_list4 = res.data.data;
    });
    getUserById().then((res) => {
      if (res.data.code == 200) {
        this.info = res.data.data;

        if (this.info.distribut_type == 3) {
          this.$refs.allTitle.msg = "亲！你还没进行企业认证，发布价格需要先进行企业认证，请花几分钟进行企业认证。";
          this.$refs.allTitle.routerPath = "/buyerCenter/amendEnterpriseInfo";
          return (this.$refs.allTitle.dialogVisible = true);
        } else if (this.info.distribut_type == 0) {
          this.$refs.allTitle.msg = "亲，您的企业还在认证中，企业认证通过后即可进行下一步操作。";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/corVip");
        } else if (this.info.distribut_type == 2) {
          this.$refs.allTitle.msg = "亲！你的企业认证不通过，详情请到消息中心查看！";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/theMessage");
        } else if (this.info.is_bus_vip == 0) {
          this.$refs.allTitle.msg = "亲，您还没有开通会员，现诚邀你开通我们的会员服务。";
          this.$refs.allTitle.dialogVisible = true;
          return (this.$refs.allTitle.routerPath = "/buyerCenter/priviLege");
        } else {
        }
      } else {
      }
    });
  }
};
</script>

<style lang="scss">
// 就改这两个
.el-table .cell {
  padding: 0px !important;
  overflow: visible;
}

.el-form-item__error {
  padding-top: 0px;
  left: -30px;
}
.czh .el-cascader__dropdown {
  height: 364px;
}

.czh .el-scrollbar__wrap {
  height: 364px;
}

::v-deep .inputClass {
  color: red;
}

.wdd .el-input__inner {
  color: red;
}

.dragClass {
  background-color: #f3f7fc;
}

// 停靠
.ghostClass {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}

// 选择
.chosenClass:hover > td {
  background: rgba($color: #f56c6c, $alpha: 0.5) !important;
}

.el-upload-dragger {
  // width: 100%;
  margin: auto;
}

.el-upload {
  // width: 1000px !important;
  text-align: center;
}

.el-upload__tip {
  text-align: center;
}

// .el-input__inner {
//     color: red
// }
// .test .el-switch {
//     margin-top: 9px;
// }
// ::v-deep .el-cascader-menu__wrap {

//     border-radius: 4px !important;
//     background-color: red;
// }

// ::v-deep #cascader-menu-721-0 {
//     height: 1000px !important;
// }

// ::v-deep .el-cascader-panel .el-cascader-menu {
//     height: 1000px !important;
// }
</style>
