<template>
    <div class="content">
        <searchBar />
        <!-- <div class="line"></div> -->
        <div class="classifyBox">
            <classifyBar />

        </div>

        <div class="location">
            <span>当前位置：</span>
            <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="cursor: pointer;"><span
                            @click="skipPage('/')">首页</span></el-breadcrumb-item>
                    <el-breadcrumb-item style="cursor: pointer;"><span
                            @click="skipPage('consult')">行业资讯</span></el-breadcrumb-item>
                </el-breadcrumb></div>
        </div>

        <div class="contaion">
            <div class="left5">
                <div style="padding: 10px; box-sizing: border-box; background-color: white;">
                    <div class="titleBox">
                        <div class="title">
                            {{ detail.title }}
                        </div>
                        <div class="date">
                            发布时间：{{ detail.time }}&nbsp;编辑：{{ detail.author }}
                        </div>
                    </div>

                    <div class="info">
                        {{ detail.content }}
                    </div>
                </div>

                <div class="line2">
                    <div class="top">
                        <div class="title">推荐文章</div>
                    </div>
                    <div class="theLine"></div>

                    <div class="contaion3">
                        <div class="info" v-for="(item, index) in IndustryInformationList"
                            @click="toConsultDetails(item.article_id)">
                            <div class="yuan"></div>
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="timer">[{{ item.add_time }}]</div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="right">
                <div class="region">
                    <div class="top3">
                        <div class="top">
                            <div class="title">价格中心导航</div>
                        </div>
                        <div class="theLine"></div>

                        <div class="contaion">
                            <div class="left2">
                                <div class="btn" v-for="(item, index) in MarketList"
                                    @click="marketChekout(index + 1, FactoryMarket[index].id)"
                                    :class="{ 'btnBgc': marketChekoutFlag == index + 1 }" v-if="item.is_show == 1">
                                    <!-- {{ FactoryMarket[index].name }} -->
                                    <!-- {{ item.area_name }} -->
                                </div>
                            </div>

                            <div class="right2">
                                <div class="region2" v-for="(item, index) in areaCategoryList"
                                    @click="skipTodayQuotedPrice(item.id, item.name, goodName)">{{ item.goods_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="strictSelection">
                    <div class="top">
                        <div class="title">产品严选</div>
                    </div>
                    <div class="theLine"></div>

                    <div class="productBox">
                        <div class="product" v-for="(item, index) in productsList" @click="skip(item.goods_id)">
                            <img :src="item.goodsOriginalImg" style="object-fit: cover; width: 156px; height: 156px;">
                            <div class="infoBox">
                                <div class="title">{{ item.goodsName }}</div>
                                <div class="bottom">
                                    <div class="left" style="width: 45%;">
                                        <div class="one">¥ {{ item.goodsPrice }}</div>
                                    </div>
                                    <div class="right"
                                        style="width: 50%; font-size: 8px; height: 16px; overflow: hidden; display: flex; align-items: center; justify-content: flex-end; color: #AEAEAE;">
                                        浏览 <span style="color: #006EB7; margin-left: 5px; "> {{ item.clickCount
                                            }}+</span>
                                    </div>
                                </div>
                                <!-- <div class="bottom2">
                                        <div class="address">广东中海南...</div>
                                        <div class="location2">广东广州</div>
                                    </div> -->
                            </div>
                        </div>


                    </div>

                </div>

            </div>


        </div>


    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import getParams from '../utils/getParams'
import { getArticleById, getBstGoodsGategoryById, getArticleByRem, getAllMarketPrice } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            marketChekoutFlag: 1,
            detail: '',
            IndustryInformationList: '',
            productsList: '',

            FactoryMarket: '',
            parent_id: '',
            areaCategoryList: '',
            market_house_id: '',
            MarketList: '',
            goodName: ''
        }
    },
    methods: {
        marketChekout(num) {
            this.marketChekoutFlag = num
            console.log(this.marketChekoutFlag)
            this.parent_id = num
            this.areaCategory()
        },

        skip(id) {
            window.open('/detailsPage?goods_id=' + id)
        },

        skipPage(page) {
            window.location = page
        },

        // 跳转咨询详情
        toConsultDetails(id) {
            window.location = '/consultDetails?article_id=' + id
        },

        //行业资讯详情
        async IndustryInformationDetail(id) {
            let res = await this.$http('/mini/ApiConnect/IndustryInformationDetail', { 'article_id': id })
            this.detail = res.result
            this.detail.content = res.result.content.replaceAll('img', 'img style="width: 100%"')
        },




        //行业资讯
        async IndustryInformation() {
            let res = await this.$http('/mini/ApiConnect/IndustryInformation', { article_type: 10, is_recommend: 1 })
            this.IndustryInformationList = res.result
        },

        //严选产品
        async StrictSelectionOfProducts() {
            let params = {
                category_id: 0,
                page: 0
            }
            let res = await this.$http('/mini/ApiConnect/StrictSelectionOfProducts', params)
            this.productsList = ""
            this.productsList = res.result.slice(0, 4)
        },

        //出厂列表
        async ExFactoryMarket() {
            let res = await this.$http('/mini/ApiConnect/ExFactoryMarket')
            this.FactoryMarket = res.result
            if (!getParams().parent_id) {
                this.parent_id = this.FactoryMarket[0].id

            }
            this.MarketList = res.result
            // this.parent_id = ''

            this.areaCategory()

        },

        //区域列表
        async areaCategory() {
            let res = await this.$http('/mini/ApiConnect/areaCategory', { 'market_house_id': this.parent_id })
            this.areaCategoryList = res.result
            this.market_house_id = ''

            // this.parent_id = ''

        },


        skipTodayQuotedPrice(market_house_id, thename) {
            window.location = '/todayQuotedPrice?cat_one=' + this.cat_one + '&cat_two=' + this.cat_two + '&this.cat_three=' + 'this.cat_three' + '&parent_id=' + this.parent_id + '&market_house_id=' + market_house_id + '&thename=' + thename + '&goodName=' + this.goodName
        },

        //原材料商品分类
        async GoodscCateList() {
            let res = await this.$http('/mini/ApiConnect/GoodscCateList')
            this.cat_one = res.result[0].id
            this.cat_two = res.result[0].son[0].id
            this.cat_three = res.result[0].son[0].son[0].id
            this.goodName = res.result[0].son[0].son[0].name


        },
    },
    mounted() {
        // let id = getParams().article_id
        // this.IndustryInformationDetail(id)
        // this.IndustryInformation(id)
        // this.StrictSelectionOfProducts()
        // this.ExFactoryMarket()
        // this.areaCategory()
        // this.GoodscCateList()
        let id = this.$route.query.article_id
        getArticleById({ id }).then((res) => {
            this.detail = res.data.data[0]
            this.detail.content = res.data.data[0].content.replaceAll('img', 'img style="width: 100%"')


        })
        getBstGoodsGategoryById({ type: 3, page: 1, size: 10 }).then((res) => {
            this.productsList = res.data.data.slice(0, 4)

        })
        getArticleByRem({ article_type: 10, is_recommend: 1 }).then((res) => {
            res.data.data.forEach((item, index) => {
                item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
            })
            this.IndustryInformationList = res.data.data
        })
        getAllMarketPrice().then((res) => {
            this.MarketList = res.data.data
        })
    },

    components: {
        searchBar,
        classifyBar,

    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.btnBgc {
    background-color: #006EB7 !important;
    color: white !important;
    border: none !important;
}

.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 50%;
    transform: translateX(-50%);
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.contaion {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .left5 {
        width: 830px;

        .titleBox {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #c5c5c5;
            padding-bottom: 13px;

            .title {
                font-size: 28px;
                text-align: left;
                color: #000;
                margin-top: 16px;
            }

            .date {
                font-size: 16px;
                text-align: left;
                color: #979797;
                margin-top: 12px;
            }
        }

        .info {
            color: #515151ff;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0;
            padding: 0 25px;
            box-sizing: border-box;
            margin-top: 10px;
            overflow: hidden;

        }

        .line2 {
            width: 100%;
            background-color: white;
            padding: 16px;
            // margin-right: 24px;
            box-sizing: border-box;
            margin-top: 15px;
            margin-bottom: 30px;

            .top {
                display: flex;
                align-items: center;

                .title {
                    font-size: 16px;
                    border-left: 3px solid #006EB7;
                    padding-left: 5px;
                }

                .date {
                    font-size: 10px;
                    color: #AEAEAE;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                margin-top: 7px;
            }

            .contaion3 {
                margin-top: 8px;
                width: 100%;
                height: 183x;

                .info {
                    // margin: 11px 0;
                    margin-top: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    padding: 0 13px !important;

                    .yuan {
                        width: 8px;
                        height: 8px;
                        border-radius: 20px;
                        background: transparent;
                        border: 1px solid #006eb7;
                        position: absolute;
                        left: 0;
                        margin-left: 10px;
                    }

                    .title {
                        margin-left: 15px;
                        font-family: 'SourceSansPro';
                        color: #000000;
                    }

                    .timer {
                        color: #000000;

                    }
                }
            }
        }
    }

    .right {
        width: 350px;

        .region {
            width: 100%;
            // height: 246px;
            margin-bottom: 20px;

            .top3 {
                width: 100%;
                height: 100%;
                background-color: white;
                border-radius: 4px;
                padding: 16px;
                // margin-right: 24px;
                box-sizing: border-box;

                .top {
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 16px;
                        border-left: 3px solid #006EB7;
                        padding-left: 5px;
                    }

                    .date {
                        font-size: 10px;
                        color: #AEAEAE;
                        margin-left: 6px;
                    }
                }

                .theLine {
                    height: 2px;
                    background-color: #e4e4e4;
                    margin-top: 7px;
                    margin-bottom: 5px;
                }

                .contaion {
                    width: 100%;

                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .left2 {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .btn {
                            margin-bottom: 20px;
                            width: 81px;
                            height: 28px;
                            border-radius: 14px;
                            border: 1px solid #006eb7;
                            color: #006eb7;
                            box-sizing: border-box;
                            text-align: center;
                            line-height: 28px;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .btnBgc {
                            background-color: #006EB7 !important;
                            color: white !important;
                            border: none !important;
                        }
                    }

                    .right2 {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        // margin-top: 10px;

                        .region2 {
                            width: 33.3%;
                            text-align: center;
                            font-size: 14px;
                            letter-spacing: 2.39px;
                            cursor: pointer;
                            margin-bottom: 20px;
                        }

                    }
                }

            }
        }

        .strictSelection {
            width: 100%;
            // height: 627px;
            background-color: white;
            padding: 12px;
            box-sizing: border-box;
            overflow: hidden;

            .top {
                display: flex;
                align-items: center;

                .title {
                    font-size: 16px;
                    border-left: 3px solid #006EB7;
                    padding-left: 5px;
                }

                .date {
                    font-size: 10px;
                    color: #AEAEAE;
                    margin-left: 6px;
                }
            }

            .theLine {
                height: 2px;
                background-color: #e4e4e4;
                margin-top: 7px;
                margin-bottom: 5px;
            }


            .productBox {
                display: flex;
                flex-wrap: wrap;
            }

            .product:nth-child(2n) {
                margin-left: 12px !important;
            }

            .product {
                overflow: hidden;
                width: 157px;
                margin-top: 13px;
                padding-bottom: 10px;
                border: 0.87px solid #ddd;
                box-sizing: border-box;
                cursor: pointer;

                .infoBox {
                    padding: 0 0px 0px 5px;

                    background-color: white;

                    .title {
                        font-size: 10px;
                        height: 20px;
                        overflow: hidden;
                    }

                    .bottom {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        padding: 0 5px;
                        box-sizing: border-box;
                    }

                    .bottom2 {
                        width: 103%;
                        font-weight: 400;
                        font-size: 8.72px;
                        line-height: 10.2px;
                        text-align: right;
                        color: #aeaeae;
                        display: flex;
                        justify-content: space-between;
                        border-top: 0.87px solid #ddd;
                        border-bottom: 0.87px solid #ddd;
                        padding: 0 10px;
                        box-sizing: border-box;
                        margin-top: 8px;
                        margin-left: -5px;

                        .address {
                            padding: 10px 0;
                        }

                        .location2 {
                            padding: 10px 0;

                        }
                    }

                    .left {
                        display: flex;
                        align-items: flex-end;
                        margin-top: 11px;


                        .one {
                            font-size: 14px;
                            font-weight: 700;
                            color: #FF0000;
                        }

                        .two {
                            font-size: .1em;
                            margin-left: 3px;
                            color: #AEAEAE;
                        }
                    }

                    .right3 {
                        font-size: 8px;

                    }
                }
            }
        }
    }

    .page {}
}
</style>