<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                商品评价
            </div>

            <div class="line1">
                <div class="left">


                    <div class="orderInfo">

                        <div class="putBox">
                            <div style="margin-bottom: 10px;">
                                商品评分：
                            </div>
                            <div class="line3">
                                <el-radio v-model="radio" label="1">1</el-radio>
                                <el-radio v-model="radio" label="2">2</el-radio>
                                <el-radio v-model="radio" label="3">3</el-radio>
                                <el-radio v-model="radio" label="4">4</el-radio>
                                <el-radio v-model="radio" label="5">5</el-radio>

                            </div>
                            <div style="margin-bottom: 10px; margin-top: 30px;">
                                评价内容：
                            </div>
                            <div class="line3">
                                <el-input type="textarea" :rows="5" placeholder="问题描述" v-model="textarea" resize="none">
                                </el-input>

                                <div class="up">
                                    <el-upload class="avatar-uploader"
                                        action="https://zhnl.ydlweb.com/mini/ApiConnect/upload" :show-file-list="false"
                                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                                        :data="importData" name="file">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </div>

                            </div>



                            <div class="subBox" style="display: flex; align-items: center;">

                                <div class="subBtn" @click="sub">
                                    确认提交
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'

export default {
    data() {
        return {
            activeName: 'first',
            orderId: '',
            OrderList: '',
            options: [],
            value: '',
            cause: '',
            textarea: '',
            importData: {},
            imageUrl: '',
            selectStatus: '',
            recIdList: '',
            tableData: [],
            addressId: '',
            popoverFlag: false,
            radio: '5',
            goodId: '',
            recId: ''
        }
    },
    methods: {
        sub() {
            this.UserCommentHandle()
        },
        flagClick() {
            if (this.popoverFlag) {
                this.popoverFlag = false
            } else {
                this.popoverFlag = true
            }
        },
        checkOutAddress() {
            window.location = '/buyerCenter/address?refund=1' + '&orderId=' + orderId

        },
        selectChange(e) {
            console.log(e)
            this.selectStatus = e

        },
        handleAvatarSuccess(res, file) {
            console.log('993')
            this.imageUrl = res.result.showUrl
            this.imageUrl2 = res.result.path
        },
        beforeAvatarUpload(file) {
            this.importData.file = file
            this.importData.module = 'company_license'

            console.log(file)
        },

        selectAddress(e) {
            console.log(e)
            this.addressId = e.address_id
            this.popoverFlag = false

        },

        handleClick(tab, event) {
            console.log(tab, event);
        },

        //订单详情
        async UserOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/UserOrderInfo', params)
            this.OrderList = res
            let recIdList = []
            res.goodslist.forEach((e, index) => {
                recIdList.push(e.rec_id)
            })
            this.recIdList = recIdList.join(',')
        },

        //退换货申请详情
        async OrderGoodsList() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/OrderGoodsList', params)
            res.causetype.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options.push(obj)
            })
        },


        //订单商品评价
        async UserCommentHandle() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                order_id: this.orderId,
                goods_rank: this.radio,
                content: this.textarea,
                rec_id: this.recId,
                img: this.imageUrl,
                goods_id: this.goodId,
                user_id: userId
            }
            let res = await this.$http('/mini/ApiConnect/UserCommentHandle', params)
            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location = '/buyerCenter/myOrder'

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }
        },

        //申请退换货
        async HandleOrderRefund() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                refund_type: this.selectStatus,
                rec_id: this.recIdList,
                address_id: this.addressId,
                order_id: this.orderId,
                reason: this.cause,
                describe: this.textarea,
                refund_imgs: this.imageUrl
            }
            if (!this.addressId) {
                this.$message.error('请选择地址');
                return
            }
            let res = await this.$http('/mini/ApiConnect/HandleOrderRefund', params)

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    // window.location = '/buyerCenter/myOrder'

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }
        },
    },

    mounted() {
        let userId = window.localStorage.getItem('user_id')
        this.importData.user_id = userId
        this.goodId = getParams().goodId
        this.orderId = getParams().orderId
        this.recId = getParams().recId
        this.UserOrderInfo()
        this.OrderGoodsList()
        this.UserAddressList()

    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }

        .put {
            width: 350px;
            margin-top: -14px;
            display: flex;
            align-items: center;

            .inputBar {
                width: 232px;
                height: 32px;

                .el-color-picker__icon,
                .el-input,
                .el-textarea {
                    border: 1px solid #006eb7;
                    box-sizing: border-box;
                }

                .el-input__inner:focus {
                    // el-input输入时设置边框颜色
                    border: #006eb7;
                    box-sizing: border-box;

                }

                .el-input__icon {
                    line-height: 32px;
                }

            }

            .el-input--prefix .el-input__inner {
                height: 32px;
            }

            .btn {
                width: 109px;
                height: 32px;
                background-color: #006EB7;
                text-align: center;
                line-height: 32px;
                color: white;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    .line1 {
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
        // border-bottom: 1px solid #e4e4e4;

        .left {
            // display: flex;
            // align-items: center;
        }

        .right2 {
            position: absolute;
            right: 0;
            bottom: 20px;
            height: 100%;
            width: 250px;

            .status {
                position: absolute;
                top: 37px;
                right: 0;
                font-size: 16px;
                color: #006EB7;
            }

            .statusBtn {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;

                .btn1 {
                    width: 120px;
                    height: 40px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    margin-left: 20px;
                    border-radius: 100px;
                    cursor: pointer;
                }
            }
        }

        .commodityBox {
            margin-top: 18px;
            display: flex;

            .collectBox {
                width: 80px;
                height: 25px;
                color: white;
                text-align: center;
                background-color: #006EB7;
                line-height: 25px;
                margin-top: 10px;
                cursor: pointer;
            }

            .right {
                margin-left: 23px;

                .commodityTitle {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23.4px;
                    text-align: left;
                    color: #000;
                }

                .price {
                    margin-top: 19px;
                }

                .serve {
                    font-size: 16px;
                    color: #898989;
                    margin-top: 10px;
                }

                .btnBox {
                    display: flex;

                    .btn1 {
                        width: 200px;
                        height: 64px;
                        border-radius: 4px;
                        background: #006eb7;
                        text-align: center;
                        line-height: 64px;
                        color: white;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-top: 30px;
                    }
                }

                .checkOutImg {
                    display: flex;
                    align-items: center;
                    margin-top: 18px;

                    .box {
                        width: 82px;
                        height: 82px;
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .box :last-child {
                        margin-right: 0px;

                    }

                    .imgBorder {
                        border: 5px solid #006EB7;
                        box-sizing: border-box;
                    }
                }

                .numBox {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .numBtn {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;

                        .min {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;
                        }

                        .max {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;

                        }

                        .put {
                            height: 30px;
                            width: 60px;
                        }

                        ::v-deep .el-input__inner {
                            height: 30px !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}

.orderInfo {
    margin-top: 50px;

    .orderTitle {
        padding-left: 10px;
        border-left: 3px solid #006EB7;
    }

    .putBox {
        padding-left: 10px;

        .line1 {
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            margin-top: 30px;
            border-bottom: none;

            .left {
                width: 40%;
            }

            .right {
                width: 40%;
                margin-left: 30px;
            }

            ::v-deep .el-select {
                width: 100% !important;
                height: 40px !important;
            }
        }

        .line2 {
            margin-top: 20px;

            .money {
                margin-bottom: 3px;
            }

            .moneyInfo {
                font-size: 14px;
                color: #bbbbbb;
            }
        }

        .line3 {
            width: 83.3%;
            position: relative;

            .up {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .subBtn {
            width: 150px;
            height: 40px;
            background-color: #006EB7;
            text-align: center;
            line-height: 40px;
            color: white;
            border-radius: 8px;
            margin-top: 30px;
            margin-left: 20px;
            cursor: pointer;
        }

        .btnBox {
            width: 100px;
            height: 40px;
            background-color: #006EB7;
            color: white;
            text-align: center;
            line-height: 40px;
            margin-top: 30px;
        }

    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 90px;
    height: 90px;
    display: block;
}

::v-deep .avatar-uploader .el-upload {
    border: 1px solid #dddddd !important;
    width: 90px !important;
    height: 90px;
}

::v-deep .avatar-uploader-icon[data-v-5439aa34] {
    width: 90px !important;
    height: 90px;
    line-height: 90px;
}

::v-deep .el-textarea__inner {
    padding-right: 130px;
}

.tableBox {
    width: 850px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 60px;

    ::v-deep .cell {
        font-size: 16px;
        color: #000;
        font-weight: normal !important;
    }

    ::v-deep .el-button--text {
        font-size: 16px;
        color: #000;
        font-weight: normal !important;
        ;
    }
}

::v-deep .el-radio__inner {
    width: 20px;
    height: 20px;
}

::v-deep .el-radio__label {
    font-size: 17px;
}

::v-deep .avatar-uploader-icon[data-v-4229f633] {
    width: 92px;
    height: 92px;
    line-height: 92px;
}
</style>