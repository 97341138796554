<template>
    <div class="zhnl">
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    1 抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="left">
                <img src="../assets/3a1b573801371d71b0e81cc47263a05.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="right">
                <div style="padding: 5px;">
                    抽纸是如何生产出来的？先将废纸浸泡生成出来的就是抽纸了
                </div>
                <div style="padding: 2px 5px;">
                    <span style="font-size: 12px; font-family:Arial,">纸浆制造</span>
                    <span style="color: darkgray;font-size: 12px;margin-left: 16px;">10万次播放</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

        exitLogin() {

        },
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.zhnl {
    overflow-y: scroll;
    -ms-overflow-style: none;
    cursor: pointer;
}

.top {
    margin-top: 10px;
    width: 100%;
    height: 20%;
    display: flex;
}

.left {
    width: 40%;
    height: 100%;
}

.right {
    padding: 2px;
    width: 60%;
    height: 100%;
}
</style>