<template>
    <div class="PriceTrend">
        <searchBar />
        <classifyBar :isNo="1" />
        <div class="centent">

            <div style="display: flex;align-items: center;margin-top: 10px;background-color: #fff;">
                <div class="name">{{ getPriceCenter.name }}</div>
                <div>
                    <div class="dateN">{{ getPriceCenter?.add_time?.slice(0, 10) }}</div>
                    <div class="priceN"><span style="font-size: 16px; color: #000;font-weight: 700;">主流价格：</span>{{
            getPriceCenter.price }}</div>
                </div>
            </div>
            <div style="border: 1px solid #ccc; margin: 10px 0;"></div>
            <div class="increase">
                <div class="BottomPrice">
                    <div style="color: #000;">
                        <div style="color: #000 !important;"></div>
                        <div style="color: #000 !important;">最低价</div>
                        <div style="color: #000 !important;">涨跌值</div>
                        <div style="color: #000 !important;">涨跌幅</div>
                    </div>
                    <div v-if="getPriceCenter">
                        <div style="color: #000 !important;"></div>
                        <div style="color: #000 !important;">{{ getPriceCenter.min_price && getPriceCenter.min_price }}
                        </div>
                        <div style="color: #000 !important;">{{ getPriceCenter.min_up_price &&
            getPriceCenter.min_up_price }}
                        </div>
                        <div style="color: #000 !important;">{{ getPriceCenter.min_up_percentage &&
            getPriceCenter.min_up_percentage }}%</div>
                    </div>
                    <div v-if="getPriceCenter">
                        <div style="color: #000 !important;">本周均价</div>
                        <div>{{ getPriceCenter.week_price && getPriceCenter.week_price }}</div>
                        <div>{{ getPriceCenter.week_min_up_price && getPriceCenter.week_min_up_price }}</div>
                        <div>{{ getPriceCenter.week_min_up_percentage && getPriceCenter.week_min_up_percentage }}%</div>
                    </div>
                    <div v-if="getPriceCenter">
                        <div style="color: #000 !important;">本月均价</div>
                        <div>{{ getPriceCenter.month_price && getPriceCenter.month_price }}</div>
                        <div>{{ getPriceCenter.month_min_up_price && getPriceCenter.month_min_up_price }}</div>
                        <div>{{ getPriceCenter.month_min_up_percentage && getPriceCenter.month_min_up_percentage }}%
                        </div>
                    </div>
                </div>
                <div class="TopPrice">
                    <div style="color: #000;" v-if="getPriceCenter">
                        <div style="color: #000 !important;"></div>
                        <div style="color: #000 !important;">最高价</div>
                        <div style="color: #000 !important;">涨跌值</div>
                        <div style="color: #000 !important;">涨跌幅</div>
                    </div>
                    <div style="color: #000;" v-if="getPriceCenter">
                        <div style="color: #000 !important;"></div>
                        <div style="color: #000 !important;">{{ getPriceCenter.max_price && getPriceCenter.max_price }}
                        </div>
                        <div style="color: #000 !important;">{{ getPriceCenter.max_up_price &&
            getPriceCenter.max_up_price }}
                        </div>
                        <div style="color: #000 !important;">{{
            getPriceCenter.max_up_percentage && getPriceCenter.max_up_percentage }}%</div>
                    </div>
                    <div v-if="getPriceCenter">
                        <div style="color: #000 !important;">本周均价</div>
                        <div>{{ getPriceCenter.week_price && getPriceCenter.week_price }}</div>
                        <div>{{ getPriceCenter.week_max_up_price && getPriceCenter.week_max_up_price }}</div>
                        <div>{{ getPriceCenter.week_max_up_percentage && getPriceCenter.week_max_up_percentage }}%</div>
                    </div>
                    <div v-if="getPriceCenter">
                        <div style="color: #000 !important;">本月均价</div>
                        <div>{{ getPriceCenter.month_price && getPriceCenter.month_price }}</div>
                        <div>{{ getPriceCenter.month_max_up_price && getPriceCenter.month_max_up_price }}</div>
                        <div>{{ getPriceCenter.month_max_up_percentage && getPriceCenter.month_max_up_percentage }}%
                        </div>
                    </div>
                </div>
            </div>
            <div style="border: 1px solid #ccc; margin: 10px 0;"></div>
            <div class="echartsBox">
                <div class="BtnN">
                    <div v-for="(item, i) in ['日价格', '周价格', '月价格', '季价格', '半年价格', '年价格']">
                        <el-button type="primary" round :plain="isY == i ? false : true" size="small"
                            @click="getPriceCurve(i)">{{ item
                            }}</el-button>
                    </div>
                </div>
                <div class="ech">
                    <pillarData2 :tableData="tableData" :yData="yData" :xData="xData"></pillarData2>
                </div>
            </div>
            <div class="HistoricalPriceList">
                <div class="title">
                    <div style="height: 10px; border: 1px solid #006eb7;"></div>历史价格列表
                </div>
                <div>
                    <el-table :data="tableDataW" style="width: 100%">
                        <el-table-column prop="date" label="日期" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="产品名称" width="180">
                        </el-table-column>
                        <el-table-column prop="area_name" label="企业" width="auto">
                        </el-table-column>
                        <el-table-column prop="price" label="价格" width="auto">
                        </el-table-column>
                        <el-table-column prop="up_price" label="涨跌值" width="auto">
                        </el-table-column>
                        <el-table-column prop="up_percentage" label="涨跌幅" width="auto">
                        </el-table-column>
                        <el-table-column prop="unit" label="单位" width="auto">
                        </el-table-column>

                    </el-table>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import pillarData2 from '../components/pillarData2.vue'
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
export default {
    name: 'PriceTrend',
    components: {
        searchBar,
        classifyBar,
        pillarData2
    },
    data() {
        return {
            tableData: [],
            tableDataW: [],
            frequency: 2,
            fun: 1,
            item: {},
            getPriceCenter: {},
            isY: 0,
            yData: [],
            xData: [],
            area_two: 0

        }
    },
    computed: {

    },
    async created() {
        // try {
        //     let item = JSON.parse(this.$route.query.item)
        //     this.item = item
        //     this.area_two = item.area_two
        //     this.tableData = item.tableData
        //     let parmas = {
        //         cat_one: item.cat_one,
        //         cat_three: item.cat_three,
        //         cat_two: item.cat_two,
        //         market_house_id: item.area_one,
        //         area_one: item.market_house_id,
        //         area_two: this.area_two
        //     }
        //     this.getPriceCurve(0)
        //     let res = await this.$http('/mini/ApiConnect/getPriceCenter', parmas)

        //     if (res.status == 1) {
        //         for (let i in res.data) {
        //             if (i == 'add_time' || i == 'name') {
        //                 this.getPriceCenter[i] = res.data[i]
        //             } else {
        //                 this.getPriceCenter[i] = Number(res.data[i]).toFixed(2)
        //             }
        //         }
        //     }
        //     let jes = await this.$http('/mini/ApiConnect/getPriceHistory', parmas)
        //     if (jes.status == 1) {
        //         this.tableDataW = jes.data
        //     }
        // } catch (error) {
        //     this.$router.go(-1)
        // }



    },
    methods: {
        getPriceCurve(val) {
            let item = this.item
            this.isY = val
            let parmas = {
                frequency: val + 1,
                cat_one: item.cat_one,
                cat_three: item.cat_three,
                cat_two: item.cat_two,
                market_house_id: item.area_one,
                area_one: item.market_house_id,
                area_two: this.area_two
            }

            this.$http('/mini/ApiConnect/getPriceCurve', parmas).then(res => {
                if (res.status == 1) {

                    this.tableData = [[]]
                    this.tableData[0].yData = res.data.data
                    this.yData = res.data.data
                    this.tableData[0].xData = res.data.title
                    this.xData = res.data.title
                    console.log(this.tableData);

                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.PriceTrend {
    width: 1200px;
    margin: auto;
    box-sizing: border-box;

    .centent {
        background-color: #fff;
        padding: 20px;

        .HistoricalPriceList {
            width: 100%;

            .title {
                padding-left: 10px;
                // border-left: #006eb7 1px solid ;
                // margin-left: 10px;
                font-weight: 700;
                display: flex;
                align-items: center;

                >div {
                    margin-right: 5px;
                }
            }
        }

        .echartsBox {
            .BtnN {
                display: flex;
                justify-content: space-between;
                width: 490px;
            }

            .ech {
                width: 100%;
                height: 320px;
                overflow: hidden;
                background-color: #fff;
            }
        }

        .increase {
            display: flex;
            justify-content: space-between;

            .BottomPrice,
            .TopPrice {
                display: flex;

                >div {
                    width: 100px;
                    border-left: 1px solid #ccc;
                    padding-left: 10px;

                    >div {
                        height: 30px;
                        color: #64b353;

                    }


                }

                >div:nth-child(4) {
                    width: 160px;
                }

                >div:nth-child(1) {
                    border-left: 0;
                }

            }
        }

        .name {
            // width: 130px;
            height: 28px;
            opacity: 1;
            color: #006eb7;
            font-size: 26px;
            font-weight: 700;
            font-family: "Microsoft YaHei UI";
            text-align: left;
            line-height: 28px;
            margin-right: 10px;
        }

        .priceN {

            // width: 120px;
            height: 18px;
            opacity: 1;
            color: #ff0c0c;
            font-size: 16px;
            font-weight: 700;
            font-family: "Microsoft YaHei UI";
            text-align: left;
            line-height: 18px;

        }

        .dateN {
            width: 78px;
            height: 16px;
            opacity: 1;
            color: #000000;
            font-size: 14px;
            font-weight: 400;
            font-family: "Microsoft YaHei UI";
            text-align: left;
            line-height: 16px;
        }
    }
}
</style>