<template>
    <div class="zh-box" style="margin-top: 30px;">
        <div class="wz-top">
            <div
                style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;margin-left: 4px;">
                {{ titleName }}
            </div>
            <div class="classifyList">
                <!-- <div class="classify-item" @click="numberChange(item)">
                    推荐
                </div> -->
                <div class="classify-item" v-for="item in areaCategoryList2.slice(0, 7) "
                    :class="{ bgc: wangNumber == item.gId }" @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>
            <div>

            </div>
        </div>
        <div class="lun">
            <div class="lun-left" style="cursor: pointer;" @click="toTitle(IndustryInformationList1)">

                <div style="position: absolute;bottom: 14px ;left: 28px;color: #FFFFFF;font-size: 16px;z-index: 9999;">
                    {{
                    IndustryInformationList1?.bgcTitle }}</div>
                <img src="../assets/newImg/矩形 1@1x (1).png" style="width: 98.5%;position: absolute;bottom: 1px;" lt="">
                <img :src="IndustryInformationList1?.imgUrl"
                    style="width: 100%;height: 100%;border-radius: 4px;object-fit: cover;" alt="">
            </div>
            <div class="lun-right">
                <div class="title-item" v-for="item in IndustryInformationList2" @click="toTitle(item)">• {{
                    item.bgcTitle }}</div>
            </div>
        </div>
        <div class="six">
            <div class="six-item" v-for="item in IndustryInformationList3" @click="toTitle(item)"
                style="cursor: pointer;">
                <div class="six-left">
                    <img :src="item.imgUrl" style="width: 100%;height: 96%;border-radius: 4px;" alt="">
                </div>
                <div class="six-right">
                    <div class="six-right-1">{{ item.bgcTitle }}</div>
                    <div class="six-right-2">{{ item.bgcContent.replace(/<[^>]*>/g, '') }}</div>
                    <div class="six-right-3">
                        <img src="../assets/newImg/Vector@0.75x.png"
                            style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                            alt="">
                        <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                            }}</span>
                        <!-- <img src="../assets/newImg/Vector@1x (5).png"
                            style="vertical-align: middle;line-height: 30px;width: 13px;height:8px;margin-left: 20px;"
                            alt=""> -->
                        <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBstGoodsGategory1, getAllGoodsConsult, getBstQuotedPrice } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            params2: {},
            areaCategoryList: [],
            wangNumber: 0,
            size: 30,
            page: 1,
            IndustryInformationList1: {},

            IndustryInformationList2: [],
            IndustryInformationList3: []
        }
    },
    // props: ['areaCategoryList2', 'titleName'],
    props: {
        areaCategoryList2: {
            type: Array,
            default: []
        },
        titleName: {
            type: String
        },
        isShow: {
            type: Number,
            default: 1
        },
        gId: {
            type: String,
            default: ''
        }
    },
    methods: {
        godeilt(e) {
            console.log(e, 'e.........');
            //  let uid=e.target.dataset.id
            const event = JSON.parse(e.target.dataset.id)
            this.tableClick2(event)
        },
        tableClick2(row) {
            const { href } = this.$router.resolve({
                path: "/todayQuotedPrice",
                query: {
                    data: JSON.stringify({
                        catOne: row.cat_one,
                        catTwo: row.cat_two,
                        catThree: row.cat_three,
                        label: row.good_name
                    })
                },
            });
            window.open(href, "_blank");
        },
        numberChange(item) {
            this.wangNumber = item.gId
            if (this.isShow == 1) {
                this.params2 = {
                    cat_one: this.wangNumber,
                    cat_two: '',
                    cat_three: '',
                    industry: '',
                    industry2: '',
                    size: this.size,
                    page: this.page
                }
            } else {
                this.params2 = {
                    cat_one: '',
                    cat_two: '',
                    cat_three: '',
                    industry: this.wangNumber,
                    industry2: '',
                    size: this.size,
                    page: this.page
                }
            }


            getAllGoodsConsult(this.params2).then((res) => {
                this.total_page = res.data.totalPage
                res.data.data.forEach((item, index) => {
                    item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
                })

                this.IndustryInformationList1 = res.data.data[0]

                this.IndustryInformationList2 = res.data.data.slice(1, 15)
                this.IndustryInformationList3 = res.data.data.slice(15, 21)

            })
        },
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/titleDetails',
                query: {
                    id: item.bgcId
                }
            });
            window.open(href, "_blank");

        }
    },
    created() { },
    computed: {

        classOption2() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        getBstQuotedPrice({ size: 61 }).then((res) => {
            this.newDayMarketData = res.data.data[0]?.addTime
            this.tableList2 = res.data.data
        })
        this.wangNumber = this.gId
        let params2 = {
            cat_one: this.gId,
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            size: this.size,
            page: this.page
        }
        getAllGoodsConsult(params2).then((res) => {
            this.total_page = res.data.totalPage
            res.data.data.forEach((item, index) => {
                item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
            })
            this.IndustryInformationList1 = res.data.data[0]
            this.IndustryInformationList2 = res.data.data.slice(1, 15)
            this.IndustryInformationList3 = res.data.data.slice(15, 21)
            this.IndustryInformationList3.forEach((item) => {
                item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
            })
        })
        // getBstGoodsGategory1().then((res) => {
        //     this.areaCategoryList = res.data.data
        //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
        //     console.log(this.areaCategoryList, 'this.areaCategoryList ');
        // })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
    .zh-box {
        width: 1200px;
        height: 800px;
        background-color: white;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';
        margin: auto;

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;
                overflow: hidden;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 8px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48.5%;
                height: 98% !important;
                padding: 5px;
                position: relative;
                overflow: hidden;
                // background-color: pink;
            }

            .lun-right {
                width: 52.8%;
                height: 98%;
                padding: 5px 0px 0px 2px;

                display: flex;
                flex-wrap: wrap;
                gap: 0;
                align-content: flex-start;
                justify-content: flex-start;

                .title-item {
                    width: 46%;
                    height: 20px;
                    padding: 12px 8px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .six {
            width: 100%;
            height: 374px;
            margin-top: 8px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 2px 0px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 32px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1620px) and (max-width: 25000px) {
    .zh-box {
        margin: auto;

        width: 1500px;
        height: 800px;
        background-color: white;
        margin-top: 30px;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';

        .wz-top {
            height: 50px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 26px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 12px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48.5%;
                height: 98%;
                padding: 5px;
                position: relative;
                overflow: hidden;

                // background-color: pink;
            }

            .lun-right {
                width: 52.8%;
                height: 98%;
                padding: 5px 0px 0px 2px;

                display: flex;
                flex-wrap: wrap;
                gap: 0;
                align-content: flex-start;
                justify-content: flex-start;

                .title-item {
                    width: 46%;
                    height: 20px;
                    padding: 12px 8px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .six {
            width: 100%;
            height: 374px;
            margin-top: 8px;
            padding: 8px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .six-item {
                width: 48%;
                height: 33.6%;
                padding: 2px 0px;
                display: flex;
                margin-top: 5px;
                border-bottom: 1px solid #EAEAEA;
                margin-left: 1px;

                .six-left {
                    width: 32%;
                    height: 100%;
                }

                .six-right {
                    width: 64%;
                    height: 100%;
                    padding: 2px 6px 2px 16px;

                    .six-right-1 {
                        height: 19px;
                        padding: 4px 1px 1px 1px;
                        font-weight: 900;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #000000;
                        font-size: 14px;
                    }

                    .six-right-2 {
                        height: 30px;
                        padding: 12px 2px 2px 2px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 12px;
                        color: #000000;
                    }

                    .six-right-3 {
                        display: flex;
                        height: 15%;
                        padding: 32px 0px 0px 4px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
    }
}

.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}

.scroll_table {
    margin-bottom: 15px;
    // margin-top: 5px;
    display: flex;
    width: 100%;

    .table_ul {
        display: flex;
        align-items: center;
        height: 32px;
        box-sizing: border-box;
        width: 100%;
        // height: 51px;
        background: #efefef;

        .li_nav {
            font-size: 13px;
            font-weight: 700;
            width: 88px;
            height: 32px;
            overflow: hidden;
            text-align: center;
            line-height: 32px;
        }
    }

    .warpw {
        height: 514px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .table_ul1 {
        // height: 514px;
        width: 100%;
        box-sizing: border-box;

        .ulis {
            width: 100%;

            >span {
                width: 88px;
                height: 45px;
                display: inline-block;
                text-align: center;
                font-size: 13px;
                line-height: 45px;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }

    ::v-deep .table .el-table__body-wrapper {
        display: none;
    }

    // 重点注意这段样式
    .seamless-warp {
        height: 482px;
        overflow: hidden;


        ::v-deep .table_scroll .el-table__header-wrapper {
            display: none;
        }
    }

    .seamless-warp2 {
        height: 482px;
        overflow: hidden;
        // background-color: rgba(230, 239, 243);

        ::v-deep .table_scroll .el-table__header-wrapper {
            display: none;
        }
    }
}

.market {
    // margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    // align-items: flex-start;

    .line1 {
        width: 446px;
        background-color: white;
        // padding: 16px;
        margin-right: 30px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                // height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            // height: 2px;
            // background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 8px;
        }
    }

    .line2 {
        width: 240px;
        background-color: white;
        padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }

    .line3 {
        width: 276px;
        background-color: white;
        // padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .contaion {
            padding: 3px 8px;
        }

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
                overflow: hidden;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }
}

::v-deep .el-table__row {
    background-color: rgb(230, 239, 243) !important;
}
</style>