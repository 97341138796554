<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                <span>订单信息</span>
            </div>

            <div class="line1" v-if="type != 3">
                <div class="left">
                    <div class="commodityBox" v-for="(item2, index2) in OrderList.goodslist" v-if="OrderList.goodslist">
                        <div class="left">
                            <img :src="item2.original_img" style="width: 133px; height: 133px;">
                        </div>
                        <div class="right">
                            <div class="commodityTitle">
                                {{ item2.goods_name }}
                            </div>


                            <div class="serve" v-if="item2.goods_sn">
                                <span>商品号:</span>
                                <span style="margin-left: 20px;">{{ item2.goods_sn }}</span>
                            </div>

                            <div class="serve" v-if="item2.order_sn">
                                <span>商品号:</span>
                                <span style="margin-left: 20px;">{{ item2.order_sn }}</span>
                            </div>


                            <div class="numBox">
                                <span>数量</span>
                                <div class="numBtn">
                                    x{{ item2.goods_num }}
                                </div>
                            </div>

                            <div class="price">
                                <span style="font-size: 16px; color: #000000ff;">价格</span>
                                <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">{{ item2.goods_price
                                    }}</span>
                                <span style="font-size: 12px; color: #000000ff;">/{{ item2.spec_key_name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="orderInfo">
                        <div class="orderTitle">订单信息</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="info">
                                    订单编号：{{ OrderList.order_sn }}
                                </div>
                                <div class="info">
                                    下单时间：{{ OrderList.add_time }}
                                </div>
                                <div class="info">
                                    订单状态：{{ OrderList.status_name }}
                                </div>
                                <div class="info">
                                    支付状态：{{ OrderList.pay_status_name }}
                                </div>

                                <div class="info">
                                    订单备注：{{ OrderList.user_note }}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="orderInfo" style="margin-top: 20px;">
                        <div class="orderTitle">客户信息</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="info" v-if="OrderList.orderinfo">
                                    收货名字：{{ OrderList.orderinfo.consignee }}
                                </div>
                                <div class="info" v-if="OrderList.orderinfo">
                                    手机号：{{ OrderList.orderinfo.mobile }}
                                </div>
                                <div class="info" v-if="OrderList.shipping_name">
                                    物流公司：{{ OrderList.shipping_name }}
                                </div>
                                <div class="info" v-if="OrderList.orderinfo">
                                    地址：{{ OrderList.orderinfo.address }}
                                </div>

                                <div class="info" v-if="OrderList.express_sn">
                                    物流单号：{{ OrderList.express_sn }}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div class="line1" v-if="type == 3">
                <div class="left">
                    <div class="commodityBox" v-for="(item3, index3) in order.goodslist" v-if="order.goodslist">
                        <div class="left">
                            <img :src="item3.original_img" style="width: 133px; height: 133px;">
                        </div>
                        <div class="right">
                            <div class="commodityTitle" v-if="item3.goods_name">
                                {{ item3.goods_name }}
                            </div>


                            <div class="serve" v-if="item3.goods_sn">
                                <span>商品号:</span>
                                <span style="margin-left: 20px;">{{ item3.goods_sn }}</span>
                            </div>

                            <div class="serve" v-if="item3.order_sn">
                                <span>商品号:</span>
                                <span style="margin-left: 20px;">{{ item3.order_sn }}</span>
                            </div>



                            <div class="numBox">
                                <span>数量</span>
                                <div class="numBtn" v-if="item3.goods_num">
                                    x{{ item3.goods_num }}
                                </div>
                            </div>

                            <div class="price">
                                <span style="font-size: 16px; color: #000000ff;">价格</span>
                                <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;"
                                    v-if="item3.goods_price">{{
                item3.goods_price
            }}</span>
                                <span style="font-size: 12px; color: #000000ff;" v-if="item3.spec_key_name">/{{
                item3.spec_key_name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="orderInfo">
                        <div class="orderTitle">订单信息</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="info">
                                    订单编号：{{ order.order_sn }}
                                </div>
                                <div class="info">
                                    下单时间：{{ order.add_time }}
                                </div>

                                <div class="info" v-if="order.address">
                                    收货地址：{{ order.address }}
                                </div>

                                <!-- <div class="info" v-if="order.consignee">
                                    收货名字：{{ order.consignee }}
                                </div> -->

                                <div class="info" v-if="order.express_sn">
                                    退货快递单号：{{ order.express_sn }}
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="orderInfo" style="margin-top: 20px;">
                        <div class="orderTitle">退货信息</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="info" v-if="refund.status_name">
                                    审核状态：{{ refund.status_name }}
                                </div>
                                <div class="info" v-if="refund.reason">
                                    退货原因：{{ refund.reason }}
                                </div>
                                <div class="info" v-if="refund.remark">
                                    商家备注：{{ refund.remark }}
                                </div>
                                <div class="info" v-if="refund.return_address">
                                    退货地址：{{ refund.return_address }}
                                </div>

                                <div class="info" v-if="refund.delivery">
                                    退货快递公司：{{ refund.delivery.express_name }}
                                </div>

                                <div class="info" v-if="refund.delivery">
                                    退货快递单号：{{ refund.delivery.express_sn }}
                                </div>

                                <div style="width: 100%;" v-if="!refund.delivery">
                                    <div style="display: flex; align-items: center; width: 50%; margin-top: 10px;">
                                        <span style="width: 100px">快递公司：</span><el-input v-model="expressname"
                                            placeholder="请输入快递公司"></el-input>
                                    </div>
                                    <div style="display: flex; align-items: center; width: 50%; margin-top: 20px;">
                                        <span style="width: 100px">快递单号：</span><el-input v-model="expresssn"
                                            placeholder="请输入快递单号"></el-input>
                                    </div>
                                </div>

                            </div>

                            <div class="subBtn" @click="sub" v-if="!refund.delivery">
                                确认提交
                            </div>
                        </div>
                    </div>

                    <div class="orderInfo" style="margin-top: 20px;" v-if="refund.seller_delivery">
                        <div class="orderTitle">换货信息</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="info" v-if="refund.seller_delivery.express_name">
                                    快递公司：{{ refund.seller_delivery.express_name }}
                                </div>
                                <div class="info" v-if="refund.seller_delivery.express_sn">
                                    快递单号：{{ refund.seller_delivery.express_sn }}
                                </div>
                                <div class="info" v-if="refund.seller_delivery.express_time">
                                    发货时间：{{ refund.seller_delivery.express_time }}
                                </div>

                            </div>

                            <!-- <div class="subBtn" @click="sub" v-if="!refund.delivery">
                                确认提交
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'

export default {
    data() {
        return {
            activeName: 'first',
            orderId: '',
            OrderList: '',
            type: '',
            order: '',
            refund: '',
            expressname: '',
            expresssn: ''
        }
    },
    methods: {
        sub() {
            this.backExpress()
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },

        skipPage(page) {
            this.$router.push(page)
        },

        //订单详情
        async UserOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/UserOrderInfo', params)
            this.OrderList = res
        },


        //售后订单详情
        async OrderGoodsList() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/OrderGoodsList', params)
        },

        //售后订单详情
        async refundOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/refundOrderInfo', params)
            this.order = res.result.order
            this.refund = res.result.refund
            if (this.refund.delivery instanceof Array) {
                this.refund.delivery = ""
            }
        },

        //填写退货单号
        async backExpress() {
            let userId = window.localStorage.getItem('user_id')
            if (this.express_name == '' || this.express_sn == '') {
                this.$message.error('请输入快递信息');
                return
            }
            let params = {
                user_id: userId,
                return_id: this.refund.id,
                express_name: this.expressname,
                express_sn: this.expresssn
            }
            let res = await this.$http('/mini/ApiConnect/backExpress', params)
            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload()

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }

        },
    },

    mounted() {
        this.orderId = getParams().orderId
        this.type = getParams().type
        if (this.type == 3) {
            this.refundOrderInfo()
            this.OrderGoodsList()
        } else {
            this.UserOrderInfo()
        }
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }

        .put {
            width: 350px;
            margin-top: -14px;
            display: flex;
            align-items: center;

            .inputBar {
                width: 232px;
                height: 32px;

                .el-color-picker__icon,
                .el-input,
                .el-textarea {
                    border: 1px solid #006eb7;
                    box-sizing: border-box;
                }

                .el-input__inner:focus {
                    // el-input输入时设置边框颜色
                    border: #006eb7;
                    box-sizing: border-box;

                }

                .el-input__icon {
                    line-height: 32px;
                }

            }

            .el-input--prefix .el-input__inner {
                height: 32px;
            }

            .btn {
                width: 109px;
                height: 32px;
                background-color: #006EB7;
                text-align: center;
                line-height: 32px;
                color: white;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    .line1 {
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
        // border-bottom: 1px solid #e4e4e4;

        .left {
            // display: flex;
            // align-items: center;
        }

        .right2 {
            position: absolute;
            right: 0;
            bottom: 20px;
            height: 100%;
            width: 250px;

            .status {
                position: absolute;
                top: 37px;
                right: 0;
                font-size: 16px;
                color: #006EB7;
            }

            .statusBtn {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;

                .btn1 {
                    width: 120px;
                    height: 40px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    margin-left: 20px;
                    border-radius: 100px;
                    cursor: pointer;
                }
            }
        }

        .commodityBox {
            margin-top: 18px;
            display: flex;

            .collectBox {
                width: 80px;
                height: 25px;
                color: white;
                text-align: center;
                background-color: #006EB7;
                line-height: 25px;
                margin-top: 10px;
                cursor: pointer;
            }

            .right {
                margin-left: 23px;

                .commodityTitle {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23.4px;
                    text-align: left;
                    color: #000;
                }

                .price {
                    margin-top: 19px;
                }

                .serve {
                    font-size: 16px;
                    color: #898989;
                    margin-top: 10px;
                }

                .btnBox {
                    display: flex;

                    .btn1 {
                        width: 200px;
                        height: 64px;
                        border-radius: 4px;
                        background: #006eb7;
                        text-align: center;
                        line-height: 64px;
                        color: white;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-top: 30px;
                    }
                }

                .checkOutImg {
                    display: flex;
                    align-items: center;
                    margin-top: 18px;

                    .box {
                        width: 82px;
                        height: 82px;
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .box :last-child {
                        margin-right: 0px;

                    }

                    .imgBorder {
                        border: 5px solid #006EB7;
                        box-sizing: border-box;
                    }
                }

                .numBox {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .numBtn {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;

                        .min {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;
                        }

                        .max {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;

                        }

                        .put {
                            height: 30px;
                            width: 60px;
                        }

                        ::v-deep .el-input__inner {
                            height: 30px !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}

.orderInfo {
    margin-top: 50px;

    .orderTitle {
        padding-left: 10px;
        border-left: 3px solid #006EB7;
    }

    .putBox {
        .line1 {
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 30px;
            border-bottom: none;
            padding: 0 13px;

            .info {
                width: 33.3%;
                margin-bottom: 10px;
            }

            ::v-deep .el-input__inner {
                height: 40px !important;
            }
        }

        .btnBox {
            width: 100px;
            height: 40px;
            background-color: #006EB7;
            color: white;
            text-align: center;
            line-height: 40px;
            margin-top: 30px;
        }

    }
}

.subBtn {
    width: 150px;
    height: 40px;
    background-color: #006EB7;
    text-align: center;
    line-height: 40px;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    margin-left: 20px;
    cursor: pointer;
}
</style>