<template>
    <div>
        <Cropper :getPropsUrl="getPropsUrl" :imageUrl="imageUrl" :cropOption="cropOption" />

        <div class="quotedBox">
            <div class="title">基本信息修改 </div>
            <!-- <img :src="baseImg" :alt="baseImg"> -->
            <div class="imgBox">
                <img :src="imageUrl" v-if="imageUrl" style="width: 120px; height: 120px; border-radius: 200px;" alt="">
                <!-- <img src="../assets/buyerCenter/Ellipse7.png" style="width: 120px; height: 120px; border-radius: 200px;"
                    v-else alt=""> -->


                <el-upload class="avatar-uploader" :show-file-list="false" :headers="headerObj"
                    action="https://www.zhnl.com/zhnl/uploadFile" :on-success="handleAvatarSuccess" name="file"
                    :before-upload="beforeUpload">
                    <div class="imgBtn">
                        更换头像
                    </div>
                </el-upload>
            </div>
            <div class="formBox">

                <div class="txt1">
                    <div class="txtName">
                        昵称：
                    </div>
                    <div>
                        <el-input v-model="name" maxlength="6">
                        </el-input>
                    </div>
                </div>

                <!-- <div class="txt1">
                    <div class="txtName">
                        公司名称：
                    </div>
                    <div>
                        <el-input v-model="company">
                        </el-input>
                    </div>
                </div> -->


                <div class="txt1">
                    <div class="txtName">
                        联系方式：
                    </div>
                    <div>
                        <el-input v-model="phone">
                        </el-input>
                    </div>
                </div>

                <div class="txt1">
                    <div class="txtName">
                        邮箱地址：
                    </div>
                    <div>
                        <el-input v-model="email">
                        </el-input>
                    </div>
                </div>


            </div>

            <div class="line1 ">
                <div class="btn"
                    style="border: 1px solid #006eb7; background: none; color: #006EB7; margin-right: 20px;"
                    @click="back">取消
                </div>

                <div class="btn" @click="sub">更新信息</div>
            </div>
        </div>
    </div>
</template>

<script>
import { updateUserByUserId, getUserById, uploadFile } from '@/api/user'
// import 'vue-cropper/dist/index.css'  // vue3才需要引入样式，vue2不要
// import Cropper from '@/components/cropper/cropper'

export default {
    data() {
        return {
            headerObj: {
                token: window.localStorage.getItem('token'),
            },
            baseImg: 'https://video.zhnl.com/user/2024/03/25/adbf8e3f21bc408585adf7e0497d1e75.mp4?x-oss-process=video/snapshot,t_10000,m_fast',
            shop_id: "",
            company_manage: '',
            business_license: '',
            shop_address: '',
            tableData: [{
                perpeo: '王先生',
                region: '广东广州',
                address: '站前二路15号中海南联',
                code: '000000',
                phone: '134123456789'
            }],

            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            imageUrl: '',

            //表单数据
            name: '',
            company: '',
            phone: '',
            email: '',

            headers: {},
            token: "",
            importData: {},

        }
    },

    mounted() {
        this.token = window.localStorage.getItem("token")
        let userId = window.localStorage.getItem('user_id')
        this.importData.user_id = userId

        console.log(this.token)
        this.UserIndexInfo()
    },

    methods: {
        beforeUpload(file) {
            console.log(file, '----');
            var that = this
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function (e) { // 读取完毕后调用接口
                // 获取到base64值
                var img64 = e.target.result
                this.baseImg = img64
                console.log(img64, 'img64---');
                // 图片大于1M的时候执行压缩
                if (img64.length > 1024 * 1024 * 1) {
                    var size = (1024 * 1024 * 1) / img64.length
                    that.compress(file, img64, size) // size越小压缩效果越大
                }
            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.url
            console.log(res, 'dddd');
            // this.imageUrl = res.result.showUrl
            // this.imageUrl2 = res.result.path
        },
        beforeAvatarUpload(file) {
            let formData = new FormData()
            formData.append("file", file.raw)
            console.log(file, 'ppfile');
            // let params = {
            //     file:file.raw
            // }
            uploadFile(formData).then((res) => {
                this.imageUrl = res.data.url
            })
            // this.importData.file = file
            // this.importData.module = 'head_pic'
            // console.log(file)
        },


        back() {
            this.$router.go(-1)
        },

        sub() {
            this.UserInfoHandle()
        },

        //修改个人信息
        async UserInfoHandle() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                user_id: userId,
                // head_pic: this.imageUrl2,
                user_name: this.name,
                // shop_name: this.company,
                user_phone: this.phone,
                user_email: this.email,
                shop_id: this.shop_id,
                // company_manage: this.company_manage,
                // business_license: this.business_license,
                // shop_address: this.shop_address,
            }
            updateUserByUserId(params).then((res) => {
                console.log(res, '8888');
                if (res.data.code == 200) {
                    window.localStorage.setItem('userName', this.name)
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                    this.$router.push({ path: '/buyerCenter/personal' })

                }
            })
            // let res = await this.$http('/mini/ApiConnect/UserInfoHandle', params)
            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success'
            //     });
            //     this.back()
            // } else {
            //     this.$message.error(res.msg);
            // }
        },

        //个人(企业)信息
        async UserIndexInfo() {
            let user_id = window.localStorage.getItem('user_id')
            getUserById().then((res) => {
                this.name = res.data.data.user_name
                this.company = res.data.data.shop_name
                this.phone = res.data.data.user_phone
                this.email = res.data.data.user_email
                this.shop_id = res.data.data.shop_id
                this.imageUrl = res.data.data.head_pic
                this.company_manage = res.data.data.company_manage
                this.business_license = res.data.data.business_license
                this.shop_address = res.data.data.shop_address
                // this.imageUrl = res.result.head_pic_show
            })
            // let res = await this.$http('/mini/ApiConnect/UserIndexInfo', {
            //     user_id: userId
            // })
            // this.name = res.result.real_name
            // this.company = res.result.company_name
            // this.phone = res.result.mobile
            // this.email = res.result.email
            // this.imageUrl = res.result.head_pic_show
        },
    }
}
</script>
<style lang="scss">
.avatar-uploader .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    // height: 956px;
    margin-bottom: 50px;
    padding: 24px;
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    height: 100vh;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
    }

    .imgBox {
        position: absolute;
        left: 80px;
        top: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .imgBtn {
            width: 80px;
            height: 28px;
            border-radius: 33px;
            opacity: 1;
            background: #006eb7;
            line-height: 28px;
            color: #fff;
            text-align: center;
            margin-top: 20px;
        }
    }

    .line1 {
        padding: 20px;
        // border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        // align-items: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            width: 80px;
            height: 28px;
            border-radius: 33px;
            opacity: 1;
            border: 1px solid #006eb7;
            background: #006eb7;
            text-align: center;
            line-height: 28px;
            color: white;
            // margin-top: 119px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }

    .tableBox {
        width: 850px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        ::v-deep .cell {
            font-size: 16px;
            color: #000;
            font-weight: normal !important;
        }

        ::v-deep .el-button--text {
            font-size: 16px;
            color: #000;
            font-weight: normal !important;
            ;
        }
    }

    .formBox {
        width: 333px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 21px;

        .txt1 {
            margin-bottom: 29px;

            .txtName {
                font-size: 16px;
                line-height: 18.72px;
                text-align: left;
                color: #000;
                margin-bottom: 7px;
            }

            .select {
                width: 332px;
                height: 34px;
            }
        }
    }
}
</style>