<template>
  <div class="index-box" @mouseleave="leaveTar()">
    <loginDialog ref="loginDialog" />

    <div class="top-one">
      <div class="topBar" :class="{ blackY: blackShadow == true }">
        <div class="img-box">
          <!-- <img src="@/assets/newImg2/Logo - 搜原网_upscayl_4x_realesrgan-x4plus.png" -->
          <img src="@/assets/Qsou.png" style="margin-left: 140px; margin-top: 15px; width: 160px; cursor: pointer; height: 48px" alt="" @click="$router.push({ path: '/' })" />

          <!-- <img src="@/assets/Logo 中海南联原材料资讯商城.png" style="margin-left: 140px; margin-top: 0.2rem; width: 128px; cursor: pointer; height: 70px" alt="" @click="$router.push({ path: '/' })" /> -->
        </div>
        <div class="title-box" style="cursor: pointer">
          <div @click="toRoute(1)" class="title-item" @mouseover="OneMouseOver" :class="{ bgc2: wzsb == 1 }">首页</div>
          <div class="title-item" @click="toRoute(5)" @mouseover="handleMouseOver" :class="{ bgc: videoShow == true, bgc2: wzsb == 2 }">
            <div>
              <span>视频 </span>
              <!-- <img src="../assets/newImg/路径@1x (1).png" style="vertical-align: middle;margin-right: 1px;"
                                v-show="!videoShow" alt="">
                            <img src="../assets/newImg/路径@1x (2).png" style="vertical-align: middle;margin-right: 1px;"
                                v-show="videoShow" alt=""> -->
            </div>
            <!-- <div class="showType" v-show="videoShow" @mouseleave="handleMouseLeave">
                            <span v-for="(item, index) in classifyList"
                                style="width: 9rem;height: 2rem;cursor: pointer;" @click="goVideo(item)"
                                class="videoColor">
                                {{ item.label }}
                            </span>
                        </div> -->
          </div>
          <div class="title-item" @click="toRoute(2)" @mouseover="handleMouseOver3" :class="{ bgc: materialShow == true, bgc2: wzsb == 4 }">
            <span>原料报价 </span>
            <!-- <img src="../assets/newImg/路径@1x (1).png" style="vertical-align: middle;margin-right: 1px;"
                            alt="" v-show="!materialShow">
                        <img src="../assets/newImg/路径@1x (2).png" style="vertical-align: middle;margin-right: 1px;"
                            alt="" v-show="materialShow">
                        <div class="showType3" v-show="materialShow" @mouseleave="handleMouseLeave3(index)">
                            <div class="showType3-left">
                                <div v-for="(item, index) in areaCategoryList" class="showType3-left-item"
                                    @click="goTitle(item)" @mouseover="materialMouse(item, index)"
                                    :class="{ bgc: index == classify, }">
                                    {{ item.label }} </div>
                            </div>
                        </div> -->
          </div>
          <div class="title-item" @mouseover="handleMouseOver2" @click="toRoute(6)" :class="{ bgc: quoteShow == true, bgc2: wzsb == 3 }">
            <span>行业行情 </span>
            <!-- <img src="../assets/newImg/路径@1x (1).png" style="vertical-align: middle;margin-right: 1px;"
                            v-show="!quoteShow" alt="">
                        <img src="../assets/newImg/路径@1x (2).png" style="vertical-align: middle;margin-right: 1px;"
                            alt="" v-show="quoteShow">
                        <div class="showType2" v-show="quoteShow" @mouseleave="handleMouseLeave2">
                            <span v-for="(item, index) in classifyList"
                                style="width: 9rem;height: 2rem;cursor: pointer;" @click="goVideo2(item)"
                                class="videoColor">
                                {{ item.label }}
                            </span>

                        </div> -->
          </div>

          <div class="title-item" @click="toRoute(3)" @mouseover="OneMouseOver" :class="{ bgc2: wzsb == 5 }">严选商城</div>
          <!-- <div class="title-item" @click="toRoute(4)" @mouseover="OneMouseOver" :class="{ bgc2: wzsb == 6, }">
                        行业资讯</div> -->
        </div>

        <div class="icon-box">
          <el-input v-model="inputData" placeholder="请输入内容" style="padding-top: 18px; width: 223px" class="custom-button" @keyup.enter.native="showSearch"></el-input>

          <img src="@/assets/newImg/联集 1@1x.png" style="width: 25px; height: 24px; padding: 26px 14px; cursor: pointer" alt="" @click="showSearch" @mouseover="handleMouseLeave4()" />
          <div>
            <img src="@/assets/newImg/组 4@1x.png" @click="goUser()" @mouseover="handleMouseOver4" style="width: 25px; height: 24px; padding: 26px 14px; cursor: pointer" alt="" />
            <div class="showType4" v-show="userShow" @mouseleave="handleMouseLeave4()">
              <div style="padding: 14px 22px 2px 27px; width: 100%; cursor: pointer; height: 33px" class="userH" @click="goUser()" v-show="userId">个人中心</div>
              <div style="padding: 9px 22px 2px 27px; width: 100%; cursor: pointer; height: 33px" class="userH" @click="logOut" v-show="!userId">前往登录</div>
              <div style="padding: 14px 22px 2px 27px; width: 100%; cursor: pointer; height: 33px" class="userH" @click="logOut" v-show="userId">退出登录</div>
            </div>
          </div>
        </div>
        <!-- <div class="showType" v-show="videoShow" @mouseleave="handleMouseLeave">
                    <span v-for="(item, index) in classifyList" style="width: 9rem;height: 2rem;cursor: pointer;"
                        @click="goVideo(item)" class="videoColor">
                        {{ item.label }}
                    </span>
                </div> -->
        <!-- <div class="showType2" v-show="quoteShow" @mouseleave="handleMouseLeave2">
                    <span v-for="(item, index) in classifyList" style="width: 9rem;height: 2rem;cursor: pointer;"
                        @click="goVideo2(item)" class="videoColor">
                        {{ item.label }}
                    </span>

                </div> -->
        <!-- <div class="showType3" v-show="materialShow" @mouseleave="handleMouseLeave3(index)">
                    <div class="showType3-left">
                        <div v-for="(item, index) in areaCategoryList" class="showType3-left-item"
                            @click="goTitle(item)" @mouseover="materialMouse(item, index)"
                            :class="{ bgc: index == classify, }">
                            {{ item.label }} ({{ item.children.length }})</div>
                    </div>
                    <div class="showType3-right" v-show="areaCategoryList2.length > 0">
                        <div v-for="(item, index) in areaCategoryList2" class="showType3-right-item"
                            @click="goToday(item)">
                            {{ item.label }}123</div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsIndustryAdvisory, getBstGoodsGategory1, getBstGoodsGategory1ByPid } from "@/api/user";

export default {
  data() {
    return {
      blackShadow: false,
      inputData: "",
      quoteShow: false,
      materialShow: false,
      classifyList: [],
      videoShow: false,
      search: false,
      areaCategoryList2: [],
      areaCategoryList: [],
      wzsb: 0,
      classify: 99999,
      path: "",
      userShow: false,
      userId: ""
    };
  },
  methods: {
    leaveTar() {
      this.quoteShow = false;
      this.videoShow = false;
      this.materialShow = false;
      this.userShow = false;
    },
    overF() {
      this.quoteShow = false;
      this.videoShow = false;
      this.materialShow = false;
      this.userShow = false;
    },
    goTitle(row) {
      const { href } = this.$router.resolve({
        path: "store",
        query: {
          gId: row.gId
        }
      });
      window.open(href, "_blank");
    },
    toRoute(id) {
      console.log(id, "id");
      if (id == 1) {
        this.path = "/";
        console.log(this.path, "ddd");
      } else if (id == 2) {
        this.path = "/store";
      } else if (id == 3) {
        this.path = "/strictStore";
      } else if (id == 4) {
        this.path = "/consult";
      } else if (id == 5) {
        this.path = "/hotVideo";
      } else if (id == 6) {
        this.path = "/quotedPrice";
      }

      if (this.$route.path == this.path) {
        return;
      } else {
        console.log("执行了吗", this.path);
        const { href } = this.$router.resolve({
          path: this.path
        });
        window.open(href, "_blank");
      }
    },
    showSearch() {
      const { href } = this.$router.resolve({
        path: "/search",
        query: {
          label: this.inputData
        }
      });
      window.open(href, "_blank");
    },
    goToday(data) {
      console.log(data);
      this.radio2 = data.gId;
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: this.radio,
            catTwo: this.radio2
          })
        }
      });
      window.open(href, "_blank");
    },

    materialMouse(data, index) {
      this.classify = index;
      // this.radio = data.gId
      // let getArr = this.areaCategoryList.filter((item, index) => {
      //     return item.gId == data.gId
      // })

      // getBstGoodsGategory1ByPid({ p_id: data.gId }).then((res) => {
      //     this.areaCategoryList2 = res.data.data
      // })
    },
    toConsult(index) {
      const { href } = this.$router.resolve({
        path: "/titleDetails",
        query: {
          id: this.IndustryInformationList[index].bgcId
        }
      });
      window.open(href, "_blank");
    },
    goVideo(item) {
      const { href } = this.$router.resolve({
        path: "/hotVideo",
        query: {
          industry: item.gId
        }
      });
      window.open(href, "_blank");
    },
    logOut() {
      // const { href } = this.$router.resolve({
      //     path: '/login',

      // });
      // window.open(href, "_blank");
      this.$nextTick(() => {
        this.$refs.loginDialog.loginDialog();
      });
      this.userShow = false;
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userName");
      window.localStorage.removeItem("headPic");
    },
    goVideo2(item) {
      const { href } = this.$router.resolve({
        path: "/quotedPrice",
        query: {
          cat_one: item.gId,
          label: item.label
        }
      });
      window.open(href, "_blank");
    },
    handleMouseOver() {
      this.videoShow = true;
      this.quoteShow = false;
      this.materialShow = false;
    },
    handleMouseLeave() {
      this.videoShow = false;
    },
    handleMouseOver2() {
      this.quoteShow = true;
      this.videoShow = false;
      this.materialShow = false;
    },
    handleMouseLeave2() {
      this.quoteShow = false;
    },

    handleMouseOver3() {
      this.quoteShow = false;
      this.videoShow = false;
      this.materialShow = true;
    },
    handleMouseOver4() {
      this.quoteShow = false;
      this.videoShow = false;
      this.materialShow = false;
      this.userShow = true;
    },
    OneMouseOver() {
      this.quoteShow = false;
      this.videoShow = false;
      this.materialShow = false;
    },
    handleMouseLeave3(index) {
      this.materialShow = false;
    },
    handleMouseLeave4(index) {
      this.userShow = false;
    },
    // handleMouseLeaveAll() {
    //     this.quoteShow = false
    //     this.videoShow = false
    //     this.materialShow = false
    // },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId,
          industry: this.$route.query.cat_id,
          industry2: this.$route.query.cat_id2
        }
      });
      window.open(href, "_blank");
    },
    goUser() {
      // let a = window.localStorage.getItem('user_id')
      if (!window.localStorage.getItem("user_id")) {
        this.$nextTick(() => {
          this.$refs.loginDialog.loginDialog();
        });

        // this.$message.warning({
        //     message: '请先登录',
        //     offset: 100,
        // })
        // return this.$router.push({
        //     path: '/login'
        // })
      } else if (this.$route.path == "/buyerCenter/home") {
        return;
      } else {
        const { href } = this.$router.resolve({
          path: "buyerCenter/home"
        });
        window.open(href, "_blank");
      }

      // if (this.$route.path == '/buyerCenter/home') {
      //     return
      // } else {
      //     const { href } = this.$router.resolve({
      //         path: "buyerCenter/home",
      //     });
      //     window.open(href, "_blank");
      // }
    }
  },
  created() {},
  mounted() {
    if (this.$route.path.includes("/vudio")) {
      this.blackShadow = true;
    }
    this.userId = window.localStorage.getItem("user_id");
    if (this.$route.path == "/") {
      this.wzsb = 1;
    } else if (this.$route.path == "/hotVideo") {
      this.wzsb = 2;
    } else if (this.$route.path == "/quotedPrice") {
      this.wzsb = 3;
    } else if (this.$route.path == "/store") {
      this.wzsb = 4;
    } else if (this.$route.path == "/strictStore") {
      this.wzsb = 5;
    } else if (this.$route.path == "/consult") {
      this.wzsb = 6;
    }

    getBstGoodsGategory1().then((res) => {
      this.areaCategoryList = res.data.data;
    }),
      getGoodsIndustryAdvisory().then((res) => {
        this.classifyList = res.data.data;
      });
  }
};
</script>

<style lang="scss" scoped>
.bgc {
  color: rgb(32, 123, 255) !important;
}

.index-box {
  font-family: "Microsoft YaHei" !important;
  width: 100%;
  position: relative;
  z-index: 1999;

  .showType {
    font-size: 14px;
    padding-top: 12px;
    padding-left: 12px;
    display: flex;
    position: absolute;
    // left: calc(492px);
    top: 80px;
    width: 30rem;
    height: 10rem;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(55px);

    z-index: 9999;
    flex-wrap: wrap;
    color: #333333;
    border-radius: 6px; // font-size: 18px;
  }

  .showType2 {
    font-size: 14px;
    padding-top: 12px;
    padding-left: 12px;
    display: flex;
    position: absolute;
    // left: calc(590px);
    top: 80px;
    width: 30rem;
    height: 10rem;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(55px);

    z-index: 9999;
    flex-wrap: wrap;
    color: #333333;
    border-radius: 6px; // font-size: 18px;
  }

  .showType4 {
    font-size: 14px;
    // padding-top: 12px;
    // padding-left: 12px;
    // display: flex;
    // position: absolute;
    width: 8rem;
    // height: 5.3rem;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(55px);
    // left: calc(65% + 528px);
    top: 74px;
    z-index: 9999;
    flex-wrap: wrap;
    color: #333333;
    border-radius: 6px; // font-size: 18px;
  }

  .showType3 {
    font-size: 14px;
    padding-top: 12px;
    padding-left: 12px;
    display: flex;
    position: absolute;
    // left: calc(715px);
    top: 80px;
    height: 15.6rem;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(55px);
    background-color: rgb(208, 234, 243);
    opacity: 0.9;
    z-index: 9999;
    flex-wrap: wrap;
    color: #333333;
    border-radius: 6px;

    .showType3-left::-webkit-scrollbar {
      width: 4px;
    }

    .showType3-left::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .showType3-left::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .showType3-left {
      width: 15rem;
      border-right: 1px solid gray;
      height: 100%;
      overflow-y: scroll;
      // overflow: hidden;
      // background-color: pink;

      .showType3-left-item {
        padding: 5px 15px;
        cursor: pointer;
      }

      .showType3-left-item:hover {
        // color: rgb(32, 123, 255) !important;
      }
    }

    .showType3-right::-webkit-scrollbar {
      width: 4px;
    }

    .showType3-right::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .showType3-right::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .showType3-right {
      width: 15rem;
      height: 100%;
      overflow-y: scroll;

      .showType3-right-item {
        padding: 5px 15px;
        cursor: pointer;
      }

      .showType3-right-item:hover {
        color: rgb(32, 123, 255) !important;
      }
    }
  }

  .videoColor:hover {
    color: rgb(32, 123, 255) !important;
  }
}

.blackY {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.topBar {
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.05);

  color: #333333;
  width: 100vw;
  height: 80px;
  z-index: 1500 !important;
  // opacity: 1;
  position: fixed;
  top: 0px;
  display: flex;
  background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
  backdrop-filter: blur(20px);
  // background-color: rgb(211, 239, 247);

  .img-box {
    height: 100%;
    width: 420px !important;
  }

  .title-box {
    display: flex;
    width: 50%;
    // margin-left: 80px;
    color: #333333;
    font-size: 18px;

    .title-item {
      padding: 1.8rem 1.1rem 1rem 1rem;
      font-family: "Microsoft YaHei Light";
    }

    .title-item:hover {
      color: rgb(32, 123, 255) !important;
    }
  }

  .icon-box {
    display: flex;
    width: 30%;
    // cursor: pointer;
  }
}

.bgc2 {
  font-size: 24px;
  color: #227bff;
  padding-bottom: 9px;
  line-height: 26px;
}

.userH:hover {
  color: #227bff;
}

::v-deep {
  .el-input__inner {
    border-radius: 19px;
  }
}
</style>
