<template>
    <div style="background-color: white;padding: 10px;margin-left: 12px;margin-top: 12px;">

        <el-button type="primary" @click="pulish('ruleFormRef')"
            style="margin-top: 20px;margin-bottom: 10px;">发布资讯</el-button>

        <div style="position: relative;width:100%" v-show="!dialogFormVisible">
            <div style="position: absolute;top:3px;right: 130px;z-index:999;width: 155px;">
                <el-select v-model="one_industry" filterable placeholder="行业分类" size="small" @change="classifyChange">
                    <el-option v-for="item in classifyList2" :key="item.gId" :label="item.label" :value="item.gId">
                    </el-option>
                </el-select>

            </div>
            <div style="position: absolute;top: 3px;right: 20px;z-index:999;width: 100px;">
                <el-select v-model="time_sort" filterable placeholder="时间排序" size="small" @change="classifyChange2">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div style="width: 100%;height:942px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部资讯" name="first">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList " @click="goTitle(item)">
                            <div class="video-left">
                                <img :src="item.imgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.bgcTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.readNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.addTime }}
                                </div>
                                <div class="middle-four">
                                    <!-- <span style="color: gray;">审核状态：</span> -->
                                    <span v-if="item.vStatus == '0'" style="color: red;">未审核</span>
                                    <span v-else-if="item.vStatus == '1'" style="color: green;">通过</span>
                                    <span v-else-if="item.vStatus == '2'" style="color: red;">未通过</span>
                                </div>
                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 40px;">
                                    <el-button type="danger" plain @click.stop="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="未审核" name="second">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList ">
                            <div class="video-left">
                                <img :src="item.imgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.bgcTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.readNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.addTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" plain @click.stop="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已通过" name="third">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList " @click="goTitle(item)"
                            style="cursor: pointer;">
                            <div class="video-left">
                                <img :src="item.imgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px;" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.bgcTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.readNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.addTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" plain @click.stop="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="未通过" name="fourth">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList ">
                            <div class="video-left">
                                <img :src="item.imgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px;" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.bgcTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.readNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.addTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" plain @click.stop="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>


            </el-tabs>
            <div style="width: 100%;">

                <el-pagination v-model:current-page="form.page" v-model:page-size="form.size"
                    style="margin-left: 30%;margin-top: 30px;" :page-sizes="[3, 6]" :page-size="6"
                    layout="total, sizes, prev, pager, next, jumper" :total="form.total" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
            </div>
        </div>
        <!-- <el-table :data="tableData" style="width: 110%;margin-top: 20px;">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="vIndustry" label="一级行业分类" width="180" />
            <el-table-column prop="vIndustry2" label="二级行业分类" width="130" />
            <el-table-column prop="catOneName" label="产品名称" />

            <el-table-column prop="bgcTitle" label="资讯标题" />
            <el-table-column label="资讯内容" width="130">
                <template #default="scope">
                    <div style="cursor: pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                        @click="showCon(scope.row.bgcContent)">
                        {{ scope.row.bgcContent }}...
                        <span style="text-overflow: ellipsis"> </span>
                    </div>
                </template>
</el-table-column>

<el-table-column prop="bgcAuthor" label="发布者" width="129" />
<el-table-column prop="addTime" label="发布时间" width="159" />
<el-table-column prop="vReason" label="未通过原因" width="110" />

<el-table-column label="审核结果">
    <template #default="scope">
                    <div>
                        <span v-if="scope.row.vStatus == '0'">未审核</span>
                        <span v-else-if="scope.row.vStatus == '1'" style="color: green;">审核通过</span>
                        <span v-else-if="scope.row.vStatus == '2'" style="color: red;">未通过</span>
                    </div>
                </template>
</el-table-column>
<el-table-column label="操作" width="130">
    <template #default="scope">
                    <div>
                 
                        <el-button type="danger" size="small" @click="del(scope.row)">
                            删除
                        </el-button>

                    </div>
                </template>
</el-table-column>
</el-table> -->

        <el-dialog :visible.sync="dialogVisible2" title="资讯内容" width="500">
            <span>{{ form.detailContent }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <!-- <div style="width: 100%;">

            <el-pagination v-model:current-page="form.page" v-model:page-size="form.size"
                style="margin-left: 30%;margin-top: 30px;" :page-sizes="[2, 4, 6]"
                layout="total, sizes, prev, pager, next, jumper" :total="form.total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div> -->
        <el-dialog :visible.sync="dialogFormVisible" title="发布资讯" width="500" @close="beforeC">
            <el-form :model="form" ref="ruleFormRef" :rules="rules">
                <el-form-item label="文章标题" label-width="130px" prop="title">
                    <el-input v-model="form.title" autocomplete="off" required />
                </el-form-item>
                <el-form-item label="文章内容" label-width="130px" prop="content">
                    <el-input type="textarea" placeholder="请输入内容" v-model="form.content" maxlength="400" required
                        show-word-limit :rows="6">
                    </el-input>
                    <!-- <el-input v-model="form.content" autocomplete="off" /> -->
                </el-form-item>
                <el-form-item label="一级分类" label-width="130px" required prop="cat_one">
                    <el-select v-model="form.cat_one" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false" @change="select1">
                        <el-option v-for="item in form.product_list1" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="二级分类" label-width="130px" required prop="cat_two">
                    <el-select v-model="form.cat_two" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false" @change="select2">
                        <el-option v-for="item in form.product_list2" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="三级分类" label-width="130px" required prop="cat_three">
                    <el-select v-model="form.cat_three" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.product_list3" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属一级行业" label-width="130px" required prop="industry">
                    <el-select v-model="form.industry" placeholder="Select" size="large" style="width: 240px" multiple
                        @change="select6(e)" :popper-append-to-body="false" @remove-tag="removeTag">
                        <el-option v-for="item in form.product_list4" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属二级行业" label-width="130px" prop="industry2">
                    <el-select v-model="form.industry2" placeholder="Select" size="large" style="width: 240px" multiple
                        :popper-append-to-body="false">
                        <el-option v-for="item in form.product_list5" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="视频标签" label-width="130px" required prop="v_label">
        <el-input v-model="form.v_label" autocomplete="off" />
    </el-form-item> -->
                <!-- <el-form-item label="选择产品" label-width="130px" required prop="goods_id">
        <el-select v-model="form.goods_id" placeholder="请选择产品" @change="select5">
            <el-option v-for="item in form.productionList" :key="item.goodsId" :label="item.goodsName"
                :value="item.goodsId" />
        </el-select>
    </el-form-item> -->
                <el-form-item label="上传图片" label-width="130px">
                    <el-upload class="upload-demo" action="https://www.zhnl.com/zhnl/uploadConFiles" :limit="1"
                        :file-list="fileList" :headers="headerObj" :on-success="handleSuccess"
                        :before-upload="beforeUpload">
                        <el-button type="primary">点击上传</el-button>

                    </el-upload>
                </el-form-item>

            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="noVideo('ruleFormRef')">取消</el-button>
                    <el-button type="primary" @click="sentProduction('ruleFormRef')">
                        发布
                    </el-button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import { getBstGoodsGategory1, getGoodsIndustryAdvisory, getBusManage, getAllGoodsConsultById, updateGoodsConsultById, addGoodsConsult, getGoodsIndustryByPid, delGoodsConsult } from '@/api/user.js'

export default {
    data() {
        return {
            classifyList2: [],
            activeName: 'first',
            options: [
                { label: '正序', value: 0 },
                { label: '倒序', value: 1 },
            ],
            time_sort: 1,
            one_industry: '',
            classifyList: [],
            c_status: null,
            tableData: [],
            dialogVisible2: false,
            bgcId: '',
            dialogFormVisible: false,
            // fileList: [{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }]
            fileList: [],
            headerObj: {
                token: window.localStorage.getItem('token'),
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请填写文章标题',
                        trigger: 'change',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请填写文章内容',
                        trigger: 'change',
                    },
                ],
                v_label: [
                    {

                        required: true,
                        message: '请填写视频标签',
                        trigger: 'change',
                    },
                ],
                goods_id: [
                    {

                        required: true,
                        message: '请选择产品',
                        trigger: 'change',
                    },
                ],
                industry: [
                    {

                        required: true,
                        message: '请选择一级行业分类',
                        trigger: 'change',
                    },
                ],

                cat_one: [
                    {

                        required: true,
                        message: '请选择产品一级分类',
                        trigger: 'change',
                    },
                ],
                cat_two: [
                    {

                        required: true,
                        message: '请选择产品二级分类',
                        trigger: 'change',
                    },
                ],
                cat_three: [
                    {

                        required: true,
                        message: '请选择产品三级分类',
                        trigger: 'change',
                    },
                ],
            },
            form: {
                videoList: [],
                title: '',
                userId: '',
                content: '',
                size: 6,
                page: 1,
                total: 0,
                v_label: '',
                v_video_url: '',
                img_url: false,
                goods_id: '',
                cat_one: '',
                cat_two: '',
                cat_three: '',
                industry: [],
                industry2: [],
                productList: [],
                product_list1: [],
                product_list2: [],
                product_list3: [],
                product_list4: [],
                product_list5: [],
                detailContent: '',
            }
        }
    },
    methods: {
        goTitle(item) {
            if (item.vStatus == 1) {
                const { href } = this.$router.resolve({
                    path: "/titleDetails",
                    query: { id: item.bgcId },
                });
                window.open(href, "_blank");
            }

        },

        classifyChange(e) {
            console.log(e, 'eeee');
            this.one_industry = e
            getAllGoodsConsultById({ page: this.form.page, size: this.form.size, c_status: this.c_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        classifyChange2(e) {
            console.log(e, 'eeee');
            this.time_sort = e
            getAllGoodsConsultById({ page: this.form.page, size: this.form.size, c_status: this.c_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        handleClick(box) {
            console.log(box.index, '----');
            this.form.page = 1;
            this.form.size = 5
            if (box.index == 0) {
                this.c_status = null

            } else if (box.index == 1) {
                this.c_status = 0

            }
            else if (box.index == 2) {
                this.c_status = 1

            }
            else if (box.index == 3) {
                this.c_status = 2

            }
            getAllGoodsConsultById({ page: this.form.page, size: this.form.size, c_status: this.c_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {

                this.form.videoList = res.data.totalPage
                this.form.videoList = res.data.data
            })
        },
        beforeUpload(file) {

            if (file.type.includes('jpeg') || file.type.includes('png')) {
                // return ture
            } else {
                this.$message({
                    type: 'warning',
                    message: '只能上传png、jpg文件'
                })
                return false
            }
        },

        beforeC() {
            // this.form.title = ''
            // this.form.content = ''
            // this.form.cat_one = ''
            // this.form.cat_two = ''
            // this.form.cat_three = ''
            // this.form.industry = []
            // this.form.industry2 = []
            // this.form.img_url = ''
        },
        edit(row) {
            this.bgcId = row.bgcId
            this.fileList.push({ file: row.imgUrl })
            console.log(row, 'row.');
            this.dialogFormVisible = true
            this.ifEdit = 1
            this.form.title = row.bgcTitle
            this.form.content = row.bgcContent
            this.form.cat_one = row.catOne
            this.select1(row.catOne)
            this.form.cat_two = row.catTwo
            this.select2(row.catTwo)
            this.form.cat_three = row.catThree

            this.form.industry = row.industryList
            this.form.img_url = row.imgUrl
            this.select6(row.industryList)
            setTimeout(() => {
                this.form.industry2 = row.industryList2
            }, 500);

        },
        handleSizeChange(e) {
            this.form.size = e

            getAllGoodsConsultById({ page: this.form.page, size: this.form.size, }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        handleCurrentChange(val) {
            console.log(val, '----');
            this.form.page = val
            getAllGoodsConsultById({ page: this.form.page, size: this.form.size, }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        showCon(row) {
            console.log(row, 'ppp');
            this.dialogVisible2 = true
            this.form.detailContent = row
        },
        handleSuccess(file) {
            if (file.code == 4017) {
                setTimeout(() => {
                    window.location = '/login'
                }, 2000)
                localStorage.removeItem('token')
                localStorage.removeItem('user_id')
            } else {
                this.form.img_url = file.imgUrl
            }
        },
        select1(e) {
            this.form.cat_three = ''
            this.form.cat_two = ''

            let newArray = this.form.product_list1.filter((item, index) => {
                return item.gId == e
            })

            this.form.product_list2 = newArray[0].children
        },
        select2(e) {
            this.form.cat_three = ''
            let newArray = this.form.product_list2.filter((item, index) => {
                return item.gId == e
            })
            this.form.product_list3 = newArray[0].children
        },
        select6(e) {

            getGoodsIndustryByPid({ parent_id: this.form.industry }).then((res) => {
                this.form.industry2 = ''
                if (res.data.data == undefined) {
                    this.form.industry2 = ''
                    this.form.product_list5 = res.data.data
                } else {
                    this.form.product_list5 = res.data.data
                }
            })
        },
        del(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delGoodsConsult({ ids: row.bgcId }).then((res) => {


                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })

                        getAllGoodsConsultById({ page: this.form.page, size: this.form.size }).then((res) => {
                            this.form.videoList = res.data.data
                            this.form.total = res.data.totalPage

                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.data.msg
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });


        },
        noVideo(ruleFormRef) {
            this.dialogFormVisible = false
        },
        pulish(ruleFormRef) {
            this.$router.push({
                path: '/buyerCenter/contribute',
                query: {
                    video: 2,
                }
            })
            // this.ifEdit = 0
            // this.dialogFormVisible = true
            // this.$refs.ruleFormRef.clearValidate();
        },
        removeTag() {
            this.form.industry2 = []
        },
        sentProduction(ruleFormRef) {

            this.$refs[ruleFormRef].validate((valid) => {
                if (valid) {
                    let params = {
                        title: this.form.title,
                        content: this.form.content,
                        img_url: this.form.img_url,
                        cat_one: this.form.cat_one,
                        cat_two: this.form.cat_two,
                        cat_three: this.form.cat_three,
                        industry: this.form.industry,
                        industry2: this.form.industry2,
                    }
                    let params2 = {
                        id: this.bgcId,
                        title: this.form.title,
                        content: this.form.content,
                        img_url: this.form.img_url,
                        cat_one: this.form.cat_one,
                        cat_two: this.form.cat_two,
                        cat_three: this.form.cat_three,
                        industry: this.form.industry,
                        industry2: this.form.industry2,
                    }
                    if (this.ifEdit) {
                        // updateGoodsConsultById/
                        updateGoodsConsultById(params2).then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '发布成功'
                                })
                                this.dialogFormVisible = false
                                this.form.title = ''
                                this.form.content = ''
                                this.form.cat_one = ''
                                this.form.cat_two = ''
                                this.form.cat_three = ''
                                this.form.img_url = ''
                                this.fileList = []
                                getAllGoodsConsultById({ page: this.form.page, size: this.form.size }).then((res) => {
                                    this.form.videoList = res.data.data
                                    this.form.total = res.data.totalPage

                                })
                            }
                        })
                    } else {
                        addGoodsConsult(params).then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '发布成功'
                                })
                                this.dialogFormVisible = false
                                this.form.title = ''
                                this.form.content = ''
                                this.form.cat_one = ''
                                this.form.cat_two = ''
                                this.form.cat_three = ''
                                this.form.img_url = ''
                                this.fileList = []
                                getAllGoodsConsultById({ page: this.form.page, size: this.form.size }).then((res) => {
                                    this.form.videoList = res.data.data
                                    this.form.total = res.data.totalPage

                                })
                            }
                        })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    created() { },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)'
        });
        getAllGoodsConsultById({ page: this.form.page, size: this.form.size, c_status: this.c_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
            loading.close()
            this.form.videoList = res.data.data
            this.form.total = res.data.totalPage

        })
        // getBusManage({ page: 1, size: 1000 }).then((res) => {
        //     let arrList = res.data.data.filter((item) => {
        //         return item.busStatus == 1
        //     })
        //     if (arrList.length <= 0) {
        //         this.$router.push({ path: "/buyerCenter/application" });
        //         this.$message({
        //             message: '请先申请厂商',
        //             type: 'warning'
        //         });
        //     }
        // })
        // getAllGoodsConsultById({ page: this.form.page, size: this.form.size }).then((res) => {
        //     this.tableData = res.data.data
        //     this.form.total = res.data.totalPage

        // })
        getBstGoodsGategory1().then((res) => {
            this.form.product_list1 = res.data.data
        })
        getGoodsIndustryAdvisory().then((res) => {
            this.form.product_list4 = res.data.data
            this.classifyList = res.data.data
            this.classifyList2 = res.data.data
            this.classifyList2.unshift({ gId: null, label: '不限' })
        })
    }
}
</script>

<style lang=scss scoped>
.video-item {
    width: 100%;
    height: 7.2rem;
    padding: 10px 6px;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #EAEAEA;

    .video-left {
        width: 16%;
        height: 100%;
        padding: 5px 3px;
    }

    .video-middle {
        width: 59%;
        height: 100%;
        padding: 5px 10px;

        .middle-one {
            color: #333333;
            font-size: 16px;
            padding: 8px 9px;
        }

        .middle-two {
            display: flex;
            padding: 2px 8px;

            .two-box {
                padding: 1px 2px;
                font-size: 14px;
                color: #9E9E9E;
                line-height: 14px;
                // vertical-align: middle
            }
        }

        .middle-three {
            padding: 4px 13px;
            font-size: 14px;
            color: #9E9E9E;
        }

        .middle-four {
            padding: 4px 13px;
            font-size: 14px;
        }
    }

    .video-right {
        width: 20%;
        height: 100%;
    }
}

::v-deep .el-tabs__item {
    color: #9E9E9E;
}

::v-deep.el-tabs__item .is-active {
    color: #409EFF !important;
}

::v-deep .el-message-box {
    z-index: 99999 !important;
}
</style>