<template>
  <div class="box" style="margin-top: 80px;">
    <div class="catery-box">
      <div class="catery-big" @mouseleave="handleMouseLeave()">
        <div class="catery-big-item" v-for="(item, index) in newCateryList" :key="item.value"
          :class="{ bgc2: wangNumber == item.value }" @mouseenter="handleMouseEnter(index, item.value)"
          @click="radioChange0(item)">
          <div style="position: absolute;top: 16px;right: 0;width: 1px;height: 50%;background-color: white;"
            v-show="index != 9 && index != 19">
          </div>
          <div>{{ item.label }}</div>
          <div class="catery-absolute" v-show="cateryShowList[index]"
            :class="{ specialMargin: (wangNumber2 > 4 && wangNumber2 < 10) || (wangNumber2 > 14 && wangNumber2 < 20) }">
            <div class="absolute-item" v-for="data2 in item.children" :key="data2.value">

              <div class="absolute-left" @click.stop="radioChange4(data2, item)">
                <img src="../assets/newImg3/路径@1x (6).png" alt="" style="vertical-align: middle;padding-right: 1px;">
                <span style="vertical-align: middle;">
                  {{ data2.label }}
                </span>
              </div>

              <div class="absolute-right">
                <div style="padding: 2px 5px;cursor: pointer;" v-for="data3 in data2.children" :key="data3.value"
                  @click.stop="radioChange5(data3, data2.value)" class="changeColor">
                  {{ data3.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content2">

      <div class="topInfo">

        <div class="priceBox2">
          <div class="productBox">
            <div class="imglogo">
              <img src="@/assets/quotedPrice/logo.png" alt="" />
            </div>
            <div class="produt" v-if="goodName != 'undefined'">
              {{ typeName }}
            </div>
            <img src="../assets/buyerCenter/c.png" style="width: 100%" />
          </div>


        </div>
      </div>
      <div class="priceBox">
        <!-- :style="boxStyle(item, index)" -->
        <div class="price-item" v-for="(item, index) in tableList" @click.stop="priceRadio(item)">

          <div style="width: 213px;box-sizing: border-box;height: 100%;">
            <div class="price-item-one2">{{ item.good_name }}</div>
            <div class="price-item-one" style="color: #9E9E9E;font-size: 15px;font-weight: 290;">元/{{ item.spec
              }}
            </div>
            <div class="price-item-one">{{ item.price }}</div>
            <div>
              <!-- {{ item.up_price }} -->
            </div>
            <div class="priceIfUp1" v-show="item.up_price < 0">
              <div>
                <img src="../assets/newImg3/路径@1x (7).png" v-show="item.up_price != 0" style="
                                                            vertical-align: middle;margin-left: 3px;
                                                        " alt="" />
              </div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 7px;line-height: 25px;">
                {{
        item.up_price
      }}</div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 10px;line-height: 25px;">
                {{
          item.up_percentage.toFixed(1)
        }}%</div>


            </div>
            <div class="priceIfUp2" v-show="item.up_price == 0">
              <div>
                <img src="../assets/newImg3/路径@1x (7).png" v-show="item.up_price != 0" style="
                                                            vertical-align: middle;margin-left: 3px;
                                                        " alt="" />
              </div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 7px;line-height: 25px;">
                {{
        item.up_price
      }}</div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 10px;line-height: 25px;">
                {{
          item.up_percentage.toFixed(2)
        }}%</div>


            </div>
            <div class="priceIfUp3" v-show="item.up_price > 0">
              <div>
                <img src="../assets/newImg3/路径@1x (8).png" style="
                                                            vertical-align: middle;margin-left: 3px;
                                                        " alt="" v-show="item.up_price != 0" />
              </div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 7px;line-height: 25px;">
                {{
        item.up_price
      }}</div>
              <div style="font-size: 15px;color: white;vertical-align: middle;margin-left: 10px;line-height: 25px;">
                {{
          item.up_percentage.toFixed(2)
        }}%</div>


            </div>

            <div class="price-item-one" style="margin-top: 5px;">{{ item.bus_name_abbrev }}</div>
            <div class="price-item-one">{{ item.city }}</div>
            <div class="price-item-one" style="margin-top: 5px;font-weight: 290;color: #333333;">{{
        item.real_name
      }}</div>

          </div>


        </div>
      </div>
      <div class="consultClass">
        <div class="six-item" v-for="item in IndustryInformationList1" @click="toTitle(item)" style="cursor: pointer;">
          <div class="six-left">
            <img :src="item.imgUrl" style="width: 100%;height: 100%;border-radius: 10px;" alt="">
          </div>
          <div class="six-right">
            <div class="six-right-1">{{ item.bgcTitle }}</div>
            <div class="six-right-2">{{ item.bgcContent }}
            </div>
            <div class="six-right-3">
              <img src="../assets/newImg/Vector@0.75x.png"
                style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;" alt="">
              <span style="vertical-align: middle;margin-left: 3px;line-height: 14px;">{{ item.addTime
                }}</span>

              <span style="margin-left: 30px;line-height: 14px;">阅读：{{ item.readNum }}</span>

            </div>
          </div>
        </div>
      </div>

      <div class="title-item-box">

        <div class="title-item" v-for="item in IndustryInformationList2" @click="toTitle(item)">• {{
        item.bgcTitle }}</div>
      </div>
      <div class="priceScroll">
        <div class="priceScroll-left">
          <div style="font-weight: 700;font-size: 16px;color: #227BFF;line-height: 40px;
          padding: 2px 0px;text-align: center;width: 100%;border-bottom: 1px solid #e4e4e4;">
            涨幅榜
          </div>
          <div class="line1">
            <!-- <div class="top">
                    </div> -->

            <div class="scroll_table">
              <div style="display: inline-block; width: 100%">
                <el-table :data="tableList2" class="table" :class="['commonScrollbarTable']" :header-cell-style="{
        'text-align': 'center',
        'color': '#000000',
        padding: '0',
        height: '46px',
        'font-family': 'MicrosoftYaHeiLight',
        'font-size': '14px',
        'font-weight': '400'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer',
        'color': '#227BFF',


        'background-color': 'rgba(230, 239, 243)',
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',


      }" @row-click="tableClick2">
                  <el-table-column prop="date" label="日期" :style="{
        'font-size': '14px',
        width: '100px'
      }">
                  </el-table-column>
                  <el-table-column prop="good_name" label="产品">
                  </el-table-column>
                  <el-table-column prop="bqPrice" label="价格">
                  </el-table-column>
                  <el-table-column prop="bus_name_abbrev" label="供货商">
                  </el-table-column>

                  <el-table-column prop="up_price" label="涨跌">
                    <template slot-scope="scope">
                      <div style="
                                                color: #21a00c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 1">
                        <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                        <div style="
                                                    text-align: center;
                                                    overflow: auto;
                                                ">
                          {{ scope.row.up_price }}
                        </div>
                      </div>

                      <div style="
                                                color: #ff0c0c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 2">
                        <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                        <div style="text-align: center">
                          {{ scope.row.up_price }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
                <div @click="godeilt($event)" style="position: relative;">
                  <vue-seamless-scroll :data="tableList2" class="seamless-warp" style="width: 100%;    "
                    :class-option="classOption2">
                    <el-table :data="tableList2" class="table_scroll" :class="['commonScrollbarTable']"
                      :header-cell-style="{
        'text-align': 'center',
        'background-color': '#EFEFEF',
        padding: '0',
        height: '32px',
        'font-family':
          'Microsoft YaHei UI Bold',
        'font-size': '14px'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer'
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',

      }" @row-click="tableClick2">
                      <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        font-size: 14px;
                                                        text-align: center;
                                                        color: #333333;
                                                    ">
                            {{
        scope.row.add_time.slice(
          5,
          scope.row.date
            ?.length
        )
      }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="good_name" label="产品名称">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
          scope.row
        )
        ">
                            {{ scope.row.good_name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="price" label="价格">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.price }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bus_name_abbrev" label="供货商">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.bus_name_abbrev }}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column prop="up_price" label="涨跌">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #21a00c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 0">
                            <img src="../assets/newImg/路径@1x (5).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                            overflow: auto;
                                                        ">
                              {{
        scope.row.up_price
      }}
                            </div>
                          </div>

                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #ff0c0c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 1">
                            <img src="../assets/newImg/路径@1x (4).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                        ">
                              {{ scope.row.up_price }}
                            </div>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>

                  </vue-seamless-scroll>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="priceScroll-middle">
          <div style="font-weight: 700;font-size: 16px;color: #227BFF;line-height: 40px;
          padding: 2px 0px;text-align: center;width: 100%;border-bottom: 1px solid #e4e4e4;">
            跌幅榜
          </div>
          <div class="line1">
            <!-- <div class="top">
                    </div> -->

            <div class="scroll_table">
              <div style="display: inline-block; width: 100%">
                <el-table :data="tableList3" class="table" :class="['commonScrollbarTable']" :header-cell-style="{
        'text-align': 'center',
        'color': '#000000',
        padding: '0',
        height: '46px',
        'font-family': 'MicrosoftYaHeiLight',
        'font-size': '14px',
        'font-weight': '400'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer',
        'color': '#227BFF',

        'background-color': 'rgba(230, 239, 243)',
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',


      }" @row-click="tableClick2">
                  <el-table-column prop="date" label="日期" :style="{
        'font-size': '14px',
        width: '100px'
      }">
                  </el-table-column>
                  <el-table-column prop="good_name" label="产品">
                  </el-table-column>
                  <el-table-column prop="bqPrice" label="价格">
                  </el-table-column>
                  <el-table-column prop="bus_name_abbrev" label="供货商">
                  </el-table-column>

                  <el-table-column prop="up_price" label="涨跌">
                    <template slot-scope="scope">
                      <div style="
                                                color: #21a00c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 1">
                        <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                        <div style="
                                                    text-align: center;
                                                    overflow: auto;
                                                ">
                          {{ scope.row.up_price }}
                        </div>
                      </div>

                      <div style="
                                                color: #ff0c0c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 2">
                        <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                        <div style="text-align: center">
                          {{ scope.row.up_price }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
                <div @click="godeilt($event)" style="position: relative;">
                  <vue-seamless-scroll :data="tableList3" class="seamless-warp" style="width: 100%;    "
                    :class-option="classOption2">
                    <el-table :data="tableList3" class="table_scroll" :class="['commonScrollbarTable']"
                      :header-cell-style="{
        'text-align': 'center',
        'background-color': '#EFEFEF',
        padding: '0',
        height: '32px',
        'font-family':
          'Microsoft YaHei UI Bold',
        'font-size': '14px'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer'
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',

      }" @row-click="tableClick2">
                      <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        font-size: 14px;
                                                        text-align: center;
                                                        color: #333333;
                                                    ">
                            {{
        scope.row.add_time.slice(
          5,
          scope.row.date
            ?.length
        )
      }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="good_name" label="产品名称">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
          scope.row
        )
        ">
                            {{ scope.row.good_name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="price" label="价格">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.price }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bus_name_abbrev" label="供货商">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.bus_name_abbrev }}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column prop="up_price" label="涨跌">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #21a00c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 0">
                            <img src="../assets/newImg/路径@1x (5).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                            overflow: auto;
                                                        ">
                              {{
        scope.row.up_price
      }}
                            </div>
                          </div>

                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #ff0c0c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 1">
                            <img src="../assets/newImg/路径@1x (4).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                        ">
                              {{ scope.row.up_price }}
                            </div>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>

                  </vue-seamless-scroll>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="priceScroll-right">
          <div style="font-weight: 700;font-size: 16px;color: #227BFF;line-height: 40px;
          padding: 2px 0px;text-align: center;width: 100%;border-bottom: 1px solid #e4e4e4;">
            热搜榜
          </div>
          <div class="line1">
            <!-- <div class="top">
                    </div> -->

            <div class="scroll_table">
              <div style="display: inline-block; width: 100%">
                <el-table :data="tableList4" class="table" :class="['commonScrollbarTable']" :header-cell-style="{
        'text-align': 'center',
        'color': '#000000',
        padding: '0',
        height: '46px',
        'font-family': 'MicrosoftYaHeiLight',
        'font-size': '14px',
        'font-weight': '400'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer',
        'color': '#227BFF',

        'background-color': 'rgba(230, 239, 243)',
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',


      }" @row-click="tableClick2">
                  <el-table-column prop="date" label="日期" :style="{
        'font-size': '14px',
        width: '100px'
      }">
                  </el-table-column>
                  <el-table-column prop="good_name" label="产品">
                  </el-table-column>
                  <el-table-column prop="bqPrice" label="价格">
                  </el-table-column>
                  <el-table-column prop="bus_name_abbrev" label="供货商">
                  </el-table-column>

                  <el-table-column prop="up_price" label="涨跌">
                    <template slot-scope="scope">
                      <div style="
                                                color: #21a00c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 1">
                        <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                        <div style="
                                                    text-align: center;
                                                    overflow: auto;
                                                ">
                          {{ scope.row.up_price }}
                        </div>
                      </div>

                      <div style="
                                                color: #ff0c0c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 2">
                        <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                        <div style="text-align: center">
                          {{ scope.row.up_price }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
                <div @click="godeilt($event)" style="position: relative;">
                  <vue-seamless-scroll :data="tableList4" class="seamless-warp" style="width: 100%;    "
                    :class-option="classOption2">
                    <el-table :data="tableList4" class="table_scroll" :class="['commonScrollbarTable']"
                      :header-cell-style="{
        'text-align': 'center',
        'background-color': '#EFEFEF',
        padding: '0',
        height: '32px',
        'font-family':
          'Microsoft YaHei UI Bold',
        'font-size': '14px'
      }" :row-style="{
        padding: '0px',
        cursor: 'pointer'
      }" :cell-style="{
        'text-align': 'center',
        'font-size': '14px',
        padding: '0',
        height: '45.5px',

      }" @row-click="tableClick2">
                      <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        font-size: 14px;
                                                        text-align: center;
                                                        color: #333333;
                                                    ">
                            {{
        scope.row.add_time.slice(
          5,
          scope.row.date
            ?.length
        )
      }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="good_name" label="产品名称">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
          scope.row
        )
        ">
                            {{ scope.row.good_name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="price" label="价格">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.price }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bus_name_abbrev" label="供货商">
                        <template slot-scope="scope">
                          <div style="color: #333333;" :data-id="JSON.stringify(
        scope.row
      )
        ">
                            {{ scope.row.bus_name_abbrev }}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column prop="up_price" label="涨跌">
                        <template slot-scope="scope">
                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #21a00c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 0">
                            <img src="../assets/newImg/路径@1x (5).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                            overflow: auto;
                                                        ">
                              {{
        scope.row.up_price
      }}
                            </div>
                          </div>

                          <div :data-id="JSON.stringify(
        scope.row
      )
        " style="
                                                        color: #ff0c0c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 1">
                            <img src="../assets/newImg/路径@1x (4).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                            <div style="
                                                            text-align: center;
                                                        ">
                              {{ scope.row.up_price }}
                            </div>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>

                  </vue-seamless-scroll>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="tableVideo">
        <div class="tableStyle" v-for="item in newTableData " :key="item.good_name">
          <el-table :data="item.data.slice(0, 5)" border style="width: 100%" :row-class-name="tableRowClassName"
            height="220" @row-click="rowClick" :cell-style="{
        'text-align': 'center'
      }" :header-cell-style="{ 'text-align': 'center', height: '4px', border: '0px solid #227BFF', color: '#227BFF', }">
            <el-table-column prop="goodsName" :label="item.good_name">
              <template #default="scope">
                <div style="  cursor: pointer;">
                  {{ scope.row.goodsName }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="busNameAbbrev" label="企业"></el-table-column>
            <!-- <el-table-column prop="busName" label="省份"></el-table-column> -->
            <el-table-column prop="bqCity" label="发货地">
              <template #default="scope">
                <div>
                  {{ scope.row.bqCity ? scope.row.bqCity : scope.row.bqProvince }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="bqPrice" label="价格"></el-table-column>
            <el-table-column prop="bqSpec" label="规格单位"></el-table-column>

          </el-table>
        </div>

        <div class="edit-item" v-for="item in videoList" @click="toVedio(item)" :key="item.vId">
          <div style="position: relative;">
            <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;">
              {{
              item.vAddTime.slice(0, 10) }}
            </div>
            <img :src="item.vImgUrl" style="width: 100%;height: 120px;object-fit: cover;border-radius: 12px;" alt="">
          </div>
          <div style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
            {{ item.vTitle }}</div>
          <div style="color: gray;font-size: 12px;color: #9499A0;margin-top: 4px;">
            <img src="../assets/newImg3/容器@1x (3).png" style="vertical-align: middle;margin-right: 4px;" alt="">

            <span style="vertical-align: middle;">{{ item.vUploader }}</span>
          </div>
          <div style="color: gray;font-size: 12px;margin-top: 8px;color: #9499A0;">
            <img src="../assets/newImg/Vector@0.75x.png"
              style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;" alt="">

            <span style="vertical-align: middle;margin-left: 4px;">{{ item.vAddTime.slice(0,10) }}</span>
            <img src="../assets/newImg3/Clip path group@1x (1).png"
              style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;margin-left: 20px;"
              alt="">
            <span style="vertical-align: middle;margin-left: 4px;">{{ item.playNum }}</span>



          </div>

        </div>

      </div>

      <!-- <div class="video-content">
        <div class="video-title">
          <div style="margin-left: 20px;font-weight:900;font-size: 24px;color: #227BFF;font-weight: 400;">相关视频</div>
        </div>
        <div class="video-bottom">

          <div class="video">
            <div class="edit-box">
              <div class="edit-item" v-for="item in videoList" @click="toVedio(item)" :key="item.vId">
                <div style="position: relative;">
                  <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;">
                    {{
                    item.vAddTime.slice(0, 10) }}
                  </div>
                  <img :src="item.vImgUrl" style="width: 100%;height: 116px;object-fit: cover;border-radius: 12px;"
                    alt="">
                </div>
                <div
                  style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  {{ item.vTitle }}</div>
                <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                  <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle;margin-right: 1px;"
                    alt="">

                  <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import searchBar from "../components/searchBar.vue";
import classifyBar from "../components/classifyBar.vue";
import classifyContain from "../components/classifyContain.vue";
import loginCon from "../components/loginCon.vue";
import strictProduct from "../components/strictProduct.vue";
import product from "../components/product.vue";
import pillarData2 from "../components/pillarData2.vue";
import getParams from "../utils/getParams";
import dayjs from 'dayjs';
import Vue from 'vue'

import { getQutoByCatIds, getAllVod, getRegionQuotation, getAllGoodsConsult, getQuotByParams, getBstGoodsGategoryAndSon, getBstGGateFatherById } from '@/api/user'
export default {
  data() {
    return {
      newTableData: [],
      data: {},
      tableList: [],
      tableList2: [],
      tableList3: [],
      tableList4: [],
      areaCategorySonList2: [],
      IndustryInformationList1: [],
      IndustryInformationList2: [],
      newCateryList: [],
      cateryShowList: [],
      wangNumber: 0,
      wangNumber2: -1,
      postData: {},
      imgList: ["../assets/index/banner.png"],
      videoList: [],
      productList: [],
      radio: '0',
      radio2: '',
      radio3: '',
      radio4: '',
      dataList: [],
      typeName: '',
      typeName2: '',
      tableData: [],
      marketChekoutFlag: 1,
      checkList: [],
      checkList2: [],
      //按钮标签
      quyuFlag: 1,
      shichang: 1,
      shichang2: 1,
      quyuFlag2: 1,
      pirceFlag: 1,

      //数据列表
      FactoryMarket: "",
      areaCategoryList: [


      ],
      areaCategoryList2: [],
      //出厂id
      market_house_id: 1,
      areaCategorySonList: [],
      //区域id
      shichangId: "",
      parent_id: "",
      dateData: [],
      cat_one: "",
      cat_two: "",
      click_num: 0,
      frequency: 1,
      goodName: "",
      title2Flag: 0,
      isRouterAlive: true,
      MarketList: "",
      search: "",
      area_two: 0
    };
  },
  computed: {

    classOption2() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      }
    },
    classOption3() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  methods: {
    rowClick(row) {
      console.log(row, 'row--');
      const { href } = this.$router.resolve({
        path: "historicalQuotation",
        query: {
          catOne: row.catOne,
          catTwo: row.catTwo,
          catThree: row.catThree,
          catFour: row.catFour,
          goodName: row.goodsName,
          quotation_man: row.quotationMan,
          area_one: row.areaOne,
          area_two: row.areaTwo
        },
      });
      window.open(href, "_blank");
    },
    radioChange4(e, item) {
      const { href } = this.$router.resolve({
        path: '/todayQuotedPriceCopy',
        query: {
          data: JSON.stringify({
            catOne: item.value,
            catTwo: e.value,
            label: e.label,

            cat_id: e.value

          })
        }

      });
      window.open(href, "_blank");

    },
    radioChange5(e, catTwo) {
      const { href } = this.$router.resolve({
        path: '/todayQuotedPrice',
        query: {
          data: JSON.stringify({
            catOne: '',
            catTwo: catTwo,
            catThree: e.value,
            label: e.label,
            cat_id: e.value
          })
        }

      });
      window.open(href, "_blank");
      console.log(e.label,);

    },
    godeilt() {

    },
    tableClick2(row) {
      console.log(row,'--');
      const { href } = this.$router.resolve({
        path: "historicalQuotation",
        query: {
          catOne: row.cat_one,
          catTwo: row.cat_two,
          catThree: row.cat_three,
          catFour: row.catFour,
          goodName: row.good_name,
          quotation_man: row.quotationMan,
          area_one: row.area_one,
          area_two: row.area_two
        },
      });
      window.open(href, "_blank");
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return
      }



    },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",
        query: {
          v_id: row.vId,

        },
      });
      window.open(href, "_blank");
    },
    handleMouseEnter(indexs, gId) {

      this.wangNumber = gId
      this.wangNumber2 = indexs
      this.cateryShowList.forEach((item, index) => {
        this.cateryShowList[index] = false
      })
      Vue.set(this.cateryShowList, indexs, true)
    },
    handleMouseLeave() {
      this.wangNumber = 0
      this.cateryShowList.forEach((item, index) => {
        Vue.set(this.cateryShowList, index, false)
      })

    },
    goSearch() {
      const { href } = this.$router.resolve({
        path: "search",
        query: {
          activeName: 'second'
        }
      });
      window.open(href, "_blank");
    },
    allVideo() {
      const { href } = this.$router.resolve({
        path: "search",

      });
      window.open(href, "_blank");
    },
    goBack() {
      this.$router.go(-1)
    },
    toTitle(item) {
      const { href } = this.$router.resolve({
        path: '/titleDetails',
        query: {
          id: item.bgcId
        }
      });
      window.open(href, "_blank");

    },


    goTitle(item) {
      console.log(item, 'item');
      this.$router.push({
        path: '/titleDetails',
        query: {
          id: item.bgcId
        }
      })
    },
    goVideo(data) {
      this.$router.push({
        path: '/vudio',
        query: {
          v_id: data.vId,
          industry: this.$route.query.cat_id,
          industry2: this.$route.query.cat_id2,
        }
      })
    },

    getPrice() {
      let data = JSON.parse(this.$route.query.data)
      console.log(data, 'data==========');
      let params = {
        cat_one: data.catOne,
        cat_two: data.catTwo,
        cat_three: data.catThree,
        cat_four: data.catFour,
        industry: this.$route.query.cat_id,
        industry2: this.$route.query.cat_id2 != undefined ? this.$route.query.cat_id2 : '',
        area: this.radio,
        province: this.radio2,
        enterprise: this.radio3,
        goods_name: this.radio4,
      }
      getRegionQuotation(params).then((res) => {
        this.tableData = res.data.data
      })
    },
    radioChange0(e) {
      const { href } = this.$router.resolve({
        path: '/todayQuotedPriceCopy',
        query: {
          data: JSON.stringify({
            catOne: e.value,
            catTwo: '',
            label: e.label,
            cat_id: e.value

          })
        }

      });
      window.open(href, "_blank");

    },
    gotoPriceTrend(val) {

      const tableData = [
        {
          production: '白油',//第一
          name: '中国石油', //第二
          number: '123',//第三列
          count: 123,//第四列数据
        },
        {
          production: '红油',
          name: '美国石油',
          number: '132',
          count: 123
        },
        {
          production: '食用油',
          name: '非洲石油',
          number: '123',
          count: 123
        },]




      this.area_two = val.id
      let objT = {};
      objT.name = this.goodName;
      objT.cat_one = this.cat_one;
      objT.cat_two = this.cat_two;
      objT.cat_three = this.cat_three;
      objT.market_house_id = this.market_house_id;
      objT.area_one = this.$route.query.parent_id
      objT.area_two = this.area_two
      objT.tableData = this.tableData;
      objT.dateData = this.dateData;

      this.skipPage("/priceTrend" + `?item=${JSON.stringify(objT)}`);
    },
    cellStyle() {
      return "'color': 'red'";
    },
    marketChekout(num, market_house_id) {
      this.marketChekoutFlag = num;
      this.parent_id = market_house_id;
      this.checkList = [];
      this.market_house_id = "";
      this.shichangId = "";
      this.dateData = [];
      this.tableData = [];
      this.areaCategory();
      this.fiveQuotedPriceList();
      this.fiveQuotedPriceList2();
      // this.areaCategorySon()
    },

    skipPage(page) {
      window.location = page;
    },


    changeCellStyle(row, column, rowIndex, columnIndex) {
      //列的label的名称
      if (row.column.label != "市场" && row.column.label != "单位") {
        return "text-align: center; color:black"; //修改的样式
      } else {
        return "text-align: center;";
      }
    },

  },
  mounted() {
    console.log(this.$route, '-===');
    this.data = JSON.parse(this.$route.query.data)
    let params = {
      cat_one: this.data?.catOne,
      cat_two: this.data?.catTwo,
      size: 7,
      hits: '',
      rise_fall: '',
    }
    let params1 = {
      cat_one: this.data?.catOne,
      cat_two: this.data?.catTwo,
      size: 7,
      hits: 0,
      rise_fall: 1,
    }
    let params2 = {
      // cat_one: this.data?.catOne,
      // cat_two: this.data?.catTwo,
      cat_one: '',
      cat_two: '',
      cat_three: '',
      industry: '',
      industry2: '',
      size: 8,
      page: 1
    }
    let params3 = {
      cat_one: this.data?.catOne,
      cat_two: this.data?.catTwo,
      size: 7,
      hits: 0,
      rise_fall: 0,
    }
    let params4 = {
      cat_one: this.data?.catOne,
      cat_two: this.data?.catTwo,
      size: 7,
      hits: 1,
      rise_fall: '',
    }
    let params5 = {
      cat_one: this.data?.catOne,
      cat_two: this.data?.catTwo,

    }
    getQuotByParams(params).then((res) => {
      this.tableList = res.data.data.slice(0, 7)
    })
    getQuotByParams(params1).then((res) => {
      this.tableList2 = res.data.data.slice(0, 7)
    })
    getQuotByParams(params3).then((res) => {
      this.tableList3 = res.data.data.slice(0, 7)
    })
    getQuotByParams(params4).then((res) => {
      this.tableList4 = res.data.data.slice(0, 7)
    })

    getQutoByCatIds(params5).then((res) => {
      this.newTableData = res.data.data.slice(0, 4)
    })
    getAllGoodsConsult(params2).then((res) => {
      res.data.data.forEach((item, index) => {
        item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD').slice(0, 10);
        item.bgcContent = item.bgcContent.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')

      })
      this.IndustryInformationList1 = res.data.data.slice(0, 2)
      this.IndustryInformationList2 = res.data.data.slice(2, 8)
    })
    // getBstQuotedPrice({ size: 61 }).then((res) => {
    //   this.tableList2 = res.data.data.slice(0, 7)


    // })
    getBstGoodsGategoryAndSon().then((res) => {
      this.newCateryList = res.data.data
      this.newCateryList.forEach((item, index) => {

        this.cateryShowList[index] = false
      })
    })

    let c = JSON.parse(this.$route.query.data)
    if (c.cat_id) {
      getBstGGateFatherById({ cat_id: c.cat_id }).then((res) => {
        if (res.data.code == 200) {
          var params2 = {
            cat_one: res.data.data,
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            page: 1,
            size: 30,
          }

          getAllVod(params2).then((res) => {
            this.videoList = res.data.data
          })
        }
      })
    } else {
      getBstGGateFatherById({ cat_id: c.catTwo }).then((res) => {
        if (res.data.code == 200) {
          var params2 = {
            cat_one: res.data.data,
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            page: 1,
            size: 30,
          }

          getAllVod(params2).then((res) => {
            this.videoList = res.data.data
          })
        }
      })
    }

    this.postData = JSON.parse(this.$route.query.data)
    this.typeName = this.postData.label




    // 获取当前日期  
    let today = dayjs();

    // 创建一个空数组来存储日期  
    // let datesArray = [];

    // 循环四次，获取前四天的日期  
    for (let i = 4; i >= 0; i--) {
      this.dataList.push(today.subtract(i, 'day').format('YYYY-MM-DD'));
    }
    let data = JSON.parse(this.$route.query.data)
    this.goodName = data.goods_name



    // this.getPrice()

  },
  components: {
    searchBar,
    classifyBar,
    classifyContain,
    loginCon,
    strictProduct,
    product,
    pillarData2,
  },
};
</script>

<style lang="scss">
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #006eb7;
  font-size: 14px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #006eb7;
  font-size: 14px !important;
}

.el-table .cell {
  white-space: pre-wrap !important;
}
</style>

<style lang="scss" scoped>
.btnCol {
  color: #227BFF;
  font-weight: 700;
}

@media screen and (min-width: 1px) and (max-width: 1680px) {
  .hhe {
    width: 990px;
  }

  .priceBox {
    height: 210px;
    margin-top: 30px;
    display: flex;

    .price-item {
      flex-wrap: wrap;
      transition: all 0.7s;
      width: 170px;
      height: 100%;
      border-right: 1px solid #D8D8D8;
      display: flex;
      overflow: hidden;
      border-top: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      border-left: 1px solid #D8D8D8;

      .chartStyle {
        width: 130px;
        height: 200px;
      }

      .price-item-one {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        // margin-top: 5px;

      }

      .price-item-one2 {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        margin-top: 10px;
      }


      .priceIfUp1 {
        margin-left: 15px;
        width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: #00B050;
        display: flex;
      }

      .priceIfUp2 {
        margin-left: 15px;
        width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: #7AAFFD;
        display: flex;
      }

      .priceIfUp3 {
        margin-left: 15px;
        width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: red;
        display: flex;
      }
    }

    .price-item:last-child {
      border-right: 1px solid #D8D8D8;

    }


  }

  .tableVideo {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    .tableStyle {
      width: 48.2%;
      margin-right: 21px;
      height: 218px;
      margin-top: 30px
    }

    .edit-item {
      box-sizing: border-box;
      cursor: pointer;
      width: 16.6%;
      height: 220px;
      ;
      padding: 30px 15px 0px 15px;
      // background-color: skyblue;
    }

  }

  .priceScroll {
    width: 100%;
    height: 415px;
    display: flex;
    margin-top: 30px;
    border-radius: 5px;

    .priceScroll-left {
      width: 32.5%;
      height: 99%;
      margin-right: 10px;
      border: 1px solid #EAEAEA;

    }

    .priceScroll-middle {
      width: 32.5%;
      height: 99%;
      margin-right: 10px;
      border: 1px solid #EAEAEA;
    }

    .priceScroll-right {
      width: 32.5%;
      height: 99%;
      border: 1px solid #EAEAEA;
    }
  }

  .consultClass {
    width: 100%;
    height: 126px;
    display: flex;
    padding: 4px 0px;
    margin-top: 30px;

    .six-item {
      box-sizing: border-box;
      width: 48.5%;
      height: 100%;
      margin-right: 26px;
      border-radius: 10px;
      display: flex;
      margin-top: 5px;
      margin-left: 1px;
      border: 1px solid #EAEAEA;

      .six-left {
        width: 32%;
        height: 100%;
      }

      .six-right {
        width: 64%;
        height: 100%;
        padding: 2px 6px 2px 16px;

        .six-right-1 {
          height: 19px;
          padding: 4px 1px 1px 1px;
          font-weight: 900;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #000000;
          font-size: 14px;
        }

        .six-right-2 {
          height: 30px;
          padding: 12px 2px 2px 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 12px;
          color: #000000;
        }

        .six-right-3 {
          display: flex;
          height: 15%;
          padding: 17px 0px 0px 4px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }

  .title-item-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 2px 6px;

    .title-item {
      width: 50%;
      height: 20px;
      padding: 12px 0px;
      overflow: hidden !important;
      /* 超出部分隐藏 */
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      font-size: 16px;
      color: #333333;
      cursor: pointer;
    }

    .title-item:hover {
      color: #227BFF;
      ;
    }
  }

  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227BFF 0%, #5F9EFA 51%, #227BFF 99%);

    .catery-big {
      width: 1200px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        cursor: pointer;
        position: relative;
        margin-top: 1.4px;
        box-sizing: border-box;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 500%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #D8D8D8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227BFF;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;

            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7
              }
            }
          }
        }
      }
    }
  }

  .content2 {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .selectBox {
    width: 1200px;
    // background-color: red;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 0px;
    margin-top: 18px;
    // height: 292px;

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }


      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }

  .video {
    width: 100%;

    .edit-box {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;
      margin-top: 14px;

      .edit-item {
        box-sizing: border-box;
        cursor: pointer;
        width: 239px;
        height: 174px;
        padding: 0px 15px;
        // background-color: skyblue;
      }
    }
  }

  .quotedBox {
    width: 1200px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 24px 0px;
    box-sizing: border-box;
    background-color: white;

    .h {
      display: flex;
      align-items: center;

      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }
    }

    .tableBox {
      margin-top: 22px;
      border-top: 1px solid #e4e4e4;
    }

    .title {
      width: 83px;
      height: 28px;
      border-radius: 14px;
      background: #006eb7;
      font-size: 12px;
      text-align: center;
      line-height: 28px;
      color: white;
    }

    :deep(.el-table .el-table__cell) {
      padding: 17px 0 !important;
      white-space: pre-wrap !important;
    }
  }

  .video-content {
    cursor: pointer;
    width: 100%;
    height: 1080px;
    background-color: white;
    margin-bottom: 100px;

    .video-title {
      width: 100%;
      height: 50px;
      // background-color: skyblue;
      // background-image: linear-gradient(rgb(212, 230, 255), white);

      // padding-left: 5px;
      line-height: 50px;
      display: flex;
      justify-content: space-between
    }

    .video-bottom {
      height: 600px;
      width: 100%;
      display: flex;
      // flex-flow: column wrap;
      background-color: white;
      flex-wrap: wrap;

      .video-bottom-box {
        padding: 5px 10px;
        width: 20%;
        height: 158px;
        box-sizing: border-box;

        .box-img {
          width: 100%;
          height: 116px;

        }

        .video-img:hover {
          box-shadow: 5px 5px 5px gray;
        }

        .box-title {
          width: 100%;
          height: 21px;
          white-space: nowrap;
          /* 文本不换行 */
          overflow: hidden;
          /* 溢出部分隐藏 */
          text-overflow: ellipsis;
          padding-top: 5px;
          font-weight: 900;
        }

        .box-title:hover {
          color: rgb(32, 123, 255) !important;
        }


        .box-title2:hover {
          color: rgb(32, 123, 255) !important;

        }

        .box-title2 {
          color: gray;
          width: 100%;
          height: 21px;
          display: flex;
        }

      }
    }
  }

}

@media screen and (min-width: 1680px) and (max-width: 10620px) {
  .hhe {
    width: 1200px;
  }

  .priceBox {
    overflow: hidden;
    box-sizing: border-box;

    height: 210px;
    margin-top: 30px;
    // display: inline-block;
    display: flex;

    .price-item {
      height: 100%;
      // border: 1px solid #D8D8D8;
      box-sizing: border-box;
      display: flex;
      // margin-right: -1px;
      // display: inline-block;
      width: 210px;
      border-top: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      border-left: 1px solid #D8D8D8;
      // width: 400px;

      flex-wrap: wrap;
      transition: all 0.7s;
      padding: 2px;
      // margin-left: 1px;

      .chartStyle {
        width: 175px;
        height: 200px;
      }

      .price-item-one {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 23px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        // margin-top: 2px;
      }

      .price-item-one2 {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 23px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        margin-top: 10px;
      }

      .priceIfUp1 {
        margin-left: 20px;
        width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: #00B050;
        display: flex;
      }

      .priceIfUp2 {
        margin-left: 20px;
        width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: #7AAFFD;
        display: flex;
      }

      .priceIfUp3 {
        margin-left: 20px;
        width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        background-color: red;
        display: flex;
      }
    }


    .price-item:last-child {
      border-right: 1px solid #D8D8D8;

    }



  }

  .consultClass {
    width: 100%;
    height: 126px;
    display: flex;
    padding: 4px 0px;
    margin-top: 30px;

    .six-item {

      box-sizing: border-box;
      width: 48.5%;
      height: 100%;
      margin-right: 26px;
      border-radius: 10px;
      display: flex;
      margin-top: 5px;
      margin-left: 1px;
      border: 1px solid #EAEAEA;

      .six-left {
        width: 32%;
        height: 100%;
      }

      .six-right {
        width: 64%;
        height: 100%;
        padding: 2px 6px 2px 16px;

        .six-right-1 {
          height: 19px;
          padding: 4px 1px 1px 1px;
          font-weight: 900;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #000000;
          font-size: 14px;
        }

        .six-right-2 {
          height: 30px;
          padding: 12px 2px 2px 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 12px;
          color: #000000;
        }

        .six-right-3 {
          display: flex;
          height: 15%;
          padding: 17px 0px 0px 4px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }

  .title-item-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 2px 6px;

    .title-item {
      width: 50%;
      height: 20px;
      padding: 12px 0px;
      overflow: hidden !important;
      /* 超出部分隐藏 */
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      font-size: 16px;
      color: #333333;
      cursor: pointer;
    }

    .title-item:hover {
      color: #227BFF;
      ;
    }
  }

  .tableVideo {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    .tableStyle {
      width: 48.6%;
      margin-right: 21px;
      height: 218px;
      margin-top: 30px
    }

    .edit-item {
      box-sizing: border-box;
      cursor: pointer;
      width: 16.6%;
      height: 220px;
      ;
      padding: 30px 15px 0px 15px;
      // background-color: skyblue;
    }

  }

  .priceScroll {
    width: 100%;
    height: 415px;
    display: flex;
    margin-top: 30px;
    border-radius: 5px;

    .priceScroll-left {
      width: 32.5%;
      height: 99%;
      margin-right: 10px;
      border: 1px solid #EAEAEA;

    }

    .priceScroll-middle {
      width: 32.5%;
      height: 99%;
      margin-right: 10px;
      border: 1px solid #EAEAEA;
    }

    .priceScroll-right {
      width: 32.5%;
      height: 99%;
      border: 1px solid #EAEAEA;
    }
  }



  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227BFF 0%, #5F9EFA 51%, #227BFF 99%);

    .catery-big {
      width: 1500px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        position: relative;
        cursor: pointer;

        margin-top: 1.4px;
        box-sizing: border-box;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 500%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #D8D8D8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227BFF;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;

            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7
              }
            }
          }
        }
      }
    }
  }

  .content2 {
    width: 1500px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .selectBox {
    width: 1500px;
    // background-color: red;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 0px;
    margin-top: 18px;
    // height: 292px;

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }


      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }

  .video {
    width: 100%;

    .edit-box {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      gap: 0;
      align-content: flex-start;
      justify-content: flex-start;
      margin-top: 14px;

      .edit-item {
        box-sizing: border-box;
        cursor: pointer;
        width: 250px;
        height: 174px;
        padding: 0px 15px;
        // background-color: skyblue;
      }
    }
  }

  .quotedBox {
    width: 1500px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 24px 0px;
    box-sizing: border-box;
    background-color: white;

    .h {
      display: flex;
      align-items: center;

      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }
    }

    .tableBox {
      margin-top: 22px;
      border-top: 1px solid #e4e4e4;
    }

    .title {
      width: 83px;
      height: 28px;
      border-radius: 14px;
      background: #006eb7;
      font-size: 12px;
      text-align: center;
      line-height: 28px;
      color: white;
    }

    :deep(.el-table .el-table__cell) {
      padding: 17px 0 !important;
      white-space: pre-wrap !important;
    }
  }

  .video-content {
    cursor: pointer;
    width: 100%;
    height: 960px;
    background-color: white;
    margin-bottom: 100px;

    .video-title {
      width: 100%;
      height: 50px;
      // background-color: skyblue;
      // background-image: linear-gradient(rgb(212, 230, 255), white);

      // padding-left: 5px;
      line-height: 50px;
      display: flex;
      justify-content: space-between
    }

    .video-bottom {
      height: 600px;
      width: 100%;
      display: flex;
      // flex-flow: column wrap;
      background-color: white;
      flex-wrap: wrap;

      .video-bottom-box {
        padding: 5px 10px;
        width: 20%;
        height: 158px;
        box-sizing: border-box;

        .box-img {
          width: 100%;
          height: 116px;

        }

        .video-img:hover {
          box-shadow: 5px 5px 5px gray;
        }

        .box-title {
          width: 100%;
          height: 21px;
          white-space: nowrap;
          /* 文本不换行 */
          overflow: hidden;
          /* 溢出部分隐藏 */
          text-overflow: ellipsis;
          padding-top: 5px;
          font-weight: 900;
        }

        .box-title:hover {
          color: rgb(32, 123, 255) !important;
        }


        .box-title2:hover {
          color: rgb(32, 123, 255) !important;

        }

        .box-title2 {
          color: gray;
          width: 100%;
          height: 21px;
          display: flex;
        }

      }
    }
  }

}



.topInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .priceBox2 {
    width: 100%;
    // width: 989px;
    // margin-left: 210px;
    margin-top: 40px;
    // height: 365px;

    .productBox {
      position: relative;

      .imglogo {
        width: 48px;
        height: 81px;
        border-radius: 2.28px;
        position: absolute;
        bottom: 0;
        left: 24px;
        opacity: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .produt {
        position: absolute;
        left: 91px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.1px;
        line-height: 23.4px;
        text-align: left;
        color: #fff;
      }
    }

    .location {
      margin-top: 10px;
      display: flex;
      align-items: center;
      transform: translateY(10px);
      height: 50px;

      .nav {
        // margin-top: 5px;
      }
    }


    .top {
      width: 100%;
      height: 167px;
      border-radius: 4px;
      background-color: white;
      padding: 12px 0;
      box-sizing: border-box;

      .title {
        width: 188px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background: #006eb7;
        text-align: center;
        line-height: 30px;
        color: white;
      }

      .contaionBox {
        margin-top: 21px;

        .line1 {
          width: 238px;
          height: 82px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e4e4e4;

          .top2 {
            font-size: 28px;
          }

          .bottom {
            margin-top: 4px;
            display: flex;
            align-items: center;
            color: #21a00c;

            .left {
              padding: 0 10px;
              border-right: 2px solid #e4e4e4;
            }

            .right {
              padding: 0 10px;
            }
          }

          .bottom2 {
            margin-top: 4px;
            display: flex;
            align-items: center;
            color: #ff0c0c;

            .left {
              padding: 0 10px;
              border-right: 2px solid #e4e4e4;
            }

            .right {
              padding: 0 10px;
            }
          }
        }

        .line1:last-child {
          border-right: none;
        }
      }
    }
  }
}


.market {
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // align-items: flex-start;

  .line2 {
    width: 350px;
    height: 476px;
    background-color: white;
    padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        border-left: 3px solid #006eb7;
        padding-left: 5px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
      margin-bottom: 5px;
    }

    .contaion {
      .info {
        width: 70%;
        height: 50px;
        line-height: 50px;
      }

      .date {
        width: 30%;
        font-size: 16px;
        color: #909090;
      }
    }
  }

  .rightLine {
    width: 828px;
    height: 476px;

    .top3 {
      width: 828px;
      height: 227px;
      background-color: white;
      border-radius: 4px;
      padding: 16px;
      // margin-right: 24px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          border-left: 3px solid #006eb7;
          padding-left: 5px;
        }

        .date {
          font-size: 10px;
          color: #aeaeae;
          margin-left: 6px;
        }
      }

      .theLine {
        height: 2px;
        background-color: #e4e4e4;
        margin-top: 7px;
        margin-bottom: 5px;
      }

      .contaion {
        padding: 20px 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .left {
          .btn {
            margin-bottom: 20px;
            width: 81px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid #006eb7;
            color: #006eb7;
            box-sizing: border-box;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            cursor: pointer;
          }

          .btnBgc {
            background-color: #006eb7 !important;
            color: white !important;
            border: none !important;
          }
        }

        .right {
          width: 690px;
          display: flex;
          flex-wrap: wrap;

          .region {
            width: 33%;
            text-align: center;
            font-size: 23.85px;
            letter-spacing: 2.39px;
            cursor: pointer;
          }

          .region:first-child {
            margin-bottom: 44px;
          }
        }
      }
    }

    .top3 {
      margin-bottom: 22px;
      width: 828px;
      height: 227px;
      background-color: white;
      // padding: 16px;
      // margin-right: 24px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          border-left: 3px solid #006eb7;
          padding-left: 5px;
        }

        .date {
          font-size: 10px;
          color: #aeaeae;
          margin-left: 6px;
        }
      }

      .theLine {
        height: 2px;
        background-color: #e4e4e4;
        margin-top: 7px;
        margin-bottom: 7px;
      }

      .contaion2 {
        padding: 0 !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;

        .info {
          width: 70%;
          height: 50px;
          line-height: 50px;
        }

        .date {
          width: 30%;
          font-size: 16px;
          color: #909090;
        }
      }

      .contaion2:last-child {
        border: none;
      }
    }
  }
}

.productBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .productInfo {
    margin-right: 15px;
  }
}

.productInfo:nth-child(5n) {
  margin-right: 0;
}

.footBar {
  width: 1920px;
  height: 284px;
  background-color: #006eb7;
}




.information-bottom::-webkit-scrollbar {
  width: 8px;
}

.information-bottom::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.information-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);

}

.information-latest {
  cursor: pointer;
  width: 100%;
  height: 800px;
  background-color: white;

  .information-top {
    width: 100%;
    height: 50px;
    background-image: linear-gradient(rgb(212, 230, 255), white);
    line-height: 50px;
    display: flex;
    justify-content: space-between
  }



  .information-bottom {
    width: 100%;
    height: 750px;

    overflow: auto;

    .box {
      width: 100%;
      height: 18%;
      // background-color: red;
      display: flex;
      margin-top: 12px;

      .box-left {
        width: 22%;
        height: 100%;
      }

      .box-right {
        width: 78%;
        height: 100%;
        // display: flex;
        padding: 10px 6px 6px 20px;

        .box-1 {
          height: 20%;
          width: 100%;
          padding-bottom: 8px;
          font-weight: 900;
        }

        .box-2 {
          height: 31%;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .box-2:hover {
          color: rgb(32, 123, 255) !important;

        }

        .box-3:hover {
          color: rgb(32, 123, 255) !important;

        }

        .box-3 {
          padding-top: 10px;
          height: 20%;
          width: 100%;
          color: gray;
          font-size: 14px;
        }
      }
    }
  }
}


.el-radio__label {
  padding-left: 0px !important;
}

::v-deep .el-radio {
  // width: 120px;
}

::v-deep .el-table .warning-row {
  background-color: #F3F7FC;
}

.bgc2 {
  background-color: white;
  color: #227BFF !important;
}

.specialMargin {
  // background-color: yellow !important;
  left: -400% !important;
}

::v-deep.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  /* 或者你想要的宽度 */
  height: 8px;
  /* 垂直滚动条的高度 */
}

/* 自定义滚动条滑块样式 */
::v-deep.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* 滑块颜色 */
  border-radius: 10px;
  /* 圆角 */
}

/* 自定义滚动条轨道样式 */
::v-deep el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 轨道颜色 */
  border-radius: 10px;
  /* 圆角 */
}

/* 当鼠标悬停在滑块上时，改变滑块样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* 滑块悬停颜色 */
}

.has-gutter {
  height: 10px !important
}

.scroll_table {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 100%;
  // height: 900px !important;

  .table_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 582px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      >span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  .seamless-warp {
    height: 318px;
    overflow: hidden;


    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }


}

::v-deep .el-table .warning-row {
  background-color: #F3F7FC;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}

::v-deep .el-table .el-table__cell {
  padding: 6px 0px;
  // padding: ;
}

// ::v-deep .el-table__header-wrapper {
//   height: 30px !important;
//   line-height: 30px !important;
//   // margin-bottom:20px !important;
//   box-sizing: border-box
// }</style>
