<template>
  <div style="width: 87vw; margin-left: 10px; padding: 0px 15px; height: 100%; border-radius: 5px">
    <el-dialog title="" :visible.sync="useVisible" width="56%">
      <div></div>
      <div style="white-space: pre-wrap; height: 600px; overflow-y: scroll"><span v-html="infoList.pContent"></span></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="useVisible = false">取 消</el-button>
        <el-button type="primary" @click="useVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="useVisible2" width="56%">
      <div></div>
      <div style="white-space: pre-wrap; height: 600px; overflow-y: scroll"><span v-html="infoList2.pContent"></span></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="useVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="useVisible2 = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-tabs v-model="activeName" style="padding: 10px">
      <el-tab-pane label="视频投稿" name="first" style="background-color: white; padding: 20px 10px">
        <el-dialog title="" :visible.sync="dialogVisibleVideo" width="45%" style="z-index: 9999999999999">
          <video autoplay muted controls :src="showVideo" style="width: 100%; height: 500px; opacity: 1"></video>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleVideo = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisibleVideo = false">确 定</el-button>
          </span>
        </el-dialog>

        <div style="width: 94%; height: 280px; cursor: pointer" v-if="videoShow" @click="videoShowChange">
          <img src="../assets/newImg2/Upload@1x.png" style="width: 100%; height: 100%" alt="" />
        </div>
        <div style="width: 94%; height: 96px; margin-top: 30px" v-if="videoShow">
          <img src="../assets/newImg2/视频格式@1x (1).png" style="width: 32%; margin-right: 30px" alt="" />
          <img src="../assets/newImg2/视频大小@1x.png" style="width: 31%; margin-right: 30px" alt="" />
          <img src="../assets/newImg2/视频分辨率@1x.png" style="width: 32%" alt="" />
        </div>
        <div style="width: 100%; text-align: center" v-if="videoShow">
          <span class="tit-black">上传视频，即表示您已同意</span>
          <span class="tit-blue" @click="show1">搜原网使用协议</span>
          <span class="tit-black">与</span>
          <span class="tit-blue" @click="show2"> 搜原网社区公约</span>
          <span class="tit-black">，请勿上传色情，反动等违法视频。</span>
        </div>
        <el-form :model="form2" ref="ruleFormRef2" :rules="rules2" v-if="!videoShow">
          <el-form-item label="上传视频" label-width="145px" required>
            <el-upload
              class="upload-demo"
              action="https://www.zhnl.com/zhnl/breakPointUpload"
              :limit="1"
              :file-list="form2.fileList"
              :headers="headerObj"
              :on-success="handleSuccess2"
              :on-exceed="handleExceed"
              :on-progress="onProgressTest"
              style="width: 300px"
              :before-upload="beforeUpload2"
            >
              <el-button type="primary" size="mini">点击上传</el-button>
              <div class="el-upload__tip" slot="tip">只能上传mp4文件，且不超过100M</div>
            </el-upload>
            <el-button type="primary" size="mini" @click="Preview" v-show="preShow">预览</el-button>
          </el-form-item>
          <el-form-item label="封面设置" label-width="145px">
            <el-upload action="https://www.zhnl.com/zhnl/uploadCoverForVod" list-type="picture-card" :limit="1" :on-success="handleSuccess3" :headers="headerObj" :before-upload="beforeAvatarUpload2">
              <i class="el-icon-plus"></i>
            </el-upload>
            <div style="color: gray; font-size: 12px; margin-left: 26px">上传220px*124px尺寸的图片</div>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>

          <el-form-item label="视频来源" label-width="145px" prop="isOriginal">
            <el-radio-group v-model="form2.isOriginal">
              <el-radio :label="0">原创</el-radio>
              <el-radio :label="1">转载</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="标题名称" label-width="145px" prop="v_title">
            <el-input v-model="form2.v_title" autocomplete="off" required style="width: 440px" show-word-limit maxlength="20" />
          </el-form-item>

          <el-form-item label="转载地址" label-width="145px" prop="reprintUrl" v-show="form2.isOriginal == 1">
            <el-input v-model="form2.reprintUrl" autocomplete="off" required style="width: 440px" maxlength="40" show-word-limit />
          </el-form-item>

          <el-form-item label="原材料标签" label-width="124px" prop="cat_one" required style="margin-top: 10px">
            <i class="el-icon-question" style="margin-right: 6px" @mouseleave="handleMouseOver2" @mouseenter="handleMouseOver1"></i>
            <span style="position: absolute; top: -30px; left: -100px; z-index: 999; color: gray; font-size: 12px" v-show="cateryShow">在视频中涉及的原材料填选，发布视频后将会在原材料频道里展示</span>
            <el-select v-model="form2.cat_one" placeholder="Select" size="large" style="width: 700px; z-index: 1000" multiple-limit="2" multiple :popper-append-to-body="false" @change="Vselect1">
              <el-option v-for="item in form2.product_list1" :key="item.gId" :label="item.label" :value="item.gId" />
            </el-select>
          </el-form-item>
          <el-form-item label="行业标签" label-width="124px" required prop="industry" style="margin-top: 10px">
            <i class="el-icon-question" style="margin-right: 6px" @mouseleave="handleMouseOver4" @mouseenter="handleMouseOver3"></i>
            <span style="position: absolute; top: -30px; left: -100px; z-index: 999; color: gray; font-size: 12px" v-show="hangShow">在视频中涉及的行业填选，发布视频后将会在行业频道里展示。</span>
            <el-select v-model="form2.industry" placeholder="Select" size="large" style="width: 700px" multiple-limit="2" multiple :popper-append-to-body="false" @remove-tag="removeTag">
              <el-option v-for="item in classifyList2" :key="item.gId" :label="item.label" :value="item.gId" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="原材料二级分类" label-width="130px" prop="cat_two" required>
                        <el-select v-model="form2.cat_two" placeholder="Select" size="large" style="width: 240px"
                            :popper-append-to-body="false" @change="Vselect2">
                            <el-option v-for=" item  in  form2.product_list2 " :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="原材料三级分类" label-width="130px" prop="cat_three" required>
                        <el-select v-model="form2.cat_three" placeholder="Select" size="large" style="width: 240px"
                            :popper-append-to-body="false">
                            <el-option v-for=" item  in  form2.product_list3 " :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item> -->

          <!-- <el-form-item label="所属二级行业" label-width="130px">
                        <el-select v-model="form2.industry2" placeholder="Select" size="large" style="width: 700px"
                            multiple :popper-append-to-body="false">
                            <el-option v-for=" item  in  form2.product_list5 " :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item> -->

          <el-form-item label="视频描述" label-width="145px" prop="v_desc">
            <el-input v-model="form2.v_desc" autocomplete="off" type="textarea" required maxlength="50" show-word-limit :rows="5" style="width: 700px" />
          </el-form-item>
        </el-form>
        <div style="padding-bottom: 100px">
          <el-button type="primary" style="width: 200px; margin-top: 10px; margin-left: 38%" @click="sentProduction2('ruleFormRef2')" v-if="!videoShow" :loading="loading2">发布视频</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="资讯投稿" name="second" style="background-color: white; width: 76%">
        <el-form :model="form" ref="ruleFormRef" :rules="rules" style="width: 60%; position: relative">
          <div>
            <input
              type="text"
              style="
                position: absolute;
                top: 74px;
                height: 30px;
                border: 0px solid red;
                z-index: 1;
                border-bottom: 1px solid rgb(232, 232, 232);
                padding: 8px 0px 18px 0px;
                width: 166%;
                margin-left: 0%;
                font-size: 23px;
                font-weight: 700;
                color: #222;
                text-align: center;
              "
              placeholder="请输入资讯文章标题（2~30个字）"
              id="myInput"
              v-model="form.title"
              maxlength="30"
            />
          </div>

          <el-form-item label="" label-width="40px" prop="content">
            <vueQuill
              serviceUrl="https://www.zhnl.com/zhnl/uploadConFiles"
              @getEditorData="getEditorData"
              @beforeAvatarUpload="beforeAvatarUpload"
              class="ql-editor"
              style="width: 165%; padding-top: 0px; height: auto"
            />
            <!-- <vueQuill2></vueQuill2> -->
            <!-- <vueQuillCopy :model-value="content" @input="editorChange" style="width: 165%; padding-top: 0px; height: auto" /> -->
          </el-form-item>
          <el-form-item label="上传资讯封面" label-width="130px" style="margin-top: 40px">
            <el-upload
              action="https://www.zhnl.com/zhnl/uploadConFiles"
              list-type="picture-card"
              :limit="1"
              :file-list="fileList"
              :headers="headerObj"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div style="color: gray; font-size: 12px; margin-left: 26px">上传220px*124px尺寸的图片</div>
            <el-dialog :visible.sync="dialogVisible2">
              <img width="100%" :src="dialogImageUrl2" alt="" />
            </el-dialog>
          </el-form-item>
          <!-- <el-form-item label="文章标题" label-width="130px" prop="title">
                        <el-input v-model="form.title" autocomplete="off" required style="width: 440px;" maxlength="20"
                            show-word-limit />
                    </el-form-item> -->

          <el-form-item label="原材料标签" label-width="130px" required prop="cat_one">
            <el-select v-model="form.cat_one" placeholder="Select" size="large" style="width: 798px" :popper-append-to-body="false" multiple>
              <el-option v-for="item in form.product_list1" :key="item.gId" :label="item.label" :value="item.gId" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="二级分类" label-width="130px" required prop="cat_two">
                        <el-select v-model="form.cat_two" placeholder="Select" size="large" style="width: 240px"
                            :popper-append-to-body="false" @change="select2">
                            <el-option v-for="item in form.product_list2" :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="三级分类" label-width="130px" required prop="cat_three">
                        <el-select v-model="form.cat_three" placeholder="Select" size="large" style="width: 240px"
                            :popper-append-to-body="false">
                            <el-option v-for="item in form.product_list3" :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item> -->
          <el-form-item label="行业标签" label-width="130px" required prop="industry">
            <el-select v-model="form.industry" placeholder="Select" size="large" style="width: 798px" multiple :popper-append-to-body="false" @remove-tag="removeTag">
              <el-option v-for="item in form.product_list4" :key="item.gId" :label="item.label" :value="item.gId" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属二级行业" label-width="130px" prop="industry2">
                        <el-select v-model="form.industry2" placeholder="Select" size="large" style="width: 798px"
                            multiple :popper-append-to-body="false">
                            <el-option v-for="item in form.product_list5" :key="item.gId" :label="item.label"
                                :value="item.gId" />
                        </el-select>
                    </el-form-item> -->

          <el-button type="primary" style="width: 200px; margin-left: 64%; margin-bottom: 300px; margin-top: 30px" :loading="loading" @click="sentProduction('ruleFormRef')">立即投稿</el-button>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getBstGoodsGategory1, getGoodsIndustryAdvisory, getGoodsIndustryByPid, addGoodsConsult, addVideo, getVideoAudit, getUserProtocol } from "@/api/user.js";
import vueQuill from "@/components/vueQuill.vue";
import vueQuill2 from "@/components/vueQuill2.vue";
import vueQuillCopy from "@/components/vueQuillCopy.vue";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  data() {
    return {
      content: "",
      loading2: false,
      loading: false,
      infoList: {},
      infoList2: {},
      useVisible: false,
      useVisible2: false,
      preShow: false,
      showVideo: "",
      content: "",
      editorOption2: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
            [{ table: "TD" }, { "table-insert-row": "TIR" }, { "table-insert-column": "TIC" }, { "table-delete-row": "TDR" }, { "table-delete-column": "TDC" }]
          ]
        }
      },
      // imageLoading: false,
      // apiurl: this.$httpServer.fdfsupload,
      ///
      cateryShow: false,
      hangShow: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisibleVideo: false,
      dialogImageUrl: "",
      dialogImageUrl2: "",
      videoShow: true,
      headerObj: {
        token: window.localStorage.getItem("token")
      },
      classifyList: [],
      classifyList2: [],
      activeName: "first",
      fileList: [],
      // fileList: [{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }],
      form: {
        videoList: [],
        title: "",
        userId: "",
        content: "",
        size: 6,
        page: 1,
        total: 0,
        v_label: "",
        v_video_url: "",
        img_url: false,
        goods_id: "",
        cat_one: [],
        cat_two: "",
        cat_three: "",
        industry: [],
        industry2: [],
        productList: [],
        product_list1: [],
        product_list2: [],
        product_list3: [],
        product_list4: [],
        product_list5: [],
        detailContent: ""
      },
      form2: {
        isOriginal: 0,
        reprintUrl: "",
        total: 0,
        page: 1,
        size: 6,
        userId: "",
        v_title: "",
        v_desc: "",
        v_label: "",
        v_video_url: "",
        v_img_url: false,
        goods_id: "",
        cat_one: [],
        cat_two: "",
        cat_three: "",
        industry2: "",
        industry: [],
        industry3: [],
        industry4: [],
        videoList: [],
        product_list1: [],
        product_list2: [],
        product_list3: [],
        product_list4: [],
        product_list5: [],
        fileList: []
      },
      rules: {
        title: [
          {
            required: true,
            message: "请填写文章标题",
            trigger: "change"
          }
        ],
        content: [
          {
            required: true,
            message: "请填写文章内容",
            trigger: "change"
          }
        ],
        v_label: [
          {
            required: true,
            message: "请填写视频标签",
            trigger: "change"
          }
        ],
        goods_id: [
          {
            required: true,
            message: "请选择产品",
            trigger: "change"
          }
        ],
        industry: [
          {
            required: true,
            message: "请选择一级行业分类",
            trigger: "change"
          }
        ],

        cat_one: [
          {
            required: true,
            message: "请选择产品一级分类",
            trigger: "change"
          }
        ],
        cat_two: [
          {
            required: true,
            message: "请选择产品二级分类",
            trigger: "change"
          }
        ],
        cat_three: [
          {
            required: true,
            message: "请选择产品三级分类",
            trigger: "change"
          }
        ]
      },
      rules2: {
        v_title: [
          {
            required: true,
            message: "请填写标题名称",
            trigger: "change"
          }
        ],
        reprintUrl: [
          {
            // required: true,
            message: "请填写转载地址",
            trigger: "change"
          }
        ],

        v_desc: [
          {
            required: true,
            message: "请填写视频描述",
            trigger: "change"
          }
        ],
        v_label: [
          {
            required: true,
            message: "请填写视频标签",
            trigger: "change"
          }
        ],

        isOriginal: [
          {
            required: true,
            message: "请选择视频来源",
            trigger: "change"
          }
        ],
        goods_id: [
          {
            required: true,
            message: "请选择产品",
            trigger: "change"
          }
        ],
        industry: [
          {
            required: true,
            message: "请选择一级行业分类",
            trigger: "change"
          }
        ],

        // industry2: [
        //     {

        //         required: true,
        //         message: '请选择二级行业分类',
        //         trigger: 'change',
        //     },
        // ],
        cat_one: [
          {
            required: true,
            message: "请选择原材料分类",
            trigger: "change"
          }
        ],

        cat_two: [
          {
            required: true,
            message: "请选择产品二级分类",
            trigger: "change"
          }
        ],
        cat_three: [
          {
            required: true,
            message: "请选择产品三级分类",
            trigger: "change"
          }
        ]
      }
    };
  },

  components: {
    quillEditor,
    vueQuill,
    vueQuill2,
    vueQuillCopy
  },
  methods: {
    editorChange(data) {
      this.content = data;
    },
    show1() {
      this.useVisible = true;
    },
    show2() {
      this.useVisible2 = true;
    },
    Preview() {
      this.dialogVisibleVideo = true;
    },
    beforeAvatarUpload2(file) {
      const isLt500K = file.size / 1024 / 1024 < 1;
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 1000KB!");
        return false;
      }
    },
    beforeAvatarUpload(file) {
      console.log(file, "file---------");
    },
    getEditorData(data) {
      console.log(data, "data========");
      this.form.content = data;
    },
    onChange() {
      this.$emit("inputContent", this.content);
    },
    newEditorbeforeupload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if (isJPG && isLt2M) this.imageLoading = true;
      return isJPG && isLt2M;
    },
    //上传图片回调
    newEditorSuccess(response, file, fileList) {
      if (response.code == "0") {
        this.addImgRange = this.$refs.newEditor.quill.getSelection();
        let length = this.$refs.newEditor.quill.selection.savedRange.index;
        this.$refs.newEditor.quill.insertEmbed(length, "image", response.data);
        this.$refs.newEditor.quill.setSelection(length + 1);
      }
      this.imageLoading = false;
    },

    editorOption(data) {
      console.log(data, "----da");
    },
    handleMouseOver1() {
      this.cateryShow = true;
    },
    handleMouseOver2() {
      this.cateryShow = false;
    },
    handleMouseOver3() {
      this.hangShow = true;
    },
    handleMouseOver4() {
      this.hangShow = false;
    },

    videoShowChange() {
      this.videoShow = false;
    },
    Vselect6() {
      getGoodsIndustryByPid({ parent_id: this.form2.industry }).then((res) => {
        this.form2.product_list5 = res.data.data;
      });
    },
    beforeUpload2(file) {
      console.log(file, "123");
      console.log(file.type.includes("mp4"), "cccccc");
      if (file.name.includes("mp4") || file.name.includes("mov")) {
        // return ture
      } else {
        this.$message({
          type: "warning",
          message: "只能上传mp4文件和mov文件"
        });
        return false;
      }
    },
    Vselect1(e) {
      this.form2.cat_three = "";
      this.form2.cat_two = "";

      let newArray = this.form2.product_list1.filter((item, index) => {
        return item.gId == e;
      });
      this.form2.product_list2 = newArray[0].children;
    },
    Vselect2(e) {
      this.form2.cat_three = "";
      let newArray = this.form2.product_list2.filter((item, index) => {
        return item.gId == e;
      });
      // let newArray2 = JSON.parse(JSON.stringify(newArray))
      this.form2.product_list3 = newArray[0].children;
    },
    onProgressTest(event, file, fileList) {
      let index = 0;
      // 只有fileList 跟file 里面的uid是唯一的互相对应的
      fileList.forEach((v, i) => {
        if (v.uid === file.uid) {
          index = i;
        }
      });
      // 获取动态的进度
      let percent = parseInt(event.percent);

      setTimeout(() => {
        console.log("123123");
        if (percent >= 99) {
          // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
          let dom = document.getElementsByClassName("el-upload-list__item")[index];
          let progressText = dom.getElementsByClassName("el-progress__text");
          if (progressText && progressText.length) {
            progressText[0].style.opacity = 0;
            progressText[0].innerText = "99%";
            progressText[0].style.opacity = 1;
          }
        }
      });
    },
    handleExceed() {
      this.$message({
        type: "warning",
        message: "只能上传一条视频"
      });
    },
    removeTag() {
      this.form.industry2 = [];
      this.form2.industry2 = [];
    },
    beforeUpload(file) {
      console.log(file, "file-----===");
      // const regex = /[^a-zA-Z0-9\s.,-]/;

      // if (regex.test(file.name)) {
      //     return this.$message({
      //         type: 'warning',
      //         message: '图片名称不能包含特殊字符'
      //     })
      // }
      if (this.fileList.length > 0) {
        this.$message({
          type: "warning",
          message: "只能上传一张图片"
        });
      }
      if (file.type.includes("jpeg") || file.type.includes("png")) {
        // return ture
      } else {
        this.$message({
          type: "warning",
          message: "只能上传png、jpg文件"
        });
        return false;
      }
    },
    handleSuccess(file, file2, fileList) {
      if (file.code == 4017) {
        setTimeout(() => {
          window.location = "/login";
        }, 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
      } else {
        this.fileList = fileList;
        this.form.img_url = file.imgUrl;
      }
    },
    handleSuccess2(file) {
      console.log(file, "file---");
      this.preShow = true;
      if (file.code == 4017) {
        this.$message({
          type: "error",
          message: "登录过期，请重新登录"
        });
        setTimeout(() => {
          window.location = "/";
        }, 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
      } else {
        this.video_url = file.data.video_url;
        this.fileList.push(file);
        this.showVideo = file.data.video_url;
        this.form2.v_img_url = file.data.img_url;
        this.form2.v_video_url = file.data.video_url;
      }
    },
    handleSuccess3(file) {
      console.log(file, "file----");
      if (file.code == 4017) {
        this.$message({
          type: "error",
          message: "登录过期，请重新登录"
        });
        setTimeout(() => {
          window.location = "/";
        }, 2000);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
      } else {
        this.form2.v_img_url = file.imgUrl;
      }
    },

    handleRemove() {},
    sentProduction(ruleFormRef) {
      console.log(this.fileList, "this.fileList");
      if (this.fileList.length < 1) {
        return this.$message({
          type: "warning",
          message: "请上传资讯封面"
        });
      }
      if (!this.form.title) {
        return this.$message({
          type: "warning",
          message: "请填写标题"
        });
      }
      this.$refs[ruleFormRef].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            title: this.form.title,
            content: this.form.content,
            img_url: this.form.img_url,
            cat_one: this.form.cat_one,
            cat_two: this.form.cat_two,
            cat_three: this.form.cat_three,
            industry: this.form.industry,
            industry2: this.form.industry2
          };
          addGoodsConsult(params).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "发布成功"
              });
              this.loading = false;
              this.form.title = "";
              this.form.content = "";
              this.form.cat_one = [];
              this.form.cat_two = "";
              this.form.cat_three = "";
              this.form.img_url = "";
              this.fileList = [];
              this.$router.push({
                path: "/buyerCenter/pushNews"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    sentProduction2(ruleFormRef) {
      this.$refs[ruleFormRef].validate((valid) => {
        if (valid) {
          this.loading2 = true;
          let params = {
            title: this.form2.v_title,
            v_desc: this.form2.v_desc,
            v_label: this.form2.v_label,
            video_url: this.form2.v_video_url,
            img_url: this.form2.v_img_url,
            cat_one: this.form2.cat_one,
            cat_two: this.form2.cat_two,
            cat_three: this.form2.cat_three,
            industry: this.form2.industry,
            industry2: this.form2.industry2,
            task_id: this.taskId,
            is_original: this.form2.isOriginal,
            reprint_url: this.form2.reprintUrl
          };
          if (this.fileList.length > 0) {
            addVideo(params).then((res) => {
              console.log(res, "res----");
              if (res.data.code == 200) {
                getVideoAudit({ video_url: this.video_url }).then((res) => {
                  // this.taskId = res.data.data.taskId
                });
                this.loading2 = false;
                this.fileList = [];
                this.$message({
                  type: "success",
                  message: "上传成功，待审核"
                });
                this.$router.push({
                  path: "/buyerCenter/publishVideo"
                });
                this.form2.v_title = "";
                this.form2.v_desc = "";
                this.form2.v_label = "";
                this.form2.v_video_url = "";
                this.form2.v_img_url = "";
                this.form2.goods_id = "";
                this.form2.cat_one = [];
                this.form2.cat_two = "";
                this.form2.cat_three = "";
                this.taskId = "";
                this.form2.industry = "";
                this.form2.industry2 = "";
                this.form2.isOriginal = 0;
                this.form2.reprintUrl = "";
                this.$refs.ruleFormRef.clearValidate();
                // formEl.clearValidate()
              } else {
                this.loading2 = false;
                this.$message({
                  type: "error",
                  message: res.data.msg
                });
              }
            });
          } else {
            this.loading2 = false;

            this.$message({
              type: "warning",
              message: "视频未上传成功"
            });
            console.log("error submit!!");
            return false;
          }
        }
      });
    },
    select1(e) {
      this.form.cat_three = "";
      this.form.cat_two = "";

      let newArray = this.form.product_list1.filter((item, index) => {
        return item.gId == e;
      });

      this.form.product_list2 = newArray[0].children;
    },
    select6(e) {
      getGoodsIndustryByPid({ parent_id: this.form.industry }).then((res) => {
        this.form.industry2 = "";
        if (res.data.data == undefined) {
          this.form.industry2 = "";
          this.form.product_list5 = res.data.data;
        } else {
          this.form.product_list5 = res.data.data;
        }
      });
    },
    select2(e) {
      console.log(e, "e...");
      this.form.cat_three = "";
      let newArray = this.form.product_list2.filter((item, index) => {
        return item.gId == e;
      });
      console.log(newArray, "newArray");
      this.form.product_list3 = newArray[0].children;
    }
  },
  created() {},
  mounted() {
    getUserProtocol({ mark_id: 2 }).then((res) => {
      this.infoList = res.data.data;
    });
    getUserProtocol({ mark_id: 3 }).then((res) => {
      this.infoList2 = res.data.data;
    });
    if (this.$route.query.video == 1) {
      this.videoShow = false;
    } else if (this.$route.query.video == 2) {
      this.activeName = "second";
    }
    getBstGoodsGategory1().then((res) => {
      this.form.product_list1 = res.data.data;
      this.form2.product_list1 = res.data.data;
    });
    getGoodsIndustryAdvisory().then((res) => {
      this.form.product_list4 = res.data.data;
      this.classifyList2 = res.data.data;
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav-scroll {
  background-color: white;
  padding: 4px;
  display: none;
}

::v-deep .el-upload .el-upload--picture-card {
  width: 23% !important;
  margin-left: 20px;
  height: 50%;
  padding: 10px;
}

.tit-black {
  color: #333333;
  font-weight: 290;
  font-size: 12px;
}

.tit-blue {
  color: #227bff;
  font-weight: 290;
  font-size: 12px;
  cursor: pointer;
}

#myInput:focus {
  outline: none;
  /* 移除焦点时的默认边框 */
  border: 1px solid transparent;
  /* 可选：如果你还想要确保边框占位但不可见 */
  /* 或者直接指定一个你想要的边框颜色或样式，但这里为了示例设置为透明 */
}
// ::v-deep .el-form-item__content {
//   margin-left: 0px !important;
// }
</style>
