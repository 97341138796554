<template>
    <!-- @click="handleScrollTop" -->
    <div class="content">
        <searchBar />
        <!-- <div class="line"></div> -->
        <div class="classifyBox">
            <classifyBar />

        </div>

        <div class="location">
            <span>当前位置：</span>
            <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/issueProduct' }">{{ submitname }}</el-breadcrumb-item>
                </el-breadcrumb></div>
        </div>

        <div class="product_pic">
            <div class="pic_title">
                商品主图
            </div>
            <div class="tips">
                <span style="color: #FF0C0C">* </span>
                建议上传 800*800 以上的实拍大图，包含商品整体款式和商品细节。
            </div>

            <div class="upLoad_box">
                <div class="one" @click="abc">
                    <div class="master_map">
                        商品主图
                    </div>
                    <el-upload action="https://www.zhnl.com/zhnl/uploadConFiles" list-type="picture-card"
                        :headers="headerObj"
                        :data="{ 'oldFile': this.imgList[0] ? this.imgList[0] : null, goods_id: this.goods_id ? this.goods_id : 0 }"
                        :on-preview="handlePictureCardPreview1" :class="{ hide_box: upload_btn1 }" name="file"
                        :before-upload="handleSuccess1" :on-success="handleAvatarSuccess1" :show-file-list="true"
                        :file-list="fileList1" :on-remove="handleRemove1">
                        <i class="el-icon-picture-outline" style="font-size: 62px;"></i>
                        <span style="font-size: 14px; color: #9E9E9E; line-height: 20px; margin-top: 6px;">上传图片</span>
                    </el-upload>

                    <el-dialog :visible="dialogVisible1">
                        <img width="100%" :src="dialogImageUrl1" alt="">
                    </el-dialog>
                </div>

                <div class="one">
                    <el-upload action="https://www.zhnl.com/zhnl/uploadConFiles" list-type="picture-card"
                        :headers="headerObj"
                        :data="{ 'oldFile': this.imgList[1] ? this.imgList[1] : null, goods_id: this.goods_id ? this.goods_id : 0 }"
                        :on-preview="handlePictureCardPreview2" :class="{ hide_box: upload_btn2 }"
                        :before-upload="handleSuccess2" :on-success="handleAvatarSuccess2" :show-file-list="true"
                        :file-list="fileList2" name="file" :on-remove="handleRemove2">
                        <i class="el-icon-picture-outline" style="font-size: 62px;"></i>
                        <span style="font-size: 14px; color: #9E9E9E; line-height: 20px; margin-top: 6px;">上传图片</span>
                    </el-upload>

                    <el-dialog :visible="dialogVisible2">
                        <img width="100%" :src="dialogImageUrl2" alt="">
                    </el-dialog>
                </div>

                <div class="one">
                    <el-upload action="https://www.zhnl.com/zhnl/uploadConFiles" list-type="picture-card"
                        :headers="headerObj"
                        :data="{ 'oldFile': this.imgList[2] ? this.imgList[2] : null, goods_id: this.goods_id ? this.goods_id : 0 }"
                        :on-preview="handlePictureCardPreview3" :class="{ hide_box: upload_btn3 }"
                        :before-upload="handleSuccess3" :on-success="handleAvatarSuccess3" :show-file-list="true"
                        :file-list="fileList3" name="file" :on-remove="handleRemove3">
                        <i class="el-icon-picture-outline" style="font-size: 62px;"></i>
                        <span style="font-size: 14px; color: #9E9E9E; line-height: 20px; margin-top: 6px;">上传图片</span>
                    </el-upload>

                    <el-dialog :visible="dialogVisible3">
                        <img width="100%" :src="dialogImageUrl3" alt="">
                    </el-dialog>
                </div>

                <div class="one">
                    <el-upload action="https://www.zhnl.com/zhnl/uploadConFiles" list-type="picture-card"
                        :headers="headerObj"
                        :data="{ 'oldFile': this.imgList[3] ? this.imgList[3] : null, goods_id: this.goods_id ? this.goods_id : 0 }"
                        :on-preview="handlePictureCardPreview4" :class="{ hide_box: upload_btn4 }"
                        :before-upload="handleSuccess4" :on-success="handleAvatarSuccess4" :show-file-list="true"
                        :file-list="fileList4" name="file" :on-remove="handleRemove4">
                        <i class="el-icon-picture-outline" style="font-size: 62px;"></i>
                        <span style="font-size: 14px; color: #9E9E9E; line-height: 20px; margin-top: 6px;">上传图片</span>
                    </el-upload>

                    <el-dialog :visible="dialogVisible4">
                        <img width="100%" :src="dialogImageUrl4" alt="">
                    </el-dialog>
                </div>
            </div>
        </div>

        <div class="contaion">
            <div class="pic_title">
                基本信息
            </div>

            <div class="form_box">
                <el-form ref="form" class="te" :model="form" :rules="rules" label-width="90px" label-position="">
                    <div class="line2" style="width: 369px;color: #000;">
                        <el-form-item label="商品名称：" prop="name">
                            <el-input v-model="form.name" height="24px" disabled></el-input>

                        </el-form-item>
                    </div>

                    <div class="title">
                        商品属性：
                    </div>
                    <div class="pinpai">
                        <div class="line2" style="display: flex; height: 40px;">
                            <el-form-item :label="`${title_list[0]}:`" prop="parameter_text0">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[0]">
                                        <input type="text" v-model="title_list0" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[0] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(0)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[0] }}:</span>
                                    </el-popover>
                                </template>
                                <el-input v-model="form.parameter_text0" height="30px"></el-input>
                            </el-form-item>

                            <el-form-item :label="`${title_list[1]}:`" prop="parameter_text1">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[1]">
                                        <input type="text" v-model="title_list1" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[1] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(1)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[1] }}:</span>
                                    </el-popover>
                                </template>
                                <el-input v-model="form.parameter_text1" height="24px"></el-input>
                            </el-form-item>
                        </div>

                        <div class="line2" style="display: flex; height: 40px;">
                            <el-form-item :label="`${title_list[2]}:`" prop="parameter_text2">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[2]">
                                        <input type="text" v-model="title_list2" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[2] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(2)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[2] }}:</span>
                                    </el-popover>

                                </template>
                                <el-input v-model="form.parameter_text2" height="24px"></el-input>
                            </el-form-item>

                            <el-form-item :label="`${title_list[3]}:`" prop="parameter_text3">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[3]">
                                        <input type="text" v-model="title_list3" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[3] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(3)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[3] }}:</span>
                                    </el-popover>

                                </template>
                                <el-input v-model="form.parameter_text3" height="24px"></el-input>
                            </el-form-item>
                        </div>

                        <div class="line2" style="display: flex; height: 40px;">
                            <el-form-item :label="`${title_list[4]}:`" prop="parameter_text4">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[4]">
                                        <input type="text" v-model="title_list4" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[4] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(4)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[4] }}:</span>
                                    </el-popover>

                                </template>
                                <el-input v-model="form.parameter_text4" height="24px"></el-input>
                            </el-form-item>

                            <el-form-item :label="`${title_list[5]}:`" prop="parameter_text5">
                                <template #label>
                                    <el-popover placement="top" width="160" v-model="visible[5]">
                                        <input type="text" v-model="title_list5" style="width: 100%;">
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"
                                                @click="visible[5] = false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="hddl(5)">确定</el-button>
                                        </div>
                                        <span slot="reference">{{ title_list[5] }}:</span>
                                    </el-popover>


                                </template>
                                <el-input v-model="form.parameter_text5" height="24px"></el-input>
                            </el-form-item>
                        </div>

                    </div>


                    <div class="line2 select_line" style="display: flex;">
                        <el-form-item label="商品品名：" prop="cat_id">
                            <el-select v-model="form.cat_id" placeholder="分类一" value-key="id" label-width="90px"
                                @change="select1">
                                <el-option :label="item.gName" :value="item.gId" :key="item.gId"
                                    v-for="(item, index) in product_cat_list"></el-option>

                            </el-select>
                        </el-form-item>

                        <el-form-item label="" prop="cat_id2">
                            <el-select v-model="form.cat_id2" placeholder="分类二" @change="select2">
                                <el-option :label="item.gName" :value="item.gId"
                                    v-for="(item, index) in product_cat_list2"></el-option>

                            </el-select>
                        </el-form-item>

                        <el-form-item label="" prop="cat_id3">
                            <el-select v-model="form.cat_id3" value-key="id" placeholder="分类三" @change="select3">
                                <el-option :label="item.gName" :value="item.gId" :key="item.gId"
                                    v-for="(item, index) in product_cat_list3"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>


                    <div class="line2">
                        <el-form-item label="商品简介：">
                            <el-input resize="none" type="textarea" :rows="3" v-model="form.goods_remark"
                                height="24px"></el-input>
                        </el-form-item>
                    </div>

                    <div class="line2" style="margin-top: 45px;width: 700px;">
                        <el-form-item label="商品售价：" prop="shop_price">
                            <div style="display: flex;align-items: center;width: 500px;">
                                <div style="width: 280px;"><el-input type="number" v-model="form.shop_price"
                                        height="24px"></el-input></div>
                                <div class="prick">每吨/千克售价</div>
                            </div>
                        </el-form-item>
                    </div>

                    <div class="line2 line3" style="display: flex;">
                        <el-form-item label="商品数量：" prop="store_count">
                            <el-input type="number" v-model="form.store_count" height="24px"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-select v-model="spec_name" placeholder="" @change="select5">
                                <el-option :label="item.specName" :value="item.gsId"
                                    v-for="(item, index) in unit"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="line2 line5">
                        <el-form-item label="是否包邮：" prop="logistics_type_id">
                            <el-select v-model="form.logistics_type_id" :placeholder="logistics_type_name"
                                @change="select4">
                                <el-option :label="item.name" :value="item.id"
                                    v-for="(item, index) in logistics"></el-option>

                            </el-select>
                        </el-form-item>
                    </div>


                    <!-- <div class="line2">
                        <el-form-item label="所在地：">
                            <el-input v-model="form.location_name" height="24px"></el-input>
                        </el-form-item>
                    </div> -->

                </el-form>
                <div class="title" style="margin-top: 10px; margin-bottom: 10px;">
                    商品描述：
                </div>
            </div>
            <div style="width: 1200px;height: 500px; position: relative;">
                <!-- <el-input style="width: 1000px;height: 400px !important; position: absolute;left: 100px;" type="textarea"
                    :rows="20" autosize size="medium" resize="none" v-model="content"
                    :autosize="{ minRows: 20, maxRows: 20 }">
                </el-input> -->
                <!-- <quill-editor v-model="content" ref="myQuillEditor" style="height: 500px;" :options="editorOption">
      </quill-editor> -->
                <div style="position: absolute;left: 50%;transform: translateX(-50%);">
                    <quill-editor v-model="content" ref="myQuillEditor" style="height: 500px;"
                        @click.native="handleEditAblequestion" :options="editorOption" @paste="hd">
                    </quill-editor>
                </div>
                <!-- <quill-editor ref="text" v-model="content" class="myQuillEditor" :options="editorOption" /> -->
            </div>
            <el-button class="button" type="primary" @click="submit(rules)">{{ submitname }}</el-button>
        </div>
    </div>
</div></template>

<script>
import searchBar from "../components/searchBar.vue";
import classifyBar from "../components/classifyBar.vue";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import getParams from '../utils/getParams'

import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { addGoods, getBstGoodsGategory, getAllLogisticsType, getAllSpec, uploadFiles } from '@/api/user'
Quill.register('modules/ImageExtend', ImageExtend)

export default {
    data() {
        return {
            // goods_id:'',
            imgList: [],
            newArray: {},
            headerObj: {
                token: window.localStorage.getItem('token'),
            },
            submitname: '发布产品',
            qillInit: true,
            fileList1: [],
            fileList2: [],
            fileList3: [],
            fileList4: [],
            userId: '',
            importData: {},
            upload_btn1: false,
            priceValue1: "",
            dialogImageUrl1: '',
            dialogVisible1: false,
            upload_btn2: false,
            priceValue2: "",
            dialogImageUrl2: '',
            dialogVisible2: false,
            upload_btn3: false,
            priceValue3: "",
            dialogImageUrl3: '',
            dialogVisible3: false,
            upload_btn4: false,
            priceValue4: "",
            dialogImageUrl4: '',
            dialogVisible4: false,
            goods_id: '',
            user_id: '',
            re: 2,
            logistics: '',
            cat_name: '',
            cat_name2: '',
            cat_name3: '',
            spec_name: 1,
            logistics_type_name: '不限',
            unit: '',
            form: {
                name: '',
                parameter_title: [],
                parameter_text0: '',
                parameter_text1: '',
                parameter_text2: '',
                parameter_text3: '',
                parameter_text4: '',
                parameter_text5: '',
                cat_id: '',
                cat_id2: '',
                cat_id3: '',
                goods_remark: '',
                shop_price: '',
                store_count: '',
                logistics_type_id: 2,
                spec_id: 1,
                location_name: ''
            },

            rules: {
                parameter_text0: [
                    { required: true, message: '请输入品牌', trigger: 'blur' },
                ],
                parameter_text1: [
                    { required: true, message: '请输入规格', trigger: 'blur' },
                ],


                parameter_text4: [
                    { required: true, message: '请输入产地', trigger: 'blur' },
                ],

                cat_id: [
                    { required: true, message: '请输入分类一', trigger: 'blur' },
                ],
                cat_id2: [
                    { required: true, message: '请输入分类二', trigger: 'blur' },
                ],
                cat_id3: [
                    { required: true, message: '请输入分类三', trigger: 'blur' },
                ],
                shop_price: [
                    { required: true, message: '请输入售价', trigger: 'blur' },
                ],
                store_count: [
                    { required: true, message: '请输入数量', trigger: 'blur' },
                ],

            },

            content: '',
            editorOption: {
                modules: {
                    ImageExtend: {  // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入 
                        name: 'file',  // 图片参数名
                        size: 3,  // 可选参数 图片大小，单位为M，1M = 1024kb
                        action: 'https://zhnl.ydlweb.com/mini/ApiConnect/upload',  // 服务器地址, 如果action为空，则采用base64插入图片
                        // response 为一个函数用来获取服务器返回的具体图片地址
                        // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
                        // 则 return res.data.url
                        response: (res) => {
                            console.log('6', res)
                            return res.result.showUrl
                        },
                        headers: (xhr) => {
                            // xhr.setRequestHeader('myHeader','myValue')
                        },  // 可选参数 设置请求头部
                        sizeError: () => { },  // 图片超过大小的回调
                        start: () => { },  // 可选参数 自定义开始上传触发事件
                        end: () => { },  // 可选参数 自定义上传结束触发的事件，无论成功或者失败
                        error: () => { },  // 可选参数 上传失败触发的事件
                        success: () => { },  // 可选参数  上传成功触发的事件
                        change: (xhr, formData) => {

                            // xhr.setRequestHeader('myHeader','myValue')
                            formData.append('user_id', this.userId)
                        } // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
                    },
                    toolbar: {  // 如果不上传图片到服务器，此处不必配置
                        container: container,  // container为工具栏，此次引入了全部工具栏，也可自行配置
                        handlers: {
                            'image': function () {  // 劫持原来的图片点击按钮事件
                                QuillWatch.emit(this.quill.id)
                            }
                        }
                    }
                }
            },


            product_cat_list: '',
            product_cat_list2: '',
            product_cat_list3: '',
            GoodsInfo: {},
            product_name_list: [
                {
                    name: '测试1',
                    id: 1
                },
                {
                    name: '测试2',
                    id: 2
                },
                {
                    name: '测试3',
                    id: 3
                },
            ],
            title_list: ['品牌', '规格', '等级', '是否进口', '产地', '是否危险'],
            visible: [false, false, false, false, false],
            title_list5: '',
            title_list4: '',
            title_list3: '',
            title_list2: '',
            title_list1: '',
            title_list0: ''

        };
    },
    async created() {
        getBstGoodsGategory().then((res) => {
            console.log(res, 'respppppp');
            this.product_cat_list = res.data.data
        })
        try {
            let goods_id = this.$route.query.id
            let query = this.$route.query
            if (goods_id) {
                this.submitname = '提交编辑'
                this.goods_id = goods_id
                //         let res = await this.$http('/mini/ApiConnect/GoodsInfo', { goods_id,user_id:localStorage.getItem('user_id') })
                //         if (res.status == 1) {
                //             let arr = this.title_list
                //             let w = res.result
                //             w.goods_service_sp.forEach((item, i) => {
                //                 if (arr.indexOf(item.title) !== -1) {
                //                     this.form[`parameter_text${arr.indexOf(item.title)}`] = item.content
                //                 }
                //             })
                //             this.goods_id = goods_id
                //             this.spec_name = w.goods_spec_name
                //             this.cat_name = w.cat_id
                //             this.cat_name2 = w.cat_id_2
                //             this.cat_name3 = query.cat_id_3

                //             this.form.name = w.goods_name
                //             this.form.cat_id = w.cat_id
                //             this.form.cat_id2 = w.cat_id_2
                //             this.form.cat_id3 = query.cat_id_3
                //             this.form.goods_remark = w.goods_remark
                //             this.form.shop_price = w.shop_price
                //             this.form.store_count = query.store_count
                //             this.form.logistics_type_id = query.logistics_type_id
                //             this.form.spec_id = query.spec_id

                //             this.goods_content = w.goods_content
                //             this.logistics_type_name = query.logistics_type_name
                //             this.spec_name = query.spec_name

                //             w.goods_images.forEach((item, i) => {
                //                 this[`dialogImageUrl${i + 1}`] = item
                //                 this[`fileList${i + 1}`] = [{ url: item }]
                //                 this[`upload_btn${i + 1}`] = true
                //             })

                // }
            }
        } catch (error) {

        }


    },

    components: {
        searchBar,
        classifyBar,
        quillEditor,
    },

    methods: {
        abc() {
            console.log('11111111111111111111');
        },
        hd(e) {
            console.log(111);
        },
        hddl(i) {
            this.title_list[i] = this[`title_list${i}`]
            this.visible[i] = false

        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.UserProductSave()
                } else {
                    this.UserProductSave()

                    // this.$message.error('请填写必填的选项');
                    // return false;
                }
            });
        },
        handleRemove1(file, fileList) {
            console.log(file, fileList);
            this.upload_btn1 = false;
            this.dialogImageUrl1 = ''

        },
        handleAvatarSuccess1(res, file) {
            console.log(res, '...');
            this.dialogImageUrl1 = res.url
        },
        handleAvatarSuccess2(res, file) {
            this.dialogImageUrl2 = res.url
        },
        handleAvatarSuccess3(res, file) {
            this.dialogImageUrl3 = res.url
        },
        handleAvatarSuccess4(res, file) {
            this.dialogImageUrl4 = res.url
        },
        handlePictureCardPreview1(file) {
            this.dialogImageUrl1 = file.url;
            this.dialogVisible1 = true;
        },
        handleSuccess1(response, file, fileList) {


            // console.log(response, 'file==========');
            // let formData = new FormData();
            // formData.append("file", response);
            // formData.append("oldFile", this.imgList[0] ? this.imgList[0] : null);
            // uploadFiles(formData).then((res) => {

            // })


            this.importData.oldFile = null
            // this.importData.module = 'goods'
            // 上传成功后，隐藏上传按钮
            this.upload_btn1 = true;


        },

        handleRemove2(file, fileList) {
            console.log(file, fileList);
            this.upload_btn2 = false;
            this.dialogImageUrl2 = ''


        },
        handlePictureCardPreview2(file) {
            this.dialogImageUrl2 = file.url;
            this.dialogVisible2 = true;
        },
        handleSuccess2(response, file, fileList) {
            // let formData = new FormData();
            // formData.append("file", response);
            // formData.append("oldFile", this.imgList[1] ? this.imgList[1] : null);
            // uploadFiles(formData).then((res) => {

            // })
            // this.importData.file = file
            // this.importData.module = 'goods'
            // 上传成功后，隐藏上传按钮
            this.upload_btn2 = true;
        },

        handleRemove3(file, fileList) {
            console.log(file, fileList);
            this.upload_btn3 = false;
            this.dialogImageUrl3 = ''

        },
        handlePictureCardPreview3(file) {
            this.dialogImageUrl3 = file.url;
            this.dialogVisible3 = true;
        },
        handleSuccess3(response, file, fileList) {
            // let formData = new FormData();
            // formData.append("file", response);
            // formData.append("oldFile", this.imgList[2] ? this.imgList[2] : null);
            // uploadFiles(formData).then((res) => {

            // })
            // this.importData.file = file
            // this.importData.module = 'goods'
            // 上传成功后，隐藏上传按钮
            this.upload_btn3 = true;
        },

        handleRemove4(file, fileList) {
            console.log(file, fileList);
            this.upload_btn4 = false;
            this.dialogImageUrl4 = ''


        },
        handlePictureCardPreview4(file) {
            this.dialogImageUrl4 = file.url;
            this.dialogVisible4 = true;
        },
        handleSuccess4(response, file, fileList) {
            // let formData = new FormData();
            // formData.append("file", response);
            // formData.append("oldFile", this.imgList[3] ? this.imgList[3] : null);
            // uploadFiles(formData).then((res) => {

            // })
            // this.importData.file = file
            // this.importData.module = 'goods'
            // 上传成功后，隐藏上传按钮
            this.upload_btn4 = true;
        },
        hdopt(val) {
            console.log(val);
            // this.form.name = e.name
        },
        select1(e, ifType = 1) {
            if (ifType == 1) {
                this.form.cat_id2 = ''
                this.form.cat_id3 = ''
            }

            console.log(e, 'select1');
            // this.getUserProductCat2(e.id)
            // this.form.cat_id = e.id
            let newArray = this.product_cat_list.filter((item, index) => {
                return item.gId == e
            })
            console.log(newArray, 'newArray1');
            this.product_cat_list2 = newArray[0].child

        },
        select2(e, ifType = 1) {
            console.log(e, 'select22222');
            if (ifType == 1) {
                this.form.cat_id3 = ''
            }

            // this.getUserProductCat3(e)
            // this.form.cat_id2 = e
            let newArray = this.product_cat_list2.filter((item, index) => {
                return item.gId == e
            })
            console.log(newArray, 'newArray2');
            this.product_cat_list3 = newArray[0].child

        },
        select3(e) {
            console.log(e, 'e', this.product_cat_list3);
            let a = this.product_cat_list3.filter((item, index) => {
                return item.gId == e
            })
            console.log(a, 'aaa');
            // this.form.cat_id3 = e
            this.form.name = a[0].gName

        },
        select4(e) {
            this.form.logistics_type_id = e
        },

        select5(e) {
            this.form.spec_id = e
        },

        //获取原材料分类
        async getUserProductCat() {
            try {
                let params = {
                    parent_id: ''
                }
                let res = await this.$http('/mini/ApiConnect/getUserProductCat', params)
                this.product_cat_list = res.result
            } catch (error) {

            }

        },
        //获取原材料分类二
        async getUserProductCat2(id) {
            try {
                let params = {
                    parent_id: id
                }
                let res = await this.$http('/mini/ApiConnect/getUserProductCat', params)
                this.product_cat_list2 = res.result
            } catch (error) {

            }


        },
        //获取原材料分类二
        async getUserProductCat3(id) {
            try {
                let params = {
                    parent_id: id
                }
                let res = await this.$http('/mini/ApiConnect/getUserProductCat', params)
                this.product_cat_list3 = res.result
            } catch (error) {

            }

        },

        //商品下拉选项
        async getGoodsOptionAll() {
            try {
                let userId = window.localStorage.getItem('user_id')
                let params = {
                    user_id: userId
                }
                let res = await this.$http('/mini/ApiConnect/getGoodsOptionAll', params)
                this.unit = res.result.unit.rows
                let ures = await this.$http('/mini/ApiConnect/getLogisticsList', params)
                this.logistics = ures.result
            } catch (error) {

            }



        },

        //保存原材料产品信息
        async UserProductSave() {
            let userId = window.localStorage.getItem('user_id')

            // let dialogImageUrl11 = this.dialogImageUrl1.replace(/^https?:\/\/zhnl\.ydlweb\.com/, '');
            // let dialogImageUrl22 = this.dialogImageUrl2.replace(/^https?:\/\/zhnl\.ydlweb\.com/, '');
            // let dialogImageUrl33 = this.dialogImageUrl3.replace(/^https?:\/\/zhnl\.ydlweb\.com/, '');
            // let dialogImageUrl44 = this.dialogImageUrl4.replace(/^https?:\/\/zhnl\.ydlweb\.com/, '');


            // let arr = [dialogImageUrl11, dialogImageUrl22, dialogImageUrl33, dialogImageUrl44]

            // let img_list = []
            // for (let i = 0; i < arr.length; i++) {
            //     if (arr[i]) {
            //         img_list.push(arr[i])
            //     }
            // }

            let text_list = [this.form.parameter_text0, this.form.parameter_text1, this.form.parameter_text2, this.form.parameter_text3, this.form.parameter_text4, this.form.parameter_text5]


            let content = this.content.replace(/https:\/\/zhnl.ydlweb.com/g, '');


            const goods_images = [this.dialogImageUrl1, this.dialogImageUrl2, this.dialogImageUrl3, this.dialogImageUrl4]
            console.log(goods_images, 'goods_images',);
            let params = {
                user_id: userId,
                goods_name: this.form.name,
                cat_id: this.form.cat_id,
                cat_id_2: this.form.cat_id2,
                cat_id_3: this.form.cat_id3,
                shop_price: this.form.shop_price,
                store_count: this.form.store_count,
                spec_id: this.form.spec_id,
                logistics_type_id: this.form.logistics_type_id,
                goods_remark: this.form.goods_remark,
                goods_content: content,
                // goods_images: img_list,
                parameter_title: this.title_list,
                parameter_text: text_list,
                location_name: this.form.location_name,
                goods_images: goods_images,
                spec_name: this.spec_name
            }
            console.log(this.form.cat_id2, '最新===');

            let res = {}
            // if (this.submitname === '提交编辑') {
            //     res = await this.$http('/mini/ApiConnect/editPublishProduct', Object.assign(params, { goods_id: this.goods_id }))
            // } else {
            //     res = await this.$http('/mini/ApiConnect/UserProductSave', params)
            // }
            addGoods(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '发布成功'
                    })
                    this.$router.push({
                        path: "/buyerCenter/theProduct"
                    })
                }
            })

            // if (res.status == 1) {
            //     this.$message.success(res.msg);
            //     setTimeout(() => {
            //         window.location = '/issueProduct'
            //     }, 1000)
            // } else {
            //     this.$message.error(res.msg);
            // }

        },

        // 滚动到顶部
        handleScrollTop() {
            console.log('5')
        },

        escapeStringHTML(str) {
            str = str.replace('<p>', '') //去除自带的p标签
            str = str.replace(/&lt;/g, '<');
            str = str.replace(/&gt;/g, '>');
            str = str.replace(/&quot;/g, '');
            return str;
        },

        handleEditAblequestion() {
            if (this.qillInit) {
                this.$refs.myQuillEditor.quill.enable(true)
                console.log(111)
                this.$refs.myQuillEditor.quill.focus()
                this.qillInit = false
            }
        },


        //产品详情
        async getUserProductDetails() {
            try {
                let params = {
                    goods_id: this.goods_id,

                }
                let res = await this.$http('/mini/ApiConnect/getUserProductDetails', params)
                this.form.name = res.result.goods_name
                this.form.parameter_text0 = res.result.service_sp[0].content
                this.form.parameter_text1 = res.result.service_sp[1].content
                this.form.parameter_text2 = res.result.service_sp[2].content
                this.form.parameter_text3 = res.result.service_sp[3].content
                this.form.parameter_text4 = res.result.service_sp[4].content
                this.form.parameter_text5 = res.result.service_sp[5].content
                this.form.cat_id = res.result.cat_id
                this.form.cat_id2 = res.result.cat_id_2
                this.form.cat_id3 = res.result.cat_id_3
                this.form.goods_remark = res.result.goods_remark
                this.form.shop_price = res.result.shop_price
                this.form.store_count = res.result.store_count
                this.form.logistics_type_id = res.result.logistics_type_id
                this.form.spec_id = res.result.spec_id
                this.form.location_name = res.result.location_name

                this.cat_name = res.result.cat_name
                this.cat_name2 = res.result.cat_name_2
                this.cat_name3 = res.result.cat_name_3
                this.spec_name = res.result.spec_name
                this.logistics_type_name = res.result.logistics_type_name

                this.content = this.escapeStringHTML(res.result.goods_content)


                if (res.result.goods_images[0].image_url.length > 25) {
                    console.log('1')

                    this.dialogImageUrl1 = res.result.goods_images[0].image_url
                    setTimeout(() => {
                        let obj = {}
                        obj.url = this.dialogImageUrl1
                        this.fileList1.push(obj)
                        this.upload_btn1 = true;
                    }, 1000)
                }
                if (res.result.goods_images[1].image_url.length > 25) {
                    this.dialogImageUrl2 = res.result.goods_images[1].image_url
                    setTimeout(() => {
                        let obj2 = {}
                        obj2.url = this.dialogImageUrl2
                        console.log('2', this.dialogImageUrl2)
                        this.fileList2.push(obj2)
                        this.upload_btn2 = true;
                    }, 1000)
                }
                if (res.result.goods_images[2].image_url.length > 25) {

                    this.dialogImageUrl3 = res.result.goods_images[2].image_url
                    setTimeout(() => {
                        let obj5 = {}
                        obj5.url = this.dialogImageUrl3
                        this.fileList3.push(obj5)
                        this.upload_btn3 = true;
                    }, 1000)

                }
                if (res.result.goods_images[3].image_url.length > 25) {

                    this.dialogImageUrl4 = res.result.goods_images[3].image_url

                    setTimeout(() => {
                        let obj4 = {}
                        obj4.url = this.dialogImageUrl4

                        this.fileList4.push(obj4)
                        this.upload_btn4 = true;
                    }, 1000)

                }
            } catch (error) {

            }
        },
        setData() {
            console.log(this.$route.query.data, 'this.newArray');
            this.newArray = this.$route.query.data
            // this.form.parameter_text0 = this.newArray.service[0]?.content
            // this.form.parameter_text1 = this.newArray.service[1]?.content
            // this.form.parameter_text2 = this.newArray.service[2]?.content
            // this.form.parameter_text3 = this.newArray.service[3]?.content
            // this.form.parameter_text4 = this.newArray.service[4]?.content
            // this.form.parameter_text5 = this.newArray.service[5]?.content
            this.content = this.newArray.goodsContent
            this.form.shop_price = this.newArray.shopPrice
            this.form.store_count = this.newArray.storeCount
            this.form.goods_remark = this.newArray.goodsRemark
            this.form.name = this.newArray.goodsName
            this.imgList = this.newArray.imgList
            this.goods_id = this.newArray.goods_id
            setTimeout(() => {
                this.form.cat_id = this.newArray.catId

                console.log(this.newArray.catId2, 'this.newArray.catId2');
                this.form.cat_id2 = this.newArray.catId2
                console.log(this.form.cat_id2, 'this.form.cat_id2');

                this.form.cat_id3 = this.newArray.catId3
                this.form.logistics_type_id = this.newArray.logisticsTypeId
                this.select1(this.form.cat_id, '2')
                this.select2(this.form.cat_id2, '2')

            }, 1000);
            console.log(this.imgList, 'this.imgListthis.imgListthis.imgList');
            this.$nextTick(() => {
                console.log(this.product_cat_list, 'this.product_cat_list');

            })
        }
    },

    mounted() {
        // getBstGoodsGategory().then((res) => {
        //     this.product_cat_list = res.data.data
        // })
        getAllSpec().then((res) => {
            this.unit = res.data.data
        })
        console.log(this.$route);
        if (this.$route.query.data) {
            this.setData()

        }


        getAllLogisticsType().then((res) => {
            this.logistics = res.data.data
        }
        )
        // this.userId = window.localStorage.getItem('user_id')
        // this.importData.user_id = this.userId
        // this.goods_id = getParams().id


        // this.getUserProductCat()
        // this.getGoodsOptionAll()
        // this.$refs.myQuillEditor.quill.enable(false)
        // setTimeout(() => {
        //     if (this.goods_id) {
        //         console.log('11', this.goods_id)
        //         this.getUserProductDetails()
        //     }
        // }, 500)
    }
};
</script>

<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006eb7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.te :v-deep .el-form-item .el-form-item__label {
    color: #000 !important;
}

::v-deep .el-input__inner {
    height: 30px !important;
}

::v-deep .el-input__inner::placeholder {
    font-size: 12px !important;
}

::v-deep .el-form-item__error {
    padding: 0 !important;
}

.pinpai ::v-deep .el-form-item__error {
    padding-top: 15px !important;
}

::v-deep .el-upload--picture-card {
    width: 124px !important;
    height: 124px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #9e9e9eff !important;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
    width: 124px !important;
    height: 124px !important;
}

::v-deep .el-button--primary {
    background-color: #006EB7 !important;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 124px !important;
    height: 124px !important;
}

::v-deep .hide_box .el-upload--picture-card {
    display: none;
}

::v-deep .el-upload-list__item {
    transition: none !important;
    -webkit-transition: nonne !important;
}

::v-deep .el-upload-list__item-name {
    transition: none !important;
    -webkit-transition: nonne !important;
}

::v-deep .el-form-item {
    display: flex;
}

::v-deep .el-form-item__content {
    margin-left: 0 !important;
}



::v-deep .el-input__inner {
    height: 30px;
}

::v-deep .el-input__icon {
    line-height: 0 !important;
}

::v-deep .el-form-item__content {
    width: 280px !important;
}

::v-deep .el-select {}

::v-deep .el-input__inner {
    font-size: 12px !important;

}

.select_line ::v-deep .el-form-item__content {
    margin-left: 0 !important;
    width: 87px !important;
    margin-right: 10px;
}

.select_line ::v-deep .el-form-item__error {
    margin-top: 8px;
}

.line3 ::v-deep .el-form-item__content {
    width: 202px !important;
}

.line3 ::v-deep .el-select {
    width: 72px !important;
    font-size: 6px !important;
    padding: 0 !important;
}

.line3 ::v-deep .el-form-item__error {
    margin-top: 8px;
}

.line5 ::v-deep .el-select {
    width: 100% !important;
    font-size: 6px !important;
    padding: 0 !important;
}

::v-deep .el-form-item__label {
    padding: 0 !important;
    color: #000 !important;
}



.inputBar ::v-deep .el-input__inner {
    height: 40px !important;
}
</style>
<style lang="scss" scoped>
.quill-editor {
    width: 1045px;
    height: 300px;

}

.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 50%;
    transform: translateX(-50%);
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.contaion {
    width: 1200px;
    border-radius: 4px;
    background: #fff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .pic_title {
        width: 1160px;
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #d6d6d6;
        font-size: 24px;
        font-weight: 700;
        font-family: "Alibaba PuHuiTi";
    }




    .btn {
        width: 142px;
        height: 42px;
        background: #006eb7;
        text-align: center;
        line-height: 42px;
        color: white;
        margin-top: 20px;
    }

    .form_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 115px;
        margin-top: 10px;

        .title {
            font-size: 14px;
            margin-left: 18px;
        }

        .line2 {
            width: 365px;
            height: 50px;

            .prick {
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                margin-left: 10px;
                color: #000;
            }
        }
    }
}

.product_pic {
    width: 1200px;
    height: 251px;
    border-radius: 4px;
    background: #ffffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .pic_title {
        width: 1160px;
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #d6d6d6;
        font-size: 24px;
        font-weight: 700;
        font-family: "Alibaba PuHuiTi";
    }

    .tips {
        width: 1160px;
        text-align: left;
        margin-top: 16px;
    }

    .upLoad_box {
        width: 1160px;
        display: flex;
        align-items: center;
        margin-top: 16px;

        .one {
            margin-left: 20px;

            position: relative;

            .master_map {
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 17px;
                border-radius: 0 0 2px 0;
                background: #006eb7ff;
                text-align: center;
                color: white;
                font-size: 10px;
            }
        }
    }

    .upLoad_box> :nth-child(1) {
        margin-left: 50px;
    }

}

.button {
    margin-top: 100px;
    margin-bottom: 30px;
}
</style>