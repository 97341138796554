<template>
    <div class="zh-box">
        <div class="wz-top">
            <div
                style="font-weight: 400;font-size: 26px;color: #227BFF;line-height: 40px;width: 200px;margin-left: 7px;">
                {{ titleName }}
            </div>
            <div class="classifyList">
                <div class="classify-item" v-for="item in areaCategoryList2 " :class="{ bgc: wangNumber == item.gId }"
                    @click="numberChange(item)">
                    {{ item.label }}
                </div>
            </div>
            <div>

            </div>
        </div>
        <div class="lun">
            <div class="lun-left" style="cursor: pointer;" @click="toTitle(videoListType1)">
                <div style="position: absolute;bottom: 14px ;left: 28px;color: #FFFFFF;font-size: 16px;">{{
                    videoListType1?.vTitle }}</div>
                <img :src="videoListType1?.vImgUrl"
                    style="width: 100%;height: 100%;border-radius: 12px;object-fit: cover;" alt="">
            </div>
            <div class="lun-right">
                <div class="title-item" v-for="item in videoListType2" @click="toTitle(item)">
                    <div class="item-left"><img :src="item.vImgUrl"
                            style="width: 100%;height: 100%;border-radius: 12px;object-fit: cover" alt=""></div>
                    <div class="item-right">
                        <div
                            style="color: #333333;font-size: 16px;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;width: 100%;overflow: hidden;">
                            {{ item.vTitle }}</div>
                        <div style="color: #9499A0;font-size: 12px;padding-top: 5px;">
                            <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                style="vertical-align: middle;margin-right: 1px;object-fit: cover" alt="">

                            <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six">
            <div class="six-left">
                <div style="width: 100%;height: 45%;">
                    <div style="flex: 1.3;height: 103%;overflow: hidden;border-radius: 5px;margin-bottom: 100px;">
                        <div class="line1">
                            <div class="top">
                                <!-- <div class="title" @click="skipOpen('/quotedPrice')">
                            <img style="width:100%;height: 100%;" src="@/assets/标题 - 产品行情.png" alt="">
                        </div> -->
                                <!-- <div class="date">{{ newDayMarketData }}</div> -->
                            </div>
                            <!-- <div class="theLine"></div> -->

                            <div class="scroll_table">
                                <div style="display: inline-block; width: 100%">
                                    <el-table :data="tableList2" class="table" :class="['commonScrollbarTable']"
                                        :header-cell-style="{
                    'text-align': 'center',
                    'background-color': 'rgba(230, 239, 243)',
                    'color': '#227BFF',
                    padding: '0',
                    height: '46px',
                    'font-family': 'MicrosoftYaHeiLight',
                    'font-size': '14px'
                }" :row-style="{
                    padding: '0px',
                    cursor: 'pointer',
                    'background-color': 'rgba(230, 239, 243)',
                }" :cell-style="{
                    'text-align': 'center',
                    'font-size': '14px',
                    padding: '0',
                    height: '45.5px',


                }" @row-click="tableClick2">
                                        <el-table-column prop="date" label="日期" :style="{
                    'font-size': '14px',
                    width: '100px'
                }">
                                        </el-table-column>
                                        <el-table-column prop="good_name" label="产品">
                                        </el-table-column>
                                        <el-table-column prop="bqPrice" label="价格">
                                        </el-table-column>
                                        <el-table-column prop="real_name" label="供货商">
                                        </el-table-column>

                                        <el-table-column prop="up_price" label="涨跌">
                                            <template slot-scope="scope">
                                                <div style="
                                                color: #21a00c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 1">
                                                    <img src="../assets/newImg/路径@1x (5).png"
                                                        style="vertical-align: middle" alt="" />
                                                    <div style="
                                                    text-align: center;
                                                    overflow: auto;
                                                ">
                                                        {{ scope.row.up_price }}
                                                    </div>
                                                </div>

                                                <div style="
                                                color: #ff0c0c;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            " v-if="scope.row.is_up == 2">
                                                    <img src="../assets/newImg/路径@1x (4).png"
                                                        style="vertical-align: middle" alt="" />
                                                    <div style="text-align: center">
                                                        {{ scope.row.up_price }}
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>

                                    </el-table>
                                    <div @click="godeilt($event)">
                                        <vue-seamless-scroll :data="tableList2" class="seamless-warp2"
                                            style="width: 100%;    " :class-option="classOption2">
                                            <el-table :data="tableList2" class="table_scroll"
                                                :class="['commonScrollbarTable']" :header-cell-style="{
                    'text-align': 'center',
                    'background-color': '#EFEFEF',
                    padding: '0',
                    height: '32px',
                    'font-family':
                        'Microsoft YaHei UI Bold',
                    'font-size': '14px'
                }" :row-style="{
                    padding: '0px',
                    cursor: 'pointer'
                }" :cell-style="{
                    'text-align': 'center',
                    'font-size': '14px',
                    padding: '0',
                    height: '45.5px',

                }">
                                                <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                                                    <template slot-scope="scope">
                                                        <div :data-id="JSON.stringify(
                    scope.row
                )
                    " style="
                                                        font-size: 14px;
                                                        text-align: center;
                                                        color: #333333;
                                                    ">
                                                            {{
                    scope.row.add_time.slice(
                        5,
                        scope.row.date
                            ?.length
                    )
                }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <!-- @row-click="tableClick2" -->
                                                <el-table-column prop="good_name" label="产品名称">
                                                    <template slot-scope="scope">
                                                        <div style="color: #333333;" :data-id="JSON.stringify(
                        scope.row
                    )
                    ">
                                                            {{ scope.row.good_name }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="price" label="价格">
                                                    <template slot-scope="scope">
                                                        <div style="color: #333333;" :data-id="JSON.stringify(
                    scope.row
                )
                    ">
                                                            {{ scope.row.price }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="real_name" label="供货商">
                                                    <template slot-scope="scope">
                                                        <div style="color: #333333;" :data-id="JSON.stringify(
                    scope.row
                )
                    ">
                                                            {{ scope.row.real_name }}
                                                        </div>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column prop="up_price" label="涨跌">
                                                    <template slot-scope="scope">
                                                        <div :data-id="JSON.stringify(
                    scope.row
                )
                    " style="
                                                        color: #21a00c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 0">
                                                            <img src="../assets/newImg/路径@1x (5).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                                                            <div style="
                                                            text-align: center;
                                                            overflow: auto;
                                                        ">
                                                                {{
                    scope.row.up_price
                }}
                                                            </div>
                                                        </div>

                                                        <div :data-id="JSON.stringify(
                    scope.row
                )
                    " style="
                                                        color: #ff0c0c;
                                                        font-size: 14px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    " v-if="scope.row.is_up == 1">
                                                            <img src="../assets/newImg/路径@1x (4).png" style="
                                                            vertical-align: middle;
                                                        " alt="" />
                                                            <div style="
                                                            text-align: center;
                                                        ">
                                                                {{ scope.row.up_price }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </el-table-column>

                                            </el-table>

                                        </vue-seamless-scroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-box">
                    <div class="edit-item" v-for="item in videoListType3" @click="toVedio(item)" :key="item.vId">
                        <div style="position: relative;">
                            <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;"> {{
                    item.vAddTime.slice(0, 10) }}
                            </div>
                            <img :src="item.vImgUrl"
                                style="width: 100%;height: 116px;object-fit: cover;border-radius: 12px;" alt="">
                        </div>
                        <div style="color: #333333;font-size: 16px;">{{ item.vTitle }}</div>
                        <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                            <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                style="vertical-align: middle;margin-right: 1px;" alt="">

                            <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="six-right">
                <div class="contaion3-item" @click="skip(item)" v-for="(item, index) in IndustryInformationList">
                    <div class="con-left">
                        <img :src="item.imgUrl" style="width: 100%;height: 100%;" alt="">
                    </div>
                    <div class="con-right">
                        <div style="font-weight: 400;padding: 8px;font-size: 16px;color: #000000;">{{ item.bgcTitle }}
                        </div>
                        <div class="con-right-middel">{{ item.bgcContent.replace(/<[^>]*>/g, '') }}</div>
                        <div style="display: flex;padding: 5px 5px 5px 8px;color: #999999;font-size: 12px;font-weight: 290;"
                            class="con-bottom">
                            <div>
                                <img src="../assets/zhnlImg/Clip path group@1x.png"
                                    style="vertical-align: middle;margin-right: 2px;" alt="">
                                <span style="vertical-align: middle;">{{ item.addTime }}</span>
                            </div>
                            <div style="margin-left: 20px;">
                                <span style="vertical-align: middle;">阅读量:{{ item.readNum }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="info" @click="skip(item)" v-for="(item, index) in IndustryInformationList">
                            <div class="yuan"></div>
                            <div class="title">
                                {{ item.bgcTitle }}
                            </div>
                            <div class="timer">[{{ item.addTime }}]</div>
                        </div> -->


            </div>
        </div>

    </div>
</template>

<script>
import { getAllGoodsConsult, getHotVod, getAllVod, getBstQuotedPrice } from '@/api/user'
import dayjs from 'dayjs';

export default {
    data() {
        return {
            areaCategoryList: [],
            videoListType: [],
            wangNumber: 0,
            size: 30,
            page: 1,
            videoListType1: {},
            videoListType2: [],
            videoListType3: [],
            tableList2: [],
            videoList2: [],
            industry: '',
            industry2: '',
            IndustryInformationList: [],

        }
    },
    props: ['areaCategoryList2', 'titleName'],
    computed: {

        classOption2() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    methods: {

        skip(item) {

            const { href } = this.$router.resolve({
                path: "/titleDetails",
                query: { id: item.bgcId },
            });
            window.open(href, "_blank");
        },
        toVedio(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,
                },
            });
            window.open(href, "_blank");
        },
        tableClick2(row) {
            const { href } = this.$router.resolve({
                path: "/todayQuotedPrice",
                query: {
                    data: JSON.stringify({
                        catOne: row.cat_one,
                        catTwo: row.cat_two,
                        catThree: row.cat_three,
                        label: row.good_name
                    })
                },
            });
            window.open(href, "_blank");
        },
        numberChange(item) {
            this.wangNumber = item.gId

            let data = {
                industry: this.industry,
                industry2: '',
                page: 1,
                size: 30,
                title: '',
                cat_one: this.wangNumber
            }
            getAllVod(data).then((res) => {
                console.log(res.data, '0000000000');
                this.videoListType1 = res.data.data[0]
                this.videoListType2 = res.data.data.slice(1, 5)
                this.videoListType3 = res.data.data.slice(5, 23)
            })
        },
        toTitle(item) {
            const { href } = this.$router.resolve({
                path: '/vudio',
                query: {
                    v_id: item.vId
                }
            });
            window.open(href, "_blank");

        },
        godeilt(e) {
            console.log(e, 'e.........');
            //  let uid=e.target.dataset.id
            const event = JSON.parse(e.target.dataset.id)
            this.tableClick2(event)
        },
    },
    created() { },
    mounted() {
        this.industry = this.$route.query.cat_one
        let data = {
            industry: this.industry,
            industry2: "",
            page: 1,
            size: 30,
            title: '',
        }
        getAllVod(data).then((res) => {
            this.videoListType1 = res.data.data[0]
            this.videoListType2 = res.data.data.slice(1, 5)
            this.videoListType3 = res.data.data.slice(5, 11)
        })
        // getBstGoodsGategory1().then((res) => {
        //     this.areaCategoryList = res.data.data
        //     this.areaCategoryList.unshift({ label: '推荐', gId: 0 })
        //     console.log(this.areaCategoryList, 'this.areaCategoryList ');
        // })
        getBstQuotedPrice({ size: 61 }).then((res) => {
            this.newDayMarketData = res.data.data[0]?.addTime
            this.tableList2 = res.data.data
        })
        let params = {
            title: this.title,
            size: 6,
            page: 1,
            industry: this.industry,
            industry2: this.industry2,
        }
        getHotVod(params).then((res) => {
            this.videoList2 = res.data.data
            this.total = res.data.totalPage
        })
        let params2 = {
            cat_one: '',
            cat_two: '',
            cat_three: '',
            industry: this.industry,
            industry2: '',
            size: this.size,
            page: this.page
        }
        getAllGoodsConsult(params2).then((res) => {
            this.total_page = res.data.totalPage
            res.data.data.forEach((item, index) => {
                item.add_time = dayjs(item.add_time * 1000).format('YYYY-MM-DD');
            })

            this.IndustryInformationList = res.data.data
        })
    }
}
</script>

<style lang=scss scoped>
@media screen and (min-width: 600px) and (max-width: 1620px) {
    .zh-box {
        margin-bottom: 20px !important;
        width: 1200px;
        margin: auto;
        height: 1066px;
        background-color: white;
        margin-top: 30px;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';

        .wz-top {
            height: 43px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 66px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 12px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48%;
                height: 98%;
                padding: 5px;
                position: relative;
                // background-color: pink;
            }

            .lun-right {
                width: 54.5%;
                height: 101%;
                padding: 5px 0px 0px 2px;
                display: flex;
                flex-wrap: wrap;

                .title-item {
                    width: 48.5%;
                    height: 46%;
                    padding: 5px 4px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    display: flex;

                    .item-left {
                        height: 98%;
                        width: 69%;
                    }

                    .item-right {
                        padding: 2px 15px;
                        height: 99%;
                        width: 31%;

                    }
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .scroll_table {
            margin-bottom: 15px;
            // margin-top: 5px;
            display: flex;
            width: 100%;

            .table_ul {
                display: flex;
                align-items: center;
                height: 32px;
                box-sizing: border-box;
                width: 100%;
                // height: 51px;
                background: #efefef;

                .li_nav {
                    font-size: 13px;
                    font-weight: 700;
                    width: 88px;
                    height: 32px;
                    overflow: hidden;
                    text-align: center;
                    line-height: 32px;
                }
            }

            .warpw {
                height: 514px;
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
            }

            .table_ul1 {
                // height: 514px;
                width: 100%;
                box-sizing: border-box;

                .ulis {
                    width: 100%;

                    >span {
                        width: 88px;
                        height: 45px;
                        display: inline-block;
                        text-align: center;
                        font-size: 13px;
                        line-height: 45px;
                        overflow: hidden;
                        box-sizing: border-box;
                    }
                }
            }

            ::v-deep .table .el-table__body-wrapper {
                display: none;
            }

            // 重点注意这段样式
            .seamless-warp {
                height: 482px;
                overflow: hidden;


                ::v-deep .table_scroll .el-table__header-wrapper {
                    display: none;
                }
            }

            .seamless-warp2 {
                height: 482px;
                overflow: hidden;
                // background-color: rgba(230, 239, 243);

                ::v-deep .table_scroll .el-table__header-wrapper {
                    display: none;
                }
            }
        }

        .six {
            width: 100%;
            height: 689px;
            margin-top: 8px;
            padding: 8px 3px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                cursor: pointer;
                width: 240px;
                height: 181px;
                padding: 0px 4px;
                // background-color: skyblue;
            }

            .six-left {
                width: 46.6%;
                height: 100%;

                .edit-box {
                    width: 100%;
                    height: 50%;
                    flex-wrap: wrap;
                    display: flex;
                    margin-top: 26px;

                    .edit-item {
                        cursor: pointer;
                        width: 177.5px;
                        height: 11.2rem;
                        padding: 0px 4px;
                        // background-color: skyblue;
                    }
                }
            }

            .six-right {
                width: 52.2%;
                height: 100%;
                overflow: scroll;
                overflow-x: hidden;
                margin-left: 12px;

                .contaion3-item {
                    height: 120px;
                    width: 100%;
                    display: flex;
                    margin-bottom: 10px;
                    cursor: pointer;

                    .con-left {
                        width: 30%;
                        height: 100%;
                    }

                    .con-right {
                        width: 68%;
                        height: 100%;

                        .con-bottom:hover {
                            color: rgb(32, 123, 255) !important;

                        }

                        .con-right-middel {
                            padding: 0px 5px 5px 8px;
                            height: 53px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            color: #000000;
                            font-size: 14px;
                            font-weight: 290;
                        }

                        .con-right-middel:hover {
                            color: rgb(32, 123, 255) !important;

                        }


                    }
                }
            }

            .six-right::-webkit-scrollbar {
                width: 8px;
            }

            .six-right::-webkit-scrollbar-thumb {
                border-radius: 20px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            .six-right::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);

            }


        }
    }
}

@media screen and (min-width: 1620px) and (max-width: 50500px) {
    .zh-box {
        width: 1500px;
        height: 1066px;
        background-color: white;
        margin-top: 30px !important;
        padding: 10px 20px;
        font-family: 'MicrosoftYaHeiLight';
        margin: auto;

        .wz-top {
            height: 43px;
            width: 100%;
            display: flex;

            .classifyList {
                display: flex;
                height: 100%;
                margin-left: 66px;

                .classify-item {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 400;
                    height: 20px;
                    padding: 12px 12px;
                    cursor: pointer;
                }

                .classify-item:hover {
                    // color: #227BFF;
                    // border-bottom: 1px solid #227BFF;
                }

            }
        }

        .lun {
            height: 310px;
            width: 100%;
            display: flex;
            // background-color: skyblue;
            margin-top: 2px;

            .lun-left {
                width: 48%;
                height: 98%;
                padding: 5px;
                position: relative;
                // background-color: pink;
            }

            .lun-right {
                width: 54.5%;
                height: 101%;
                padding: 5px 0px 0px 2px;
                display: flex;
                flex-wrap: wrap;

                .title-item {
                    width: 370px;
                    height: 46%;
                    padding: 5px 4px;
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    display: flex;

                    .item-left {
                        height: 98%;
                        width: 62%;
                    }

                    .item-right {
                        padding: 2px 15px;
                        height: 99%;
                        width: 38%;

                    }
                }

                .title-item:hover {
                    color: #227BFF;
                    ;
                }
            }
        }

        .scroll_table {
            margin-bottom: 15px;
            // margin-top: 5px;
            display: flex;
            width: 100%;

            .table_ul {
                display: flex;
                align-items: center;
                height: 32px;
                box-sizing: border-box;
                width: 100%;
                // height: 51px;
                background: #efefef;

                .li_nav {
                    font-size: 13px;
                    font-weight: 700;
                    width: 88px;
                    height: 32px;
                    overflow: hidden;
                    text-align: center;
                    line-height: 32px;
                }
            }

            .warpw {
                height: 514px;
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
            }

            .table_ul1 {
                // height: 514px;
                width: 100%;
                box-sizing: border-box;

                .ulis {
                    width: 100%;

                    >span {
                        width: 88px;
                        height: 45px;
                        display: inline-block;
                        text-align: center;
                        font-size: 13px;
                        line-height: 45px;
                        overflow: hidden;
                        box-sizing: border-box;
                    }
                }
            }

            ::v-deep .table .el-table__body-wrapper {
                display: none;
            }

            // 重点注意这段样式
            .seamless-warp {
                height: 482px;
                overflow: hidden;


                ::v-deep .table_scroll .el-table__header-wrapper {
                    display: none;
                }
            }

            .seamless-warp2 {
                height: 482px;
                overflow: hidden;
                // background-color: rgba(230, 239, 243);

                ::v-deep .table_scroll .el-table__header-wrapper {
                    display: none;
                }
            }
        }

        .six {
            width: 100%;
            height: 689px;
            margin-top: 8px;
            padding: 8px 3px;
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            align-content: flex-start;
            justify-content: flex-start;

            .edit-item {
                cursor: pointer;
                width: 240px;
                height: 181px;
                padding: 0px 4px;
                // background-color: skyblue;
            }

            .six-left {
                width: 46.6%;
                height: 100%;

                .edit-box {
                    width: 100%;
                    height: 50%;
                    flex-wrap: wrap;
                    display: flex;
                    margin-top: 26px;

                    .edit-item {
                        cursor: pointer;
                        width: 224.5px;
                        height: 11.2rem;
                        padding: 0px 4px;
                        // background-color: skyblue;
                    }
                }
            }

            .six-right {
                width: 52.5%;
                height: 100%;
                overflow: scroll;
                overflow-x: hidden;
                margin-left: 12px;

                .contaion3-item {
                    height: 120px;
                    width: 100%;
                    display: flex;
                    margin-bottom: 10px;
                    cursor: pointer;

                    .con-left {
                        width: 30%;
                        height: 100%;
                    }

                    .con-right {
                        width: 68%;
                        height: 100%;

                        .con-bottom:hover {
                            color: rgb(32, 123, 255) !important;

                        }

                        .con-right-middel {
                            padding: 0px 5px 5px 8px;
                            height: 53px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            color: #000000;
                            font-size: 14px;
                            font-weight: 290;
                        }

                        .con-right-middel:hover {
                            color: rgb(32, 123, 255) !important;

                        }


                    }
                }
            }

            .six-right::-webkit-scrollbar {
                width: 8px;
            }

            .six-right::-webkit-scrollbar-thumb {
                border-radius: 20px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            .six-right::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);

            }


        }
    }
}


.bgc {
    color: #227BFF !important;
    border-bottom: 1px solid #227BFF;
}

::v-deep .el-table__row {
    background-color: rgb(230, 239, 243) !important;
}
</style>