<template>
  <div class="top">
    <div class="top-img" @click="goShow">
      <img src="@/assets/Qsou.png" class="img-sty" alt="" @click="$router.push({ path: '/' })" />

      <!-- <img src="@/assets/newImg2/Logo - 搜原网_upscayl_4x_realesrgan-x4plus.png" alt="" class="img-sty"> -->
    </div>
    <div style="right: 40px; position: absolute">
      <div style="line-height: 78px; color: red; cursor: pointer" @click="logOut">退出登录</div>
      <!-- <img src="@/assets/newImg/组 4@1x.png" @click="goUser()" @mouseover="handleMouseOver4"
                style="width: 25px;height: 24px;padding: 26px 14px;cursor: pointer;" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logOut() {
      const { href } = this.$router.resolve({
        path: "/"
      });
      window.open(href, "_blank");
      // this.$router.push({
      //     path: '/'
      // })
      this.userShow = false;
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userName");
      window.localStorage.removeItem("headPic");
      this.$message({
        type: "success",
        message: "账号已退出"
      });
    },
    goShow() {
      const { href } = this.$router.resolve({
        path: "/"
      });
      window.open(href, "_blank");
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.top {
  position: fixed !important;
  top: 0px !important;
  z-index: 1500;
}

@media screen and (min-width: 100px) and (max-width: 1500px) {
  .top {
    height: 70px;
    width: 100vw;
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    opacity: 1;
    display: flex;
    background: #ffffff;
    backdrop-filter: blur(20px);
    position: relative;

    .top-img {
      margin: 0;
      padding: 0;
      width: 13vw !important;
      // width: 190px;
      cursor: pointer;
      height: 67px !important;

      .img-sty {
        width: 145px;
        height: 48px;
        margin-top: 5%;
        margin-left: 20px;
        // padding: 0px 24px;
      }
    }
  }
}

@media screen and (min-width: 1500px) and (max-width: 1900px) {
  .top {
    position: relative;

    height: 70px;
    width: 100vw;
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    opacity: 1;
    display: flex;
    background: #ffffff;
    backdrop-filter: blur(20px);

    .top-img {
      margin: 0;
      padding: 0;
      width: 13vw !important;
      // width: 190px;
      cursor: pointer;
      height: 67px !important;

      .img-sty {
        width: 152px;
        height: 48px;
        margin-top: 5%;
        padding: 0px 30px;
        // padding: 0px 32px;
      }
    }
  }
}

@media screen and (min-width: 1900px) and (max-width: 2300px) {
  .top {
    position: relative;
    height: 70px;
    width: 100vw;
    display: flex;
    // float: ;
    // position: fixed;
    opacity: 1;
    display: flex;
    background: #ffffff;
    backdrop-filter: blur(20px);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    padding-bottom: 5px;

    .top-img {
      margin: 0;
      padding: 0;
      width: 13vw !important;
      // width: 190px;
      cursor: pointer;
      height: 67px !important;

      .img-sty {
        width: 160px;
        height: 48px;
        margin-top: 5%;
        padding: 0px 32px;
      }
    }
  }
}

@media screen and (min-width: 2300px) and (max-width: 5800px) {
  .top {
    position: relative;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    height: 70px;
    width: 100vw;
    display: flex;
    // float: ;
    // position: fixed;
    opacity: 1;
    display: flex;
    background: #ffffff;
    backdrop-filter: blur(20px);

    .top-img {
      margin: 0;
      padding: 0;
      width: 13vw !important;
      // width: 190px;
      cursor: pointer;
      height: 67px !important;

      .img-sty {
        width: 160px;
        height: 48px;
        margin-top: 3%;
        padding: 0px 32px;
      }
    }
  }
}
</style>
