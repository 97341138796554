<template>
    <div id="app">
        <topNewbar v-show="topShow == 0" ref="topNewbar" />
        <topNewbar2 v-show="topShow == 1" />
        <router-view @oneMouse="oneMouse" />

        <el-backtop class="el-backtop" style="right: 40px; bottom: 80px;"><i class="el-icon-caret-top"></i>
        </el-backtop>
        <footBar v-show="ifshow" />
    </div>
</template>

<script>
import topBar from './components/topBar.vue'
import topNewbar from './components/topNewbar.vue'
import topNewbar2 from './components/topNewbar2.vue'

import animate from 'animate.css'
import footBar from './components/footBar.vue'

export default {
    data() {
        return {
            ifshow: 1,
            topShow: 1,
        };
    },
    methods: {
        oneMouse() {
            this.$refs.topNewbar.overF()
        }
    },
    mounted() {

        if (this.$route.path.includes('buyerCenter')) {
            this.topShow = 1
        } else {
            this.topShow = 0
        }
        if (this.$route.name == '登录') {
            this.ifshow = 0
        }
    },
    components: {
        topBar,
        footBar, topNewbar, topNewbar2
    }
}
</script>
<style lang="scss" scoped>
// #app::-webkit-scrollbar {
//     width: 8px;
// }


// #app::-webkit-scrollbar-thumb {
//     border-radius: 20px;
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     background: rgba(0, 0, 0, 0.2);
// }

// #app::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     border-radius: 0;
//     background: rgba(0, 0, 0, 0.1);

// }

#app {
    font-family: "微软雅黑" !important;
    font-family: 'Microsoft YaHei' !important;
    overflow-x: hidden;
    /* 隐藏X轴滚动条 */
    // background-color: red;
}

.el-message-box__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 其他你需要的样式 */
}

.classifyBox {
    margin-top: 5px !important;
}

.location {
    margin-top: 0 !important;
}

.hhu:hover {
    box-shadow: 0 0 10px #ccc !important;
}

.hht:hover {
    background-color: rgb(6, 26, 95) !important;
}

.hhy:hover {
    background-color: #006EB7 !important;
    color: #fff !important;
    cursor: pointer;
}

.hhe:hover {
    color: #006EB7 !important;
}

.ov1 {
    overflow: hidden !important;

    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.ov2 {
    overflow: hidden !important;

    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

.onclass:hover {
    cursor: not-allowed !important;
}

@import url("./assets/font/font.css");

body {
    font-family: "Microsoft YaHei UI";
    color: #000000;
    // cursor: pointer;
}

body {
    background-color: #F2F2F2;
    // padding-bottom: 20px;
    margin: 0 !important;
}

a {
    text-decoration: none;
}

a:hover {
    color: #000000;
}

a:visited {
    color: #000000;
}

a:link {
    color: #000000;
}

.flexColCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flexRowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
