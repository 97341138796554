<template>
    <div class="bigBox" style="margin-top: 79px">
        <div class="content">
            <!-- <searchBar /> -->
            <!-- <div class="line"></div> -->
            <!-- <div class="classifyBox">
                <classifyBar />

            </div> -->

            <div class="location">
                <span>当前位置：</span>
                <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/navigation' }">基础油</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/navigation' }">15号基础油</el-breadcrumb-item>
                    </el-breadcrumb></div>
            </div>

            <div class="commodityBox">


                <div class="left">
                    <img :src="newImg" style="width: 163px; height: 163px;">
                </div>
                <div class="right">
                    <div class="commodityTitle">
                        {{ info.goodsName }}
                    </div>


                    <!-- <div class="serve" v-if="info.goods_service">
                        <span>服务:</span>
                        <span style="margin-left: 20px;">{{ info.goods_service[0].bspTitle }}</span>
                    </div> -->

                    <div class="numBox">
                        <span>数量: {{ $route.query.num }}</span>
                        <!-- <div class="numBtn">
                            {{ $route.query.num }}
                        </div> -->
                    </div>
                    <div class="numBox">
                        <span>发货地：{{ info.locationName }}</span>

                    </div>
                    <div class="numBox">
                        <span>物流方式：{{ info.LogisticsTypeName }}</span>

                    </div>
                    <div class="numBox">
                        <span>单价：￥{{ info.shopPrice }}</span>

                    </div>


                    <!-- <div class="price">
                        <span style="font-size: 16px; color: #000000ff;">单价</span>
                        <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">￥{{ info.shopPrice }}</span>

                    </div> -->
                </div>
            </div>


            <div class="countPirce" style="margin-top: 20px;">
                <span style="font-size: 20px;">总价：<span
                        style="font-size: 22px;font-weight: 900; color: rgb(255, 12, 12);">￥{{
                            info.shopPrice * $route.query.num }}</span></span>
            </div>


            <el-dialog title="" :visible.sync="dialogVisible" width="40%" center :close-on-click-modal="false"
                :show-close="false">
                <div style="width: 100%;text-align: center;font-weight: 900;font-size: 30px;">订单提交成功</div>
                <div style="width: 100%;text-align: center;font-weight: 900;font-size: 30px;">请联系中海南联官方客服进行对接</div>
                <div style="width: 100%;text-align: center;font-weight: 900;font-size: 30px;">18929760113</div>
                <!-- <div ref="qrcode" style="width: 200px; height: 200px;" @click="chufa">

                </div> -->



                <span slot="footer" class="dialog-footer">
                    <el-button @click="toStrict" type="primary">返回严选商城</el-button>
                    <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
                </span>
            </el-dialog>

            <div class="orderInfo">
                <div class="orderTitle" style="font-weight: 900;">地址信息</div>

                <div class="putBox" style="padding-bottom: 100px;">
                    <div class="line1">
                        <el-form :model="form" ref="ruleFormRef" :rules="rules">
                            <el-form-item label="详细地址" label-width="100px" prop="u_address">
                                <el-input v-model="form.u_address" autocomplete="off" required :maxlength="50" />
                            </el-form-item>
                            <el-form-item label="联系方式" label-width="100px" prop="u_phone">
                                <el-input v-model="form.u_phone" autocomplete="off" required :maxlength="11" />
                            </el-form-item>
                            <el-form-item label="收货人" label-width="100px" prop="consignee">
                                <el-input v-model="form.consignee" autocomplete="off" required :maxlength="10" />
                            </el-form-item>

                            <div class="btnBox" style="margin-top: 0; margin-left: 46px;"
                                @click="createOrder('ruleFormRef')">
                                确认并支付
                            </div>

                        </el-form>
                        <!-- <div class="put1" v-if="this.consignee != 'undefined'">
                            姓名：{{ this.consignee }}
                        </div>
                        <div class="put1" v-if="this.mobile != 'undefined'">
                            电话：{{ this.mobile }}
                        </div>
                        <div class="put1" v-if="this.address != 'undefined'">
                            您的地址：{{ this.address }}
                        </div> -->

                        <!-- <div class="btnBox" style="margin-top: 0; width: 70px; height: 30px; line-height: 30px;"
                            @click="checkOutAddress">
                            更换地址
                        </div> -->
                    </div>

                    <!-- 
                    <div class="subBox" style="display: flex; align-items: center;">
                        <el-popover placement="right" width="900" trigger="manual" v-model="popoverFlag">

                            <div class="tableBox">
                                <el-table :data="tableData" style="width: 100%; border: 1px solid #AEAEAE;" type=index
                                    :header-cell-style="{ background: '#EBECF0', color: '#606266' }">
                                    <el-table-column prop="consignee" label="收货人" width="90px">
                                    </el-table-column>
                                    <el-table-column prop="province" label="所在地区" width="200px">
                                    </el-table-column>
                                    <el-table-column prop="address" label="详细地址" width="180px">
                                    </el-table-column>
                                    <el-table-column prop="zipCode" label="邮政编码" width="100px">
                                    </el-table-column>
                                    <el-table-column prop="mobile" label="手机号码">
                                    </el-table-column>
                                    <el-table-column prop="operate" label="操作" width="100px" align="center">
                                        <template slot-scope="scope">
                                            <el-button style="margin-right: 5px;" type="text" size="small"
                                                @click="selectAddress(scope.$index, scope.row)">选中地址</el-button>

                                        </template>
</el-table-column>
</el-table>
</div>

<el-button class="subBtn"
    style="background: none; border: 1px solid #006EB7; color: #006EB7; box-sizing: border-box; line-height: 10px; font-size: 16px;"
    slot="reference" @click="flagClick">更改地址</el-button>
</el-popover>

<div class="btnBox" style="margin-top: 0; margin-left: 30px;" @click="createOrder">
    确认并支付
</div>

</div> -->



                </div>
            </div>

        </div>

    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import getParams from '../utils/getParams'
import { getGoodsInfoById, getUserAddressByUserId, addOrder } from '@/api/user.js'
import QRCode from 'qrcodejs2';

export default {
    data() {
        return {
            newImg: '',
            qrCodeUrl: 'www.baidu.com',
            dialogVisible: true,
            checkOutImgList: [require("../assets/detailsPage/image1.png"), require("../assets/detailsPage/Group.png")],
            checkOutImgFlag: 0,
            info: {},
            comment_rate: '',
            productsList: '',
            type: 0,
            form: {
                u_address: '',
                u_phone: '',
                consignee: '',
            },
            commentList: '',
            levelList: '',
            tableData: [],
            number: 1,
            numberFlag1: 0,
            rules: {
                u_address: [
                    {
                        required: true,
                        message: '请填写详细地址',
                        trigger: 'change',
                    },
                ],
                u_phone: [
                    {
                        required: true,
                        message: '请填写联系方式',
                        trigger: 'change',
                    },
                ],
                consignee: [
                    {
                        required: true,
                        message: '请填写收货人',
                        trigger: 'change',
                    },
                ],


            },
            options: [],
            value: '',
            options2: [],
            value2: '',
            options3: [],
            value3: '',
            goodslist: '',
            allprices: '',
            allnumber: '',

            //订单参数
            name: '',
            phone: '',
            address: '',
            popoverFlag: false,
            addressId: '',
            goods_id: '',
            info: '',
            region: '',
            phone: '',
            perpeo: ''
        }
    },

    components: {
        searchBar,
        classifyBar,
    },

    methods: {
        chufa() {
        },
        toStrict() {
            this.$router.push({
                path: '/strictStore'
            })
        },
        selectAddress(index, e) {
            // this.addressId = e.address_id
            // this.popoverFlag = false
            // this.UserAddressDefault(e.address_id, e.adress, e.phone, e.perpeo)
            // setTimeout(() => {
            //     window.location = '/buyOrder?addressId=' + e.address_id + '&goods_id=' + this.goods_id + "&address=" + e.address + '&phone=' + e.phone + '&perpeo=' + e.perpeo + '&region=' + e.region
            // }, 1000)
            this.address = this.tableData[index].address
            this.mobile = this.tableData[index].mobile
            this.consignee = this.tableData[index].consignee
            this.$message({
                type: 'success',
                message: '修改成功'
            })
            this.popoverFlag = false

        },
        flagClick() {
            if (this.tableData.length < 1) {
                this.$message({
                    message: '你没有地址请编辑',
                    type: 'warning'
                });
                return this.$router.push('/buyerCenter/address')
            }
            this.popoverFlag = !this.popoverFlag

        },
        select1() {
            this.UserRegionList2()
        },

        select2() {
            this.UserRegionList3()
        },
        min() {
            if (this.number <= 1) {
                this.numberFlag1 = 0
            } else {
                this.number = this.number - 1
            }
        },

        max() {
            this.numberFlag1 = 1

            this.number = this.number + 1
        },

        barBtn(type) {
            this.type = type
        },

        createOrder(ruleFormRef) {
            console.log(this.$refs.qrcode, 'this.$refs.qrcode');
            // new QRCode(this.$refs.qrcode, {
            //     text: 'weixin://wxpay/bizpayurl?pr=psf2eBAz1',
            //     width: 200,
            //     height: 200
            // });
            this.$refs[ruleFormRef].validate((valid) => {
                if (valid) {
                    let params = {
                        user_id: window.localStorage.getItem('user_id'),
                        goods_id: this.goods_id,
                        num: this.$route.query.num,
                        goods_price: this.info.shopPrice,
                        admin_note: '',
                        user_note: '',
                        prom_type: 0,
                        ship_type: 2,
                        consignee: this.form.consignee,
                        province: 0,
                        city: 0,
                        district: 0,
                        address: this.form.u_address,
                        mobile: this.form.u_phone,
                        zipcode: '',
                        shipping_code: '',
                        shipping_name: '',
                        pay_code: '',
                        pay_name: '',
                        take_time: ''
                    }
                    addOrder(params).then((res) => {
                        if (res.data.code == 200) {

                            this.dialogVisible = true
                        }
                    })
                }

            });
            // this.CreatOrderInfo()

        },

        checkOutAddress() {
            window.location = '/buyerCenter/address'

        },

        //产品详情
        async GoodsInfo(userId, goods_id) {

            let params = { goods_id: goods_id }
            getGoodsInfoById(params).then((res) => {
                this.info = res.data.data.goods_info
                this.newImg = this.info.goodsImages[0]
                console.log(this.info, '222');
                // this.info.goods_service = res.data.data.service
                console.log(this.info.goodsImages[0], 'djdjdjdj');
                // new QRCode(this.$refs.qrcode, {
                //     text: 'weixin://wxpay/bizpayurl?pr=psf2eBAz1',
                //     width: 200,
                //     height: 200
                // });
            })
            // let res = await this.$http('/mini/ApiConnect/GoodsInfo', params)

            // this.checkOutImgList = res.result.goods_images
            // this.goods_id = res.result.goods_id

        },

        //购物车结算
        async SettleAccounts() {
            let userId = window.localStorage.getItem('user_id')

            let res = await this.$http('/mini/ApiConnect/SettleAccounts', { user_id: userId })
            this.goodslist = res.goodslist
            this.allprices = res.allprices
            this.allnumber = res.allnumber
            this.address = res.address
        },

        //修改默认地址
        async UserAddressDefault(addressId, adress, phone, perpeo) {
            let userId = window.localStorage.getItem('user_id')
            let res = await this.$http('/mini/ApiConnect/UserAddressDefault', { user_id: userId, address_id: addressId })
            if (res.status == 1) {
                window.location = '/buyOrder?addressId=' + e.address_id + '&goods_id=' + this.goods_id + "&address=" + e.address + '&phone=' + e.phone + '&perpeo=' + e.perpeo + '&region=' + e.region
            }
        },

        //购物车提交订单
        async CreatOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                type: 'buynow',
                action: 'submit_order',
                ship_type: 1,
                pay_code: 'weixin',
                address_id: this.addressId,
                goods_id: this.goods_id,
                goods_num: 1
            }
            let res = await this.$http('/mini/ApiConnect/CreatOrderInfo', params)

            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                setTimeout(() => {
                    window.location = '/buyerCenter/myOrder'

                }, 1000)
            } else {
                this.$message.error(res.msg);
            }

        },

        //地址下拉列表
        async UserRegionList() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList')

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options.push(obj)
            })
        },

        async UserRegionList2() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value })

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options2.push(obj)
            })
        },

        async UserRegionList3() {
            let res = await this.$http('/mini/ApiConnect/UserRegionList', { region_id: this.value2 })

            res.result.forEach((e, index) => {
                let obj = {}
                obj.value = e.id
                obj.label = e.name
                this.options3.push(obj)
            })
        },

        //获取地址
        async UserAddressList() {
            // let userId = window.localStorage.getItem('user_id')
            // let res = await this.$http('/mini/ApiConnect/UserAddressList', { user_id: userId })
            // res.result.forEach((e, index) => {
            //     let obj = {}
            //     obj.perpeo = e.consignee
            //     obj.region = e.region
            //     obj.address = e.address
            //     obj.code = e.zipcode
            //     obj.phone = e.mobile
            //     obj.address_id = e.address_id
            //     this.tableData.push(obj)
            //     console.log(this.tableData);
            // })
            let user_id = window.localStorage.getItem('user_id')
            getUserAddressByUserId({ user_id }).then((res) => {
                res.data.data.forEach((e, index) => {
                    let obj = {}
                    obj.consignee = e.consignee
                    obj.province = e.province
                    obj.address = e.address
                    obj.zipCode = e.zipCode
                    obj.mobile = e.mobile
                    obj.address_id = e.addressId
                    obj.city_id = e.city_id
                    obj.province_id = e.province_id
                    obj.twOn_id = e.twOn_id
                    obj.district_id = e.district_id
                    this.tableData.push(obj)

                })
                this.address = this.tableData[0].address
                this.mobile = this.tableData[0].mobile
                this.consignee = this.tableData[0].consignee
            })
        },
    },

    mounted() {
        this.dialogVisible = false
        let userId = window.localStorage.getItem('user_id')
        this.goods_id = getParams().goods_id
        this.addressId = getParams().addressId

        this.address = decodeURI(getParams().address)
        this.phone = decodeURI(getParams().phone)
        this.perpeo = decodeURI(getParams().perpeo)
        this.region = decodeURI(getParams().region)

        // this.UserRegionList()
        // this.SettleAccounts()
        // this.UserAddressList()
        this.GoodsInfo(userId, this.goods_id)
    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.bigBox {
    background-color: white;
}

::v-deep .content1 {
    background-color: white;
}

.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.commodityBox {
    margin-top: 18px;
    display: flex;

    .collectBox {
        width: 80px;
        height: 25px;
        color: white;
        text-align: center;
        background-color: #006EB7;
        line-height: 25px;
        margin-top: 10px;
        cursor: pointer;
    }

    .right {
        margin-left: 23px;

        .commodityTitle {
            font-weight: 400;
            font-size: 18px;
            font-weight: 900;
            line-height: 23.4px;
            text-align: left;
            color: #000;
            margin-top: 8px
        }

        .price {
            margin-top: 17px;
        }

        .serve {
            font-size: 16px;
            color: #898989;
            margin-top: 10px;
        }

        .btnBox {
            display: flex;

            .btn1 {
                width: 200px;
                height: 64px;
                border-radius: 4px;
                background: #006eb7;
                text-align: center;
                line-height: 64px;
                color: white;
                font-size: 24px;
                margin-right: 6px;
                margin-top: 30px;
            }
        }

        .checkOutImg {
            display: flex;
            align-items: center;
            margin-top: 18px;

            .box {
                width: 82px;
                height: 82px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .box :last-child {
                margin-right: 0px;

            }

            .imgBorder {
                border: 5px solid #006EB7;
                box-sizing: border-box;
            }
        }

        .numBox {
            margin-top: 10px;
            display: flex;
            align-items: center;

            .numBtn {
                margin-left: 20px;
                display: flex;
                align-items: center;

                .min {
                    width: 30px;
                    height: 30px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 30px;
                    color: white;
                }

                .max {
                    width: 30px;
                    height: 30px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 30px;
                    color: white;
                }

                .put {
                    height: 30px;
                    width: 60px;
                }

                ::v-deep .el-input__inner {
                    height: 30px !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    text-align: center;
                }
            }

        }
    }
}

.orderInfo {
    margin-top: 50px;

    .orderTitle {
        padding-left: 10px;
        border-left: 3px solid #006EB7;
    }

    .putBox {
        .line1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 30px;

            .put1 {
                height: 40px;
                width: 48%;
            }

            ::v-deep .el-input__inner {
                height: 40px !important;
            }
        }

        .btnBox {
            width: 100px;
            height: 40px;
            background-color: #006EB7;
            color: white;
            text-align: center;
            line-height: 40px;
            margin-top: 30px;
            cursor: pointer;
        }

    }
}

.btnBgc2 {
    background-color: #a8a8a8 !important;
}
</style>