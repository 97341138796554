<template>
    <div>

        <el-dialog title="" :visible.sync="dialogVisible" width="450px" :before-close="handleClose" top="20vh" center
            style="border-radius: 10px;">
            <div
                style="width: 100%;text-align: left;font-weight: 900;font-weight: 400;font-size: 18px;color: #613C14;padding: 20px 0px;box-sizing: border-box;border-bottom: 1px solid #D8D8D8;">
                温馨提示
            </div>
            <div
                style="padding: 30px 0px 26px 0px;font-weight: 400;font-size: 16px;color: #613C14;box-sizing: border-box;text-align: center;">
                {{ msg }}
            </div>


            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="freeVip">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            msg: 'caozuo',
            routerPath: null,
        }
    },
    // props: ['msg'],
    methods: {
        handleClose() {
            this.dialogVisible = false
        },
        freeVip() {
            this.dialogVisible = false
            console.log(this.routerPath, 'this.routerPath');
            console.log(this.routerPath != null, 'this.routerPath !== null');
            if (this.routerPath != null) {
                console.log('zou zhe1111111111 ',);
                this.$router.push({
                    path: this.routerPath
                })
            } else {
                console.log('zou zhe ',);
            }
        }
    },
    created() { },
    mounted() { }
}
</script>

<style lang=scss scoped>
::v-deep .el-dialog {
    border-radius: 12px;
}

::v-deep .el-dialog__header {
    padding: 0px !important;
}

::v-deep .el-dialog__body {
    padding: 0px 20px !important;
    // border-radius: 10px;
}
</style>