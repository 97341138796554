<template>
    <!-- $route.name=='quotedPrice'?'top:-15px':$route.name=='encyclopedia'?'top:7px':'top:-5px' -->
    <div class="content112" ref="content" @mouseleave.stop="clickClassify2" style="height: 365px" :style="$route.name == 'index'
        ? 'top:-5px'
        : $route.name == 'quotedPrice'
            ? 'top:-15px'
            : 'top:7px'
        ">
        <div class="classifyBox" v-if="moreFlag == 0">
            <div class="classify1" @click="pushprice(item)" v-for="(item, index) in classifyList5"
                @mouseenter.stop="clickClassify(item.gId)">
                <div>
                    <div style="
                            height: 20px;
                            margin-top: 9px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        ">
                        <div style="display: flex">
                            <!-- <i class="iconfont" :class="iconArr[index]" v-if="index != 2 && index != 3 && index != 4"></i> -->
                            <!-- <el-image :src="item.gImage" style="
                                    width: 18px;
                                    height: 20px;
                                    transform: translateY(21px);
                                "></el-image> -->
                            <div style="margin-left: 10px; font-size: 14px">
                                {{ item.label }}
                            </div>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="valU oneO ov1">
                        <!-- <div v-for="(e, j) in item.children" :key="e.id">
                            {{ e.label
                            }}
                            <span v-if="j !== item.children.length - 1">/</span>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="classify1" v-if="classifyList.length > 13" @click.stop="more">
                更多
            </div>
        </div>

        <div class="classifyBox2" v-if="moreFlag == 1">
            <div class="classify" v-for="(item, index) in classifyList" @click.stop="clickClassify(item.id)">
                {{ item.name }}
            </div>

            <div class="classify" v-if="classifyList.length >= 13" @click.stop="more">
                收起
            </div>
        </div>
        <!--  -->
        <!-- v-if="twoClassify == 1 " -->
        <div class="twoClassify animate__animated animate__fadeInRight" v-if="twoClassify == 1">
            <div class="twoClassifyBox" v-for="(item, index) in classifyList2">
                <div class="one">
                    <div class="shu"></div>
                    <span class="oneTxt" style="cursor: pointer;" @click="twoList(item, index)">{{ item.label }}</span>
                </div>
                <div class="line"></div>
                <div class="two">
                    <div class="son" v-for="(item2, index2) in item.child"
                        @click.stop="goQuotedPrice(item, item2, index)">
                        <!-- @click.stop="skipTodayQuotedPrice(item.parent_id, item2.parent_id, item2.id, item2.name)" -->
                        {{ item2.gName }}
                    </div>
                </div>
            </div>

            <div v-if="classifyList2.length < 1">
                <el-empty description="无数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/login/icon/font_4210042_jr5y4vpg6/iconfont.css'

import { getGoodsIndustryAdvisory, getAllRawMaterial } from '@/api/user'
export default {
    props: ['productType'],
    data() {
        return {
            classifyList: [],
            classifyList5: [],
            classifyList2: [],
            twoClassify: 0,
            moreFlag: 0,
            classifyList6: [],
            newId: ''
        }
    },

    methods: {
        twoList(item) {
            const { href } = this.$router.resolve({
                name: "quotedPrice",
                query: {
                    cat_one: this.newId,
                    cat_two: item.gId,
                    label: item.label
                },
            });
            window.open(href, "_blank");
            // this.classifyList5.filter((item) => {

            // })
            // this.$router.push(
            //     {
            //         path: '/quotedPrice',
            //         query: {
            // cat_one: this.newId,
            // cat_two: item.gId,
            // label: item.label
            //         }
            //     }
            // )
        },
        pushprice(item) {
            const { href } = this.$router.resolve({
                name: "quotedPrice",
                query: { cat_one: item.gId, label: item.label },
            });
            window.open(href, "_blank");
            // this.$router.push(
            //     {
            //         path: '/quotedPrice',
            //         query: {
            //             cat_one: item.gId,
            //             label: item.label
            //         }
            //     }
            // )
        },
        ifGetWhy(params) {

            // if (params === '原材料商城') {
            //     getAllRawMaterial().then((res) => {
            //         console.log(res, 'wddd ')
            //         this.classifyList5 = res.data.data.slice(0, 13)
            //         console.log(this.classifyList5, ' this.classifyList5')
            //     })
            //     this.$router.push({ path: '/store' })
            // } else if (params === '主页') {
            //     this.GoodscCateList()
            //     this.$router.push({ path: '/' })
            // }
        },
        ce() {
        },
        goQuotedPrice(item, item2, i) {
            console.log(this.classifyList2, 'this.classifyList');
            let cat_id_1 = this.classifyList2[i].gId
            let goodNameOne = this.classifyList2[i].gName
            let cat_id_2 = item.gId
            let cat_id_3 = item2.gId
            let goodName = item2.gName
            this.$router.push({
                path: '/quotedPrice',
                query: { cat_id_1, cat_id_2, cat_id_3, goodNameOne, goodName }
            })
        },
        clickClassify(id) {
            this.newId = id
            let obj = this.$refs.content

            let newArray = this.classifyList5.filter((item) => {
                return item.gId == id
            })
            console.log(newArray, 'newArray');
            this.classifyList2 = newArray[0].children
            if (this.twoClassify == 0) {
                this.twoClassify = 1
                obj.style.width = '1030px'
            }
            if (this.productType == 2) {
                this.RawMaterialCateList(id)
            } else {
                // this.GoodscCateList(id)
            }
        },

        clickClassify2() {
            let obj = this.$refs.content

            this.twoClassify = 0
            obj.style.width = '190px'

            this.moreFlag = 0
        },

        more() {
            if (this.moreFlag == 0) {
                this.moreFlag = 1
            } else {
                this.moreFlag = 0
            }
        },
        skipTodayQuotedPrice(cat_one, cat_two, cat_three, goodName) {
            if (this.productType == 2) {
                window.location =
                    '/store?cat_one=' +
                    cat_one +
                    '&cat_two=' +
                    cat_two +
                    '&cat_three=' +
                    cat_three +
                    '&goodName=' +
                    goodName
            } else {
                window.location =
                    '/quotedPrice?cat_one=' +
                    cat_one +
                    '&cat_two=' +
                    cat_two +
                    '&cat_three=' +
                    cat_three +
                    '&goodName=' +
                    goodName
            }
        },
        //严选商品分类
        GoodscCateList(id) {
            getGoodsIndustryAdvisory().then((res) => {
                this.classifyList5 = res.data.data.slice(0, 100)
            })
            // let res = await this.$http('/mini/ApiConnect/GoodscCateList')
            // this.classifyList = res.result
            // this.classifyList5 = res.result.slice(0, 13)

            // if (id) {

            //     let classifyList2 = []
            //     res.result.forEach((e, index) => {
            //         if (e.son) {
            //             e.son.forEach((e, index) => {

            //                 if (e.parent_id == id) {
            //                     classifyList2.push(e)

            //                 }
            //             })
            //         }
            //     })

            //     this.classifyList2 = classifyList2
            // }
        }
        ,
        //原材料商品分类
        RawMaterialCateList(id) {
            getAllRawMaterial().then((res) => {
                this.classifyList5 = res.data.data
            })
            // let res = await this.$http('/mini/ApiConnect/RawMaterialCateList')
            // this.classifyList = res.result
            // this.classifyList5 = res.result.slice(0, 13)

            // if (id) {
            //     let classifyList2 = []
            //     res.result.forEach((e, index) => {
            //         if (e.son) {
            //             e.son.forEach((e, index) => {
            //                 if (e.parent_id == id) {
            //                     classifyList2.push(e)
            //                 }
            //             })
            //         }

            //     })
            //     this.classifyList2 = classifyList2
            // }
        },
    },

    mounted() {
        if (this.$route.path == '/') {
            this.GoodscCateList()
        } else if (this.$route.path == '/store') {
            this.RawMaterialCateList()
        }

        // if (this.productType == 2) {
        //     this.RawMaterialCateList()
        // } else {
        //     this.GoodscCateList()
        // }
        // let that = this
        // document.onclick = function () {
        //     that.twoClassify = 0
        //     that.moreFlag = 0
        // }
    }
}
</script>
<style lang='scss'>
.oneO {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.valU {
    color: #9e9e9e;
    font-size: 10px;
    font-weight: 400;
    width: 150px;
    // margin-left: 20px;
    box-sizing: border-box;
    padding-left: 30px;
    height: 26px;
    line-height: 26px;
    text-align: left;
    display: flex;
    align-items: center;

    >div {
        all: initial;
        box-sizing: border-box;

        height: 26px;
        line-height: 26px;
        color: #9e9e9e;
        font-size: 10px;
        font-weight: 400;
    }
}

.content112 {
    // height: 365px;
    width: 190px;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0;
    box-sizing: border-box;
    align-content: flex-start;
    position: absolute;
    z-index: 299;
    top: -5px;

    .classifyBox {
        height: 365px;
        width: 190px;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        background-color: white;
        // padding: 0 5px;

        // padding: 25px 0;
        padding-right: 0;
        box-sizing: border-box;
        align-content: flex-start;
        position: relative;
        overflow-y: scroll;
        -ms-overflow-style: none;
        /* IE 10+ */
        -ms-overflow-style: none;
        /* IE 10+ */
    }

    ::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
    }

    .classifyBox2 {
        // height: 365px;
        width: 190px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        padding: 24px 0;
        padding-right: 0;
        padding-bottom: 0;
        box-sizing: border-box;
        align-content: flex-start;
        position: relative;
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .classify1 {
        width: 99%;
        height: 37px;
        line-height: 61px;
        text-align: center;
        border: 1px solid #eee;
        cursor: pointer;
        font-size: 16px;
        padding: 0 10px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;

        .iconfont {
            font-size: 18px;
        }
    }

    .classify1:hover {
        background-color: rgb(34, 134, 255);
        color: #fff;
    }

    .classify {
        width: 62px;
        height: 20px;
        margin-bottom: 28px;
        margin-left: 21px;
        text-align: left;
        cursor: pointer;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
    }
}

.twoClassify {
    width: 810px;
    height: 365px;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 5px;
    left: 189px;
    z-index: 999;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    padding: 25px 18px;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;

    .one {
        // width: 100%;
        // width: 75px;
        height: 20px;
        // margin-bottom: 28px;
        text-align: left;
        // border-right: 1px solid black;
        // padding-right: 15px;
        // border-bottom: 1px solid #000;
    }

    .twoClassifyBox {
        // display: flex;
        // flex-direction: row;

        margin-bottom: 17px;

        .line {
            border: 0.5px solid #eaeaea;
            margin: 5px 0;
        }

        .one {
            width: 137px;
            display: flex;
            font-weight: 700;
            align-items: center;
            color: #000;
            // color: #006EB7;1

            .shu {
                width: 2px;
                height: 15px;
                background-color: #1a6590;
                margin-right: 5px;
            }
        }

        .oneTxt {
            width: 380px;
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            // text-overflow: ellipsis;
            white-space: nowrap;
        }

        .two {
            width: 80%;
            display: flex;
            flex-wrap: wrap;

            .son {
                cursor: pointer;
                margin-left: 5px;
                margin-bottom: 10px;
                opacity: 1;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                font-family: 'Microsoft YaHei UI';
                text-align: left;
                border-right: 1px solid #eaeaea;
                padding-right: 10px;
            }

            .son:hover {
                color: #e22a36;
            }
        }
    }
}

.animate__fadeInRight {
    --animate-duration: 0.3s;
}
</style>