<template>
    <div style="background-color: white;padding: 10px;margin-left: 12px;margin-top: 12px;" @click="cancel">
        <el-button type="primary" @click="pulish('ruleFormRef')"
            style="margin-top: 20px;margin-bottom: 10px;">发布视频</el-button>
        <div style="position: relative;width:100%" v-show="!dialogFormVisible && !dialogVisible">
            <div style="position: absolute;top:3px;right: 130px;z-index:9999;width: 155px;">
                <el-select v-model="one_industry" filterable placeholder="行业分类" size="small" @change="classifyChange">
                    <el-option v-for="item in classifyList3" :key="item.gId" :label="item.label" :value="item.gId">
                    </el-option>
                </el-select>

            </div>
            <div style="position: absolute;top: 3px;right: 20px;z-index:9999999;width: 100px;">
                <el-select v-model="time_sort" filterable placeholder="时间排序" size="small" @change="classifyChange2">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>

            </div>
        </div>
        <div style="width: 100%;height:942px">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部视频" name="first">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="(item, index) in  form.videoList ">
                            <div class="video-left">
                                <img :src="item.vImgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px;border-radius: 4px"
                                    alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.vTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.playNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.vAddTime }}
                                </div>
                                <div class="middle-four">
                                    <!-- <span style="color: gray;">审核状态：</span> -->
                                    <span v-if="item.vStatus == '0'" style="color: red;">未审核</span>
                                    <span v-else-if="item.vStatus == '1'" style="color: green;">通过</span>
                                    <span v-else-if="item.vStatus == '2'" style="color: red;">未通过</span>
                                    <span style="margin-left: 5px;" v-show="item.isPutOn == 0">私密</span>
                                    <span style="margin-left: 5px;" v-show="item.isPutOn == 1">公开</span>
                                </div>
                            </div>
                            <div class="video-right">
                                <div style="margin-top: 40px;text-align: center;position: relative;">
                                    <div style="width: 300px;height: 170px;background-color: white;position: absolute;bottom: -184px;z-index: 9999;padding: 5px;box-sizing: border-box;box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);border-radius: 14px;  "
                                        v-show="shareList[index]">
                                        <div style="width: 100%;height: 80%;padding: 5px;box-sizing: border-box;">
                                            <img :src="item.vImgUrl" alt=""
                                                style="width: 100%;height: 100%;object-fit: cover;border-radius: 5px;">
                                            <div
                                                style="position: absolute;bottom: 46px;color: white;left: 18px;font-weight: 900;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                {{
        item.vTitle }}</div>
                                        </div>
                                        <div style="height: 20%;width: 100%;display: flex">
                                            <div
                                                style="width: 80% ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;line-height: 32px;">
                                                https://www.zhnl.com/vudio?v_id={{ item.vId }}
                                            </div>
                                            <div style="width: 20%;background-color:#F1F3F7;line-height: 32px;border-radius: 12px;cursor: pointer;"
                                                @click="transmit(item.vId)">
                                                复制
                                            </div>
                                        </div>
                                    </div>
                                    <el-button type="primary" size="mini" @click="putOn2(item)"
                                        v-if="item.vStatus == 1">查看</el-button>
                                    <el-button type="primary" size="mini" @click.stop="share(index)"
                                        v-if="item.vStatus == 1">分享</el-button>

                                    <el-button type="primary" size="mini" @click="putOn1(item, 1)"
                                        v-if="item.isPutOn == 0">上架</el-button>

                                    <el-button type="primary" size="mini" @click="putOn1(item, 0)"
                                        v-if="item.isPutOn == 1">下架</el-button>
                                    <el-button type="primary" size="mini" @click="putOn3(item.vId)">数据</el-button>
                                    <el-button type="danger" size="mini" @click="del(item)">删除</el-button>


                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="未审核" name="second">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList ">
                            <div class="video-left">
                                <img :src="item.vImgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.vTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.playNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.vAddTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" size="mini" @click="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已通过" name="third">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList " @click="goVideo(item)"
                            style="cursor: pointer">
                            <div class="video-left">
                                <img :src="item.vImgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.vTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.playNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.vAddTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" size="mini" @click="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="未通过" name="fourth">
                    <div style="width: 100%;">
                        <div class="video-item" v-for="item in  form.videoList ">
                            <div class="video-left">
                                <img :src="item.vImgUrl"
                                    style="height: 96%;width: 100%;object-fit: cover;border-radius: 4px" alt="">
                            </div>
                            <div class="video-middle">
                                <div class="middle-one">{{ item.vTitle }}</div>
                                <div class="middle-two">
                                    <div class="two-box"> <img src="../assets/newImg/容器@1x.png" alt=""></div>
                                    <div class="two-box">{{ item.playNum }}</div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/容器@1x (1).png" alt="">
                                    </div>
                                    <div class="two-box">
                                        {{ item.commentNum }}
                                    </div>
                                    <div class="two-box" style="margin-left: 13px">
                                        <img src="../assets/newImg/路径@1x (3).png" alt="">
                                    </div>
                                    <div class="two-box">{{ item.likeNum }}</div>
                                </div>
                                <div class="middle-three">
                                    {{ item.vAddTime }}
                                </div>

                            </div>
                            <div class="video-right">
                                <div style="text-align: center;margin-top: 50px;">
                                    <el-button type="danger" size="mini" @click="del(item)">删除</el-button>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>


            </el-tabs>
            <div style="width: 100%;">

                <el-pagination v-model:current-page="form.page" v-model:page-size="form.size"
                    style="margin-left: 30%;margin-top: 30px;" :page-sizes="[3, 6]" :page-size="6"
                    layout="total, sizes, prev, pager, next, jumper" :total="form.total" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
            </div>
        </div>
        <!-- <el-table :data="form.videoList" style="width: 105%;margin-top: 20px;">
            <el-table-column type="index">
            </el-table-column>
            <el-table-column prop="vIndustry" label="一级行业分类" width="160px" />
            <el-table-column prop="vIndustry2" label="二级行业分类" width="120px" />
            <el-table-column prop="catOneName" label="产品名称" />



            <el-table-column prop="vTitle" label="视频标题" />
            <el-table-column label="视频描述" width="130" prop="vDesc">
                <template #default="scope">
                    <div style="cursor: pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                        @click="showCon(scope.row.vDesc)">
                        {{ scope.row.vDesc }}...
                        <span style="text-overflow: ellipsis"> </span>

                    </div>
                </template>
</el-table-column>
<el-table-column label="视频图片" width="200">
    <template #default="scope">
                    <div>
                        <img :src="scope.row.vImgUrl" alt="" style="width: 200px;height: 150px;">
                    </div>
                </template>
</el-table-column>

<el-table-column prop="vAddTime" label="发布时间" width="105" />
<el-table-column label="审核结果">
    <template #default="scope">
                    <div>
                        <span v-if="scope.row.vStatus == '0'" style="color: red;">未审核</span>
                        <span v-else-if="scope.row.vStatus == '1'" style="color: green;">通过</span>
                        <span v-else-if="scope.row.vStatus == '2'" style="color: red;">未通过</span>
                    </div>
                </template>
</el-table-column>
<el-table-column prop="vReason" label="未通过原因" width="105" />

<el-table-column label="操作" width="110">
    <template #default="scope">
                    <div>


                        <el-button type="danger" size="small" @click="del(scope.row)">
                            删除视频
                        </el-button>
                    </div>
                </template>
</el-table-column>
</el-table> -->
        <!-- <div style="width: 100%;">

            <el-pagination v-model:current-page="form.page" v-model:page-size="form.size"
                style="margin-left: 30%;margin-top: 30px;" :page-sizes="[2, 4, 6]"
                layout="total, sizes, prev, pager, next, jumper" :total="form.total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div> -->
        <el-dialog title="是否删除视频" :visible.sync="dialogVisible" v-model="dialogVisible" width="36%" center>
            <!-- <div>是否确定删除</div> -->
            <span slot="footer" class="dialog-footer" center>
                <div style="text-align: center;">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisibleComfire">确 定</el-button>
                </div>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogFormVisible2" title="发布视频" width="500" style="z-index: 99999999999;" top="20">
            <el-form :model="form" ref="ruleFormRef" :rules="rules">
                <el-form-item label="所属一级行业" label-width="130px" required prop="industry">
                    <el-select v-model="form.industry3" placeholder="Select" size="large" style="width: 240px" multiple
                        @change="select6(e)" :popper-append-to-body="false">
                        <el-option v-for=" item  in  form.product_list4 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>


                <el-form-item label="所属二级行业" label-width="130px">
                    <el-select v-model="form.industry4" placeholder="Select" size="large" style="width: 240px" multiple
                        :popper-append-to-body="false">
                        <el-option v-for=" item  in  form.product_list5 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>


            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="noVideo('ruleFormRef')">取消</el-button>
                    <el-button type="primary" @click="sentProduction2('ruleFormRef')">
                        修改
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogFormVisible" title="发布视频" width="500" top="10px">
            <el-form :model="form" ref="ruleFormRef" :rules="rules">
                <el-form-item label="标题名称" label-width="130px" prop="v_title">
                    <el-input v-model="form.v_title" autocomplete="off" required />
                </el-form-item>
                <el-form-item label="视频描述" label-width="130px" prop="v_desc">
                    <el-input v-model="form.v_desc" autocomplete="off" type="textarea" required maxlength="300"
                        show-word-limit :rows="5" />
                </el-form-item>
                <!-- <el-form-item label="视频标签" label-width="130px" prop="v_label" required>
                    <el-input v-model="form.v_label" autocomplete="off" />
                </el-form-item> -->
                <el-form-item label="一级分类" label-width="130px" prop="cat_one" required>
                    <el-select v-model="form.cat_one" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false" @change="select1">
                        <el-option v-for=" item  in  form.product_list1 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="二级分类" label-width="130px" prop="cat_two" required>
                    <el-select v-model="form.cat_two" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false" @change="select2">
                        <el-option v-for=" item  in  form.product_list2 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="三级分类" label-width="130px" prop="cat_three" required>
                    <el-select v-model="form.cat_three" placeholder="Select" size="large" style="width: 240px"
                        :popper-append-to-body="false">
                        <el-option v-for=" item  in  form.product_list3 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属一级行业" label-width="130px" required prop="industry">
                    <el-select v-model="form.industry" placeholder="Select" size="large" style="width: 240px" multiple
                        @change="select6(e)" :popper-append-to-body="false" @remove-tag="removeTag">
                        <el-option v-for=" item  in  classifyList2" :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>


                <el-form-item label="所属二级行业" label-width="130px">
                    <el-select v-model="form.industry2" placeholder="Select" size="large" style="width: 240px" multiple
                        :popper-append-to-body="false">
                        <el-option v-for=" item  in  form.product_list5 " :key="item.gId" :label="item.label"
                            :value="item.gId" />
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="选择产品" label-width="130px" required prop="goods_id">
                    <el-select v-model="form.goods_id" placeholder="请选择产品" @change="select5">
                        <el-option v-for="item in form.productionList" :key="item.goodsId" :label="item.goodsName"
                            :value="item.goodsId" />
                    </el-select>
                </el-form-item> -->
                <el-form-item label="上传视频" label-width="130px" required>
                    <el-upload class="upload-demo" action="https://www.zhnl.com/zhnl/breakPointUpload" :limit="1"
                        :file-list="form.fileList" :headers="headerObj" :on-success="handleSuccess"
                        :on-exceed="handleExceed" :on-progress="onProgressTest" accept=".mp4"
                        :before-upload="beforeUpload">
                        <el-button type="primary">点击上传</el-button>
                        <div class="el-upload__tip" slot="tip">只能上传mp4文件，且不超过100M</div>
                    </el-upload>
                </el-form-item>

            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="noVideo('ruleFormRef')">取消</el-button>
                    <el-button type="primary" @click="sentProduction('ruleFormRef')">
                        发布
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible2" title="视频描述" width="500">
            <span>{{ form.vDesc }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getAllVodById, getGoodsIndustryAdvisory, getGoodsIndustryByPid, addVideo, delVideo, updateQuotByQId, getVideoAudit, updateVodIsPutOnById } from '@/api/user.js'

import Vue from 'vue'

export default {
    data() {
        return {
            options: [
                { label: '正序', value: 0 },
                { label: '倒序', value: 1 },
            ],
            shareList: [],
            time_sort: 1,
            one_industry: '',
            classifyList: [],
            classifyList2: [],
            classifyList3: [],
            v_status: null,
            currentPage1: 6,
            activeName: 'first',
            v_id: '',
            taskId: '',
            vId: '',
            dialogVisible2: false,
            form: {
                total: 0,
                page: 1,
                size: 6,
                userId: '',
                v_title: '',
                v_desc: '',
                v_label: '',
                v_video_url: '',
                v_img_url: false,
                goods_id: '',
                cat_one: '',
                cat_two: '',
                cat_three: '',
                industry2: '',
                industry: [],
                industry3: [],
                industry4: [],
                videoList: [],
                product_list1: [],
                product_list2: [],
                product_list3: [],
                product_list4: [],
                product_list5: [],
                fileList: [],
            },
            fileList: [],
            dialogVisible: false,
            dialogFormVisible: false,
            dialogFormVisible2: false,

            headerObj: {
                token: window.localStorage.getItem('token'),
            },
            rules: {
                v_title: [
                    {
                        required: true,
                        message: '请填写标题名称',
                        trigger: 'change',
                    },
                ],
                v_desc: [
                    {
                        required: true,
                        message: '请填写视频描述',
                        trigger: 'change',
                    },
                ],
                v_label: [
                    {

                        required: true,
                        message: '请填写视频标签',
                        trigger: 'change',
                    },
                ],
                goods_id: [
                    {

                        required: true,
                        message: '请选择产品',
                        trigger: 'change',
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: '请选择一级行业分类',
                        trigger: 'change',
                    },
                ],

                // industry2: [
                //     {

                //         required: true,
                //         message: '请选择二级行业分类',
                //         trigger: 'change',
                //     },
                // ],
                cat_one: [
                    {

                        required: true,
                        message: '请选择产品一级分类',
                        trigger: 'change',
                    },
                ],
                cat_two: [
                    {

                        required: true,
                        message: '请选择产品二级分类',
                        trigger: 'change',
                    },
                ],
                cat_three: [
                    {

                        required: true,
                        message: '请选择产品三级分类',
                        trigger: 'change',
                    },
                ],
            },

        }
    },
    methods: {
        cancel() {
            this.shareList.forEach((item, index) => {
                Vue.set(this.shareList, index, false);

            })
            console.log('ddd');
        },
        transmit(vId) {
            this.currentUrl = 'https://www.zhnl.com/vudio?v_id=' + vId
            navigator.clipboard.writeText(this.currentUrl);
            this.$message({
                message: '已成功复制到粘贴板',
                type: 'success',
                offset: 130,
            })
        },
        share(index) {
            console.log(index, 'index--');
            this.shareList.forEach((item, index) => {
                Vue.set(this.shareList, index, false);

            })
            Vue.set(this.shareList, index, true);

            // this.shareList[index] = true
            console.log(this.shareList[index], 'this.shareList[index]');
        },
        putOn3(v_id) {
            this.$router.push({
                path: '/buyerCenter/dataPage',
                query: {
                    v_id: v_id
                }
            })
        },
        goVideo(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,

                },
            });
            window.open(href, "_blank");
        },
        putOn1(item, is_put_on) {
            console.log(item, 'iye----');
            let idArray = []
            idArray.push(item.vId)
            updateVodIsPutOnById({ is_put_on: is_put_on, v_id: idArray }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    })
                    getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                        this.form.videoList = res.data.data
                        this.form.total = res.data.totalPage

                    })
                }
            })
        },
        putOn2(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,

                },
            });
            window.open(href, "_blank");
        },

        classifyChange(e) {
            console.log(e, 'eeee');
            this.one_industry = e
            getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        classifyChange2(e) {
            console.log(e, 'eeee');
            this.time_sort = e
            getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage

            })
        },
        handleClick(box) {
            console.log(box.index, '----');
            this.form.page = 1;
            this.form.size = 5
            if (box.index == 0) {
                this.v_status = null

            } else if (box.index == 1) {
                this.v_status = 0

            }
            else if (box.index == 2) {
                this.v_status = 1

            }
            else if (box.index == 3) {
                this.v_status = 2

            }
            getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {

                this.form.total = res.data.totalPage
                this.form.videoList = res.data.data
            })
        },
        showCon(row) {
            console.log(row, 'ppp');
            this.dialogVisible2 = true
            this.form.vDesc = row
        },
        edit(row) {
            this.dialogFormVisible2 = true
            this.vId = row.vId
        },
        beforeUpload(file) {
            console.log(file, '123');
            console.log(file.type.includes('mp4'), 'cccccc');
            if (file.type.includes('mp4')) {
                // return ture
            } else {
                this.$message({
                    type: 'warning',
                    message: '只能上传mp4文件'
                })
                return false
            }
        },
        dialogVisibleComfire() {
            delVideo({ v_id: this.v_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    getAllVodById({ page: this.form.page, size: this.form.size, one_industry: this.one_industry, time_sort: this.time_sort, v_status: this.v_status }).then((res) => {
                        this.form.videoList = res.data.data
                        this.form.total = res.data.totalPage
                    })
                    this.dialogVisible = false
                }
            })
        },
        del(row) {
            this.v_id = row.vId
            this.dialogVisible = true
        },
        handleSizeChange(e) {
            this.form.size = e

            getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data

                this.form.total = res.data.totalPage
            })
        },
        handleCurrentChange(val) {
            console.log(val, '----');
            this.form.page = val
            getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                this.form.videoList = res.data.data
                this.form.total = res.data.totalPage
            })
        },
        noVideo(ruleFormRef) {
            this.dialogFormVisible = false
            console.log(this.form.fileList, '-------------');
        },
        handleExceed() {

            this.$message({
                type: 'warning',
                message: '只能上传一条视频'
            })

        },
        onProgressTest(event, file, fileList) {

            let index = 0
            // 只有fileList 跟file 里面的uid是唯一的互相对应的
            fileList.forEach((v, i) => {
                if (v.uid === file.uid) {
                    index = i
                }
            })
            // 获取动态的进度
            let percent = parseInt(event.percent)

            setTimeout(() => {
                console.log('123123');
                if (percent >= 99) {
                    // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
                    let dom = document.getElementsByClassName('el-upload-list__item')[index]
                    let progressText = dom.getElementsByClassName('el-progress__text')
                    if (progressText && progressText.length) {
                        progressText[0].style.opacity = 0
                        progressText[0].innerText = '99%'
                        progressText[0].style.opacity = 1
                    }
                }
            })
        },
        pulish(ruleFormRef) {
            this.$router.push({
                path: '/buyerCenter/contribute',
                query: {
                    video: 1,
                }
            })
            console.log(this.form.fileList, ' this.form.fileList');
            // this.dialogFormVisible = true
            // this.$refs.ruleFormRef.clearValidate();
            // this.form.fileList = []

        },
        sentProduction2() {
            let params = {

            }
            updateQuotByQId

        },
        sentProduction(ruleFormRef) {
            console.log('123', ruleFormRef);
            this.$refs[ruleFormRef].validate((valid) => {
                console.log(valid, '123-----');
                if (valid) {
                    console.log('jinlaile');
                    let params = {

                        title: this.form.v_title,
                        v_desc: this.form.v_desc,
                        v_label: this.form.v_label,
                        video_url: this.form.v_video_url,
                        img_url: this.form.v_img_url,
                        cat_one: this.form.cat_one,
                        cat_two: this.form.cat_two,
                        cat_three: this.form.cat_three,
                        industry: this.form.industry,
                        industry2: this.form.industry2,
                        task_id: this.taskId,
                    }
                    if (this.fileList.length > 0) {

                        addVideo(params).then((res) => {
                            if (res.data.code == 200) {
                                this.fileList = []
                                this.$message({
                                    type: 'success',
                                    message: '上传成功，待审核'
                                })
                                // this.form.fileList = []
                                getAllVodById({ page: this.form.page, size: this.form.size, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
                                    this.form.videoList = res.data.data
                                    this.form.total = res.data.totalPage
                                })
                                this.dialogFormVisible = false
                                this.form.v_title = ''
                                this.form.v_desc = ''
                                this.form.v_label = ''
                                this.form.v_video_url = ''
                                this.form.v_img_url = ''
                                this.form.goods_id = ''
                                this.form.cat_one = ''
                                this.form.cat_two = ''
                                this.form.cat_three = ''
                                this.taskId = ''
                                this.form.industry = ''
                                this.form.industry2 = ''

                                this.$refs.ruleFormRef.clearValidate();
                                // formEl.clearValidate()
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '视频未上传成功'
                        })
                        console.log('error submit!!');
                        return false;
                    }
                }
            });
        },
        handleSuccess(file) {

            if (file.code == 4017) {
                this.$message({
                    type: 'error',
                    message: '登录过期，请重新登录'
                })
                setTimeout(() => {
                    window.location = '/'
                }, 2000)
                localStorage.removeItem('token')
                localStorage.removeItem('user_id')
            } else {
                getVideoAudit({ video_url: file.data.video_url }).then((res) => {
                    this.taskId = res.data.data.taskId
                })
                this.fileList.push(file)
                this.form.v_img_url = file.data.img_url
                this.form.v_video_url = file.data.video_url
            }
        },
        select1(e) {
            this.form.cat_three = ''
            this.form.cat_two = ''

            let newArray = this.form.product_list1.filter((item, index) => {
                return item.gId == e
            })
            this.form.product_list2 = newArray[0].children
        },
        select2(e) {
            this.form.cat_three = ''
            let newArray = this.form.product_list2.filter((item, index) => {
                return item.gId == e
            })
            // let newArray2 = JSON.parse(JSON.stringify(newArray))
            this.form.product_list3 = newArray[0].children
        },
        select6() {
            getGoodsIndustryByPid({ parent_id: this.form.industry }).then((res) => {
                this.form.product_list5 = res.data.data
            })
        },
        removeTag() {
            console.log('有没有');
            this.form.industry2 = []
        },
    },
    created() { },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)'
        });
        // getBusManage({ page: 1, size: 1000 }).then((res) => {
        //     let arrList = res.data.data.filter((item) => {
        //         return item.busStatus == 1
        //     })
        //     if (arrList.length <= 0) {
        //         this.$router.push({ path: "/buyerCenter/application" });
        //         this.$message({
        //             message: '请先申请厂商',
        //             type: 'warning'
        //         });
        //     }
        // })

        getAllVodById({ page: this.form.page, size: this.form.size, v_status: this.v_status, one_industry: this.one_industry, time_sort: this.time_sort }).then((res) => {
            loading.close()
            this.form.videoList = res.data.data
            this.form.total = res.data.totalPage

        })
        // getBstGoodsGategory1().then((res) => {
        //     this.form.product_list1 = res.data.data
        // })
        // getGoodsIndustryAdvisory().then((res) => {

        //     this.classifyList2 = res.data.data

        // })
        getGoodsIndustryAdvisory().then((res) => {

            this.classifyList3 = res.data.data
            this.classifyList3.unshift({ gId: null, label: '不限' })
        })

    }
}
</script>

<style lang=scss scoped>
::v-deep .el-tabs__content {
    height: auto;
    /* 高度自适应内容 */
    overflow: visible !important;
    // background-color: red;
    /* 如果内容太宽，允许水平滚动 */
}

.video-item {
    width: 100%;
    height: 7.2rem;
    padding: 10px 6px;
    display: flex;
    border-bottom: 1px solid #EAEAEA;

    .video-left {
        width: 16%;
        height: 100%;
        padding: 5px 3px;
        border-radius: 4px
    }

    .video-middle {
        width: 59%;
        height: 100%;
        padding: 5px 10px;

        .middle-one {
            color: #333333;
            font-size: 16px;
            padding: 8px 9px;
        }

        .middle-two {
            display: flex;
            padding: 2px 8px;

            .two-box {
                padding: 1px 2px;
                font-size: 14px;
                color: #9E9E9E;
                line-height: 14px;
                // vertical-align: middle
            }
        }

        .middle-three {
            padding: 4px 13px;
            font-size: 14px;
            color: #9E9E9E;
        }

        .middle-four {
            padding: 4px 13px;
            font-size: 14px;
        }
    }

    .video-right {
        width: 31%;
        height: 100%;
    }
}

::v-deep .el-tabs__item {
    color: #9E9E9E;
}

::v-deep.el-tabs__item .is-active {
    color: #409EFF !important;
}
</style>