<template>
    <div>
        <div id="qrcode" style="width: 100px; height: 200px;"></div>
    </div>
</template>

<script>

import QRcode from "qrcodejs2";
export default {
    data() {
        return {}
    },
    methods: {},
    created() { },
    mounted() {
        new QRcode("qrcode", {
            text: "https://www.baidu.com", //需要转换为二维码的内容
            width: 100, //宽
            height: 100,//高
        });
    }
}
</script>

<style lang=scss></style>