<template>
    <div class="content hhu" style="width:228px !important;">
        <img :src="img" style="object-fit: cover; width: 228px; height: 228px;">
        <div class="infoBox" :style="item.type == 2 ? 'height:185px !important;' : 'height:154px'">
            <div class="title">{{ title }}</div>
            <div class="center">
                <div style="display: flex; align-items: center; height: 20px;">
                    <div style="" v-show="item.locationName">
                        <el-tag size="small" effect="dark" style="background-color: #006EB7;height: 22px;">{{
            item.locationName.slice(0, 2) }}</el-tag>
                    </div>
                    <div style="margin-left: 5px;">
                        <div class="" v-if="item.shop_type">
                            <img :src="$img + item.shop_type" alt="" style="width: 69px;height: 22px; "
                                class="imtStyle">
                        </div>
                    </div>
                    <div style="margin-left: 2px;" class="yyt" v-show="item.type != 3"> <img src="@/assets/666.png"
                            style="width: 17px;height: 15px;" alt=""></div>
                    <div v-show="item.type == 3" class="yyr"><img v-if="item.goods_pattern" :src="item.goods_pattern"
                            style="width: 64px;height: 20px;" alt=""> </div>
                </div>
                <div class="text" style="margin-top: 10px;">
                    <div>联系人：{{ item.contactsName || '*' }}</div>
                    <div>联系方式：{{ item.shopMobile || '*' }}</div>
                    <div class="vtitleBox">
                        <!-- <div v-for="ass in filterService" class="ov1">{{ ass.title +':' + (ass.content || '*') }}</div>  -->
                        <div v-for="ass in item.service" style="margin-top: 3px;" v-if="ass.title" class="ov1">{{
            ass.title
            + ':' + (ass.content || '*') }}</div>
                    </div>

                    <!-- <div class="topT">
                        <div v-for="t in item.service" v-if="t.title == '规格'">{{ t.content ? `规格:${t.content}` : '规格:' }}
                        </div>
                        <div v-for="t in item.service" v-if="t.title == '等级'">{{ t.content ? `等级:${t.content}` : '等级:' }}
                        </div>
                    </div>
                    <div class="bottomT">
                        <div v-for="t in item.service" v-if="t.title == '品牌'">{{ t.content ? `品牌:${t.content}` : '品牌:' }}
                        </div>
                        <div v-for="t in item.service" v-if="t.title == '产地'">{{ t.content ? `产地:${t.content}` : '产地:' }}
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="one">
                        <div>¥</div>
                        <div style="font-size: 20px;">{{ price }}</div>
                        <div>/{{ specName }}</div>
                    </div>

                </div>
                <div class="right">
                    <!-- 已浏览 <span style="color: #006EB7;"> {{click_count}}+</span> -->
                    <!-- <el-button type="primary" plain  icon="el-icon-star-off" size="small" round style="width: 56px;height: 20px;">收藏</el-button> -->
                    <!-- <div class="iconS">
                        <i class="el-icon-star-off" v-show="isCollect == 100 ? item.isCollect == 0 : isCollect == 0"
                            @click.stop="setCollectUserGoods(item.goodId)">收藏</i>
                        <i class="el-icon-star-on" v-show="isCollect == 100 ? item.isCollect == 1 : isCollect == 1"
                            @click.stop="setCancelCollectUserGoods(item.goodId)">已收藏</i>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'
import { goodsCollect } from '@/api/user'
export default {
    props: ['img', 'title', 'price', 'specName', 'click_count', 'item', 'type'],
    data() {
        return {
            isCollect: 100,
            isY: false,
            filterServiceArr: [{
                title: '规格'
            },
            {
                title: '等级'
            },
            {
                title: '品牌'
            },
            {
                title: '产地'
            }]
        }
    },
    mounted() {
        console.log(this.item, '....ssss');
    },
    computed: {
        filterService() {
            return this.filterServiceArr.map(item => {
                const data = this.item.service.find(items => items.title == item.title)
                if (data)
                    return { ...item, ...data }
                return item
            })
        },
    },
    created() {
        if (this.$route.name == 'strictStore') {
            this.isY = true
        } else {
            this.isY = false
        }
    },

    methods: {
        collection(goodsId, isval) {

            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                goods_id: goodsId
            }
            if (isval == 1) {
                this.$http('/mini/ApiConnect/setCancelCollectUserGoods', params).then(res => {
                    this.$notify({
                        title: '操作成功',
                        message: res.msg,
                        type: 'success'
                    });
                })

            } else {
                this.$http(' /mini/ApiConnect/setCollectUserGoods', params).then(res => {
                    this.$notify({
                        title: '操作成功',
                        message: res.msg,
                        type: 'success'
                    });
                })
            }


        },
        async setCollectUserGoods(id = null) {
            console.log(id, 'idoooo');
            let params = {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: id,
                type: 3
            }

            goodsCollect(params).then((res) => {
                console.log(res, 'pppp');
                if (res.data.code == 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    })
                    this.$emit('add')
                } else if (res.data.code == 4019) {
                    this.$message({
                        message: '请登录后操作',
                        type: 'error'
                    })
                }
            })
            // let goods_id = null

            // console.log(1);
            // let userId = window.localStorage.getItem('user_id')
            // if (id) {
            //     goods_id = id
            // }
            // if (!goods_id) return this.$message({
            //     message: '收藏失败',
            //     type: 'error'
            // })

            // let params = {
            //     user_id: userId,
            //     goods_id
            // }
            // let res = await this.$http('/mini/ApiConnect/setCollectUserGoods', params)
            // this.isCollect = 1

            // this.$message({
            //     message: res.msg,
            //     type: 'success'
            // });
        },

        //取消收藏商品
        async setCancelCollectUserGoods(goodId = null) {

            let params = {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: goodId,
                type: 3
            }
            goodsCollect(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    })
                    this.$emit('add')
                } else if (res.data.code == 4019) {
                    this.$message({
                        message: '请登录后操作',
                        type: 'error'
                    })
                }
            })
            this.getUserProduct()
            // let goods_id = null

            // let userId = window.localStorage.getItem('user_id')
            // if (id) {
            //     goods_id = id
            // }

            // if (!goods_id) return this.$message({
            //     message: '取消收藏失败',
            //     type: 'error'
            // });
            // let params = {
            //     user_id: userId,
            //     goods_id
            // }
            // let res = await this.$http('/mini/ApiConnect/setCancelCollectUserGoods', params)
            // this.isCollect = 0
            // this.$message({
            //     message: res.msg,
            //     type: 'success'
            // });
        },
    }
}
</script>

<style lang="scss" scoped>
.yyt {
    width: 16px;
    height: 14px;
    border: 1px solid #ece29e;
    line-height: 14px;

}

.yyr {
    // margin-left: 10px;
    border-radius: 5px;
    overflow: hidden;
    width: 64px;
    height: 20px;
    border-radius: 2px;
    // background: #006eb7;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    text-align: center;
    margin-top: 1px;
}


.content {
    width: 228px;
    margin-top: 20px;

    .center {
        margin-top: 10px;

        .text {
            div {
                font-size: 12px;
            }

            .vtitleBox {
                display: flex;
                flex-wrap: wrap;

                >div {
                    flex: 0 0 50%;
                }
            }

            .topT {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;

                >div:nth-child(2) {
                    margin-right: 30px;
                    text-align: left;

                }
            }

            .bottomT {
                display: flex;
                justify-content: space-between;

                >div:nth-child(2) {
                    text-align: left;
                    margin-right: 30px;
                }
            }
        }
    }

    .infoBox {

        transform: translateY(-7px);
        padding: 5px 5px 20px 5px;
        background-color: white;
        box-sizing: border-box;
        height: 142px;
        position: relative;

        .title {
            font-size: 14px;
            font-weight: 700;
            height: 20px;
            overflow: hidden;
        }

        .bottom {
            position: absolute;
            left: 2px;
            bottom: 10px;
            width: 218px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0 5px;
            box-sizing: border-box;
        }

        .left {
            display: flex;
            align-items: flex-end;
            margin-top: 11px;


            .one {
                font-size: 14px;
                font-weight: 700;
                color: #FF0000;
                font-family: 'SourceSansPro';
                display: flex;
                align-items: center;
            }

            .two {
                font-size: 10px;
                font-weight: 700;
                margin-left: 3px;
                color: #FF0000;
            }
        }

        .right {
            font-size: 10px;
            color: #AEAEAE;

            .iconS {
                color: #3c8eb8;
                width: 56px;
                height: 20px;
                border: #3c8eb8 1px solid;
                text-align: center;
                line-height: 20px;
                border-radius: 10px;
            }

            .iconS:hover {
                background-color: #006eb7;
                color: #fff;
            }

        }
    }

    .infoBox:hover {
        color: red;
    }


}
</style>