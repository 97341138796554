<template>
    <!-- <div style="background-image: linear-gradient(to bottom right , rgb(189,216,255), rgb(255,255,254));"> -->
    <!-- <div style="background-image : url('https://video.zhnl.com/2024/07/15/9a620a04a4e34b29a6e9578c244aa4e6e46bf4813a7778f63a6bd2147a46cce.jpg'); 
         background-size: 100% 100%; 
         background-repeat: no-repeat; 
         
        "> -->
    <div @mouseenter="allMouseEnter()">
        <div class="video-content">
            <div
                style="position: absolute;top: 57vh;left: 11.1vw;font-size: 40px;font-weight: 900;color: white;z-index: 999999;">
                {{ nameArray[vIndex] }}
            </div>
            <video autoplay muted :src="showVideo"
                style="object-fit:fill;width: 100vw;height: 100%;opacity: 1;"></video>
        </div>
        <div class="video-all">
            <div class="con">
                <div class="con1"></div>
                <div class="con2"></div>
            </div>
            <div class="video-hot" :style="{ marginTop: marginTop + 'px' }" style="z-index: 999;">
                <div style="height: 17.5rem;width: 100%;display: flex;box-sizing: border-box;" class="video-list0">
                    <div style="height: 2.5rem; color:#227BFF;font-size: 26px;margin-left: 5px;margin-top: 14.6rem;
                    ">热门视频
                    </div>
                    <div style="height: 2.5rem;width: 83%;margin-left: 50px;padding-top: 5px;margin-top: 5rem;">
                        <!-- <dayHot :isP="true" /> -->
                    </div>
                </div>

                <div class="video-list">
                    <div class="video-left">
                        <div class="left-item" v-for="item in videoList" @click="toVideo(item)" :key="item.vId">
                            <div style="width: 100%;height: 75%;position: relative;">
                                <div style="position: absolute;bottom: 5px;right: 8px;font-size: 12px;color: #FFFFFF;">
                                    {{ item.vAddTime.slice(0, 10) }}
                                </div>
                                <img :src="item.vImgUrl"
                                    style="width: 101%;height: 100%;object-fit: cover;border-radius: 12px;" alt="">
                            </div>
                            <div
                                style="padding: 2px 0px 0px 0px;color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 15%;">
                                {{ item.vTitle }}
                            </div>
                            <div style="color: #9499A0;font-size: 13px;padding-top: 1px;height: 10%;">
                                <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                    style="vertical-align: middle;margin-right: 1px;" alt="">

                                <span style="vertical-align: middle;color: #9499A0;font-size: 12px;"> {{ item.vUploader
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="hot-box">
            <div class="left-box">
                <div style="padding-top: 16px;padding-bottom: 10px;cursor: pointer;" @click="goTou">
                    <img src="../assets/newImg2/按钮 投稿@4x.png" style="height: 51px;width: 82%;margin-left: 9%;" alt="">
                </div>
                <div class="left-item" @click="toTargetBox(item, index)"
                    v-for="(item, index) in areaCategoryList3.slice(0, 12)" :class="{ bgc: wzNum == item.gId }">
                    {{ item.label }}
                </div>
            </div>

            <div class="edit" style="padding-top: 23px">
                <div style="height: 2.5rem;display: flex;justify-content: space-between; ">
                    <div style="color:#227BFF;font-size: 26px;margin-left: 5px;">编辑推荐</div>
                    <div style="vertical-align: middle;line-height: 3.1rem;margin-right: 0.5vw;cursor: pointer;">
                        <!-- <img src="../assets/newImg/Vector@1x (3).png" alt="" style="margin-right: 10px;"
                            @click="reduceNumber" v-show="videoNumber <= 1">
                        <img src="../assets/newImg/左箭头 黑.png" alt="" style="margin-right: 10px;"
                            v-show="videoNumber > 1" @click="reduceNumber">
                        <img src="../assets/newImg/Vector@1x (1).png" alt="" @click="addNumber"
                            v-show="videoNumber < allPage">
                        <img src="../assets/newImg/右箭头淡色.png" style="width: 21px;height: 20px;" alt=""
                            v-show="videoNumber >= allPage"> -->

                    </div>
                </div>
                <div class="edit-box">
                    <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
                        <div style="position: relative;">
                            <div style="position: absolute;bottom: 8px;right: 3px;font-size: 12px;color: #FFFFFF;"> {{
        item.vAddTime.slice(0, 10) }}
                            </div>
                            <img :src="item.vImgUrl"
                                style="width: 100%;height: 116px;object-fit: cover;border-radius: 12px;" alt="">
                        </div>
                        <div
                            style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{ item.vTitle }}</div>
                        <div style="color: gray;font-size: 12px;padding-top: 3px;color: #9499A0;">
                            <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                style="vertical-align: middle;margin-right: 1px;" alt="">

                            <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="wz" style="width: 100vw;">
                <hangNewVideo :areaCategoryList2="areaCategoryList2" ref="hangNewVideo" />

                <cateryNewVideo :areaCategoryList2="areaCategoryList" ref="cateryNewVideo" />
            </div>

            <div>

                <div class="video-type" v-for="item in hangYeList">
                    <div style="height: 40px;width: 100%;display: flex;">
                        <div style=" color:#227BFF;font-size: 26px;margin-left: 6px;font-weight: 400;">
                            {{ item.label }}
                        </div>
                        <div style=" color:#227BFF;font-size: 14px;margin-left: 12px;line-height: 36px;font-weight: 290; cursor: pointer;width: 60px;"
                            @click="toVideo">
                            更多 >
                        </div>
                        <div style="width: 70%;margin-left: 20px;">
                            <!-- <dayHot :isP="true" /> -->
                        </div>
                    </div>

                    <div class="item-box">
                        <div class="type-item" v-for="data in item.videoList" @click="toVedio(data)" :key="data.vId"
                            style="cursor: pointer;">
                            <div style="height: 85%;width: 100%;">
                                <img :src="data.vImgUrl" alt="" style="height: 100%;width: 100%;border-radius: 12px;">
                            </div>
                            <div
                                style="color: #333333;font-size: 16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 7%;">
                                {{ data.vTitle }}</div>
                            <div style="color: #9499A0;font-size: 12px;padding-top: 3px;height: 6%;">
                                <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                    style="vertical-align: middle;margin-right: 1px;" alt="">

                                <span style="vertical-align: middle;"> {{ data.vUploader }}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import strictProduct4 from '../components/strictProduct4.vue'
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import { getHotVod, getRecommendVod, getIndustryIsIndexPlay, getGoodsIndustryAdvisory, getBstGoodsGategory1, getIndexPlayVod } from '@/api/user'
import dayHot from '../components/dayHot.vue'
import hangNewVideo from '../components/hangNewVideo.vue'
import cateryNewVideo from '../components/cateryNewVideo.vue'


export default {
    data() {
        return {
            nameArray: [],
            dingTer: '',
            vIndex: 0,
            wzNum: '',
            marginTop: 70,
            videoList: [],
            size: 36,
            page: 1,
            title: '',
            industry: '',
            industry2: '',
            total: 0,
            allPage: 0,
            videoNumber: 1,
            videoList2: [],
            hangYeList: [],
            areaCategoryList2: [],
            areaCategoryList: [],
            areaCategoryList3: [],
            showVideo: '',
            showVideoList: [],
        }
    },
    components: {
        strictProduct4,
        searchBar,
        classifyBar,
        dayHot,
        hangNewVideo,
        cateryNewVideo

    },
    methods: {
        allMouseEnter() {
            this.$emit('oneMouse')
        },
        toTargetBox(e, index) {
            this.$refs.hangNewVideo.numberChange({ gId: e.gId })

            this.wzNum = e.gId
            const targetBox = document.getElementById('wz');
            // this.$refs.strictProduct.classifyTap(index, e.gId)
            if (targetBox) {
                window.scrollTo({
                    top: targetBox.offsetTop - 100,
                    behavior: 'smooth' // 平滑滚动（可选）  
                });
            }
        },
        goTou() {
            const { href } = this.$router.resolve({
                path: "/buyerCenter/contribute",

            });
            window.open(href, "_blank");

        },
        toVedio(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,
                    industry: this.$route.query.cat_id,
                    industry2: this.$route.query.cat_id2,
                },
            });
            window.open(href, "_blank");
        },
        addNumber() {
            if (this.totalPage < 14) {
                return
            }
            this.videoNumber += 1
            let params2 = {
                page: this.videoNumber,
                size: 14,
            }
            getRecommendVod(params2).then((res) => {
                this.videoList2 = res.data.data
            })
        },
        reduceNumber() {
            if (this.videoNumber > 1) {
                this.videoNumber -= 1
                let params2 = {
                    page: this.videoNumber,
                    size: 14,
                }
                getRecommendVod(params2).then((res) => {
                    this.videoList2 = res.data.data
                })
            }
        },
        productInfo(id) {
            this.industry = id
            let params = {
                title: this.title,
                size: this.size,
                page: this.page,
                industry: this.industry,
                industry2: this.industry2,
            }
            getHotVod(params).then((res) => {
                this.videoList = res.data.data
                this.total = res.data.totalPage
            })
        },
        toVideo(row) {
            const { href } = this.$router.resolve({
                path: "/vudio",
                query: {
                    v_id: row.vId,
                }
            });
            window.open(href, "_blank");

            // this.$router.push({
            //     path: '/vudio',
            //     query: {
            //         v_id: row.vId,
            //         industry: this.$route.query.cat_id,
            //         industry2: this.$route.query.cat_id2,
            //     }
            // })
        },
        searchProduct(data) {
            console.log(data, '...sss');
            this.title = data
            let params = {
                title: this.title,
                size: this.size,
                page: this.page,
                industry: this.industry,
                industry2: this.industry2,
            }
            getHotVod(params).then((res) => {
                this.videoList = res.data.data
                this.total = res.data.totalPage

            })

        },
        handleSizeChange(e) {
            this.size = e
            let params = {
                title: this.title,
                size: this.size,
                page: this.page,
                industry: this.industry,
                industry2: this.industry2,
            }
            getHotVod(params).then((res) => {
                this.videoList = res.data.data
                this.total = res.data.totalPage
            })
        },
        handleCurrentChange(val) {
            this.page = val
            let params = {
                title: this.title,
                size: this.size,
                page: this.page,
                industry: this.industry,
                industry2: this.industry2,
            }
            getHotVod(params).then((res) => {
                this.videoList = res.data.data
                this.total = res.data.totalPage
            })
        },
    },
    created() { },

    mounted() {
        getIndexPlayVod({}).then((res) => {
            this.showVideoList = res.data.data.map((item) => {
                return item.vVideoUrl
            })
            this.nameArray = res.data.data.map((item) => {
                return item.vTitle
            })


            this.showVideo = this.showVideoList[0]
            this.total = res.data.totalPage
            let $this = this
            this.dingTer = setInterval(function () {
                if ($this.vIndex < $this.showVideoList.length - 1) {
                    $this.vIndex += 1
                    $this.showVideo = $this.showVideoList[$this.vIndex]
                    console.log($this.vIndex, '$this.vIndex0------');
                } else {
                    $this.vIndex = 0
                    console.log($this.vIndex, '$this.vIndex0------');

                    $this.showVideo = $this.showVideoList[$this.vIndex]
                }
            }, 9000);
        })
        if (this.$route.query.gId) {
            this.$refs.hangNewVideo.numberChange({ gId: this.$route.query.gId })
        }
        getBstGoodsGategory1().then((res) => {
            this.areaCategoryList = res.data.data
            this.areaCategoryList.unshift({ label: '推荐', gId: 0 })

        }),
            getGoodsIndustryAdvisory().then((res) => {
                this.areaCategoryList2 = res.data.data.slice(0, 11)
                this.areaCategoryList3 = res.data.data
                this.areaCategoryList2.unshift({ label: '推荐', gId: 0 })


            })
        getIndustryIsIndexPlay({ size: 10, video_size: 9, consult_size: 7, index: 0, quote_size: 0 }).then((res) => {
            if (res.data.code == 200) {
                this.hangYeList = res.data.data
            }
        })
        let params = {
            title: this.title,
            size: this.size,
            page: this.page,
            industry: this.industry,
            industry2: this.industry2,
        }
        getHotVod(params).then((res) => {
            this.videoList = res.data.data
        })

        let paramss = {
            page: 1,
            size: 34,

        }
        let datas = {
            page: 1,
            size: 10000,

        }
        getRecommendVod(paramss).then((res) => {
            this.videoList2 = res.data.data
            this.allPage = Math.ceil(res.data.totalPage / 14)

        })
        // getRecommendVod(datas).then((res) => {
        //     this.allPage = Math.ceil(res.data.totalPage / 14)
        //     console.log(this.videoNumber, this.allPage, '1-2');
        // })
    },
    beforeDestroy() {
        clearInterval(this.dingTer);
    }
}
</script>

<style lang=scss scoped>
.hot-box {
    padding: 0;
    margin: 0;
    width: 100vw;
    // margin-left: calc(10px + 9.2vw);
    background: linear-gradient(to bottom, rgba(154, 195, 255, 1) 0%, rgba(143, 169, 213, 0.9) 50%, rgba(255, 255, 255, 1) 100%);

    padding-left: calc(10px + 10.2vw);
    background-color: red;


}

@media screen and (min-width: 120px) and (max-width: 1400px) {
    .video-content {
        width: 100vw !important;
        height: 100vh;
        position: fixed;
        top: 0;
        z-index: -11111;
    }

    .video-all {
        width: 100vw;

        display: flex;

        .con {
            margin-top: 50vh;
            width: 10.7vw;
            height: 22rem;


            .con1 {
                height: 17.5rem;
                width: 100%;
                display: flex;
                box-sizing: border-box;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            .con2 {
                width: 100%;
                height: 22rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
            }
        }

        .video-hot {
            .video-list0 {
                // background-color: red;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            font-size: 16px;
            color: #333333;
            width:calc(89.3vw);
            z-index: 1800 !important;
            margin: 0;
            // margin-left: calc(0px + 9.2vw);
            margin-top: 50vh !important;
            // background-image: url('https://video.zhnl.com/2024/07/16/af8b6f8be2b04c13a63a4f63c6918736c508788eb601f92ed7779f54a14fb2c.jpg');
            // background-image: url('https://video.zhnl.com/2024/07/16/7f096d9ec1564aabb63505fac9c2eb00c508788eb601f92ed7779f54a14fb2c.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .video-list {
                display: flex;
                overflow: hidden;

                width: 99%;
                height: 21rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);

                .video-left {
                    flex: 10;
                    display: flex;
                    flex-wrap: wrap;
                    z-index: 1800;
                    overflow: hidden;

                    .left-item {
                        box-sizing: border-box;

                        padding: 4px;
                        // margin-left: 12px;
                        height: 49%;
                        width: 19.6%;
                        // width: 208px;
                        cursor: pointer;
                        margin-top: 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .left-item:hover {
                        color: rgb(32, 123, 255) !important;

                    }
                }

                .video-right {
                    flex: 1.7;

                    margin-left: 7px;

                    .contaion3-item {
                        height: 135px;
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;
                        cursor: pointer;

                        .con-left {
                            width: 30%;
                            height: 100%;
                        }

                        .con-right {
                            width: 68%;
                            height: 100%;

                            .con-bottom:hover {
                                color: rgb(32, 123, 255) !important;

                            }

                            .con-right-middel {
                                padding: 0px 5px 5px 8px;
                                height: 60px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                            }

                            .con-right-middel:hover {
                                color: rgb(32, 123, 255) !important;

                            }


                        }
                    }
                }
            }
        }
    }

    .edit {
        width: 100%;
        margin: 0px;
        height: 25rem;
        margin-bottom: 40px;
        // background-color: white;

        .edit-box {

            width: 88.8%;
            height: 24rem;
            box-sizing: border-box;
            overflow: hidden;

            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;
            box-sizing: border-box;

            overflow: hidden;

            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 19.5%;
                height: 50%;
                padding: 4px;
                // background-color: skyblue;
            }
        }
    }

    .video-type {
        padding-bottom: 10px;
        width: 100%;
        // width: 1330px;
        height: 342px;
        margin: auto;
        // margin-bottom: 3rem;
        overflow: hidden;

        .item-box {
            display: flex;
            height: 314px;
            width: 88%;
            overflow: hidden;
            flex-wrap: wrap;

            .type-item {
                box-sizing: border-box;
                // width: 220px;
                width: 19.9%;
                height: 100%;
                padding: 2px 5px;
                flex-wrap: wrap;
                flex-grow: 0;
                flex-shrink: 0;

            }
        }
    }
}



@media screen and (min-width: 1400px) and (max-width: 1890px) {
    .video-content {
        width: 100vw !important;
        height: 100vh;
        position: fixed;
        top: 0;
        z-index: -11111;
    }

    .video-all {
        width: 100vw;

        display: flex;

        .con {
            margin-top: 50vh;
            width: 10.7vw;
            height: 22rem;


            .con1 {
                height: 17.5rem;
                width: 100%;
                display: flex;
                box-sizing: border-box;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            .con2 {
                width: 100%;
                height: 22rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
            }
        }

        .video-hot {
            .video-list0 {
                // background-color: red;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            font-size: 16px;
            color: #333333;
            width:calc(89.3vw);
            z-index: 1800 !important;
            margin: 0;
            // margin-left: calc(0px + 9.2vw);
            margin-top: 50vh !important;
            // background-image: url('https://video.zhnl.com/2024/07/16/af8b6f8be2b04c13a63a4f63c6918736c508788eb601f92ed7779f54a14fb2c.jpg');
            // background-image: url('https://video.zhnl.com/2024/07/16/7f096d9ec1564aabb63505fac9c2eb00c508788eb601f92ed7779f54a14fb2c.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .video-list {
                display: flex;
                width: 99%;
                overflow: hidden;

                height: 20rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);

                .video-left {
                    flex: 10;
                    display: flex;
                    flex-wrap: wrap;
                    z-index: 1800;
                    overflow: hidden;

                    .left-item {
                        box-sizing: border-box;

                        padding: 4px;
                        // margin-left: 12px;
                        height: 48%;
                        width: 16.5%;
                        // width: 208px;
                        cursor: pointer;
                        margin-top: 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .left-item:hover {
                        color: rgb(32, 123, 255) !important;

                    }
                }

                .video-right {
                    flex: 1.7;

                    margin-left: 7px;

                    .contaion3-item {
                        height: 135px;
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;
                        cursor: pointer;

                        .con-left {
                            width: 30%;
                            height: 100%;
                        }

                        .con-right {
                            width: 68%;
                            height: 100%;

                            .con-bottom:hover {
                                color: rgb(32, 123, 255) !important;

                            }

                            .con-right-middel {
                                padding: 0px 5px 5px 8px;
                                height: 60px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                            }

                            .con-right-middel:hover {
                                color: rgb(32, 123, 255) !important;

                            }


                        }
                    }
                }
            }
        }
    }

    .edit {
        width: 100%;
        margin: 0px;

        height: 25rem;
        // background-color: pink;
        margin-bottom: 40px;
        // background-color: white;

        .edit-box {
            width: 88%;
            overflow: hidden;

            height: 24rem;
            box-sizing: border-box;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            // box-sizing: content-box;


            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 16.5%;
                height: 12rem;
                padding: 0px 4px;
                // background-color: skyblue;
            }
        }
    }

    .video-type {
        padding-bottom: 10px;
        width: 99%;
        // width: 1330px;
        height: 339px;
        margin: auto;
        // margin-bottom: 3rem;
        overflow: hidden;

        .item-box {
            display: flex;
            height: 314px;
            width: 87.4%;
            overflow: hidden;

            .type-item {
                box-sizing: border-box;
                // width: 220px;
                width: 16.6%;
                height: 98%;
                padding: 2px 5px;
                flex-wrap: wrap;
                flex-grow: 0;
                flex-shrink: 0;

            }
        }
    }
}

@media screen and (min-width: 1890px)and (max-width: 2200px) {

    .video-content {
        width: 100vw !important;
        height: 100vh;
        position: fixed;
        top: 0;
        z-index: -11111;

    }

    .video-all {
        width: 100vw;

        display: flex;

        .con {
            margin-top: 50vh;
            width: 10.7vw;
            height: 22rem;

            .con1 {
                height: 17.5rem;
                width: 100%;
                display: flex;
                box-sizing: border-box;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            .con2 {
                width: 100%;
                height: 22rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
            }
        }

        .video-hot {
            .video-list0 {
                // background-color: red;
                // background-color: rgb(159, 188, 231);
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            font-size: 16px;
            color: #333333;
            width:calc(89.3vw);
            z-index: 1800 !important;
            margin: 0;
            // margin-left: calc(0px + 9.2vw);
            margin-top: 50vh !important;
            // background-image: url('https://video.zhnl.com/2024/07/16/af8b6f8be2b04c13a63a4f63c6918736c508788eb601f92ed7779f54a14fb2c.jpg');
            // background-image: url('https://video.zhnl.com/2024/07/16/7f096d9ec1564aabb63505fac9c2eb00c508788eb601f92ed7779f54a14fb2c.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .video-list {
                display: flex;
                width: 99%;
                height: 22rem;
                overflow: hidden;

                z-index: 1800 !important;
                // background-color: #9ac3ff;
                // opacity: 0.99;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);

                .video-left {
                    flex: 10;
                    display: flex;
                    flex-wrap: wrap;
                    z-index: 1800;

                    .left-item {
                        box-sizing: border-box;
                        padding: 5px;
                        height: 49%;
                        width: 14.20%;
                        cursor: pointer;
                        margin-top: 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .left-item:hover {
                        color: rgb(32, 123, 255) !important;

                    }
                }

                .video-right {
                    flex: 1.7;

                    margin-left: 7px;

                    .contaion3-item {
                        height: 135px;
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;
                        cursor: pointer;

                        .con-left {
                            width: 30%;
                            height: 100%;
                        }

                        .con-right {
                            width: 68%;
                            height: 100%;

                            .con-bottom:hover {
                                color: rgb(32, 123, 255) !important;

                            }

                            .con-right-middel {
                                padding: 0px 5px 5px 8px;
                                height: 60px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                            }

                            .con-right-middel:hover {
                                color: rgb(32, 123, 255) !important;

                            }


                        }
                    }
                }
            }
        }
    }


    .edit {
        // margin-left: calc(10px + 9.2vw);
        // background: linear-gradient(to bottom, rgba(154, 195, 255, 1) 0%, rgba(143, 169, 213, 0.9) 50%, rgba(154, 195, 255, 1) 100%);
        width: calc(90vw - 29px);
        // padding-top: 3rem;
        height: 25rem;
        box-sizing: border-box;
        z-index: 9999999999;
        margin: 0px;
        margin-bottom: 40px;

        .edit-box {
            overflow: hidden;
            // ddddd
            width: 100%;
            height: 24rem;
            box-sizing: border-box;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;

            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 14.24%;
                height: 12rem;
                padding: 0px 4px;
            }
        }
    }


    .video-type {
        padding-bottom: 10px;
        width: 99%;
        // width: 1330px;
        height: 379px;
        margin: auto;
        // margin-bottom: 3rem;
        overflow: hidden;
        // background: linear-gradient(to bottom, rgba(154, 195, 255, 1) 0%, rgba(143, 169, 213, 0.9) 50%, rgba(154, 195, 255, 1) 100%);

        .item-box {
            display: flex;
            height: 345px;
            width: 88%;
            overflow: hidden;

            .type-item {
                box-sizing: border-box;
                // width: 220px;
                width: 12.5%;
                height: 98%;
                padding: 2px 5px;
                flex-wrap: wrap;
                flex-grow: 0;
                flex-shrink: 0;

            }
        }
    }
}

@media screen and (min-width: 2200px)and (max-width: 22000px) {

    .video-content {
        width: 100vw !important;
        height: 100vh;
        position: fixed;
        top: 0;
        z-index: -11111;

    }

    .video-all {
        width: 100vw;

        display: flex;

        .con {
            margin-top: 50vh;
            width: 10.7vw;
            height: 22rem;

            .con1 {
                height: 17.5rem;
                width: 100%;
                display: flex;
                box-sizing: border-box;
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            .con2 {
                width: 100%;
                height: 22rem;
                z-index: 1800 !important;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
            }
        }

        .video-hot {
            .video-list0 {
                // background-color: red;
                // background-color: rgb(159, 188, 231);
                background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            font-size: 16px;
            color: #333333;
            width:calc(89.3vw);
            z-index: 1800 !important;
            margin: 0;
            // margin-left: calc(0px + 9.2vw);
            margin-top: 50vh !important;
            // background-image: url('https://video.zhnl.com/2024/07/16/af8b6f8be2b04c13a63a4f63c6918736c508788eb601f92ed7779f54a14fb2c.jpg');
            // background-image: url('https://video.zhnl.com/2024/07/16/7f096d9ec1564aabb63505fac9c2eb00c508788eb601f92ed7779f54a14fb2c.jpg');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .video-list {
                display: flex;
                width: 99%;
                height: 22rem;
                z-index: 1800 !important;
                // background-color: #9ac3ff;
                // opacity: 0.99;
                background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
                overflow: hidden;

                .video-left {
                    flex: 10;
                    display: flex;
                    flex-wrap: wrap;
                    z-index: 1800;

                    .left-item {
                        box-sizing: border-box;
                        padding: 5px;
                        height: 49%;
                        width: 11%;
                        cursor: pointer;
                        margin-top: 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .left-item:hover {
                        color: rgb(32, 123, 255) !important;

                    }
                }

                .video-right {
                    flex: 1.7;

                    margin-left: 7px;

                    .contaion3-item {
                        height: 135px;
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;
                        cursor: pointer;

                        .con-left {
                            width: 30%;
                            height: 100%;
                        }

                        .con-right {
                            width: 68%;
                            height: 100%;

                            .con-bottom:hover {
                                color: rgb(32, 123, 255) !important;

                            }

                            .con-right-middel {
                                padding: 0px 5px 5px 8px;
                                height: 60px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                            }

                            .con-right-middel:hover {
                                color: rgb(32, 123, 255) !important;

                            }


                        }
                    }
                }
            }
        }
    }


    .edit {
        // margin-left: calc(10px + 9.2vw);
        // background: linear-gradient(to bottom, rgba(154, 195, 255, 1) 0%, rgba(143, 169, 213, 0.9) 50%, rgba(154, 195, 255, 1) 100%);
        width: calc(90vw - 29px);
        // padding-top: 3rem;
        height: 25rem;
        z-index: 9999999999;
        margin: 0px;
        margin-bottom: 40px;

        .edit-box {
            width: 100%;
            height: 24rem;
            box-sizing: border-box;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;
            overflow: hidden;

            .edit-item {
                box-sizing: border-box;
                cursor: pointer;
                width: 11%;
                height: 12rem;
                padding: 0px 4px;
            }
        }
    }


    .video-type {
        padding-bottom: 10px;
        width: 99%;
        // background-color: red;
        // width: 1330px;
        height: 379px;
        // margin: auto;
        // margin-bottom: 3rem;
        overflow: hidden;
        // background: linear-gradient(to bottom, rgba(154, 195, 255, 1) 0%, rgba(143, 169, 213, 0.9) 50%, rgba(154, 195, 255, 1) 100%);

        .item-box {
            display: flex;
            height: 345px;
            width: 100%;
            overflow: hidden;

            .type-item {
                box-sizing: border-box;
                width: 9.8%;
                height: 98%;
                padding: 2px 5px;
                flex-wrap: wrap;
                flex-grow: 0;
                flex-shrink: 0;


            }
        }
    }
}

// @media screen and (min-width: 2300px) {
//     .video-content {
//         width: 100vw !important;
//         height: 100vh;
//         position: fixed;
//         top: 0;
//         z-index: -11111;
//     }

//     .video-all {
//         width: 100vw;

//         display: flex;

//         .con {
//             margin-top: 50vh;
//             width: 10.7vw;
//             height: 22rem;


//             .con1 {
//                 height: 17.5rem;
//                 width: 100%;
//                 display: flex;
//                 box-sizing: border-box;
//                 background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
//                 background-size: 100% 100%;
//                 background-repeat: no-repeat;
//             }

//             .con2 {
//                 width: 100%;
//                 height: 22rem;
//                 z-index: 1800 !important;
//                 background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);
//             }
//         }

//         .video-hot {
//             .video-list0 {
//                 // background-color: red;
//                 background-image: url('https://video.zhnl.com/2024/07/22/5aaf987fcedd4e02b8a5c040b9ad6ff6af0c6ecf1d12e30ff17e686bf8d10ec.jpg');
//                 background-size: 100% 100%;
//                 background-repeat: no-repeat;
//             }

//             font-size: 16px;
//             color: #333333;
//             width:calc(89.3vw);
//             z-index: 1800 !important;
//             margin: 0;
//             // margin-left: calc(0px + 9.2vw);
//             margin-top: 50vh !important;
//             // background-image: url('https://video.zhnl.com/2024/07/16/af8b6f8be2b04c13a63a4f63c6918736c508788eb601f92ed7779f54a14fb2c.jpg');
//             // background-image: url('https://video.zhnl.com/2024/07/16/7f096d9ec1564aabb63505fac9c2eb00c508788eb601f92ed7779f54a14fb2c.jpg');
//             background-size: 100% 100%;
//             background-repeat: no-repeat;

//             .video-list {
//                 display: flex;
//                 width: 99%;
//                 height: 22rem;
//                 z-index: 1800 !important;
//                 background: linear-gradient(to bottom, rgba(154, 195, 255, 0.92) 0%, rgba(158, 195, 231, 0.88) 50%, rgba(154, 195, 255, 1) 100%);

//                 .video-left {
//                     flex: 10;
//                     display: flex;
//                     flex-wrap: wrap;
//                     z-index: 1800;

//                     .left-item {
//                         box-sizing: border-box;

//                         padding: 4px;
//                         // margin-left: 12px;
//                         height: 48%;
//                         width: 14.20%;
//                         // width: 208px;
//                         cursor: pointer;
//                         margin-top: 3px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }

//                     .left-item:hover {
//                         color: rgb(32, 123, 255) !important;

//                     }
//                 }

//                 .video-right {
//                     flex: 1.7;

//                     margin-left: 7px;

//                     .contaion3-item {
//                         height: 135px;
//                         width: 100%;
//                         display: flex;
//                         margin-bottom: 10px;
//                         cursor: pointer;

//                         .con-left {
//                             width: 30%;
//                             height: 100%;
//                         }

//                         .con-right {
//                             width: 68%;
//                             height: 100%;

//                             .con-bottom:hover {
//                                 color: rgb(32, 123, 255) !important;

//                             }

//                             .con-right-middel {
//                                 padding: 0px 5px 5px 8px;
//                                 height: 60px;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 display: -webkit-box;
//                                 -webkit-box-orient: vertical;
//                                 -webkit-line-clamp: 3;
//                             }

//                             .con-right-middel:hover {
//                                 color: rgb(32, 123, 255) !important;

//                             }


//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .edit {
//         width: 100%;
//         // width: 1330px;
//         // margin: auto;
//         // margin-top: 3rem;
//         height: 29rem;
//         // background-color: pink;
//         margin-bottom: 0.2rem;
//         // background-color: white;

//         .edit-box {
//             width: 100%;
//             height: 86%;
//             flex-wrap: wrap;
//             display: flex;
//             justify-content: flex-start;

//             // box-sizing: content-box;


//             .edit-item {
//                 box-sizing: border-box;
//                 cursor: pointer;
//                 width: 14.24%;
//                 height: 12rem;
//                 padding: 0px 4px;
//                 // background-color: skyblue;
//             }
//         }
//     }

//     .video-type {
//         width: 100%;
//         padding-bottom: 10px;
//         height: 392px;
//         margin: auto;
//         // margin-top: 3rem;
//         overflow: hidden;

//         .item-box {
//             display: flex;
//             height: 352px;
//             width: 100%;

//             .type-item {
//                 box-sizing: border-box;
//                 // width: 220px;
//                 width: 14.2%;
//                 height: 98%;
//                 padding: 2px 5px;

//             }
//         }
//     }
// }





.video-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 200px !important;
    margin-top: 10px;

    .box-item {
        width: 19%;
        height: 200px;
        // background-color: green;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 10px;


        .box-one {
            height: 75%;
            border-radius: 10px;
        }

        .box-two {
            height: 10%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 3px 1px;
        }

        .box-three {
            height: 10%;
            padding: 1px 1px;
            color: gray;
            font-size: 13px;
        }

        .box-two:hover {
            color: rgb(32, 123, 255) !important;
        }

        .box-three:hover {
            color: rgb(32, 123, 255) !important;
        }
    }
}

.videoImg:hover {
    box-shadow: 5px 5px 5px gray;
}



.left-box {
    position: fixed;
    left: 10px;
    top: 95px;
    width: 9.2vw;
    border-radius: 20px;
    opacity: 0.81;
    height: 82vh;
    z-index: 9999999;
    padding: 0px 5px;
    // background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.86) 100%);
    // background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    background: linear-gradient(170deg, rgba(255, 255, 255, 0.76) 0%, rgba(34, 123, 255, 0.27) 82%, rgba(149, 185, 233, 0.76) 100%);
    // background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.4) 0%, rgba(34, 123, 255, 0.76) 50%, rgba(79, 148, 255, 0.76) 100%);
    // background: linear-gradient(170deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    // background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    // backdrop-filter: blur(20px);

    // box-sizing: border-box;


    box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);

    .left-item {
        font-family: 'Microsoft YaHei UI';
        font-size: 16px;
        font-weight: 290;
        color: #333333;
        width: 98%;
        padding: 6px 0px;
        padding-left: 6px;
        // border-bottom: 1px dashed gray;
        cursor: pointer;
        height: 4.5vh;
        line-height: 4.5vh;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .left-item:hover {
        color: #227BFF !important;

    }


}

.bgc {
    color: #227BFF !important;
}

body {

    margin: 0 !important;
}
</style>