<template>
    <div style="background-color: rgb(246, 247, 248);width: 100%;height: 98%;">
        <TitleDialog :imgUrl="imgUrl" ref="TitleDialog" />
        <VipDialog9 :imgUrl="imgUrl" ref="VipDialog9" />
        <VipDialog2Copy :imgUrl="imgUrl" ref="VipDialog2Copy" />
        <allTitle :imgUrl="imgUrl" ref="allTitle" />
        <VipDialog2CopyOne :imgUrl="imgUrl" ref="VipDialog2CopyOne" />


        <el-dialog title="" :visible.sync="dialogVisible2" width="690px" style="" :center="true">
            <div
                style="width: 100%;text-align: center;font-weight: 400;font-size: 14px;color: #613C14;padding: 30px 100px;box-sizing: border-box;border-bottom: 1px solid #D8D8D8;">
                搜原网信用额度规则
            </div>
            <div
                style="padding: 3px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;margin-top: 20px;">
                一、信用额度规则 </div>
            <div
                style="padding:4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                欢迎您成为我们的尊贵企业会员，信用额度规则如下; </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                1.额度获取 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 搜原网会以您的消费记录，公司实力来评估信用额度； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 信用额度依据个人消费记录，实时更新； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                2.信用额度有效期 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 信用额度长期有效; </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                3.信用额度使用 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 信用额度可以替代现金购买严选商城的原材料; </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                4.其它条款 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 信用额度不可转让其他会员； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 信用额度不可兑现为现金； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 搜原网保留最终解释权； </div>
            <div style="height: 200px;"></div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false" type="primary" plain style="border-radius: 30px;">
                    已阅读</el-button>

            </span>
        </el-dialog>
        <el-dialog title="" :visible.sync="dialogVisible3" width="690px" style="" top="8vh" :center="true">
            <div
                style="width: 100%;text-align: center;font-weight: 400;font-size: 14px;color: #613C14;padding: 30px 100px;box-sizing: border-box;border-bottom: 1px solid #D8D8D8;">
                搜原网积分规则
            </div>
            <div
                style="padding: 3px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;margin-top: 20px;">
                一、积分规则 </div>
            <div
                style="padding:4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                欢迎您成为我们的尊贵企业会员，会员积分规则如下 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                1.积分获取 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 每在《严选商城》消费1元人民币，获取1积分； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 积分依据个人消费记录，实时更新； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                2.积分有效期 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 积分长期有效; </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                3.积分使用 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 积分可以兑现商城礼品或服务; </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 30px;">
                4.其它条款 </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 积分不可转让其他会员； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 积分不可兑现为现金； </div>
            <div
                style="padding:1px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;margin-left: 40px;">
                • 搜原网保留最终解释权； </div>
            <div style="height: 200px;"></div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false" type="primary" plain style="border-radius: 30px;">
                    已阅读</el-button>

            </span>
        </el-dialog>
        <el-dialog title="" :visible.sync="dialogVisible" width="700px" top="8vh">
            <div style="height: 50px;"></div>

            <div style="width: 90%;height: 90%;padding: 20px;">
                <img src="../assets/newImg3/75c93caa3a7fca338a690ba67e8e2f6.jpg" style="width: 90%;height: 100%" alt="">
            </div>

            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
            </span>
        </el-dialog>
        <div class="myMoney" style="height: 160px;">
            <div style="padding: 26px 35px 20px 35px;color: #333333;font-size: 16px;display: flex;line-height: 25px;">
                <div style="width: 80px;">
                    <span style="font-weight: 400;">会员状态：</span>
                </div>
                <div>
                    <img src="../assets/newImg3/超级黄金会员.gif" style="width: 52px;height: 17px;" alt=""
                        v-if="info.is_super_vip == 1">
                    <img src="../assets/newImg3/黄金会员.gif" style="width: 52px;height: 18px;" alt=""
                        v-else-if="info.is_bus_vip == 1">
                    <img src="../assets/newImg3/黄金会员.gif" style="width: 52px;height: 18px;" alt=""
                        v-else-if="info.is_free_vip == 0">
                    <span style="font-weight: 290;" v-if="info.is_super_vip == 1">超级黄金会员</span>
                    <span style="font-weight: 290;" v-else-if="info.is_free_vip == 2">未开通</span>
                    <span style="font-weight: 290;" v-else-if="info.is_free_vip == 0">黄金会员(试用版)</span>
                    <span style="font-weight: 290;" v-else-if="info.is_bus_vip == 1">黄金会员</span>
                    <span style="font-weight: 290;" v-else-if="info.is_bus_vip == 0">非企业会员</span>
                </div>
                <div style="margin-left: 5px;margin-right: 2px;" v-show="info.is_bus_vip == 1">
                    <img src="../assets/newImg3/Frame@1x.png" style="vertical-align: middle;" alt="">
                    <span style="font-weight: 290;color: #227BFF;font-size: 15px;margin-right: 5px;
                        vertical-align: middle;margin-left: 2px;" v-if="info.is_super_vip == 1">{{
            info.super_vip_arr_time }}到期</span>
                    <span
                        style="font-weight: 290;color: #227BFF;font-size: 15px;margin-right: 5px;vertical-align: middle;margin-left: 2px;"
                        v-else-if="info.is_bus_vip == 1">{{
            info.bus_vip_arr_time }}到期</span>
                </div>
                <el-button type="primary" size="mini" style="" @click="goVip"
                    v-if="info.is_bus_vip == 0">开通会员</el-button>
                <el-button type="primary" size="mini" style="" @click="goVip3(3)"
                    v-else-if="info.is_super_vip == 1">续费会员</el-button>
                <el-button type="primary" size="mini" style="" @click="goVip3"
                    v-else-if="info.is_bus_vip == 1">升级续费会员</el-button>


                <el-button type="primary" size="mini" v-show="info.is_free_use_bus_vip == 0" @click="goVip"
                    plain>免费试用(90天)</el-button>

            </div>

            <div style="padding: 20px 35px;color: #333333;font-size: 16px;display: flex;line-height: 25px;">
                <div style="width: 80px;">
                    <span style="font-weight: 400;">企业认证：</span>
                </div>

                <div style="width: 130px;font-weight: 290;margin-left: 2px;line-height: 27px;">
                    <span v-if="info.distribut_type == 0" style="color:black;font-weight: 290;">审核中</span>
                    <span v-if="info.distribut_type == 1" style="color: green;font-weight: 290;">已认证</span>
                    <span v-if="info.distribut_type == 2" style="color: red;font-weight: 290;">未通过</span>
                    <span v-if="info.distribut_type == 3" style="color: red;font-weight: 290;">未申请</span>
                </div>
                <el-button type="primary" size="mini" @click="goVip2" v-show="info.distribut_type == 3">我要认证</el-button>
                <el-button type="primary" size="mini" @click="goVip2" v-show="info.distribut_type == 2">重新认证</el-button>
                <div v-show="info.distribut_type == 0">
                    <!-- v-show="info.distribut_type == 0" -->
                    <el-button type="primary" size="mini" @click="custom">联系客服</el-button>
                    <!-- <span style="color: #333333;font-size: 12px;margin-left: 10px;cursor: pointer;"
                        @click="dialogVisible = true">联系客服</span> -->
                </div>
                <!--  -->

            </div>
            <!-- <div style="padding: 20px 60px;color: #333333;display: flex;" v-show="info.is_bus_vip == 1">
                <div style="width: 80px;">
                    <span style="font-weight: 400;">到期时间：</span>
                </div>
                <div style="width: 90px;">
                    <span style="font-weight: 290;">{{ info.bus_vip_arr_time }}</span>
                </div>

            </div> -->

        </div>
        <div class="myMoney">
            <div class="title">企业信用额度</div>
            <div class="sky-box">
                <div style="padding: 8px 12px;font-size: 14px;color: #333333;font-weight: 290;margin-left: 2px;">可用额度
                </div>
                <div style="padding: 6px 12px;display: flex;">
                    <div style="color: #333333;font-size: 20px;font-weight: 700;line-height: 25px;width: 100px;">￥{{
            info.total_amount }}</div>
                    <div style="margin-left: 30px;"><el-button type="primary" size="mini"
                            @click="showRule">额度准则</el-button></div>
                </div>
            </div>
        </div>
        <div class="myMoney">
            <div class="title">企业积分</div>
            <div class="sky-box">
                <div style="padding: 8px 12px;font-size: 14px;color: #333333;font-weight: 290;margin-left: 2px;">可用积分
                </div>
                <div style="padding: 6px 12px;display: flex;">
                    <div style="color: #333333;font-size: 20px;font-weight: 700;line-height: 25px;width: 100px;">￥{{
            info.pay_points
        }}</div>
                    <div style="margin-left: 30px;">
                        <el-button type="primary" size="mini" @click="showRule2">积分准则</el-button>
                        <el-button type="primary" size="mini" style="margin-left: 27px;">积分商城</el-button>
                    </div>
                    <!-- <div style="margin-left: 30px;"><el-button type="primary" size="mini">去提现</el-button></div> -->
                </div>
            </div>
        </div>

        <div class="quotedBox" v-show="info.distribut_type != 3">
            <div class="title">认证资料 </div>
            <div style="display: flex;">
                <div class="tt" style="align-items:flex-start;padding: 0px;font-weight: 700;">
                    <div class="xian" style="margin-top: 14px;"></div>
                    <div style="margin-top: 10px;">营业执照</div>
                    <!-- <div class="zhiti">营业执照</div> -->
                </div>

                <div class="tt">
                    <img v-if="info.business_license" :src="info.business_license"
                        style="width: 214.39px;height: 156px;cursor: pointer;border-radius: 12px;margin-left: 25px;"
                        @click="goImg">
                    <div v-else>并无上传营业执照</div>
                </div>
            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">审核状态


                    <!-- <span v-if="info.distribut_type == 0">未审核</span> -->
                </div>
                <span v-if="info.distribut_type == 0"
                    style="color:black;margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;">未审核</span>
                <span v-if="info.distribut_type == 1"
                    style="color: green;margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;">通过</span>
                <span v-if="info.distribut_type == 2"
                    style="color: red;margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;">未通过</span>
                <span v-if="info.distribut_type == 3"
                    style="color: red;margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;">未申请企业认证</span>
            </div>
            <div class="tt" v-if="info.reason">
                <div class="xian"></div>
                <div class="zhiti">未通过原因：
                </div>
                <span style="margin-left: 40px;color: #333333;font-weight: 290;font-size: 14px;line-height: 14px;">{{
            info.reason
        }}</span>

            </div>

            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">公司名称
                </div>

                <span style="margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;"> {{ info.shop_name
                    }}</span>

            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">企业法人
                </div>
                <span style="margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;"> {{
            info.company_legal_person
        }}</span>

            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">运营者

                </div>
                <span style="margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;"> {{
                info.company_operator }}</span>

            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">运营者手机号

                </div>
                <span style="margin-left: 40px;font-weight: 290;font-size: 14px;line-height: 14px;"> {{ info.shop_phone
                    }}</span>
            </div>



            <!-- <div class="btn" @click="skip" v-if="info.distribut_type == 0 || info.distribut_type == 2">申请企业信息认证信息</div> -->
            <el-button type="primary" style="margin-left: 20px;margin-top: 10px;" @click="cancel"
                v-show="info.distribut_type == 0">取消认证</el-button>
            <el-button type="primary" style="margin-left: 20px;margin-top: 10px;" @click="cancel2"
                v-show="info.distribut_type == 3 ">申请企业认证</el-button>
            <el-button type="primary" style="margin-left: 20px;margin-top: 10px;" @click="cancel2"
                v-show=" info.distribut_type == 2">重新认证</el-button>

            <div class="line1 ">
                <!-- <div class="left" v-if="!info.company_name">
                    <div class="yuan"></div>公司名称：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>公司名称：{{info.company_name}}
                </div>

                <div class="left" v-if="!info.company_address">
                    <div class="yuan"></div>地址：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>地址：{{ info.company_address }}
                </div>

                <div class="left" v-if="!info.company_address">
                    <div class="yuan"></div>联系方式：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>联系方式：{{ info.company_phone }}
                </div>

                <div class="left">
                    <div class="yuan"></div><div>经营范围：</div><span style="width: 725px;">{{ info.company_manage }}</span>
                </div>

                <div class="left">
                    <div class="yuan"></div>营业执照：<img :src="info.business_license_show" style="width: 121px; height: 88px;" alt="">
                </div>  -->


                <!-- <div class="btn" @click="skip" v-if="info.distribut_type == 3 || info.distribut_type == 2">申请企业认证</div> -->
                <!-- <div class="btn" @click="skip">申请企业认证</div> -->
            </div>



        </div>

    </div>
</template>

<script>

import { getUserById, wxSendWallet, getBusManage, getWChatCode, removePersonBus } from '@/api/user.js'
import TitleDialog from '../components/TitleDialog.vue'
import VipDialog9 from '../components/VipDialog9.vue'
import VipDialog2Copy from '../components/VipDialog2Copy.vue'
import VipDialog2CopyOne from '../components/VipDialog2CopyOne.vue'

import allTitle from '../components/allTitle.vue'


export default {
    data() {
        return {
            bus_id: '',
            info: {},
            input: '',
            kfShow: false,
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            imgUrl: '',
        }
    },
    components: {
        TitleDialog,
        VipDialog9,
        VipDialog2Copy,
        allTitle,
        VipDialog2CopyOne
    },
    methods: {
        custom() {
            this.$refs.VipDialog9.visible = true

        },
        handleClose(key, keyPath) {
            console.log(key, 'eeee');
        },
        freeVip() {

        },
        showRule() {
            this.dialogVisible2 = true
        },
        showRule2() {
            this.dialogVisible3 = true
        },
        cancel() {
            removePersonBus({}).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '企业认证申请已取消'
                    })
                    getUserById().then((res) => {
                        this.info = res.data.data
                    })
                }
            })
        },
        cancel2() {
            this.$router.push({
                path: '/buyerCenter/amendEnterpriseInfo'
            })
        },
        goImg() {
            window.open(this.info.business_license)
        },
        showKF() {
            this.kfShow = true
        },
        hiddenKF() {
            this.kfShow = false

        },
        goVip() {
            if (this.info.distribut_type == 3) {

                this.$refs.TitleDialog.visible = true
                this.$refs.TitleDialog.routeUrl = '/buyerCenter/amendEnterpriseInfo'
                return this.$refs.TitleDialog.imgUrl = 'https://video.zhnl.com/2024/08/07/e67af57207b04851a78f11a0fc60ab4f温馨提示-07.jpg'
            } else if (this.info.distribut_type == 0) {
                this.$refs.TitleDialog.visible = true
                this.$refs.TitleDialog.routeUrl = ''
                return this.$refs.TitleDialog.imgUrl = 'https://video.zhnl.com/2024/08/07/e48db477c7c447f5aad1c9ffcebd2389d95d0128f44f442b4309ba50a218072.jpg'
            } else if (this.info.distribut_type == 2) {
                this.$refs.TitleDialog.visible = true
                this.$refs.TitleDialog.routeUrl = '/buyerCenter/amendEnterpriseInfo'
                return this.$refs.TitleDialog.imgUrl = 'https://video.zhnl.com/2024/08/07/7d256e2d4c074ff48f933e6f7ef38a2e72dbac00b84b4d42a7b2dd4cee234b9.jpg'

            }
            this.$router.push({
                path: '/buyerCenter/priviLege',

            })
        },
        goVip2() {
            this.$router.push({
                path: '/buyerCenter/amendEnterpriseInfo',
                query: {
                    isVip: 1
                }
            })

        },
        goVip3(id) {

            if (this.info.is_bus_vip == 0) {
                this.$refs.VipDialog2Copy.useIfXu = 0
                this.$refs.VipDialog2CopyOne.useIfXu = 0


            } else {
                this.$refs.VipDialog2Copy.useIfXu = 1
                this.$refs.VipDialog2CopyOne.useIfXu = 1

            }
            if (this.info.is_super_vip == 0) {
                this.$refs.VipDialog2Copy.busIfXu = 0
                this.$refs.VipDialog2CopyOne.busIfXu = 0

            } else {
                this.$refs.VipDialog2Copy.busIfXu = 1
                this.$refs.VipDialog2CopyOne.busIfXu = 1
            }
            if (id == 3) {
                this.$refs.VipDialog2CopyOne.getCode()
                this.$refs.VipDialog2CopyOne.visible = true
                this.$refs.VipDialog2CopyOne.busId = this.bus_id
            } else {
                this.$refs.VipDialog2Copy.getCode()
                this.$refs.VipDialog2Copy.visible = true
                this.$refs.VipDialog2Copy.busId = this.bus_id
            }

            // this.$router.push({
            //     path: '/buyerCenter/priviLege',
            //     query: {
            //         isVip: 1
            //     }
            // })

        },
        getMoney() {
            getWChatCode({}).then((res) => {
                if (res.data.code == 200) {
                    window.open(res.data.data)
                }
            })

        },
    },

    created() { },
    mounted() {
        console.log(this.$route, 'this.$route');
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)',
        });
        getBusManage({ page: 1, size: 1 }).then((res) => {
            if (res.data.data.length > 0) {
                this.bus_id = res.data.data[0].busId
            }
        })
        getUserById().then((res) => {
            this.info = res.data.data
            loading.close();

        })

    }
}
</script>

<style lang=scss scoped>
.myMoney {
    width: 98%;
    height: 220px;
    margin-top: 20px !important;
    box-sizing: content-box;
    margin: auto;
    border-radius: 8px;
    background-color: white;

    .title {
        color: #333333;
        font-size: 18px;
        font-style: 'Microsoft YaHei';
        font-weight: 400;
        padding: 9px 15px;
    }

    .sky-box {
        height: 150px;
        width: 99%;
        margin: auto;
        background-color: rgb(215, 231, 255);
        border-radius: 10px;
    }
}

.quotedBox {
    width: 98%;
    // margin-top: 24px;
    height: 750px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;
    margin: auto;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
    }

    .tt {
        display: flex;
        align-items: center;
        margin: 20px;
        padding: 5px 0px;

        .zhiti {
            width: 96px;
            font-size: 14px;
            font-weight: 700;
            color: #333333;
            font-size: 16px;
        }

        >div {
            margin-right: 10px;
        }
    }

    .xian {
        width: 2px;
        height: 16px;
        opacity: 1;
        background: #006eb7;
    }


    .line1 {
        padding: 20px;
        padding-top: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        position: relative;

        .left {
            display: flex;
            align-items: center;
            margin-left: 35px;
            margin-bottom: 29px;

            .textBox {
                font-weight: 400;
                font-size: 20px;
                line-height: 23.4px;
                text-align: left;
                color: #000;
                margin-left: 51px;

                .name {
                    margin-bottom: 12px;
                }
            }

            .yuan {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #006eb7;
                margin-right: 8px;
            }
        }


    }

    .btn {
        width: 180px;
        height: 28px;
        border-radius: 33px;
        opacity: 1;
        background: #006eb7;
        text-align: center;
        line-height: 28px;
        color: white;
        margin-top: 60px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}

::v-deep .el-dialog__header {
    padding: 0px !important;
}

::v-deep .el-dialog__body {
    padding: 0px 20px !important;
}

::v-deep .el-dialog {
    // margin-top: 8vh !important;
}
</style>