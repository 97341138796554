<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                帮助管理
            </div>

            <div class="line1">
                <div class="info" @click="skipPage('/buyerCenter/helpDetails?article_id=' + item.article_id)" v-for="(item, index) in helpList">
                    <div class="yuan"></div>
                    <div class="title2">
                        {{ item.title }}
                    </div>
                    <div class="timer">[{{item.add_time}}]</div>
                </div>
            </div>
            <el-empty v-if="helpList.length<1" description="暂无数据"></el-empty>

            <div class="page">
                <el-pagination layout="prev, pager, next" :total="20" :page-size="20" prev-text="上一页" next-text="下一页"
                    @next-click="nextclick" @prev-click="prevclick">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'first',
            total_page: '',
            p: 1,
            helpList: ''
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },

        skipPage(page) {
            this.$router.push(page)
        },

        //帮助管理
        async HelpManagement() {
            let res = await this.$http('/mini/ApiConnect/HelpManagement')
            this.helpList = res.result
        },
        nextclick() {
            this.p = this.p + 1
            this.HelpManagement()
        },

        prevclick() {
            if (this.p > 1) {
                this.p = this.p - 1
                this.HelpManagement()
            }
        },
    },
    mounted() {
        // this.HelpManagement()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
           font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-bottom: 20px;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }


    }

    .line1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .info {
            // margin: 11px 0;
            margin-top: 20px;
            width: 95%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;

            .yuan {
                width: 8px;
                height: 8px;
                border-radius: 20px;
                background: transparent;
                border: 1px solid #006eb7;
                position: absolute;
                left: 0;
            }

            .title2 {
                margin-left: 25px;
            }
        }

         .info:hover{
            color: #006eb7;
            .yuan{
                background-color: #006eb7;
            }
        }
    }

}

.page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .el-pager li {
    width: 56px !important;
    height: 35px !important;
    line-height: 35px !important;
}

::v-deep .el-pagination .btn-prev {
    width: 56px !important;
    height: 35px !important;
}

::v-deep .el-pagination .btn-next {

    width: 56px !important;
    height: 35px !important;
}

::v-deep .el-pagination .btn-prev {
    padding-right: 0 !important;
}

::v-deep .btn-next {
    padding-left: 0 !important;
}

::v-deep .el-pagination {
    margin-top: 10px;
    padding-left: 0 !important;
}
</style>