<template>
    <div>
        123
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {},
    created() { },
    mounted() { }
}
</script>

<style lang=scss></style>