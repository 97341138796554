<template>
    <div>
        <div class="quotedBox">
            <div class="title">基本信息 </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">审核状态：

                    <span v-if="info.distribut_type == 0" style="color:black">未审核</span>
                    <span v-if="info.distribut_type == 1" style="color: green;">已认证</span>
                    <span v-if="info.distribut_type == 2" style="color: red;">未通过</span>
                    <span v-if="info.distribut_type == 3" style="color: red;">未申请企业认证</span>
                    <!-- <span v-if="info.distribut_type == 0">未审核</span> -->
                </div>
            </div>
            <div class="tt" v-if="info.reason">
                <div class="xian"></div>
                <div class="zhiti">未通过原因：{{ info.reason }}</div>
            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">公司名称：{{ info.shop_name }}</div>
            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">公司地址：{{ info.shop_address }}</div>
            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">联系方式：{{ info.user_phone }}</div>
            </div>
            <!-- <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">公司地址：{{ busObj.shop_address }}</div>
            </div>
            <div class="tt">
                <div class="xian"></div>
                <div class="zhiti">邮箱地址：{{ busObj.user_email }}</div>
            </div> -->

            <div class="title">经营范围</div>
            <div class="tt">
                <div style="font-size: 14px;">{{ info.company_manage ? info.company_manage : '暂未填写' }}</div>


            </div>

            <div class="title">营业执照 </div>
            <div class="tt">
                <img v-if="info.business_license" :src="info.business_license" style="width: 224.39px;height: 163px;">
                <div v-else>并无上传营业执照</div>
            </div>
            <!-- <div class="btn" @click="skip" v-if="info.distribut_type == 0 || info.distribut_type == 2">申请企业信息认证信息</div> -->

            <div class="line1 ">
                <!-- <div class="left" v-if="!info.company_name">
                    <div class="yuan"></div>公司名称：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>公司名称：{{info.company_name}}
                </div>

                <div class="left" v-if="!info.company_address">
                    <div class="yuan"></div>地址：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>地址：{{ info.company_address }}
                </div>

                <div class="left" v-if="!info.company_address">
                    <div class="yuan"></div>联系方式：暂未填写
                </div>

                <div class="left" v-else>
                    <div class="yuan"></div>联系方式：{{ info.company_phone }}
                </div>

                <div class="left">
                    <div class="yuan"></div><div>经营范围：</div><span style="width: 725px;">{{ info.company_manage }}</span>
                </div>

                <div class="left">
                    <div class="yuan"></div>营业执照：<img :src="info.business_license_show" style="width: 121px; height: 88px;" alt="">
                </div>  -->


                <!-- <div class="btn" @click="skip" v-if="info.distribut_type == 3 || info.distribut_type == 2">申请企业认证</div> -->
                <!-- <div class="btn" @click="skip">申请企业认证</div> -->
            </div>



        </div>
    </div>
</template>

<script>
import { updateUserByUserId, getUserById, getBusManage } from '@/api/user'

export default {
    data() {
        return {
            info: '',
            busObj: {},
        }
    },
    methods: {
        skip() {
            // window.location='/buyerCenter/amendPersonal'
            this.$router.push('/buyerCenter/priviLege')
        },
        //个人(企业)信息
        async UserIndexInfo() {
            let user_id = window.localStorage.getItem('user_id')
            getUserById().then((res) => {
                this.info = res.data.data
            })
        },
    },
    mounted() {
        this.UserIndexInfo()
        getBusManage({ page: 1, size: 1000 }).then((res) => {
            this.busObj = res.data.data[0]
        })
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    height: 850px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
    }

    .tt {
        display: flex;
        align-items: center;
        margin: 20px;

        .zhiti {
            font-size: 14px;
        }

        >div {
            margin-right: 10px;
        }
    }

    .xian {
        width: 2px;
        height: 16px;
        opacity: 1;
        background: #006eb7;
    }


    .line1 {
        padding: 20px;
        padding-top: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        position: relative;

        .left {
            display: flex;
            align-items: center;
            margin-left: 35px;
            margin-bottom: 29px;

            .textBox {
                font-weight: 400;
                font-size: 20px;
                line-height: 23.4px;
                text-align: left;
                color: #000;
                margin-left: 51px;

                .name {
                    margin-bottom: 12px;
                }
            }

            .yuan {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #006eb7;
                margin-right: 8px;
            }
        }


    }

    .btn {
        width: 180px;
        height: 28px;
        border-radius: 33px;
        opacity: 1;
        background: #006eb7;
        text-align: center;
        line-height: 28px;
        color: white;
        margin-top: 60px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
</style>