<template>
    <div class="content flexColCenter">
        <searchBar />
        <!-- <div class="line"></div> -->
        <div class="classifyBox">
            <classifyBar :classify="5" />

        </div>

        <div class="location">
            <span>当前位置：</span>
            <div class="nav"><el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="cursor: pointer;"><span
                            @click="skipPage('/')">首页</span></el-breadcrumb-item>

                </el-breadcrumb></div>
        </div>

        <div class="titleBox">
            {{ ContactUs.title }}<br />
            {{ ContactUs.describe }}
        </div>
        <div class="theLine"></div>
        <div class="mapBox">
            <div class="BaiDuMap">
                <baidu-map class="map-wrap" :center="mapData.center" :zoom="mapData.zoom" @ready="mapHandler"
                    @click="getLocation">
                    <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
                    <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
                    <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
                        :autoLocation="true"></bm-geolocation>
                </baidu-map>

            </div>
        </div>

        <div class="textBox">
            <div class="left">
                {{ ContactUs.company_name }}<br />
                地址：{{ ContactUs.address }}<br />
                客服热线：{{ ContactUs.kf_phone }}<br />
                邮编：{{ ContactUs.code }}<br />
            </div>
            <div class="right">
                <div class="one">
                    <img :src="Web.code_one" style="width: 128px; height: 128px;">
                    <span>{{ Web.code_title_one }}</span>
                </div>
                <div class="one">
                    <img :src="Web.code_two" style="width: 128px; height: 128px;">
                    <span>{{ Web.code_title_two }}</span>
                </div>
            </div>
        </div>

        <div class="theLine" style="margin-top: 100px;"></div>


        <div class="formBox">
            <el-input v-model="names" placeholder="您的称呼"></el-input>
            <div style="margin: 3px 0; color: red;" :class="{ 'hiddenBtn': namesFlag == 0 }">称呼不能为空</div>
            <el-input v-model="phone" placeholder="联系电话"></el-input>
            <div style="margin: 3px 0; color: red;" :class="{ 'hiddenBtn': phoneFlag == 0 }">联系电话不正确</div>

            <el-input v-model="email" placeholder="电子邮件"></el-input>
            <div style="margin: 3px 0; color: red;" :class="{ 'hiddenBtn': emailFlag == 0 }">电子邮件不正确</div>

            <el-input v-model="adress2" placeholder="联系地址"></el-input>
            <div style="margin: 3px 0; color: red;" :class="{ 'hiddenBtn': adressFlag == 0 }">联系地址不能为空</div>

            <el-input type="textarea" :rows="6" v-model="area" placeholder="留言内容"></el-input>
            <div style="margin: 3px 0; color: red;" :class="{ 'hiddenBtn': areaFlag == 0 }">内容不能为空</div>

        </div>

        <div class="btn" @click="sub">
            提交留言
        </div>
    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import map from '../components/map.vue'

export default {
    data() {
        return {
            address: null, // 地址信息
            businessDetail: {},  //商家信息(包含坐标信息)
            //地图数据
            mapData: {
                center: { lng: 113.443304, lat: 23.168608 },//中心坐标
                zoom: 18,    //缩放级别
            },
            BMap: null, //BMap类
            map: null,  //地图对象,
            ContactUs: '',
            latitude: '',
            longitude: '',
            Web: '',

            //表单数据
            names: '',
            phone: '',
            email: '',
            adress2: '',
            area: '',
            namesFlag: 0,
            phoneFlag: 0,
            emailFlag: 0,
            adressFlag: 0,
            areaFlag: 0
        }
    },

    components: {
        searchBar,
        classifyBar,
        map
    },

    methods: {
        skipPage(page) {
            window.location = page
        },

        async mapHandler({ BMap, map }) {
            if (this.businessId) {
                //可以在此处请求接口获取坐标数据
                await this.getMallBusinessDetail()
            }

            this.BMap = BMap //保存百度地图类
            this.map = map //保存地图对象
            //如果一开始坐标存在(编辑的时候)
            if (this.businessDetail.longitude && this.businessDetail.latitude) {
                //设置坐标
                this.mapData.center.lng = this.businessDetail.longitude
                this.mapData.center.lat = this.businessDetail.latitude
            } else {
                //如果坐标不存在则动态获取当前浏览器坐标（创建的时候）
                let geolocation = new BMap.Geolocation()
                //获取当前的坐标（使用promise 将异步转换为同步）
                await new Promise((resolve) => {
                    geolocation.getCurrentPosition(() => {
                        // this.mapData.center.lng = this.businessDetail.longitude = r.point.lng
                        // this.mapData.center.lat = this.businessDetail.latitude = r.point.lat
                        this.mapData.center.lng = this.longitude
                        this.mapData.center.lat = this.latitude //这里的经纬度是固定的，没有动态获取，上面注释的两行是动态获取的
                        resolve()
                    })
                })
            }
            //创建定位标记
            let marker = new BMap.Marker(
                new BMap.Point(
                    this.longitude,
                    this.latitude
                )
            )

            map.addOverlay(marker)  //将标记添加到地图上
        },
        //在地图上选择区域
        // getLocation(e) {
        //     this.businessDetail.longitude = e.point.lng  //设置经度
        //     this.businessDetail.latitude = e.point.lat  //设置纬度
        //     let BMapGL = this.BMap  //百度地图类
        //     let map = this.map//地图对象 
        //     map.clearOverlays()   //清除地图上所有的覆盖物(保证每次点击只有一个标记)
        //     //创建定位标记
        //     let marker = new BMapGL.Marker(new BMapGL.Point(e.point.lng, e.point.lat))   //将标记添加到地图上
        //     map.addOverlay(marker)      //创建坐标解析对象
        //     let geoc = new BMapGL.Geocoder()  //解析当前的坐标成地址
        //     geoc.getLocation(e.point, (rs) => {
        //         let addressComp = rs.addressComponents  //获取地址对象
        //         //拼接出详细地址
        //         this.businessDetail.address =
        //             addressComp.province +
        //             addressComp.city +
        //             addressComp.district +
        //             addressComp.street +
        //             addressComp.streetNumber
        //     })
        // },

        //提交
        sub() {
            if (this.names == '') {
                this.namesFlag = 1
                return
            } else {
                this.namesFlag = 0
            }

            if (this.phone == '') {
                this.phoneFlag = 1
                return
            } else {
                this.phoneFlag = 0
            }
            if (this.email == '') {
                this.emailFlag = 1
                return
            } else {
                this.emailFlag = 0
            }
            if (this.adress2 == '') {
                this.adressFlag = 1
                return
            } else {
                this.adressFlag = 0
            }

            if (this.area == '') {
                this.areaFlag = 1
                return
            } else {
                this.areaFlag = 0
            }

            this.setContact()
        },
        //经纬度转换
        qqMapTransBMap(lng, lat) {
            let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
            let x = lng;
            let y = lat;
            let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
            let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
            let lngs = z * Math.cos(theta) + 0.0065;
            let lats = z * Math.sin(theta) + 0.006;

            return {
                lng: lngs,
                lat: lats
            }
        },

        //获取联系我们
        async getContactUs() {
            let res = await this.$http('/mini/ApiConnect/getContactUs')
            this.ContactUs = res.result

            const targetPoint = this.qqMapTransBMap(this.ContactUs.longitude, this.ContactUs.latitude)
            console.log('getContactUs', targetPoint)
            this.latitude = targetPoint.lat
            this.longitude = targetPoint.lng
        },

        //获取联系我们
        async WebConfig() {
            let res = await this.$http('/mini/ApiConnect/WebConfig')
            this.Web = res.result
        },

        //提交表单
        async setContact() {
            let userId = window.localStorage.getItem('user_id')

            let params = {
                user_id: userId,
                name: this.names,
                mobile: this.phone,
                email: this.email,
                address: this.adress2,
                content: this.area
            }

            let res = await this.$http('/mini/ApiConnect/setContact', params)
            console.log('123', res)
            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
            }

        },

    },

    mounted() {
        this.getContactUs()
        this.WebConfig()
    }
}
</script>
<style lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006EB7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
.content {
    width: 1200px;
    margin: 0 auto;
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.classifyBox {
    margin-top: 29px;
}

.location {
    width: 1200px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .nav {}
}

.mapBox {
    width: 1000px;
    height: 400px;
}

.BaiDuMap {
    width: 1000px;
    height: 400px;
}

.map-wrap {
    height: 400px;

}

.titleBox {
    color: #000000ff;
    font-size: 40px;
    text-align: center;
}

.theLine {
    width: 711px;
    height: 7px;
    background: #d0d5dd;
    margin-top: 13px;
    margin-bottom: 32px;
}

.textBox {
    width: 1000px;
    margin-top: 40px;
    display: flex;
    // justify-content: center;
    align-items: flex-end;

    .left {
        width: 50%;
        color: #000000ff;
        font-size: 22px;
        line-height: 45px;
    }

    .right {
        margin-left: 180px;
        display: flex;
        justify-content: center;
        align-items: center;

        .one {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 40px;
        }
    }
}

.formBox {
    width: 891px;


    ::v-deep .el-input {
        // margin-bottom: 20px;
    }

    ::v-deep .el-input__inner {
        background-color: #F2F2F2;
        border: 1px solid black;
    }

    ::v-deep .el-textarea__inner {
        background-color: #F2F2F2;
        border: 1px solid black;
    }
}

.hiddenBtn {
    visibility: hidden;
}

.btn {
    width: 142px;
    height: 42px;
    background: #006eb7;
    text-align: center;
    line-height: 42px;
    color: white;
    margin-top: 40px;
    cursor: pointer;
    margin-bottom: 50px;

}
</style>