<template>
    <div>
        <div class="topbgc" :style="$route.path == '/buyerCenter/attention' ? 'height:188px;' : 'height:168px;'"></div>
        <div class="content1">
            <div @click="skipPage('/')" style="position: relative;width: 100%;height: 60px;">
                <!-- <img src="../assets/theLogo.png" class="img1"> -->
                <!-- <img src="../assets/login/口号_工业原材料商城 1.png" class="img2"> -->
                <img src="../assets/zhnlImg/Logo 中海南联原材料资讯商城.png" class="img1">
            </div>


            <div class="right flexRowCenter">
                <div class="inputBar flexRowCenter">
                    <el-input v-model="search" @keyup.enter.native="searchFn" placeholder="请输入产品名称"
                        prefix-icon="el-icon-search"></el-input>
                </div>
                <div class="btn" @click="searchFn">搜索</div>
                <div class="btn" style="margin-left: 12px;" @click="skipOpen">申请厂商</div>

                <!-- <div class="hotSearch">热搜：D40溶剂油 3#白油</div> -->
            </div>

        </div>
        <div class="navBarUU" v-if="shownav && bol">
            <div :class="ishd == 0 ? 'xzl' : ''" @click="gotoym(0)">严选 ({{ data.goods_num }})</div>
            <div :class="ishd == 1 ? 'xzl' : ''" @click="gotoym(1)">原材料 ({{ data.material }})</div>
            <div :class="ishd == 2 ? 'xzl' : ''" @click="gotoym(2)">企业 ({{ data.com }})</div>
            <div :class="ishd == 3 ? 'xzl' : ''" @click="gotoym(3)">百科 ({{ data.wikipedia }})</div>
            <div :class="ishd == 4 ? 'xzl' : ''" @click="gotoym(4)">资讯 ({{ data.common }})</div>
        </div>
        <div :style="shownav && bol ? 'margin-top:10px' : ''">
            <dayHot v-if="showDayHot"></dayHot>
        </div>

    </div>
</template>

<script>
import dayHot from './dayHot.vue'
export default {
    components: {
        dayHot
    },
    data() {
        return {
            search: '',
            IsSupplierFlag: '',
            ishd: 0,
            data: {},
            isT: true,
            showDayHot: false,
            bol: false,
            shownav: false

        }
    },
    computed: {
        isUU() {
            return this.$route.name === 'index' ? false : search
        }
    },
    methods: {

        gotoym(val) {

            this.isT = false
            if (val == 0) {
                this.skipPage('/strictStore?keywords=' + this.search)
                localStorage.setItem('ishd', 0)
            } else if (val == 1) {
                this.skipPage('/store?keywords=' + this.search)
                localStorage.setItem('ishd', 1)

            } else if (val == 2) {
                this.skipPage('/buyerCenter/attention?keywords=' + this.search)
                localStorage.setItem('ishd', 2)

            } else if (val == 3) {
                this.skipPage('/encyclopedia?keywords=' + this.search)
                localStorage.setItem('ishd', 3)

            } else if (val == 4) {
                this.skipPage('/message?keywords=' + this.search)
                localStorage.setItem('ishd', 4)

            }

        },
        skipOpen(page) {
            if (window.localStorage.getItem('user_id')) {
                this.$router.push({
                    path: '/buyerCenter/application'
                })
            } else {
                this.$message(
                    {
                        type: 'warning',
                        message: '请先登录您的账号',
                        offset: 230,
                    }
                )
                this.$router.push({
                    path: '/login'
                })
            }
            // if (this.IsSupplierFlag == 2) {
            //     this.skipPage('/issueProduct')
            // } else {
            //     this.skipPage('/buyerCenter/suppliers')
            // }
        },
        skipPage(page) {
            window.location = page
        },

        //是否已成为供应商
        // async IsSupplier () {
        //     let userId = window.localStorage.getItem('user_id')

        //     let res = await this.$http('/mini/ApiConnect/IsSupplier', { user_id: userId })

        //     this.statusTxt = res.msg


        //     if (res.msg == "已成为供应商") {
        //         this.IsSupplierFlag = 2
        //     }

        // },
        searchFn() {
            this.skipPage('/strictStore?keywords=' + this.search)
        }


    },
    created() {


        let uu = this.$route.query.keywords

        // if (uu) {
        //     this.showDayHot = true
        //     this.shownav=true
        //     this.search = uu
        //     let params = {
        //         keyword: this.search
        //     }
        //     this.$http('/mini/ApiConnect/searchNum', params).then(res => {
        //         console.log(res);
        //         if (res.status == 1) {
        //             this.data = res.result
        //         }
        //     })
        // } else {
        //     this.showDayHot = false
        // }

        if (this.$route.path == '/strictStore') {
            this.bol = true
            this.ishd = 0
        } else if (this.$route.path == '/store') {
            this.ishd = 1
            this.bol = true
        } else if (this.$route.path == '/buyerCenter/attention') {
            this.ishd = 2
            this.bol = true
        } else if (this.$route.path == '/encyclopedia') {
            this.ishd = 3
            this.bol = true
        } else if (this.$route.path == '/message') {
            this.ishd = 4
            this.bol = true
        } else {
            this.bol = false
        }


    },

    mounted() {
        // this.IsSupplier()
    }
}
</script>

<style lang="scss">
.content1 {

    .inputBar {
        width: 675px;
        height: 42px;

        .el-color-picker__icon,
        .el-input,
        .el-textarea {
            border: 0.5px solid rgb(34, 123, 255);

        }

        .el-input__inner:focus {
            // el-input输入时设置边框颜色
            border: #006eb7;
        }

        .el-input--prefix .el-input__inner {
            height: 40px;
        }
    }
}

.navBarUU {
    width: 1200px;
    margin: auto;
    margin-top: 15px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    // margin-bottom: 20px;
    text-align: center;

    .xzl {
        color: #006EB7;
        border-bottom: 2px solid #006EB7;
    }

    div {
        cursor: pointer;
        width: 100px;
        height: 22px;
    }
}
</style>

<style lang="scss" scoped>
.topbgc {
    position: absolute;
    left: 0;
    top: 0;
    width: 99.6vw;
    height: 168px;
    background-color: #fff;
    z-index: -1;
}

::v-deep .content1 .inputBar .el-color-picker__icon,
.content1 .inputBar .el-input,
.content1 .inputBar .el-textarea {
    border-radius: 4px 0 0 4px;
}

::v-deep .el-input__inner {
    background-color: #F2F2F2 !important;
}

.content1 {
    width: 1200px;
    margin: auto;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    .img1 {

        object-fit: contain;
        width: 170px;
        height: 109px;
        cursor: pointer;
        position: absolute;
        top: -18px;
        left: 42%;
        transform: translateX(-50%);

    }

    .img2 {
        object-fit: contain;
        width: 188px;
        height: 57px;
        cursor: pointer;
        position: absolute;
        top: 25px;
        left: 57%;
        transform: translateX(-50%);
    }
}

.right {

    position: relative;

}

.btn {
    width: 142px;
    height: 42px;
    background-color: rgb(34, 134, 255);
    text-align: center;
    line-height: 42px;
    color: white;
    cursor: pointer;
}

.btn:hover {
    background-color: rgb(34, 134, 255);
}

.hotSearch {
    position: absolute;
    bottom: -19px;
    left: 0;
    font-size: 10px;
    color: #AEAEAE;
}

::v-deep .el-input--prefix .el-input__inner {
    padding-left: 45px;
}

::v-deep .inputBar .el-input__icon {
    color: #006EB7;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 3px;
}
</style>