<template>
    <div>
        <div class="quotedBox">
            <div class="title">产品管理</div>

            <div class="btn_box">
                <div class="left_btn">
                    <div class="btn1" @click="skip_issue">
                        <i class="el-icon-circle-plus-outline"></i>
                        新增产品
                    </div>

                    <div class="btn2" v-if="this.tab_flag == 2" @click="IsOnSale(3)">
                        <i class="el-icon-receiving"></i>
                        批量下架
                    </div>

                    <div class="btn2" v-if="this.tab_flag == 1 || this.tab_flag == 3" @click="IsOnSale(2)">
                        <i class="el-icon-receiving"></i>
                        批量上架
                    </div>

                    <div class="btn3" @click="allDel(2)">
                        <i class="el-icon-delete"></i>
                        批量删除
                    </div>
                    <div class="ipt">
                        <div>
                            <el-input placeholder="请输入产品名称" v-model="product_name">
                                <i slot="prefix" class="el-input__icon el-icon-search"
                                    style="font-size: 18px; line-height: 34px;color: #006eb7;"></i>

                            </el-input>
                        </div>
                        <div @click="getUserProduct(0)">查询</div>

                    </div>
                </div>
            </div>

            <div class="line1">
                <div class="tag_box">
                    <div class="tab1" :class="{ 'tab_color': tab_flag == 0 }" @click="pro_list(0)">全部</div>
                    <div class="tab1" :class="{ 'tab_color': tab_flag == 1 }" @click="pro_list(1)">已发布</div>
                    <div class="tab1" :class="{ 'tab_color': tab_flag == 2 }" @click="pro_list(2)">已上架</div>
                    <div class="tab1" :class="{ 'tab_color': tab_flag == 3 }" @click="pro_list(3)">已下架</div>
                </div>
            </div>

            <div class="product_title">
                <div class="all_select">
                    <!-- <div class="select_box" v-if="is_allSelect == 1" @click="allSelect">

                    </div>
                    <div class="select_box isSelect" v-if="is_allSelect == 2" @click="allSelect">
                        <i class="el-icon-check" style="color: white"></i>
                    </div> -->
                    <!-- 全选 -->
                </div>
                <div class="product_name">
                    商品名称
                </div>

                <div class="unit_price">
                    单价(元) <i class="el-icon-sort" style="font-weight: 700;"></i>
                </div>

                <div class="inventory">
                    库存 <i class="el-icon-sort" style="font-weight: 700;"></i>
                </div>

                <div class="date">
                    更新时间 <i class="el-icon-sort" style="font-weight: 700;"></i>
                </div>
                <div class="operation">
                    操作 <i class="el-icon-sort" style="font-weight: 700;"></i>
                </div>
            </div>

            <div class="product_box">
                <div class="one" v-for="(item, index) in goods_list"
                    :class="item.isOnSale && disabledType == 1 ? 'wdd' : ''">
                    <div class="select_box" v-if="!item.isSelect" @click="tap_select(index, item)">
                    </div>
                    <div class="select_box isSelect" v-if="item.isSelect" @click="tap_select(index, item)">
                        <i class="el-icon-check" style="color: white"></i>
                    </div>
                    <img :src="item.imgList[0]" style="width: 90px; height: 90px; margin-left: 10px;">

                    <div class="name_box">
                        <div class="name">
                            <div class="ov1"> {{ item.goodsName }}</div>
                            <div class="ov1" style="font-size: 14px;font-weight: 400;margin-top: 10px;">ID: {{
                        item.goodsSn
                    }}
                            </div>
                        </div>
                    </div>

                    <div class="price">
                        ¥{{ item.shopPrice }}/{{ item.specName }}
                    </div>
                    <div class="inventory">
                        {{ `${item.storeCount}${item.specName}` }}
                    </div>

                    <div class="date">
                        {{ item.lastUpdate }}
                    </div>
                    <div class="operation">
                        <span style="color: #000000; cursor: pointer;" @click="skip_issue2(item)">编辑</span>
                        <span style="color: #FF0C0C; margin-left: 22px; cursor: pointer;"
                            @click="del(1, item.goodsId)">删除</span>
                    </div>
                </div>


                <div class="page">
                    <el-pagination layout="prev, pager, next" :total="totalCount" :page-size="size" prev-text="上一页"
                        next-text="下一页" @next-click="nextclick" @prev-click="prevclick" @current-change="currentChange">
                    </el-pagination>
                </div>

            </div>
        </div>

        <!-- :before-close="handleClose" -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span>确定要删除吗?</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getGoods, deleteGoods, groundingGoods, underGoods } from '@/api/user'
export default {
    data() {
        return {
            disabledType: '1',
            dialogVisible: false,
            tab_flag: 1,
            userId: '',
            is_allSelect: 1,
            goods_list: '',
            totalCount: 0,
            page: 1,
            product_name: '',
            size: 5,
            newArr2: [],
        }
    },

    methods: {
        // searchProduct() {
        //     product_name
        // },
        skip_issue() {
            // window.open('/issueProduct')
            this.$router.push({
                path: '/issueProduct'
            })
        },
        skip_issue2(item) {
            console.log(item, 'dd');
            this.$router.push({ path: '/issueProduct', query: { data: item } })
            // window.open('/issueProduct?id=' + item.goods_id + `&cat_id_3=${item.cat_id_3}&store_count=${item.store_count}&logistics_name=${item.logistics_name}&logistics_type_id=${item.logistics_type_id}&spec_name=${item.spec_name}&spec_id=${item.spec_id}`)
        },
        nextclick() {
            this.page = this.page + 1
            if (this.tab_flag == 0) {
                this.getUserProduct()
            } else {
                this.getUserProduct(2)
            }
        },

        prevclick() {
            if (this.page > 0) {
                this.page = this.page - 1
                if (this.tab_flag == 0) {
                    this.getUserProduct()
                } else {
                    this.getUserProduct(2)
                }
            }
        },

        currentChange(e) {
            this.page = e
            console.log(e, 'eee....');
            this.getUserProduct(this.tab_flag)
            // if (this.tab_flag == 0) {

            // } else {
            //     this.getUserProduct(2)
            // }
        },


        pro_list(flag) {
            this.page = 1
            if (flag != 1) {
                this.disabledType = ''
            } else {
                this.disabledType = '1'
            }
            this.tab_flag = flag
            this.is_allSelect = 1

            this.getUserProduct(flag)

        },
        tap_select(the_index, item) {
            console.log(the_index, 'the_index', item);
            this.goods_list.forEach((e, index) => {
                if (index == the_index) {
                    let isSelect2 = this.goods_list[the_index].isSelect
                    let obj = this.goods_list[the_index]
                    obj.isSelect = !isSelect2
                    this.$set(this.goods_list, the_index, obj)
                    console.log('select', this.goods_list[the_index].isSelect)
                }
            })
            let newArr = this.goods_list.filter((item, index) => {
                return item.isSelect == true
            })
            let newArr2 = newArr.map((item) => {
                return item.goodsId
            })
            this.newArr2 = newArr2
            console.log(newArr, 'newArr-------------', newArr2);
        },

        allSelect() {
            if (this.is_allSelect == 1) {

                this.is_allSelect = 2
                this.goods_list.forEach((e, index) => {
                    e.isSelect = true
                })
            } else {
                this.is_allSelect = 1
                this.goods_list.forEach((e, index) => {
                    e.isSelect = false
                })
            }
        },

        allDel(flag) {
            this.$confirm('确定要删除吗?')
                .then(_ => {
                    this.DelUserProduct(this.newArr2)
                })
                .catch(_ => { });
        },

        del(flag, theId) {
            console.log(theId, '====');
            this.$confirm('确定要删除吗?')
                .then(_ => {
                    this.DelUserProduct(theId)
                })
                .catch(_ => { });
        },

        //删除原材料产品
        async DelUserProduct(theId) {
            console.log(typeof theId, 'typeof theId', theId);
            if (typeof theId == 'number') {
                console.log('123', theId)
                let a = theId
                let arr = [a]
                console.log(arr, 'eeeee');
                let params = {
                    goods_ids: arr
                }
                deleteGoods(params).then((res) => {
                    if (res.data.code == 200) {
                        this.getUserProduct()
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                    }
                })
            } else {
                let params = {
                    goods_ids: theId
                }
                deleteGoods(params).then((res) => {
                    if (res.data.code == 200) {
                        this.getUserProduct()
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                    }
                })
            }


            // let ids;
            // if (flag == 1) {
            //     ids = theId
            // }
            // if (flag == 2) {
            //     let arr = []
            //     this.goods_list.forEach((e, index) => {
            //         if (e.isSelect) {
            //             arr.push(e.goods_id)
            //         }
            //         ids = arr.toString()
            //     })
            // }
            // let params = {
            //     user_id: this.userId,
            //     ids: ids
            // }

            // let res = await this.$http('/mini/ApiConnect/DelUserProduct', params)
            // if (res.status == 1) {
            //     this.$message(res.msg);
            //     this.getUserProduct()
            // } else {
            //     this.$message.error(res.msg);
            // }
        },

        //产品列表
        async getUserProduct(flag = 1) {
            let params = {
                size: this.size,
                page: this.page,
                product_name: this.product_name,
                status: flag,
            }
            getGoods(params).then((res) => {
                this.goods_list = res.data.data
                this.totalCount = res.data.total
                console.log(this.totalCount, 'this.totalCount');
            })
            // this.is_allSelect = flag
            // let show = 1
            // if (flag == 2) {
            //     show = 0
            // }
            // let params = {
            //     user_id: this.userId,
            //     size: 4,
            //     status: flag,
            //     page: this.page,
            //     product_name:this.product_name
            // }
            // let res = await this.$http('/mini/ApiConnect/getUserProduct', params)
            // this.totalCount = res.count
            // this.goods_list = res.result
            // this.goods_list.forEach((e, index) => {
            //     e.date_time = e.date_time.slice(0, 10)
            //     e.isSelect = false
            // })
        },

        //上下架原材料产品
        async IsOnSale(flag) {
            console.log(this.newArr2, ';newArr2');
            if (flag == 2) {
                let params = {
                    goods_ids: this.newArr2
                }
                groundingGoods(params).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '上架成功'
                        })
                        this.page = 1
                        this.getUserProduct(1)
                    }
                })
            } else if (flag == 3) {
                let params = {
                    goods_ids: this.newArr2
                }
                underGoods(params).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '下架成功'
                        })
                        this.page = 1
                        this.getUserProduct(2)
                    }
                })
            }

            // let ids;
            // let arr = []
            // this.goods_list.forEach((e, index) => {
            //     if (e.isSelect) {
            //         arr.push(e.goods_id)
            //     }
            //     ids = arr.toString()
            // })
            // let params = {
            //     user_id: this.userId,
            //     ids: ids,
            //     is_on_sale: flag
            // }
            // let res = await this.$http('/mini/ApiConnect/IsOnSale', params)
            // if (res.status == 1) {
            //     this.$message(res.msg);
            //     if (flag == 0) {
            //         this.getUserProduct(1)
            //     } else {
            //         this.getUserProduct(2)

            //     }

            // } else {
            //     this.$message.error(res.msg);
            // }
        },


    },
    mounted() {
        this.userId = window.localStorage.getItem('user_id')
        this.getUserProduct()

    }
}
</script>

<style lang="scss" scoped>
.wdd {
    background-color: rgb(241, 241, 241);
    pointer-events: none;
}

.page {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding: 24px;
    padding-top: 16px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 16px;
        font-weight: 700;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 16px;
        box-sizing: border-box;
    }

    .btn_box {
        .left_btn {
            display: flex;
            align-items: center;
            margin-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            position: relative;

            .ipt {
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                height: 32px;
                border: #006EB7 1px solid;

                >div:nth-child(2) {
                    width: 109px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    opacity: 1;
                    background: #006eb7;
                    color: #fff;
                }
            }

            .btn1 {
                width: 100px;
                height: 28px;
                border-radius: 33px;
                background: #006eb7ff;
                color: white;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                margin-left: 13px;
                margin-right: 20px;
                cursor: pointer;
            }

            .btn1:hover {
                background: rgb(7, 141, 230);

            }

            .btn2 {
                width: 100px;
                height: 28px;
                border-radius: 33px;
                color: #006EB7;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                border: 1px solid #006eb7ff;
                margin-right: 20px;
                cursor: pointer;
            }

            .btn2:hover {
                background: #006eb7ff;
                color: white;

            }

            .btn3 {
                width: 100px;
                height: 28px;
                border-radius: 33px;
                color: #FF0C0C;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                border: 1px solid #FF0C0C;
                cursor: pointer;

            }

            .btn3:hover {
                background: #FF0C0C;
                color: white;

            }
        }
    }

    .line1 {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .tag_box {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            padding-left: 21px;

            .tab1 {
                margin-right: 35px;
                cursor: pointer;
            }

            .tab_color {
                color: #006EB7;
            }
        }
    }

    .product_title {
        width: 100%;
        height: 40px;
        background-color: #F2F4F5;
        display: flex;
        align-items: center;

        .all_select {
            width: 138px;
            display: flex;
            align-items: center;
            margin-left: 16px;

            .select_box {
                width: 14px;
                height: 14px;
                border-radius: 2px;
                border: 1px solid #000000ff;
                margin-right: 3px;
            }
        }

        .product_name {
            width: 250px;
        }

        .unit_price {
            width: 132px;
        }

        .inventory {
            width: 141px;
        }

        .date {
            width: 169px;
        }
    }

    .isSelect {
        background-color: #006EB7 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product_box {
        .one {
            display: flex;
            align-items: center;
            height: 117px;
            padding: 10px 0;
            border-bottom: 1px solid #e4e4e4;


            .select_box {
                width: 14px;
                height: 14px;
                border-radius: 2px;
                border: 1px solid #000000ff;
                margin-left: 16px;
                margin-right: 3px;
            }

            .name_box {
                width: 250px;
                height: 100%;
                margin-left: 15px;
                margin-top: 17px;

                .name {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: "Alibaba PuHuiTi";
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
            }

            .price {
                font-size: 14px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi";
                color: #FF0C0C;
                width: 106px;
                margin-left: 5px;
            }

            .inventory {
                font-size: 14px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi";
                width: 88px;
                text-align: center;
            }

            .date {
                font-size: 14px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi";
                width: 150px;
                text-align: right;
            }

            .operation {
                font-size: 14px;
                font-weight: 400;
                font-family: "Alibaba PuHuiTi";
                margin-left: 67px;
            }
        }
    }
}
</style>