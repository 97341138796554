<template>
  <div class="index-box" @mouseenter="allMouseEnter()">
    <loginDialog ref="loginDialog" />
    <el-dialog title="绑定手机号码" :visible.sync="dialogFormVisible" center width="440px" :show-close="ifClose" @close="closeDia" style="position: relative; padding: 0px">
      <img src="../assets/newImg3/Logo - 搜原网 - syw@1x.png" style="position: absolute; left: -156px; top: 39px" alt="" />
      <el-form :model="form">
        <el-form-item label="手机号码" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码" :label-width="formLabelWidth">
          <el-input v-model="form.code" autocomplete="off" style="width: 136px"></el-input>
          <el-button type="primary" :disabled="ifDisabled" @click="sentCode" style="margin-left: 20px">
            <span v-if="showCode">获取验证码</span>
            <span v-else>{{ wddCount }}</span>
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sentMobile" style="">确认绑定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogFormVisible2" center width="22%" :show-close="ifClose">
      <div style="margin: auto; width: 100%; text-align: center">您的账号已经被封禁</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sentMobile2">确 定</el-button>
      </div>
    </el-dialog>

    <div style="position: relative; z-index: 9999999">
      <div style="width: 30px; display: flex; position: absolute; top: 81px; height: 20px; right: 2px; cursor: pointer" @click="pushLa">
        <div v-show="this.shouldFadeOut == true">
          <img src="../assets/newImg/路径@1x (1).png" style="vertical-align: middle; margin-right: 1px; width: 20px" alt="" />
        </div>
        <div v-show="this.shouldFadeOut == false">
          <img src="../assets/newImg/路径@1x (2).png" style="vertical-align: middle; margin-right: 1px; width: 20px" alt="" />
        </div>
      </div>
    </div>

    <!-- <transition name="fade"> -->
    <div class="carouselContainer" style="position: relative" v-show="lunboShow" :class="{ 'fade-out': shouldFadeOut, 'fade-in': shouldFadeIn && !isBoxVisible }">
      <div style="width: 100%; height: 5rem; position: absolute; top: 570px; z-index: 99">
        <img src="../assets/newImg/矩形 1@1x.png" style="width: 100%; height: 5rem" alt="" />
      </div>
      <el-carousel :interval="5000" arrow="always" height="650px">
        <el-carousel-item>
          <img src="../assets/newImg3/3e03e527ea3a635565e2f21e78a8a53.jpg" style="width: 100vw; height: 650px" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/newImg3/a5d16ed1121257a0debfd73d726aaba.jpg" style="width: 100vw; height: 650px" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/newImg/轮播图 大宗原材料商城，供应链金融@1x.png" style="width: 100vw; height: 650px" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/newImg/轮播图 中海南联原材料商城@1x.png" style="width: 100vw; height: 650px" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- </transition> -->

    <div class="video-hot" :style="{ marginTop: marginTop + 'px' }">
      <div style="height: 2.5rem; width: 100%; display: flex">
        <div style="height: 2.5rem; color: #227bff; font-size: 26px; margin-left: 5px">热门视频</div>
        <div class="daySty">
          <dayHot :isP="true" />
        </div>
      </div>

      <div class="video-list">
        <div class="video-left" @mouseleave="allVideoHiden()">
          <div class="left-item" v-for="(item, index) in videoList" @click="toVedio(item)" :key="item.vId" :class="{ active: index == activeIndex }">
            <div style="width: 100%; height: 118px; position: relative" @mouseleave="handleMouseLeave()">
              <div style="position: absolute; bottom: 5px; right: 8px; font-size: 12px; color: #ffffff">
                {{ item.vAddTime.slice(0, 10) }}
              </div>
              <img :src="item.vImgUrl" style="width: 101%; height: 100%; object-fit: cover; border-radius: 12px" alt="" v-if="!(index == activeIndex)" @mouseenter="handleMouseEnter(index)" />
              <video :src="item.vVideoUrl" v-if="index == activeIndex" style="width: 100%; height: 100%; object-fit: fill; border-radius: 12px" autoplay muted loop></video>
            </div>
            <div style="padding: 2px 0px 0px 0px; color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ item.vTitle }}
            </div>
            <div style="color: #9499a0; font-size: 13px; padding-top: 2px; height: 20px">
              <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

              <span style="vertical-align: middle; color: #9499a0; font-size: 12px"> {{ item.vUploader }}</span>
            </div>
          </div>
        </div>
        <div class="video-right" @click="getVip">
          <img src="@/assets/newImg/Container@1x.png" style="width: 100%; height: 86%" alt="" />
          <img src="@/assets/newImg/Overlay@1x.png" style="width: 100%; height: 12%" alt="" />
        </div>
      </div>
    </div>

    <div class="tab">
      <div class="scroll_table2">
        <div style="display: inline-block; width: 100%">
          <el-table
            :data="tableList3"
            style=""
            class="table"
            id="czh"
            :class="['commonScrollbarTable']"
            :header-cell-style="{
              'text-align': 'center',
              'background-color': 'green',
              'font-weight': '900',
              color: '#227BFF',
              padding: '0',
              height: '46px',
              'font-family': 'MicrosoftYaHeiLight',
              'font-size': '14px'
            }"
            :row-style="{
              padding: '0px',
              cursor: 'pointer',
              color: '#227BFF',

              'background-color': 'rgba(30, 239, 243)'
            }"
            :cell-style="{
              'text-align': 'center',
              'font-size': '14px',
              padding: '0',
              height: '45.5px',
              color: '#227BFF'
            }"
            @row-click="tableClick2"
          >
            <el-table-column
              prop="date"
              label="日期"
              :style="{
                'font-size': '14px',
                width: '100px'
              }"
            >
            </el-table-column>
            <el-table-column prop="good_name" label="产品"> </el-table-column>
            <el-table-column prop="bqPrice" label="价格"> </el-table-column>
            <el-table-column prop="bus_name_abbrev" label="供货商"> </el-table-column>

            <el-table-column prop="up_price" label="涨跌">
              <template slot-scope="scope">
                <div style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                  <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                  <div style="text-align: center; overflow: auto">
                    {{ scope.row.up_price }}
                  </div>
                </div>

                <div style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 2">
                  <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                  <div style="text-align: center">
                    {{ scope.row.up_price }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div @click="godeilt($event)" style="position: relative">
            <vue-seamless-scroll :data="tableList3" class="seamless-warp2" style="width: 100%" :class-option="classOption3">
              <el-table
                :data="tableList3"
                class="table_scroll"
                :class="['commonScrollbarTable']"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': '#EFEFEF',
                  'font-weight': '900',
                  padding: '0',
                  height: '32px',
                  'font-family': 'Microsoft YaHei UI Bold',
                  'font-size': '14px'
                }"
                :row-style="{
                  padding: '0px',
                  cursor: 'pointer',
                  'font-weight': '900',
                  color: '#227BFF'
                }"
                :cell-style="{
                  color: '#227BFF',
                  'text-align': 'center',
                  'font-size': '14px',
                  padding: '0',
                  height: '45.5px'
                }"
              >
                <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                  <template slot-scope="scope">
                    <div :data-id="JSON.stringify(scope.row)" style="font-size: 14px; text-align: center; color: #227bff">
                      {{ scope.row.add_time.slice(5, scope.row.date?.length) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="good_name" label="产品名称">
                  <template slot-scope="scope">
                    <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                      {{ scope.row.good_name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="价格">
                  <template slot-scope="scope">
                    <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                      {{ scope.row.price }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="bus_name_abbrev" label="供货商">
                  <template slot-scope="scope">
                    <div style="color: #227bff" :data-id="JSON.stringify(scope.row)">
                      <img src="../assets/newImg3/Icon_SVIP1@1x.png" style="vertical-align: middle" alt="" />
                      <span style="vertical-align: middle"> {{ scope.row.bus_name_abbrev }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="up_price" label="涨跌">
                  <template slot-scope="scope">
                    <div :data-id="JSON.stringify(scope.row)" style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 0">
                      <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; overflow: auto; font-weight: 290">
                        {{ scope.row.up_price }}
                      </div>
                    </div>

                    <div :data-id="JSON.stringify(scope.row)" style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                      <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; font-weight: 400">
                        {{ scope.row.up_price }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div style="height: 103%; margin-left: 7px; overflow: hidden; border-radius: 12px; margin-bottom: 100px; width: 50%">
        <div class="line1">
          <div class="scroll_table">
            <div style="display: inline-block; width: 100%">
              <el-table
                :data="tableList2"
                class="table"
                :class="['commonScrollbarTable']"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'rgba(230, 239, 243)',
                  color: '#227BFF',
                  padding: '0',
                  height: '46px',
                  'font-family': 'MicrosoftYaHeiLight',
                  'font-size': '14px'
                }"
                :row-style="{
                  padding: '0px',
                  cursor: 'pointer',
                  'background-color': 'rgba(230, 239, 243)'
                }"
                :cell-style="{
                  'text-align': 'center',
                  'font-size': '14px',
                  padding: '0',
                  height: '45.5px'
                }"
                @row-click="tableClick2"
              >
                <el-table-column
                  prop="date"
                  label="日期"
                  :style="{
                    'font-size': '14px',
                    width: '100px'
                  }"
                >
                </el-table-column>
                <el-table-column prop="good_name" label="产品"> </el-table-column>
                <el-table-column prop="bqPrice" label="价格"> </el-table-column>
                <el-table-column prop="real_name" label="供货商" width="148"> </el-table-column>

                <el-table-column prop="up_price" label="涨跌">
                  <template slot-scope="scope">
                    <div style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                      <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center; overflow: auto">
                        {{ scope.row.up_price }}
                      </div>
                    </div>

                    <div style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 2">
                      <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                      <div style="text-align: center">
                        {{ scope.row.up_price }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div @click="godeilt($event)">
                <vue-seamless-scroll :data="tableList2" class="seamless-warp2" style="width: 100%" :class-option="classOption2">
                  <el-table
                    :data="tableList2"
                    class="table_scroll"
                    :class="['commonScrollbarTable']"
                    :header-cell-style="{
                      'text-align': 'center',
                      'background-color': '#EFEFEF',
                      padding: '0',
                      height: '32px',
                      'font-family': 'Microsoft YaHei UI Bold',
                      'font-size': '14px'
                    }"
                    :row-style="{
                      padding: '0px',
                      cursor: 'pointer'
                    }"
                    :cell-style="{
                      'text-align': 'center',
                      'font-size': '14px',
                      padding: '0',
                      height: '45.5px'
                    }"
                  >
                    <el-table-column prop="add_time" label="日期" :labelClassName="'uuu'">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="font-size: 14px; text-align: center; color: #333333">
                          {{ scope.row.add_time.slice(5, scope.row.date?.length) }}
                        </div>
                      </template>
                    </el-table-column>
                    <!-- @row-click="tableClick2" -->
                    <el-table-column prop="good_name" label="产品名称">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                          {{ scope.row.good_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                          {{ scope.row.price }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="real_name" label="供货商 " width="148">
                      <template slot-scope="scope">
                        <div style="color: #333333" :data-id="JSON.stringify(scope.row)">
                          <img src="../assets/newImg3/Icon_SVIP1@1x.png" style="vertical-align: middle" alt="" v-if="scope.row.is_supper_vip == 1" />
                          <img src="../assets/newImg3/黄金标识.png" style="vertical-align: middle" alt="" v-else-if="scope.row.is_bus_vip == 1" />

                          <span style="vertical-align: middle"> {{ scope.row.real_name }}</span>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="up_price" label="涨跌">
                      <template slot-scope="scope">
                        <div :data-id="JSON.stringify(scope.row)" style="color: #21a00c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 0">
                          <img src="../assets/newImg/路径@1x (5).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center; overflow: auto">
                            {{ scope.row.up_price }}
                          </div>
                        </div>

                        <div :data-id="JSON.stringify(scope.row)" style="color: #ff0c0c; font-size: 14px; display: flex; align-items: center; justify-content: center" v-if="scope.row.is_up == 1">
                          <img src="../assets/newImg/路径@1x (4).png" style="vertical-align: middle" alt="" />
                          <div style="text-align: center">
                            {{ scope.row.up_price }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="flex: 1; height: 101%; margin-left: 20px; background-color: white; cursor: pointer; border-radius: 12px; padding: 3px">
        <div class="information" @click="toConsult(0)">
          <div class="information-left">
            <img :src="IndustryInformationList[0]?.imgUrl" style="width: 100%; height: 95%; margin-top: 2px; object-fit: cover; border-radius: 12px" alt="" />
          </div>
          <div class="information-right" style="margin-left: 6px; padding: 8px; width: 70%">
            <div
              class="title-1"
              style="
                height: 19px;
                padding: 1px 1px 1px 1px;
                font-weight: 900;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #000000;
                font-size: 14px;
              "
            >
              {{ IndustryInformationList[0]?.bgcTitle }}
            </div>
            <div
              class="title-2"
              style="
                height: 47px;
                padding: 4px 2px 2px 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-size: 12px;
                color: #000000;
                width: 422px;
              "
            >
              {{ IndustryInformationList[0]?.bgcContent.replace(/<[^>]*>/g, "") }}
            </div>
            <div class="title-3" style="display: flex; height: 15%; padding: 10px 0px 0px 4px; font-size: 12px; color: #999999">
              <div style="display: flex">
                <img src="../assets/newImg/Vector@0.75x.png" style="vertical-align: middle; line-height: 8px; line-height: 13px; width: 13px; height: 13px" alt="" />

                <div style="margin-left: 3px; line-height: 14px">{{ IndustryInformationList[0]?.addTime.slice(0, 10) }}</div>
              </div>
              <div style="margin-left: 20px; line-height: 14px">阅读: {{ IndustryInformationList[0]?.readNum }}</div>
            </div>
          </div>
        </div>
        <div class="information" @click="toConsult(1)">
          <div class="information-left">
            <img :src="IndustryInformationList[1]?.imgUrl" style="width: 100%; height: 95%; margin-top: 2px; object-fit: cover; border-radius: 12px" alt="" />
          </div>
          <div class="information-right" style="margin-left: 6px; padding: 8px; width: 70%">
            <div
              class="title-1"
              style="
                height: 19px;
                padding: 1px 1px 1px 1px;
                font-weight: 900;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #000000;
                font-size: 14px;
              "
            >
              {{ IndustryInformationList[1]?.bgcTitle }}
            </div>
            <div
              class="title-2"
              style="
                height: 47px;
                padding: 4px 2px 2px 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-size: 12px;
                color: #000000;
                width: 422px;
              "
            >
              {{ IndustryInformationList[1]?.bgcContent.replace(/<[^>]*>/g, "") }}
            </div>
            <div class="title-3" style="display: flex; height: 15%; padding: 10px 0px 0px 4px; font-size: 12px; color: #999999">
              <div style="display: flex">
                <img src="../assets/newImg/Vector@0.75x.png" style="vertical-align: middle; line-height: 8px; line-height: 13px; width: 13px; height: 13px" alt="" />

                <div style="margin-left: 3px; line-height: 14px">{{ IndustryInformationList[1]?.addTime.slice(0, 10) }}</div>
              </div>
              <div style="margin-left: 20px; line-height: 14px">阅读: {{ IndustryInformationList[1]?.readNum }}</div>
            </div>
          </div>
        </div>

        <!-- <div style="flex: 2;height: 100%;">
                    <img :src="videoList[0].vImgUrl" alt="" style="width: 100%;height: 100%;object-fit: cover">
                </div>
                <div style="flex: 1;height: 100%;padding-left: 10px;">
                    <div style="margin-top: 6rem;">{{ videoList[0].vTitle }} </div>
                    <div style="color: gray;font-size: 13px;padding-top: 3px;">
                        <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                            style="vertical-align: middle;margin-right: 1px;" alt="">

                        <span style="vertical-align: middle;"> {{ videoList[0].vUploader }}</span>
                    </div>
                </div> -->
      </div>

      <!-- <div style="flex: 1.3;height: 100%;margin-left: 20px;background-color: white;cursor: pointer;border-radius: 5px;padding: 3px;"
                @click="toVedio(videoList[0])">
                <div class="information" @click="toConsult(2)">
                    <div class="information-left">
                        <img :src="IndustryInformationList[2].imgUrl" style="width: 100%;height: 85%;margin-top: 10px;"
                            alt="">
                    </div>
                    <div class="information-right" style="margin-left: 6px;padding: 8px;width: 70%;">
                        <div class="title-1"
                            style="height: 19px;padding: 1px 1px 1px 1px; font-weight:900;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;color: #000000;font-size: 14px;">
                            {{ IndustryInformationList[2].bgcTitle }}
                        </div>
                        <div class="title-2"
                            style="height: 45px;padding: 4px 2px 2px 2px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;font-size: 12px;color: #000000;;">
                            {{ IndustryInformationList[2].bgcContent }}
                        </div>
                        <div class="title-3"
                            style="display: flex;height: 15%;padding: 10px 0px 0px 4px;font-size: 12px;color: #999999;">
                            <div style="display: flex;">
                                <img src="../assets/newImg/Vector@0.75x.png"
                                    style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                                    alt="">

                                <div style="margin-left: 3px;line-height: 14px;">{{
                        IndustryInformationList[2].addTime.slice(0,
                            10)
                    }}</div>
                            </div>
                            <div style="margin-left: 20px;line-height: 14px;">阅读: {{ IndustryInformationList[2].readNum
                                }}</div>
                        </div>
                    </div>
                </div>
                <div class="information" @click="toConsult(3)">
                    <div class="information-left">
                        <img :src="IndustryInformationList[3].imgUrl" style="width: 100%;height: 85%;margin-top: 10px;"
                            alt="">
                    </div>
                    <div class="information-right" style="margin-left: 6px;padding: 8px;width: 70%;">
                        <div class="title-1"
                            style="height: 19px;padding: 1px 1px 1px 1px; font-weight:900;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;color: #000000;font-size: 14px;">
                            {{ IndustryInformationList[3].bgcTitle }}
                        </div>
                        <div class="title-2"
                            style="height: 45px;padding: 4px 2px 2px 2px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;font-size: 12px;color: #000000;">
                            {{ IndustryInformationList[3].bgcContent }}
                        </div>
                        <div class="title-3"
                            style="display: flex;height: 15%;padding: 10px 0px 0px 4px;font-size: 12px;color: #999999;">
                            <div style="display: flex;">
                                <img src="../assets/newImg/Vector@0.75x.png"
                                    style="vertical-align: middle;line-height: 8px;line-height: 13px;width: 13px;height: 13px;"
                                    alt="">

                                <div style="margin-left: 3px;line-height: 14px;">{{
                        IndustryInformationList[3].addTime.slice(0,
                            10)
                    }}</div>
                            </div>
                            <div style="margin-left: 20px;line-height: 14px;">阅读: {{ IndustryInformationList[3].readNum
                                }}</div>
                        </div>
                    </div>
                </div>
                <div style="flex: 2;height: 100%;">
                    <img :src="videoList[0].vImgUrl" alt="" style="width: 100%;height: 100%;object-fit: cover">
                </div>
                <div style="flex: 1;height: 100%;padding-left: 10px;">
                    <div style="margin-top: 6rem;">{{ videoList[0].vTitle }} </div>
                    <div style="color: gray;font-size: 13px;padding-top: 3px;">
                        <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                            style="vertical-align: middle;margin-right: 1px;" alt="">

                        <span style="vertical-align: middle;"> {{ videoList[0].vUploader }}</span>
                    </div>
                </div>
            </div> -->
    </div>

    <div class="edit">
      <div style="height: 2.5rem; display: flex; justify-content: space-between">
        <div style="color: #227bff; font-size: 26px; margin-left: 5px">编辑推荐</div>
        <div style="vertical-align: middle; line-height: 3.1rem; margin-right: 16px; cursor: pointer">
          <img src="../assets/newImg/Vector@1x (3).png" alt="" style="margin-right: 10px" @click="reduceNumber" v-show="videoNumber <= 1" />
          <img src="../assets/newImg/左箭头 黑.png" alt="" style="margin-right: 10px" v-show="videoNumber > 1" @click="reduceNumber" />
          <img src="../assets/newImg/Vector@1x (1).png" alt="" @click="addNumber" v-show="videoNumber < allPage" />
          <img src="../assets/newImg/右箭头淡色.png" style="width: 21px; height: 20px" alt="" v-show="videoNumber >= allPage" />
        </div>
      </div>
      <div class="edit-box">
        <div class="edit-item" v-for="item in videoList2" @click="toVedio(item)" :key="item.vId">
          <div style="position: relative">
            <div style="position: absolute; bottom: 8px; right: 3px; font-size: 12px; color: #ffffff">{{ item.vAddTime.slice(0, 10) }}</div>
            <img :src="item.vImgUrl" style="width: 100%; height: 116px; object-fit: cover; border-radius: 12px" alt="" />
          </div>
          <div style="color: #333333; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
            {{ item.vTitle }}
          </div>
          <div style="color: gray; font-size: 12px; padding-top: 3px; color: #9499a0">
            <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px" alt="" />

            <span style="vertical-align: middle">{{ item.vUploader }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="video-type" v-for="item in hangYeList">
      <div style="height: 2.5rem; width: 100%; display: flex">
        <div style="height: 2.5rem; color: #227bff; font-size: 26px; margin-left: 6px; font-weight: 400">
          {{ item.label }}
        </div>
        <div style="height: 2.5rem; color: #227bff; font-size: 14px; margin-left: 12px; line-height: 38px; font-weight: 290; cursor: pointer" @click="toVideo(item.gId)">更多 ></div>
        <div class="daySty2">
          <dayHot2 :isP="true" :quoteList="item.quoteList" />
        </div>
      </div>

      <div style="display: flex; height: 91%; width: 100%; flex-grow: 0; flex-shrink: 0">
        <div class="type-item" v-for="data in item.videoList" @click="toVedio(data)" :key="data.vId" style="cursor: pointer">
          <div class="type-item-img">
            <img :src="data.vImgUrl" alt="" style="height: 100%; width: 100%; border-radius: 12px; object-fit: cover" />
          </div>
          <div class="type-item-title">
            {{ data.vTitle }}
          </div>
          <div style="color: #9499a0; font-size: 12px; padding-top: 3px; height: 20px">
            <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle; margin-right: 1px; object-fit: cover" alt="" />

            <span style="vertical-align: middle"> {{ data.vUploader }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getHotVod,
  getBstQuotedPrice,
  getGoodsIndustryAdvisory,
  getQuotToIndex,
  getIndustryIsIndexPlay,
  getAllGoodsConsult,
  WeiChatCallback,
  getCode,
  updateUserByCode,
  getAllVod,
  getRecommendVod,
  getStatusByOrderSn
} from "@/api/user";
import dayHot from "../components/dayHot.vue";
import dayHot2 from "../components/dayHot2.vue";
import dayjs from "dayjs";
import VipDialog from "../components/VipDialog.vue";
import loginDialog from "../components/loginDialog.vue";

export default {
  data() {
    return {
      shouldFadeOut: false,
      shouldFadeIn: false,
      isBoxVisible: true,
      positionTimer2: "",
      positionTimer: "",
      positionTimer3: "",
      activeIndex: -1,
      hangYeList: [],
      laTop: 65,
      allPage: 0,
      totalPage: "",
      marginTop: 50,
      shouldDisappear: false, // 控制盒子是否应该消失的布尔值
      ifDisabled: false,
      showCode: true,
      form: {
        code: "",
        phone: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      videoNumber: 1,
      totalNumber: 0,
      showMany: true,
      classify: 0,
      inputData: "",
      search: false,
      videoShow: false,
      classifyList: [],
      videoList: [],
      videoList2: [],
      videoListType: [],
      videoListType2: [],
      videoListType3: [],
      videoListType4: [],
      videoListType5: [],
      videoListType6: [],
      tableList2: [],
      tableList3: [],

      quoteShow: false,
      materialShow: false,
      areaCategoryList2: [],
      areaCategoryList: [],
      lunboShow: true,
      IndustryInformationList: [],
      radio2: "",
      radio: "",
      openid: "",
      dialogFormVisible: false,
      dialogFormVisible2: false,
      formLabelWidth: "90px",
      wddCount: 60,
      ifClose: true
    };
  },
  components: {
    dayHot,
    dayHot2,
    VipDialog,
    loginDialog
  },
  methods: {
    allMouseEnter() {
      this.$emit("oneMouse");
    },
    getVip() {
      console.log(this.$refs.VipDialog.dialogVisible, "this.$refs.qrcode");
      // this.$refs.VipDialog.visible = true
      // this.$refs.VipDialog.getCode()
      // this.$refs.VipDialog.open()
    },
    godeilt(e) {
      const event = JSON.parse(e.target.dataset.id);
      this.tableClick2(event);
    },
    WatchVideoListType(gId) {
      let params2 = {
        industry: gId,
        industry2: "",
        page: 1,
        size: 30,
        title: ""
      };

      getAllVod(params2).then((res) => {
        this.videoListType = res.data.data.slice(0, 7);
        return this.videoListType;
      });
      // return this.videoListType
    },
    disappearBox() {
      this.shouldDisappear = true;
      console.log("123...dd");
    },
    toVideo(gId) {
      const { href } = this.$router.resolve({
        path: "/hotVideo",
        query: {
          gId: gId
        }
      });
      window.open(href, "_blank");
    },
    sentMobile() {
      console.log(this.form.phone, "this.form.phone");
      if (!this.form.phone) {
        return;
      }
      let params = {
        mobile: this.form.phone,
        code: this.form.code,
        openid: this.openid
        // openid: 'oDKHO6a0bT_Ofjbq6IyxuOX86SEU'
      };
      updateUserByCode(params).then((res) => {
        console.log(res, "绑定成功");
        if (res.data.code == 200) {
          window.localStorage.setItem("token", res.data.data.token);
          window.localStorage.setItem("user_id", res.data.data.userId);
          window.localStorage.setItem("userName", res.data.data.userName);
          window.localStorage.setItem("headPic", res.data.data.headPic);
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "绑定成功",
            offset: 82
          });
          // window.location.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    sentMobile2() {
      this.$router.push({
        path: "/login"
      });
      this.dialogFormVisible2 = false;
    },
    sentCode() {
      let params = {
        mobile: this.form.phone
      };
      this.ifDisabled = true;
      getCode(params).then((res) => {
        if (res.data.code == 200) {
          this.showCode = false;
          this.positionTimer = setInterval(() => {
            if (this.wddCount < 1) {
              clearInterval(this.positionTimer);
              this.positionTimer = null;

              this.showCode = true;
              this.ifDisabled = false;
              this.wddCount = 60;
            } else {
              this.ifDisabled = true;
              this.showCode = false;
              this.wddCount -= 1;
            }
          }, 1000);
        }
      });
    },
    addNumber() {
      if (this.totalPage < 14) {
        return;
      }
      this.videoNumber += 1;
      let params2 = {
        page: this.videoNumber,
        size: 14
      };
      getRecommendVod(params2).then((res) => {
        this.videoList2 = res.data.data;
      });
    },
    reduceNumber() {
      if (this.videoNumber > 1) {
        this.videoNumber -= 1;
        let params2 = {
          page: this.videoNumber,
          size: 14
        };
        getRecommendVod(params2).then((res) => {
          this.videoList2 = res.data.data;
        });
      }
    },
    showManys() {
      this.showMany = !this.showMany;
    },
    pushLa() {
      console.log("ddd...", this.shouldFadeOut);
      if (this.shouldFadeOut) {
        this.shouldFadeIn = true;
        this.shouldFadeOut = false;
        this.marginTop = 50;
      } else {
        console.log("走这里");
        this.shouldFadeOut = true;
        this.isBoxVisible = false;
        this.shouldFadeIn = false;
        this.marginTop = 116;
      }
      // this.isBoxVisible = true;
    },
    pushLa2() {},
    showSearch() {
      this.search = true;
    },
    goToday(data) {
      console.log(data);
      this.radio2 = data.gId;
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: this.radio,
            catTwo: this.radio2
          })
        }
      });
      window.open(href, "_blank");
    },
    materialMouse(data, index) {
      this.classify = index;
      this.radio = data.gId;
      console.log(data.gId, "..");
      let getArr = this.areaCategoryList.filter((item, index) => {
        return item.gId == data.gId;
      });
      this.areaCategoryList2 = getArr[0].children;
      console.log(this.areaCategoryList2, "this.areaCategoryList2");
    },
    toConsult(index) {
      const { href } = this.$router.resolve({
        path: "/titleDetails",
        query: {
          id: this.IndustryInformationList[index].bgcId
        }
      });
      window.open(href, "_blank");
    },
    goVideo(item) {
      const { href } = this.$router.resolve({
        path: "/hotVideo",
        query: {
          industry: item.gId
        }
      });
      window.open(href, "_blank");
    },
    goVideo2(item) {
      const { href } = this.$router.resolve({
        path: "/quotedPrice",
        query: {
          cat_one: item.gId,
          label: item.label
        }
      });
      window.open(href, "_blank");
    },
    handleMouseEnter(index) {
      console.log("鼠标已经进来了");

      this.activeIndex = index; // 更新被激活的盒子索引
    },
    handleMouseLeave() {
      console.log("鼠标已经离开了");
      this.activeIndex = -1; // 鼠标离开时，取消激活
    },
    allVideoHiden() {
      this.activeIndex = -1; // 鼠标离开时，取消激活
    },

    tableClick2(row) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: row.cat_one,
            catTwo: row.cat_two,
            catThree: row.cat_three,
            catFour: row?.cat_four,
            label: row.good_name
          })
        }
      });
      window.open(href, "_blank");
    },

    toRoute(id) {
      console.log("id", id);
      if (id == 1) {
        const { href } = this.$router.resolve({
          path: "/"
        });
        window.open(href, "_blank");
      } else if (id == 2) {
        const { href } = this.$router.resolve({
          path: "/store"
        });
        window.open(href, "_blank");
      } else if (id == 3) {
        const { href } = this.$router.resolve({
          path: "/strictStore"
        });
        window.open(href, "_blank");
      } else if (id == 4) {
        const { href } = this.$router.resolve({
          path: "/consult"
        });
        window.open(href, "_blank");
      } else if (id == 5) {
        const { href } = this.$router.resolve({
          path: "/hotVideo"
        });
        window.open(href, "_blank");
      } else if (id == 6) {
        const { href } = this.$router.resolve({
          path: "/quotedPrice"
        });
        window.open(href, "_blank");
      }
    },
    closeDia() {
      window.location.reload();
    },
    toVedio(row) {
      const { href } = this.$router.resolve({
        path: "/vudio",

        path: "/vudio",
        query: {
          v_id: row.vId,
          industry: this.$route.query.cat_id,
          industry2: this.$route.query.cat_id2
        }
      });
      window.open(href, "_blank");
    },
    goUser() {
      console.log(this.$route.path, "this.$route.path");
      if (this.$route.path == "buyerCenter/home") {
        return;
      } else {
        // const { href } = this.$router.resolve({
        //     path: "buyerCenter/home",
        // });
        // window.open(href, "_blank");
      }
    }
  },
  beforeMount() {
    let a = window.localStorage.getItem("user_id");
    if (this.$route.query.code != null) {
      if (a == null || a == undefined) {
        let params = {
          code: this.$route.query.code,
          state: ""
        };
        WeiChatCallback(params).then((res) => {
          if (res.data.code == 4014) {
            this.dialogFormVisible2 = true;
            return;
          }
          this.openid = res.data.data.openid;

          if (res.data.code == 200) {
            if (res.data.data.index == 0) {
              this.dialogFormVisible = true;
              window.localStorage.setItem("token", res.data.data.token);
              window.localStorage.setItem("user_id", res.data.data.userId);
              window.localStorage.setItem("userName", res.data.data.userName);
              window.localStorage.setItem("headPic", res.data.data.headPic);
            } else if (res.data.data.index == 1) {
              window.localStorage.setItem("token", res.data.data.token);
              window.localStorage.setItem("user_id", res.data.data.userId);
              window.localStorage.setItem("userName", res.data.data.userName);
              window.localStorage.setItem("headPic", res.data.data.headPic);

              this.dialogFormVisible = false;
              this.$router.go(0);
            }
          }
        });
      }
    }
  },
  created() {},
  computed: {
    classOption2() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      };
    },
    classOption3() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: this.limit2, // 开始无缝滚动的数据量 this.tableList
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  mounted() {
    getQuotToIndex({ size: 50 }).then((res) => {
      this.tableList3 = res.data.data;
    });
    if (this.$route.query.status == 1) {
      this.$refs.loginDialog.loginDialog();
    }
    // console.log(this.WatchVideoListType(295), 'this.WatchVideoListType(295)');
    // let userId = window.localStorage.getItem('user_id')
    // if (!userId) {
    //     this.$refs.loginDialog.loginDialog()
    // }
    getIndustryIsIndexPlay({ size: 10, video_size: 7, consult_size: 10, index: 0, quote_size: 30 }).then((res) => {
      if (res.data.code == 200) {
        this.hangYeList = res.data.data;
      }
    });
    this.positionTimer2 = setTimeout(() => {
      this.showManys();
      this.shouldFadeOut = true;
      this.isBoxVisible = false;
      this.marginTop = 116;
    }, 10000);

    let data = {
      cat_one: "",
      cat_two: "",
      cat_three: "",
      industry: "",
      industry2: "",
      size: 4,
      page: 1
    };
    getAllGoodsConsult(data).then((res) => {
      res.data.data.forEach((item, index) => {
        item.add_time = dayjs(item.add_time * 1000).format("YYYY-MM-DD");
      });
      this.IndustryInformationList = res.data.data;
    });

    getGoodsIndustryAdvisory().then((res) => {
      this.classifyList = res.data.data;
    });
    getBstQuotedPrice({ size: 61 }).then((res) => {
      this.newDayMarketData = res.data.data[0]?.addTime;
      this.tableList2 = res.data.data;
    });
    let params = {
      page: 1,
      size: 14
    };
    let datas = {
      page: 1,
      size: 10000
    };

    getRecommendVod(params).then((res) => {
      this.videoList2 = res.data.data.slice(0, 14);
      this.totalPage = res.data.totalPage;
    });
    getRecommendVod(datas).then((res) => {
      this.allPage = Math.ceil(res.data.totalPage / 14);
      // this.allPage = Number((res.data.totalPage / 14).toFixed(0))
    });
    getHotVod(params).then((res) => {
      this.videoList = res.data.data.slice(0, 12);
    });
  },
  destroyed() {
    clearInterval(this.positionTimer);
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 100px) {
  .index-box {
    font-family: "Microsoft YaHei" !important;
    width: 100%;
    position: relative;

    .showType {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 10rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      left: calc(9rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px; // font-size: 18px;
    }

    .showType2 {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 10rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      left: calc(17rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px; // font-size: 18px;
    }

    .showType3 {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 22rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      background-color: rgb(208, 234, 243);
      opacity: 0.9;
      left: calc(25rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px;

      .showType3-left::-webkit-scrollbar {
        width: 4px;
      }

      .showType3-left::-webkit-scrollbar-thumb {
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      .showType3-left::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      .showType3-left {
        width: 48%;
        border-right: 1px solid gray;
        height: 100%;
        overflow-y: scroll;
        // overflow: hidden;
        // background-color: pink;

        .showType3-left-item {
          padding: 5px 15px;
          cursor: pointer;
        }

        .showType3-left-item:hover {
          // color: rgb(32, 123, 255) !important;
        }
      }

      .showType3-right {
        width: 50%;
        height: 100%;

        .showType3-right-item {
          padding: 5px 15px;
          cursor: pointer;
        }

        .showType3-right-item:hover {
          color: rgb(32, 123, 255) !important;
        }
      }
    }

    .videoColor:hover {
      color: rgb(32, 123, 255) !important;
    }
  }

  .topBar {
    color: #333333;
    width: 100vw;
    height: 69px;
    z-index: 9999;
    // opacity: 1;
    position: fixed;
    top: 0px;
    display: flex;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(20px);
    // background-color: rgb(211, 239, 247);

    .img-box {
      height: 100%;
      width: 380px;
    }

    .title-box {
      display: flex;
      width: 60%;
      margin-left: 4rem;
      color: #333333;
      font-size: 18px;

      .title-item {
        padding: 1.1rem;
        font-family: "Microsoft YaHei Light";
      }

      .title-item:hover {
        color: rgb(32, 123, 255) !important;
      }
    }

    .icon-box {
      display: flex;
      width: 16%;
      // cursor: pointer;
    }
  }

  .carouselContainer {
    width: 100vw;
    height: 650px;
    top: 0;
    /* 初始位置 */
    opacity: 1;
    /* 初始透明度 */
    transform: scale(1);
    /* 初始缩放比例 */
    transition: transform 2s, opacity 2s, top 2s;
    /* 平滑过渡设置 */
  }

  .tab {
    width: 1230px;
    margin: auto;
    display: flex;
    height: 16rem;
    position: relative;
    margin-bottom: 3rem;
  }

  .video-hot {
    font-size: 16px;
    color: #333333;
    width: 1230px;
    margin: auto;
    margin-bottom: 3.6rem;
    overflow: hidden;

    .daySty {
      height: 2.5rem;
      width: 84%;
      margin-left: 50px;
      padding-top: 5px;
    }

    .tab {
      width: 1230px;
      margin: auto;
      display: flex;
      height: 16rem;
      position: relative;

      margin-bottom: 3rem;
    }

    .video-list {
      display: flex;
      width: 100%;
      height: 22.8rem;

      .video-left {
        flex: 10;
        display: flex;
        flex-wrap: wrap;

        .left-item {
          padding: 4px;
          // margin-left: 12px;
          height: 50%;
          width: 204.2px;
          cursor: pointer;
          margin-top: 3px;
        }

        .left-item:hover {
          color: rgb(32, 123, 255) !important;
        }
      }

      .video-right {
        flex: 1.5;
        margin-left: 7px;
      }
    }
  }

  .edit {
    overflow: hidden;
    height: 400px;
    width: 1230px;
    margin: auto;
    margin-top: 3rem;
    // height: 29rem;
    // background-color: pink;
    margin-bottom: 0.3rem;
    // background-color: white;

    .edit-box {
      width: 100%;
      height: 88%;
      flex-wrap: wrap;
      display: flex;

      .edit-item {
        cursor: pointer;
        width: 196px;
        height: 11.8rem;
        padding: 0px 4px;
        // background-color: skyblue;
      }
    }
  }

  .video-type {
    width: 1230px;
    height: 20rem;
    margin: auto;
    margin-bottom: 3rem;
    overflow: hidden;

    .daySty2 {
      height: 2.5rem;
      width: 74%;
      margin-left: 50px;
    }

    .type-item {
      box-sizing: border-box;
      min-width: 205px !important;
      height: 98%;
      padding: 2px 4px;

      .type-item-title {
        color: #333333;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .type-item-img {
        height: 83%;
        width: 100%;
      }
    }
  }

  .bgc {
    color: rgb(32, 123, 255) !important;
  }

  .el-table thead {
    height: 60px !important;
  }

  .information {
    width: 100%;
    height: 48%;
    padding: 3px 0px;
    display: flex;

    .information-left {
      width: 42%;
      height: 100%;
    }

    .information-right {
      width: 60%;
      height: 100%;
    }
  }

  ::v-deep .el-table__row {
    background-color: rgb(230, 239, 243) !important;
  }
}

@media screen and (min-width: 1500px) {
  .index-box {
    font-family: "Microsoft YaHei" !important;
    width: 100%;
    position: relative;

    .showType {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 10rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      left: calc(9rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px; // font-size: 18px;
    }

    .showType2 {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 10rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      left: calc(17rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px; // font-size: 18px;
    }

    .showType3 {
      font-size: 14px;
      padding-top: 12px;
      padding-left: 12px;
      display: flex;
      position: absolute;
      width: 30rem;
      height: 22rem;
      background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
      backdrop-filter: blur(55px);
      background-color: rgb(208, 234, 243);
      opacity: 0.9;
      left: calc(25rem + 380px);
      top: 74px;
      z-index: 9999;
      flex-wrap: wrap;
      color: #333333;
      border-radius: 6px;

      .showType3-left::-webkit-scrollbar {
        width: 4px;
      }

      .showType3-left::-webkit-scrollbar-thumb {
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      .showType3-left::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      .showType3-left {
        width: 48%;
        border-right: 1px solid gray;
        height: 100%;
        overflow-y: scroll;
        // overflow: hidden;
        // background-color: pink;

        .showType3-left-item {
          padding: 5px 15px;
          cursor: pointer;
        }

        .showType3-left-item:hover {
          // color: rgb(32, 123, 255) !important;
        }
      }

      .showType3-right {
        width: 50%;
        height: 100%;

        .showType3-right-item {
          padding: 5px 15px;
          cursor: pointer;
        }

        .showType3-right-item:hover {
          color: rgb(32, 123, 255) !important;
        }
      }
    }

    .videoColor:hover {
      color: rgb(32, 123, 255) !important;
    }
  }

  .topBar {
    color: #333333;
    width: 100vw;
    height: 69px;
    z-index: 9999;
    // opacity: 1;
    position: fixed;
    top: 0px;
    display: flex;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%);
    backdrop-filter: blur(20px);
    // background-color: rgb(211, 239, 247);

    .img-box {
      height: 100%;
      width: 380px;
    }

    .title-box {
      display: flex;
      width: 60%;
      margin-left: 4rem;
      color: #333333;
      font-size: 18px;

      .title-item {
        padding: 1.1rem;
        font-family: "Microsoft YaHei Light";
      }

      .title-item:hover {
        color: rgb(32, 123, 255) !important;
      }
    }

    .icon-box {
      display: flex;
      width: 16%;
      // cursor: pointer;
    }
  }

  .tab {
    width: 1430px;
    margin: auto;
    display: flex;
    height: 16rem;
    position: relative;

    margin-bottom: 3rem;
  }

  .carouselContainer {
    width: 100vw;
    height: 650px;
    top: 0;
    /* 初始位置 */
    top: 0;
    overflow: hidden;
    /* 防止内容溢出 */
    transition: height 2s, top 2s, opacity 2s;
    /* 平滑过渡设置 */
    opacity: 1;

    .my-carousel {
      height: 650px;
    }
  }

  /* 下拉出现的动画 */
  @keyframes fadeInDown {
    from {
      height: 0;
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      height: 650px;
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOutUp {
    from {
      height: 650px;
      opacity: 1;
      transform: translateY(0);
    }

    to {
      height: 0;
      opacity: 0;
      transform: translateY(-50px);
      /* 稍微向上移动以增强消失效果 */
    }
  }

  .fade-out {
    animation: fadeOutUp 2s forwards;
    /* 使用fadeOutUp动画，并在动画结束时保持最后一帧的状态 */
  }

  .fade-in {
    animation: fadeInDown 2s forwards;
    /* 使用fadeInDown动画，并在动画结束时保持最后一帧的状态 */
    height: 0;
    /* 初始时高度为0，以触发动画 */
    opacity: 0;
    transform: translateY(-50px);
  }

  .video-hot {
    font-size: 16px;
    color: #333333;
    width: 1430px;
    margin: auto;
    margin-bottom: 3.6rem;

    .video-list {
      display: flex;
      width: 100%;
      height: 22.8rem;

      .video-left {
        flex: 10;
        display: flex;
        flex-wrap: wrap;

        .left-item {
          padding: 4px;
          // margin-left: 12px;
          height: 50%;
          width: 198.2px;
          cursor: pointer;
          margin-top: 3px;
          transition: transform 0.5s ease;
          /* 过渡效果 */
        }

        .left-item.active {
          // transform: scale(1.4);
          // z-index: 9999;
          /* 放大一倍 */
        }

        .left-item:hover {
          color: rgb(32, 123, 255) !important;
        }
      }

      .video-right {
        flex: 1.5;
        margin-left: 7px;
      }
    }
  }

  .edit {
    width: 1430px;
    margin: auto;
    margin-top: 3rem;
    // height: 29rem;
    // background-color: pink;
    margin-bottom: 0.3rem;
    // background-color: white;

    .edit-box {
      width: 100%;
      height: 86%;
      flex-wrap: wrap;
      display: flex;

      .edit-item {
        cursor: pointer;
        width: 196px;
        height: 11.8rem;
        padding: 0px 4px;
        // background-color: skyblue;
      }
    }
  }

  .video-type {
    width: 1430px;
    height: 20rem;
    margin: auto;
    margin-bottom: 3rem;

    .type-item {
      .type-item-title {
        color: #333333;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .type-item-img {
        height: 83%;
        width: 100%;
      }

      box-sizing: border-box;

      width: 208px;
      height: 98%;
      padding: 2px 4px;
    }
  }

  .bgc {
    color: rgb(32, 123, 255) !important;
  }

  .el-table thead {
    height: 60px !important;
  }

  .information {
    width: 100%;
    height: 48%;
    padding: 3px 0px;
    display: flex;

    .information-left {
      // width: 100px;
      width: 42%;
      height: 100%;
      overflow: hidden;
    }

    .information-right {
      width: 60%;
      height: 100%;
      overflow: hidden;
    }
  }

  ::v-deep .el-table__row {
    background-color: rgb(230, 239, 243) !important;
  }
}

::v-deep .el-dialog__body {
  padding: 10px 30px 0px 30px !important;
}

.scroll_table {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 100%;

  .table_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 514px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      > span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  .seamless-warp {
    height: 482px;
    overflow: hidden;

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }

  .seamless-warp2 {
    height: 482px;
    overflow: hidden;
    // background-color: rgba(230, 239, 243);

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}

.scroll_table2 {
  margin-bottom: 15px;
  // margin-top: 5px;
  display: flex;
  width: 49.9%;
  z-index: 999;
  position: absolute;
  top: 43px;
  left: 8px;

  .table2_ul {
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    width: 100%;
    // height: 51px;
    background: #efefef;

    .li_nav {
      font-size: 13px;
      font-weight: 700;
      width: 88px;
      height: 32px;
      overflow: hidden;
      text-align: center;
      line-height: 32px;
    }
  }

  .warpw {
    height: 514px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .table_ul1 {
    // height: 514px;
    width: 100%;
    box-sizing: border-box;

    .ulis {
      width: 100%;

      > span {
        width: 88px;
        height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        line-height: 45px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  ::v-deep .table .el-table__body-wrapper {
    display: none;
  }

  // 重点注意这段样式
  .seamless-warp {
    height: 122px;
    overflow: hidden;

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }

  .seamless-warp2 {
    // height: 482px;
    height: 122px;
    overflow: hidden;
    // background-color: rgba(230, 239, 243);

    ::v-deep .table_scroll .el-table__header-wrapper {
      display: none;
    }
  }
}

.market {
  // margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  // align-items: flex-start;

  .line1 {
    width: 446px;
    background-color: white;
    // padding: 16px;
    margin-right: 30px;
    box-sizing: border-box;
    padding-bottom: 0;
    position: relative !important;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        // height: 80px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      // height: 2px;
      // background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 8px;
    }
  }

  .line2 {
    width: 240px;
    background-color: white;
    padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;
    padding-bottom: 0;

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        height: 80px;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 5px;
      height: 480px;

      .info9 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        height: 43px;
        line-height: 43px;
        font-size: 14px !important;
        border-bottom: 1px dashed #ebeef5;
      }
    }

    .contaion :hover {
      color: #006eb7 !important;

      .info {
        border-bottom: 1px solid #006eb7;
      }
    }
  }

  .line3 {
    width: 276px;
    background-color: white;
    // padding: 16px;
    // margin-right: 24px;
    box-sizing: border-box;
    padding-bottom: 0;

    .contaion {
      padding: 3px 8px;
    }

    .top {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        // padding-left: 5px;
        cursor: pointer;
        font-weight: 700;
        width: 100%;
        height: 80px;
        overflow: hidden;
      }

      .date {
        font-size: 10px;
        color: #aeaeae;
        margin-left: 6px;
      }
    }

    .theLine {
      height: 2px;
      background-color: #e4e4e4;
      margin-top: 7px;
    }

    .contaion {
      margin-top: 5px;
      height: 480px;

      .info9 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        height: 43px;
        line-height: 43px;
        font-size: 14px !important;
        border-bottom: 1px dashed #ebeef5;
      }
    }

    .contaion :hover {
      color: #006eb7 !important;

      .info {
        border-bottom: 1px solid #006eb7;
      }
    }
  }
}

::v-deep #czh .el-table__header-wrapper {
  display: none;
}

// .fade-image img {
//     transition: transform 1s ease-out, opacity 1s linear;
//     /* 设置过渡效果 */
// }

// .fade-enter-active,
// .fade-leave-active {
//     transition: opacity 1.7s;
//     /* 动画持续时间为0.5秒 */
// }

// .fade-enter,
// .fade-leave-to {
//     opacity: 0;
//     /* 初始和结束状态的不透明度为0 */
// }
</style>
