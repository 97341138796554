var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"quotedBox"},[_c('div',{staticClass:"title"},[_vm._v(" 退换商品 ")]),_c('div',{staticClass:"line1"},[_c('div',{staticClass:"left"},[_vm._l((_vm.OrderList.goodslist),function(item2,index2){return _c('div',{staticClass:"commodityBox"},[_c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"133px","height":"133px"},attrs:{"src":item2.original_img}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"commodityTitle"},[_vm._v(" "+_vm._s(item2.goods_name)+" ")]),_vm._m(0,true),_c('div',{staticClass:"numBox"},[_c('span',[_vm._v("数量")]),_c('div',{staticClass:"numBtn"},[_vm._v(" "+_vm._s(item2.goods_num)+" ")])]),_c('div',{staticClass:"price"},[_c('span',{staticStyle:{"font-size":"16px","color":"#000000ff"}},[_vm._v("价格")]),_c('span',{staticStyle:{"font-size":"28px","color":"#ff0c0cff","margin":"0 8px"}},[_vm._v(_vm._s(item2.goods_price))]),_c('span',{staticStyle:{"font-size":"12px","color":"#000000ff"}},[_vm._v(_vm._s(item2.spec_key_name))])])])])}),_c('div',{staticClass:"orderInfo"},[_c('div',{staticClass:"orderTitle"},[_vm._v("选择服务类型")]),_c('div',{staticClass:"putBox"},[_c('div',{staticClass:"line1"},[_c('div',{staticClass:"put1",on:{"click":function($event){return _vm.skipPage('/buyerCenter/afterSalePut?type=1&orderId=' + _vm.orderId)}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"put1",on:{"click":function($event){return _vm.skipPage('/buyerCenter/afterSalePut?type=2&orderId=' + _vm.orderId)}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"put1",on:{"click":function($event){return _vm.skipPage('/buyerCenter/afterSalePut?type=2&orderId=' + _vm.orderId)}}},[_vm._m(5),_vm._m(6)])])])])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve"},[_c('span',[_vm._v("服务")]),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("·七天无理由退货 ·运费险 ·安心购交期险 ·安心购品质险")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/buyerCenter/yy.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[_vm._v("我要退款(无需退货)")]),_c('div',{staticClass:"bottom"},[_vm._v("没收到货，或与卖家协商同意不用退货只退款")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/buyerCenter/rr.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[_vm._v("我要退货退款")]),_c('div',{staticClass:"bottom"},[_vm._v("已收到货，需要退还收到的货物")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/buyerCenter/tt.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[_vm._v("我要换货")]),_c('div',{staticClass:"bottom"},[_vm._v("已收到货，需要退还收到的货物")])])
}]

export { render, staticRenderFns }