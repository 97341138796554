<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                退换商品
            </div>

            <div class="line1">
                <div class="left">
                    <div class="commodityBox" v-for="(item2, index2) in OrderList.goodslist">
                        <div class="left">
                            <img :src="item2.original_img" style="width: 133px; height: 133px;">
                        </div>
                        <div class="right">
                            <div class="commodityTitle">
                                {{ item2.goods_name }}
                            </div>


                            <div class="serve">
                                <span>服务</span>
                                <span style="margin-left: 20px;">·七天无理由退货 ·运费险 ·安心购交期险 ·安心购品质险</span>
                            </div>


                            <div class="numBox">
                                <span>数量</span>
                                <div class="numBtn">
                                    {{ item2.goods_num }}
                                </div>
                            </div>

                            <div class="price">
                                <span style="font-size: 16px; color: #000000ff;">价格</span>
                                <span style="font-size: 28px; color: #ff0c0cff; margin: 0 8px;">{{ item2.goods_price
                                    }}</span>
                                <span style="font-size: 12px; color: #000000ff;">{{ item2.spec_key_name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="orderInfo">
                        <div class="orderTitle">选择服务类型</div>

                        <div class="putBox">
                            <div class="line1">
                                <div class="put1"
                                    @click="skipPage('/buyerCenter/afterSalePut?type=1&orderId=' + orderId)">
                                    <div class="left">
                                        <img src="../assets/buyerCenter/yy.png" style="width: 30px; height: 30px;">
                                    </div>
                                    <div class="right">
                                        <div class="top">我要退款(无需退货)</div>
                                        <div class="bottom">没收到货，或与卖家协商同意不用退货只退款</div>
                                    </div>
                                </div>

                                <div class="put1"
                                    @click="skipPage('/buyerCenter/afterSalePut?type=2&orderId=' + orderId)">
                                    <div class="left">
                                        <img src="../assets/buyerCenter/rr.png" style="width: 30px; height: 30px;">
                                    </div>
                                    <div class="right">
                                        <div class="top">我要退货退款</div>
                                        <div class="bottom">已收到货，需要退还收到的货物</div>
                                    </div>
                                </div>

                                <div class="put1"
                                    @click="skipPage('/buyerCenter/afterSalePut?type=2&orderId=' + orderId)">
                                    <div class="left">
                                        <img src="../assets/buyerCenter/tt.png" style="width: 30px; height: 30px;">
                                    </div>
                                    <div class="right">
                                        <div class="top">我要换货</div>
                                        <div class="bottom">已收到货，需要退还收到的货物</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import getParams from '../utils/getParams'

export default {
    data() {
        return {
            activeName: 'first',
            orderId: '',
            OrderList: ''
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },

        skipPage(page) {
            this.$router.push(page)
        },

        //订单详情
        async UserOrderInfo() {
            let userId = window.localStorage.getItem('user_id')
            let params = {
                user_id: userId,
                order_id: this.orderId,
            }
            let res = await this.$http('/mini/ApiConnect/UserOrderInfo', params)
            this.OrderList = res
        },
    },
    mounted() {
        this.orderId = getParams().orderId
        this.UserOrderInfo()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }

        .put {
            width: 350px;
            margin-top: -14px;
            display: flex;
            align-items: center;

            .inputBar {
                width: 232px;
                height: 32px;

                .el-color-picker__icon,
                .el-input,
                .el-textarea {
                    border: 1px solid #006eb7;
                    box-sizing: border-box;
                }

                .el-input__inner:focus {
                    // el-input输入时设置边框颜色
                    border: #006eb7;
                    box-sizing: border-box;

                }

                .el-input__icon {
                    line-height: 32px;
                }

            }

            .el-input--prefix .el-input__inner {
                height: 32px;
            }

            .btn {
                width: 109px;
                height: 32px;
                background-color: #006EB7;
                text-align: center;
                line-height: 32px;
                color: white;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    .line1 {
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e4e4e4;

        .left {
            // display: flex;
            // align-items: center;
        }

        .right2 {
            position: absolute;
            right: 0;
            bottom: 20px;
            height: 100%;
            width: 250px;

            .status {
                position: absolute;
                top: 37px;
                right: 0;
                font-size: 16px;
                color: #006EB7;
            }

            .statusBtn {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;

                .btn1 {
                    width: 120px;
                    height: 40px;
                    background-color: #006EB7;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    margin-left: 20px;
                    border-radius: 100px;
                    cursor: pointer;
                }
            }
        }

        .commodityBox {
            margin-top: 18px;
            display: flex;

            .collectBox {
                width: 80px;
                height: 25px;
                color: white;
                text-align: center;
                background-color: #006EB7;
                line-height: 25px;
                margin-top: 10px;
                cursor: pointer;
            }

            .right {
                margin-left: 23px;

                .commodityTitle {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23.4px;
                    text-align: left;
                    color: #000;
                }

                .price {
                    margin-top: 19px;
                }

                .serve {
                    font-size: 16px;
                    color: #898989;
                    margin-top: 10px;
                }

                .btnBox {
                    display: flex;

                    .btn1 {
                        width: 200px;
                        height: 64px;
                        border-radius: 4px;
                        background: #006eb7;
                        text-align: center;
                        line-height: 64px;
                        color: white;
                        font-size: 24px;
                        margin-right: 6px;
                        margin-top: 30px;
                    }
                }

                .checkOutImg {
                    display: flex;
                    align-items: center;
                    margin-top: 18px;

                    .box {
                        width: 82px;
                        height: 82px;
                        margin-right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .box :last-child {
                        margin-right: 0px;

                    }

                    .imgBorder {
                        border: 5px solid #006EB7;
                        box-sizing: border-box;
                    }
                }

                .numBox {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .numBtn {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;

                        .min {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;
                        }

                        .max {
                            width: 30px;
                            height: 30px;
                            background-color: #006EB7;
                            text-align: center;
                            line-height: 30px;
                            color: white;
                            cursor: pointer;

                        }

                        .put {
                            height: 30px;
                            width: 60px;
                        }

                        ::v-deep .el-input__inner {
                            height: 30px !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}

.orderInfo {
    margin-top: 50px;

    .orderTitle {
        padding-left: 10px;
        border-left: 3px solid #006EB7;
    }

    .putBox {
        .line1 {
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 30px;
            border-bottom: none;

            .put1 {
                height: 40px;
                width: 40%;
                display: flex;
                align-items: center;
                padding: 20px 10px;
                background-color: white;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                margin-right: 50px;
                margin-bottom: 30px;
                cursor: pointer;

                .right {
                    margin-left: 20px;

                    .bottom {
                        font-size: 14px;
                    }
                }
            }

            ::v-deep .el-input__inner {
                height: 40px !important;
            }
        }

        .btnBox {
            width: 100px;
            height: 40px;
            background-color: #006EB7;
            color: white;
            text-align: center;
            line-height: 40px;
            margin-top: 30px;
        }

    }
}
</style>