import axios from 'axios'

import { MessageBox, Message } from 'element-ui'
const api = axios.create({
    baseURL: '', // 请求的公共地址部分
    timeout: 　19000 // 请求超时时间 当请求时间超过5秒还未取得结果时 提示用户请求超时
})
api.interceptors.request.use(config => {
    if(window.localStorage.getItem('token'))
    {
        config.headers.token = window.localStorage.getItem('token')
    }
    // config.headers['token'] = "ACCESS_TOKEN692:eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI2OTIiLCJ1c2VyTmFtZSI6IjEzODIyMjczNjgyIiwiZXhwIjoxNzA0OTYzNzE3LCJpYXQiOjE3MDQ3MDQ1MTcsImp0aSI6IjM5MmQyNzA1LThhZDEtNDYxOS1hMDAxLWEzMjM0YTk1ZGM1MSJ9.Zx6nYuoa5HSJy37D2_QqAGFAUmd8pgoNgDjhEb-W8RE"
    // config 请求的信息
   return config // 将配置完成的config对象返回出去 如果不返回 请求则不会进行
}, err => {
   // 请求发生错误时的处理 抛出错误
  Promise.reject(err)
})
 //     this.$message({
    //         message:'登录信息已过期，请重新登录',
    //         type: 'error'
    //     });
    //    setTimeout(()=>{
    //     this.$router.push({
    //         path: '/login'
    //     })
    //    },2000)
    let showMsg = true

api.interceptors.response.use(res => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403

    if(res.data.code == 4017){
        window.localStorage.removeItem('user_id')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('userName')
        window.localStorage.removeItem('headPic')
 
        if(showMsg){
            showMsg = false
            Message({
                message: '登录过期，请重新登录',
                type: 'error',
              })
              setTimeout(()=>{
                window.location = `/?status=1`
            },1500)
        }
    
          return Promise.resolve(res)

        //   const url = new URL(window.location.href);
        //   使用URLSearchParams解析查询参数
        //   const queryParams = new URLSearchParams(url.search);
        // 创建URLSearchParams对象，并传入查询字符串（不包括问号）  
        //   var searchParams = new URLSearchParams(window.location.search.substring(1));  
        //   获取v_id的值  
        //     var vId = searchParams.get('v_id');  
       
        // return
        // this.$message('登录过期，请重新登录');     

    // return Promise.resolve(res)
    }else if(res.data.code == 4019){
        // Message({
        //             message:res.data.msg,
        //             type: 'warning'
        //         });
        return Promise.resolve(res)
        // Message({
        //     message: '验证码错误',
        //     type: 'error',
        //   })
    }else if(res.data.code == 200){
        return Promise.resolve(res)
    }else if(res.data.code == 500){
           Message({
                message:'系统错误,请稍后重试',
                type: 'warning'
            });
        return Promise.resolve(res)
    }else{
        if(res.data.msg)
        {
      
        }else{
            // Message({
            //     message:'系统错误',
            //     type: 'warning'
            // });
        }
      
        return Promise.resolve(res)
    }
    
    // res 是所有相应的信息
    // console.log(res)
  
}, err => {
    // 服务器响应发生错误时的处理
    Promise.reject(err)
})
export default api