<template>
    <div class="bigBox" style="margin-top: 80px;">
        <div class="content">
            <!-- <searchBar /> -->
            <!-- <div class="classifyBox">
                <classifyBar />
            </div> -->
            <div class="location">
                <span>当前位置：</span>
                <div class="nav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item style="cursor: pointer"><span
                                @click="skipPage('/')">首页</span></el-breadcrumb-item>
                        <el-breadcrumb-item style="cursor: pointer">
                            <span v-if="$route.query.productType === 'strictStore'
                                    " @click="skipPage('/strictStore')">严选商城</span>
                            <span v-else @click="skipPage('/store')">原材料商城</span>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item style="cursor: pointer"><span>{{
                                    info.goodsName
                                }}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div style="background: #fff">
                <div class="commodityBox">
                    <div class="left">
                        <img :src="checkOutImgList[checkOutImgFlag]" style="width: 600px; height: 450px" />
                        <!-- <div class="checkOutImg">
                            <img src="@/assets/detailsPage/ChevronLeft.png" @click="lastPic" />
                            <div class="box" :class="{ imgBorder: checkOutImgFlag == index }"
                                v-for="(item, index) in checkOutImgList">
                                <img style="width: 72px; height: 72px" :src="item" @click="pigCheckOut(index)" />
                            </div>

                            <img src="@/assets/detailsPage/ChevronLeft(1).png" @click="nextPic" />
                        </div> -->
                        <div class="checkOutImg">
                            <img src="@/assets/detailsPage/ChevronLeft.png" @click="lastPic" />
                            <div class="box" :class="{ imgBorder: checkOutImgFlag == index }"
                                v-for="(item, index) in checkOutImgList">
                                <img style="width: 72px; height: 72px" :src="item" @click="pigCheckOut(index)" />
                            </div>

                            <img src="@/assets/detailsPage/ChevronLeft(1).png" @click="nextPic" />
                        </div>
                    </div>
                    <div class="right">
                        <div class="commodityTitle" v-if="info.goodsName">
                            <div> {{ info.goodsName }}</div>
                            <div style="margin-left: 20px;margin-top: 2px;">
                                <div v-if="info.collect != 1 || !iscollects" style="font-size: 12px;">
                                    <div class="iconS hhy" @click="setCollectUserGoods(info.goodsId, info.isCollect)">
                                        <i class="el-icon-star-off" v-if="info.isCollect == 0">收藏</i>
                                        <i class="el-icon-star-on" v-if="info.isCollect == 1">已收藏</i>
                                    </div>
                                    <!-- <span>收藏：</span>
                        <i class="el-icon-star-off" @click="this.setCollectUserGoods" style="color: #898989; font-size: 24px;"></i> -->
                                </div>

                                <div v-if="info.collect == 1 || iscollects">
                                    <div class="iconS hhy">
                                        <i class="el-icon-star-on" @click="setCancelCollectUserGoods(1)">已收藏</i>
                                    </div>

                                    <!-- <span>已收藏：</span>
                        <i class="el-icon-star-off" @click="this.setCancelCollectUserGoods" style="color: rgba(248, 234, 30, 0.863); font-size: 24px;"></i> -->
                                </div>
                            </div>
                        </div>
                        <div class="price">
                            <span style="font-size: 16px; color: #000000ff">价格：</span>
                            <span style="font-size: 20px; color: #ff0c0cff">￥</span>
                            <span style="font-size: 28px; color: #ff0c0cff">{{
                                    info.shopPrice
                                }}</span>
                            <span style="font-size: 20px; color: #ff0c0cff">/{{ info.specName }}</span>
                            <div style="padding: 5px 0px;">起订量：1吨起批</div>
                            <!-- <div style="
									margin: 10px 0;
									display: flex;
									align-items: center;
								">
                                <div>规格：</div>
                                <div class="ppt" v-if="goodsServiceSp.content">
                                    {{
                                        `${goodsServiceSp.content}`
                                    }}
                                    <div class="ppr">
                                        <img src="../assets/1000.png" alt="" />
                                    </div>
                                </div>
                                <div v-else>*</div>
                            </div> -->

                            <div class="textSize">
                                <div>
                                    发货地：{{ info.locationName }}
                                </div>
                                <div>
                                    商品编码：{{ info.goodsSn }}
                                </div>
                                <div>
                                    是否包邮：{{ info.isFreeShipping == 0 ? '否' : '是' }}
                                </div>
                                <div>
                                    物流方式：{{ info.LogisticsTypeName }}
                                </div>
                                <!-- <div v-for="e in service">{{ `${e.bspTitle}:${e.bspContent}` }}</div> -->
                            </div>
                            <!-- <div class="textSize">
                                <div>含量：{{ goodsServiceSp.content }}%</div>
                                <div>产地：{{ goodsServiceSp.origin }}</div>
                            </div> -->
                        </div>

                        <div class="serve">
                            <div style="line-height: 40px;">服务：</div>
                            <div style="padding: 3px;">
                                <div style="color: rgb(34, 123, 255);">品质保障·资金安全·售后无忧</div>
                                <div>正品保证·描述相符·48小时发货·破损包退</div>
                            </div>
                            <!-- <span>服务：</span> -->
                            <!-- <span>{{ goods_service[0].title }}</span> -->
                        </div>
                        <div style="margin-top: 8px;">
                            <el-input-number v-model="num" :min="1" :max="1000" label="描述文字"></el-input-number>
                            <span style="margin-left: 1vw;font-size: 18px;">共计：</span>
                            <span style="color: red; font-size: 18px;">￥{{ info.shopPrice * num }}</span>
                        </div>
                        <div>

                        </div>
                        <!-- <div class="collectBox"> -->

                        <!-- <div class="collectBtn" @click="this.setCollectUserGoods" v-if="info.collect != 1">收藏</div>
                        <div class="collectBtn" @click="this.setCollectUserGoods" v-if="info.collect == 1">已收藏</div> -->
                        <!-- <div class="collectBtn" @click="this.setCancelCollectUserGoods">取消收藏</div> -->
                        <!-- </div> -->
                        <el-dialog title="留言" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                            <div>
                                <div>
                                    联系人：
                                    <span style="color: #006eb7">{{
                                    ComInfo.user_name
                                }}</span>
                                </div>
                                <div style="margin: 10px 0">
                                    联系电话：<span style="color: #006eb7">{{
                                        ComInfo.user_phone
                                    }}</span>
                                </div>
                            </div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="dialogVisible = false">取 消</el-button>
                                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                            </span>
                        </el-dialog>

                        <div class="btnBox">
                            <div class="btn1 hht" v-if="productType != 'store'" @click="addCart(info)">
                                加入购物车
                            </div>
                            <div class="btn1 hht" v-if="productType != 'store'" @click="buy(info)">
                                立即购买
                            </div>
                            <!-- &&info.user_id != userId -->
                            <div class="btn1 hhy" style="
									background-color: #fff;
									border: #006eb7 1px solid;
									color: #006eb7;
								" v-if="productType == 'store'
                                    " @click="getComInfoFn(info.userId)">
                                联系商家
                            </div>

                            <!-- skipPage('/chat?admin_id=' +info.admin_id +'&goods_id=' +info.goods_id) -->
                            <div class="btn1 hht" v-if="productType == 'store'" @click="
                                    skipPage(
                                        '/storeHome?admin_id=' +
                                        info.admin_id +
                                        '&goods_id=' +
                                        info.goods_id + '&user_id=' + info.userId
                                    )
                            ">
                                商家主页
                            </div>
                            <!-- v-if="productType == 'store'" -->


                        </div>

                        <!-- <div class="checkOutImg">
                            <img src="@/assets/detailsPage/ChevronLeft.png" @click="lastPic" />
                            <div class="box" :class="{ imgBorder: checkOutImgFlag == index }"
                                v-for="(item, index) in checkOutImgList">
                                <img style="width: 72px; height: 72px" :src="item" @click="pigCheckOut(index)" />
                            </div>

                            <img src="@/assets/detailsPage/ChevronLeft(1).png" @click="nextPic" />
                        </div> -->
                    </div>
                    <!-- <div class="right2">
                        <div class="right2-one">
                            <img src="@/assets/logo.png" alt="" style="width: 100%;height: 100%;">
                        </div>
                        <div class="right2-two">广东中海南联有限职业技术学院xxxxxxxxxxx</div>
                        <div class="right2-three">
                            <img src="@/assets/7771.png" alt="" style="width: 100%;height: 75%;margin: 0 auto;">
                        </div>
                        <div class="right2-four">主要经验及明年打算的氨甲环酸大家好按实际客户端哈</div>
                        <div class="right2-fire">
                            <el-button type="primary">商家主页</el-button>
                        </div>

                    </div> -->
                </div>




            </div>

            <!-- <div class="ProductRecommendation">
                <div class="tuijian">产品推荐</div>

                <div class="productBox">
                    <div class="productInfo" v-for="(item, index) in productsListArr" @click="skip(item.goods_id)">
                        <product :img="item.original_img" :title="item.goodsName" :price="item.shop_price"
                            :specName="item.specName" :click_count="item.click_count" :item="item" />
                    </div>
                </div>
            </div> -->
            <div>
                <div>
                    <h1>商品详细描述</h1>
                </div>
                <div
                    style="background-color: white;height: 400px;margin-bottom: 20px;padding: 20px;font-size: 18px;font-weight: 900;">
                    {{ info.goodsContent }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import searchBar from '../components/searchBar.vue'
import classifyBar from '../components/classifyBar.vue'
import getParams from '../utils/getParams'
import product from '../components/product.vue'
import { getGoodsInfoById, getUserById, goodsCollect, addBstCart } from '@/api/user'
export default {
    data() {
        return {
            num: 1,
            service: [],
            checkOutImgList: [
                // require('../assets/detailsPage/image1.png'),
                // require('../assets/detailsPage/Group.png'),
                // require('../assets/1000.png'),
            ],
            checkOutImgFlag: 0,
            info: {},
            comment_rate: '',
            productsList: '',
            type: 0,
            productType: '',
            productsListArr: '',
            commentList: '',
            comment: '',
            levelList: '',
            adminId: '',
            goods_id: '',
            userId: '',
            goodsServiceSp: {},
            search: '',
            isCollect: 100,
            iscollects: false,
            isY: false,
            dialogVisible: false,
            ComInfo: {},
            goods_service_sp: [],
            goods_service: [],
            filterServiceArr: [{
                title: '规格'
            },
            {
                title: '等级'
            },
            {
                title: '品牌'
            },
            {
                title: '产地'
            }]
        }
    },
    computed: {
        filterService() {
            return this.filterServiceArr.map(item => {
                const data = this.productsList?.service?.find(items => items.title == item.title)
                if (data)
                    return { ...item, ...data }
                return item
            })
        },
    },
    components: {
        searchBar,
        classifyBar,
        product,
    },

    mounted() {
        // getUserById().then((res) => {

        // })
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then((_) => {
                    done()
                })
                .catch((_) => { })
        },
        async getComInfoFn(com_id) {
            console.log(com_id, 'com_id');
            // getUserById({ user_id: com_id }).then((res) => {
            //     this.ComInfo = res.data.data
            // })
            // this.dialogVisible = true
            // let res = await this.$http('/mini/ApiConnect/getComInfo', {
            //     user_id: this.userId,
            //     com_id,
            // })
            // this.ComInfo = res.result
        },
        async setCollectUserGoods(i = 0) {
            if (i) {
                this.isCollects = true
            } else {
                this.isCollect = 0
            }

            let userId = window.localStorage.getItem('user_id')
            let id = getParams().goods_id

            let params = {
                user_id: userId,
                goods_id: id,
            }
            let res = await this.$http(
                '/mini/ApiConnect/setCollectUserGoods',
                params
            )

            this.$message({
                message: res.msg,
                type: 'success',
            })
        },
        //取消收藏商品
        async setCancelCollectUserGoods(i = 0) {
            if (i) {
                this.isCollects = false
            } else {
                this.isCollect = 1
            }


            let userId = window.localStorage.getItem('user_id')
            let id = getParams().goods_id

            let params = {
                user_id: userId,
                goods_id: id,
            }

            let res = await this.$http(
                '/mini/ApiConnect/setCancelCollectUserGoods',
                params
            )
            this.$message({
                message: res.msg,
                type: 'success',
            })
        },
        lastPic() {
            console.log('lastPic')
            if (this.checkOutImgFlag > 0) {
                this.checkOutImgFlag -= 1
            } else {
                return
            }
        },
        nextPic() {
            console.log('nextPic')

            if (this.checkOutImgList[this.checkOutImgFlag + 1]) {
                this.checkOutImgFlag += 1
            }
        },

        pigCheckOut(index) {
            console.log(index)
            this.checkOutImgFlag = index
        },

        barBtn(type) {
            this.type = type
        },

        skip(id) {
            if (this.$route.query.productType === 'strictStore') {
                window.location =
                    '/detailsPage?goods_id=' + id + 'productType=strictStore'
            } else {
                window.location =
                    '/detailsPage?goods_id=' + id + 'productType=store'
            }
        },
        skipPage(page) {
            window.open(page)
        },

        addCart(info) {
            if (!window.localStorage.getItem('user_id')) {
                return this.$message({
                    type: 'warning',
                    message: '请登录后操作'
                })
                this.$router.push({
                    path: '/login'
                })
            }
            // this.AjaxJionCart()

            let params = {
                userId: window.localStorage.getItem('user_id'),
                goodsId: info.goodsId,
                goodsSn: info.goodsSn,
                goodsName: info.goodsName,
                goodsPrice: info.shopPrice,
                goodsNum: this.num,
            }
            let cartList = []
            cartList.push(params)
            addBstCart({ cartList: cartList }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '加入购物车成功'
                    })
                }
            })
        },

        buy(info) {
            console.log(info, 'info---');
            // window.location = '/buyOrder?goods_id=' + info.goodsId
            if (window.localStorage.getItem('user_id')) {
                this.$router.push({
                    path: '/buyOrder',
                    query: {
                        goods_id: info.goodsId,
                        num: this.num,
                    }
                })
            } else {
                this.$message({
                    type: 'warning',
                    message: '请先登录您的账号',
                    offset: 230,
                })
                this.$router.push({
                    path: '/login'
                })
            }
        },

        //加入购物车
        async AjaxJionCart() {
            let userId = window.localStorage.getItem('user_id')
            let params = { user_id: userId, goods_id: this.goods_id }
            let res = await this.$http('/mini/ApiConnect/AjaxJionCart', params)
            if (res.status == 1) {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
            } else {
                this.$message.error(res.msg)
            }
        },

        //产品详情
        async GoodsInfo(userId, goods_id) {
            let params = { goods_id: goods_id, user_id: window.localStorage.getItem('user_id') }

            getGoodsInfoById(params).then((res) => {
                console.log(res, '详情详情');
                this.info = res.data.data.goods_info
                this.service = res.data.data.service
                this.checkOutImgList = res.data.data.goods_info.goodsImages
                let newArray = []
                this.checkOutImgList.forEach((item, index) => {
                    if (item != '') {
                        newArray.push(item)
                    }
                    this.checkOutImgList = newArray
                })
                // console.log(newArray, 'newArray');

            })
            // let res = await this.$http('/mini/ApiConnect/GoodsInfo', params)
            // this.info = res.result
            // this.iscollects=this.info.collect===1?true:false
            // this.goods_service=this.info.goods_service
            // this.goods_service_sp=res.result.goods_service_sp
            // this.checkOutImgList = res.result.goods_images
            // this.goods_id = res.result.goods_id
            // this.commentList = res.comment.result
            // this.comment = res.comment
            // let arr = ['品牌brand', '等级grade', '规格content', '产地origin']
            // this.goodsServiceSp = {
            //     brand: '',
            //     grade: '',
            //     content: '',
            //     origin: '',
            // }
            // res.result.goods_service_sp.forEach((item) => {
            //     arr.forEach((e) => {
            //         let t = e.slice(0, 2)
            //         if (item.title == t) {
            //             let g = e.slice(2, e.length)
            //             this.goodsServiceSp[g] = item.content
            //         }
            //     })
            // })
        },

        //商品评论列表
        async TrueGoodsComment(userId, goods_id) {
            let params = { user_id: userId, goods_id: goods_id }
            let res = await this.$http(
                '/mini/ApiConnect/TrueGoodsComment',
                params
            )
            this.comment_rate = res.comment_rate
            this.commentList = res.commentList
            this.levelList = res.levelList
        },

        //严选产品
        async StrictSelectionOfProducts() {
            let type = this.$route.query.productType
            let ut = type == 'store' ? 2 : 3
            let params = {
                category_id: 0,
                page: 0,
                type: ut,
            }
            let res = await this.$http(
                '/mini/ApiConnect/StrictSelectionOfProducts',
                params
            )
            this.productsList = ''
            this.productsListArr = ''
            this.productsList = res.result.slice(0, 6)
            this.productsListArr = res.result
        },

        //收藏商品
        async setCollectUserGoods(goodId, isCollect) {
            console.log(goodId, isCollect);
            let params = {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: goodId,
                type: 3
            }
            goodsCollect(params).then((res) => {
                console.log(res, 'eee');
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: res.data.msg
                    })
                    this.GoodsInfo(0, goodId)
                } else {

                }
            })
            // let userId = window.localStorage.getItem('user_id')
            // let id = getParams().goods_id

            // let params = {
            //     user_id: userId,
            //     goods_id: id,
            // }
            // let res = await this.$http(
            //     '/mini/ApiConnect/setCollectUserGoods',
            //     params
            // )

            // this.$message({
            //     message: res.msg,
            //     type: 'success',
            // })
        },

        //取消收藏商品
        async setCancelCollectUserGoods() {
            let userId = window.localStorage.getItem('user_id')
            let id = getParams().goods_id

            let params = {
                user_id: userId,
                goods_id: id,
            }

            let res = await this.$http(
                '/mini/ApiConnect/setCancelCollectUserGoods',
                params
            )
            this.$message({
                message: res.msg,
                type: 'success',
            })
        },
    },

    created() {
        // this.$isLogin()
        let userId = window.localStorage.getItem('user_id')
        this.userId = userId
        // let id = getParams().goods_id
        let id = this.$route.query.goods_id

        this.adminId = getParams().adminId
        this.productType = getParams().productType
        console.log(id, this.adminId, this.productType);
        this.GoodsInfo(userId, id)
        // this.TrueGoodsComment(userId, id)
        // this.StrictSelectionOfProducts()
    },
}
</script>
<style lang="scss">
.el-breadcrumb__inner {
    color: #006eb7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    font-weight: normal;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #006eb7 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    font-weight: normal;
}
</style>
<style lang="scss" scoped>
.vtitleBox {
    display: flex;
    flex-wrap: wrap;

    >div {
        flex: 0 0 50%;
        font-weight: 400;
        font-size: 10px;
        margin-top: 5px;
    }
}

.iconS {
    color: #3c8eb8;
    width: 45px;
    height: 16px;
    border: #3c8eb8 1px solid;
    text-align: center;
    line-height: 16px;
    border-radius: 10px;
}

.iconS:hover {
    background-color: #006eb7;
    color: #fff;
}

.bigBox {
    //   background-color: white;
}

::v-deep .content1 {
    background-color: white;
}

.content {
    width: 1200px;
    margin: 0 auto;

    .ProductRecommendation {
        width: 100%;

        margin: 20px auto;

        .tuijian {
            width: 100%;
            height: 58px;
            box-sizing: border-box;
            color: #000000;
            padding-left: 20px;
            font-size: 24px;
            font-weight: 700;
            font-family: 'Microsoft YaHei UI';
            text-align: left;
            line-height: 58px;
            background-color: #fff;
            margin-bottom: 20px;
        }

        .productBox {
            display: flex;
            flex-direction: row;
            justify-content: left;
            flex-wrap: wrap;
            width: 100%;

            .productInfo {
                width: 228px !important;
                margin-right: 15px;
                margin-bottom: 15px;
                cursor: pointer;
            }

            >.productInfo:nth-child(5n) {
                margin-right: 0;
            }
        }
    }
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.classifyBox {
    margin-top: 29px;
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;
    transform: translateY(10px);

    .nav {}
}

.commodityBox {
    margin-top: 18px;
    display: flex;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;

    .collectBox {
        width: 200px;
        height: 25px;
        color: #000000ff;
        line-height: 25px;
        margin-top: 16px;
        cursor: pointer;
    }

    .right2 {
        width: 270px;
        background-color: red;
        height: 36vh;
        background-image: linear-gradient(rgb(205, 225, 255), white);
        margin-left: 20px;
        border: 1px solid rgb(205, 225, 255);

        .right2-one {
            width: 50px;
            height: 18%;
            margin: 0 auto;
            border-radius: 50%;
            // background-color: red;
            overflow: hidden;
            padding: 20px 0px;
        }

        .right2-two {
            // padding: 0px 10px;
            text-align: center;
            overflow: hidden;
            // width: 250px;
            height: 25px;
            margin: 0 auto;
        }

        .right2-three {
            width: 30%;
            height: 10%;
            margin: 0 auto;
            // border-radius: 50%;
            // background-color: red;
            overflow: hidden;
        }

        .right2-four {
            width: 100%;
            height: 18%;
            margin: 0 auto;
            // border-radius: 50%;
            // background-color: red;
            padding: 2px 5px;
            overflow: hidden;
        }

        .right2-fire {
            width: 35%;
            height: 18%;
            margin: 0 auto;
        }
    }

    .right {

        margin-left: 23px;
        height: 420px;
        width: 30vw;
        padding: 20px;
        background-color: rgb(211, 211, 211, 30%);

        // background-image: url('@/assets/31e0d65509671afa8cc76018e71769c.png');

        .iconS {
            color: #3c8eb8;
            width: 56px;
            height: 20px;
            border: #3c8eb8 1px solid;
            text-align: center;
            line-height: 20px;
            border-radius: 10px;
        }

        .iconS:hover {
            background-color: #006eb7;
            color: #fff;
        }

        .commodityTitle {
            display: flex;
            font-weight: 400;
            font-size: 22px;
            line-height: 23.4px;
            font-weight: 700;
            text-align: left;
            color: #000;
        }

        .price {
            margin-top: 20px;

            .ppt {
                position: relative;
                width: 96px;
                height: 28px;
                background-color: #d9e9f3;
                border: 1px solid #a2d0eb;
                box-sizing: border-box;
                color: #3578a8;
                line-height: 28px;
                text-align: center;
                font-size: 12px;

                .ppr {
                    // width: 16px;
                    // height: 16px;
                    position: absolute;
                    right: 0;
                    bottom: -10px;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .textSize {
                display: flex;
                width: 300px;
                justify-content: space-between;

                // 后加
                flex-wrap: wrap;

                >div {
                    flex: 0 0 50%;
                    margin-bottom: 10px;
                }
            }
        }

        .serve {
            font-size: 16px;
            color: #898989;
            margin-top: 3px;
            display: flex;
            height: 48px;
        }

        .btnBox {
            display: flex;
            align-items: center;
            height: 62px;

            .iconS {
                color: #3c8eb8;
                width: 56px;
                height: 20px;
                border: #3c8eb8 1px solid;
                text-align: center;
                line-height: 20px;
                border-radius: 10px;
                font-size: 12px;
                transform: translateY(74%);
            }

            .btn1 {
                width: 120px;
                height: 40px;
                border-radius: 4px;
                background: #006eb7;
                text-align: center;
                line-height: 40px;
                color: white;
                font-size: 16px;
                margin-right: 20px;
                margin-top: 30px;
                cursor: pointer;
            }
        }
    }
}

.checkOutImg {
    display: flex;
    align-items: center;
    margin-top: 18px;

    .box {
        width: 82px;
        height: 82px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box :last-child {
        margin-right: 0px;
    }

    .imgBorder {
        border: 5px solid #006eb7;
        box-sizing: border-box;
    }
}

.productContain {
    margin-top: 68px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;

    .left5 {
        width: 210px;
    }

    .strictSelection {
        width: 210px;
        background-color: white;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 10px;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                border-left: 3px solid #006eb7;
                padding-left: 5px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
            margin-bottom: 5px;
        }

        // .productBox {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        // }

        .product {
            overflow: hidden;
            width: 180px;
            margin-top: 13px;
            border: 0.87px solid #ddd;
            box-sizing: border-box;
            cursor: pointer;

            .infoBox {
                padding: 0 0px 0px 5px;

                background-color: white;

                .tage {
                    display: flex;
                    align-items: center;
                    font-family: 'Microsoft YaHei UI';
                    font-weight: 400;
                    font-size: 9.47px;
                    text-align: left;
                    color: #000;

                    >div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    >div:nth-child(2) {
                        margin-left: 10px;
                    }
                }

                .title {
                    font-size: 12px;
                    height: 20px;
                    overflow: hidden;
                }

                .bottom {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    padding: 0 5px;
                    padding-bottom: 10px;
                    box-sizing: border-box;
                }

                .bottom2 {
                    width: 103%;
                    font-weight: 400;
                    font-size: 8.72px;
                    line-height: 10.2px;
                    text-align: right;
                    color: #aeaeae;
                    display: flex;
                    justify-content: space-between;
                    border-top: 0.87px solid #ddd;
                    border-bottom: 0.87px solid #ddd;
                    padding: 0 10px;
                    box-sizing: border-box;
                    margin-top: 8px;
                    margin-left: -5px;

                    .address {
                        padding: 10px 0;
                    }

                    .location2 {
                        padding: 10px 0;
                    }
                }

                .left {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 11px;

                    .one {
                        font-size: 14px;
                        font-weight: 700;
                        color: #ff0000;
                    }

                    .two {
                        font-size: 0.1em;
                        margin-left: 3px;
                        color: #aeaeae;
                    }
                }

                .right {
                    width: 39%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 8px;
                    color: #aeaeae;
                    text-align: right;
                    white-space: nowrap !important;
                    /* 不换行 */
                    overflow: hidden !important;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis !important;
                    /* 超出部分显示省略号 */
                }
            }
        }
    }

    .right {
        width: 926px;
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .bar {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 3px solid #006eb7;

            .btn {
                width: 87px;
                height: 30px;
                border-radius: 4px 4px 0 0;
                text-align: center;
                line-height: 30px;
                margin-right: 20px;
                cursor: pointer;
            }

            .btnBgc {
                background: #006eb7;
                color: white;
            }
        }

        .detaildPic {
            margin-top: 25px;
        }

        .type {
            width: 100%;
            margin-top: 22px;

            .title {
                width: 926px;
                height: 64px;
                background: #eeeded;
                font-weight: 400;
                font-size: 24px;
                line-height: 64px;
                text-align: left;
                color: #000;
                padding-left: 24px;
                box-sizing: border-box;
            }

            .info {
                width: 100%;
                height: 360px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-wrap: wrap;
                padding: 0 23px;
                box-sizing: border-box;

                .line1 {
                    display: flex;
                    justify-content: space-between;
                    width: 33%;
                    margin-top: 33px;
                    font-size: 20px;
                }
            }
        }

        .evaluate {
            width: 100%;
            margin-top: 22px;

            .title {
                width: 926px;
                height: 64px;
                background: #eeeded;
                font-weight: 400;
                font-size: 24px;
                line-height: 64px;
                text-align: left;
                color: #000;
                padding-left: 24px;
                box-sizing: border-box;
            }

            .numBox {
                margin-top: 26px;
                margin-left: 35px;

                .txt {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28.08px;
                    text-align: left;
                    color: #000;
                }

                .num {
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 46.8px;
                    text-align: left;
                    color: #f00;
                }
            }

            .evaluateBar {
                width: 100%;
                height: 32px;
                background-color: #eeeded;
                display: flex;
                align-items: center;
                padding: 0 23px;
                box-sizing: border-box;
                margin-top: 37px;

                span {
                    margin-right: 30px;
                }
            }

            .evaluateContain {
                margin-bottom: 50px;

                .line2 {
                    padding: 24px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: flex-start;
                    border-bottom: 1px solid #ddd;

                    .left {
                        display: flex;
                        align-items: center;
                        width: 18%;

                        span {
                            margin-left: 11px;
                        }
                    }

                    .right2 {
                        width: 85%;
                        padding-top: 9px;

                        .info {
                            font-size: 16px;
                            margin-top: 24px;
                        }


                    }
                }
            }
        }
    }
}
</style>
