<template>
    <div>
        <VipDialog2Copy ref="VipDialog2Copy" />
        <VipDialog6 ref="VipDialog6" />
        <el-dialog title="" :visible.sync="dialogVisible" width="990px" :before-close="handleClose" style="">
            <div
                style="width: 100%;text-align: center;font-weight: 400;font-size: 14px;color: #613C14;padding: 30px 100px;box-sizing: border-box;border-bottom: 1px solid #D8D8D8;">
                用户协议及隐私条款
            </div>
            <div
                style="padding: 5px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;margin-top: 20px;">
                欢迎您阅读《搜原网企业认证服务协议》并成为搜原网企业认证！ 
            </div>
            <div
                style="padding:4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                《搜原网企业认证服务协议》（简称为“本协议”）是您与搜原网网站（以下统称为“搜原网”）之间关于“搜原网企业认证”以及“搜原网企业认证”中搜原网提供的各项服务（以下统称为“企业认证”）的法律协议。
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                本协议中与您的权益（可能）存在重大关系的条款（包括相关免除搜原网责任的条款、限制您权利的条款、约定的争议解决方式及司法管辖条款等），搜原网已采用字体加粗的方式来特别提醒您，请您留意重点查阅。您应当在确认充分阅读字体加粗条款，明确理解的前提下使用企业认证服务。 
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                根据国家法律法规、监管政策及行业规范变化、互联网高速发展态势及服务运营需要，搜原网有权修改、替换、升级搜原网软件/服务，并提前向用户进行公示（包括但不限于弹出公告、网站公告、系统消息、邮件等方式进行公示），同时据实修改搜原网服务相关协议条款/规则以覆盖您与搜原网的所有权利与义务，前述修改的协议条款/规则自公示期满之日起生效。如果用户不同意或者不接受搜原网相关软件/服务的修改、替代、升级，有权拒绝、停止、取消使用搜原网服务。如用户继续使用搜原网服务，那么视为悉知变动内容并同意接受。
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                如果您未满18周岁，请在法定监护人的陪同下阅读本协议。 
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                本协议是基于《搜原网用户使用协议》以及搜原网相关协议规范（统称为“搜原网服务条款”）制定，与其是不可分割的；同时，搜原网不时发布的关于企业认证的相关协议、活动规则等也是本协议的一部分，您在使用企业认证服务的同时应遵守本协议、搜原网服务条款及前述相关协议、活动规则等所有条款。 
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                特别提示：您通过搜原网指定渠道开通企业认证服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及第三方原因导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向搜原网提出任何主张或追究搜原网任何责任。同时，《搜原网企业认证服务协议》可能由于您使用的软件版本存在差别，您理解并同意本协议最终版本以搜原网网站网页端提供版本为最终版本。
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                （一） 企业认证服务是搜原网在搜原网网站及其软件客户端中设立的网络增值服务。 
            </div>
            <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                （二）
                企业认证服务秉承以下宗旨：为成为企业认证的您提供各种更完善的线上线下增值服务，促进您与其他企业认证、企业认证与搜原网之间的相互交流。 搜原网郑重声明： 搜原网企业认证服务，不影响搜原网网站现有的一切免费服务并保证服务质量。搜原网企业认证服务，与搜原网现存正式会员服务体系并存。 
            </div>
            <!-- <div
                style="padding: 4px 0px;font-weight: 290;font-size: 14px;color: #613C14;box-sizing: border-box;text-align: left;line-height: 28px;">
                （三）成为企业认证的方法  1.您可通过搜原网指定的包括但不限于银行卡、支付宝、微信支付等现有方式或未来搜原网指定方式，在依约支付一定费用后开通企业认证；或通过搜原网官方活动/他人赠与获 </div> -->
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="freeVip">确 定</el-button>
            </span>
        </el-dialog>
        <div class="box">
            <div
                style="color: #FFFFFF;font-size: 48px;font-weight: 700;text-align: center;padding-top: 30px;padding-bottom: 30px;">
                企业特权
            </div>
            <div style="color: #FFFFFF;font-size: 20px;font-weight: 400;text-align: center;margin-bottom: 40px;">
                搜原企业号：一键报价，获取海量询盘！</div>
            <div style="color: #FFFFFF;font-size: 20px;font-weight: 400;text-align: center;margin-bottom: 20px;">

                <div style="margin-bottom: 20px">
                    <el-button type="primary" @click="sub2('ruleFormRef')"
                        style="background-color: white;color: #227BFF;"
                        v-if="info.is_free_use_bus_vip == 0">免费试用→</el-button>
                </div>
                <el-button type="primary" @click="sub3('ruleFormRef')"
                    style="background-color: white;color: #227BFF;">马上成为企业会员→</el-button>
                <!-- <el-button type="primary" @click="sub3('ruleFormRef')" v-else
                    style="background-color: white;color: #227BFF;">立即续费会员→</el-button> -->
            </div>
            <div style="width: 825px;height: 597px;margin:auto">
                <img src="../assets/newImg3/特权对比@1x (1).png" style="width: 100%;height: 100%;" alt="">

            </div>

        </div>
    </div>
</template>

<script>
import { getUserById, addBusFreeVip, getBusManage } from '@/api/user'
import VipDialog2Copy from '../components/VipDialog2Copy.vue'
import VipDialog6 from '../components/VipDialog6.vue'

export default {
    data() {
        return {
            info: {},
            dialogVisible: false,
            bus_id: '',
        }
    },
    components: {

        VipDialog2Copy,
        VipDialog6,
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
        },
        sub2() {
            this.dialogVisible = true

        },
        freeVip() {
            addBusFreeVip({ bus_id: this.bus_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$refs.VipDialog6.visible = true
                    this.dialogVisible = false
                } else {
                    this.$message({
                        type: 'error',
                        message: '操作失败',
                        offset: 100,

                    })
                }
            })
        },
        sub3() {
            this.$refs.VipDialog2Copy.visible = true
            this.$refs.VipDialog2Copy.busId = this.bus_id
            if (this.info.is_bus_vip == 0) {
                this.$refs.VipDialog2Copy.useIfXu = 0

            } else {
                this.$refs.VipDialog2Copy.useIfXu = 1
            }
            this.$refs.VipDialog2Copy.getCode()

            if (this.info.is_super_vip == 0) {
                this.$refs.VipDialog2Copy.busIfXu = 0
            } else {
                this.$refs.VipDialog2Copy.busIfXu = 1
            }

        },

    },
    created() { },
    mounted() {
        getBusManage({ page: 1, size: 1 }).then((res) => {
            if (res.data.data.length > 0) {
                this.bus_id = res.data.data[0].busId
            }
        })
        // this.$refs.VipDialog6.visible = true

        getUserById().then((res) => {
            this.info = res.data.data
            this.$refs.VipDialog2Copy.is_bus_vip = res.data.data.is_bus_vip
            this.$refs.VipDialog2Copy.is_super_vip = res.data.data.is_super_vip

        })
    }
}
</script>


<style lang=scss scoped>
.box {
    width: 99.4%;
    height: 1330px;
    // height: 120vh;
    background-color: white;
    background: linear-gradient(125deg, #603AFF 0%, #4A7FFF 51%, #37B5FF 99%);
}

::v-deep .el-dialog__header {
    padding: 0px;
}

::v-deep .el-dialog__body {
    padding: 0px 20px;
}

::v-deep .el-dialog {
    margin-top: 8vh !important;
}
</style>