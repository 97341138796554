<template>
    <div class="box">
        <div class="content2">
            <!-- <searchBar /> -->
            <!-- <div class="line"></div> -->

            <!-- <classifyBar /> -->
            <div class="location">
                <span>当前位置：</span>
                <div class="nav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/index' }">主页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/store' }">原材料产品</el-breadcrumb-item>
                        <el-breadcrumb-item>商家主页</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div style="position: relative;width: 100%;">
                <div class="bj"></div>
                <div class="storeBox">
                    <div class="left">
                        <div class="dd"></div>

                        <div class="rr">
                            <img :src="dataList.busLogo" style="width: 115px; height: 115px; border-radius: 300px" />

                            <div class="info">
                                <div class="title">
                                    {{ info.real_name }}
                                </div>
                                <div class="address" style="font-weight: 900;font-size: 26px;padding: 4px;">
                                    {{ dataList.busName }}
                                </div>
                                <div class="address ov1">联系人：{{ dataList.busLegalPerson }}</div>
                                <div class="address ov1">联系电话：{{ dataList.busMobile }}</div>
                            </div>
                        </div>
                    </div>


                    <div class="right ">
                        <!-- <div class="btn1 hht"
                            @click="$router.push({ path: `/storeDetails?admin_id=${$route.query.admin_id}&goods_id=${$route.query.goods_id}`, query: { com_id } })">
                            认证信息</div>
                        <div class="btn1 hht" style="margin-left: 20px" @click="setInterestCom"
                            v-if="info.is_follow == 0">
                            关注
                        </div> -->
                        <!-- <div class="btn1 hht" style="margin-left: 20px" @click="setCancelInterestCom"
                            v-if="info.is_follow == 1">
                            已关注
                        </div> -->
                    </div>
                </div>
                <div style="width: 100%;margin-top: 20px;height: 68vh;background-color: white;margin-bottom: 30px;">
                    <div
                        style="background-color: white;color: rgb(34, 123, 255);font-size: 22px;font-weight: 600;padding: 6px 6px 6px 14px;">
                        所有产品</div>
                    <el-table :data="tableData" style="width: 100%;" height="520">

                        <el-table-column prop="goodsName" label="产品">
                        </el-table-column>
                        <el-table-column prop="bqPrice" label="今日价格">
                        </el-table-column>
                        <el-table-column prop="fourPrice" label="昨日价格">
                        </el-table-column>
                        <el-table-column label="涨跌值">
                            <template #default="scope">
                                <!-- <span>{{ scope.row.upPrice }}</span> -->
                                <div v-if="scope.row.bqPrice - scope.row.fourPrice > 0" style="color: red;">
                                    {{ scope.row.bqPrice - scope.row.fourPrice }}
                                </div>
                                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice < 0" style="color: green;">
                                    {{ scope.row.bqPrice - scope.row.fourPrice }}
                                </div>
                                <div v-else-if="scope.row.bqPrice - scope.row.fourPrice == 0">
                                    0
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="涨跌幅">
                            <template #default="scope">
                                <div v-if="scope.row.fourPrice == 0 || scope.row.bqPrice - scope.row.fourPrice == 0">0%
                                </div>
                                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice > 0"
                                    style="color: red;">
                                    {{ ((scope.row.bqPrice - scope.row.fourPrice) * 100 /
                            scope.row.fourPrice).toFixed(2)
                                    }}%

                                </div>
                                <div v-else-if="(scope.row.bqPrice - scope.row.fourPrice) / scope.row.fourPrice < 0"
                                    style="color: green;">
                                    {{ ((scope.row.bqPrice - scope.row.fourPrice) * 100 /
                            scope.row.fourPrice).toFixed(2)
                                    }}%
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="catTwoName" label="所属">
                        </el-table-column>
                        <el-table-column prop="spec" label="单位">
                        </el-table-column> -->

                    </el-table>

                </div>
            </div>
        </div>

        <div class="block" style="padding: 20px 0px;">
            <!-- <span class="demonstration"></span> -->
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5, 10]"
                :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="allNumber">
            </el-pagination> -->
        </div>

        <!-- <div
            style="background-color:white;width: 1200px;height: 100vh;margin: auto;overflow: auto;margin-bottom: 50px;">
            <div class="title-box" v-for="item in titleList" @click="goTitle(item)">
                <div class="title-left">
                    <img :src="item.imgUrl" alt="" style="width: 100%;height: 100%">
                </div>
                <div class="title-right">


                    <div style="padding: 5px 30px;font-weight: 900;">{{ item.bgcTitle }}</div>
                    <div
                        style="padding: 5px 30px;height: 147px;text-overflow:ellipsis;overflow: hidden; -webkit-line-clamp: 7; -webkit-box-orient: vertical;  display: -webkit-box; ">
                        {{
                            item.bgcContent.replace(/<[^>]*>/g, '') }}
                    </div>

                </div>
            </div>



        </div> -->
    </div>
</template>

<script>
import searchBar from "../components/searchBar.vue";
import classifyBar from "../components/classifyBar.vue";
import classifyContain from "../components/classifyContain.vue";
import loginCon from "../components/loginCon.vue";
import strictProduct from "../components/strictProduct.vue";

import strictProduct2 from "../components/strictProduct2.vue";
import product from "../components/product.vue";
import getParams from "../utils/getParams";
import { getShippingMain, getShippingMainGoods, getQuotationByBusId, getBusManageById, getAllGoodsConsult } from '@/api/user'
export default {
    data() {

        return {
            page: 1,
            size: 5,
            allNumber: 0,
            imgList: ["../assets/index/banner.png"],
            titleList: [],
            tableData: [

            ],
            dataList: {},
            tableData2: [
                {
                    date: "醋酸甲酯",
                    name: "中海南联",
                    down: "3800",
                },
                {
                    date: "醋酸甲酯",
                    name: "中海南联",
                    down: "3800",
                },
                {
                    date: "醋酸甲酯",
                    name: "中海南联",
                    down: "3800",
                },
                {
                    date: "醋酸甲酯",
                    name: "中海南联",
                    down: "3800",
                },
                {
                    date: "醋酸甲酯",
                    name: "中海南联",
                    down: "3800",
                },
            ],
            UserProduct: [],
            com_id: 0,
            info: "",
            isBai: 2,
            isCollect: 100
        };
    },
    methods: {
        goTitle(item) {
            this.$router.push({
                path: '/titleDetails',
                query: {
                    id: item.bgcId
                }
            })
        },
        handleSizeChange(e) {
            console.log(e, 'e1');
            this.size = e
            let params = {
                bus_id: this.$route.query.id,
                page: this.page,
                size: this.size

            }
            getQuotationByBusId(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
        },
        handleCurrentChange(e) {
            this.page = e
            let params = {
                bus_id: this.$route.query.id,
                page: this.page,
                size: this.size
            }
            getQuotationByBusId(params).then((res) => {
                this.tableData = res.data.data
                this.allNumber = res.data.totalPage
            })
            console.log(e, 'e2');
        },
        skip(id, admin_id) {
            window.location =
                "/detailsPage?goods_id=" +
                id +
                "&productType=store" +
                "&adminId=" +
                admin_id;
        },
        skipPage(page) {
            window.location = page;
        },

        cellStyle() {
            return "'color': 'red'";
        },
        daBtn(num) {
            this.isBai = num;
        },
        //点击分类获取数据
        productInfo(id) {
            this.getUserProduct('', id);
            console.log(id);
        },
        searchProduct(id, search) {
            this.page = 1;
            this.getUserProduct2(id, search);
        },
        //产品列表
        // async getUserProduct(userId) {
        //     let params = { user_id: userId, page: 1 }
        //     let res = await this.$http('/mini/ApiConnect/getUserProduct', params)
        //     this.UserProduct = res.result.rows
        // },

        //获取商家信息
        async getComInfo() {
            let userId = window.localStorage.getItem("user_id");

            let params = {
                user_id: this.com_id,
            };
            getShippingMain(params).then((res) => {
                this.info = res.data.data
            })
            // let res = await this.$http("/mini/ApiConnect/getComInfo", params);

            // this.info = res.result;
        },

        //关注商家
        async setInterestCom() {
            let userId = window.localStorage.getItem("user_id");

            let params = {
                user_id: userId,
                com_id: this.com_id,
            };
            let res = await this.$http("/mini/ApiConnect/setInterestCom", params);

            this.$message({
                message: res.msg,
                type: "success",
            });
        },

        //取消关注
        async setCancelInterestCom() {
            let userId = window.localStorage.getItem("user_id");

            let params = {
                user_id: userId,
                com_id: this.com_id,
            };
            let res = await this.$http(
                "/mini/ApiConnect/setCancelInterestCom",
                params
            );

            this.$message({
                message: res.msg,
                type: "success",
            });
        },

        //产品列表
        async getUserProduct(id, uid = 0, bqPrice = 1) {
            // if (!id) {
            //   id = window.localStorage.getItem("user_id");
            // }
            // this.com_id=id
            console.log(bqPrice, 22);
            let params = {
                user_id: this.com_id,
                // page: 1,
                // keyword: "",
                // size: 10,
                // bqPrice,
                // category_id: uid,
                // type: 2
            };
            getShippingMainGoods(params).then((res) => {
                this.UserProduct = res.data.data
            })
            // let res = await this.$http("/mini/ApiConnect/StrictSelectionOfProducts", params);
            // this.UserProduct = [];
            // this.UserProduct = this.UserProduct.concat(res.result);
            // this.totalCount = res.count;
        },

        async getUserProduct2(id, search) {
            let params = {
                user_id: this.com_id,
                page: 0,
                product_name: search,
                size: 10,
            };
            let res = await this.$http("/mini/ApiConnect/getUserProduct", params);
            this.UserProduct = [];
            this.UserProduct = this.UserProduct.concat(res.result);
            this.totalCount = res.count;
        },
    },
    created() {
        this.com_id = this.$route.query.admin_id
    },
    mounted() {
        // let comid = getParams().com_id;
        // if (this.$route.query) {
        //     this.com_id = this.$route.query.user_id
        // }
        // console.log(this.$route.query.user_id, 'route');
        // let userId = window.localStorage.getItem("user_id");
        // this.getUserProduct();
        // this.getComInfo();
        let params2 = {
            cat_one: '',
            cat_two: '',
            cat_three: '',
            industry: '',
            industry2: '',
            page: 1,
            size: 30
        }
        getAllGoodsConsult(params2).then((res) => {
            console.log('打印了');
            this.titleList = res.data.data
        })
        let params = {
            bus_id: this.$route.query.id,
            page: this.page,
            size: this.size

        }
        getQuotationByBusId(params).then((res) => {
            this.tableData = res.data.data
            this.allNumber = res.data.totalPage
        })
        getBusManageById({ bus_id: this.$route.query.id }).then((res) => {
            this.dataList = res.data.data
        })
    },
    components: {
        searchBar,
        classifyBar,
        classifyContain,
        loginCon,
        strictProduct,
        product,
        strictProduct2,
    },
};
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rr {
    position: absolute;
    top: 100px;
    // transform: translateY(-50%);
    top: 0;
    left: -53px;
    z-index: 2;
    display: flex;
    margin-left: 52px;
    position: relative;
}

.dd {
    position: absolute;
    top: -49px;
    left: -53px;
    background-color: #fff;
    width: 400px;
    height: 217px;
    // z-index: 99;
    background: -webkit-linear-gradient(to right,
            rgba(253, 253, 253),
            rgba(253, 253, 253),
            rgba(253, 253, 253, .9),
            rgba(253, 253, 253, .2));
    /* Safari、Chrome */

    background: linear-gradient(to right,
            rgba(253, 253, 253),
            rgba(253, 253, 253),
            rgba(253, 253, 253, .9),
            rgba(253, 253, 253, .2));
}

.content2 {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .storeBox {
        width: 100%;
        height: 214px;
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 20px;
        background-image: url("../assets/buyerCenter/Rectangle.png");
        background-size: 100%;

        .left {
            display: flex;
            margin-left: 52px;
            position: relative;

            .info {
                margin-left: 40px;

                .title {
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 37.44px;
                    text-align: left;
                    color: #000;
                }

                .address {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18.72px;
                    text-align: left;
                    color: #000;
                    margin-top: 9px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            position: absolute;
            top: 140px;
            left: 562px;
            z-index: 99;

            .btn1 {
                width: 120px;
                height: 36px;
                border-radius: 18px;
                background: #006eb7;
                text-align: center;
                line-height: 36px;
                color: white;
                cursor: pointer;
            }
        }
    }
}

.topInfo {
    display: flex;
    align-items: center;

    .banner {
        width: 820px;
        height: 365px;
    }
}

.quotedBox {
    width: 1200px;
    margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    // background-color: white;

    .title {
        font-size: 24px;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 20px;
        box-sizing: border-box;
    }

    .line1 {
        padding: 20px;
        max-width: 1200px;
        // border-bottom: 1px solid #e4e4e4;
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-top: 20px;

        .boxL {
            display: flex;
            align-items: center;
        }

        .left1 {
            width: 100px;
            height: 100px;
        }

        .left2 {
            width: 180px;
            height: 100px;
            margin-left: 15px;

            .Stype {
                width: 69px;
                height: 20px;
                border-radius: 2px;
                opacity: 1;
                background: linear-gradient(270deg,
                        #ffa80b 0%,
                        #ffc252 48%,
                        #fdefd5 100%);
                font-size: 12px;
                color: #fff;
                box-sizing: border-box;
                padding-left: 5px;
            }

            &>div:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            &>div:nth-child(2) {
                font-size: 14px;
                padding: 2px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                width: 44px;
                background-color: #0370b7;
                margin-bottom: 10px;
            }
        }

        .left3 {
            width: 180px;
            height: 100px;
            font-size: 14px;
        }

        .left4 {
            width: 120px;
            color: #f23c24;
            font-weight: 700;
        }

        .left5 {
            display: flex;
            position: absolute;
            right: 80px;

            .tt5 {
                width: 80px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                border-radius: 33px;
                opacity: 1;
                border: 1px solid #006eb7;
                color: #006eb7;
                margin-left: 20px;
            }
        }

        .left {
            width: 80%;

            .title2 {
                font-size: 20px;
                cursor: pointer;
            }

            .infoBox {
                margin-top: 23px;
                display: flex;

                .info {
                    width: 160px;
                    font-size: 16px;
                    height: 23px;
                    white-space: nowrap;
                    /* 文本不换行 */
                    overflow: hidden;
                    /* 溢出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 文本超出部分用省略号表示 */
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            background: #006eb7;
            text-align: center;
            line-height: 36px;
            color: white;
        }
    }
}

.location {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 1200px;
    transform: translateY(10px);

    .nav {}
}

.line {
    width: 100%;
    height: 2px;
    background: #006eb7;
    position: absolute;
    top: 157px;
    left: 0;
}

.productBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F2F2F2;

    .productInfo {
        margin-right: 15px;
        cursor: pointer;
    }


    >.productInfo:nth-child(5n) {
        margin-right: 0;
    }
}

.title-box {
    width: 100%;
    height: 200px;
    background-color: white;
    display: flex;
    padding: 10px 0px;
    cursor: pointer;

    .title-left {
        width: 30%;
        height: 100%;
    }

    .title-right {
        width: 70%;
        height: 100%;
    }
}
</style>
