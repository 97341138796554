<template>
    <div class="box" @click="cancel">

        <div class="test_two_box">
            <div class="video-label">
                <div class="video-left">
                    <div style="font-size: 20px; color: #18191C;font-weight: 400;"> {{ dataBase.vTitle }}</div>
                    <div style="display: flex;color: #9499A0;padding-top: 8px;font-size: 12px;">
                        <div>
                            <img src="../assets/newImg2/SVG@1x (1).png"
                                style="vertical-align: middle;margin-right: 1px;" alt="">

                            <span style="vertical-align: middle;">{{ dataBase.playNum }}</span>
                        </div>

                        <div style="margin-left: 20px;">
                            <img src="../assets/newImg2/SVG@1x (2).png"
                                style="vertical-align: middle;margin-right: 1px;" alt="">
                            <span style="vertical-align: middle;">{{ dataBase.comment_num }}</span>
                        </div>

                        <div style="margin-left: 20px;line-height: 20px;">
                            {{ dataBase.vAddTime.slice(0, 11) }}
                        </div>
                        <div style="margin-left: 20px;line-height: 20px;width: 80px;">
                            视频来源:
                            <span v-show="dataBase.isOriginal == 0">原创</span>
                            <span v-show="dataBase.isOriginal == 1">转载</span>
                        </div>
                        <div style="margin-left: 20px;line-height: 20px;height:20px;width:180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;-webkit-box-orient: vertical;
                        " v-show="dataBase.isOriginal == 1">
                            <span>
                                转载地址: {{ dataBase.reprintUrl }}
                            </span>



                        </div>
                        <div style="margin-left: 20px;">
                            <img src="../assets/newImg2/Vector@1x (7).png"
                                style="vertical-align: middle;margin-right: 1px;" alt="">
                            <span style="vertical-align: middle;">未经作者授权，禁止转载</span>
                        </div>

                    </div>
                </div>
                <div class="video-right">
                    <div style="height: 50px;width: 50px;position: relative;">
                        <img :src="headPic" style="width: 100%;height: 100%;border-radius: 50px;" alt="">
                        <img src="@/assets/newImg2/会员标识@1x.png" style="position:absolute;right: 0;bottom: 0;"
                            v-if="vUploaderIsVip" alt="">
                    </div>
                    <div style="margin-left: 8px;">
                        <div style="padding: 5px;margin-left: 6px;">
                            {{ dataBase.vUploader }}
                        </div>
                        <div style="margin-top: 5px;">
                            <el-button type="primary" size='mini' style="width: 200px" @click="toAddtend"
                                v-show="isFans == '未关注'">
                                +关注
                            </el-button>
                            <el-button type="primary" size='mini' style="width: 200px" v-show="isFans == '已关注'"
                                @click="toAddtend2">已关注</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div style=" display: flex;">
                                <div class="title">
                                    {{ dataBase.vTitle }}
                                </div>

                                <div class="time-title2">
                                    <div style="height: 50px;width: 50px;">
                                        <img src="../assets/02b8050c91227b471d3aedbd55ecb557_1.png"
                                            style="width: 100%;height: 100%;border-radius: 50px;margin-top: 20px;"
                                            alt="">

                                    </div>
                                    <div class="time-title2-right">
                                        <div>
                                            {{ dataBase.vUploader }}
                                            <div> <el-button type="primary" size='mini'>关注</el-button>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                        </div>
                        <div style="display: flex;">
                            <div class="time-title" style="color: gray;">
                                <div>
                                    <img src="../assets/newImg2/SVG@1x (1).png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">

                                    <span style="vertical-align: middle;">{{ dataBase.playNum }}</span>
                                </div>

                                <div style="margin-left: 20px;">
                                    <img src="../assets/newImg2/SVG@1x (2).png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">
                                    <span style="vertical-align: middle;">{{ dataBase.comment_num }}</span>
                                </div>

                                <div style="margin-left: 20px;line-height: 20px;">
                                    {{ dataBase.vAddTime }}
                                </div>

                                <div style="margin-left: 20px;">
                                    <img src="../assets/newImg2/Vector@1x (7).png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">
                                    <span style="vertical-align: middle;">未经作者授权，禁止转载</span>
                                </div>


                            </div>

                        </div> -->

            <!-- <div style="   margin-left: 15vw;width: 50vw;margin-bottom: 10px;">
                <span>视频描述:</span>
                <span style="">{{ dataBase.vDesc }}</span>
            </div> -->

            <div class="content-flex">
                <div class="vudio-big">
                    <div class="vudio-mp4">

                        <video id="myVideo" class="video-js" :src="wdd" controls="controls" autoplay>
                            <source :src="wdd" type="video/mp4">
                        </video>
                        <div class="vudio-left" @click="jump">
                            <!-- <i class="el-icon-cpu"></i> -->
                            <!-- <img src="@/assets/90e49e052bd15e723fec337a1d6b346.png" alt=""> -->
                        </div>
                    </div>

                </div>
                <div class="video-all">
                    <!-- <div style="padding: 5px;font-weight: 900;font-size: 18px;border-bottom: 1px solid #EAEAEA;">
                        <span>播放列表</span>

                    </div> -->
                    <div class="video-list">

                        <div class="video-item" v-for=" item  in  videoList.slice(0, 8) " @click="getVideo(item)">
                            <div style="width: 40%;">
                                <img :src="item.vImgUrl"
                                    style="width: 100%;height: 100%;object-fit: cover;border-radius: 4px;" alt=""
                                    class="video-img">
                            </div>
                            <div style="width: 60%;">
                                <div class="item-top">{{ item.vTitle }}</div>
                                <div class="item-middle">{{ item.vDesc }}</div>

                                <div class="item-bottom">
                                    <img src="../assets/zhnlImg/Icon-YouTube@1x.png"
                                        style="vertical-align: middle;margin-right: 1px;" alt="">
                                    <!-- <div style="font-size: 12px;"> -->
                                    <span style="vertical-align: middle;font-size: 12px;"> {{ item.vUploader }}</span>
                                    <span style="margin-left: 10px;vertical-align: middle;font-size: 12px;">播放量：{{
        item.playNum
    }}</span>
                                    <!-- </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div
                style="width: 49vw;margin-left: 15vw;display: flex;cursor: pointer;padding: 15px 10px;border-bottom: 1px solid #E3E5E7;">
                <div v-if="zanShow" class="" style="display: flex;" @click="toZan">
                    <div><img src="../assets/newImg/点赞.png" alt="" style="width: 40px;height: 40px;"></div>
                    <div style="padding: 10px 26px;color: gray;">{{ like_num }}</div>
                </div>
                <div v-if="!zanShow" style="display: flex;" @click="toZan">
                    <div> <img src="../assets/newImg/点赞 (1).png" alt="" style="width: 40px;height: 40px;">
                    </div>
                    <div style="padding: 10px 26px;color: gray;">{{ like_num }}</div>

                </div>
                <div style="display: flex;position: relative;">
                    <div>
                        <img src="../assets/newImg/转发.png" alt="" style="width: 40px;height: 40px;"
                            @click.stop="transmit1">
                    </div>
                    <div style="padding: 10px 26px;color: gray;">
                        {{ vRelay }}
                    </div>
                    <div v-show="imgShow"
                        style="width: 300px;height: 170px;background-color: white;position: absolute;bottom: -184px;z-index: 9999;padding: 5px;box-sizing: border-box;box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);border-radius: 14px;  ">
                        <div style="width: 100%;height: 80%;padding: 5px;box-sizing: border-box;">
                            <img :src="dataBase.vImgUrl" alt=""
                                style="width: 100%;height: 100%;object-fit: cover;border-radius: 5px;">
                            <div
                                style="position: absolute;bottom: 46px;color: white;left: 18px;font-weight: 900;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                {{
        dataBase.vTitle }}</div>
                        </div>
                        <div style="height: 20%;width: 100%;display: flex">
                            <div
                                style="width: 80% ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;line-height: 32px;">
                                https://www.zhnl.com/vudio?v_id={{ dataBase.vId }}
                            </div>
                            <div style="width: 20%;background-color:#F1F3F7;line-height: 32px;border-radius: 12px;cursor: pointer;"
                                @click="transmit()">
                                <span style="margin-left: 10px;">复制</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;">
                    <div>
                        <i class="el-icon-star-off" style="font-size: 40px;" @click="collect(1)"
                            v-show="dataBase.isCollect == '未收藏'"></i>
                        <i class="el-icon-star-on" style="font-size: 40px;" @click="collect(2)"
                            v-show="dataBase.isCollect == '已收藏'"></i>
                    </div>
                    <div style="padding: 10px 26px;color: gray;">
                        {{ collectNum }}
                    </div>
                </div>
            </div>

            <div class="video-content">
                <div class="video-content-vDes" style="padding-left: 10px;">{{ dataBase.vDesc }}</div>
                <div style="display: flex;padding: 20px 0px;">
                    <div class="video-content-label" v-for=" item  in  industyList  ">
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="car">
                <h1>用户评论({{ dataBase.comment_num }})</h1>
                <div>
                    <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="300" show-word-limit
                        :rows="4">
                    </el-input>
                    <div style="text-align: right;margin-top: 15px;margin-right: 20px;margin-bottom: 10px;">
                        <el-button type="primary" @click="pushConment">发表评论</el-button>
                    </div>
                </div>
                <div class="car-box" v-for="( item, index ) in  commentList">
                    <div class="left">
                        <div class="left-one">
                            <img :src="item.headPic" alt=""
                                style="width: 80%;height: 80%; border-radius: 40px;margin-left: 30px;">
                            <img src="@/assets/newImg2/会员标识@1x.png" style="position:absolute;right: -15px;bottom: 8px;"
                                alt="" v-show="item.user2IsVip == 1">
                        </div>
                    </div>
                    <div class="moddle">
                        <div style="padding-left: 6px;font-weight: 900;">
                            <span style="font-size: 13px;font-weight: 500;color: #61666D;">{{ item.userName }}</span>
                            <!-- <span style="margin-left: 20px;"></span>
                            <span style="font-size: 14px;"> </span> -->
                            <!-- <span>来自 四川</span> -->
                        </div>
                        <div style="margin-top: 6px;font-size: 14px;padding: 1px 6px;">{{ item.comContent
                            }}</div>
                        <div style="margin-top: 12px;font-size: 14px;padding: 0px 6px;display: flex;">
                            <div style="color: gray; font-size: 14px;">{{ item.comTime }}</div>
                            <div v-if="item.userId == user_id" @click="delComtent(item.vclId)"
                                style="color: red;padding-left: 5px;cursor: pointer;">
                                删除</div>
                            <div style="margin-left: 5px;cursor: pointer;" @click="toReply(item, index)">回复
                            </div>

                        </div>
                        <div class="childBox" style="margin-top: 5px;padding-left: 30px;"
                            v-for="( item2, index2 ) in  item.children ">
                            <div style="display: flex;padding-top: 8px">
                                <div style="height: 20px;width: 20px;position: relative;">
                                    <img :src="item2.headPic" style="width: 100%;height: 100%;border-radius: 12px;"
                                        alt="">
                                    <img src="@/assets/newImg2/会员标识@1x.png"
                                        style="position:absolute;right: 2px;bottom: 0px;width: 5px;height: 5px;" alt=""
                                        v-show="item.user2IsVip == 1">
                                </div>
                                <div style="margin-left: 6px;font-size: 13px;font-weight: 500;color: #61666D;">{{
        item2.userName }}</div>
                            </div>
                            <div style="padding-top: 6px;">
                                <span style="color: skyblue;margin-right: 6px;">@{{ item2.userName2
                                    }}</span>{{ item2.comContent }}
                            </div>
                            <div style="margin-top: 12px;font-size: 14px;cursor: pointer;display: flex;">
                                <div style="color: gray; font-size: 14px;">{{ item2.comTime }}</div>
                                <div v-if="item2.userId == user_id" @click="delComtent2(item2.vclId, index)"
                                    style="color: red;padding-left: 5px;">删除</div>
                                <div @click="toReply2(item2, index, item)" style="margin-left: 5px;">回复
                                </div>

                            </div>
                        </div>
                        <div v-if="conmentLists[index]">
                            <el-input type="textarea" :placeholder="placeholder" v-model="com_content[index]"
                                maxlength="300" show-word-limit :rows="1"
                                style="padding-left: 30px;margin-top: 10px;width: 96%;">
                            </el-input>
                            <div style="text-align: right;margin-top: 15px;margin-bottom: 10px;margin-left: 20px;">
                                <el-button type="primary" @click="pushConment2(item, index)">回复</el-button>
                            </div>

                        </div>
                        <!-- v-show="item.children.length > 4" -->
                        <div style="margin-top: 10px;color: gray;cursor: pointer;" @click="showAll(item, index)"
                            v-if="showComtentList[index]" v-show="item.children.length >= 3">
                            显示全部....</div>


                    </div>
                    <div class="right">
                        <div>{{ index + 1 }}楼</div>
                        <!-- <div style="color: red;text-align: left;height: 60px;padding-right: 15px;cursor: pointer;padding-top: 49px;"
                            @click="delComtent(item.vclId)" v-if="item.userId == user_id">
                            <span>删除评论</span>
                        </div> -->
                    </div>

                </div>
            </div>

        </div>
        <!-- <el-upload class="upload-demo" :on-change="handleChange" :on-success="fileUpload" action="htts"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload> -->



        <!-- <button @click="add">获取</button> -->
        <!-- <div id="wechat-login">
        123
        </div> -->
        <!-- <button open-type="getPhoneNumber" bindgetphonenumber="getPhoneNumber"></button> -->

    </div>
</template>
<script type="text/ecmascript-6">

import { mapState } from "vuex";
import classifyBar2 from '../components/classifyBar2.vue'
import { getVodById, uploadVod, getBstGoodsGategory, getAllVod, playVideoById, delVideoComByUserId, addVideoCom, getComAudit, addLikeById, addVideoRelayById, addFollowByUserId, addCollectById } from '@/api/user'
export default {
    name: 'login',
    data() {
        return {
            vUploaderIsVip: false,
            imgShow: false,
            collectNum: 0,
            isFans: '',
            vUserId: '',
            headPic: '',
            industyList: [],
            currentUrl: window.location.href,
            relay_num: 0,
            like_num: 0,
            vRelay: 0,
            ifZan: '',
            zanShow: 0,
            ifOffer: '',
            userId: '',
            vclId: '',
            p_vcl_id: '',
            com_content: [],
            conmentLists: [],
            addIndex: "",
            textarea: '',
            dataBase: {},
            videoList: [],
            commentList: [],
            showComtentList: [],
            user_id: '',
            vId: '',
            v_id: '',
            treeProps: {
                children: 'children',
                label: 'gName'
            },
            fileList: [],
            wdd: '',
            co: 1,
            menus: [{
                id: 1,
                label: '一级 1',
                children: [{
                    id: 4,
                    label: '二级 1-1',
                    children: [{
                        id: 9,
                        label: '三级 1-1-1'
                    }, {
                        id: 10,
                        label: '三级 1-1-2'
                    }]
                }]
            }, {
                id: 2,
                label: '一级 2',
                children: [{
                    id: 5,
                    label: '二级 2-1'
                }, {
                    id: 6,
                    label: '二级 2-2'
                }]
            }, {
                id: 3,
                label: '一级 3',
                children: [{
                    id: 7,
                    label: '二级 3-1'
                }, {
                    id: 8,
                    label: '二级 3-2'
                }]
            }]
        }
    },
    components: {
        classifyBar2
    },
    // 创建完毕状态(里面是操作)
    created() {


    },
    mounted() {
        const loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.2)',
        });
        console.log('执行一次');
        this.user_id = window.localStorage.getItem('user_id')
        let params = {
            cat_one: '',
            cat_two: '',
            cat_three: '',
            industry: this.$route.query.industry,
            industry2: this.$route.query.industry2 != undefined ? this.$route.query.industry2 : null,
            page: 1,
            size: 100
        }
        getAllVod(params).then((res) => {
            this.videoList = res.data.data

        })
        this.v_id = this.$route.query.v_id
        playVideoById({ v_id: this.v_id }).then((res) => {
            loading.close();

            this.vUserId = res.data.data.vUserId
            this.headPic = res.data.data.uploaderHeadPic
            this.like_num = res.data.data.like_num
            this.vRelay = res.data.data.vRelay
            this.collectNum = res.data.data.collectNum

            this.isFans = res.data.data.isFans
            this.vUploaderIsVip = res.data.data.vUploaderIsVip
            this.industyList = res.data.data.industyList.map((item) => {
                return item.label
            })
            if (res.data.data.isLike == '未点赞') {
                this.ifZan = 0
                this.zanShow = 0
            } else {
                this.ifZan = 1
                this.zanShow = 1
            }
            this.v_id = this.$route.query.v_id
            this.wdd = res.data.data.vVideoUrl
            this.dataBase = res.data.data
            this.commentList = res.data.data.comment
            this.commentList.forEach((item) => {
                item.children = item.children.slice(0, 3)
            })
            this.commentList.forEach((item, index) => {
                this.conmentLists[index] = false
                this.showComtentList[index] = true
            })
            // this.commentList.forEach((item, index) => {
            //     this.showComtentList[index] = true
            // })
        })
        this.$store.commit('Login')
        // document.querySelector('video').src = 'https://vc15-al1-pl-agv.autohome.com.cn/video-31/E3BD4E39114FD258/2024-02-15/DE4BBFC269C311066F15C4841F4F2CE2-300-wm.mp4?key=2185E04E58AA1228699A94F361FC6E74&time=1708165461.mp4'
        // this.wdd = 'https://vc15-al1-pl-agv.autohome.com.cn/video-31/E3BD4E39114FD258/2024-02-15/DE4BBFC269C311066F15C4841F4F2CE2-300-wm.mp4?key=2185E04E58AA1228699A94F361FC6E74&time=1708165461.mp4'
        // this.initVideo();
        // getWebNav().then((res)=>{
        //     console.log(res,'00000000000000000');
        // })
        // getLoginParam().then()
        //     var obj =   new WxLogin({

        //                self_redirect: false, // 是否在iframe内跳转 redirect_uri
        //               id: 'wechat-login', // 希望二维码嵌入容器的 id
        //               appid:"wx18b332ba52498422",
        //                scope: "snsapi_login",
        //               redirect_uri: encodeURIComponent("http://www.zhnl.com"),
        //               href:'data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMjAwcHg7DQogIGhlaWdodDogMjAwcHg7DQp9DQo=',
        // })
        getBstGoodsGategory().then((res) => {
            this.menus = res.data.data
            console.log(this.menus, 'this.menus');
        })
    },
    watch: {
        '$route.query': {
            handler: function (val) {
                this.v_id = val.v_id
                playVideoById({ v_id: this.v_id }).then((res) => {
                    if (res.data.data.isLike == '未点赞') {
                        this.ifZan = 0
                        this.zanShow = 0
                    } else {
                        this.ifZan = 1
                        this.zanShow = 1
                    }
                    this.isFans = res.data.data.isFans
                    this.vUserId = res.data.data.vUserId
                    this.vId = this.v_id
                    this.headPic = res.data.data.uploaderHeadPic
                    this.wdd = res.data.data.vVideoUrl
                    this.dataBase = res.data.data
                    this.commentList = res.data.data.comment
                    this.commentList.forEach((item) => {
                        item.children = item.children.slice(0, 3)
                    })
                })
            },
            // immediate: true // 初次变化立即查询
        }
    },
    methods: {
        //收藏
        collect(id) {
            if (id == 1) {
                addCollectById({ v_id: this.dataBase.vId }).then((res) => {
                    if (res.data.code == 200) {
                        playVideoById({ v_id: this.v_id }).then((res) => {
                            this.dataBase = res.data.data
                            this.collectNum = res.data.data.collectNum
                        })
                        this.$message({
                            type: 'success',
                            message: '收藏成功',
                            offset: 86
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            offset: 86,

                            message: res.data.msg
                        })
                    }
                })
            } else {
                addCollectById({ v_id: this.dataBase.vId }).then((res) => {
                    if (res.data.code == 200) {
                        playVideoById({ v_id: this.v_id }).then((res) => {
                            this.dataBase = res.data.data
                            this.collectNum = res.data.data.collectNum
                        })
                        this.$message({
                            type: 'success',
                            message: '取消收藏成功',
                            offset: 76
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            offset: 86,

                            message: res.data.msg

                        })
                    }
                })
            }
        },
        toAddtend() {
            console.log(this.dataBase, 'tabase');
            if (this.dataBase.vUserId == window.localStorage.getItem('user_id')) {
                return this.$message({
                    type: 'warning',
                    message: '不能关注自己账号',
                    offset: 86,

                })
            }
            addFollowByUserId({ user_id: this.vUserId }).then((res) => {
                if (res.data.code == 200) {
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        this.isFans = res.data.data.isFans
                    })
                    this.$message({
                        type: 'success',
                        message: '关注成功',
                        offset: 86,

                    })
                } else {
                    this.$message({
                        type: 'error',
                        offset: 86,
                        message: res.data.msg
                    })
                }
            })
        },
        toAddtend2() {
            addFollowByUserId({ user_id: this.vUserId }).then((res) => {
                if (res.data.code == 200) {
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        this.isFans = res.data.data.isFans
                    })
                    this.$message({
                        type: 'success',
                        message: '已取消关注',
                        offset: 76
                    })
                } else {
                    this.$message({
                        type: 'error',
                        offset: 86,
                        message: res.data.msg
                    })
                }
            })
        },
        transmit() {
            addVideoRelayById({ v_id: this.v_id }).then((res) => {
                console.log(res, '....//');
                if (res.data.code == 200) {
                    this.currentUrl = this.currentUrl
                    navigator.clipboard.writeText(this.currentUrl);
                    this.$message({
                        message: '已成功复制到粘贴板',
                        type: 'success',
                        offset: 130,
                    })
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        this.like_num = res.data.data.like_num
                        this.vRelay = res.data.data.vRelay

                    })
                }

            })
        },
        transmit1() {
            this.imgShow = true
        },
        cancel() {
            this.imgShow = false

        },
        toZan() {
            addLikeById({ v_id: this.$route.query.v_id }).then((res) => {
                console.log(res, '。。。。xxx');
                if (res.data.msg == '已取消点赞') {
                    this.ifZan = 0
                    this.zanShow = 0
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: res.data.msg,
                    })
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        this.like_num = res.data.data.like_num

                    })
                } else {
                    this.ifZan = 1
                    this.zanShow = 1
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: res.data.msg,
                    })
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        this.like_num = res.data.data.like_num


                    })
                }
            })
        },
        showAll(data, index) {
            console.log(index, 'eee');
            this.showComtentList.forEach((item, index) => {
                this.$set(this.showComtentList, index, true)
            })
            this.$set(this.showComtentList, index, false)

            // console.log(item, 'ddd');
            playVideoById({ v_id: this.v_id }).then((res) => {
                if (res.data.data.isLike == '未点赞') {
                    this.ifZan = 0
                    this.zanShow = 0
                } else {
                    this.ifZan = 1
                    this.zanShow = 1
                    console.log('执行了吗');
                }
                this.commentList = res.data.data.comment
                this.commentList.forEach((item) => {
                    if (item.vclId != data.vclId)
                        item.children = item.children.slice(0, 3)
                })

            })
        },
        toReply(item, index) {
            // vclId p_vcl_id
            this.placeholder = `@${item.userName}`
            this.vclId = item.userId
            this.p_vcl_id = item.vclId
            this.addIndex = index
            this.vId = item.vId
            console.log(index, 'index', item);
            this.conmentLists.forEach((item, index) => {
                this.$set(this.conmentLists, index, false)
            })
            this.$set(this.conmentLists, index, true)
            // this.conmentLists[index] = true
            console.log(this.conmentLists, ' this.conmentLists');
        },
        toReply2(item2, index, item) {
            // this.$set(this.showComtentList, index, false)
            this.placeholder = `回复${item2.userName}`
            // vclId p_vcl_id
            this.vclId = item2.userId
            this.p_vcl_id = item.vclId
            this.addIndex = index
            this.vId = item2.vId
            this.conmentLists.forEach((item2, index) => {
                this.$set(this.conmentLists, index, false)
            })
            this.$set(this.conmentLists, index, true)
            // this.conmentLists[index] = true
            console.log(this.conmentLists, ' this.conmentLists');
        },
        pushConment2(data, index) {
            console.log(data, '//sss');
            this.showComtentList.forEach((item, index) => {
                this.$set(this.showComtentList, index, true)
            })
            let params = {
                reply_vcl_id: this.vclId,
                v_id: this.v_id,
                p_vcl_id: this.p_vcl_id,
                com_content: this.com_content[this.addIndex]
            }
            this.$set(this.showComtentList, index, false)
            addVideoCom(params).then((res) => {
                if (res?.data.code == 200) {
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        if (res.data.data.isLike == '未点赞') {
                            this.ifZan = 0
                            this.zanShow = 0
                        } else {
                            this.ifZan = 1
                            this.zanShow = 1
                            console.log('执行了吗');
                        }
                        this.commentList = res?.data.data.comment

                        this.commentList.forEach((item) => {
                            console.log(item, 'eee');
                            if (item.vclId != data.vclId) {
                                item.children = item.children.slice(0, 3)
                            }
                        })
                    })
                    // this.com_content[this.addIndex]
                    this.$set(this.com_content, this.addIndex, '')
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '回复成功'
                    })
                } else {
                    // this.$message({
                    //     type: 'warning',
                    //     message: '系统出错'
                    // })
                }
            })
        },
        delComtent(id, index) {
            console.log(index, '...');
            delVideoComByUserId({ vcl_id: id }).then((res) => {
                if (res.data?.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '删除成功'
                    })
                    // this.showComtentList.forEach((item, indexs) => {
                    //     this.$set(this.showComtentList, indexs, true)
                    // })
                    // this.$set(this.showComtentList, index, false)
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        if (res.data.data.isLike == '未点赞') {
                            this.ifZan = 0
                            this.zanShow = 0
                        } else {
                            this.ifZan = 1
                            this.zanShow = 1
                            console.log('执行了吗');
                        }
                        // this.vId = this.v_id
                        // this.wdd = res.data.data.vVideoUrl
                        // this.dataBase = res.data.data
                        this.commentList = res.data.data.comment
                        this.commentList.forEach((item) => {
                            console.log(item, 'eee9999');
                            item.children = item.children.slice(0, 3)
                        })
                        console.log(this.commentList, 'this.commentList.........');
                    })
                }
            })
        },
        delComtent2(id) {
            delVideoComByUserId({ vcl_id: id }).then((res) => {
                if (res.data?.code == 200) {
                    this.$message({
                        type: 'success',
                        offset: 86,
                        message: '删除成功'
                    })
                    playVideoById({ v_id: this.v_id }).then((res) => {
                        if (res.data.data.isLike == '未点赞') {
                            this.ifZan = 0
                            this.zanShow = 0
                        } else {
                            this.ifZan = 1
                            this.zanShow = 1
                            console.log('执行了吗');
                        }
                        // this.vId = this.v_id
                        // this.wdd = res.data.data.vVideoUrl
                        // this.dataBase = res.data.data
                        this.commentList = res.data.data.comment
                        this.commentList.forEach((item) => {
                            console.log(item, 'eee9999');
                            item.children = item.children.slice(0, 3)
                        })
                    })
                }
            })
        },
        pushConment() {
            if (!this.textarea) {
                this.$message({
                    offset: 86,
                    type: 'warning',
                    message: '请填写信息'
                })
                return
            }
            if (!this.user_id) {
                this.$message({
                    type: 'warning',
                    message: '请先登录您的账号',
                    offset: 230,
                })
                this.$router.push({
                    path: '/login'
                })
                return
            }
            const sensitiveWordsArray = ['你妹的', '你大爷', '傻逼', '滚', '操你妈', '色情', '做爱', '你妈逼', '操', 'sb', 'SB', '吊毛', '傻缺', '草你'];
            this.conmentLists.forEach((item2, index) => {
                this.$set(this.conmentLists, index, false)
            })

            // this.textarea
            // sensitiveWordsArray.forEach((item) => {
            //     if (this.textarea.indexOf(item) != -1) {
            //         ifOffer = 0
            //     }
            // })
            getComAudit({ comment: this.textarea }).then((res) => {
                console.log(res, '...///');

                if (res.data.code == 200) {
                    this.ifOffer = 1

                } else {
                    this.ifOffer = 0
                }
                let params = {
                    reply_vcl_id: '0',
                    com_content: this.textarea,
                    v_id: this.v_id,
                    p_vcl_id: '0'
                }
                if (this.ifOffer) {
                    addVideoCom(params).then((res) => {
                        if (res?.data.code == 200) {
                            this.$message({
                                type: 'success',
                                offset: 86,
                                message: '评论成功'
                            })
                            this.textarea = ''
                            playVideoById({ v_id: this.v_id }).then((res) => {
                                if (res.data.data.isLike == '未点赞') {
                                    this.ifZan = 0
                                    this.zanShow = 0
                                } else {
                                    this.ifZan = 1
                                    this.zanShow = 1
                                    console.log('执行了吗');
                                }
                                this.commentList = res.data.data.comment
                            })
                        }
                    })
                } else {
                    // this.$message({
                    //     type: 'warning',
                    //     message: '评论内容包含敏感词'
                    // })
                }
            })

        },
        getVideo(item) {

            const _time = Date.now()
            this.$router.push({
                path: '/vudio',
                query: {
                    v_id: item.vId,
                    _time
                }
            })
        },
        handleCheckChange(e) {
            console.log(e, '======');
        },
        append(data) {
            console.log(data, '----');
        },
        add() {
            getVodById({ videoId: '9095542bb69571eeb44c5017f1f80102' }).then((res) => {
                console.log('成功了', res);
                this.wdd = res.data.data.playURL
                console.log(this.wdd, 'this.wdd');
            })
        },
        fileUpload(res) {
            this.wdd = res.videoUrl
            // console.log(res,'------');
            // let params = {videoId:res.videoId}
            // getVodById(params).then((res)=>{
            //     console.log('成功了',res);
            //     this.wdd = res.data.data.playURL
            //     console.log(this.wdd,'this.wdd');
            // })
        },
        handleChange(file, fileList) {
            console.log(file, 'file');

            let formData = new FormData()
            formData.append("file", file.raw)
            formData.append("goods_id", 12)
            uploadVod(formData).then((res) => {
            })
        },
        initVideo() {
            //初始化视频方法
            let myPlayer = this.$video(myVideo, {
                //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                controls: true,
                //自动播放属性,muted:静音播放
                autoplay: "muted",
                //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                preload: "auto",
                //设置视频播放器的显示宽度（以像素为单位）
                width: "800px",
                //设置视频播放器的显示高度（以像素为单位）
                height: "400px"
            });
        },
        jump() {
            if (this.co == 1) {
                this.co++
                this.wdd = 'https://vc15-hw1-pl-agv.autohome.com.cn/video-35/E3BD4E39114FD258/2024-02-17/59DBFA7FABF3D2286F15C4841F4F2CE2-300-wm.mp4?key=133087A95B9ED11C859FFDF80FCDAFC8&time=1708244812'
            } else {
                this.wdd = 'https://vc15-hw1-pl-agv.autohome.com.cn/video-10/0A33363922E51BDE/2024-02-17/046BF656238F37716F15C4841F4F2CE2-300-wm.mp4?key=F44B853CBBAF36CD78019CAD7D81DC7E&time=1708245891'
            }

        },
    },
    computed: {
        ...mapState(["age"]),
    }
}
</script>

<style scoped lang="scss">
// html{
//   scrollbar-face-color:red;
//   scrollbar-highlight-color:red;
//   scrollbar-shadow-color:red;
//   scrollbar-3dlight-color:red;
//   scrollbar-arrow-color:red;
//   scrollbar-track-color:red;
//   scrollbar-darkshadow-color:red;
//   }
::-webkit-scrollbar {
    // display: none;
    width: 13px;
    background-color: red !important;
    ;

}

::-webkit-scrollbar-thumb {
    background-color: rgb(153, 153, 153);
}

::-webkit-scrollbar-track {
    background-color: white;
}

.item-middle:hover {
    color: rgb(32, 123, 255) !important;

}

.box {
    background-color: white;
    width: 100vw;
    padding-top: 92px;

    // height: 100vh;
}

.test_two_box {
    width: 100vw;
    background-color: white;

    .video-label {
        width: 100%;
        height: 68px;
        display: flex;

        .video-left {
            width: 50vw;
            margin-left: 15vw;
            height: 100%;
        }

        .video-right {
            margin-left: 18px;
            width: 20vw;
            height: 100%;
            display: flex;
            // margin-bottom: 5px
        }
    }

    .title {
        width: 36vw;
        margin: 24px 15vw 0px 15vw;
        color: black;
        font-size: 22px;
        font-weight: 600;
        overflow: hidden;



    }

    .time-title2 {
        display: flex;

        .time-title2-right {
            margin-left: 20px;
            margin-top: 20px;
        }
    }

    .time-title {
        // padding-top: 20px;
        width: 36vw;
        margin: 12px 15vw 10px 15vw;
        font-size: 13px;
        font-weight: 290;
        color: #9499A0;
        display: flex;
    }

    .box-one {
        margin-left: 3vw;
        vertical-align: middle;
    }

    .video-content {
        width: 49vw;
        margin-left: 15vw;
        // display: flex;
        cursor: pointer;
        padding: 15px 10px;
        border-bottom: 1px solid #E3E5E7;

        .video-content-vDesc {
            padding: 10px 0px;
            color: #333333;
            font-size: 16px;
            font-weight: 290;

        }

        .video-content-label {
            border-radius: 114px;
            padding: 10px 12px;
            background: #F1F2F3;
            margin-right: 5px
        }
    }
}

#myVideo {
    width: 100%;
    height: 100%;
    // background-color: green;
    // margin-left: 20vw;
    // margin: 0px 20vw;
}


.upload-demo {
    margin-left: 20vw;
    margin-top: 4vh;
}

.content-flex {
    display: flex;
    height: 452px;

    // height: 500px;
    // background-color: red;
    .video-all {
        height: 101%;
        width: 27%;
        // background-color: rgb(242, 242, 242);
        margin-left: 10px;

        // overflow-y: scroll;
        // overflow-x: none;
        // -ms-overflow-style: none;
        .video-list::-webkit-scrollbar {
            width: 8px;
        }

        .video-list::-webkit-scrollbar-thumb {
            border-radius: 20px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
        }

        .video-listt::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);

        }

        .video-list {
            width: 100%;
            height: 91.1%;
            // background-color: rgb(242, 242, 242);
            // overflow-y: scroll;
            // overflow-x: none;
            // -ms-overflow-style: none;

            .video-item {
                cursor: pointer;
                display: flex;
                height: 24%;
                width: 96%;
                margin-top: 2px;
                // background-color: pink;
                padding: 4px;
                margin-bottom: 3px;

                .video-img:hover {
                    box-shadow: 2px 3px 3px gray;
                }

                .item-top {
                    font-weight: 800;
                    padding: 2px 2px 2px 6px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // height: 70%;
                    // background-color: green;
                }

                .item-middle {
                    height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    font-size: 13px;
                    color: gray;
                    padding: 4px 5px;
                }

                .item-bottom {


                    font-size: 18px;
                    display: flex;
                    // height: 28%;
                    padding: 9px 2px 2px 6px;
                    color: gray;
                    // color: rgb(34, 134, 255);
                    // background-color: yellow;
                }

                // .item-middle:hover {
                //     color: rgb(34, 134, 255);
                // }

                .item-bottom:hover {
                    color: rgb(34, 134, 255);
                }
            }
        }
    }

}

.cp-big {
    width: 24vw;
    background-color: rgb(242, 242, 242);
    // background-color: red;
    height: 100%;
    // background-color: red;

    .cp1 {
        // background-color: white;
        color: black;
        padding: 3px;
        width: 100%;
        padding: 0px 14px;
        font-weight: 900;
        height: 10%;

    }


    .cp2 {
        cursor: pointer;
        // background-color: red;
        // background-color: rgb(242, 242, 242);
        // box-sizing: content-box;
        width: 100% !important;
        color: black;
        height: 67vh;
        // overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        /* IE 10+ */
        -ms-overflow-style: none;

        // scrollbar-face-color: #fcfcfc; 
        /* IE 10+ */
        .cp2-1 {
            display: flex;
            width: 100%;
            height: 12vh;
            padding: 2px 4px;
            // background-color: pink;

            .cp2-left {
                width: 40%;
                height: 100%;
            }

            .cp2-right {
                padding: 2px 5px;
                width: 60%;
                height: 12vh;

                // background-color: red;

                .cp2-top {
                    // height: 7vh;
                    padding: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    height: 36px;
                    // font-weight: 600;
                }

                .cp2-middle {
                    padding: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    height: 33px;
                    font-size: 14px;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 1;
                }

                .cp2-bottom {
                    font-size: 14px;
                    padding: 8px 4px;
                    // padding: 9px 0px 2px 0px;
                    font-size: 14px;
                    color: gray
                }
            }
        }
    }

}

.car {
    width: 50vw;
    margin-left: 15vw;
    color: black;
    padding-bottom: 100px;
    // background-color: white;

    .car-box {
        margin-top: 35px;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 26px;
        // height: 15vh;

        // background-color: red;
        .left {
            flex: 1.2;
            // margin: 0px auto;
            // position: relative;

            .left-one {
                width: 70px;
                height: 70px;
                position: relative;
                // position: absolute;
                // top: 90%;
                // left: 50%;
                // transform: translate(-50%, -50%);
            }
        }

        .moddle {
            // padding-top: 35px;
            // height: 150vh;
            flex: 10;

        }

        .right {
            flex: 1.5;
            // padding-top: 35px;

        }
    }



}

.vudio-big {
    position: relative;
    margin-left: 15vw;

}

.vudio-left {
    position: absolute;
    // bottom: 3.2vh;
    // left: 7vw;
    left: 8rem;
    bottom: 1.9rem;
    z-index: 100000;
    display: none;
}

// video::-webkit-media-controls-mute-button{
//      width: 26px;
//      height: 26px;
// }
video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

.vudio-mp4 {
    width: 50vw;
    height: 100%;
}

.vudio-big:hover {
    .vudio-left {
        display: block;
    }
}

.market {
    // margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    // align-items: flex-start;

    .line1 {
        width: 446px;
        // background-color: white;
        // padding: 16px;
        margin-right: 30px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                // height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            // height: 2px;
            // background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 8px;
        }
    }

    .line2 {
        width: 240px;
        // background-color: white;
        padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }

    .line3 {
        width: 276px;
        // background-color: white;
        // padding: 16px;
        // margin-right: 24px;
        box-sizing: border-box;
        padding-bottom: 0;

        .contaion {
            padding: 3px 8px;
        }

        .top {
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                // padding-left: 5px;
                cursor: pointer;
                font-weight: 700;
                width: 100%;
                height: 80px;
                overflow: hidden;
            }

            .date {
                font-size: 10px;
                color: #aeaeae;
                margin-left: 6px;
            }
        }

        .theLine {
            height: 2px;
            background-color: #e4e4e4;
            margin-top: 7px;
        }

        .contaion {
            margin-top: 5px;
            height: 480px;

            .info9 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                height: 43px;
                line-height: 43px;
                font-size: 14px !important;
                border-bottom: 1px dashed #ebeef5;
            }
        }

        .contaion :hover {
            color: #006eb7 !important;

            .info {
                border-bottom: 1px solid #006eb7;
            }
        }
    }
}

// #myVideo:hover{
//     .vudio-left{
//         display:block !important;
//     }
//     background-color: red;
// }</style>