<template>
  <div class="box" @mouseenter="allMouseEnter()">
    <div class="catery-box">
      <div class="catery-big" @mouseleave="handleMouseLeave()">
        <div
          class="catery-big-item"
          v-for="(item, index) in newCateryList"
          :key="item.value"
          :class="{ bgc2: wangNumber == item.value }"
          @mouseenter="handleMouseEnter(index, item.value)"
          @click.stop="radioChange0(item)"
        >
          <!-- v-show="index != 7 && index != 15" -->
          <div style="position: absolute; top: 16px; right: 1px; width: 1px; height: 50%; background-color: white" v-show="index != 9 && index != 19"></div>
          <div>{{ item.label }}</div>
          <div class="catery-absolute" v-show="cateryShowList[index]" :class="{ specialMargin: (wangNumber2 > 4 && wangNumber2 < 10) || (wangNumber2 > 14 && wangNumber2 < 20) }">
            <div class="absolute-item" v-for="data2 in item.children" :key="data2.value">
              <div class="absolute-left" @click.stop="radioChange2(data2, item)">
                <img src="../assets/newImg3/路径@1x (6).png" alt="" style="vertical-align: middle; padding-right: 1px" />
                <span style="vertical-align: middle">
                  {{ data2.label }}
                </span>
              </div>

              <div class="absolute-right">
                <div style="padding: 2px 5px; cursor: pointer" v-for="data3 in data2.children" :key="data3.value" @click.stop="radioChange3(data3, data2.value)" class="changeColor">
                  {{ data3.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div
        style="
          width: 30px;
          height: 214px;
          background-color: rgb(242, 244, 255);
          margin-top: 30px;
          border-left: 1px solid #d8d8d8;
          box-sizing: border-box;
          cursor: pointer;
          border-top: 1px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;
        "
        @click="changePage2"
      >
        <img src="../assets/newImg3/左建.png" style="width: 17px; height: 17px; margin-top: 100px; padding-left: 6px; cursor: pointer" alt="" />
      </div>
      <div class="priceBox">
        <transition-group name="list" tag="div" class="boxes-container" style="display: flex">
          <div
            class="price-item"
            v-for="(item, index) in tableList2"
            @click.stop="priceRadio(item)"
            :style="{ transform: `translateX(${-countNum * 210}px)` }"
            :key="item.id"
            @mouseenter="showLargeBox(index, item)"
            @mouseleave="hideLargeBox(index, item)"
            :class="{ 'red-border': item.up_price > 0 && showIndex === index, 'green-border': item.up_price < 0 && showIndex === index, 'blue-border': item.up_price == 0 && showIndex === index }"
          >
            <div style="width: 213px; box-sizing: border-box; height: 100%">
              <div class="price-item-one2">{{ item.good_name }}</div>
              <div class="price-item-one">{{ item.type ? item.type : "/" }}</div>

              <!-- <div class="price-item-one" style="color: #9e9e9e; font-size: 15px; font-weight: 290">元/{{ item.spec }}</div> -->
              <div class="price-item-one">
                {{ item.price }} <span style="color: #9e9e9e; font-size: 15px; font-weight: 290; margin-left: 4px">元/{{ item.spec }}</span>
              </div>
              <div></div>
              <div class="priceIfUp1" v-show="item.up_price < 0">
                <div style="background-color: #00b050; border-radius: 5px 0px 0px 5px; padding-left: 2px">
                  <img src="../assets/newImg3/路径@1x (7).png" v-show="item.up_price != 0" style="vertical-align: middle; margin-left: 3px" alt="" />
                </div>
                <div style="font-size: 15px; color: white; vertical-align: middle; line-height: 25px; background-color: #00b050; padding-left: 6px">
                  {{ item.up_price }}
                </div>
                <div
                  style="font-size: 15px; color: white; vertical-align: middle; padding-left: 10px; line-height: 25px; background-color: #00b050; padding-right: 5px; border-radius: 0px 5px 5px 0px"
                >
                  {{ item.up_percentage.toFixed(2) }}%
                </div>
              </div>
              <div class="priceIfUp2" v-show="item.up_price == 0">
                <div style="background-color: #7aaffd">
                  <img src="../assets/newImg3/路径@1x (7).png" v-show="item.up_price != 0" style="vertical-align: middle; margin-left: 3px" alt="" />
                </div>
                <div style="font-size: 15px; color: white; vertical-align: middle; margin-left: 3px; line-height: 25px; background-color: #7aaffd; padding-left: 6px; border-radius: 5px 0px 0px 5px">
                  {{ item.up_price }}
                </div>
                <div
                  style="font-size: 15px; color: white; vertical-align: middle; padding-left: 10px; line-height: 25px; background-color: #7aaffd; padding-right: 5px; border-radius: 0px 5px 5px 0px"
                >
                  {{ item.up_percentage.toFixed(2) }}%
                </div>
              </div>
              <div class="priceIfUp3" v-show="item.up_price > 0">
                <div style="background-color: red; border-radius: 5px 0px 0px 5px; padding-left: 2px">
                  <img src="../assets/newImg3/路径@1x (8).png" v-show="item.up_price != 0" style="vertical-align: middle; margin-left: 3px" alt="" />
                </div>
                <div style="font-size: 15px; color: white; vertical-align: middle; line-height: 25px; background-color: red; padding-left: 6px">
                  {{ item.up_price }}
                </div>
                <div style="font-size: 15px; color: white; vertical-align: middle; padding-left: 10px; line-height: 25px; background-color: red; padding-right: 5px; border-radius: 0px 5px 5px 0px">
                  {{ item.up_percentage.toFixed(2) }}%
                </div>
              </div>
              <div class="price-item-one" style="margin-top: 5px; font-weight: 290; color: #333333">
                <span style="vertical-align: middle"> {{ item.real_name }}</span>

                <img v-if="item.is_supper_vip == 1" src="../assets/newImg3/超级会员静态.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" />
                <!-- <span v-else-if="item.isFree == 0"></span> -->
                <span v-else-if="item.is_bus_vip_1 == 1"></span>
                <!-- <img  == 1" src="../assets/newImg3/黄金会员@1x.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" /> -->
                <img v-else-if="item.is_bus_vip == 1" src="../assets/newImg3/黄金会员@1x.png" style="vertical-align: middle; padding-left: 2px; width: 52px; height: 17px" alt="" />
              </div>
              <div class="price-item-one" style="margin-top: 5px; font-weight: 290; color: #333333">{{ item.city }}</div>
            </div>
            <div :ref="'echart' + index" class="chartStyle"></div>
          </div>
        </transition-group>
        <!-- :style="boxStyle(item, index)" -->
      </div>
      <div style="width: 30px; height: 214px; background-color: rgb(242, 244, 255); margin-top: 30px; border: 1px solid #d8d8d8; box-sizing: border-box; cursor: pointer" @click="changePage">
        <img src="../assets/newImg3/右建.png" style="width: 17px; height: 17px; margin-top: 100px; padding-left: 6px; cursor: pointer" alt="" />
      </div>
    </div>

    <!-- <div class="selectBox">
            <div style="display: flex;margin-left: 30px;">
                <div class="wangzhisb" v-for="(item, index) in areaCategoryList"
                    :class="{ bgc: wangNumber == item.gId }" @mouseenter="wangLang(item)" style="cursor: pointer;">{{
        item.label }}</div>
            </div>
        </div>
        <div class="select-item">
            <div class="select-right">
                <div v-for="(item, index) in areaCategoryTwoList" style="padding: 10px 20px ;cursor: pointer;"
                    @click="radioChange3(item)" class="bgc"> {{
        item.label }}</div>
            </div>
        </div> -->
    <div>
      <informationBox :areaCategoryList2="areaCategoryList2" titleName="热门报价" :gId="$route.query.gId" />
    </div>
    <div>
      <informationBox4 :areaCategoryList2="areaCategoryList2" titleName="能源" :gId="$route.query.gId" />
    </div>

    <div>
      <informationBox3 :areaCategoryList2="areaCategoryList2" titleName="有机化工最新报价" :gId="$route.query.gId" />

      <!-- <videoBox :areaCategoryList2="areaCategoryList2" titleName="原材料最新视频" /> -->
    </div>

    <!-- <storeBox /> -->
    <!-- 一级分类框 -->
    <!-- <div class="selectBox" style="height: 30vh;margin-bottom: 50px;">


            <div class="district" style="padding-bottom: 5px">
                <span style="margin-right: 50px; width: 65px; padding-bottom: 20px">一级分类：</span>

                <div class="select hhe" style="width: 990px">
                    <el-radio-group v-model="radio" @change="radioChange1">
                        <el-radio :label="item.gId" v-for="(item, index) in areaCategoryList">{{ item.label
                            }}</el-radio>

                    </el-radio-group>

                </div>
            </div>
            <div class="district" style="padding-bottom: 5px" v-if="radio">
                <span style="margin-right: 50px; width: 65px; padding-bottom: 20px">二级分类：</span>

                <div class="select hhe" style="width: 990px">
                    <el-radio-group v-model="radio2" @change="radioChange2">
                        <el-radio :label="item.gId" v-for="(item, index) in areaCategoryList2">{{ item.label
                            }}</el-radio>

                    </el-radio-group>

                </div>
            </div>
            <div class="district" style="padding-bottom: 5px" v-if="radio2">
                <span style="margin-right: 50px; width: 65px; padding-bottom: 20px">三级分类：</span>

                <div class="select hhe" style="width: 990px">
                    <el-radio-group v-model="radio3" @change="radioChange3">
                        <el-radio :label="item.gId" v-for="(item, index) in areaCategoryList3">{{ item.label
                            }}</el-radio>

                    </el-radio-group>

                </div>
            </div>





        </div> -->
    <div class="quotedBox" v-show="false">
      <div v-if="isBai == 2">
        <div class="line1 hhu" v-for="(item, index) in UserProduct">
          <div class="boxL">
            <div class="left1"></div>
            <div class="left2">
              <div>{{ item.goodsName }}</div>
              <div>
                {{ item.locationName ? item.locationName.slice(0, 2) : "未知" }}
              </div>
              <div class="" v-if="item.shopTypeName">
                <img :src="$img + item.shopTypeName" style="width: 69px; height: 20px" alt="" />
              </div>
            </div>
            <div class="left3">
              <div class="info" v-for="e in item.service" :key="e.id" style="margin: 3px 0px">
                {{ `${e.title}: ${e.content}` }}
              </div>
              <!-- <div class="info">规格：{{ item.spec_name }}</div>
                            <div class="info">品牌：{{ item.locationName }}</div>
                            <div class="info">等级:1111</div>
                            <div class="info">产地:{{ item.logistics_name }}</div> -->
            </div>
            <div class="left4">¥{{ item.goodsPrice }}/{{ item.specName }}</div>
            <div class="left5">
              <div class="tt5 hhy" @click="skip(item.goodId)">查看详情</div>
              <div class="tt5 hhy">
                <i class="el-icon-star-off" v-show="item.isCollect == 0" @click.stop="setCollectUserGoods(item.goodId)">收藏</i>
                <i class="el-icon-star-on" v-show="item.isCollect == 1" @click.stop="setCancelCollectUserGoods(item.goodId)">已收藏</i>
              </div>
            </div>
            <div class="left6">
              <div>{{ item.complayName }}</div>
              <div>
                <span>公司资质:</span>
                <img src="../assets/666.png" alt="" />
              </div>
              <div><span>联系人:</span>{{ item.contactsName }}</div>
              <div><span>联系方式:</span>{{ item.shopMobile }}</div>
              <div style="color: #016fb8" @click="skipPageGoods('/storeHome?admin_id=' + item.admin_id + '&goods_id=' + item.goods_id + '&com_id=' + item.user_id)">
                <i class="el-icon-s-shop">商家主页></i>
              </div>
            </div>
          </div>
        </div>
        <el-empty v-if="UserProduct.length < 1" description="暂无您想要的商品"></el-empty>
      </div>
      <div v-else>
        <div class="productBox">
          <div class="productInfo" v-for="(item, index) in UserProduct" @click="skip(item.goods_id)">
            <product
              v-if="isBai == 1"
              :img="item.original_img"
              :title="item.goodsName"
              :price="item.goodsPrice"
              :specName="item.specName"
              :click_count="item.click_count"
              :item="item"
              @add="getUserProduct"
            />
          </div>
          <div style="width: 1200px; margin: 50px auto">
            <el-empty description="没有你想要的商品" v-if="UserProduct.length < 1"></el-empty>
          </div>
        </div>
      </div>

      <div class="page">
        <el-pagination
          layout="prev, pager, next"
          :total="totalCount"
          :page-size="10"
          prev-text="上一页"
          next-text="下一页"
          @next-click="nextclick"
          @prev-click="prevclick"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- <div>
            <div
                style="height: 56px;line-height: 56px;   background-image: linear-gradient(rgba(202, 221, 250), rgba(242, 247, 255));padding-left: 20px;font-weight: 900;font-size: 20px;">
                最新资讯
            </div>
            <div class="content-box">
                <div class="content-item" v-for="(item, index) in IndustryInformationList" @click="toConsult(item)"
                    style="cursor: pointer;">
                    <div class="item-left">
                        <img :src="item.imgUrl" style="height: 100%;width: 100%;" alt="">
                    </div>
                    <div class="item-right">
                        <div class="right-one">{{ item.bgcTitle }}</div>
                        <div class="right-two">
                            {{ item.bgcContent }}
                        </div>
                        <div class="right-three">
                            <div style="display: flex;">
                                <img src="../assets/zhnlImg/Clip path group@1x.png"
                                    style="height: 18px;width: 18px;line-height: 18px;" alt="">
                                <div style="line-height: 19px;margin-left: 8px;">{{ item.addTime }}</div>
                            </div>
                            <div style="margin-left: 30px;display: flex;">
                                <img src="../assets/zhnlImg/Group@1x.png" alt=""
                                    style="padding-bottom: 3px;padding-right: 0px;height: 16px;width: 16px;">
                                <div style="line-height: 18px;margin-left: 4px;">{{ item.readNum }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1210px;height: 50px;background-color: red; background-image: linear-gradient(rgba(205, 223, 255), rgba(252, 245, 245));
            padding-left: 30px;padding-top: 10px;font-weight: 900;font-size: 19px;">
            <div>热门视频</div>
        </div>
        <div class="video-box">
            <div class="box-item" v-for="item in videoList" @click="toVideo(item)" style="cursor: pointer;">
                <div class="box-one">
                    <img :src="item.vImgUrl" style="width: 100%;height: 100%;border-radius: 10px;object-fit: cover;"
                        alt="" class="videoImg">
                </div>
                <div class="box-two">{{ item.vTitle }}</div>
                <div class="box-three">
                    <img src="../assets/zhnlImg/Icon-YouTube@1x.png" style="vertical-align: middle;margin-right: 1px;"
                        alt="">
                    <span style="vertical-align: middle;">{{ item.vUploader }}</span>
                    <span style="margin-left: 16px;">播放量:{{ item.playNum }}</span>
                </div>
            </div>

        </div>
        <div style="width: 100%;margin-top: 20px;margin-bottom: 80px;">

            <el-pagination v-model:current-page="page" v-model:page-size="size"
                style="margin-left: 35%;margin-top: 30px;" :page-sizes="[10, 20]"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div> -->
  </div>
</template>

<script>
import searchBar from "../components/searchBar.vue";
import classifyBar from "../components/classifyBar.vue";
import classifyContain from "../components/classifyContain.vue";
import loginCon from "../components/loginCon.vue";
import strictProduct from "../components/strictProduct.vue";
import product from "../components/product.vue";
import informationBox from "../components/informationBox.vue";
import informationBox4 from "../components/informationBox4.vue";
import informationBox3 from "../components/informationBox3.vue";
import videoBox from "../components/videoBox.vue";
import storeBox from "../components/storeBox.vue";
import strictProduct2 from "../components/strictProduct2.vue";
import getParams from "../utils/getParams";
import {
  getRawMaterialByBrId,
  getBstQuotedPrice,
  goodsCollect,
  getRawMaterialByBrName,
  getQutoByCatOne,
  getQuotToIndex,
  getAllGoodsConsult,
  getBstGoodsGategoryAndSon,
  getFatherCatBySonCat,
  getBstGoodsGategory1ByPid
} from "@/api/user";
import dayjs from "dayjs";
import Vue from "vue";

export default {
  data() {
    return {
      maxNum: 0,
      countNum: 0,
      size: 100,
      page: 1,
      spec: "",
      dataList: [],
      lineArray: [],
      showIndex: "",
      isHoveredList: [],
      hoverIndex: null, // 当前悬停的盒子索引
      ifShowList: [],
      cateryShowList: [],
      wangNumber: 0,
      wangNumber2: -1,
      newCateryList: [],
      wangNumber: 0,
      areaCategoryTwoList: [],

      title: "",
      industry: "",
      industry2: "",
      total: 0,
      tableList2: [],
      isF: 0,
      videoList: [],
      imgList: ["../assets/index/banner.png"],
      IndustryInformationList: [],
      areaCategoryList: [],
      areaCategoryList2: [],
      areaCategoryList3: [],
      areaCategoryListChildren: [],
      radio: "",
      radio2: "",
      radio3: "",
      index: 0,
      tableData2: [
        {
          date: "醋酸甲酯",
          name: "中海南联",
          down: "3800"
        },
        {
          date: "醋酸甲酯",
          name: "中海南联",
          down: "3800"
        },
        {
          date: "醋酸甲酯",
          name: "中海南联",
          down: "3800"
        },
        {
          date: "醋酸甲酯",
          name: "中海南联",
          down: "3800"
        },
        {
          date: "醋酸甲酯",
          name: "中海南联",
          down: "3800"
        }
      ],
      UserProduct: [],
      totalCount: 0,
      page: 1,
      userId: "",
      isBai: 2,
      isCollect: 100,
      search: "",
      GetPositionList: [], //广告
      keyword: "",
      isB: 1,
      totalPage: 0
    };
  },
  async created() {},
  methods: {
    changePage() {
      if (this.countNum < this.maxNum - 7) {
        this.countNum += 1;
      }
    },
    changePage2() {
      if (this.countNum > 0) {
        this.countNum -= 1;
      }
    },
    getEchartData(index) {
      const chart = eval("this.$refs.echart" + index)[0];
      if (chart) {
        const myChart = this.$echarts.init(chart);
        let spec = this.spec;
        const option = {
          tooltip: {
            trigger: "axis", // 触发类型，可选为'item'、'axis'
            axisPointer: {
              type: "shadow" // 指示器类型
            },
            formatter: function (params) {
              // 自定义显示内容，params 是包含当前数据点信息的数组
              var value = params[0].value; // 获取当前数据点的值
              var seriesName = params[0].seriesName; // 获取系列名称
              // 根据需要格式化显示内容
              return `价格：${value}/${spec}`;
            }
          },
          xAxis: {
            type: "category",
            data: this.dataList
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false // 隐藏Y轴轴线
            },
            axisTick: {
              show: false // 隐藏Y轴刻度线
            },
            axisLabel: {
              show: false // 隐藏Y轴刻度标签
            }
          },
          series: [
            {
              data: this.lineArray,
              type: "line",
              smooth: true
            }
          ]
        };
        myChart.setOption(option);
      }
    },
    boxStyle(item, index) {
      return {
        width: this.hoverIndex === index ? `${426}px` : "213px",
        transition: "width 0.5s ease"
      };
    },

    showLargeBox(index, item) {
      this.spec = item.spec;
      this.lineArray = [];
      this.lineArray.push(item.ftd_price);
      this.lineArray.push(item.yt_price);
      this.lineArray.push(item.td_price);
      this.isHoveredList[index] = true;
      this.showIndex = index;

      // this.hoverIndex = index;
      this.getEchartData(index);

      // this.$set(this.ifShowList, index, false);
    },
    hideLargeBox(index, item) {
      this.showIndex = "";

      // this.hoverIndex = null;
      this.isHoveredList[index] = false;
      // this.$set(this.ifShowList, index, true);
    },
    getWidth(text) {
      // 返回文本长度乘以20
      return text.length * 32;
    },
    handleMouseEnter(indexs, gId) {
      this.wangNumber = gId;
      this.wangNumber2 = indexs;
      this.cateryShowList.forEach((item, index) => {
        this.cateryShowList[index] = false;
      });
      Vue.set(this.cateryShowList, indexs, true);
    },
    handleMouseLeave() {
      this.wangNumber = 0;
      this.cateryShowList.forEach((item, index) => {
        Vue.set(this.cateryShowList, index, false);
      });
    },
    allMouseEnter() {
      this.$emit("oneMouse");
    },
    wangLang(data) {
      this.wangNumber = data.gId;
      // getBstGoodsGategory1ByPid({ p_id: this.wangNumber }).then((res) => {
      //     this.areaCategoryTwoList = res.data.data
      // })
      // this.areaCategoryListChildren = this.areaCategoryList.filter((item) => {
      //     return item.gId == data.gId
      // })
    },
    toVideo(row) {
      this.$router.push({
        path: "/vudio",
        query: {
          v_id: row.vId
        }
      });
    },
    toConsult(item) {
      this.$router.push({
        path: "/titleDetails",
        query: {
          id: item.bgcId
        }
      });
    },

    radioChange1(e) {
      this.radio2 = "";
      this.radio3 = "";
      let a = this.areaCategoryList.filter((item) => {
        return item.gId == e;
      });
      this.areaCategoryList2 = a[0].children;
      this.areaCategoryList3 = [];
    },

    radioChange0(e) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPriceCopy",
        query: {
          data: JSON.stringify({
            catOne: e.value,
            catTwo: "",
            label: e.label,
            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
    },
    radioChange2(e, item) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPriceCopy",
        query: {
          data: JSON.stringify({
            catOne: item.value,
            catTwo: e.value,
            label: e.label,

            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
    },
    priceRadio(e) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: e?.cat_one,
            catTwo: e?.cat_two,
            catThree: e?.cat_three,
            catFour: e?.cat_four,
            label: e.good_name,
            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
      getFatherCatBySonCat({ cat_id: e.value }).then((res) => {});
    },
    radioChange3(e, catTwo) {
      const { href } = this.$router.resolve({
        path: "/todayQuotedPrice",
        query: {
          data: JSON.stringify({
            catOne: "",
            catTwo: catTwo,
            catThree: e.value,
            label: e.label,
            cat_id: e.value
          })
        }
      });
      window.open(href, "_blank");
    },
    isFBtn(val, arrList) {
      this.isF = val;
    },
    collection(goodsId, isval) {
      this.isCollect = isval;
      let userId = window.localStorage.getItem("user_id");
      if (isval == 1) {
        this.$http("/mini/ApiConnect/setCancelCollectUserGoods", {
          user_id: userId,
          goods_id: goodsId
        }).then((res) => {
          this.$notify({
            title: "操作成功",
            message: res.msg,
            type: "success"
          });
        });
      } else {
        this.$http(" /mini/ApiConnect/setCollectUserGoods", {
          user_id: userId,
          goods_id: goodsId
        }).then((res) => {
          this.$notify({
            title: "操作成功",
            message: res.msg,
            type: "success"
          });
        });
      }
    },
    async setCollectUserGoods(goodId = null) {
      let params = {
        user_id: window.localStorage.getItem("user_id"),
        goods_id: goodId,
        type: 3
      };
      goodsCollect(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.$emit("add");
        } else if (res.data.code == 4019) {
          this.$message({
            message: "请登录后操作",
            type: "error"
          });
        }
      });
      this.getUserProduct();
      // let goods_id = null

      // console.log(1)
      // let userId = window.localStorage.getItem('user_id')
      // if (id) {
      //     goods_id = id
      // }
      // if (!goods_id)
      //     return this.$message({
      //         message: '收藏失败',
      //         type: 'error'
      //     })

      // let params = {
      //     user_id: userId,
      //     goods_id
      // }
      // let res = await this.$http(
      //     '/mini/ApiConnect/setCollectUserGoods',
      //     params
      // )
      // this.isCollect = 1

      // this.$message({
      //     message: res.msg,
      //     type: 'success'
      // })

      // this.getUserProduct()
    },

    //取消收藏商品
    async setCancelCollectUserGoods(goodId = null) {
      let params = {
        user_id: window.localStorage.getItem("user_id"),
        goods_id: goodId,
        type: 3
      };
      goodsCollect(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.$emit("add");
        } else if (res.data.code == 4019) {
          this.$message({
            message: "请登录后操作",
            type: "error"
          });
        }
      });
      this.getUserProduct();
      // let goods_id = null

      // let userId = window.localStorage.getItem('user_id')
      // if (id) {
      //     goods_id = id
      // }

      // if (!goods_id)
      //     return this.$message({
      //         message: '取消收藏失败',
      //         type: 'error'
      //     })
      // let params = {
      //     user_id: userId,
      //     goods_id
      // }
      // let res = await this.$http(
      //     '/mini/ApiConnect/setCancelCollectUserGoods',
      //     params
      // )
      // this.isCollect = 0
      // this.$message({
      //     message: res.msg,
      //     type: 'success'
      // })

      this.getUserProduct();
    },
    daBtn(num) {
      console.log(num, "num--");
      this.isBai = num;
    },
    skipPage(page) {
      window.location = page;
    },
    skipPageGoods(page) {
      window.open(page);
    },
    skip(id, admin_id) {
      window.location = "/detailsPage?goods_id=" + id + "&productType=store" + "&adminId=" + admin_id;
    },
    cellStyle() {
      return "'color': 'red'";
    },

    //点击分类获取数据
    productInfo(id) {
      this.getUserProduct(id, 2);
    },

    searchProduct(id, search) {
      this.page = 1;
      this.getUserProduct2(id, search);
    },

    nextclick() {
      this.page = this.page + 1;
      this.getUserProduct();
    },

    prevclick() {
      if (this.page > 0) {
        this.page = this.page - 1;
        this.getUserProduct();
      }
    },

    currentChange(e) {
      this.page = e;
      this.getUserProduct();
    },

    //产品列表
    getUserProduct(id, classify) {
      let a = getParams();
    },

    async getUserProduct2(id, search) {
      this.isB = id;
      getRawMaterialByBrName({ goods_name: search, user_id: window.localStorage.getItem("user_id"), page: this.page, index: this.isB }).then((res) => {
        this.UserProduct = res.data.data;
      });
    }
  },
  mounted() {
    // if ("WebSocket" in window) {
    //     console.log('进来了');
    //     //或者直接写
    //     const socket = new WebSocket("ws://api.vvtr.com/connect?apiKey=n3oLBHZvkX827z1961a99fc0872755c");
    //     socket.onopen = () => { console.log('open----') }
    //     socket.onmessage = (message) => { console.log('onmessage----', message) }
    //     socket.onerror = (error) => { console.log('onerror----', error) }
    // } else {
    //     Notification.error({
    //         title: "错误",
    //         message: "您的浏览器不支持websocket，请更换Chrome或者Firefox",
    //     });
    // }

    let today = dayjs();

    // 创建一个空数组来存储日期
    // let datesArray = [];

    // 循环四次，获取前四天的日期
    for (let i = 2; i >= 0; i--) {
      this.dataList.push(today.subtract(i, "day").format("MM-DD"));
    }
    getBstQuotedPrice({ size: this.size, page: this.page }).then((res) => {
      this.totalPage = res.data.totalPage;
      this.tableList2 = res.data.data;
      this.maxNum = res.data.data.length;
      console.log(this.maxNum, "this.maxNum");

      this.tableList2.forEach((item) => {
        this.ifShowList.push(true);
      });
    });
    getBstGoodsGategoryAndSon().then((res) => {
      this.newCateryList = res.data.data;
      this.newCateryList.forEach((item, index) => {
        this.cateryShowList[index] = false;
      });
    });

    let params2 = {
      cat_one: "",
      cat_two: "",
      cat_three: "",
      industry: "",
      industry2: "",
      size: 20,
      page: 1
    };
  },
  components: {
    searchBar,
    classifyBar,
    classifyContain,
    loginCon,
    strictProduct,
    product,
    strictProduct2,
    informationBox,
    informationBox3,
    informationBox4,
    videoBox,
    storeBox
  }
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .advertisement {
    width: 1200px;
    height: 160px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    padding: 20px;

    .adver_img {
      width: calc(1100px / 4);
      height: 120px;
      margin-right: 20px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .adver_img:nth-child(4) {
      margin-right: 0;
    }
  }
}

.content2 {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.topInfo {
  display: flex;
  align-items: center;
  position: relative;

  .banner {
    width: 100%;
    height: 365px;
    // margin-left: 190px;
  }
}

.quotedBox {
  width: 1200px;
  margin-top: 8px;
  margin-bottom: 50px;
  // padding: 24px;
  box-sizing: border-box;
  // background-color: white;

  .title {
    font-size: 24px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .line1 {
    padding-left: 20px;
    // border-bottom: 1px solid #e4e4e4;
    margin: 10px 0;
    height: 160px;

    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #fff;

    .boxL {
      display: flex;
      align-items: center;
    }

    .left1 {
      width: 100px;
      height: 100px;
    }

    .left2 {
      width: 180px;
      height: 100px;
      margin-left: 15px;

      .Stype {
        width: 69px;
        height: 20px;
        border-radius: 2px;
        opacity: 1;
        background: linear-gradient(270deg, #ffa80b 0%, #ffc252 48%, #fdefd5 100%);
        font-size: 12px;
        color: #fff;
        box-sizing: border-box;
        padding-left: 5px;
      }

      & > div:nth-child(1) {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      & > div:nth-child(2) {
        font-size: 14px;
        padding: 2px;
        font-weight: 400;
        text-align: center;
        color: #fff;
        width: 44px;
        background-color: #0370b7;
        margin-bottom: 10px;
      }
    }

    .left3 {
      width: 180px;
      height: 100px;
      font-size: 14px;
    }

    .left4 {
      width: 120px;
      color: #f23c24;
      font-weight: 700;
    }

    .left5 {
      display: flex;

      .tt5 {
        width: 80px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 33px;
        opacity: 1;
        border: 1px solid #006eb7;
        color: #006eb7;
        margin-left: 20px;
      }
    }

    .left6 {
      width: 373px;
      padding: 13px 0 13px 45px;
      margin-left: 8px;
      height: 160px;
      box-sizing: border-box;
      background-color: #e8effd;

      & > div {
        margin-bottom: 5px;
      }

      & > div:nth-child(1) {
        font-size: 16px;
        color: #006eb7;
        font-weight: 700;
      }

      & > div:nth-child(2),
      & > div:nth-child(3),
      & > div:nth-child(4) {
        color: #90959f;
      }
    }

    .left {
      width: 80%;

      .title2 {
        font-size: 20px;
        cursor: pointer;
      }

      .infoBox {
        margin-top: 23px;
        display: flex;

        .info {
          width: 160px;
          font-size: 16px;
          height: 23px;
          white-space: nowrap;
          /* 文本不换行 */
          overflow: hidden;
          /* 溢出部分隐藏 */
          text-overflow: ellipsis;
          /* 文本超出部分用省略号表示 */
          margin-right: 10px;
        }
      }
    }

    .right {
      width: 120px;
      height: 36px;
      border-radius: 18px;
      background: #006eb7;
      text-align: center;
      line-height: 36px;
      color: white;
    }
  }
}

.location {
  // margin-top: 20px;
  display: flex;
  align-items: center;
  width: 1200px;

  .nav {
  }
}

.page {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productBox {
  display: flex;

  flex-wrap: wrap;

  .productInfo {
    flex: 0 0 calc(100% / 5);
    cursor: pointer;
  }
}

@media screen and (min-width: 100px) and (max-width: 1620px) {
  .priceBox {
    height: 214px;
    margin-top: 30px;
    display: flex;
    width: 1100px;
    flex-shrink: 0;
    overflow: hidden;
    box-sizing: border-box;

    .price-item {
      flex-wrap: wrap;
      transition: all 0.7s;
      width: 176px;
      height: 100%;
      box-sizing: border-box;
      border-right: 1px solid #d8d8d8;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid #d8d8d8;

      .chartStyle {
        width: 130px;
        height: 200px;
      }

      .price-item-one {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        // margin-top: 5px;
      }

      .price-item-one2 {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        margin-top: 10px;
      }

      .priceIfUp1 {
        margin-left: 15px;
        // width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        // background-color: #00B050;
        display: flex;
      }

      .priceIfUp2 {
        margin-left: 15px;
        // width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        // background-color: #7AAFFD;
        display: flex;
      }

      .priceIfUp3 {
        margin-left: 15px;
        // width: 152px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        // background-color: red;
        display: flex;
      }
    }

    .price-item:last-child {
      border-right: 1px solid #d8d8d8;
    }

    .price-item:hover {
      width: 350px;
    }
  }

  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227bff 0%, #5f9efa 51%, #227bff 99%);

    .catery-big {
      width: 1200px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        cursor: pointer;
        position: relative;
        margin-top: 1.4px;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 600%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #d8d8d8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227bff;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;
            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7;
              }
            }
          }
        }
      }
    }
  }

  .select-item {
    display: flex;
    height: 48px;
    width: 1200px;
    background-color: white;
    padding: 6px 20px 0px 20px;
    margin-top: 2px;
    color: #333333;
    font-size: 16px;

    .select-left {
      width: 8%;
      height: 93%;
      margin-left: 10px;
      padding: 10px 2px;
      color: rgb(32, 123, 255) !important;
    }

    .select-right {
      width: 91%;
      height: 93%;
      display: flex;
    }
  }

  .selectBox {
    border-radius: 4px !important;
    background-color: white;
    border-radius: 4px;
    padding: 12px 20px 0px 20px;
    margin-top: 18px;
    height: 58px;
    width: 1200px;
    margin-top: 100px;
    margin-bottom: 1px;
    display: flex;
    font-family: "MicrosoftYaHeiLight";
    color: #333333;
    font-size: 16px;

    .wangzhisb {
      color: #333333;
      height: 65%;
      padding: 11px 5px 4px 18px;
      font-size: 16px;
      // border-bottom: 1px solid red;
    }

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }

      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1620px) and (max-width: 25000px) {
  .catery-box {
    width: 100vw;
    height: 100px;
    margin-top: 80px;
    background: linear-gradient(93deg, #227bff 0%, #5f9efa 51%, #227bff 99%);

    .catery-big {
      width: 80%;
      margin: auto;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .catery-big-item {
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        height: 44px;
        width: 10%;
        line-height: 44px;
        padding: 4px 0px 1px 0px;
        text-align: center;
        box-sizing: border-box;
        position: relative;

        .catery-absolute {
          // border-radius: 8px;
          position: absolute;
          width: 600%;
          display: flex;
          flex-wrap: wrap;
          top: 44px;
          background-color: white;
          z-index: 999;

          .absolute-item {
            line-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            word-wrap: break-word;
            width: 100%;
            margin: auto;
            padding: 3px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #d8d8d8;

            .absolute-left {
              height: 30px;
              cursor: pointer;
              box-sizing: border-box;
              padding: 2px 10px;
              text-align: left;
              color: #227bff;
              font-weight: 400;
              font-size: 14px;
              box-sizing: border-box;
              white-space: nowrap;
            }

            .absolute-right {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              height: 100%;
              text-align: left;
              display: flex;
              flex-wrap: wrap;

              .changeColor:hover {
                color: #006eb7;
              }
            }
          }
        }
      }
    }
  }

  .priceBox {
    overflow: hidden;
    box-sizing: border-box;
    width: 1470px;
    flex-shrink: 0;
    height: 214px;
    margin-top: 30px;
    // display: inline-block;
    display: flex;
    // margin: auto;

    .price-item {
      height: 100%;
      // border: 1px solid #D8D8D8;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      // margin-right: -1px;
      // display: inline-block;
      width: 210px;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid #d8d8d8;
      // width: 400px;

      flex-wrap: wrap;
      transition: all 0.7s;
      padding: 2px;
      // margin-left: 1px;

      .chartStyle {
        width: 175px;
        height: 200px;
      }

      .price-item-one {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 23px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        // margin-top: 2px;
      }

      .price-item-one2 {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 23px;
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        margin-top: 10px;
      }

      .priceIfUp1 {
        margin-left: 20px;
        // width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        // background-color: #00B050;
        display: flex;
      }

      .priceIfUp2 {
        margin-left: 20px;

        // width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        display: flex;
      }

      .priceIfUp3 {
        margin-left: 20px;
        // width: 154px;
        margin-top: 5px;
        height: 27px;
        border-radius: 5px;
        // background-color: red;
        display: flex;
      }
    }

    .price-item:last-child {
      border-right: 1px solid #d8d8d8;
    }

    .price-item:hover {
      width: 400px;
    }
  }

  .select-item {
    display: flex;
    height: 48px;
    width: 1500px;
    background-color: white;
    padding: 6px 20px 0px 20px;
    margin-top: 2px;
    color: #333333;
    font-size: 16px;

    .select-left {
      width: 8%;
      height: 93%;
      margin-left: 10px;
      padding: 10px 2px;
      color: rgb(32, 123, 255) !important;
    }

    .select-right {
      width: 91%;
      height: 93%;
      display: flex;
    }
  }

  .selectBox {
    border-radius: 4px !important;
    background-color: white;
    border-radius: 4px;
    padding: 12px 20px 0px 20px;
    margin-top: 18px;
    height: 58px;
    width: 1500px;
    margin-top: 100px;
    margin-bottom: 1px;
    display: flex;
    font-family: "MicrosoftYaHeiLight";
    color: #333333;
    font-size: 16px;

    .wangzhisb {
      color: #333333;
      height: 65%;
      padding: 11px 18px 4px 18px;
      font-size: 16px;
      // border-bottom: 1px solid red;
    }

    .btnBox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .btn {
        margin-right: 35px;
        width: 81px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        box-sizing: border-box;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
      }

      .btnBgc {
        background-color: #006eb7 !important;
        color: white !important;
        border: none !important;
      }
    }

    .district {
      font-size: 12px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      align-items: flex-start;
    }

    :deep(.el-checkbox) {
      width: 100px;
      margin-bottom: 10px;
    }

    :deep(.el-checkbox__label) {
      margin-top: 1px;
      font-size: 16px;
      margin-right: 53px;
      font-size: 12px;
    }

    :deep(.el-checkbox__inner) {
      font-size: 12px;
    }

    .checkedBox {
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .checked {
        height: 28px;
        border-radius: 14px;
        background: #006eb7;
        text-align: center;
        padding: 4px 10px;
        box-sizing: border-box;
        color: white;
        margin-right: 32px;
      }

      .clear {
        font-size: 12px;
        width: 69px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #006eb7;
        color: #006eb7;
        cursor: pointer;
      }
    }
  }
}

.el-radio {
  width: 100px !important;
}

.content-box {
  height: 800px;
  width: 1200px;
  background-color: white;
  margin-bottom: 50px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0px 18px;

  .content-item {
    border-bottom: 1px solid gray;
    padding: 20px 9px;
    height: 140px;
    width: 96%;
    margin: auto;
    display: flex;

    .item-left {
      // padding: 5px;
      width: 19%;
      height: 100%;
    }

    .item-right {
      height: 100%;
      width: 79%;

      .right-one {
        // background-color: red;
        height: 20%;
        padding: 0px 10px;
        padding-top: 8px;
        padding-bottom: 1px;
        font-weight: 900;
        width: 100%;
        font-size: 18px;
      }

      .right-two {
        height: 60px;
        padding: 0px 10px;
        // background-color: yellow;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        color: black;
      }

      .right-two:hover {
        color: rgb(32, 123, 255) !important;
      }

      .right-three:hover {
        color: rgb(32, 123, 255) !important;
      }

      .right-three {
        color: gray;
        height: 21%;
        width: 100%;
        display: flex;
        padding: 0px 10px;
        padding-top: 20px;
      }
    }
  }
}

.content-box::-webkit-scrollbar {
  width: 8px;
}

.content-box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.content-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.bgc {
  color: rgb(32, 123, 255) !important;
}

.video-box {
  width: 1210px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .box-item {
    width: 19%;
    height: 200px;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 10px;

    .box-one {
      height: 75%;
      border-radius: 10px;
    }

    .box-two {
      height: 10%;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 3px 1px;
    }

    .box-three {
      height: 10%;
      padding: 1px 1px;
      color: gray;
      font-size: 13px;
    }

    .box-two:hover {
      color: rgb(32, 123, 255) !important;
    }

    .box-three:hover {
      color: rgb(32, 123, 255) !important;
    }
  }
}

.bgc2 {
  background-color: white;
  color: #227bff !important;
}

.specialMargin {
  // background-color: yellow !important;
  left: -500% !important;
}

.red-border {
  border: 2px solid red !important;
}

.green-border {
  // background-color: green !important;
  border: 2px solid #00b050 !important;
}

.blue-border {
  border: 2px solid #7aaffd !important;
}

.list-enter {
  transform: translateX(700%);
  // transform: translateY(700%);
  // transition: all linear 10s;
}

.list-leave-to {
  transform: translateX(-900%);
  // transition: all linear 10s;
}
</style>
