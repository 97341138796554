<template>
    <div>
        <div class="quotedBox">
            <div class="title">
                供应商
            </div>

            <div class="line1">
                <div class="status">
                    <span>是否供应商：</span>
                    <span>{{ statusTxt }}</span>

                    <div class="btn" @click="ApplyForSupplier" v-if="IsSupplierFlag != 2">
                        申请成为供应商
                    </div>

                    <div class="btn" @click="skipAdmin" v-if="IsSupplierFlag == 2">
                        发布商品
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { addSupply, getUserById } from '@/api/user'
export default {
    data() {
        return {
            activeName: 'first',
            statusTxt: '',
            IsSupplierFlag: ''
        }
    },
    methods: {
        skipAdmin() {
            // window.open('https://zhnl.ydlweb.com/')
            window.location = '/issueProduct'
        },
        //是否已成为供应商
        async IsSupplier() {
            let user_id = window.localStorage.getItem('user_id')
            getUserById().then((res) => {
                console.log(res.data);
                if (res.data.data.admin_type == 2) {
                    this.IsSupplierFlag = 1
                    this.statusTxt = '否'
                } else if (res.data.data.admin_type == 1) {
                    this.IsSupplierFlag = 2
                    this.statusTxt = '是'

                }
            })

            // let res = await this.$http('/mini/ApiConnect/IsSupplier', { user_id: userId })

            // this.statusTxt = res.msg


            // if(res.msg == "已成为供应商") {
            //     this.IsSupplierFlag = 2
            // }

        },

        //申请成为供应商
        async ApplyForSupplier() {
            let user_id = window.localStorage.getItem('user_id')
            addSupply({ user_id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '已申请'
                    })
                }
            })
            // let res = await this.$http('/mini/ApiConnect/ApplyForSupplier', { user_id: userId })
            // if (res.status == 1) {
            //     this.$message({
            //         message: res.msg,
            //         type: 'success'
            //     });
            // } else {
            //     this.$message.error(res.msg);
            //     this.$router.push('/buyerCenter/amendEnterpriseInfo')
            // }
        },
    },
    mounted() {
        this.IsSupplier()
    }
}
</script>

<style lang="scss" scoped>
.quotedBox {
    width: 100%;
    // margin-top: 24px;
    margin-bottom: 50px;
    // padding: 24px;
    box-sizing: border-box;
    background-color: white;

    .title {
        font-size: 16px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ::v-deep .el-tabs__item {
            font-size: 20px !important;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            background-color: white !important;
        }
    }

}


.titleBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c5c5c5;
    padding-bottom: 13px;

    .title {
        font-size: 28px;
        text-align: left;
        color: #000;
        margin-top: 16px;
    }

    .date {
        font-size: 16px;
        text-align: left;
        color: #979797;
        margin-top: 12px;
    }
}

.info {
    color: #515151ff;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;
}

.status {
    padding: 20px;

    .btn {
        width: 120px;
        height: 40px;
        background-color: #006EB7;
        text-align: center;
        line-height: 40px;
        color: white;
        margin-top: 20px;
        cursor: pointer;
    }
}

.line1 {
    // padding: 20px 0;
}
</style>