<template>
    <div class="boxPu">
        <div class="content">
            <div @mouseleave="close" style="postion: relative">
                <div style="height: 36px">
                    <div class="line1 oop" @mouseenter="open">
                        <img src="../assets/index/Group.png" style="
                                object-fit: contain;
                                width: 18px;
                                height: 12px;
                                margin: 0 12px;
                            " />
                        <span style="font-size: 16px; font-weight: 400">{{
                classname
            }}</span>
                    </div>
                    <!-- v-if="classifyFlag " -->
                    <!-- && $route.name == 'quotedPrice' -->
                    <div class="op animate__animated animate__fadeInDown"
                        style="position: absolute; top: 156px; z-index: 999" @mouseleave="close" v-if="classifyFlag">
                        <classifyContain ref="classifyContain" />
                    </div>
                </div>
                <div class="line" v-show="!search"></div>
            </div>

            <div class="line2" :class="{ bgc: classify == 1, }" @click="btn(index + 1)"
                v-for="(item, index) in nav.slice(0, 7)" style="cursor: pointer;">
                <!-- <a :href="item.pcLink">{{ item.bwnName }}</a> -->
                <span @click="sentwdd(item.bwnName, item.bwnId)" :class="{ wdd: item.bwnId == wddId }">{{ item.bwnName
                    }}</span>
                <!-- getAllRawMaterial -->
            </div>

            <div class="line2 " v-if="nav.length > 6" :class="{ bgc: classify == 1 }">
                <el-popover placement="bottom" width="400" trigger="click">
                    <div class="nav_li_box">
                        <div class="son" v-for="
                            (e, index) in nav.slice(7, nav.length)">
                            <div class="son-l" @click="openurl(e)">
                                {{ e.bwnName }}
                            </div>
                        </div>
                    </div>
                    <i class="el-icon-arrow-right" slot="reference"></i>
                </el-popover>
            </div>
            <!-- <div class="line2" :class="{ bgc: classify == 1 }" @click="btn(6)">
                <router-link to="/encyclopedia">产品百科</router-link>
            </div> -->
        </div>
        <!-- <dayHot v-if="$route.name != 'quotedPrice'"></dayHot> -->
    </div>
</template>

<script>
import classifyContain from '../components/classifyContain.vue'
import dayHot from './dayHot.vue'
import { getWebNav, getAllRawMaterial } from '@/api/user'
export default {
    props: ['classify', 'isNo'],
    data() {
        return {
            btnFlag: 1,
            classifyFlag: false,
            wddId: null,
            nav: [
                { name: '主页' },
                { name: '原材料商城' },
                { name: '严选商城' },
                { name: '产品行情' },
                { name: '产品百科' },
                { name: '行业资讯' },
                { name: '积分商城' }
            ],
            search: '',
            arrList: [],
            classname: '原材料资讯'
        }
    },
    components: {
        classifyContain,
        dayHot
    },
    methods: {
        // getclassfy(){
        //     this.$refs.classifyContain.RawMaterialCateList()
        // },
        sentwdd(bwnName, id) {
            // this.$emit('getAllRawMaterial', bwnName)
            if (id == 1 && this.$route.path != '/') {

                const { href } = this.$router.resolve({
                    name: "/",

                });
                window.open(href, "_blank");

            } else if (id == 18 && this.$route.path != '/store') {
                // this.wddId = 18

                const { href } = this.$router.resolve({
                    name: "store",

                });
                window.open(href, "_blank");


            } else if (id == 9 && this.$route.path != '/quotedPrice') {

                const { href } = this.$router.resolve({
                    name: "quotedPrice",

                });
                window.open(href, "_blank");
            } else if (id == 19 && this.$route.path != '/strictStore') {

                const { href } = this.$router.resolve({
                    name: "strictStore",

                });
                window.open(href, "_blank");
            } else if (id == 21 && this.$route.path != '/consult') {

                const { href } = this.$router.resolve({
                    name: "consult",

                });
                window.open(href, "_blank");
            } else if (id == 22 && this.$route.path != '/hotVideo') {

                let a = 97

                const { href } = this.$router.resolve({
                    name: "hotVideo",

                });
                window.open(href, "_blank");
            }

        },
        openurl(e) {
            window.open(e.link)
        },
        btn(num) {
            this.btnFlag = num
        },
        open() {
            if (!this.isNo) {
                // this.classifyFlag = true
            }
            // if (this.arrList.length <= 0) {
            //     getAllRawMaterial().then((res) => {
            //         console.log(res);
            //         this.arrList = res.data.data
            //     })
            // }
            // this.$emit('isFBtn', 1, this.arrList)
        },
        close() {
            console.log('ad');
            this.classifyFlag = false
            if (!this.isNo) {

                // this.$emit('isFBtn', 0)
            }
        },

        getWebNav() {
            // let params = { type: 1 };
            // let res = await this.$http("/mini/ApiConnect/getWebNav", params);
            // this.nav = res.result;
            getWebNav({ type: 1 }).then((res) => {
                this.nav = res.data.data
            })
        }
    },
    mounted() {
        this.getWebNav()
        if (this.$route.path == '/') {
            this.wddId = 1
        } else if (this.$route.path == '/store') {
            this.wddId = 18
        } else if (this.$route.path == '/quotedPrice') {
            this.wddId = 9
        } else if (this.$route.path == '/strictStore') {
            this.wddId = 19
        } else if (this.$route.path == '/consult') {
            this.wddId = 21
        } else if (this.$route.path == '/hotVideo') {
            this.wddId = 22
        }
    },
    created() {
        let nwe = this.$route.path
        if (nwe === '/quotedPrice') {
            this.classname = '产品行情分类'
        } else if (nwe === '/store') {
            this.classname = '原材料商品分类'
        } else if (nwe === '/strictStore') {
            this.classname = '严选商城'
        } else if (nwe === '/encyclopedia') {
            this.classname = '产品百科分类'
        }
        else if (nwe === '/consult') {
            this.classname = '行业资讯'
        }
        else if (nwe === '/hotVideo') {
            this.classname = '热门视频'
        }


    },

    watch: {
        // '$route.path':function(nwe,old){
        //     console.log(nwe)
        //     if(nwe==='/quotedPrice'){
        //         this.classname='产品行情分类'
        //     }else if(nwe==='/store'){
        //         this.classname='原材料商品分类'
        //     }else if(nwe==='/strictStore'){
        //         this.classname='严选商品分类'
        //     }else if(nwe==='/encyclopedia'){
        //         this.classname='产品百科分类'
        //     }
        // }
    }
}
</script>
<style lang="scss"></style>

<style lang="scss" scoped>
.nav_li_box {
    width: 400px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .son {
        width: calc(100% / 3);
        font-size: 14px;
        margin-bottom: 25px;
        position: relative;

        .son-l {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 30px;
            line-height: 30px;
            padding: 0 10px;
        }

        .son-l:hover {
            color: rgb(34, 134, 255);
            cursor: pointer;
        }
    }
}

.boxPu {
    width: 1200px;
    margin: 0 auto;
}

.content {
    width: 1200px;
    margin: 0 auto;
    height: 36px;
    margin-top: 24px;
    display: flex;
    align-items: center;

    .line1 {
        width: 190px;
        height: 36px;
        background-color: rgb(34, 134, 255);
        border-radius: 10px 10px 0 0;
        line-height: 36px;
        color: white;
        font-size: 16px;
    }


    .line2,
    a:visited {
        width: 127px;
        height: 36px;
        line-height: 40px;
        text-align: center;
    }

    .line2:nth-child(2) {
        margin-left: 15px !important;
    }
}

.line2 :hover {
    // background-color: white;
    // color: white !important;
    border-bottom: 3px solid rgb(34, 134, 255);
    border-radius: 10px 10px 0 0;

    a {
        // color: white;
        background: none;
    }
}

.wdd {
    border-bottom: 3px solid rgb(34, 134, 255);
    border-radius: 10px 10px 0 0;
}

.animate__fadeInDown {
    --animate-duration: 0.5s;
}

.line {
    width: 100%;
    height: 2px;
    background: rgb(34, 134, 255);
    position: absolute;
    top: 166px;
    left: 0;
}
</style>
