<template>
    <div>
        <div class="myMoney">
            <div class="title">数据分析</div>
            <div class="sky-box">
                <div class="sky-left">
                    <img :src="info.vImgUrl" style="width: 100%;height: 100%;object-fit: cover;border-radius: 4px;"
                        alt="">
                </div>
                <div class="sky-right">
                    <div style="color: #333333;font-size: 16px;font-weight: 400;padding: 3px 10px;">{{ info.vTitle }}
                    </div>
                    <div style="display: flex;color: #333333;font-size: 12px;font-weight: 290;padding: 4px 10px;">
                        <div class="two-box"> <img src="../assets/newImg/容器@1x (2).png" alt=""></div>
                        <div class="two-box"> {{ info.playNum }}</div>
                        <div class="two-box" style="margin-left: 13px">
                            <img src="../assets/newImg/容器@1x (1).png" alt="">
                        </div>
                        <div class="two-box">
                            {{ info.commentNum }}
                        </div>
                        <div class="two-box" style="margin-left: 13px">
                            <img src="../assets/newImg/路径@1x (3).png" alt="">
                        </div>
                        <div class="two-box" style="margin-left: 2px;"> {{ info.likeNum }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vedio">
            <div class="vedio-top">
                <div style="font-size: 20px;padding: 13px;color: #333333;">视频数据</div>
                <div style="padding: 20px 15px;color: #9E9E9E;font-size: 12px;">统计周期：{{ sevenday }}-{{ today }}
                </div>
                <div style="padding-top: 13px;">
                    <el-select v-model="value" placeholder="请选择" style="width: 6rem;" size="mini" @change="dayChange">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="vedio-data">
                <div class="data-item" :class="{ bgc: classify == 1, }" @click="changeClassify(1)">
                    <div style="padding: 10px 42px;color: #252632;font-size: 14px;font-weight: 400;">播放量</div>
                    <div style="padding: 1px 40px;font-size: 20px;color: #333333;font-weight: 700;">{{ videoInfo.playNum
                        }}</div>
                </div>

                <div class="data-item" :class="{ bgc: classify == 2, }" @click="changeClassify(2)">
                    <div style="padding: 10px 42px;color: #252632;font-size: 14px;font-weight: 400;">点赞</div>
                    <div style="padding: 1px 40px;font-size: 20px;color: #333333;font-weight: 700;">{{ videoInfo.likeNum
                        }}</div>
                </div>
                <div class="data-item" :class="{ bgc: classify == 3, }" @click="changeClassify(3)">
                    <div style="padding: 10px 42px;color: #252632;font-size: 14px;font-weight: 400;">分享</div>
                    <div class="num">{{ videoInfo.relayNum }}</div>
                </div>
                <div class="data-item" :class="{ bgc: classify == 4, }" @click="changeClassify(4)">
                    <div style="padding: 10px 42px;color: #252632;font-size: 14px;font-weight: 400;">评论</div>
                    <div style="padding: 1px 40px;font-size: 20px;color: #333333;font-weight: 700;">{{
                        videoInfo.commentNum
                    }}</div>
                </div>

            </div>
            <div ref="chart" style="width: 99%; height: 666px;background-color: white;"></div>

        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { getVodDrawById, getVodInfoById } from '../api/user'
export default {
    data() {
        return {
            info: {},
            videoInfo: {},
            zhexList: [],
            zheyList: [],
            sevenday: '',
            today: '',
            value: '7',
            classify: 1,
            mark: 1,
            day: 7,
            options: [
                {
                    value: '7',
                    label: '近7天'
                },
                {
                    value: '15',
                    label: '近15天'
                },
            ],
        }
    },
    methods: {
        getEchartData() {
            console.log(this.videoTitle, 'MMMMMMMMMMMMMM');

            const chart = this.$refs.chart;
            if (chart) {
                const myChart = this.$echarts.init(chart);
                let czh = this.videoTitle;
                const option = {
                    xAxis: {
                        type: 'category',
                        data: this.zhexList
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: this.zheyList,
                            type: 'line',
                            smooth: true,
                            areaStyle: { // 区域填充样式  
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(185, 213, 255, 0.6)' // 顶部颜色  
                                }, {
                                    offset: 1,
                                    color: 'rgba(221, 234, 255, 0.3)' // 底部颜色，逐渐透明  
                                }]),
                                shadowColor: 'rgba(0, 0, 0, 0.1)' // 可选，为区域添加阴影  
                            },
                        }
                    ]
                };
                myChart.setOption(option);
            }
        },
        changeClassify(index) {
            this.classify = index
            this.mark = index
            let params = {
                v_id: this.$route.query.v_id,
                day: this.day,
                mark: this.mark,
            }
            getVodDrawById(params).then((res) => {
                console.log(res, 'res----');
                if (res.data.code == 200) {
                    this.zhexList = res.data.data.drawInfo.map((item) => {
                        return item.dates
                    })
                    this.zheyList = res.data.data.drawInfo.map((item) => {
                        return item.play_num
                    })


                    this.getEchartData()
                }
            })
        },
        dayChange(index) {
            this.day = index
            const sevenDaysAgo = dayjs().subtract(index, 'day');

            // 格式化日期为 YYYY.MM.DD 的形式  
            this.sevenday = sevenDaysAgo.format('YYYY.MM.DD');
            let params = {
                v_id: this.$route.query.v_id,
                day: this.day,
                mark: this.mark,
            }
            getVodDrawById(params).then((res) => {
                if (res.data.code == 200) {
                    this.videoInfo = res.data.data.videoInfo

                    this.zhexList = res.data.data.drawInfo.map((item) => {
                        return item.dates
                    })
                    this.zheyList = res.data.data.drawInfo.map((item) => {
                        return item.play_num
                    })


                    this.getEchartData()
                }
            })
        },
    },
    created() { },
    mounted() {
        getVodInfoById({ v_id: this.$route.query.v_id }).then((res) => {
            if (res.data.code == 200) {
                this.info = res.data.data
            }
        })
        const now = dayjs();
        // 获取七天前的日期  
        const sevenDaysAgo = now.subtract(7, 'day');
        // 格式化日期为 YYYY.MM.DD 的形式  
        this.sevenday = sevenDaysAgo.format('YYYY.MM.DD');
        this.today = dayjs().format('YYYY.MM.DD');
        let params = {
            v_id: this.$route.query.v_id,
            day: this.day,
            mark: this.mark,
        }
        getVodDrawById(params).then((res) => {
            console.log(res, 'res----');
            if (res.data.code == 200) {
                this.videoInfo = res.data.data.videoInfo
                this.zhexList = res.data.data.drawInfo.map((item) => {
                    return item.dates
                })
                this.zheyList = res.data.data.drawInfo.map((item) => {
                    return item.play_num
                })


                this.getEchartData()
            }
        })
    }
}
</script>

<style lang=scss scoped>
.myMoney {
    width: 98%;
    height: 170px;
    margin-top: 20px !important;
    box-sizing: content-box;
    margin: auto;
    border-radius: 8px;
    background-color: white;

    .title {
        color: #333333;
        font-size: 18px;
        font-style: 'Microsoft YaHei';
        font-weight: 400;
        padding: 9px 15px;
    }

    .sky-box {
        height: 120px;
        width: 99%;
        margin: auto;
        border-radius: 10px;
        padding: 10px;
        display: flex;

        .sky-left {
            width: 160px;
            height: 90px;
        }

        .sky-right {}
    }
}

.vedio {
    height: 28rem;
    width: 99%;
    margin-top: 20px;
    background-color: F9F9FA;
    opacity: 1;
    margin-left: 20px;

    .vedio-top {
        width: 100%;
        height: 3.05rem;
        display: flex;
        background-color: white;
    }

    .vedio-data {
        width: 100%;
        display: flex;
        margin-top: 5px;
        border-radius: 5px;

        .bgc {
            background-color: white;
            color: #227BFF !important;
            border-top: 2px solid #227BFF;
        }

        .data-item {
            border-radius: 12px 12px 0px 0px;
            width: 24.7%;
            height: 90px;

            .num {
                padding: 1px 40px;
                font-size: 20px;
                color: #333333;
                font-weight: 700;
            }
        }
    }
}

.two-box {
    padding: 2px 1px;
}
</style>